import { CaseEmployeeModel, EmployeeModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to  model keys type. Two values added for display isLocked & icon */
type CaseEmployeesKeysType =
  | keyof CaseEmployeeModel
  | keyof EmployeeModel
  | 'select'
  | 'deleteIcon'
  | 'createDate'
  | 'updateDate'
  | 'updateUserId';

/* tuple setup for config */
type CaseEmployeesHeaderTupleType = [CaseEmployeesKeysType, string];

/* actual display configuration */
const CASE_EMPLOYEES_LIST_HEADERS: readonly CaseEmployeesHeaderTupleType[] = [
  ['displayName', 'Name'],
  ['jobTitle', 'Title'],
  ['caseRoleDescription', 'Role'],
  ['email', 'Email'],
  ['employeePhoneNumber', 'PhoneNumber'],
  ['deleteIcon', ''],
];

/* list of columns that can be linked */
const CASE_EMPLOYEES_LIST_LINK_COLUMNS = [];

/* list of columns that can be sorted */
const CASE_EMPLOYEES_LIST_SORT_COLUMNS = ['caseRoleDescription'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class CaseEmployeesListData extends DataTableSetup {
  /* set the list headers */
  private caseEmployeesListHeaders = [...CASE_EMPLOYEES_LIST_HEADERS];
  private caseEmployeesListLinkColumns = [...CASE_EMPLOYEES_LIST_LINK_COLUMNS];
  private caseEmployeesListSortColumns = [...CASE_EMPLOYEES_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = CaseEmployeesListData.linkableColumns(
      CaseEmployeesListData.hiddenColumns(
        CaseEmployeesListData.sortColumns(
          CaseEmployeesListData.initColumns(this.caseEmployeesListHeaders
            .map((item) => this.translocoService.translate(item))),
          this.caseEmployeesListSortColumns
        ),
        []
      ),
      this.caseEmployeesListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
