import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngIf="to.isHidden">
      <div *ngFor="let field of field.fieldGroup || [{}]; let i = index;" style="margin-bottom: 5px">
        <ng-container *ngIf="true">
          <cf2-alt-expansion-panel
            [isExpanded]="false"
            [disableExpansion]="false"
            titleText={{getHeaderText(field)}}>
            <panel-content>
              <div [ngClass]="{'level-1': to.type === 'level-1', 'level-2': to.type === 'level-2'}" style="padding: 16px;" class="mat-elevation-z8 row" >
                <formly-field class="col" [field]="field"></formly-field>
              </div>
            </panel-content>
          </cf2-alt-expansion-panel>
        </ng-container>
      </div>
  </div>
  `,
})
export class RepeatTypeCAComponent extends FieldArrayType {
  constructor() {
    super();
  }

  getHeaderText(field: any) {
    let labelText: string;
    //if ((field.fieldGroup.find(f => f.key === 'commonAssessmentGroupsEn')) !== undefined) {
      labelText = field.fieldGroup[0].fieldGroup[0].model.groupName;
    //} 
    // else {
    //   field.fieldGroup.forEach(element => {
    //     if ((element.fieldGroup.find(f => f.key === 'planItemCode')) !== undefined) {
    //       labelText = element.fieldGroup.filter(f => f.key === 'planItemCode')[0].model.planItemDescription;
    //     }
    //   });
    // }
    return labelText;
  }
}
