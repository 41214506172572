import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../base/base.component';

import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { ModalService } from '@shared/services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cf2-multiple-entries',
  template: ``,
})
export class MultipleEntriesComponent<Model, Labels> extends BaseComponent implements OnInit {
  isReady = false;
  showForm = false;
  canEdit = false;
  editMode = false;
  temporaryKey = 0;

  fg: UntypedFormGroup;
  getFc: (key: string) => AbstractControl;
  fields: {
    fg: UntypedFormGroup;
    labels: Partial<Labels>;
    required?: Partial<Labels>;
    disabled?: Partial<Labels>;
  };

  get isReadOnly() {
    return this.clientCaseDataSvc.viewType === 'VIEW';
  }

  dataSub$ = new BehaviorSubject<Model[]>([]);

  constructor(public router: Router, public modalSvc: ModalService, public clientCaseDataSvc: ClientCaseDataService) {
    super();
  }
  ngOnInit() {
    if (this.clientCaseDataSvc.viewType === 'VIEW') {
      this.clientCaseDataSvc.disableAll();
    }
  }

  generateKey() {
    this.temporaryKey = this.temporaryKey + 0.001;
    return this.temporaryKey;
  }

  redirectTo(uri: string) {
    this.router.navigate(['/clients/new'], { skipLocationChange: true }).then(() =>
      // Required timeout to prevent component destroy errors
      setTimeout(
        () =>
          this.router.navigate([uri], {
            queryParams: {
              stepIndex: this.clientCaseDataSvc.stepperFormIndex,
            },
          }),
        10
      )
    );
  }

  async save() {
    // Save all tabs data to backend
    let response: { status: number | string };
    try {
      response = await this.clientCaseDataSvc.save();

      if (response.status === 'InvalidForm') return;
      if (response?.status === 200) {
        this.clientCaseDataSvc.showRecentlyCreatedCase = true;
        this.clientCaseDataSvc.markAsPristine();
        this.redirectTo('/clients');
      } else {
        this.modalSvc.openDialog({
          data: this.modalSvc.errorDialog,
        });
      }
    } catch (err) {
      if (!err?.status || err?.status !== 'InvalidForm') {
        console.log(err);
        this.modalSvc.openDialog({
          data: this.modalSvc.errorDialog,
        });
      }
    }
  }
}
