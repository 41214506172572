import { Component, Input, ViewEncapsulation, EventEmitter, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SelectOptions } from '@core/models/select-options.model';

import { map, startWith, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';

@Component({
  selector: 'cf2-autocomplete-select-field-fixed',
  template: `
    <div
      class="flex-row input-field-fixed"
      [class.backwards-compatible-with-input-field-top]="compatibleWithInputFieldTop"
    >
      <mat-form-field appearance="standard" floatLabel="always" class="cf2-chip-list">
        <mat-label>
          {{ label }}
          <span *ngIf="required" class="required-asterisk">*</span>
        </mat-label>
        <mat-chip-list #chipList role="listbox" aria-label="Autocomplete selection" [class.single-selection]="!multi">
          <input
            #autoCompleteInput
            type="text"
            matInput
            [placeholder]="label"
            [formControl]="_localCtrl"
            [matAutocomplete]="selectAutoComplete"
            [disableControl]="!!disabled"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            #autocompleteTrigger="matAutocompleteTrigger"
            [autocompletePosition]="autocompleteTrigger"
            (click)="openPanel()"
          />
          <ng-container *ngIf="!multi">
            <mat-chip
              *ngFor="let option of selection.selected"
              [disabled]="!!disabled"
              (click)="openPanel()"
              [matTooltip]="option.description"
            >
              {{ option.description }}
            </mat-chip>
          </ng-container>

          <div matSuffix class="mat-select-arrow-wrapper ng-tns-c156-67">
            <div class="mat-select-arrow ng-tns-c156-67"></div>
          </div>
        </mat-chip-list>
        <mat-autocomplete
          autoActiveFirstOption
          #selectAutoComplete="matAutocomplete"
          [displayWith]="getDisplayValue"
          (optionSelected)="onSelected($event, $event.option)"
        >
          <mat-option
            #allOption
            *ngIf="multi && filteredOptionsList?.length > 2"
            [value]="'Select All'"
            (click)="selectAll(allOption)"
          >
            <mat-checkbox *ngIf="multi" [checked]="allOptionSelected"> {{ 'SelectAll' | transloco }} </mat-checkbox>
          </mat-option>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option" [disabled]="option?.disabled">
            <mat-checkbox *ngIf="multi" [checked]="isOptionSelected(option)">
              {{ option.description }}
            </mat-checkbox>
            <ng-container *ngIf="!multi">
              {{ option.description }}
            </ng-container>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="ctrl"> {{ ctrl['errorMessage'] ? ctrl['errorMessage'] : null }}</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-row flex-wrap" *ngIf="multi">
      <mat-chip
        [ngStyle]="{ margin: '4px' }"
        *ngFor="let option of selection.selected"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(option)"
        (click)="openPanel()"
      >
        {{ option.description }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </div>
  `,
  styleUrls: ['./input-field-fixed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'autcompleteSelectField',
})
export class AutoCompleteSelectFieldFixedComponent implements OnInit, OnDestroy {
  @ViewChild('selectAutoComplete') matAutocomplete: MatAutocomplete;
  @ViewChild('autocompleteTrigger') matAutocompleteTrigger: MatAutocompleteTrigger;

  // used to set the input value in this component
  _localCtrl = new UntypedFormControl();

  // initalize containerCtrl with null, FormContorl object is set from the container component
  _containerCtrl: UntypedFormControl | null = null;
  get ctrl() {
    return this._containerCtrl;
  }
  @Input() set ctrl(ctrl: UntypedFormControl) {
    this._containerCtrl = ctrl;
  }

  private _options: SelectOptions[] = [];
  get options() {
    return this._options;
  }
  @Input()
  set options(options: SelectOptions[]) {
    this._options = options;
    this.filteredOptions.next(options);
    this._containerCtrl?.value ? this.setLocalControl(this._containerCtrl?.value) : null;
  }

  @Input() required = false;
  @Input() multi = false;
  @Input() label = '';
  @Input() value = '';
  @Input() disabled = false;
  @Input() small = false;
  @Input() fullWidth = false;
  @Input() icon: string;
  @Input() backgroundColor: 'blue' | 'grey' | 'none' = 'none';
  @Input() removable = false;

  // added compatible layer to align well with the old form fields
  @Input() compatibleWithInputFieldTop = true;

  @Output() selectionChanged = new EventEmitter();
  filteredOptions: BehaviorSubject<SelectOptions[]> = new BehaviorSubject<SelectOptions[]>([]);
  filteredOptionsList: SelectOptions[];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  selection = new SelectionModel<SelectOptions>(true, []);

  allOptionSelected = false;
  filteredOptionsSub: Subscription;

  ngOnInit() {
    this.filteredOptionsSub = this._localCtrl.valueChanges
      .pipe(
        startWith(this._containerCtrl?.value ? this._containerCtrl.value : ''),
        map((value) => (typeof value === 'string' ? value : value?.description)),
        map((value) => (value ? this._filter(value) : this.options?.slice())),
        tap((list) => (this.filteredOptionsList = list))
      )
      .subscribe((options) => this.filteredOptions.next(options));

    if (this.ctrl) {
      // set on page load
      this.setLocalControl(this.ctrl.value);

      // set on value changed from container or outside the component
      this.ctrl.valueChanges.subscribe((value) => {
        this.setLocalControl(value);

        if (this.ctrl.disabled) this._localCtrl.disable();
        else this._localCtrl.enable();
      });
    }
  }

  ngOnDestroy() {
    this.filteredOptionsSub?.unsubscribe();
  }

  _filter(value) {
    return this.options?.filter((opt) => opt?.description?.toLowerCase().includes(value?.toLowerCase()));
  }

  setLocalControl(controlValue) {
    if (this.options) {
      const selectedOptions = this.options.filter((opt) =>
        this.multi ? controlValue.includes(opt.value) : controlValue == opt.value
      );

      this.selection.clear();
      selectedOptions?.length > 0 ? this.selection.select(...selectedOptions) : null;
    }
  }

  getDisplayValue = (selectedOption: SelectOptions) => {
    return selectedOption?.description ? selectedOption.description : selectedOption;
  };

  onSelected($event: MatAutocompleteSelectedEvent, selectedOption: MatOption) {
    if (this.disabled || this.ctrl?.disabled || selectedOption.value === 'Select All') return;
    // if not multi then clear all selection and just select the newly selected option
    if (!this.multi) {
      this.selection.clear();
      this.selection.select(selectedOption.value);

      // is multi then toggle option
    } else {
      this.selection.toggle(selectedOption.value);
    }

    this._localCtrl.setValue('');

    this.emitValues();

    if (this.multi) setTimeout(() => this.openPanel(), 1);
  }

  selectAll(allOption: MatOption) {
    this.allOptionSelected = !this.allOptionSelected;
    const selected = this.allOptionSelected;

    selected ? this.selection.select(...this.options) : this.selection.clear();
    selected ? allOption.select() : allOption.deselect();

    this._localCtrl.setValue('');

    this.emitValues();
  }

  get selectedValues() {
    return this.selection?.selected?.map((opt) => opt.value);
  }

  isOptionSelected(option: SelectOptions) {
    return this.selectedValues.includes(option.value);
  }

  remove(option: SelectOptions): void {
    this.selection.deselect(option);

    this.emitValues();
  }

  openPanel() {
    if (this.disabled || this.ctrl?.disabled) return;
    this.matAutocompleteTrigger.openPanel();
  }

  emitValues() {
    const selectedValues = this.selectedValues;

    if (this.multi) {
      this.selectionChanged.emit(selectedValues);
      this.ctrl?.setValue(selectedValues);
    } else {
      this.selectionChanged.emit(selectedValues[0]);
      this.ctrl?.setValue(selectedValues[0]);
    }
  }
}
