import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { UserModel, UserClaim } from '@api/models';
import { UserService } from '@api/services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-user',
  template: `
    <cf2-header-row>
      <header-title><ng-container *transloco="let t"> {{ t(pageTitle) }}</ng-container></header-title>

      <page-content>
        <div class="fullBody">
        <ng-container *transloco="let t">  
          <table *ngIf="user">
            <thead>
              <tr>
                <th width="100%">{{ t('UserId') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ user.userId }}</td>
              </tr>
            </tbody>
          </table>

          <hr />

          <table *ngIf="token">
            <thead>
              <tr>
                <th width="100%">
                Token&nbsp;&nbsp;<a tooltip="Copy Token" (click)="copyToken()"><i class="fa fa-copy fa-1x"></i></a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ token }}</td>
              </tr>
            </tbody>
          </table>

          <hr />

          <table *ngIf="claims">
            <thead>
              <tr>
                <th width="50%">{{ t('Type') }}</th>
                <th width="50%">{{ t('Value') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of claims">
                <td>{{ item.type }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        </div>
      </page-content>
    </cf2-header-row>
  `,
  styleUrls: ['./user.component.scss'],
  providers: [UserService],
})
export class UserComponent implements OnInit {
  public pageTitle = 'UserDetails';
  public user: UserModel;
  public token: string;
  public claims: UserClaim[];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.apiUserGet$Json().subscribe((data) => {
      this.user = data;
    });

    this.userService.apiUserTokenGet$Json().subscribe((data) => {
      this.token = data;
    });

    this.userService.apiUserClaimsGet$Json().subscribe((data) => {
      this.claims = data;
    });
  }

  copyToken() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.token);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });

    document.execCommand('copy');
    alert('Token Copeid');
  }
}
