import { TaskModel, TaskSearchModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to document stores keys type. Two values added for display isLocked & icon */
type WorkFlowStoreKeysType = keyof TaskSearchModel;

/* tuple setup for document config */
type WorkFlowListHeaderTupleType = [WorkFlowStoreKeysType, string];

/* actual display configuration */
 const WORKFL_LIST_HEADERS: readonly WorkFlowListHeaderTupleType[] = [
   ['moduleDescription', 'Component'],
   ['taskName', 'Task'],
   ['firstName', 'ClientName'],
   ['recordNumber', 'ReferenceID'],
   ['recordStatus', 'Status'],
   ['createdDate', 'CreatedDate'],
   ['dueDateFormatted', 'DueDate'],
   ['claimTypeCode', 'ClaimType'],
   // ['comment', 'Comment'],
 ];

/* list of columns that can be linked */
const WORKFL_LIST_LINK_COLUMNS = ['recordNumber'];

/* list of columns that can be sorted */
const WORKFL_LIST_SORT_COLUMNS = [
  'moduleDescription',
  'taskName',
  'firstName',
  'recordNumber',
  'recordStatus',
  'createdDate',
  'createdDate',
  'dueDate',
  'claimTypeCode'
];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class WorkFlowListData extends DataTableSetup {
  /* set the list headers */
  private WorkFlowListHeaders = [...WORKFL_LIST_HEADERS];
  private WorkFlowListLinkColumns = [...WORKFL_LIST_LINK_COLUMNS];
  private WorkFlowListSortColumns = [...WORKFL_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config(): ListConfig[] {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private WorkFlowListSetup() {
    const setup = WorkFlowListData.linkableColumns(
      WorkFlowListData.hiddenColumns(
        WorkFlowListData.sortColumns(
          WorkFlowListData.initColumns(this.WorkFlowListHeaders.map((item) => this.translocoService.translate(item))),
          this.WorkFlowListSortColumns
        ),
        []
      ),
      this.WorkFlowListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();

    this.config = this.WorkFlowListSetup();
  }
}
