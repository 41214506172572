import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OPEN_CASE_STATUS_CODES } from '@core/constants/cf2-case.constants';
import { DataService } from '@core/services/data.service';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { filter, map, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-program-type-popup',
  template: `
  <ng-container *transloco="let t"> 
    <mat-dialog-content>
      <div class="overlay" *ngIf="isLoading">
        <div class="spinner-wrapper"></div>
      </div>
      <ng-container>
        <div class="flex-row flex-wrap">
          <p>
          {{t('Message.Prompt.NewClientProgramSelection')}}
          </p>
        </div>
        <div class="flex-row flex-wrap flex-center">
          <ng-container *ngIf="program$ | async as options">
            <cf2-select-field-fixed
              [options]="options"
              [ctrl]="programType"
              [label]="'ProgramType' | transloco"
              fullWidth="true"
            ></cf2-select-field-fixed>
          </ng-container>
        </div>
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
      <!-- return empty array on cancel -->
      <button mat-button [mat-dialog-close]="null">{{ t('Cancel') }}</button>
      <button mat-button [mat-dialog-close]="programType.value" [disabled]="!programType.value">{{ t('Continue') }}</button>
    </mat-dialog-actions>
    </ng-container>
  `,
  styles: [
    `
      :host .loading {
        text-align: center;
        font-size: 24px;
        padding: 16px;
      }

      :host cf2-select-field-fixed {
        width: 50%;
      }
    `,
  ],
})
export class ProgramTypePopupComponent implements OnInit {
  isReady = false;
  isLoading = false;
  programType = new UntypedFormControl(null);
  program$ = this.dataSvc.programs$.pipe(
    map((res) => {
      const activePrograms = this.clientCaseDataSvc.caseHistory
        .filter((caseData) => OPEN_CASE_STATUS_CODES.includes(caseData.caseStatusCode))
        .map((caseData) => caseData.parentProgramKey);

      const filteredPrograms = res.body.filter((program) => !activePrograms.includes(program.parentProgramKey));

      // If employment Ontario exists in the list then set the program type to employment ontario
      filteredPrograms.some((program) => program.programName.toLowerCase() === 'EO'.toLowerCase())
        ? this.programType.setValue(
            filteredPrograms.find((program) => program.programName.toLowerCase() === 'EO'.toLowerCase())
              ?.parentProgramKey
          )
        : null;

      return filteredPrograms.map((program) => ({
        value: program.parentProgramKey,
        description:  this.translocoService.getActiveLang() === 'en'? (program.programDescription):(program.programDescriptionFr),
      }));
    }),
    tap((optionsData) => {
      this.isReady = true;
    })
  );

  constructor(
    public dialogRef: MatDialogRef<ProgramTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {},
    private dataSvc: DataService,
    private clientCaseDataSvc: ClientCaseDataService,
    private translocoService: TranslocoService
  ) {}
  ngOnInit() {}

  clicked(evt: any) {
    this.dialogRef.close(true);
  }
}
