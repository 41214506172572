import * as R from 'remeda';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Documents } from '@shared/components/documents/models/documents.model';
import { DocumentsViewType } from '@shared/components/documents/models/document-form.model';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { DocumentsListData, ListConfig } from './documents-datasource';
import { faLock, faLockOpen, faTimesCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CLIENT_MODULE } from '@core/services/navigation.service';
import { Globals } from '../../../../globals';
import { TranslocoService } from '@ngneat/transloco';
import { ModalService } from "@shared/services/modal.service";

export interface DocumentsListConfig {
  showLockIcon?: boolean;
  showDeleteIcon?: boolean;
  showSelection?: boolean;
}

@Component({
  selector: 'cf2-documents-list',
  template: `
    <mat-table [dataSource]="filteredData" matSort aria-label="Documnent List">
      <!-- column definitions -->
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
        <mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          [disabled]="!columnConfig[i].sort"
          [class.is-select-column]="columnConfig[i].key === 'select'"
          [class.is-icon-column]="
            columnConfig[i].key === 'icon' || columnConfig[i].key === 'isLocked' || columnConfig[i].key === 'deleteIcon'
          "
        >
          <ng-container *ngIf="columnConfig[i].key !== 'select'">
            {{ columnConfig[i].label }}
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'select'">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </ng-container>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let cell; index as j"
          [class.is-linkable]="columnConfig[i].linkable"
          [class.is-select-column]="columnConfig[i].key === 'select'"
          [class.is-icon-column]="
            columnConfig[i].key === 'icon' || columnConfig[i].key === 'isLocked' || columnConfig[i].key === 'deleteIcon'
          "
        >
          <ng-container *ngIf="columnConfig[i].key === 'select'">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectionChanged($event, cell) : null"
              [checked]="selection.isSelected(cell)"
            >
            </mat-checkbox>
          </ng-container>
          <ng-container
            *ngIf="
              columnConfig[i].key !== 'select' &&
              columnConfig[i].key !== 'icon' &&
              columnConfig[i].key !== 'isLocked' &&
              columnConfig[i].key !== 'documentTypeDescription'
            "
          >
            {{ cell[column] }}
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'icon'">
            <fa-icon [icon]="cell[column]"></fa-icon>
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'deleteIcon'">
            <a *ngIf="!isReadOnly" href="javascript: void(0);" aria-label="Delete document" (click)="deleteDocument(cell.parentDocumentStoreKey)">
              <fa-icon [icon]="deleteIcon" class="red"></fa-icon>
            </a>
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'isLocked'">
            <div class="lock-icon">
              <a href="javascript: void(0);" [attr.aria-label]="cell['isLink']?'View Document':'Edit Document'" (click)="viewEditDocument(cell.parentDocumentStoreKey)">
                <ng-container *ngIf="cell['isLink']">
                  <fa-icon [icon]="linkIcon"></fa-icon>
                </ng-container>
                <ng-container *ngIf="!cell['isLink']">
                  <fa-icon [icon]="lockOpenIcon" *ngIf="!cell[column]"></fa-icon>
                  <fa-icon
                    [icon]="lockIcon"
                    *ngIf="cell[column]"
                    (click)="viewEditDocument(cell.parentDocumentStoreKey)"
                  ></fa-icon>
                </ng-container>
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'documentTypeDescription'">
            <!-- keys >=1 are generated in the backend. Keys < 1 are generated temporarily in the front-end -->
            <span *ngIf="cell.parentDocumentStoreKey < 1">{{ cell[column] }}</span>
            <a *ngIf="cell.parentDocumentStoreKey >= 1" (click)="downloadDoc(cell.parentDocumentStoreKey)">{{
              cell[column]
            }}</a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Header Row-->
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

      <!-- Select Record -->
      <mat-row *matRowDef="let cell; columns: columns"></mat-row>
    </mat-table>

    <!-- No data found -->
    <ng-container *ngIf="isReady && filteredData.length < 1">
      <div class="noresult">
        <div class="center">No results found</div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() data: Documents[];
  @Input() isReady = false;
  @Input() filteredData: Documents[];
  @Input() caseKey: number;
  @Input() searchFc?: UntypedFormControl;

  @Input() isReadOnly = false; //

  @Input() view?: DocumentsViewType = 'view';
  // set default values for document list configuration
  _config: DocumentsListConfig = {
    showLockIcon: true, // default show lock icon
    showDeleteIcon: false, // default hides delete icon
    showSelection: false, // default dont show row select checkboxes
  };

  get config() {
    return this._config;
  }

  @Input()
  set config(configObj: DocumentsListConfig) {
    this._config = {
      ...this._config,
      ...configObj,
    };
  }

  // @return number
  @Output() selectedDocument = new EventEmitter<number>();
  // @return Documents[]
  @Output() selectedDocuments = new EventEmitter<Documents[]>();

  @Output() delete = new EventEmitter<number>();

  columnConfig: ListConfig[];
  lockIcon = faLock;
  lockOpenIcon = faLockOpen;

  deleteIcon = faTimesCircle;
  linkIcon = faLink;

  showDeleteIcon = false;
  documentFileName: string;
  fileType: string;

  columns: any;
  sortColumn: string;
  sortDirection: boolean;
  currentPath: string;

  selection = new SelectionModel<Documents>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredData.forEach((row) => this.selection.select(row));

    this.selectedDocuments.emit(this.selection.selected);
  }

  getDocumentsCount() {
    var dCount = 0;
    this.filteredData.forEach((row) => dCount++);
    //return dCount;
    return this.filteredData.length;
  }

  selectionChanged(event, cell) {
    this.selection.toggle(cell);
    this.selectedDocuments.emit(this.selection.selected);
  }

  selected(key: number) {
    return this.dataSvc.downloadLink(key);
  }

  viewEditDocument(key: number) {
    // #TODO: use something else other than current router path
    if ((this.currentPath && this.currentPath === 'new') || this.currentPath === 'view') {
      this.selectedDocument.emit(key);
    } else {
      this.viewDoc(key);
    }
  }

  viewDoc(key: number) {
    this.setDocumentKey(key);
    this.router.navigate(['view'], { relativeTo: this.route });
  }

  downloadDoc(key: number) {
    this.dataSvc.getDocumentInfo(key)
        .subscribe((response) => {
          if (response.status !== 200) {
          } else {
            this.documentFileName = response.body.documentName;
            let checkFileType = this.documentFileName.split('.').pop();
            //let fileType: string;
            const fileTypes = {
              txt: "text/plain",
              pdf: "application/pdf",
              doc: "application/vnd.ms-word",
              docx: "application/vnd.ms-word",
              xls: "application/vnd.ms-excel",
              png: "image/png",
              jpg: "image/jpeg",
              jpeg: "image/jpeg",
              gif: "image/gif",
              csv: "text/csv"
            };

            this.fileType = fileTypes[checkFileType];

            this.dataSvc.downloadDocument(key)
                .subscribe((response) => {
                  if (response.status !== 200) {
                    this.modalSvc.openDialog({
                      data: {
                        message: this.translocoService.translate('Message.Error.DocumentNotFound'),
                        title: this.translocoService.translate('Error'),
                        buttons: { cancel: { text: this.translocoService.translate('Okay') } },
                      },
                    });
                  } else {
                    const blob = new Blob([response.body], { type: this.fileType });

                    const a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = this.documentFileName;
                    a.click();
                    
                    // Clean up
                    this.documentFileName = "";
                    this.fileType = "";
                  }
                });
          }
        });
  }

  deleteDocument(key: number) {
    this.delete.emit(key);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataSvc: DocumentsDataService,
    private globals: Globals,
    private translocoService: TranslocoService,
    private modalSvc: ModalService
  ) {
    if (this.route?.snapshot?.routeConfig?.path) {
      this.currentPath = this.route.snapshot.routeConfig.path;
    }

    const tableSetup = new DocumentsListData(translocoService);

    this.columnConfig = tableSetup.config;

    this.columns = this.columnConfig.map((column) => column.key);

    //reset document filters when we change modules
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        //if doesn't start with /clients/documents, reset filter
        const sub_module_path =
          CLIENT_MODULE.path +
          '/' +
          CLIENT_MODULE.children.find((child) => {
            return child.key == 'documents';
          }).path;
        if (!val.url.startsWith(sub_module_path) && globals.activeSubModule == 'documents') {
          dataSvc.filters = undefined;
        }
      }
    });
  }

  configureDataTable() {
    // show delete icon
    if (!this.config.showDeleteIcon) {
      this.columnConfig = this.columnConfig.filter((col) => col.key !== 'deleteIcon');
    }

    // show select checkbox
    if (!this.config.showSelection) {
      this.columnConfig = this.columnConfig.filter((col) => col.key !== 'select');
    }

    // show islocked
    if (!this.config.showLockIcon) {
      this.columnConfig = this.columnConfig.filter((col) => col.key !== 'isLocked');
    }

    if (this.isReadOnly) {
      // remove delete and view icons
      this.columnConfig = R.reject(this.columnConfig, (col) => ['deleteIcon', 'isLocked'].indexOf(col.key) !== -1);
    }

    this.columns = this.columnConfig.map((column) => column.key);
  }

  ngOnInit(): void {
    // if (!this.caseKey) throw new Error('No case key set in DocumentsListComponent');

    this.configureDataTable();
    // show recently created at the top
    this.sortData('createDateFormatted', false);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((obs) => {
      this.sortData(obs.active, obs.direction === 'asc');
    });

    if (this.searchFc) {
      this.searchFc.valueChanges.subscribe((obs) => {
        this.updateSearch(obs);
      });
    }
  }

  /**
   * set the document key
   * @param key - set the value of document key
   */
  setDocumentKey(key: number) {
    this.dataSvc.setDocumentKey(key);
  }

  viewDocument(key: number) {
    this.setDocumentKey(key);
    this.router.navigate([`/clients/documents/view`]);
  }

  sortData(column: string, asc: boolean): void {
    const data = this.data;
    const uniqVals = R.uniq(data.map((vals) => vals[column]));

    // avoid re-sorting a column that has one value.
    // TODO: move this out to a sorted value
    const nullChecked = data.map((itm) => ({ ...itm, [column]: itm[column] == null ? '' : itm[column] }));

    if (column === 'documentDateFormatted') {
      const sorted = this.sortResults('documentDate', nullChecked, asc);
      this.sortDirection = asc;
      this.sortColumn = column;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    } else {
      const sorted = this.sortResults(column, nullChecked, asc);
      this.sortColumn = column;
      this.sortDirection = asc;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    }
  }

  sortResults(column: string, data: any[], isAsc = true): any[] {
    return R.sort(data, (a, b) => this.compare(a[column], b[column], isAsc));
  }

  compare(a, b, isAsc): any {
    if (!a) return -1;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a.length < 1 ? -1 : a <= b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  async updateSearch(filterStr: string): Promise<void> {
    const filter = filterStr.toLowerCase();
    const data = this.data;
    const columns = this.columns;
    const filtered = data.filter((row) => {
      const picked = R.pick(row, columns);
      const mapped = R.toPairs(picked).map((val) => val[1]);
      const some = mapped.some((val: string) => {
        if (typeof val === 'string') {
          const test = val.toLowerCase().includes(filter);
          return test;
        } else return val === filter;
      });
      return some;
    });
    this.filteredData = filtered;
  }
}
