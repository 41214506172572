import { Component, ViewChild, ElementRef, Input, AfterViewChecked } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-time-picker-field-top',
  template: `
    <!-- <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    > -->
    <cf2-timepicker-wrapper [ctrl]="ctrl">
      <!-- <mat-form-field appearance="standard" floatLabel="always" #elRef> -->
      <custom-label>
        {{ label | titlecase }} <span *ngIf="required === true" class="required-asterisk">*</span>
      </custom-label>
      <custom-component>
        <kendo-timepicker
          #timeInput
          id="{{ idField }}"
          name="{{ idField }}"
          [format]="'hh:mm a'"
          value="{{ value }}"
          [min]="minTime"
          [max]="maxTime"
          autocomplete="off"
          (blur)="validateTime()"
          (input)="updateTime($event.target.value)"
          (change)="updateTime($event.target.value)"
          [formControl]="ctrl"
        ></kendo-timepicker>
      </custom-component>
      <mat-error *ngIf="hasError(ctrl)">{{ hasCustomError ? errorText : errorMap(ctrl) }}</mat-error>
    </cf2-timepicker-wrapper>
    <!-- </div> -->
  `,
  styleUrls: ['../input-field-top.component.scss'],
})
export class TimePickerFieldTopComponent implements AfterViewChecked {
  @ViewChild('timeInput') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() hasCustomError = false;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() maxTime: Date;
  @Input() minTime: Date;
  @Input() defaultTime = new Date(Date.now());
  @Input() errorText: string;
  @Input() idField: string;
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;

  private trackTimeValue = '';

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
    if (this.maxTime !== undefined) this.updateMax;
    if (this.minTime !== undefined) this.updateMin;
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
    this.trackTimeValue = this.value.toString();
  }

  public updateMax(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.max = this.maxTime.toTimeString();
  }

  public updateMin(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.min = this.minTime.toTimeString();
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }

  public errorMap(fc: UntypedFormControl): string {
    if (fc.errors === undefined) return '';
    if (fc.errors.timeRangeError) {
      const timeValue = fc.errors.timeRangeError.value.toLocaleTimeString();
      if (timeValue < fc.errors.timeRangeError.minValue.toLocaleTimeString()) {
        return 'Before allowable minimum time.';
      } else {
        return 'After allowable maximum time.';
      }
    }
    if (fc.errors.required) return 'This field is required.';

    return 'Invalid Time. Please use format HH:MM AM/PM';
  }

  // pickup numeric keys only
  omitCharacters(event: KeyboardEvent): boolean {
    const k = event.charCode;
    return (k > 47 && k < 58) || k === 45 || k === 47;
  }

  // capture input value
  updateTime(value: string): void {
    this.trackTimeValue = value;
  }

  // validate time on blur
  validateTime(): boolean {
    this.ctrl.setErrors({});

    if (this.ctrl.invalid && this.ctrl.value === null && this.trackTimeValue.length > 0) {
      this.ctrl.setErrors({ invalidDate: true });
      return false;
    } else if (this.ctrl.value === null && this.trackTimeValue.length === 0) {
      this.ctrl.reset();
    }

    if (this.ctrl.value !== null) {
      this.ctrl.setValue(this.ctrl.value);
    }

    return true;
  }

  // validate the time
  public hasError(fc: UntypedFormControl): boolean {
    if (!fc || !fc.invalid) return false;
    if (this.hasCustomError) {
      return fc.invalid && fc.touched;
    }
    return fc.invalid && fc.touched;
  }
}
