import { EapPlanItemModel } from '@api/models/eap-plan-item-model';
import { EapSubGoalModel } from '@api/models/eap-sub-goal-model';
import { EapModel } from '@api/models/eap-model';
import { FormsComponentField } from '@core/models/base-component-field';

export type EapKeysType = keyof EapModel;
export type EapSubGoalKeysType = keyof EapSubGoalModel;
export type EapPlanItemKeysType = keyof EapPlanItemModel | 'planItemDetails';

export type EapKeysLabelsType = Record<EapKeysType, string | boolean>;
export type EapSubGoalLabelsType = Record<EapSubGoalKeysType, string | boolean>;

export type EapPlanItemsLabelType = Record<EapPlanItemKeysType, string | boolean>;

export interface CreateEapComponentField extends FormsComponentField {
  key: EapKeysType;
}

export interface EapSubGoalComponentField extends FormsComponentField {
  key: EapSubGoalKeysType;
}

export interface EapPlanItemComponentField extends FormsComponentField {
  key: EapPlanItemKeysType;
}

export const EAP_FIELDS: readonly CreateEapComponentField[] = [
  {
    key: 'primaryGoal',
    label: 'Primary Employment Goal',
    type: 'string',
    required: true,
  },
  {
    key: 'secondaryGoal',
    label: 'Long Term Goal',
    type: 'string',
    required: false,
  },
  {
    key: 'desiredOccupation',
    label: 'Desired Occupation',
    type: 'string',
    required: false,
  },
  {
    key: 'desiredNocCode',
    label: 'Desired NOC Code',
    type: 'select',
    required: true,
  },
  {
    key: 'parentEapKey',
    label: '',
    type: 'select',
    required: false,
  },
];

export const PLAN_ITEM_FIELDS: readonly EapPlanItemComponentField[] = [
  {
    key: 'planItemCode',
    label: 'Plan Item',
    type: 'select',
  },
  {
    key: 'expectedStartDate',
    label: 'Expected Start',
    type: 'wideDate',
    required: false,
  },
  {
    key: 'expectedEndDate',
    label: 'Expected End',
    type: 'wideDate',
    required: false,
  },
  {
    key: 'expectedCost',
    label: 'Expected Cost',
    type: 'expectedCost',
    required: false,
  },
  {
    key: 'planItemStatusCode',
    label: 'Status',
    type: 'select',
  },
  {
    key: 'actualStartDate',
    label: 'Actual Start',
    type: 'wideDate',
    required: false,
  },
  {
    key: 'actualEndDate',
    label: 'Actual End',
    type: 'wideDate',
    required: false,
  },
  {
    key: 'actualCost',
    label: 'Actual Cost',
    type: 'select',
    required: false,
  },
  {
    key: 'hstAmount',
    label: 'HST Amount',
    type: 'select',
    required: false,
  },
  {
    key: 'employerBusinessKey2',
    label: 'Employer',
    type: 'select',
    required: false,
  },
  {
    key: 'nocCode',
    label: 'NOC',
    type: 'select',
    required: false,
  },
  {
    key: 'naicsCode',
    label: 'NAICS',
    type: 'select',
    required: false,
  },
  {
    key: 'hourlyPay',
    label: 'Hourly rate',
    type: 'select',
    required: false,
  },
  {
    key: 'planItemOutcomeCode',
    label: 'Outcome',
    type: 'select',
    required: false,
  },
  {
    key: 'comment',
    label: 'Description',
    type: 'string',
    required: false,
  },
  {
    key: 'readyForReview',
    label: 'Ready for review',
    type: 'checkbox',
    required: false,
  },
  /* TODO: identify what the label should be for otherDescription */
  {
    key: 'otherDescription',
    label: 'Plan Item Name',
    type: 'string',
    required: false,
  },
  {
    key: 'planItemDetails',
    label: '',
    type: 'select',
    required: false,
  },
  {
    key: 'parentEapPlanItemKey',
    label: '',
    type: 'select',
    required: false,
  },
];

export const SUB_GOAL_FIELD: FormsComponentField = {
  label: 'Sub-Goal',
  key: 'subgoal',
  type: 'string',
};