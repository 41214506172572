// core angular modules
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// config

// Vendor Modules

// App Modules
import { AppRouterModule } from './app.routes';

// components
import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { HomeModule } from '@modules/home/home.module';
import { CommonModule } from '@angular/common';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NativeDateModule } from '@angular/material/core';
import { AppInjector } from '@core/services/app-injector.service';
import { NavigationComponent } from '@core/navigation/navigation.component';
import { Cf2MaterialModule } from '@modules/cf2-material/cf2-material.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';

const ngxMaskOptions: Partial<IConfig> = { validation: false };

@NgModule({
  declarations: [AppComponent, NavigationComponent],
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    Cf2MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    HomeModule,
    NgxMaskModule.forRoot(ngxMaskOptions),
    NativeDateModule,
    HttpClientModule,
    TranslocoRootModule,
  ],
  // providers: [
  //   { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  //   { provide: DateAdapter, useClass: CustomDatePickerAdapter },
  // ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector.injector = injector;
  }
}
