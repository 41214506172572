import { Directive, Host, Input, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormsComponentField } from '@core/models/base-component-field';
import { FieldTypeRecord } from '@core/models/cf2-forms.model';
import { Cf2Validators } from '@core/models/cf2-validators.model';
import { FormsFns } from '@core/models/forms-fns.model';
import { RxFormControl, RxwebValidators } from '@rxweb/reactive-form-validators';
import * as R from 'remeda';
@Directive({
  selector: '[cf2Field]',
  providers: [
    { provide: Cf2Validators, useClass: Cf2Validators, multi: false },
    { provide: RxFormControl, useValue: RxFormControl },
  ],
})
export class FieldDirective implements OnDestroy {
  fieldTypeMap: FieldTypeRecord = {
    birthdate: () => this.vFns.birthdateValidators.bind(this),
    cams: () => this.vFns.camsValidators,
    checkbox: () => () => [],
    confirmation: () => this.vFns.confirmationValidator,
    cra: () => this.vFns.craValidators,
    currency: () => this.vFns.currencyValidators,
    date: () => this.vFns.dateValidators,
    display: () => () => [],
    email: () => this.vFns.emailValidators,
    expectedCost: () => this.vFns.expectedCost,
    idValidated: () => this.vFns.idValidated,
    longString: (options) => this.vFns.longStringValidators(options),
    midLongString: (options) => this.vFns.midLongStringValidators(options),
    midString: (options) => this.vFns.midStringValidators(options),
    name: () => this.vFns.nameValidators,
    pastDate: () => this.vFns.pastDateValidators,
    phone: () => this.vFns.phoneValidator,
    postal: () => this.vFns.postalValidators,
    select: () => () => [],
    sin: () => this.vFns.sinValidator,
    sms: () => this.vFns.smsRequired,
    otherLanguage: () => this.vFns.otherLanguage,
    string: (options) => this.vFns.stringValidators(options),
    wideDate: () => this.vFns.wideDateValidators.bind(this),
    futureDate: () => this.vFns.dateValidators,
    time: () => this.vFns.timeValidators,
  };
  required: boolean;
  field: FormsComponentField;
  // @Input() set cf2Field(field: FormsComponentField) {
  //   const { required, type, label, disabled } = field;
  //   this.type = type;
  //   this.isRequired = required;
  // }
  @Input() set cf2Field(field: FormsComponentField) {
    this.field = field;
  }

  @Input() set isRequired(bool: boolean) {
    this.required = bool;
    this.field ? this.toggleField() : R.noop();
  }

  // @Input() ctrl: FormControl;
  toggleField() {
    const base = this.fieldTypeMap[this.field.type]({})();
    const validators = this.required
      ? RxwebValidators.compose({ validators: base.concat(RxwebValidators.required()) })
      : RxwebValidators.compose({ validators: base });
    this.ctrl.control.setValidators(validators);
    this.ctrl.control.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.ctrl.control.parent.updateValueAndValidity(FormsFns.formUpdateOpts);
  }
  constructor(@Host() public ctrl: NgControl, private vFns: Cf2Validators) { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ctrl.control.setValidators([]);
    this.ctrl.control.updateValueAndValidity(FormsFns.formUpdateOpts);
  }
}
