import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

import { BaseComponent } from '@shared/components/base/base.component';

@Component({
  selector: 'cf2-redirect',
  template: `...redirecting`,
  styleUrls: ['./redirect.component.scss'],
  providers: [],
})
export class RedirectComponent extends BaseComponent implements OnInit {
  constructor(
    // @Inject(CF2_AUTH_REQUEST) private requestCfg: Cf2AuthRequestType,
    // @Inject(REDIRECT_TOKEN) private isRedirect: Cf2AuthRequestType,

    private msalService: MsalService
  ) {
    // console.log('inits', requestCfg);
    super();
  }

  async ngOnInit() {
    const isLoggedIn = !!this.msalService.instance.getActiveAccount();

    if (!isLoggedIn) {
      // this.authSvc.login();
    } else {
      // this.authSvc.init();
      // this.navigate('home', {});
    }
  }
}
