import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputFieldTopComponent } from '../label-top/input-field-top/input-field-top.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { ClientService } from '@api/services';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ValidatorFns } from '@core/models/cf2-validators.model';

/** Component type ahead searchs for clients contain values entered
 *
 * ctrl - must be set to a FormControl which will represent the parentClientKey
 * siteKey - if set will search the API and filter only those clients associated with
 * the site
 *
 * When text is entered into the input, it will call the API for Client's name containing
 * entered value.  And an option is selected the parentClientKey is set to the value of
 * 'ctrl' and the parentCaseKey is set to the value of 'parentCaseKeyCtrl' if a FormControl
 * has been defined
 *
 * if ctrl.value contains a parentClientKey it will lookup the client.displayName
 * and set the visible input control to return value
 */
@Component({
  selector: 'cf2-client-name-lookup',
  template: `
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onNamedSelected($event.option)">
      <mat-option *ngFor="let option of foundOptions | async" [value]="option.value" [displayWith]="displayFn">
        {{ option.description }}
      </mat-option>
    </mat-autocomplete>

    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'typeahead-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small,
        'full-width-no-left-margin': fullWidth && noLeftMargin
      }"
      class="typeahead-field-top auto-complete"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }}
          <span *ngIf="clientNameRequired === true && labelVisible === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          type="text"
          [placeholder]="placeholder"
          aria-label="Number"
          matInput
          [formControl]="searchControl"
          [matAutocomplete]="auto"
          value=""
          #autoComplete
        />
        <button
          mat-button
          *ngIf="ctrl.value && !disabled"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearValue()"
        >
          <mat-icon>close</mat-icon>
        </button>

        <mat-error *ngIf="ctrl && ctrl['errorMessage']"> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./client-name-lookup.component.scss'],
})
export class ClientNameLookupComponent extends InputFieldTopComponent implements OnInit {
  /* ctrl - set to a control tied to parentClientKey */
  @Input() ctrl: UntypedFormControl;
  /* ctrl - set to a control tied to parentCaseKey */
  @Input() parentCaseKeyCtrl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() siteKey: number;
  @Input() ignoreCaseKeys: number[] = [];
  @Output() clientSelected = new EventEmitter<number>();

  @Input() clientNameRequired = false;

  foundOptions: Observable<{ description: string; value: string }[]>;
  currentResults: Observable<{ description: string; value: string; parentCaseKey: number }[]>;
  searchControl;

  constructor(private clientSvc: ClientService) {
    super();
  }

  ngOnInit(): void {
    if (!this.ctrl) {
      console.log('cf2-client-name-lookup lookup has not been passed a control');
    }
    if (this.ctrl.value && this.ctrl.value > 0) {
      this.getClientName();
    }
    this.searchControl = new UntypedFormControl(
      '',
      this.required ? [Validators.required, ValidatorFns.lengthValidator()(3)] : []
    );

    // this.searchControl = this.ctrl;
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        map((value: string) => this._search(value))
      )
      .subscribe((result) => {
        this.currentResults = result;
        this.foundOptions = result;
      });
  }
  displayFn(option: { description: string; value: string }) {
    return option.description;
  }

  clearValue() {
    this.ctrl.patchValue('');
    this.searchControl.patchValue('');
  }

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value?.length > 0) this.updateValue();
    if (this.disabled) this.ctrl.disable();
  }

  private _search(value: string) {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    const results = this.clientSvc
      .apiClientTypeaheadGet$Json({
        clientName: filterValue,
        parentSiteKey: this.siteKey,
      })
      .pipe(
        map((result) =>
          result
            .filter((r) => !this.ignoreCaseKeys.includes(r.parentCaseKey))
            .map((opt) => ({
              description: opt.displayName,
              value: opt.parentClientKey?.toString(),
              parentCaseKey: opt.parentCaseKey,
            }))
        )
      );
    return results;
  }

  onNamedSelected(selected: any) {
    this.searchControl.patchValue(selected.viewValue);
    if (this.ctrl !== undefined && this.ctrl) this.ctrl.patchValue(+selected.value);
    this.clientSelected.emit(+selected.value);
    if (this.parentCaseKeyCtrl !== undefined && this.parentCaseKeyCtrl) {
      this.currentResults.subscribe((result) => {
        const lookup = result.find((r) => r.value === selected.value);
        this.parentCaseKeyCtrl.patchValue(lookup.parentCaseKey);
      });
    }
  }

  getClientName() {
    if (this.ctrl.value) {
      this.clientSvc
        .apiClientParentClientKeyGet$Json({
          parentClientKey: this.ctrl.value,
        })
        .subscribe((result) => this.searchControl.patchValue(result.displayName));
    }
  }
}
