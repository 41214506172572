/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeModel } from '../models/employee-model';
import { EmployeeUserModel } from '../models/employee-user-model';
import { OrganizationAreaModel } from '../models/organization-area-model';
import { OrganizationModel } from '../models/organization-model';
import { ProcessResponse } from '../models/process-response';
import { SiteAttributeModel } from '../models/site-attribute-model';
import { SiteModel } from '../models/site-model';
import { SiteTargetModel } from '../models/site-target-model';
import { SiteContractType } from '@api/models/site-contract-type-model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiOrganizationSubregionParentOrganizationalUnitKeyDelete
   */
  static readonly ApiOrganizationSubregionParentOrganizationalUnitKeyDeletePath = '/api/Organization/subregion/{parentOrganizationalUnitKey}';

  /**
   * Delete a sub-region.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSubregionParentOrganizationalUnitKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSubregionParentOrganizationalUnitKeyDelete$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSubregionParentOrganizationalUnitKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a sub-region.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSubregionParentOrganizationalUnitKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSubregionParentOrganizationalUnitKeyDelete$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<ProcessResponse> {

    return this.apiOrganizationSubregionParentOrganizationalUnitKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOrganizationOrganizationParentOrganizationalUnitKeyDelete
   */
  static readonly ApiOrganizationOrganizationParentOrganizationalUnitKeyDeletePath = '/api/Organization/organization/{parentOrganizationalUnitKey}';

  /**
   * Delete a service delivery organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationOrganizationParentOrganizationalUnitKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeyDelete$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationOrganizationParentOrganizationalUnitKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a service delivery organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationOrganizationParentOrganizationalUnitKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeyDelete$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<ProcessResponse> {

    return this.apiOrganizationOrganizationParentOrganizationalUnitKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyGet
   */
  static readonly ApiOrganizationSiteParentSiteKeyGetPath = '/api/Organization/site/{parentSiteKey}';

  /**
   * Get a single site record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyGet$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<SiteModel>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SiteModel>;
      })
    );
  }

  /**
   * Get a single site record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyGet$Json(params: {
    parentSiteKey: number;

  }): Observable<SiteModel> {

    return this.apiOrganizationSiteParentSiteKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SiteModel>) => r.body as SiteModel)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyDelete
   */
  static readonly ApiOrganizationSiteParentSiteKeyDeletePath = '/api/Organization/site/{parentSiteKey}';

  /**
   * Delete a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyDelete$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyDelete$Json(params: {
    parentSiteKey: number;

  }): Observable<ProcessResponse> {

    return this.apiOrganizationSiteParentSiteKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete
   */
  static readonly ApiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDeletePath = '/api/Organization/site/{parentSiteKey}/attributes/{parentSiteAttributeKey}';

  /**
   * Delete a site attribute.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete$Json$Response(params: {
    parentSiteKey: number;
    parentSiteAttributeKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.path('parentSiteAttributeKey', params.parentSiteAttributeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a site attribute.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete$Json(params: {
    parentSiteKey: number;
    parentSiteAttributeKey: number;

  }): Observable<ProcessResponse> {

    return this.apiOrganizationSiteParentSiteKeyAttributesParentSiteAttributeKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteTargetParentSiteTargetKeyDelete
   */
  static readonly ApiOrganizationSiteTargetParentSiteTargetKeyDeletePath = '/api/Organization/siteTarget/{parentSiteTargetKey}';

  /**
   * Delete a site target.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteTargetParentSiteTargetKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteTargetParentSiteTargetKeyDelete$Json$Response(params: {
    parentSiteTargetKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteTargetParentSiteTargetKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentSiteTargetKey', params.parentSiteTargetKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a site target.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteTargetParentSiteTargetKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteTargetParentSiteTargetKeyDelete$Json(params: {
    parentSiteTargetKey: number;

  }): Observable<ProcessResponse> {

    return this.apiOrganizationSiteTargetParentSiteTargetKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOrganizationRegionGet
   */
  static readonly ApiOrganizationRegionGetPath = '/api/Organization/region';

  /**
   * Get a list of all regions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationRegionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionGet$Json$Response(params): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationRegionGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * Get a list of all regions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationRegionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionGet$Json(params?: {

  }): Observable<Array<OrganizationModel>> {

    return this.apiOrganizationRegionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationSubregionGet
   */
  static readonly ApiOrganizationSubregionGetPath = '/api/Organization/subregion';

  /**
   * Get a list of all sub-regions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSubregionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSubregionGet$Json$Response(params?: {
    parentOrganizationalUnitKey?: number;

  }): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSubregionGetPath, 'get');
    if (params) {

      rb.query('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * Get a list of all sub-regions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSubregionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSubregionGet$Json(params?: {
    parentOrganizationalUnitKey?: number;

  }): Observable<Array<OrganizationModel>> {

    return this.apiOrganizationSubregionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationOrganizationGet
   */
  static readonly ApiOrganizationOrganizationGetPath = '/api/Organization/organization';

  /**
   * Get a list of all service delivery organizations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationOrganizationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationGet$Json$Response(params?: {
    parentOrganizationalUnitKey?: number;

  }): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationOrganizationGetPath, 'get');
    if (params) {

      rb.query('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * Get a list of all service delivery organizations.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationOrganizationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationGet$Json(params?: {
    parentOrganizationalUnitKey?: number;

  }): Observable<Array<OrganizationModel>> {

    return this.apiOrganizationOrganizationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet
   */
  static readonly ApiOrganizationOrganizationParentOrganizationalUnitKeySiteGetPath = '/api/Organization/organization/{parentOrganizationalUnitKey}/site';

  /**
   * Get a list of all sites for a service delivery organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<Array<SiteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationOrganizationParentOrganizationalUnitKeySiteGetPath, 'get');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteModel>>;
      })
    );
  }

  /**
   * Get a list of all sites for a service delivery organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<Array<SiteModel>> {

    return this.apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteModel>>) => r.body as Array<SiteModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationOrganizationParentOrganizationalUnitKeySitePost
   */
  static readonly ApiOrganizationOrganizationParentOrganizationalUnitKeySitePostPath = '/api/Organization/organization/{parentOrganizationalUnitKey}/site';

  /**
   * Update or Create a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationOrganizationParentOrganizationalUnitKeySitePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeySitePost$Json$Response(params: {
    parentOrganizationalUnitKey: number;
      body?: SiteModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationOrganizationParentOrganizationalUnitKeySitePostPath, 'post');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update or Create a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationOrganizationParentOrganizationalUnitKeySitePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationOrganizationParentOrganizationalUnitKeySitePost$Json(params: {
    parentOrganizationalUnitKey: number;
      body?: SiteModel
  }): Observable<string> {

    return this.apiOrganizationOrganizationParentOrganizationalUnitKeySitePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyEmployeesGet
   */
  static readonly ApiOrganizationSiteParentSiteKeyEmployeesGetPath = '/api/Organization/site/{parentSiteKey}/employees';

  /**
   * Get a list of all employees associated with a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyEmployeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyEmployeesGet$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<Array<EmployeeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyEmployeesGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeModel>>;
      })
    );
  }

  /**
   * Get a list of all employees associated with a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyEmployeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyEmployeesGet$Json(params: {
    parentSiteKey: number;

  }): Observable<Array<EmployeeModel>> {

    return this.apiOrganizationSiteParentSiteKeyEmployeesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeModel>>) => r.body as Array<EmployeeModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationEmployeesGet
   */
  static readonly ApiOrganizationEmployeesGetPath = '/api/Organization/employees';

  /**
   * Get a list of all employees associated with a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationEmployeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationEmployeesGet$Json$Response(params?: {

    /**
     * optional filter by org key
     */
    parentOrganizationKey?: null | number;

    /**
     * optional filter by site key
     */
    parentSiteKey?: null | number;

  }): Observable<StrictHttpResponse<Array<EmployeeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationEmployeesGetPath, 'get');
    if (params) {

      rb.query('parentOrganizationKey', params.parentOrganizationKey, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeModel>>;
      })
    );
  }

  /**
   * Get a list of all employees associated with a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationEmployeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationEmployeesGet$Json(params?: {

    /**
     * optional filter by org key
     */
    parentOrganizationKey?: null | number;

    /**
     * optional filter by site key
     */
    parentSiteKey?: null | number;

  }): Observable<Array<EmployeeModel>> {

    return this.apiOrganizationEmployeesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeModel>>) => r.body as Array<EmployeeModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyAttributesGet
   */
  static readonly ApiOrganizationSiteParentSiteKeyAttributesGetPath = '/api/Organization/site/{parentSiteKey}/attributes';

  /**
   * Get a list of all attributes associated with a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyAttributesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyAttributesGet$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<Array<SiteAttributeModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyAttributesGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteAttributeModel>>;
      })
    );
  }

  /**
   * Get a list of all attributes associated with a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyAttributesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyAttributesGet$Json(params: {
    parentSiteKey: number;

  }): Observable<Array<SiteAttributeModel>> {

    return this.apiOrganizationSiteParentSiteKeyAttributesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteAttributeModel>>) => r.body as Array<SiteAttributeModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyAttributesPost
   */
  static readonly ApiOrganizationSiteParentSiteKeyAttributesPostPath = '/api/Organization/site/{parentSiteKey}/attributes';

  /**
   * Create a site attribute.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyAttributesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteParentSiteKeyAttributesPost$Json$Response(params: {
    parentSiteKey: number;
      body?: SiteAttributeModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyAttributesPostPath, 'post');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a site attribute.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyAttributesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteParentSiteKeyAttributesPost$Json(params: {
    parentSiteKey: number;
      body?: SiteAttributeModel
  }): Observable<string> {

    return this.apiOrganizationSiteParentSiteKeyAttributesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyTargetsGet
   */
  static readonly ApiOrganizationSiteParentSiteKeyTargetsGetPath = '/api/Organization/site/{parentSiteKey}/targets';

  /**
   * Get a list of all targets associated with a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyTargetsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyTargetsGet$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<Array<SiteTargetModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyTargetsGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteTargetModel>>;
      })
    );
  }

  /**
   * Get a list of all targets associated with a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyTargetsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteParentSiteKeyTargetsGet$Json(params: {
    parentSiteKey: number;

  }): Observable<Array<SiteTargetModel>> {

    return this.apiOrganizationSiteParentSiteKeyTargetsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteTargetModel>>) => r.body as Array<SiteTargetModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationRegionParentOrganizationalUnitKeySubregionGet
   */
  static readonly ApiOrganizationRegionParentOrganizationalUnitKeySubregionGetPath = '/api/Organization/region/{parentOrganizationalUnitKey}/subregion';

  /**
   * Get a list of all subregions within a region.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationRegionParentOrganizationalUnitKeySubregionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySubregionGet$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationRegionParentOrganizationalUnitKeySubregionGetPath, 'get');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * Get a list of all subregions within a region.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationRegionParentOrganizationalUnitKeySubregionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySubregionGet$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<Array<OrganizationModel>> {

    return this.apiOrganizationRegionParentOrganizationalUnitKeySubregionGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationRegionParentOrganizationalUnitKeySubregionPost
   */
  static readonly ApiOrganizationRegionParentOrganizationalUnitKeySubregionPostPath = '/api/Organization/region/{parentOrganizationalUnitKey}/subregion';

  /**
   * Update or Create a sub-region.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationRegionParentOrganizationalUnitKeySubregionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySubregionPost$Json$Response(params: {
    parentOrganizationalUnitKey: number;
      body?: OrganizationModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationRegionParentOrganizationalUnitKeySubregionPostPath, 'post');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update or Create a sub-region.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationRegionParentOrganizationalUnitKeySubregionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySubregionPost$Json(params: {
    parentOrganizationalUnitKey: number;
      body?: OrganizationModel
  }): Observable<string> {

    return this.apiOrganizationRegionParentOrganizationalUnitKeySubregionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet
   */
  static readonly ApiOrganizationRegionParentOrganizationalUnitKeyOrganizationGetPath = '/api/Organization/region/{parentOrganizationalUnitKey}/organization';

  /**
   * Get a list of all service delivery organizations within a region (plus parents).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<Array<OrganizationModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationRegionParentOrganizationalUnitKeyOrganizationGetPath, 'get');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationModel>>;
      })
    );
  }

  /**
   * Get a list of all service delivery organizations within a region (plus parents).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<Array<OrganizationModel>> {

    return this.apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationModel>>) => r.body as Array<OrganizationModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationRegionParentOrganizationalUnitKeySiteGet
   */
  static readonly ApiOrganizationRegionParentOrganizationalUnitKeySiteGetPath = '/api/Organization/region/{parentOrganizationalUnitKey}/site';

  /**
   * Get a list of all sites within a region (plus parents).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json$Response(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<StrictHttpResponse<Array<SiteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationRegionParentOrganizationalUnitKeySiteGetPath, 'get');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteModel>>;
      })
    );
  }

  /**
   * Get a list of all sites within a region (plus parents).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json(params: {
    parentOrganizationalUnitKey: number;

  }): Observable<Array<SiteModel>> {

    return this.apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteModel>>) => r.body as Array<SiteModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationAreasAreaNameGet
   */
  static readonly ApiOrganizationAreasAreaNameGetPath = '/api/Organization/areas/{areaName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationAreasAreaNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationAreasAreaNameGet$Json$Response(params: {
    areaName: null | string;

  }): Observable<StrictHttpResponse<Array<SiteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationAreasAreaNameGetPath, 'get');
    if (params) {

      rb.path('areaName', params.areaName, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationAreasAreaNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationAreasAreaNameGet$Json(params: {
    areaName: null | string;

  }): Observable<Array<SiteModel>> {

    return this.apiOrganizationAreasAreaNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteModel>>) => r.body as Array<SiteModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationAreasGet
   */
  static readonly ApiOrganizationAreasGetPath = '/api/Organization/areas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationAreasGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationAreasGet$Json$Response(params): Observable<StrictHttpResponse<Array<OrganizationAreaModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationAreasGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationAreaModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationAreasGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationAreasGet$Json(params?: {

  }): Observable<Array<OrganizationAreaModel>> {

    return this.apiOrganizationAreasGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OrganizationAreaModel>>) => r.body as Array<OrganizationAreaModel>)
    );
  }

  /**
   * Path part for operation apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost
   */
  static readonly ApiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPostPath = '/api/Organization/subregion/{parentOrganizationalUnitKey}/organization';

  /**
   * Update or Create a service delivery organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost$Json$Response(params: {
    parentOrganizationalUnitKey: number;
      body?: OrganizationModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPostPath, 'post');
    if (params) {

      rb.path('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update or Create a service delivery organization.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost$Json(params: {
    parentOrganizationalUnitKey: number;
      body?: OrganizationModel
  }): Observable<string> {

    return this.apiOrganizationSubregionParentOrganizationalUnitKeyOrganizationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteTargetParentSiteKeyPost
   */
  static readonly ApiOrganizationSiteTargetParentSiteKeyPostPath = '/api/Organization/siteTarget/{parentSiteKey}';

  /**
   * Create a site target.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteTargetParentSiteKeyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteTargetParentSiteKeyPost$Json$Response(params: {
    parentSiteKey: number;
      body?: SiteTargetModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteTargetParentSiteKeyPostPath, 'post');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a site target.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteTargetParentSiteKeyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteTargetParentSiteKeyPost$Json(params: {
    parentSiteKey: number;
      body?: SiteTargetModel
  }): Observable<string> {

    return this.apiOrganizationSiteTargetParentSiteKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteParentSiteKeyEmployeePost
   */
  static readonly ApiOrganizationSiteParentSiteKeyEmployeePostPath = '/api/Organization/site/{parentSiteKey}/employee';

  /**
   * Create or Update an employee record (plus user).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteParentSiteKeyEmployeePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteParentSiteKeyEmployeePost$Json$Response(params: {
    parentSiteKey: number;
      body?: EmployeeUserModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteParentSiteKeyEmployeePostPath, 'post');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create or Update an employee record (plus user).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteParentSiteKeyEmployeePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOrganizationSiteParentSiteKeyEmployeePost$Json(params: {
    parentSiteKey: number;
      body?: EmployeeUserModel
  }): Observable<string> {

    return this.apiOrganizationSiteParentSiteKeyEmployeePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOrganizationSiteContractTypesGet
   */
  static readonly ApiOrganizationSiteContractTypesGetPath = '/api/Organization/contractType/{siteKey}';

  /**
   * Get a list of contract types for a site
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrganizationSiteContractTypesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteContractTypesGet$Json$Response(params: {
    siteKey: number;
  }): Observable<StrictHttpResponse<Array<SiteContractType>>> {

    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ApiOrganizationSiteContractTypesGetPath, 'get');
    if (params) {

      rb.path('siteKey', params.siteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteContractType>>;
      })
    );
  }

  /**
   * Get a list of contract types for a site
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOrganizationSiteContractTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrganizationSiteContractTypeGet$Json(params?: {

    /**
     * optional filter by site key
     */
    siteKey: null | number;

  }): Observable<Array<SiteContractType>> {

    return this.apiOrganizationSiteContractTypesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteContractType>>) => r.body as Array<SiteContractType>)
    );
  }
}
