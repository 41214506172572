/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseloadModel } from '../models/caseload-model';
import { ClientCaseModel } from '../models/client-case-model';
import { MeetingAttendeeModel } from '../models/meeting-attendee-model';
import { MeetingModel } from '../models/meeting-model';
import { MeetingOccurrenceModel } from '../models/meeting-occurrence-model';
import { MeetingSubjectTypes } from '../models/meeting-subject-types';
import { ProcessResponse } from '../models/process-response';
import { SiteRoomModel } from '../models/site-room-model';

@Injectable({
  providedIn: 'root',
})
export class SchedulingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSchedulingParentMeetingKeyGet
   */
  static readonly ApiSchedulingParentMeetingKeyGetPath = '/api/Scheduling/{parentMeetingKey}';

  /**
   * Get a meeting record (parent meeting record only).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyGet$Json$Response(params: {
    parentMeetingKey: number;

  }): Observable<StrictHttpResponse<MeetingModel>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingKeyGetPath, 'get');
    if (params) {

      rb.path('parentMeetingKey', params.parentMeetingKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeetingModel>;
      })
    );
  }

  /**
   * Get a meeting record (parent meeting record only).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyGet$Json(params: {
    parentMeetingKey: number;

  }): Observable<MeetingModel> {

    return this.apiSchedulingParentMeetingKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeetingModel>) => r.body as MeetingModel)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingKeyDelete
   */
  static readonly ApiSchedulingParentMeetingKeyDeletePath = '/api/Scheduling/{parentMeetingKey}';

  /**
   * Delete a meeting (plus future meeting occurrence records).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyDelete$Json$Response(params: {
    parentMeetingKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentMeetingKey', params.parentMeetingKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a meeting (plus future meeting occurrence records).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyDelete$Json(params: {
    parentMeetingKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete
   */
  static readonly ApiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDeletePath = '/api/Scheduling/{parentSiteKey}/rooms/{parentSiteRoomKey}';

  /**
   * Delete a meeting room.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete$Json$Response(params: {
    parentSiteKey: number;
    parentSiteRoomKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.path('parentSiteRoomKey', params.parentSiteRoomKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a meeting room.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete$Json(params: {
    parentSiteKey: number;
    parentSiteRoomKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentSiteKeyRoomsParentSiteRoomKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingAttendeeParentMeetingAttendeeKeyDelete
   */
  static readonly ApiSchedulingAttendeeParentMeetingAttendeeKeyDeletePath = '/api/Scheduling/attendee/{parentMeetingAttendeeKey}';

  /**
   * Logically delete an attendee from a MeetingOccurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingAttendeeParentMeetingAttendeeKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingAttendeeParentMeetingAttendeeKeyDelete$Json$Response(params: {
    parentMeetingAttendeeKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingAttendeeParentMeetingAttendeeKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentMeetingAttendeeKey', params.parentMeetingAttendeeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Logically delete an attendee from a MeetingOccurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingAttendeeParentMeetingAttendeeKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingAttendeeParentMeetingAttendeeKeyDelete$Json(params: {
    parentMeetingAttendeeKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingAttendeeParentMeetingAttendeeKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingAttendeeDelete
   */
  static readonly ApiSchedulingAttendeeDeletePath = '/api/Scheduling/attendee';

  /**
   * Logically delete a list attendees from a MeetingOccurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingAttendeeDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeDelete$Json$Response(params?: {
    body?: null | Array<number>
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingAttendeeDeletePath, 'delete');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Logically delete a list attendees from a MeetingOccurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingAttendeeDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeDelete$Json(params?: {
    body?: null | Array<number>
  }): Observable<ProcessResponse> {

    return this.apiSchedulingAttendeeDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet
   */
  static readonly ApiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGetPath = '/api/Scheduling/meetingOccurrence/{parentMeetingOccurrenceKey}';

  /**
   * Get a meeting occurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json$Response(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<StrictHttpResponse<MeetingOccurrenceModel>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGetPath, 'get');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeetingOccurrenceModel>;
      })
    );
  }

  /**
   * Get a meeting occurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<MeetingOccurrenceModel> {

    return this.apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeetingOccurrenceModel>) => r.body as MeetingOccurrenceModel)
    );
  }

  /**
   * Path part for operation apiSchedulingMeetingOccurrenceGet
   */
  static readonly ApiSchedulingMeetingOccurrenceGetPath = '/api/Scheduling/meetingOccurrence';

  /**
   * Get Meeting Occurence meeting passed filter parameters
   *             
   * A call must have either parentSiteKey or parentOrganizationalUnitKey otherwise
   * no results will be returned.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingMeetingOccurrenceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrenceGet$Json$Response(params?: {
    parentSiteKey?: null | Array<number>;
    parentEmployeeKey?: null | Array<number>;
    meetingCategoryType?: null | Array<string>;
    meetingSubjectType?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;
    parentOrganizationalUnitKey?: null | Array<number>;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingMeetingOccurrenceGetPath, 'get');
    if (params) {
      const employeeArray = encodeURIComponent(JSON.stringify(params.parentEmployeeKey));
      rb.query('parentSiteKey', params.parentSiteKey, {});
      rb.query('parentEmployeeKey', employeeArray, {});
      rb.query('meetingCategoryType', params.meetingCategoryType, {});
      rb.query('meetingSubjectType', params.meetingSubjectType, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('parentOrganizationalUnitKey', params.parentOrganizationalUnitKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get Meeting Occurence meeting passed filter parameters
   *             
   * A call must have either parentSiteKey or parentOrganizationalUnitKey otherwise
   * no results will be returned.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingMeetingOccurrenceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrenceGet$Json(params?: {
    parentSiteKey?: null | Array<number>;
    parentEmployeeKey?: null | Array<number>;
    meetingCategoryType?: null | Array<string>;
    meetingSubjectType?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;
    parentOrganizationalUnitKey?: null | Array<number>;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingMeetingOccurrenceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingMeetingOccurrencePost
   */
  static readonly ApiSchedulingMeetingOccurrencePostPath = '/api/Scheduling/meetingOccurrence';

  /**
   * Update a specific meeting occurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingMeetingOccurrencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingMeetingOccurrencePost$Json$Response(params?: {
    body?: MeetingOccurrenceModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingMeetingOccurrencePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update a specific meeting occurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingMeetingOccurrencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingMeetingOccurrencePost$Json(params?: {
    body?: MeetingOccurrenceModel
  }): Observable<string> {

    return this.apiSchedulingMeetingOccurrencePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiSchedulingSiteParentSiteKeyAvailableMeetingsGet
   */
  static readonly ApiSchedulingSiteParentSiteKeyAvailableMeetingsGetPath = '/api/Scheduling/site/{parentSiteKey}/availableMeetings';

  /**
   * Get a list of all available meeting occurrences for a site between 2 dates
   * (for non-workshops it will only return meetings with no client booked).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json$Response(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingSiteParentSiteKeyAvailableMeetingsGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('meetingSubjectType', params.meetingSubjectType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get a list of all available meeting occurrences for a site between 2 dates
   * (for non-workshops it will only return meetings with no client booked).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingSiteParentSiteKeyGet
   */
  static readonly ApiSchedulingSiteParentSiteKeyGetPath = '/api/Scheduling/site/{parentSiteKey}';

  /**
   * Get a list of all meeting occurrences for a site between 2 dates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingSiteParentSiteKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyGet$Json$Response(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingSiteParentSiteKeyGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get a list of all meeting occurrences for a site between 2 dates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingSiteParentSiteKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyGet$Json(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingSiteParentSiteKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingSiteParentSiteKeyMeetingCountGet
   */
  static readonly ApiSchedulingSiteParentSiteKeyMeetingCountGetPath = '/api/Scheduling/site/{parentSiteKey}/{meetingCount}';

  
  /**
   * Get a list of all available meeting occurrences for a site between 2 dates.
   * - From Date cannot go lower than current date and time.
   * - For non-workshops it will only return meetings with no client booked.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingSiteParentSiteKeyMeetingCountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyMeetingCountGet$Json$Response(params: {
    parentSiteKey: number;
    meetingCount: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingSiteParentSiteKeyMeetingCountGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.path('meetingCount', params.meetingCount, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('meetingSubjectType', params.meetingSubjectType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get a list of the next available meeting occurrences for a site between 2 dates.
   * - Max number of results is set with meetingCount
   * - From Date cannot go lower than current date and time.
   * - For non-workshops it will only return meetings with no client booked.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingSiteParentSiteKeyMeetingCountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyMeetingCountGet$Json(params: {
    parentSiteKey: number;
    meetingCount: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingSiteParentSiteKeyMeetingCountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingSiteParentSiteKeyAvailableDatesGet
   */
  static readonly ApiSchedulingSiteParentSiteKeyAvailableDatesGetPath = '/api/Scheduling/site/{parentSiteKey}/availableDates';

  /**
   * Get a list of dates which have available meetings for a site between 2 dates
   * - From Date cannot go lower than current date and time.
   * - For non-workshops it will only return meetings with no client booked.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json$Response(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingSiteParentSiteKeyAvailableDatesGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('meetingSubjectType', params.meetingSubjectType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get a list of dates which have available meetings for a site between 2 dates
   * (for non-workshops it will only return meetings with no client booked)
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json(params: {
    parentSiteKey: number;
    fromDate?: null | string;
    toDate?: null | string;

    /**
     * Type of meeting (e.g. Intake / Workshop)
     */
    meetingSubjectType?: MeetingSubjectTypes;

  }): Observable<Array<string>> {

    return this.apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation apiSchedulingEmployeeParentEmployeeKeyGet
   */
  static readonly ApiSchedulingEmployeeParentEmployeeKeyGetPath = '/api/Scheduling/employee/{parentEmployeeKey}';

  /**
   * Get a list of all meeting occurrences for a user/employee between 2 dates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingEmployeeParentEmployeeKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingEmployeeParentEmployeeKeyGet$Json$Response(params: {
    parentEmployeeKey: number;
    fromDate?: null | string;
    toDate?: null | string;
    meetingStatusCode?: null | string;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingEmployeeParentEmployeeKeyGetPath, 'get');
    if (params) {

      rb.path('parentEmployeeKey', params.parentEmployeeKey, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('meetingStatusCode', params.meetingStatusCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get a list of all meeting occurrences for a user/employee between 2 dates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingEmployeeParentEmployeeKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingEmployeeParentEmployeeKeyGet$Json(params: {
    parentEmployeeKey: number;
    fromDate?: null | string;
    toDate?: null | string;
    meetingStatusCode?: null | string;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingEmployeeParentEmployeeKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingCaseParentCaseKeyGet
   */
  static readonly ApiSchedulingCaseParentCaseKeyGetPath = '/api/Scheduling/case/{parentCaseKey}';


  /**
   * Get a meeting occurrence by Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingCaseParentCaseKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingCaseParentCaseKeyGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<MeetingOccurrenceModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingCaseParentCaseKeyGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MeetingOccurrenceModel>>;
      })
    );
  }

  /**
   * Get a meeting occurrence by Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingCaseParentCaseKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingCaseParentCaseKeyGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<MeetingOccurrenceModel>> {

    return this.apiSchedulingCaseParentCaseKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MeetingOccurrenceModel>>) => r.body as Array<MeetingOccurrenceModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingParentSiteKeyRoomsGet
   */
  static readonly ApiSchedulingParentSiteKeyRoomsGetPath = '/api/Scheduling/{parentSiteKey}/rooms';

  /**
   * Returns a list of all meeting rooms within a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentSiteKeyRoomsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentSiteKeyRoomsGet$Json$Response(params: {
    parentSiteKey: number;

  }): Observable<StrictHttpResponse<Array<SiteRoomModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentSiteKeyRoomsGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SiteRoomModel>>;
      })
    );
  }

  /**
   * Returns a list of all meeting rooms within a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentSiteKeyRoomsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentSiteKeyRoomsGet$Json(params: {
    parentSiteKey: number;

  }): Observable<Array<SiteRoomModel>> {

    return this.apiSchedulingParentSiteKeyRoomsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SiteRoomModel>>) => r.body as Array<SiteRoomModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingParentSiteKeyRoomsPost
   */
  static readonly ApiSchedulingParentSiteKeyRoomsPostPath = '/api/Scheduling/{parentSiteKey}/rooms';

  /**
   * Create or Update a site meeting room.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentSiteKeyRoomsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingParentSiteKeyRoomsPost$Json$Response(params: {
    parentSiteKey: number;
    body?: SiteRoomModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentSiteKeyRoomsPostPath, 'post');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create or Update a site meeting room.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentSiteKeyRoomsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingParentSiteKeyRoomsPost$Json(params: {
    parentSiteKey: number;
    body?: SiteRoomModel
  }): Observable<string> {

    return this.apiSchedulingParentSiteKeyRoomsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet
   */
  static readonly ApiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGetPath = '/api/Scheduling/site/{parentSiteKey}/unassignedIntakeMeetings';

  /**
   * Get a list of unassigned intake meetings within a site.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet$Json$Response(params: {
    parentSiteKey: number;
    streams?: null | Array<number>;
    caseStatus?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;

  }): Observable<StrictHttpResponse<Array<CaseloadModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGetPath, 'get');
    if (params) {

      rb.path('parentSiteKey', params.parentSiteKey, {});
      rb.query('streams', params.streams, {});
      rb.query('caseStatus', params.caseStatus, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseloadModel>>;
      })
    );
  }

  /**
   * Get a list of unassigned intake meetings within a site.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet$Json(params: {
    parentSiteKey: number;
    streams?: null | Array<number>;
    caseStatus?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;

  }): Observable<Array<CaseloadModel>> {

    return this.apiSchedulingSiteParentSiteKeyUnassignedIntakeMeetingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseloadModel>>) => r.body as Array<CaseloadModel>)
    );
  }

  /**
   * Path part for operation apiSchedulingPost
   */
  static readonly ApiSchedulingPostPath = '/api/Scheduling';

  /**
   * Create or Update a meeting (plus child occurrence records).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingPost$Json$Response(params?: {
    body?: MeetingModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create or Update a meeting (plus child occurrence records).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingPost$Json(params?: {
    body?: MeetingModel
  }): Observable<string> {

    return this.apiSchedulingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiSchedulingGroupMeetingOccurrencePost
   */
  static readonly ApiSchedulingGroupMeetingOccurrencePostPath = '/api/Scheduling/groupMeetingOccurrence';

  /**
   * Update a specific group meeting occurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingGroupMeetingOccurrencePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingGroupMeetingOccurrencePost$Json$Response(params?: {
    body?: MeetingOccurrenceModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingGroupMeetingOccurrencePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update a specific group meeting occurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingGroupMeetingOccurrencePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingGroupMeetingOccurrencePost$Json(params?: {
    body?: MeetingOccurrenceModel
  }): Observable<string> {

    return this.apiSchedulingGroupMeetingOccurrencePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost
   */
  static readonly ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPostPath = '/api/Scheduling/intakeMeeting/{parentMeetingOccurrenceKey}';

  /**
   * Add a new intake meeting from an inquiry record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;
    parentInquiryKey?: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});
      rb.query('parentInquiryKey', params.parentInquiryKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add a new intake meeting from an inquiry record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json(params: {
    parentMeetingOccurrenceKey: number;
    parentInquiryKey?: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost
   */
  static readonly ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPostPath = '/api/Scheduling/intakeMeeting/{parentMeetingOccurrenceKey}/existingClient/{parentClientKey}';

  /**
   * Add a new intake meeting from an inquiry record for existing client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;
    parentInquiryKey?: number;
    parentClientKey: number;

  }): Observable<StrictHttpResponse<ClientCaseModel>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});
      rb.query('parentInquiryKey', params.parentInquiryKey, {});
      rb.path('parentClientKey', params.parentClientKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClientCaseModel>;
      })
    );
  }

  /**
   * Add a new intake meeting from an inquiry record for existing client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json(params: {
    parentMeetingOccurrenceKey: number;
    parentInquiryKey?: number;
    parentClientKey: number;

  }): Observable<ClientCaseModel> {

    return this.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientCaseModel>) => r.body as ClientCaseModel)
    );
  }

  /**
   * Path part for operation apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost
   */
  static readonly ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPostPath = '/api/Scheduling/intakeMeeting/{parentMeetingOccurrenceKey}/{parentClientKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;
    parentClientKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});
      rb.path('parentClientKey', params.parentClientKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json(params: {
    parentMeetingOccurrenceKey: number;
    parentClientKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingOccurrenceKeyLockPost
   */
  static readonly ApiSchedulingParentMeetingOccurrenceKeyLockPostPath = '/api/Scheduling/{parentMeetingOccurrenceKey}/lock';

  /**
   * Locks a single meeting record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingOccurrenceKeyLockPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingOccurrenceKeyLockPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingOccurrenceKeyLockPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Locks a single meeting record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingOccurrenceKeyLockPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingOccurrenceKeyLockPost$Json(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingOccurrenceKeyLockPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingOccurrenceKeyUnlockPost
   */
  static readonly ApiSchedulingParentMeetingOccurrenceKeyUnlockPostPath = '/api/Scheduling/{parentMeetingOccurrenceKey}/unlock';

  /**
   * Unlocks a single meeting record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingOccurrenceKeyUnlockPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Unlocks a single meeting record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json(params: {
    parentMeetingOccurrenceKey: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingKeyEmployeePost
   */
  static readonly ApiSchedulingParentMeetingKeyEmployeePostPath = '/api/Scheduling/{parentMeetingKey}/employee';

  /**
   * Add an employee (attendee) to a meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingKeyEmployeePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyEmployeePost$Json$Response(params: {
    parentMeetingKey: number;
    parentEmployeeKey?: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingKeyEmployeePostPath, 'post');
    if (params) {

      rb.path('parentMeetingKey', params.parentMeetingKey, {});
      rb.query('parentEmployeeKey', params.parentEmployeeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add an employee (attendee) to a meeting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingKeyEmployeePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyEmployeePost$Json(params: {
    parentMeetingKey: number;
    parentEmployeeKey?: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingKeyEmployeePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingKeyClientPost
   */
  static readonly ApiSchedulingParentMeetingKeyClientPostPath = '/api/Scheduling/{parentMeetingKey}/client';

  /**
   * Add a client (attendee) to a meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingKeyClientPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyClientPost$Json$Response(params: {
    parentMeetingKey: number;
    parentClientKey?: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingKeyClientPostPath, 'post');
    if (params) {

      rb.path('parentMeetingKey', params.parentMeetingKey, {});
      rb.query('parentClientKey', params.parentClientKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add a client (attendee) to a meeting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingKeyClientPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingParentMeetingKeyClientPost$Json(params: {
    parentMeetingKey: number;
    parentClientKey?: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingKeyClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingParentMeetingKeyClientsPost
   */
  static readonly ApiSchedulingParentMeetingKeyClientsPostPath = '/api/Scheduling/{parentMeetingKey}/clients';

  /**
   * Add a list of client (attendees) to a meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingParentMeetingKeyClientsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingParentMeetingKeyClientsPost$Json$Response(params: {
    parentMeetingKey: number;
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingParentMeetingKeyClientsPostPath, 'post');
    if (params) {

      rb.path('parentMeetingKey', params.parentMeetingKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add a list of client (attendees) to a meeting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingParentMeetingKeyClientsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingParentMeetingKeyClientsPost$Json(params: {
    parentMeetingKey: number;
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<ProcessResponse> {

    return this.apiSchedulingParentMeetingKeyClientsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost
   */
  static readonly ApiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPostPath = '/api/Scheduling/meetingOccurrences/{parentMeetingOccurrenceKey}/client';

  /**
   * Add a client (attendee) to a meeting occurrence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;
    parentClientKey?: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});
      rb.query('parentClientKey', params.parentClientKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add a client (attendee) to a meeting occurrence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost$Json(params: {
    parentMeetingOccurrenceKey: number;
    parentClientKey?: number;

  }): Observable<ProcessResponse> {

    return this.apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost
   */
  static readonly ApiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPostPath = '/api/Scheduling/meetingOccurrences/{parentMeetingOccurrenceKey}/clients';

  /**
   * Add a list of client (attendees) to a meeting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json$Response(params: {
    parentMeetingOccurrenceKey: number;
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPostPath, 'post');
    if (params) {

      rb.path('parentMeetingOccurrenceKey', params.parentMeetingOccurrenceKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Add a list of client (attendees) to a meeting.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json(params: {
    parentMeetingOccurrenceKey: number;
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<ProcessResponse> {

    return this.apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingAttendeeClientPost
   */
  static readonly ApiSchedulingAttendeeClientPostPath = '/api/Scheduling/attendee/client';

  /**
   * Updates a Meeting occurrence Client Attendee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingAttendeeClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeClientPost$Json$Response(params?: {
    body?: MeetingAttendeeModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingAttendeeClientPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Updates a Meeting occurrence Client Attendee.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingAttendeeClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeClientPost$Json(params?: {
    body?: MeetingAttendeeModel
  }): Observable<ProcessResponse> {

    return this.apiSchedulingAttendeeClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiSchedulingAttendeeClientsPost
   */
  static readonly ApiSchedulingAttendeeClientsPostPath = '/api/Scheduling/attendee/clients';

  /**
   * Updates a list of Meeting occurrence Client Attendees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSchedulingAttendeeClientsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeClientsPost$Json$Response(params?: {
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulingService.ApiSchedulingAttendeeClientsPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Updates a list of Meeting occurrence Client Attendees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSchedulingAttendeeClientsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSchedulingAttendeeClientsPost$Json(params?: {
    body?: null | Array<MeetingAttendeeModel>
  }): Observable<ProcessResponse> {

    return this.apiSchedulingAttendeeClientsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
