import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import * as R from 'remeda';
import { map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, merge, of } from 'rxjs';
import { RxFormBuilder, file } from '@rxweb/reactive-form-validators';

import { Globals } from '../../../../globals';
import { DocumentMetadataDto, DocumentDto, ProcessResponse, OutcomeClaimModel } from '@api/models';
import { CaseService } from '@api/services/case.service';
import { DocumentService } from '@api/services/document.service';
import { DataService } from '@core/services/data.service';
import { LoggingService } from '@core/services/logging.service';
import { StrictHttpResponse } from '@api/strict-http-response';
import { Documents } from '@shared/components/documents/models/documents.model';

import { DocumentsLabelsType } from '@modules/client/components/documents-filter/documents-filter.component';
import {
  parseDateFormatted8601FromString,
  parseDateFormattedFromDate
} from '@shared/models/date-formats.model';
import { DocumentForm, DocumentsViewType } from '@shared/components/documents/models/document-form.model';
import { FileTypeFns } from '@shared/models/file-types.model';
import { EmploymentService, ExpenditureService } from '@api/services';
import { OutcomeService } from '@api/services';
import { errorMapFn } from '@core/models/cf2-validators.model';
import { MultipleEntriesResponse } from '@shared/components/multiple-entries/multiple-entries.model';
import { TranslocoService } from '@ngneat/transloco';

interface DocumentsData {
  /**
   * For newly created documents, tempKey value is in between 0.0001 - 0.9999 which is generated on the front-end
   * For existing document tempKey = DocumentStore.parentDocumentStoreKey
   */
  tempKey?: number;
  document: Documents;
  files: Array<any>;
  fileChanged?: boolean;
  action?: 'CREATE' | 'UPDATE' | 'UPDATE_METADATA' | 'ARCHIVE' | 'DELETE' | 'LINK' | 'UNLINK';
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsDataService {
  private _filters: DocumentsLabelsType;

  _documentsViewType: DocumentsViewType;

  _parentCategoryCode: string;
  private _documents: DocumentDto[];

  _selectedDocumentKey: number;

  documentsList: DocumentDto[];

  documentsData: DocumentsData[] = [];

  documentsListDataSub$: BehaviorSubject<Documents[]> = new BehaviorSubject<Documents[]>([]);

  documentForm: DocumentForm;

  documentFormGroup: UntypedFormGroup;

  documentCategoryList: { value: string; description: string; disabled: boolean }[] = [];

  documentTypeList: { value: string; description: string; disabled: boolean }[] = [];
  invalidFiles$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  invalidFiles = false;

  outcomeClaims: OutcomeClaimModel[] = [];
  selectedOutcomeClaimKey: number;

  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  get filters() {
    const filters = this._filters;

    if (!filters) {
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: 'no filters in DocumetnsDataService.#filters',
      });
    }
    return filters;
  }

  set filters(filters: DocumentsLabelsType) {
    if (!filters) {
      this.logSvc.logError({
        lvl: 'DEBUG',
        mssg: 'no filters in DocumetnsDataService.filters',
      });
    }
    this._filters = filters;
  }

  /* get the document types */
  get documentType$() {
    return this.dataSvc.lookupRecords('DocumentType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((data) => (this.documentTypeList = data))
    );
  }

  /* get the document section code */
  get documentCategory$() {
    return this.dataSvc.lookupRecords('DocumentSection').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((data) => (this.documentCategoryList = data))
    );
  }

    //get documentCategoryCode$() {
    //    return this.dataSvc.lookupRecords('DocumentCategory').pipe(
    //        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
    //        tap((data) => (this.documentCategoryList = data))
    //    );
    //}

    //get documentSubCategoryCode$() {
    //    return this.dataSvc.lookupRecords('DocumentSubCategory').pipe(
    //        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
    //        tap((data) => (this.documentCategoryList = data))
    //    );
    //}

  /**
   * get the document type by it's parent key
   * @param parentCode - the parentCode of the documentSection
   */
  getDocumentTypeByParent(parentCode: string) {
    if (!parentCode)
      return this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no document parent key set in getDocumentTypeByParent ',
      });

    const obs = this.dataSvc.lookupDocumentType(parentCode);

    return obs as Observable<DocumentType[]>;
  }

  /**
   * getDocumentTypeDescription
   * @param documentTypeCode string
   */
  getDocumentTypeDescription(documentTypeCode: string) {
    let desc = '';
    if (this.documentTypeList?.length > 0) {
      for (let docType of this.documentTypeList) {
        if (docType.value === documentTypeCode) {
          desc = docType.description ? docType.description : '';
          break;
        }
      }
    }

    return desc;
  }

  constructor(
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private docSvc: DocumentService,
    private globals: Globals,
    private caseSvc: CaseService,
    private logSvc: LoggingService,
    private expenditureSvc: ExpenditureService,
    private outcomeSvc: OutcomeService,
    private employmentSvc: EmploymentService,
    private translocoService : TranslocoService
  ) {}

  /* State handlers - state is loaded from local storage */
  /* get the case key from local storage */
  getCaseKey() {
    const caseKey = this.globals.caseKey;
    if (!caseKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for documents data service',
      });
    return caseKey;
  }

  /* set the case key in local storage */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }

  get parentCategoryCode() {
    return this._parentCategoryCode;
  }

  /** set the module code  */
  set parentCategoryCode(code: string) {
    this._parentCategoryCode = code;
  }

  /* get the case key from local storage */
  getDisplayName() {
    const clientDisplayName = this.globals.clientDisplayName;
    if (!clientDisplayName)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for documents data service',
      });
    return clientDisplayName;
  }

  /* set the case key in local storage */
  setDisplayName(name: string) {
    this.globals.clientDisplayName = name;
  }

  /* get the document key from local storage */
  getDocumentKey() {
    return this.globals.documentKey;
  }

  /* set the document key in local storage */
  setDocumentKey(key: number) {
    this.globals.documentKey = key;
  }

  get selectedDocumentKey(): number {
    return this._selectedDocumentKey;
  }
  // set current slected document in the datatable
  set selectedDocumentKey(key: number) {
    this._selectedDocumentKey = key;
  }

  get documentsViewType() {
    return this._documentsViewType;
  }

  set documentsViewType(view: DocumentsViewType) {
    this._documentsViewType = view;
  }

  get expenditureKey() {
    const expenditureKey = this.globals.expenditureKey;
    if (!expenditureKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no expenditureKey set in globals for documents data service',
      });

    return expenditureKey;
  }

  get outcomeClaimKey() {
    const outcomeClaimKey = this.globals.outcomeKey;
    if (!outcomeClaimKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no outcomeKey set in globals for documents data service',
      });

    return outcomeClaimKey;
  }

  get employerPostionReferralKey() {
    const key = this.globals.employerPositionReferralKey;
    if (!key)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no employment set in globals for documents data service',
      });

    return key;
  }

  setDocumentsListDataSource(data: Documents[]) {
    this.documentsListDataSub$.next(data);
  }

  // @TODO: Test and remove
  // setFgView(view: DocumentsViewType) {
  //   this.documentsViewType = view;
  // }

  getDocumentFormFields() {
    return this.documentForm.fields;
  }

  touchFiles() {
    this.invalidFiles$.next(true);
  }

  constructDocumentName(clientName: string, typeDescription: string, date: string, ext: string) {
    // "{FirstName} {LastName} - {DocumentType} - {DocumentDate}"
    return `${clientName} - ${typeDescription} - ${parseDateFormattedFromDate(new Date(date))}.${ext}`;
  }

  /**
   * extractFileDate
   * @param documentName
   *
   * Ex:  documentName: "Lindie Dumphry - Employer Incentive (Invoice) - 14-Sep-2020.txt"
   * Ex: @return string '14-sep-2020'
   */
  extractFileDate(documentName: string) {
    let result = documentName;
    if (documentName?.length > 0) {
      // splity by . ["Lindie Dumphry - Employer Incentive (Invoice) - 14-Sep-2020", "txt"]
      // split by '-' = ["Lindie Dumphry ", " Employer Incentive (Invoice) ", " 14", "Sep", "2020"]
      const splitName = documentName.split('.')[0].split('-');
      if (splitName.length >= 5) {
        // get last 3 items in array using slice and join them
        result = splitName.slice(-3).join('-');
      }
    }

    return result;
  }

  documentKeyFileChanged(changedData: Pick<DocumentsData, 'tempKey' | 'files'>) {
    this.documentsData = this.documentsData.map((docData) => {
      if (docData.tempKey === changedData.tempKey) {
        docData.files = [...changedData.files];
        docData.fileChanged = true;
      }
      return docData;
    });
  }

  // check if document form and files are valid
  isFormValid() {
    if (!this.documentFormGroup || (this.documentFormGroup.valid && !this.invalidFiles)) {
      return true;
    }

    this.documentFormGroup.markAllAsTouched();
    this.invalidFiles ? this.touchFiles() : null;
    return false;
  }

  initDocumentsCard(obj: { view: DocumentsViewType; category: string }) {
    const { view, category } = obj;
    this.documentsViewType = view;
    this.parentCategoryCode = category;
  }

  initDocumentForm(value?: any) {
    console.log(value);
    this.documentForm = value ? new DocumentForm(this.fb, this.translocoService, { value: value }) : new DocumentForm(this.fb, this.translocoService);
    this.documentForm.fields.fg.patchValue(value);
    this.documentFormGroup = this.documentForm.fields.fg;
    // console.log(this.documentFormGroup);
  }

  constructDocumentData(docData: DocumentsData) {
    const documentData = docData;

    documentData.document.parentDocumentStoreKey = documentData.tempKey;
    documentData.document.displayName = this.globals.displayName ? this.globals.displayName : '';
    // TODO: Evaluate if client side parsing is necessary
    //documentData.document.documentDateFormatted = parseDate(new Date(documentData.document.documentDate));
    documentData.document.icon = FileTypeFns.fileType(FileTypeFns.fileExt(documentData.files[0].name));

    // check if list values already exists
    if (this.documentCategoryList.length > 0) {
      // get the documentSectionDescription when documentSectionCode is given
      this.documentCategoryList.forEach((listItem) => {
        if (listItem.value === documentData.document.documentSectionCode) {
          documentData.document.documentSectionDescription = listItem.description;
        }
      });
    }

    if (this.documentTypeList.length > 0) {
      // get the documentTypeDescription when documentTypeCode is given
      this.documentTypeList.forEach((listItem) => {
        if (listItem.value === documentData.document.documentTypeCode) {
          documentData.document.documentTypeDescription = listItem.description;
        }
      });
    }

    return documentData;
  }

  addDocumentData(documentData: DocumentsData) {
    // create new document
    documentData.action = 'CREATE';
    this.documentsData.push(this.constructDocumentData(documentData));
    console.log(this.documentsData);
  }

  updateDocumentData(documentData: DocumentsData) {
    this.documentsData = this.documentsData.map((docData) => {
      if (docData.tempKey === documentData.tempKey) {

        // construct updated documentData
        docData.document = {
          ...docData.document,
          ...this.constructDocumentData(documentData).document,
        };

        // if we are updaing newly created document locally
        if (docData.tempKey < 1) {
          // do nothing, implict create action
          // archive existing document and create a new one
        } else if (docData.fileChanged) {
          docData.action = 'UPDATE';

          // if files not changed then just update the metadata of the document
        } else {
          docData.action = 'UPDATE_METADATA';
        }
      }

      return docData;
    });
  }

  /**
   *
   * @param documentKey
   */
  deleteDocumentData(documentKey: number) {
    const docData = this.getDocumentDataByKey(documentKey);
    const localLink = docData.action === 'LINK';

    // documentKey with < 1 values are generated locally for newly created document
    // if linked locally then just remove from this.documentData
    if (documentKey < 1 || localLink) {
      this.documentsData = R.reject(this.documentsData, (doc) => doc.tempKey === documentKey);
    } else {
      this.documentsData = this.documentsData.map((docData) => {
        if (docData.tempKey === documentKey) {
          docData.action = docData.document.isLink ? 'UNLINK' : 'DELETE';
        }

        return docData;
      });
    }
  }

  addLinkDocuments(documents: Documents[]) {
    const mappedDocuments: Documents[] = this.mapDocuments(documents);
    let linkDocumentsData = this.mapDocumentsData(mappedDocuments);
    linkDocumentsData = linkDocumentsData.map((doc) => {
      doc.action = 'LINK';
      doc.document.isLink = true;
      return doc;
    });

    linkDocumentsData.forEach((linkDoc) => {
      let found = false;
      for (const doc of this.documentsData) {
        if (doc.tempKey === linkDoc.tempKey) {
          found = true;
          break;
        }
      }

      // if linked doc is found then dont need to update this.documentsData
      if (!found) this.documentsData.push(linkDoc);
    });
  }

  /**
   * getDocumentsData
   *
   * get documents which ar enot deleted
   */
  getDocumentsData() {
    //
    const filteredDocs = R.reject(this.documentsData, (docData) => {
      return !!docData.action && (docData.action === 'DELETE' || docData.action === 'UNLINK');
    });
    return filteredDocs.map((obj) => obj.document);
  }

  getLinkedDocuments() {
    return R.filter(this.documentsData, (docData) => docData?.action === 'LINK').map((docData) => docData.document);
  }

  setDocumentsData(docsData: DocumentsData[]) {
    this.documentsData = docsData;
  }

  getDocumentDataByKey(documentKey) {
    return this.documentsData.filter((doc) => doc.document.parentDocumentStoreKey === documentKey)[0];
  }

  // DOCTODO: missing documentLink and doc.CreateDateFormatted ?
  mapDocuments(docs: DocumentDto[]): Documents[] {
    const mapped = docs.map((doc) => ({
      ...doc,
      isLocked: FileTypeFns.IsDeleteLocked(new Date(doc.createDateFormatted)),
      icon: doc.documentName ? FileTypeFns.fileType(FileTypeFns.fileExt(doc.documentName)) : null,
    }));
    return mapped;
  }

  mapDocumentsData(docs: Documents[]): DocumentsData[] {
    const documentsData = docs.map((data) => {
      return {
        tempKey: data.parentDocumentStoreKey,
        document: data,
        files: [
          {
            name: data.documentName ? data.documentName : '',
            type: data.documentName ? FileTypeFns.fileExt(data.documentName) : '',
            size: data.documentSize,
            fileDate: data.documentName ? this.extractFileDate(data.documentName) : '',
          },
        ],
      };
    });

    return documentsData;
  }

  sanitizeDocumentForm() {
    this.documentForm = null;
    this.documentFormGroup = null;
    this.selectedDocumentKey = null;
  }

  sanitizeData() {
    // #HTODO: Sanitize documents forms, state, keys, currentkey, selectedkey, subscriptions
    this.sanitizeDocumentForm();
    this.documentsViewType = null;
    this.parentCategoryCode = null;
    this.documentsData = [];
    this.documentsListDataSub$.next([]);

    // sanitize outcomes data
    this.outcomeClaims = [];
    this.selectedOutcomeClaimKey = null;
  }

  downloadDocument(key: number): Observable<any> {
    return this.docSvc.apiDocumentDocumentKeyDownloadGet$Response({
      documentKey: key,
    });
  }

  getDocumentInfo(key: number): Observable<any> {
    return this.docSvc.apiDocumentDocumentKeyGet$Json$Response({
      documentKey: key,
    });
  }

  getDocument(): Observable<any> {
    const parentDocumentStoreKey = this.getDocumentKey();
    if (!parentDocumentStoreKey || isNaN(parentDocumentStoreKey)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in get getDocument() documents dataSvc isNan or invalid',
      });
    }
    return this.docSvc.apiDocumentDocumentKeyGet$Json$Response({
      documentKey: parentDocumentStoreKey,
    });
  }

  getAllDocuments(): Observable<StrictHttpResponse<DocumentDto[]>> {
    const parentCasekey = this.getCaseKey();

    if (isNaN(parentCasekey)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllDocuments in documents dataSvc isNan',
      });
    }
    return this.caseSvc.apiCaseCaseKeyDocumentsGet$Json$Response({
      caseKey: parentCasekey,
    });
  }

  /**
   * getAllExpenditureDocuments
   *
   * Get all documents related to a expenditure
   */
  getAllExpenditureDocuments(parentExpenditureKey) {
    const key = this.expenditureKey;

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllExpenditureDocuments in documents dataSvc isNan',
      });
    }

    return this.expenditureSvc.apiExpenditureParentExpenditureKeyDocumentsGet$Json({
      parentExpenditureKey,
    });
  }

  getAllOutcomeDocuments(parentOutcomeClaimKey) {
    const key = this.outcomeClaimKey;

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllExpenditureDocuments in documents dataSvc isNan',
      });
    }

    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json({
      parentOutcomeClaimKey,
    });
  }

  getAllEmploymentDocuments(parentEmployerPositionReferralKey) {
    const key = this.outcomeClaimKey;

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllEmploymentDocuments in documents dataSvc isNan',
      });
    }

    return this.employmentSvc.apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json({
      parentEmployerPositionReferralKey,
    });
  }

  getFilteredDocuments(
    fromDate?: null | string,
    toDate?: null | string,
    moduleCode?: null | string,
    documentSectionCode?: null | string,
    documentTypeCode?: null | string,
    includeArchived?: boolean,
    parentCaseKey?: null | number
  ): Observable<StrictHttpResponse<DocumentDto[]>> {
    const key = this.getCaseKey();

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getFilteredDocuments in documents dataSvc isNan',
      });
    }

    //** New Api to get filtered case documents */
    return this.caseSvc.apiCaseCaseKeyDocumentsGet$Json$Response({
      caseKey: parentCaseKey,
      fromDate,
      toDate,
      documentSectionCode,
      documentTypeCode,
      includeArchived: includeArchived,
    });
  }

  /**
   * getExpenditureAvailableDocuments
   *
   * get available documents to link to new expenditure
   */
  getExpenditureAvailableDocuments(parentCaseKey: number) {
    return this.expenditureSvc.apiExpenditureAvailableDocumentsGet$Json$Response({
      parentCaseKey,
    });
  }

  /**
   * getParentExpenditureKeyAvailableDocuments
   *
   * get available documents to link to existing expenditure
   */
  getParentExpenditureKeyAvailableDocuments(parentExpenditureKey: number) {
    return this.expenditureSvc.apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json$Response({
      parentExpenditureKey,
    });
  }

  /**
   * getOutcomeClaimAvailableDocuments
   *
   * get available documents to link to new outcome claims
   */
  getOutcomeClaimAvailableDocuments(parentCaseKey: number) {
    return this.outcomeSvc.apiOutcomeAvailableDocumentsGet$Json$Response({
      parentCaseKey,
    });
  }

  /**
   * getParentOutcomeClaimKeyAvailableDocuments
   *
   * get available documents to link to existing outcome claims
   */
  getParentOutcomeClaimKeyAvailableDocuments(parentOutcomeClaimKey: number) {
    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json$Response({
      parentOutcomeClaimKey,
    });
  }

  /**
   * getEmploymentAvailableDocuments
   *
   * get available documents to link to new Employment
   */
  getEmploymentAvailableDocuments(parentCaseKey: number) {
    return this.employmentSvc.apiEmploymentAvailableDocumentsGet$Json$Response({
      parentCaseKey,
    });
  }

  /**
   * getParentEmployerPositionReferralKeyKeyAvailableDocuments
   *
   * get available documents to link to existing Employment
   */
  getParentEmployerPositionReferralKeyAvailableDocuments(parentEmployerPositionReferralKey: number) {
    return this.employmentSvc.apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json$Response({
      parentEmployerPositionReferralKey,
    });
  }

  async submitDocumentMetaData(doc: Partial<DocumentMetadataDto>) {
    const documentKey = this.getDocumentKey();
    if (!documentKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No documentKey in DocumentsData.submitDocumentMetaData',
      });
    const submitted = await this.docSvc
      .apiDocumentDocumentKeyMetadataPost$Json$Response({
        documentKey,
        body: { ...doc },
      })
      .toPromise();

    if (submitted.status !== 200) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `Failed to save meta data changes from DocumentsData.submitDocumentMetaData with response code ${submitted.status} `,
      });
    }

    return submitted;
  }

  async submitDocument(doc: Partial<DocumentDto>, fileBlob: { file: any }) {
    if (!doc) return console.log('no document uploaded');

    const documentDate = parseDateFormatted8601FromString(doc.documentDate as any);

    const uploaded = await this.caseSvc
      .apiCaseParentCaseKeyUploadPost$Json$Response({
        parentCaseKey: this.getCaseKey(),
        documentSectionCode: doc.documentSectionCode,
        documentType: doc.documentTypeCode,
        documentDate,
        comment: doc.comment,
        //documentCategoryCode: doc.documentCategoryCode,
        //documentSubCategoryCode: doc.documentSubCategoryCode,
        body: fileBlob,
      })
      .toPromise();
    return uploaded;
  }

  async submitParentExpenditureDocuments(parentExpenditureKey: number) {
    if (this.documentsData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentExpenditureKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} submitParentExpenditureDocument: parentExpenditureKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    try {
      console.log('DocumentsData', this.documentsData);

      // Remove documents without action
      const documentsWithAction = R.reject(this.documentsData, (doc) => !doc.action);
      console.log('DOcuments With Action', documentsWithAction);
      const documentPromises = R.flatMap(documentsWithAction, (docData) => {
        const {
          documentSectionCode,
          documentTypeCode,
          comment,
          documentDateFormatted,
          parentDocumentStoreKey,
          parentLinkDocumentKey,
        } = docData.document;

        // create new document
        if (docData.action === 'CREATE') {
          return this.expenditureSvc
            .apiExpenditureParentExpenditureKeyUploadPost$Json$Response({
              comment,
              documentSectionCode,
              documentType: documentTypeCode,
              documentDate: documentDateFormatted,
              parentExpenditureKey,
              body: { file: docData.files[0] },
            })
            .toPromise();

          // update document metadata
        } else if (docData.action === 'UPDATE_METADATA') {
          return this.docSvc
            .apiDocumentDocumentKeyMetadataPost$Json$Response({
              documentKey: parentDocumentStoreKey,
              body: docData.document,
            })
            .toPromise();

          // Update document file
        } else if (docData.action === 'UPDATE') {
          // First archive the existing document and
          //  then create a new document
          const actions = [
            // step 1: archive the exising document
            this.docSvc
              .apiDocumentDocumentKeyDelete$Json$Response({
                documentKey: parentDocumentStoreKey,
              })
              .toPromise(),

            // step 2: and create a new document
            this.expenditureSvc
              .apiExpenditureParentExpenditureKeyUploadPost$Json$Response({
                comment,
                parentExpenditureKey,
                documentSectionCode,
                documentType: documentTypeCode,
                documentDate: documentDateFormatted,
                body: { file: docData.files[0] },
              })
              .toPromise(),
          ];
          // console.log(actions);
          return actions;

          // archive document
        } else if (docData.action === 'DELETE') {
          return this.docSvc
            .apiDocumentDocumentKeyDelete$Json$Response({
              documentKey: parentDocumentStoreKey,
            })
            .toPromise();

          // Link documents
        } else if (docData.action === 'LINK') {
          return this.expenditureSvc
            .apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json$Response({
              parentExpenditureKey,
              parentDocumentStoreKey,
            })
            .toPromise();

          // Unlink
        } else if (docData.action === 'UNLINK') {
          return this.expenditureSvc
            .apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json$Response({
              parentExpenditureKey,
              documentLinkKey: parentLinkDocumentKey,
            })
            .toPromise();
        }
      });

      // console.log(documentPromises);
      if (documentPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        const response = await Promise.all(documentPromises);
        let status: number = 200;
        response.forEach((docRes) => (docRes.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.submitParentExpenditureDocuments: Error saving documents`,
      });
      // console.log(e);
      return e;
    } finally {
      // sanitize document actions, fileChanged to prevent performing same updates to the db again
      this.documentsData = R.reject(
        this.documentsData,
        (doc) => doc.action === 'DELETE' || doc.action === 'UNLINK'
      ).map((docData) => {
        return {
          ...docData,
          action: null,
          fileChanged: false,
        };
      });
    }
  }

  async submitParentOutcomeClaimDocuments(parentOutcomeClaimKey: number) {
    if (this.documentsData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentOutcomeClaimKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} submitPrentOutcomeClaimDocuments: parentOutcomeClaimKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    try {
      console.log('DocumentsData', this.documentsData);

      // Remove documents without action
      const documentsWithAction = R.reject(this.documentsData, (doc) => !doc.action);
      console.log('DOcuments With Action', documentsWithAction);
      const documentPromises = R.flatMap(documentsWithAction, (docData) => {
        const {
          documentSectionCode,
          documentTypeCode,
          comment,
          documentDateFormatted,
          parentDocumentStoreKey,
          parentLinkDocumentKey,
        //  documentCategoryCode,
        //  documentSubCategoryCode
        } = docData.document;

        // create new document
        if (docData.action === 'CREATE') {
          return this.outcomeSvc
            .apiOutcomeParentOutcomeClaimKeyUploadPost$Json$Response({
              comment,
              documentSectionCode,
              documentDate: documentDateFormatted,
              documentType: documentTypeCode,
              parentOutcomeClaimKey,
              //documentCategoryCode,
              //documentSubCategoryCode,
              body: { file: docData.files[0] },
            })
            .toPromise();

          // update document metadata
        } else if (docData.action === 'UPDATE_METADATA') {
          return this.docSvc
            .apiDocumentDocumentKeyMetadataPost$Json$Response({
              documentKey: parentDocumentStoreKey,
              body: docData.document,
            })
            .toPromise();

          // Update document file
        } else if (docData.action === 'UPDATE') {
          // First archive the existing document and
          //  then create a new document
          const actions = [
            // step 1: archive the exising document
            this.docSvc
              .apiDocumentDocumentKeyDelete$Json$Response({
                documentKey: parentDocumentStoreKey,
              })
              .toPromise(),

            // step 2: and create a new document
            this.outcomeSvc
              .apiOutcomeParentOutcomeClaimKeyUploadPost$Json$Response({
                comment,
                documentDate: documentDateFormatted,
                documentType: documentTypeCode,
                documentSectionCode,
                parentOutcomeClaimKey,
                body: { file: docData.files[0] },
              })
              .toPromise(),
          ];
          // console.log(actions);
          return actions;

          // archive document
        } else if (docData.action === 'DELETE') {
          return this.docSvc
            .apiDocumentDocumentKeyDelete$Json$Response({
              documentKey: parentDocumentStoreKey,
            })
            .toPromise();

          // Link documents
          // #HTODO: documentPromise Type Error
        } else if (docData.action === 'LINK') {
          return this.outcomeSvc
            .apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json$Response({
              parentOutcomeClaimKey,
              parentDocumentStoreKey,
            })
            .toPromise();

          // unlink
        } else if (docData.action === 'UNLINK') {
          return this.outcomeSvc
            .apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json$Response({
              parentOutcomeClaimKey,
              documentLinkKey: parentLinkDocumentKey,
            })
            .toPromise();
        }
      });

      // console.log(documentPromises);
      if (documentPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        // document Link endpoint is returing StrictHttpResponse<number> which is throwing a type error so just add the type below
        const response = await Promise.all<MultipleEntriesResponse<DocumentDto>>(documentPromises);
        let status: number = 200;
        response.forEach((docRes) => (docRes.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.submitParentOutcomeClaimDocuments: Error saving documents`,
      });
      // console.log(e);
      return e;
    } finally {
      // sanitize document actions, fileChanged to prevent performing same updates to the db again
      this.documentsData = R.reject(
        this.documentsData,
        (doc) => doc.action === 'DELETE' || doc.action === 'UNLINK'
      ).map((docData) => {
        return {
          ...docData,
          action: null,
          fileChanged: false,
        };
      });
    }
  }

  async submitParentEmployersPositionReferralDocuments(parentEmployerPositionReferralKey: number) {
    if (this.documentsData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentEmployerPositionReferralKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} submitParentEmployersPositionReferralDocuments: parentEmployerPositionReferralKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    try {
      console.log('DocumentsData', this.documentsData);

      // Remove documents without action
      const documentsWithAction = R.reject(this.documentsData, (doc) => !doc.action);
      console.log('DOcuments With Action', documentsWithAction);
      const documentPromises = R.flatMap(documentsWithAction, (docData) => {
        const {
          documentSectionCode,
          documentTypeCode,
          comment,
          documentDateFormatted,
          parentDocumentStoreKey,
          parentLinkDocumentKey,
        } = docData.document;

        // create new document
        if (docData.action === 'CREATE') {
          return this.employmentSvc
            .apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json$Response({
              comment,
              documentSectionCode,
              documentDate: documentDateFormatted,
              documentType: documentTypeCode,
              parentEmployerPositionReferralKey,
              body: { file: docData.files[0] },
            })
            .toPromise();

          // update document metadata
        } else if (docData.action === 'UPDATE_METADATA') {
          return this.docSvc
            .apiDocumentDocumentKeyMetadataPost$Json$Response({
              documentKey: parentDocumentStoreKey,
              body: docData.document,
            })
            .toPromise();

          // Update document file
        } else if (docData.action === 'UPDATE') {
          // First archive the existing document and
          //  then create a new document
          const actions = [
            // step 1: archive the exising document
            this.docSvc
              .apiDocumentDocumentKeyDelete$Json$Response({
                documentKey: parentDocumentStoreKey,
              })
              .toPromise(),

            // step 2: and create a new document
            this.employmentSvc
              .apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json$Response({
                comment,
                documentDate: documentDateFormatted,
                documentType: documentTypeCode,
                documentSectionCode,
                parentEmployerPositionReferralKey,
                body: { file: docData.files[0] },
              })
              .toPromise(),
          ];
          // console.log(actions);
          return actions;

          // archive document
        } else if (docData.action === 'DELETE') {
          return this.docSvc
            .apiDocumentDocumentKeyDelete$Json$Response({
              documentKey: parentDocumentStoreKey,
            })
            .toPromise();

          // Link documents
          // #HTODO: documentPromise Type Error
        } else if (docData.action === 'LINK') {
          return this.employmentSvc
            .apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json$Response({
              parentEmployerPositionReferralKey,
              parentDocumentStoreKey,
            })
            .toPromise();
          // unlink
        } else if (docData.action === 'UNLINK') {
          return this.employmentSvc
            .apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json$Response({
              parentEmployerPositionReferralKey,
              documentLinkKey: parentLinkDocumentKey,
            })
            .toPromise();
        }
      });

      // console.log(documentPromises);
      if (documentPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        // document Link endpoint is returing StrictHttpResponse<number> which is throwing a type error so just add the type below
        const response = await Promise.all<MultipleEntriesResponse<DocumentDto>>(documentPromises);
        let status: number = 200;
        response.forEach((docRes) => (docRes.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.submitParentOutcomeClaimDocuments: Error saving documents`,
      });
      // console.log(e);
      return e;
    } finally {
      // sanitize document actions, fileChanged to prevent performing same updates to the db again
      this.documentsData = R.reject(
        this.documentsData,
        (doc) => doc.action === 'DELETE' || doc.action === 'UNLINK'
      ).map((docData) => {
        return {
          ...docData,
          action: null,
          fileChanged: false,
        };
      });
    }
  }

  /**
   * return a document download link. This is mapped into the
   */
  downloadLink(parentDocumentStoreKey: number) {
    return DocumentService.ApiDocumentDocumentKeyDownloadGetPath.replace(
      '{documentKey}',
      //'test'
      parentDocumentStoreKey.toString()
    );
  }

  archiveDocument() {
    const key = this.getDocumentKey();
    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in archiveDocument in documents dataSvc isNan',
      });
    }

    return this.docSvc.apiDocumentDocumentKeyArchivePost$Json$Response({ documentKey: key });
  }

  deleteDocument() {
    const parentDocumentStoreKey = this.getDocumentKey();
    console.log(this.getDocumentKey());

    if (isNaN(parentDocumentStoreKey)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in deleteDocument in documents dataSvc isNan',
      });
    }

    return this.docSvc.apiDocumentDocumentKeyDelete$Json$Response({
      documentKey: parentDocumentStoreKey,
    });
  }

  IsEditArchiveLocked(): boolean {
    return false;
  }

  public formDirty(fg: UntypedFormGroup): boolean {
    return fg.dirty;
  }

  public cleanForm(fg: UntypedFormGroup): void {
    if (!fg) return;
    fg.markAsPristine();
    fg.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public formError(fg: UntypedFormGroup, files: any[]): boolean {
    return (!fg.valid || files.length < 1) && fg.touched;
  }

  public formErrorList(fg: UntypedFormGroup, labels, files: any[]): string[] {
    const errors = [];
    if (files.length < 1) {
      errors.push('At least one file is required');
    }

    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;
      //console.log(control)

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (!errors.includes(this.errorMap(control, this.controlLabel(labels[key])))) {
            errors.push(this.errorMap(control, this.controlLabel(labels[key])));
          }
        });
      }
    });
    return errors;
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    if (!fc.errors) return '';
    if (fc.errors.required) return this.translocoService.translateObject("Message.Error.ControlRequired", {ControlName: label});
    if (fc.errors.duplicateSin) return this.translocoService.translate("DuplicateSin");
    if (fc.errors.sin && fc.errors.sin.message) return this.translocoService.translate("Message.Error.InvalidSin");
    if (fc.errors.minLength && fc.errors.minLength.message) return `${label} ${fc.errors.minLength.message} `;

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translate("Message.Error.IncompletePhoneNumber");
    }

    if (fc.errors.minlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMinimumCharacters", {Minimum: fc.errors.minlength.requiredLength});
    if (fc.errors.maxlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMaximumCharacters", {Maximum: fc.errors.maxlength.requiredLength});
    if (fc.errors.email) return `${label} must be in the format "example@mail.com".`;

    if (fc.errors.name) return `Invalid name.`;

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid.Please use format DD - MON - YYYY.`;
  }

  public controlLabel(control: string): string {
    switch (control) {
      case 'Identify As':
        return 'Gender';
      case 'Phone No.':
        return 'Phone Number';
      case 'Street Address 1':
        return 'Address';
      case 'comment':
        return 'Description';
      case 'documentTypeCode':
        return 'Document Type';
      case 'documentDate':
        return 'Document Date';
    }

    return control;
  }

  docErrors() {
    const ret = (this.documentFormGroup?.touched && this.documentFormGroup?.invalid) || this.invalidFiles;
    return ret === undefined ? false : ret;
  }

  docErrorList(prefix = '') {
    const errors: string[] = [];

    if (this.documentFormGroup) {
      Object.keys(this.documentFormGroup?.controls).forEach((key) => {
        if (this.documentFormGroup?.controls[key].invalid) {
          errors.push(
            errorMapFn(this.documentFormGroup?.controls[key] as UntypedFormControl, prefix + this.controlLabel(key))
          );
        }
      });
    }

    if (this.invalidFiles) errors.push('Document File required');

    return errors;
  }

  docDirty() {
    let any_dirty = false;

    this.documentsData.forEach((doc) => {
      console.warn('got here');
      any_dirty = any_dirty || doc.fileChanged;
    });

    return any_dirty || this.documentFormGroup?.dirty;
  }

  cleanDoc() {
    this.cleanForm(this.documentFormGroup);
  }
}
