import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationModel, PositionModel, SiteModel } from '@api/models';
import { UserStateService } from '@core/services/user-state.service';
import { Cf2RoleType } from '@core/types/cf2-role.type';
import { OptionsDataService } from '@modules/cf2-cache/services/options-data.service';
import {
  greaterThanEqualTo,
  IFormGroup,
  minLength,
  required,
  RxFormBuilder,
  ResetFormType,
  prop,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { SelectOptionType } from '@shared/models/select-options.model';
import { map } from 'rxjs/operators';
import { SelectFieldFixedComponent } from '../input-field-fixed/select-field-fixed.component';
import * as R from 'remeda';
import { AutoCompleteSelectFieldFixedComponent } from '../input-field-fixed/autocomplete-select-field-fixed.component';

interface Rules {
  required: boolean;
}
type Fields = 'organization' | 'sites' | 'employees';
/*
 cannot user interface for RolesAndRules
 https://github.com/microsoft/TypeScript/issues/24220
 https://stackoverflow.com/questions/51659420/consider-using-a-mapped-object-type-instead-whats-a-mapped-object-type-and
*/
type FieldRules = {
  [key in Fields]: Rules;
};
export type RolesAndRules = {
  [key in Cf2RoleType]?: FieldRules;
};

export interface SitesOptions extends SiteModel {
  description: string;
  value: number;
}
export interface OrgsOptions extends SelectOptionType {
  sites: SitesOptions[];
}

export function resetFormType() {
  return { resetType: ResetFormType.All };
}
export class OrgSitesForm {
  @required()
  //@minLength({ value: 1 })
  @greaterThanEqualTo({ value: 1, isArrayControl: true })
  organization: number[] = [];
  @required()
  //@minLength({ value: 1 })
  @greaterThanEqualTo({ value: 1, isArrayControl: true })
  site: number[] = [];
  /**
   *  @Note: @TODO: Need to figure out how to apply the validation decorators conditionally depending on component properties.
   * For the time being, validations are manually set using formcontrol.setValidators
   * */
  // @required()
  // @minLength({ value: 1 })
  // @greaterThanEqualTo({ value: 1, isArrayControl: true })
  @prop()
  employee: number[] = [];
}

@Component({
  selector: 'cf2-orgs-sites-employees',
  exportAs: 'orgComponent',
  styleUrls: ['./orgs-sites-employees.component.scss'],
  templateUrl: './orgs-sites-employees.component.html',
})
export class OrgsSitesEmployeesComponent implements OnInit {
  @ViewChild('sitesSelect') private sitesSelect: SelectFieldFixedComponent;
  @ViewChild('empSelect') private empSelect: AutoCompleteSelectFieldFixedComponent;
  @Input() horizontal = true;
  @Input() fg: IFormGroup<OrgSitesForm> = this.formBuilder.formGroup(new OrgSitesForm()) as IFormGroup<OrgSitesForm>;
  employeeRemovable = false;

  defaultFieldRules: FieldRules = {
    organization: {
      required: true,
    },
    sites: {
      required: true,
    },
    employees: {
      required: false,
    },
  };

  // if you want to override security rules then pass it as a input prop from host component
  @Input() securityRules: RolesAndRules = {
    EC: {
      ...this.defaultFieldRules,
      employees: {
        required: true,
      },
    },
    SM: this.defaultFieldRules,
    OM: this.defaultFieldRules,
    RM: this.defaultFieldRules,
    SC: this.defaultFieldRules,
    SCM: this.defaultFieldRules,
    SCO: this.defaultFieldRules,
    HD: this.defaultFieldRules,
    SSM: this.defaultFieldRules,
    AUD: this.defaultFieldRules,
  };

  getFieldRule(field: Fields, rule: keyof Rules) {
    return this.securityRules[this.userStateSvc.roleCode]?.[field]?.[rule];
  }

  orgOpts: OrgsOptions[] = [];
  siteOpts: any[] = [];
  siteOpts$;
  empOpts$;
  empOpts: { value: number; description: string }[] = [];
  role: string;
  parentOrganizationKey;
  parentSiteKey;
  parentEmployeeKey;

  @Input() set orgsValue(orgs: OrganizationModel[]) {
    if (!orgs) return;
    if (!this.sitesSelect || !this.empSelect) {
      setTimeout(() => (this.orgsValue = orgs));
      return;
    }

    const orgOpts = orgs
      .map((org) => ({
        value: org.parentOrganizationalUnitKey,
        description: org.organizationName,
        disabled: ['EC', 'SM'].includes(this.role) ? true : false,
        sites: org.sites.map(
          (site) =>
            ({
              ...site,
              description: `${org.organizationName} - ${site.siteName}`,
              value: site.parentSiteKey,
              orgKey: org.parentOrganizationalUnitKey,
            } as SitesOptions)
        ),
      }))
      .filter((opt) =>
        this.userStateSvc.editAllCaseRoles.concat(['SSM', 'RM']).includes(this.role) ? opt : opt.value === this.parentOrganizationKey
      );

    this.orgOpts = orgOpts;
    this.siteOpts = orgOpts.reduce((prev, curr) => prev.concat(...curr.sites), []);

    this.fg.controls.organization.patchValue(['EC', 'SM', 'OM'].includes(this.role) ? [this.parentOrganizationKey] : []);
    this.fg.controls.organization.updateValueAndValidity();

    this.fg.controls.site.patchValue(
      ['EC', 'SM'].includes(this.role)
        ? [this.parentSiteKey]
        : ['OM'].includes(this.role)
        ? this.siteOpts.filter((site) => site.orgKey === this.parentOrganizationKey).map((site) => site.value)
        : []
    );
    this.fg.controls.site.updateValueAndValidity();
    this.fg.controls.employee.patchValue(['EC'].includes(this.role) ? [this.parentEmployeeKey] : []);
    this.fg.controls.employee.updateValueAndValidity();
  }
  @Input() set empsValues(emps: PositionModel[]) {
    if (!emps) return;

    if (this.role === 'EC') {
      this.fg.controls.employee.setValidators([
        RxwebValidators.required(),
        RxwebValidators.minLength({ value: 1 }),
        RxwebValidators.greaterThanEqualTo({ value: 1, isArrayControl: true }),
      ]);

      //2021-10-18 KMW - CFO-6961
      //this.fg.controls.employee.disable();
    }

    this.empOpts =
      emps?.map((emp) => ({
        value: emp.parentEmployeeKey,
        description: emp.displayName,
        disabled: false,
        //2021-10-18 KMW - CFO-6961
        //disabled: role === 'EC' ? true : false,
        siteKey: emp.parentSiteKey,
        orgKey: emp.parentOrganizationalUnitKey,
      })) || [];
  }

  constructor(
    private userStateSvc: UserStateService,
    private formBuilder: RxFormBuilder,
    public optionsCacheSvc: OptionsDataService
  ) { }

  ngOnInit(): void {
    this.role = this.userStateSvc.roleCode;
    this.parentOrganizationKey = this.role === 'OM' ? this.userStateSvc.user.parentOrganizationalUnitKey : this.userStateSvc.user.reportsToOrganizationalUnitKey;
    this.parentSiteKey = this.userStateSvc.user.parentSiteKey;
    this.parentEmployeeKey = this.userStateSvc.user.parentEmployeeKey;

    if (this.role !== 'EC') this.employeeRemovable = true;

    this.siteOpts$ = this.fg.controls.organization.valueChanges.pipe(
      map(() => {
        /**
         * 1) Filter by selected organization
         * 2) if role is EC | SM then filter by org and filter by employee site
         * */
        let filtered = this.siteOpts.filter((site) => this.fg.controls.organization.value.includes(site.orgKey));
        filtered = this.role === 'EC' || this.role === 'SM' ? filtered.filter((site) => site.value === this.parentSiteKey) : filtered;

        // Check if fg.site.value exists in the dropdonnlist
        const dropdownListValues = filtered.map((site) => site.value);
        const existingValues = R.reject(
          this.fg.controls.site.value,
          (val: number) => !dropdownListValues.includes(val)
        );

        this.fg.controls.site.setValue(existingValues, {
          emitEvent: false,
          onlySelf: false,
        });
        this.fg.controls.site.updateValueAndValidity({
          onlySelf: false,
          emitEvent: false,
        });

        return filtered;
      })
    );
    this.empOpts$ = this.fg.controls.site.valueChanges.pipe(
      map(() => {
        /**
         * 1) Filter by selected sites
         * 2) if role is EC then filter by sites and filter by employee key
         * */
        let filtered = this.empOpts?.filter((emp: any) => this.fg.controls.organization?.value?.includes(emp.orgKey));
        //2021-10-18 KMW - CFO-6961
        //filtered = role === 'EC' ? filtered?.filter((emp: any) => parentEmployeeKey === emp.value) : filtered;

        // Check if fg.employee.value exists in the dropdonnlist
        const dropdownListValues = filtered.map((emp) => emp.value);
        const existingValues = R.reject(
          this.fg.controls.employee.value,
          (val: number) => !dropdownListValues.includes(val)
        );
        this.fg.controls.employee.setValue(existingValues, {
          emitEvent: false,
          onlySelf: false,
        });
        this.fg.controls.employee.updateValueAndValidity({
          onlySelf: false,
          emitEvent: false,
        });

        return filtered;
      })
    );
  }
}
