import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { UntypedFormControl } from '@angular/forms';
import { FormsComponentField } from '@core/models/base-component-field';

@Component({
  selector: 'cf2-currency-field-fixed',
  template: `
    <div #container class="input-field-fixed">
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label>
          {{ field ? field.label : label }} <span *ngIf="required || field?.required" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          placeholder="{{ placeHolder }}"
          type="text"
          autocomplete="off"
          mask="separator.2"
          [formControl]="ctrl"
          [disableControl]="disabled"
          [cf2Field]="field"
        />
        <span matPrefix>$&nbsp;</span>
        <!-- <span matSuffix>.00</span> -->
        <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
      <!-- </div> -->
    </div>
  `,
  styleUrls: ['./input-field-fixed.component.scss'],
})
export class CurrencyFieldFixedComponent {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() faIcon: IconDefinition;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() placeHolder = '';
  @Input() disabled = false;
  @Input() field: FormsComponentField;

  @Output() iconClick = new EventEmitter<boolean>();
}
