import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { dateBuilder } from '@core/models/date.model';
import { CaseNotesDataService } from '../services/case-notes-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cf2-case-notes-form',
  template: `
    <div class="case-notes-form" *ngIf="isInit">
      <div class="detail-row flex-row">
        <cf2-date-field-top
          [ctrl]="getFc('clientContactDate')"
          [label]="labels.clientContactDate"
          [required]="required.clientContactDate"
          [disabled]="disabled.clientContactDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></cf2-date-field-top>

        <ng-container *ngIf="showPrimaryCategoryField">
          <ng-container *ngIf="primaryCategoryOptions$ | async as options">
            <cf2-select-field-top
              [ctrl]="getFc('primaryCategoryCode')"
              [label]="labels.primaryCategoryCode"
              [required]="required.primaryCategoryCode"
              [disabled]="disabled.primaryCategoryCode"
              [options]="options"
            ></cf2-select-field-top>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="secondaryCategoryOptions$ | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('secondaryCategoryCode')"
            [label]="labels.secondaryCategoryCode"
            [required]="required.secondaryCategoryCode"
            [disabled]="disabled.secondaryCategoryCode"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>

        <ng-container *ngIf="logTypeOptions$ | async as options">
          <cf2-select-field-top
            [ctrl]="getFc('logTypeCode')"
            [label]="labels.logTypeCode"
            [required]="required.logTypeCode"
            [disabled]="disabled.logTypeCode"
            [options]="options"
          ></cf2-select-field-top>
        </ng-container>
      </div>

      <div class="comments-row">
        <editor
          [formControl]="getFc('comment')"
          [required]="true"
          [init]="editorConfiguration"
          [disableControl]="disabled.comment"
        ></editor>
        <div>
          <mat-error *ngIf="getFc('comment').hasError('maxLength')">
            Your comment exceeds exceeds the character limit
            {{ getFc('comment').getError('maxLength').message }}
          </mat-error>
          <ng-container *transloco="let t">  

            <mat-error *ngIf="getFc('comment').touched && getFc('comment').hasError('required')">
            {{ t('ACommentIsRequired') }}
            </mat-error>
          </ng-container>
          </div>
      </div>
    </div>
  `,
  styleUrls: ['./case-notes-form.component.scss'],
})
export class CaseNotesFormComponent extends BaseComponent implements OnInit {
  isInit = false;
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: any;
    disabled: any;
    required: any;
  };

  @Input() defaultCategory: string;
  @Input() showPrimaryCategoryField = false;
  primaryCategoryOptions$ = this.dataSvc.primaryCategory$.pipe(
    map((options) => options.filter((opt) => ['CLAIMS_OUTC', 'JOB_START', 'MONITOR'].includes(opt.value)))
  );
  secondaryCategoryOptions$: Observable<any>;
  logTypeOptions$ = this.dataSvc.loadContactTypes$();
  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;
  getFc: (formKey: string) => AbstractControl;
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);
  // configure the editor
  editorConfiguration = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 230,
    menubar: false,
    plugins: ['lists wordcount'],
    toolbar: 'bold italic underline | bullist numlist ',
    resize: false,
    statusbar: false,
    branding: false,
  };
  constructor(private dataSvc: CaseNotesDataService) {
    super();
  }

  setSecondaryCategory(primaryCategoryCode?: string) {
    if (primaryCategoryCode) {
      this.secondaryCategoryOptions$ = this.dataSvc.loadSecondarySubCategories$(primaryCategoryCode) as Observable<any>;
    } else {
      this.secondaryCategoryOptions$ = of([]);
    }
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-case-notes-form>, you have to initalize case note form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc(fg);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;

    if (fg.value.primaryCategoryCode) {
      this.setSecondaryCategory(fg.value.primaryCategoryCode);
    }

    const primaryCategoryChangeSub = fg.get('primaryCategoryCode').valueChanges.subscribe((val) => {
      this.setSecondaryCategory(val);
    });

    this.addSubscription(primaryCategoryChangeSub);
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
