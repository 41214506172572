import { UntypedFormGroup } from '@angular/forms';
import { CaseEmployeeModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';

export type CaseEmployeeKeysType = keyof CaseEmployeeModel;
export type CaseEmployeeLabelsType = Record<CaseEmployeeKeysType, string | boolean>;

export interface CaseEmployeeComponentField extends FormsComponentField {
  key: CaseEmployeeKeysType;
}

export const CASE_EMPLOYEE_CONFIG: readonly CaseEmployeeComponentField[] = [
  {
    key: 'parentEmployeeKey',
    label: 'Employee',
    type: 'select',
    required: true,
  },
];

export class CaseEmployeeForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: CaseEmployeeLabelsType;
    required: CaseEmployeeLabelsType;
    disabled: CaseEmployeeLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, private ts: TranslocoService, opts?: { value?: any }) {
    super(fb, ts);
    const config = CASE_EMPLOYEE_CONFIG;

    this._fields = opts ? (this.makeFields([...config], opts) as any) : (this.makeFields([...config]) as any);
    // console.log(this._fields);
  }
}
