import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ViewType } from '@core/models/view-type.model';
import { BehaviorSubject } from 'rxjs';
import { errorMapFn } from '@core/models/cf2-validators.model';

@Injectable({ providedIn: 'root' })
export class MultipleEntriesService<Model, Labels, EntityData> {
  formGroup: UntypedFormGroup;
  formFields: {
    fields: {
      fg: UntypedFormGroup;
      labels: Labels;
      required: Labels;
      disabled: Labels;
    };
  };
  externalFormGroup: UntypedFormGroup;
  externalForm: {
    fields: {
      fg: UntypedFormGroup;
      labels: Labels;
      required: Labels;
      disabled: Labels;
    };
  };
  selectedKey: number;
  entityData: EntityData[] = [];
  entityListDataSub$ = new BehaviorSubject<Model[]>([]);
  viewType: ViewType;

  setViewType(view: ViewType) {
    this.viewType = view;
  }

  setEntityData(Data: EntityData[]) {
    this.entityData = Data;
  }

  setEntityDataSource(data: Model[]) {
    this.entityListDataSub$.next(data);
  }

  sanitizeForm() {
    // externalForm and externalFormGroup should be sanitized in thier respective components which its being used
    this.formFields = null;
    this.formGroup = null;
    this.selectedKey = null;
  }

  sanitizeData() {
    // #HTODO: Sanitize forms, state, keys, currentkey, selectedkey, subscriptions
    this.sanitizeForm();
    this.viewType = null;
    this.entityData = [];
    this.entityListDataSub$.next([]);
  }

  isFormValid(external = false) {
    return external
      ? this.externalFormGroup === null || this.externalFormGroup === undefined || this.externalFormGroup.valid
      : this.formGroup === null || this.formGroup === undefined || this.formGroup.valid;
  }

  hasErrors(external = false) {
    const ret = external
      ? this.externalFormGroup?.touched && this.externalFormGroup?.invalid
      : this.formGroup?.touched && this.formGroup?.invalid;
    return ret === undefined ? false : ret;
  }

  errorList(prefix = '', external = false) {
    const errors: string[] = [];
    const fg = external ? this.externalFormGroup : this.formGroup;
    const fields = external ? this.externalForm?.fields : this.formFields?.fields;

    if (fg) {
      Object.keys(fg.controls).forEach((key) => {
        if (fg.controls[key].invalid) {
          const errorMessage = errorMapFn(fg.controls[key] as UntypedFormControl, prefix + fields?.labels?.[key]);
          errors.push(errorMessage);
        }
      });
    }

    return errors;
  }

  isFormDirty(external = false) {
    const fg = external ? this.externalFormGroup : this.formGroup;
    return fg?.dirty;
  }
}
