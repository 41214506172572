export const APP_KEYS = [
  'clientId',
  'applicationDataLoaded',
  'applicationTitle',
  'serviceTitle',
  'applicationVersion',
  'environment',
  'deploymentDate',
  'defaultGridRows',
  'maximumFileSizeMb',
  'fileTypes',
  'hstIndicator',
  'hstPercent',
  'gstPercent',
  'pstPercent',
  'displayName',
  'firstName',
  'invalid',
  'lastName',
  'email',
  'username',
  'functionalAccess',
  'loggedIn',
  'ignoreAuthentication',
  'languageCode',
  'siteName',
  'siteKey',
  'organizationName',
  'organizationKey',
  'roleCode',
  'roleDescription',
  'activeModule',
  'activeSubModule',
  'previousModule',
  'previousSubModule',
  'caseKey',
  'clientKey',
  'documentKey',
  'employerPositionKey',
  'employerPositionReferralKey',
  'clientDisplayName',
  'expenditureKey',
  'meetingKey',
  'eapKey',
  'meetingKey',
  'meetingOccurrenceKey',
  'monitoringPhaseKey',
  'outcomeKey',
  'parentInquiryKey',
  'caseOrgKey',
  'clientSearch',
  'inquirySearch',
  'caseLoadSearch',
  'caseNotesSearch',
  'employmentSearch',
  'expenditureSearch',
  'schedulingSearch',
  'outcomeClaimsSearch',
  'exportCasesSearch',
  'loginSuccessRedirectUri',
] as const;
