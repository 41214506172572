import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  // styleUrls: ['./edit-eap.component.scss'],
  template: `
    <!-- <div [ngClass]="{'level-1': to.type === 'level-1', 'level-2': to.type === 'level-2'}" style="padding: 16px; margin-bottom: 24px;" class="mat-elevation-z8 row" *ngFor="let field of field.fieldGroup || [{}]; let i = index;">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="col-sm-2 d-flex align-items-center" *ngIf="to.isHidden">
        <button mat-raised-button [disabled]="field?.templateOptions?.disableRemove" color="secondary" (click)="remove(i)">
          <mat-icon aria-hidden="false" color="warn" aria-label="delete icon">delete</mat-icon>  
          <span>Remove</span>
        </button>
      </div>
    </div>
    <div style="margin:30px 0;" *ngIf="to.isHidden">
      <button mat-raised-button [disabled]="!to.enableAdd" color="secondary" type="button" (click)="add()">
      <mat-icon aria-hidden="false" aria-label="add icon">add</mat-icon>  
      {{ to.addText }}</button>
    </div> -->
    <div *ngIf="to.isHidden; else eapClosed">
      <div *ngFor="let field of field.fieldGroup || [{}]; let i = index;" style="margin-bottom: 5px">
        <ng-container *ngIf="true">
          <cf2-alt-expansion-panel
            [isExpanded]="false"
            [disableExpansion]="false"
            titleText={{getHeaderText(field)}}>
            <panel-content>
              <div [ngClass]="{'level-1': to.type === 'level-1', 'level-2': to.type === 'level-2'}" style="padding: 16px;" class="mat-elevation-z8 row" >
                <formly-field class="col" [field]="field"></formly-field>
                <div class="col-sm-2 d-flex align-items-center">
                  <button mat-raised-button [disabled]="field?.templateOptions?.disableRemove" color="secondary" (click)="removeItem(i)">
                    <mat-icon aria-hidden="false" color="warn" aria-label="delete icon">delete</mat-icon>  
                    <span>Remove</span>
                  </button>
                  <button *ngIf="(field?.templateOptions?.disableRemove && to.type === 'level-2')"
                    mat-button color="secondary" matTooltipPosition="right"
                    matTooltip="A plan item cannot be deleted when the status is 'In progress' or 'Completed'. To delete a plan item, the Actual Start Date, Actual End Date, Plan Item Status, and Outcome needs to be removed.">
                    <mat-icon aria-hidden="false" style="color:#65758f" aria-label="help">help</mat-icon>
                  </button>
                </div>
              </div>
            </panel-content>
          </cf2-alt-expansion-panel>
        </ng-container>
      </div>
    </div>
    <ng-template #eapClosed>
      <div [ngClass]="{'level-1': to.type === 'level-1', 'level-2': to.type === 'level-2'}" style="padding: 16px; margin-bottom: 24px;" class="mat-elevation-z8 row" *ngFor="let field of field.fieldGroup || [{}]; let i = index;">
        <formly-field class="col" [field]="field"></formly-field>
        <div class="col-sm-2 d-flex align-items-center" *ngIf="to.isHidden">
          <button mat-raised-button [disabled]="field?.templateOptions?.disableRemove" color="secondary" (click)="remove(i)">
            <mat-icon aria-hidden="false" color="warn" aria-label="delete icon">delete</mat-icon>  
            <span>Remove</span>
          </button>
        </div>
      </div>
    </ng-template>
    <div style="margin:30px 0;" *ngIf="to.isHidden">
      <button mat-raised-button [disabled]="!to.enableAdd" color="secondary" type="button" (click)="add()">
        <mat-icon aria-hidden="false" aria-label="add icon">add</mat-icon>  
        {{ to.addText }}
      </button> 
      <button *ngIf="(!to.enableAdd && to.type === 'level-1')"
        mat-button color="secondary" matTooltipPosition="right"
        matTooltip="Plan-Items and Sub-Goals cannot be added/edited until the Start Monitoring Phase is finalized.">
        <mat-icon aria-hidden="false" style="color:#65758f" aria-label="help">help</mat-icon>
      </button>
      <button *ngIf="(!to.enableAdd && to.type === 'level-2')"
        mat-button color="secondary" matTooltipPosition="right"
        matTooltip="New Plan-Items cannot be added once the Sub-Goal Outcome has been defined or if the start monitoring checkpoint is in progress.">
        <mat-icon aria-hidden="false" style="color:#65758f" aria-label="help">help</mat-icon>
      </button>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {
  constructor() {
    super();
  }

  removeItem(i: any) {
    super.remove(i);

    if (this.field.key === 'subGoals') {
      //loop through all sub-goals and update validationResult to undefined
      this.field.fieldGroup.forEach(s => {
        s.fieldGroup[0].model.validationResult = undefined;
        s.fieldGroup[0].model.subGoalCode = null;
      });

      //re-evaluate all sub-goals
      this.field.fieldGroup.forEach(s => {
        s.fieldGroup[0].formControl.updateValueAndValidity();
      });
      console.log(this.field.fieldGroup);
    }
  }

  getHeaderText(field: any) {
    let labelText: string;
    if ((field.fieldGroup.find(f => f.key === 'subGoalCode')) !== undefined) {
      labelText = field.fieldGroup.filter(x => x.key == 'subGoalCode')[0].model.subGoalDescription;
    } else {
      field.fieldGroup.forEach(element => {
        if ((element.fieldGroup.find(f => f.key === 'planItemCode')) !== undefined) {
          labelText = element.fieldGroup.filter(f => f.key === 'planItemCode')[0].model.planItemDescription;
        }
      });
    }
    return labelText;
  }
}
