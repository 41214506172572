import { UntypedFormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Cf2Validators, ValidatorFns } from './cf2-validators.model';
import * as R from 'remeda';

import {
  FormsComponentFieldType,
  ValidatorMapFn,
  ValidatorOptionsType,
  FgBuilderDataType,
  FormsComponentField,
} from './base-component-field';

import { ReactiveFormConfig, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Injectable } from '@angular/core';
import { FormsComponentValuesType } from './base-component-field';
import { TranslocoService } from '@ngneat/transloco';

export const fcBuild = (val: string | Date, ...validators): UntypedFormControl => new UntypedFormControl(val, validators);
export const markAsTouched = (fg: UntypedFormGroup): void => {
  const controls = fg.controls;
  Object.keys(controls).forEach((key) => controls[key].markAllAsTouched());
  fg.updateValueAndValidity({ onlySelf: false, emitEvent: true });
};

export type FieldTypeRecord = Record<FormsComponentFieldType, ValidatorMapFn>;
export type DateTypes = Pick<FieldTypeRecord, 'birthdate' | 'futureDate' | 'pastDate' | 'time' | 'wideDate' | 'date'>;

/**
 * The apps form building class
 *
 * @export
 * @class Cf2Forms
 */
@Injectable({
  providedIn: 'root',
})
export class Cf2Forms {
  /**
   * Function for sanitizing forms that receive no input data on setup.
   * It will also sanitize non-truthy values
   *
   * @static
   * @param {*} [formData={}]
   * @memberof Cf2Forms
   */
  static sanitizeForm = (formData: any = {}) => {
    const blacklist = Object.keys(formData).filter(
      (key) =>
        formData[key] == undefined ||
        formData[key] == null ||
        formData[key].length < 1 ||
        (typeof formData[key] === 'string' && formData[key] === '')
    ) as any;

    const sanitized = R.omit(formData, blacklist);
    return sanitized;
  };
  private vFns = new Cf2Validators();

  /**
   * Field type map maps the validators to the types of input fields in a field config.
   *
   * Add a curried validator function with the min/max values. There is an options config
   * available to further customize types by mapping an options object ot your config and passing the options values through. See ValidatorFns.longString for an example
   *
   * @type {FieldTypeRecord}
   * @memberof Cf2Forms
   */
  fieldTypeMap: FieldTypeRecord = {
    birthdate: () => this.vFns.birthdateValidators.bind(this),
    cams: () => this.vFns.camsValidators,
    checkbox: () => () => [],
    confirmation: () => this.vFns.confirmationValidator,
    cra: () => this.vFns.craValidators,
    currency: () => this.vFns.currencyValidators,
    date: () => this.vFns.dateValidators,
    display: () => () => [],
    email: () => this.vFns.emailValidators,
    expectedCost: () => this.vFns.expectedCost,
    idValidated: () => this.vFns.idValidated,
    string: (options) => this.vFns.stringValidators(options),
    longString: (options) => this.vFns.longStringValidators(options),
    midLongString: (options) => this.vFns.midLongStringValidators(options),
    midString: (options) => this.vFns.midStringValidators(options),
    name: () => this.vFns.nameValidators,
    pastDate: () => this.vFns.pastDateValidators,
    phone: () => this.vFns.phoneValidator,
    postal: () => this.vFns.postalValidators,
    select: () => () => [],
    sin: () => this.vFns.sinValidator,
    sms: () => this.vFns.smsRequired,
    otherLanguage: () => this.vFns.otherLanguage,
    wideDate: () => this.vFns.wideDateValidators.bind(this),
    futureDate: () => this.vFns.dateValidators,
    time: () => this.vFns.timeValidators, //TODO: Create a Time Validator
  };

  private mapFcConfig(fnMap: FieldTypeRecord) {
    const map = fnMap;
    return (options: ValidatorOptionsType) => (type: FormsComponentFieldType) => map[type](options);
  }

  /**
   * make a form group
   *
   * @param data - a set of configured FormGroup builder data - this
   * is a tuple of the the value for the item and the data.
   * @returns tuple with the config key and a new form Control with the data in it
   *
   */
  private makeFg(data: FgBuilderDataType[]) {
    const builder = this.mapFcConfig(this.fieldTypeMap);
    // const filterDisplay = data.filter((obj) => obj.type !== 'display');
    const filterDisplay = data;
    const fgTuples = filterDisplay.map<[string, UntypedFormControl | [FormsComponentValuesType, ValidatorFn[]]]>((config) => {
      const value = config.value || config.value === 0 || config.value === false ? config.value : null;
      const validators = builder(config.options)(config.type)();
      return [config.key, ValidatorFns.makeFc(value, { validators, required: config.required, tuple: true })];
    });
    const group = fgTuples.reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {});
    const rxGroup = this.formBuilder.group(group, {});

    return rxGroup;
  }

  makeFields<T extends FormsComponentField>(arr: T[], opts: {} = {}) {
    if (arr.length < 1) throw new Error('no valid data passed to create fields');
    const fgValues = [...arr].map((val) =>
      R.pick<FormsComponentField, 'key' | 'type' | 'options' | 'value' | 'required' | 'disabled'>(val, [
        'key',
        'type',
        'options',
        'value',
        'required',
        'disabled',
      ])
    );
    
    const labels = this.makeLabels([...arr]);
    const required = this.makeRequired(arr);
    const disabled = this.makeDisabled(arr);

    const fg = this.makeFg(fgValues);

    return { fg, labels, required, disabled };
  }

  makeLabels(arr: FormsComponentField[], opts: {} = {}) {
    return arr
      .map((val) => R.pick<FormsComponentField, 'key' | 'label'>(val, ['key', 'label']))
      .reduce((prev, curr) => ({ ...prev, [curr.key]: this.translocoService.translate(curr.label) }), {});//curr.label
  }
  makeRequired(arr: FormsComponentField[]) {
    return arr
      .map((field) => R.pick<FormsComponentField, 'required' | 'key'>(field, ['required', 'key']))
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr.key]: curr.required != null || curr.required !== undefined ? curr.required : true,
        }),
        {}
      );
  }

  /**
   * Make formfields disabled
   *
   * @param {FormsComponentField[]} arr
   * @return {*}
   * @memberof Cf2Forms
   */
  makeDisabled(arr: FormsComponentField[]) {
    return arr
      .map((field) => R.pick<FormsComponentField, 'disabled' | 'key'>(field, ['disabled', 'key']))
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr.key]: curr.disabled != null || curr.disabled !== undefined ? curr.disabled : false,
        }),
        {}
      );
  }
  /**
   * Make types. Should be deprecated
   *
   * @static
   * @param {FormsComponentField[]} arr
   * @return {*}
   * @memberof Cf2Forms
   */
  static mapTypes(arr: FormsComponentField[]) {
    return R.mapToObj(arr, (field: FormsComponentField) => [field.key, field]);
  }

  /**
   * Creates an instance of Cf2Forms. This is where the validation messages are set.
   * @param {RxFormBuilder} formBuilder
   * @memberof Cf2Forms
   */
  constructor(public formBuilder: RxFormBuilder, protected translocoService : TranslocoService) {
    ReactiveFormConfig.set({
      validationMessage: {
        name: translocoService.translate("Error.InvalidCharacters"),
        sin: translocoService.translate("Message.Error.Sin"),                            
        alpha: translocoService.translate("Message.Error.Alpha"),                            
        alphaNumeric: translocoService.translate("Message.Error.AlphaNumeric"),                
        compare: translocoService.translate("Message.Error.Compare"),                    
        contains: translocoService.translate("Message.Error.Contains"),                    
        creditcard: translocoService.translate("Message.Error.CreditCard"),                  
        digit: translocoService.translate("Message.Error.Digit"),   
        email:  translocoService.translate("Message.Error.InvalidEmail"),                 
        greaterThanEqualTo: translocoService.translate("Message.Error.GreaterThanEqualTo"),                 
        greaterThan: translocoService.translate("Message.Error.GreaterThan"),                 
        hexColor: translocoService.translate("Message.Error.HexColor"),                    
        json: translocoService.translate("Message.Error.Json"),                       
        lessThanEqualTo: translocoService.translate("Message.Error.LessThanEqualTo"),            
        lessThan: translocoService.translate<string>("Message.Error.LessThan").replace("\\",'').replace("\\",''),                  
        lowerCase: translocoService.translate("Message.Error.LowerCase"),                
        maxLength: translocoService.translate<string>("Message.Error.MaxLength").replace("\\",'').replace("\\",''),                
        maxNumber: translocoService.translate<string>("Message.Error.MaxNumber").replace("\\",'').replace("\\",''),                
        minNumber: translocoService.translate<string>("Message.Error.MinNumber").replace("\\",'').replace("\\",''),                 
        password: translocoService.translate("Message.Error.Password"),                 
        pattern: translocoService.translate("Message.Error.Pattern"),                   
        range: translocoService.translate("Message.Error.Range"),                     
        required: translocoService.translate("Message.Error.Required"),                 
        time: translocoService.translate("Message.Error.Time"),                     
        upperCase: translocoService.translate("Message.Error.UpperCase"),                
        url: translocoService.translate("Message.Error.Url"),                      
        zipCode: translocoService.translate("Message.Error.ZipCode"),                  
        minLength: translocoService.translate<string>("Message.Error.MinLength").replace("\\",'').replace("\\",''),                
        requiredTrue: translocoService.translate("Message.Error.RequiredTrue"),             
        invalidSin: translocoService.translate("Message.Error.InvalidSin"),                            
        noneOf: translocoService.translate("Message.Error.NoneOf"),                         
        maxDate: translocoService.translate("Message.Error.MaxDate"),                  
        minDate: translocoService.translate("Message.Error.MinDate"),                  
        matDatepickerMin: translocoService.translate("Message.Error.MatDatepickerMin"),         
        matDatepickerMax: translocoService.translate("Message.Error.MatDatepickerMax"),         
        startsWith: translocoService.translate("Message.Error.PhoneStartsWith")                
      },
    });
  }
}