import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Cf2Inject } from '@core/decorators/cf2-inject.decorator';
import { dateBuilder } from '@core/models/date.model';
import { Cf2KeyValuePair } from '@core/models/store.model';
import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { Subscription, timer } from 'rxjs';
import { Globals } from 'src/app/globals';

import * as R from 'remeda';
import { LoggingService } from '@core/services/logging.service';
import { ModalService } from '@shared/services/modal.service';
import { CaseHeaderModel } from '@api/models';
import { ViewType } from '@core/models/view-type.model';

/**
 * Base component class for CF2. Has methods for routing, state and config.
 *
 * @export
 * @class BaseComponent
 */
@Component({
  selector: 'cf2-base',
  template: ``,
})
export class BaseComponent {
  /**
   * Injects the router from the component tree
   *
   * @private
   * @type {Router}
   * @memberof BaseComponent
   */
  @Cf2Inject(Router)
  private _router: Router;
  /**
   * Injects root globals
   *
   * @private
   * @type {Globals}
   * @memberof BaseComponent
   */
  @Cf2Inject(Globals)
  private _globals: Globals;
  /**
   * Variable for tracking ready state of component for loading spinners
   *
   * @memberof BaseComponent
   */

  @Cf2Inject(CF2_CONFIG)
  private _config: Cf2ConfigType;

  @Cf2Inject(NgZone)
  private _ngZone: NgZone;

  @Cf2Inject(ModalService)
  private _modalSvc: ModalService;

  isReady = false;

  /**
   * Calls the router navigate function with the given keys
   * @param page - string version of the url to navigate to
   * @param keys - Appkeys - valid keys that are used in managing state in Globals and a numeric value
   */
  navigate(page: string, keys: Partial<Cf2KeyValuePair>) {
    this.setGlobalKeys(keys);
    this._ngZone.run(() => this._router.navigate([page], { state: { ...keys } }));
  }

  generateUrl(path: string, queryParams?: any) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree([path], {queryParams})
    );
  
    console.log(url)
    return url;
  }

  openInNewTab(path: string, queryParams?: any) {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.generateUrl(path, queryParams);
  
    window.open(url, '_blank');
  }

  clearStore() {
    this._globals.clearStore();
  }

  setGlobalKeys(keys: Partial<Cf2KeyValuePair>) {
    const paired = keys ? R.toPairs<number>(keys as any) : [];
    paired.forEach((pair) => (this._globals[pair[0]] = pair[1]));
  }

  /**
   * Returns to the point of origin.
   *
   * @memberof BaseComponent
   */
  navigateBack() {
    const returnUrl = this._globals.returnUrl ? this._globals.returnUrl : 'home';
    this._ngZone.run(() => this._router.navigate([returnUrl]));
  }

  /**
   * Return the value of timeout
   *
   * @readonly
   * @memberof BaseComponent
   */
  get timeout() {
    return this._config.timeout;
  }

  /**
   * Mindate
   *
   * @memberof BaseComponent
   */
  public minDate = new Date(dateBuilder()[0] - 4, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /**
   * Today's date
   *
   * @memberof BaseComponent
   */
  public today = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);

  private _subscriptions: Subscription[] = [];
  /**
   * Unsubscribe to referenced observables
   *
   * @memberof BaseComponent
   */
  unsubscribe() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Add a subscription to the subscription list
   * @param sub - RXJS subscription
   */
  addSubscription(sub: Subscription) {
    this._subscriptions.push(sub);
  }

  /**
   * Add an array of subscriptions to be tracked for disposal on destruct
   * @param subs - array of subscriptions
   */

  addSubscriptions(subs: Subscription[]) {
    subs.forEach((sub) => this.addSubscription(sub));
  }
  /**
   * function to attach timeouts for loading spinners
   *
   * @return {*}
   * @memberof BaseComponent
   */
  timer$() {
    return timer(this._config.timeout);
  }

  /**
   * Return an error modal dialog
   *
   * @return {*}
   * @memberof BaseComponent
   */
  errorModal$() {
    return this._modalSvc.openDialog({ data: this._modalSvc.errorDialog }).afterClosed();
  }

  get currentUrl() {
    return this._router.url;
  }

  get returnUrl() {
    return this._globals.returnUrl;
  }
}
