import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import { APP_KEYS } from './app-keys';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/no-empty-interface

export type AppKeysType = typeof APP_KEYS[number];

export type KeyStoreType = { key: AppKeysType; value: number | string };

export type Cf2KeyValuePair = Record<AppKeysType, number | string>;
export class Cf2Store {
  private _lf = sessionStorage;

  ready$ = new BehaviorSubject<boolean>(false);

  constructor() {

    this.ready$.next(true);
  }
  setItem<K extends string, T>(pair: { key: K; value: T }) {
    const { key = '', value = 0 } = pair;
    const isValid = key !== 'invalid';
    if (isValid) {
      this._lf.setItem(key, value.toString());
      return of({ key, value })
    } else {
      return of(null);
    }
  }

  getItem(key: AppKeysType) {
    return of(this._lf.getItem(key));
  }

  delete(key: AppKeysType) {
    this._lf.removeItem(key);
  }

  protected clear() {
    this._lf.clear();
  }

  batchKeys(keyValues: Partial<Cf2KeyValuePair> = null) {
    const keys = Object.keys(keyValues);

    const keyPairs = keys
      .filter((key) => APP_KEYS.includes(key as any))
      .map((key) => this.setItem({ key, value: keyValues[key] }).pipe(map((value) => ({ key, value }))));

    return forkJoin(keyPairs);
  }
}
