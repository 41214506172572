import { Component } from '@angular/core';
import { tableFieldFactory, Cf2TableConfig } from '@core/types/cf2-table-field.type';

import { CaseModel } from '@api/models'
import { BaseComponent } from '@shared/components/base/base.component';
import { CaseService } from '@api/services/case.service';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { InactiveCaseKeysType, INACTIVE_CASE_LIST_HEADERS } from '@modules/home/models/inactive-cases-card-models';
import { TranslocoService } from '@ngneat/transloco';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'cf2-inactive-cases-card',
  templateUrl: './inactive-cases-card.component.html',
  styleUrls: [ './inactive-cases-card.component.scss'],
})
export class InactiveCasesCardComponent extends BaseComponent {
  data$ = this.caseSvc.apiCaseInactiveCasesGet$Json({})
    .pipe(
/*      map((icm) =>
      icm.map((cm) => ({ ...cm, clientDisplayName : cm.client.displayName, clientBusinessKey2 : cm.client.clientBusinessKey2 }))
      )*/
    );

  setupMap: Cf2TableConfig<InactiveCaseKeysType>;
  columnConfig: Cf2TableConfig<InactiveCaseKeysType>;

  constructor(private caseSvc: CaseService, private translocoService : TranslocoService, public globals: Globals) {
    super();
     const tableConfig = INACTIVE_CASE_LIST_HEADERS.map((tuples) =>
       tableFieldFactory(tuples[0], { label: translocoService.translate(tuples[1]), type: tuples[2], sortable: true })
     );

     const icMap = new Cf2TableConfig<InactiveCaseKeysType>();
     tableConfig.forEach((field) => icMap.set(field.key, field));
     this.setupMap = icMap;

     this.columnConfig = icMap;
  }

  viewInactiveCase(inactivecase: CaseModel) {
    this.globals.caseKey = inactivecase.parentCaseKey;
    this.navigate('clients', {
      clientKey: inactivecase.parentClientKey,
      caseKey: inactivecase.parentCaseKey,
    });
  }
}
