import { Component, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SiteModel } from '@api/models';
import { InquiriesService, Organization } from '@modules/inquiries/services/inquiries.service';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-booking-filter',
  template: `
    <div class="bookingFilter">
      <span class="p-2 drawer-subheader">City</span>
      <mat-selection-list
        dense
        class="select-all"
        [multiple]="true"
        [disabled]="fetchingFilters"
        (selectionChange)="selectAllCities(regionListAll.selectedOptions.selected)"
        #regionListAll
      >
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let subregion of subRegionsAll"
          [selected]="subregion.selected"
          [value]="subregion.name"
          [@fadeInOut]
        >
          {{ subregion.name }}
        </mat-list-option>
      </mat-selection-list>
      <mat-selection-list
        dense
        class="not-select-all"
        [multiple]="true"
        [disabled]="fetchingFilters"
        (selectionChange)="fetchOrganizations(regionList.selectedOptions.selected)"
        #regionList
      >
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let subregion of subRegions"
          [selected]="subregion.selected"
          [value]="subregion.name"
          [@fadeInOut]
        >
          {{ subregion.name }}
        </mat-list-option>
      </mat-selection-list>

      <div class="p-2 drawer-subheader">Organization</div>

      <mat-selection-list
        dense
        class="select-all"
        [multiple]="true"
        [disabled]="fetchingFilters"
        (selectionChange)="selectAllOrganizations(orgListAll.selectedOptions.selected)"
        #orgListAll
      >
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let org of organizationsAll"
          [selected]="org.selected"
          [value]="org.name"
          [@fadeInOut]
        >
          {{ org.name }}
        </mat-list-option>
      </mat-selection-list>
      <mat-selection-list
        dense
        class="not-select-all"
        [multiple]="true"
        [disabled]="fetchingFilters"
        (selectionChange)="fetchSites(orgList.selectedOptions.selected)"
        #orgList
      >
        <mat-list-option
          checkboxPosition="before"
          *ngFor="let org of organizations"
          [selected]="org.selected"
          [value]="org.name"
          [@fadeInOut]
        >
          {{ org.name }}
        </mat-list-option>
      </mat-selection-list>

      <div class="p-2 drawer-subheader">Site</div>

      <mat-selection-list
        *ngIf="sites !== undefined"
        class="sites"
        dense
        [multiple]="false"
        (selectionChange)="changeSiteEvent(siteList.selectedOptions.selected[0])"
        [disabled]="fetchingFilters"
        #siteList
      >
        <mat-list-option
          color="primary"
          checkboxPosition="before"
          *ngFor="let site of sites"
          [selected]="site.parentSiteKey === this.parentSiteKey"
          [value]="site.siteName"
          [@fadeInOut]
        >
          {{ site.siteName }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  `,
  styleUrls: ['./booking-filter.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(200)),
    ]),
  ],
})
export class BookingFilterComponent {
  @Output() siteChange = new EventEmitter<any>();

  public isLoading = true;

  public subRegionsMaster: Organization[] = [];
  public organizationsMaster: Organization[] = [];
  public sitesMaster: SiteModel[] = [];

  public subRegions: Organization[] = [];
  public subRegionsAll: Organization[] = [];
  public organizations: Organization[] = [];
  public organizationsAll: Organization[] = [];
  public sites: SiteModel[] = [];

  public parentSiteKey = -1;
  public fetchingFilters = true;

  constructor(private inquiriesService: InquiriesService) {
    // default the site
    if (
      this.inquiriesService.inquiry !== undefined &&
      this.inquiriesService.inquiry.parentSiteKey !== undefined &&
      this.inquiriesService.inquiry.parentSiteKey !== null
    ) {
      this.parentSiteKey = this.inquiriesService.inquiry.parentSiteKey;
    }

    if (this.inquiriesService.inquiry !== undefined) {
      // reset data
      for (let i = 0; i < this.inquiriesService.subRegions.length; i++) {
        this.inquiriesService.subRegions[i].selected = true;
      }

      for (let i = 0; i < this.inquiriesService.organizations.length; i++) {
        this.inquiriesService.organizations[i].selected = true;
      }

      // get sites / organizations & cities
      if (this.inquiriesService.sites !== null) this.sitesMaster = [...this.inquiriesService.sites];
      if (this.inquiriesService.organizations !== null)
        this.organizationsMaster = [...this.inquiriesService.organizations];
      if (this.inquiriesService.subRegions !== null) this.subRegionsMaster = [...this.inquiriesService.subRegions];

      // add "select all"
      this.organizationsAll.push({ name: '(Select All)', sdos: [], selected: true, sites: [] });
      this.subRegionsAll.push({ name: '(Select All)', sdos: [], selected: true, sites: [] });

      // get sites / orgs / cities
      this.buildSubRegionList();
      this.buildOrganizationList();
      this.buildSiteList();

      // sort the arrays
      this.sortArrays();

      // done!
      this.fetchingFilters = false;
    }
  }

  // sort the records!
  private sortArrays(): void {
    if (this.subRegions !== undefined) {
      this.subRegions = this.subRegions.sort((a: Organization, b: Organization) => {
        return this.inquiriesService.compareText(a.name, b.name, true);
      });
    }

    if (this.organizations !== undefined) {
      this.organizations = this.organizations.sort((a: Organization, b: Organization) => {
        return this.inquiriesService.compareText(a.name, b.name, true);
      });
    }

    if (this.sites !== undefined) {
      this.sites = this.sites.sort((a: SiteModel, b: SiteModel) => {
        return this.inquiriesService.compareText(a.siteName, b.siteName, true);
      });
    }
  }

  // select all functionality
  public selectAllCities(regionsAll: MatListOption[]): void {
    if (regionsAll === undefined || regionsAll.length === 0) {
      this.organizationsAll = [];

      // deselect all cities
      this.subRegions.forEach((region) => {
        region.selected = false;
      });

      // hide all service delivery organizations and sites
      this.organizations = [];
      this.sites = [];
    } else {
      if (this.organizationsAll.length === 0) {
        this.organizationsAll.push({ name: '(Select All)', sdos: [], selected: true, sites: [] });
      }

      // select all cities
      this.subRegions.forEach((region) => {
        region.selected = true;
      });

      // select all organizations
      this.organizations.forEach((organization) => {
        organization.selected = true;
      });

      // reset all service delivery organizations and sites
      this.buildOrganizationList();
      this.buildSiteList();

      this.sortArrays();
      return;
    }
  }

  public selectAllOrganizations(organizationsAll: MatListOption[]): void {
    if (organizationsAll === undefined || organizationsAll.length === 0) {
      // deselect all service delivery organizations
      this.organizations.forEach((organization) => {
        organization.selected = false;
      });

      // hide all sites
      this.sites = [];
    } else {
      // select all service delivery organizations
      this.organizations.forEach((organization) => {
        organization.selected = true;
      });

      // reset the sites
      this.buildSiteList();

      this.sortArrays();
      return;
    }
  }

  // region (city) selection change event
  public fetchOrganizations(regions: MatListOption[]): void {
    this.organizations = [];
    this.sites = [];

    // all select - just reset and we're done
    if (regions.length === this.subRegionsMaster.length) {
      this.subRegionsAll[0].selected = true;
      this.organizationsAll[0].selected = false;

      this.subRegions = [...this.subRegionsMaster];
      this.organizations = [...this.organizationsMaster];
      this.sites = [...this.sitesMaster];

      this.sortArrays();
      return;
    }

    // deselect the "Select All" option
    this.subRegionsAll[0].selected = false;

    if (this.organizationsAll.length === 0) {
      this.organizationsAll.push({ name: '(Select All)', sdos: [], selected: true, sites: [] });
    }

    // update the main sub region list
    if (this.subRegions !== undefined) {
      this.subRegions.forEach((regionMaster) => {
        regionMaster.selected = false;

        regions.forEach((subRegion) => {
          if (subRegion.value === regionMaster.name) {
            regionMaster.selected = true;
          }
        });
      });
    }

    // update the organizations and sites
    this.buildOrganizationList();
    this.buildSiteList();

    // sort arrays
    this.sortArrays();
  }

  // organization selection change event
  public fetchSites(organizations: MatListOption[]): void {
    this.sites = [];

    // all select - just reset and we're done
    if (organizations.length === this.organizationsMaster.length) {
      this.organizationsAll[0].selected = true;

      this.organizations = this.organizationsMaster;
      this.sites = this.sitesMaster;
      this.sortArrays();
      return;
    }

    // deselect the "Select All" option
    this.organizationsAll[0].selected = false;

    // update the main organization list
    if (this.organizations !== undefined) {
      this.organizations.forEach((organizationMaster) => {
        organizationMaster.selected = false;

        organizations.forEach((organization) => {
          if (organization.value === organizationMaster.name) {
            organizationMaster.selected = true;
          }
        });
      });
    }

    // update the sites
    this.buildSiteList();

    // sort arrays
    this.sortArrays();
  }

  // site selected!
  public changeSiteEvent(site: MatListOption): void {
    // build sites
    let index = -1;

    if (this.sites !== undefined) {
      for (let i = 0; i < this.sites.length; i++) {
        if (site.value === this.sites[i].siteName) {
          index = i;
          break;
        }
      }
    }

    if (index === -1) return;

    if (this.sitesMaster !== undefined) {
      this.sitesMaster.forEach((siteTemp) => {
        if (siteTemp.siteName === this.sites[index].siteName) {
          const parentSiteKey = siteTemp.parentSiteKey;
          this.parentSiteKey = parentSiteKey;

          // raise event to parent to update the list
          this.siteChange.emit(siteTemp);
        }
      });
    }
  }

  // create a filtered sub region list
  private buildSubRegionList(): void {
    if (this.subRegionsMaster !== undefined) {
      for (let i = 0; i < this.subRegionsMaster.length; i++) {
        if (this.subRegions.length === 0) {
          const suborg: Organization = {
            name: this.subRegionsMaster[i].name,
            sdos: [],
            sites: [],
            selected: true,
          };

          this.subRegions.push(suborg);
        } else {
          let found = false;

          for (let j = 0; j < this.subRegions.length; j++) {
            if (this.subRegionsMaster[i].name === this.subRegions[j].name) {
              found = true;
            }
          }

          if (!found) {
            const suborg: Organization = {
              name: this.subRegionsMaster[i].name,
              sdos: [],
              sites: [],
              selected: true,
            };

            this.subRegions.push(suborg);
          }
        }
      }
    }
  }

  // build filtered organizations list
  public buildOrganizationList(): void {
    if (this.organizationsMaster !== undefined) {
      for (let i = 0; i < this.organizationsMaster.length; i++) {
        let canAdd = false;
        const masterName = this.organizationsMaster[i].name;

        this.subRegionsMaster.forEach((regionMaster) => {
          this.subRegions.forEach((region) => {
            if (region.selected && region.name === regionMaster.name) {
              if (regionMaster.sdos.includes(masterName)) {
                canAdd = true;
              }
            }
          });
        });

        if (canAdd) {
          if (this.organizations.length === 0) {
            const suborg: Organization = {
              name: this.organizationsMaster[i].name,
              sdos: [],
              sites: [],
              selected: true,
            };

            this.organizations.push(suborg);
          } else {
            let found = false;

            for (let j = 0; j < this.organizations.length; j++) {
              if (this.organizationsMaster[i].name === this.organizations[j].name) {
                found = true;
              }
            }

            if (!found) {
              const suborg: Organization = {
                name: this.organizationsMaster[i].name,
                sdos: [],
                sites: [],
                selected: true,
              };

              this.organizations.push(suborg);
            }
          }
        }
      }
    }
  }

  // build filtered site list
  public buildSiteList(): void {
    let allowedRegions: string[] = [];
    this.sites = [];

    if (this.sitesMaster !== undefined) {
      for (let i = 0; i < this.sitesMaster.length; i++) {
        let canAdd = false;
        const masterName = this.sitesMaster[i].siteName;

        this.subRegionsMaster.forEach((regionMaster) => {
          this.subRegions.forEach((region) => {
            if (region.selected && region.name === regionMaster.name) {
              if (!allowedRegions.includes(region.name)) {
                allowedRegions.push(region.name);
              }

              // organizations
              regionMaster.sdos.forEach((regionSdo) => {
                this.organizations.forEach((organization) => {
                  if (organization.selected && organization.name === regionSdo) {
                    this.organizationsMaster.forEach((organizationMaster) => {
                      if (organizationMaster.name === organization.name) {
                        if (organizationMaster.sites.includes(masterName)) {
                          canAdd = true;
                        }
                      }
                    });
                  }
                });
              });
            }
          });
        });

        if (canAdd) {
          // validate the city is active
          if (allowedRegions.includes(this.sitesMaster[i].subRegionName)) {
            const suborg: SiteModel = {
              siteName: this.sitesMaster[i].siteName,
            };

            this.sites.push(suborg);
          }
        }
      }
    }
  }
}
