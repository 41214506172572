import { Directive, ElementRef, Input } from '@angular/core';
import { DateTypes } from '@core/models/cf2-forms.model';
import { dateBuilder } from '@core/models/date.model';
import { FormsFns } from '@core/models/forms-fns.model';
import { DateFieldFixedComponent } from '@shared/components/input-field-fixed/date-field-fixed.component';

@Directive({
  selector: '[cf2DateType]',
})
export class DateTypeDirective {
  pastDate = {
    minDate: new Date(dateBuilder()[0] - 4, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0),
    maxDate: new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]),
  };

  date = {
    minDate: new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0),
    maxDate: new Date(dateBuilder()[0] + 2, dateBuilder()[1], dateBuilder()[2]),
  };
  select = {
    minDate: null,
    maxDate: null,
  };
  @Input() set cf2DateType(type: keyof DateTypes) {
    const { minDate, maxDate } = this[type] || this.date;
    this.field.maxDate = maxDate;
    this.field.minDate = minDate;
    // 'birthdate' | 'futureDate' | 'pastDate' | 'time' | 'wideDate' | 'date';
  }
  constructor(private field: DateFieldFixedComponent) {}
}
