import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { dateBuilder } from '@core/models/date.model';
import { monthVal } from '../../models/date-formats.model';
import { faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'cf2-upload-box',
  template: `
    <div class="upload-group">
      <div class="upload-control">
        <ngx-dropzone
          (change)="onSelect($event)"
          #uploader
          [multiple]="multipleFiles"
          [accept]="allowedFileTypes"
          [maxFileSize]="maxFileSize"
          [class.no-files-border]="noFiles"
          [aria-label]="'Upload file'"
        >
          <ngx-dropzone-label>
            <span class="material-icons">
              cloud_upload
            </span>
            <div>Drag files to Upload</div>
            <div>or</div>
            <div>
              <a href="javascript:void(0)">Browse</a>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
        <ng-container *ngIf="invalidTypeText">
          <span class="invalid-type-msg">{{ invalidTypeText }}</span>
        </ng-container>
      </div>

      <div class="file-list" [class.no-files-border]="noFiles">
        <ng-container *ngFor="let file of files; index as i">
          <cf2-file-row
            [displayName]="displayName"
            [fileTypeStr]="file.type"
            [fileName]="file.name"
            [docType]="fileType"
            [date]="date"
            [fileSize]="file.size"
            [index]="i"
            (remove)="onRemove($event)"
          ></cf2-file-row>
        </ng-container>
        <ng-container *ngIf="noFiles">
          <span class="no-files-text">A file must be selected for upload</span>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./upload-box.component.scss'],
})
export class UploadBoxComponent implements OnInit {

  @Input() multipleFiles = false;
  @Input() displayName = 'No client set';
  @Input() noFiles = false;
  @Input() fileType: string;
  @Input() fileDate: string;
  @Input() date: string;
  @Input() maxFileSizeMB: number;

  @Output() fileBlobsUploaded = new EventEmitter<File[]>();

  faLockOpen = faLockOpen;
  faLock = faLock;

  // browse Link opens the file prompt
  @Input() files: any[] = [];

  // limit for file types (set from global config)
  allowedFileTypes: string;

  // bytes - default to max 10mb (set from global config)
  maxFileSize = 0;

  invalidTypeText: string;

  constructor(private globals: Globals) { }
  ngOnInit(): void {
    const dateTuple = dateBuilder();
    const year = dateTuple[0];
    const month = monthVal[dateTuple[1]];
    const day = dateTuple[2];

    // get the saved document data 
    if (this.files[0]?.fileDate) {
      this.date = this.files[0].fileDate;

      /* set today's date value as all files uploaded will be today's date */
    } else {
      this.date = `${day} - ${month} - ${year}`;
    }

    /* file size multiplied by 1024 for conversion */
    this.maxFileSize = (this.maxFileSizeMB ? this.maxFileSizeMB : this.globals.maximumFileSizeMb) * 1048576;
    this.allowedFileTypes = this.globals.fileTypes.join(', ');
  }

  onSelect(event) {
    this.files = event.addedFiles;
    this.invalidTypeText = null;

    if (event.addedFiles.length > 0) {
      this.fileBlobsUploaded.emit(this.files);
    } else {
      console.log(event.rejectedFiles);
      if (event.rejectedFiles.some((r) => r.reason === 'type')) {
        this.invalidTypeText = 'The file type is not accepted';
      } else if (event.rejectedFiles.some((r) => r.reason === 'size')) {
        this.invalidTypeText = 'The file size cannot exceed ' + this.maxFileSize / 1048576 + ' MB.';
      }
    }
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileBlobsUploaded.emit(this.files);
  }
}
