/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserAccess } from '../models/user-access';
import { UserClaim } from '../models/user-claim';
import { UserModel } from '../models/user-model';
import { UserPreferenceValue } from '../models/user-preference-value';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUserGet
   */
  static readonly ApiUserGetPath = '/api/User';

  /**
   * Get the current user's record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<UserModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserGetPath, 'get');

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModel>;
      })
    );
  }

  /**
   * Get the current user's record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Json(params?: {

  }): Observable<UserModel> {

    return this.apiUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserModel>) => r.body as UserModel)
    );
  }

  /**
   * Path part for operation apiUserTokenGet
   */
  static readonly ApiUserTokenGetPath = '/api/User/Token';

  /**
   * Get the current user's token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserTokenGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTokenGet$Json$Response(): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserTokenGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the current user's token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserTokenGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserTokenGet$Json(): Observable<string> {

    return this.apiUserTokenGet$Json$Response().pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiUserClaimsGet
   */
  static readonly ApiUserClaimsGetPath = '/api/User/Claims';

  /**
   * Get the current user's AD claims.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserClaimsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserClaimsGet$Json$Response(): Observable<StrictHttpResponse<Array<UserClaim>>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserClaimsGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserClaim>>;
      })
    );
  }

  /**
   * Get the current user's AD claims.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserClaimsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserClaimsGet$Json(): Observable<Array<UserClaim>> {

    return this.apiUserClaimsGet$Json$Response().pipe(
      map((r: StrictHttpResponse<Array<UserClaim>>) => r.body as Array<UserClaim>)
    );
  }

  /**
   * Path part for operation apiUserAccessGet
   */
  static readonly ApiUserAccessGetPath = '/api/User/Access';

  /**
   * Get the current user's functional access.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserAccessGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserAccessGet$Json$Response(): Observable<StrictHttpResponse<Array<UserAccess>>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserAccessGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserAccess>>;
      })
    );
  }

  /**
   * Get the current user's functional access.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserAccessGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserAccessGet$Json(): Observable<Array<UserAccess>> {

    return this.apiUserAccessGet$Json$Response().pipe(
      map((r: StrictHttpResponse<Array<UserAccess>>) => r.body as Array<UserAccess>)
    );
  }

  /**
   * Path part for operation apiUserGroupsGet
   */
  static readonly ApiUserGroupsGetPath = '/api/User/Groups';

  /**
   * Get the current user's Groups using claims and MS Graph.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserGroupsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGroupsGet$Json$Response(): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserGroupsGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the current user's Groups using claims and MS Graph.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserGroupsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGroupsGet$Json(): Observable<string> {

    return this.apiUserGroupsGet$Json$Response().pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiUserPreferencesGet
   */
  static readonly ApiUserPreferencesGetPath = '/api/User/Preferences';

  /**
   * Get current user filter preferences.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPreferencesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPreferencesGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<UserPreferenceValue>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPreferencesGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceValue>;
      })
    );
  }

  /**
   * Get current user filter preferences.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPreferencesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPreferencesGet$Json(params?: {

  }): Observable<UserPreferenceValue> {

    return this.apiUserPreferencesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceValue>) => r.body as UserPreferenceValue)
    );
  }

  /**
   * Path part for operation apiUserPreferencesPost
   */
  static readonly ApiUserPreferencesPostPath = '/api/User/Preferences';

  /**
   * Save current user filter preferences.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPreferencesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPreferencesPost$Json$Response(params?: {body?: UserPreferenceValue}): Observable<StrictHttpResponse<UserPreferenceValue>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPreferencesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserPreferenceValue>;
      })
    );
  }

  /**
   * Save current user filter preferences.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPreferencesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPreferencesPost$Json(params?: {
      body?: UserPreferenceValue
  }): Observable<UserPreferenceValue> {

    return this.apiUserPreferencesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserPreferenceValue>) => r.body as UserPreferenceValue)
    );
  }

}
