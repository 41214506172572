import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClientAddressModel, CodeTableModel } from '@api/models';
import { SelectOptions } from '@core/models/select-options.model';
import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { AddressForm, AddressLabelsType } from '@shared/components/address/models/address-form.model';
import * as R from 'remeda';
import { map, tap } from 'rxjs/operators';
import { MultipleEntriesData } from '@shared/components/multiple-entries/multiple-entries.model';
import { MultipleEntriesService } from '@shared/components/multiple-entries/multiple-entries-data.service';
import { Globals } from 'src/app/globals';
import { parseDate } from '@progress/kendo-angular-intl';
import { TranslocoService } from '@ngneat/transloco';

interface EntityData extends MultipleEntriesData<ClientAddressModel> {}

@Injectable({ providedIn: 'root' })
export class AddressDataService extends MultipleEntriesService<ClientAddressModel, AddressLabelsType, EntityData> {
  // ExternLFormGroup is currently used in [Client Case Create/edit form] in [Details Tab] Address form
  // This is intialized in client-case-details-form.component.ts
  // this should be sanitized in client-case-details-data.service.ts
  externalFormGroup: UntypedFormGroup;
  externalForm: AddressForm;

  addressTypeList: SelectOptions[] = [];
  provinceTypeList: SelectOptions[] = [];

  getIsReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  constructor(private fb: RxFormBuilder, private dataSvc: DataService, private globals: Globals, private translocoService : TranslocoService) {
    super();
  }

  get addressTypes$() {
    return this.dataSvc.lookupRecords('AddressType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => (this.addressTypeList = result))
    );
  }

  get provinceTypes$() {
    return this.dataSvc.lookupRecords('ProvinceType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => (this.provinceTypeList = result))
    );
  }

  initForm(value?: Partial<ClientAddressModel>) {
    this.formFields = value ? new AddressForm(this.fb, this.translocoService, { value: value }) : new AddressForm(this.fb, this.translocoService);
    this.formFields.fields.fg.patchValue(value);
    this.formGroup = this.formFields.fields.fg;

    return this.formFields;
  }

  initExternalAddressForm(value?: Partial<ClientAddressModel>) {
    if (value) {
      this.externalForm = new AddressForm(this.fb, this.translocoService, { value: value });
      this.externalForm.fields.fg.patchValue(value, {
        emitEvent: false,
      });
    } else {
      this.externalForm = new AddressForm(this.fb, this.translocoService );
    }

    this.externalFormGroup = this.externalForm.fields.fg;

    return this.externalForm;
  }

  getEntityDataByKey(key: number) {
    return this.entityData.filter((entityData) => entityData.entity.key === key)[0];
  }

  getEntityData(config: { sanitizeParentKey: boolean } = { sanitizeParentKey: false }) {
    const filtered = R.reject(this.entityData, (entityData) => {
      // return !!entityData.action && entityData.action === 'DELETE' && !entityData.entity.primaryAddressIndicator;
      return !!entityData.action && entityData.action === 'DELETE';
    });
    return filtered.map((obj) => {
      if (config.sanitizeParentKey)
        return {
          ...obj.entity,
          // send null for newly created entities
          key: obj.entity.key >= 1 ? obj.entity.key : null,
        } as ClientAddressModel;
      else return obj.entity;
    });
  }

  getPrimaryAddress() {
    return R.find(this.entityData, (data) => data.entity.primary)?.entity; // return entity data obj
  }

  constructEntityData(data: EntityData) {
    const entityData = data;

    entityData.entity.key = entityData.tempKey;

    // check if list values already exists
    if (this.addressTypeList.length > 0) {
      this.addressTypeList.forEach((listItem) => {
        if (listItem.value === entityData.entity.code) {
          entityData.entity.addressTypeDescription = listItem.description;
        }
      });
    }

    // check if list values already exists
    if (this.provinceTypeList.length > 0) {
      this.provinceTypeList.forEach((listItem) => {
        if (listItem.value === entityData.entity.provinceCode) {
          entityData.entity.provinceDescription = listItem.description;
        }
      });
    }

    return entityData;
  }

  resetPrimary() {
    this.entityData = this.entityData.map((data) => {
      if (data.entity.primary) {
        data.action = 'UPDATE';
        data.entity.primary = false;
      }
      return data;
    });
  }

  addEntityData(entityData: EntityData) {
    // create new casenote
    entityData.action = 'CREATE';
    const newEntry = this.constructEntityData(entityData);

    this.entityData.push(newEntry);

    return newEntry;
  }

  updateEntityData(entityData: EntityData) {
    this.entityData = this.entityData.map((data) => {
      if (data.entity.key === entityData.tempKey) {
        // construct updated caseNoteData
        data.entity = {
          ...data.entity,
          ...this.constructEntityData(entityData).entity,
        };

        // Update exising case notes
        if (data.entity.key  >= 1) {
          data.action = 'UPDATE';
        }
      }

      return data;
    });
  }

  mapEntityData(entities: ClientAddressModel[]): EntityData[] {
    const entityData = entities.map((data) => {
      return {
        key: data.key,
        entity: {
          ...data,
          primary: data.primary ? true : false, // Need explict value for checkbox
          addressEffectiveDate: data.addressEffectiveDate
            ? parseDate(data.addressEffectiveDate)
            : null,
          addressExpiryDate: data.addressExpiryDate
            ? parseDate(data.addressExpiryDate)
            : null,
        },
      };
    });

    return entityData;
  }

  addUpdatePrimaryAddressData(primaryAddress: Partial<ClientAddressModel>) {
    const hasPrimaryAddress = this.getPrimaryAddress();

    // update existing
    if (hasPrimaryAddress) {
      this.updateEntityData({
        tempKey: hasPrimaryAddress.key,
        entity: primaryAddress,
      });
    } else {
      this.addEntityData({
        tempKey: 0.1, // initialize a local key fo tracking, sanitize before saving
        entity: primaryAddress,
      });
    }

    // update entity data source to show in the lisst component
    this.setEntityDataSource(this.getEntityData());
  }

  setExternalFormGroup(primaryAddressValue: Partial<ClientAddressModel>) {
    this.externalFormGroup.patchValue(primaryAddressValue, {
      emitEvent: true,
    });
  }
}
