import { Component, Input, ViewChild, ElementRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import {casefloDateFormat, CUSTOM_DATE_FORMATS, CustomDatePickerAdapter} from '../../../models/date-formats.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@Component({
  selector: 'cf2-date-field-top-new',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-container':'input-field-container'
      }"
    >
      <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="true" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
            #dateInput
            matInput
            [matDatepicker]="datepicker"
            id="{{ idField }}"
            name="{{ idField }}"
            placeholder="{{ placeHolder }}"
            value="{{ value }}"
            [min]="minDate"
            [max]="maxDate"
            autocomplete="off"
            (blur)="isDateValid()"
            (input)="updateDate($event.target.value)"
            (change)="updateDate($event.target.value)"
            [formControl]="ctrl"
        />

        <mat-datepicker-toggle matSuffix [for]="datepicker" [disabled]="disabled" #date></mat-datepicker-toggle>
        <mat-datepicker startView="month" #datepicker></mat-datepicker>
        <mat-error *ngIf="hasError(ctrl)">{{ hasCustomError ? errorText : errorMap(ctrl) }}</mat-error>
        <!-- <mat-error *ngIf="hasCustomError">abcdef {{ errorText }}</mat-error> -->
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top-new.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: CustomDatePickerAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateFieldTopNewComponent implements AfterViewChecked {
  @ViewChild('dateInput') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() hasCustomError = false;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() defaultDate = new Date(Date.now());
  @Input() errorText: string;
  @Input() idField: string;
  @Input() placeHolder = casefloDateFormat;
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;
  @Input() readOnly = false;

  private trackDateValue = '';

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
    if (this.maxDate !== undefined) this.updateMax;
    if (this.minDate !== undefined) this.updateMin;
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
    this.trackDateValue = this.value.toString();
  }

  public updateMax(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.max = this.maxDate.toDateString();
  }

  public updateMin(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.min = this.minDate.toDateString();
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }

  public errorMap(fc: UntypedFormControl): string {
    //debugger;

    if (fc.errors === undefined || Object.keys(fc.errors).length < 1) return null;
    if (fc.errors.matDatepickerMin) return 'Too far in the past.';
    if (fc.errors.minDate) return 'Too far in the past.';
    if (fc.errors.matDatepickerMax) return 'Too far in the future.';
    if (fc.errors.maxDate) return 'Too far in the future.';
    if (fc.errors.required) return 'This field is required.';
    if (fc.errors.invalidDate) return 'Invalid date. Please use format ' + casefloDateFormat + '.';

    return 'Invalid date. Please use format ' + casefloDateFormat + '.';
  }

  // pickup numeric keys only
  omitCharacters(event: KeyboardEvent): boolean {
    const k = event.charCode;
    return (k > 47 && k < 58) || k === 45 || k === 47;
  }

  // capture input value
  updateDate(value: string): void {
    this.trackDateValue = value;
  }

  // validate date on blur
  isDateValid(): boolean {
    if (this.ctrl.invalid && this.ctrl.value === null && this.trackDateValue.length > 0) {
      this.ctrl.setErrors({ invalidDate: true });
      return false;
    } else if (
      this.ctrl.value === null &&
      this.trackDateValue.length === 0 &&
      this.ctrl.errors !== null &&
      Object.keys(this.ctrl.errors).length > 0
    ) {
      this.ctrl.reset();
    }

    if (this.ctrl.value !== null) {
      this.ctrl.setValue(this.ctrl.value);
    }

    return true;
  }

  // validate the date
  public hasError(fc: UntypedFormControl): boolean {
    if (!fc || !fc.invalid) {
      return false;
    }
    if (this.hasCustomError) {
      return fc.invalid && fc.touched;
    }
    return fc.invalid && fc.touched && Object.keys(fc.errors).length > 0;
  }

  requireAllPlanItemFields() {}
}
