import { Directive, ElementRef, Input } from '@angular/core';
import { parseDateTimeAsDateFromString } from '@shared/models/date-formats.model';

@Directive({
  selector: '[cf2DueDate]',
})
export class DueDateDirective {
  today = new Date(Date.now());
  @Input() set cf2DueDate(params: { date: string; overdueBy: Date }) {
    // TODO: verify if next line is handling the date correctly
    const dateToCheck = typeof params.date === 'string' ? (parseDateTimeAsDateFromString(params.date) as Date) : params.date;
    const isOverdue = dateToCheck.valueOf() < params.overdueBy.valueOf();
    if (isOverdue) {
      this.el.nativeElement.style.color = 'red';
    }
  }
  constructor(private el: ElementRef) {}
}
