import { MonitoringPhaseModel, OutcomeClaimModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { FormsFns } from '@core/models/forms-fns.model';

import { TranslocoService } from '@ngneat/transloco';
import {parseDateFormatted8601FromString} from "@shared/models/date-formats.model";
import {parseDate} from "@progress/kendo-angular-intl";

export type ClaimDetailsKeysType = keyof OutcomeClaimModel;

export type ClaimDetailsLabelsType = Record<ClaimDetailsKeysType, boolean | string>;

export interface OutcomeClaimsConfig extends FormsComponentField {
  key: ClaimDetailsKeysType;
}

export interface OutcomeClaimsFormOpts {
  value?: Partial<OutcomeClaimModel> & Partial<MonitoringPhaseModel>;
}

export const OUTCOME_CLAIMS_FIELD_CONFIG: readonly OutcomeClaimsConfig[] = [
  {
    key: 'scheduledStartDate',
    label: 'Scheduled Start Date',
    type: 'select',
    disabled: true,
    required: false,
  },
  {
    key: 'actualStartDate',
    label: 'Actual Start Date',
    type: 'pastDate',
    required: true,
  },
  {
    key: 'actualEndDate',
    label: 'Actual End Date',
    type: 'pastDate',
    required: true,
  },
  {
    key: 'claimTypeCode',
    label: 'Claim Type',
    type: 'select',
    disabled: true,
    required: false,
  },
  {
    key: 'calculatedClaimAmount',
    label: 'Claim Amount',
    type: 'currency',
    required: false,
    disabled: true,
  },
  {
    key: 'outcomeResultCode',
    label: 'Outcome Result',
    type: 'select',
    required: false,
  },
  {
    key: 'claimStatusCode',
    label: 'Claim Status',
    type: 'select',
    required: false,
  },
  {
    key: 'onholdReason',
    label: 'On Hold Reason',
    type: 'string',
    required: false,
  },
  {
    key: 'onholdToDate',
    label: 'On Hold To',
    type: 'select',
    required: false,
  },
  {
    key: 'biaIndicator',
    label: 'Brokered Incentive Agreement',
    type: 'checkbox',
    required: true,
  },
  {
    key: 'biaStartDate',
    label: 'BIA Start Date',
    type: 'futureDate',
    required: true,
  },
  {
    key: 'biaEndDate',
    label: 'BIA End Date',
    type: 'futureDate',
    required: true,
  },
  {
    key: 'isStillReceivingOW',
    label: 'Is Client still a recipient of OW?',
    type: 'checkbox',
    required: false,
  },  
];

/**
 * Create outcome claims form configuration
 *
 * @export
 * @class OutcomeClaimsForm
 * @extends {Cf2Forms}
 */
export class ClaimDetailsForm extends Cf2Forms {
  _labels: any;
  _disabled: any;
  _required: any;
  _fg: any;

  /**
   * Creates an instance of ClaimDetailsForm.
   * @param {*} fb
   * @param {OutcomeClaimsFormOpts} [opts={ value: null }]
   * @memberof ClaimDetailsForm
   */
  constructor(fb, ts : TranslocoService, opts: OutcomeClaimsFormOpts = { value: null }) {
    super(fb, ts);
    const { value = null } = opts;

    const config = [...OUTCOME_CLAIMS_FIELD_CONFIG];

    const mappedConfig = value
      ? FormsFns.mapValue(config)({
        ...value,
          // TODO: do all values need to be passed through parseDate() ?
        scheduledStartDate: value?.scheduledStartDate ? new Date(value.scheduledStartDate) : null,
        actualStartDate: value?.actualStartDate ? parseDate(value.actualStartDate) : null,
        actualEndDate: value?.actualEndDate ? new Date(value.actualEndDate): null,
        onHoldToDate: value?.onholdToDate ? new Date(value.onholdToDate) : null,
        biaStartDate: value.biaStartDate ? new Date(value.biaStartDate) : null,
        biaEndDate: value.biaEndDate ? new Date(value.biaEndDate) : null,
      })
      : config;

    const { labels, disabled, required, fg } = this.makeFields(mappedConfig);

    if (value) {
      if (!value.biaIndicator) fg.controls.biaIndicator.setValue(value.biaIndicator);
      if (!value.isStillReceivingOW) fg.controls.isStillReceivingOW.setValue(value.isStillReceivingOW);
    }

    this._labels = labels;
    this._disabled = disabled;
    this._required = required;
    this._fg = fg;
  }

  get fg() {
    return this._fg;
  }

  get labels() {
    return this._labels;
  }

  get disabled() {
    return this._disabled;
  }

  get required() {
    return this._required;
  }
}
