import { UntypedFormGroup } from '@angular/forms';
import * as R from 'remeda';
import { CaseNoteModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';
export type CaseNotesViewType = 'create' | 'edit' | 'view';

export type CaseNotesKeysType = keyof CaseNoteModel;
export type CaseNotesLabelsType = Record<CaseNotesKeysType, string | boolean>;

export interface CaseNotesComponentField extends FormsComponentField {
  key: CaseNotesKeysType;
}

export const CASE_NOTE_CONFIG: CaseNotesComponentField[] = [
  {
    key: 'clientContactDate',
    label: 'ClientContactDate',
    type: 'date',
    required: true,
  },
  {
    key: 'primaryCategoryCode',
    label: 'Category',
    type: 'select',
    required: true,
  },
  {
    key: 'secondaryCategoryCode',
    label: 'Subcategory',
    type: 'select',
    required: true,
  },
  {
    key: 'logTypeCode',
    label: 'ContactedBy',
    type: 'select',
    required: true,
  },
  {
    key: 'comment',
    label: 'Description',
    type: 'longString',
    required: true,
    options: {
      maxLength: 500,
    },
  },
];

export class CaseNoteForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: CaseNotesLabelsType;
    required: CaseNotesLabelsType;
    disabled: CaseNotesLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, transloco: TranslocoService, opts: { value: Partial<CaseNoteModel> }) {
    super(fb, transloco);
    let config: CaseNotesComponentField[] = CASE_NOTE_CONFIG;

    // if casenote is system generated then disable all form fields and make it not required
    if (opts?.value?.logTypeCode === 'SYSTEM')
      config = R.map(config, (obj) => {
        obj.disabled = true;
        obj.required = false;
        return obj;
      });

    this._fields = this.makeFields([...config]) as any;
    // console.log(this._fields);
  }
}
