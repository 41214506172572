import { Pipe, PipeTransform } from '@angular/core';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';

@Pipe({ name: 'formattedDate' })
export class Cf2FormattedDate implements PipeTransform {
  transform(value: Date): string {
    if (typeof value === 'string') return value;
    return parseDateFormattedFromDate(value);
  }
}
