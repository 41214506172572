import { Inject } from '@angular/core';
import { OutcomeClaimModel } from '@api/models';
import { OutcomeService } from '@api/services';
import { Cf2TableConfig, CF2TableFieldType, tableFieldFactory } from '@core/types/cf2-table-field.type';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { OUTCOME_LIST_HEADERS } from '@modules/outcomes/models/outcome-task-history-datasource';

export type OutcomeClaimKeysType = keyof OutcomeClaimModel | 'organization';

/* tuple setup for document config */
type OutcomeListHeaderTupleType = [OutcomeClaimKeysType, string, CF2TableFieldType];

export const MONITORING_CHECK_LIST_HEADERS: readonly OutcomeListHeaderTupleType[] = [
  ['parentOutcomeClaimKey', 'ClaimID', 'link'],
  ['claimTypeDescription', 'ClaimType', 'text'],
  ['clientDisplayName', 'ClientName', 'text'],
  ['scheduledStartDateFormatted', 'ScheduledStartDate', 'date'],
  ['outcomeStatusDescription', 'OutcomeStatus', 'text'],
];

@Inject({
  providedIn: 'root',
  providers: [OutcomeService],
})
export class MonitoringChecksCardDataSource {
  /* set the list headers */
  private mcListHeader = [...MONITORING_CHECK_LIST_HEADERS];
  private mcLinkHeader = ['parentOutcomeClaimKey'];

  columnConfig: {
    linkable: boolean;
    key: string;
    label: string;
    pos?: number;
    hidden?: boolean;
    sort?: boolean;
    centered?: boolean;
  }[];

  private outcomeListSetup() {
    const setup = DataTableSetup.linkableColumns(
      DataTableSetup.initColumns(MONITORING_CHECK_LIST_HEADERS),
      this.mcLinkHeader
    );

    return setup;
  }

  constructor() {
    this.columnConfig = this.outcomeListSetup();
  }
}
