import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable, Observable, of } from 'rxjs';

@Component({
    selector: 'formly-ng-select',
    template: `
    <mat-form-field style="width: 100%;">
        <mat-label>{{to.label}}</mat-label>
        <input type="text"
            [placeholder]="to.label"
            matInput
            [formControl]="formControl"
            [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption  #auto="matAutocomplete">
        <mat-option *ngFor="let option of to.options | async" [value]="option.value">
            {{option.label}}
        </mat-option>
        </mat-autocomplete>
    </mat-form-field>
   `
})

export class NgSelectFormlyComponent extends FieldType {
    displayFn(value: string) {
        let options: { value: string, label: string }[];
        if (isObservable(this.to.options)) {
            // Todo: Add support for observables here
            // options = this.to.options as Observable<{ value: string, label: string }[]>;
            return '';
        } else {
            options = this.to.options as { value: string, label: string }[];
            options = options || [];
            const matches = options.filter(op => op.value === value);
            let label = '';
            if (matches.length > 0) {
                label = matches[0].label;
            }
            return label;
        }
    }
}