import { AccountKeysType, UserKeysType } from '@core/types/cf2-key-types.type';

export const CASE_KEYS = [
  'caseKey',
  'clientKey',
  'expenditureKey',
  'monitoringPhaseKey',
  'outcomeKey',
  'parentInquiryKey',
  'caseOrgKey',
] as const;

export const USER_STATE_KEYS: readonly Partial<UserKeysType>[] = [
  'email',
  'displayName',
  'userId',
  'languageCode',
  'reportsToOrganizationalUnitKey',
  'organizationName',
  'userRoleCode',
  'siteName',
  'parentSiteKey',
  'roleCode',
  'roleDescription',
];

export const ACCOUNT_STATE_KEYS: readonly Partial<AccountKeysType>[] = ['name', 'username'];
