import { Injectable } from '@angular/core';
import {
  CaseModel,
  EapModel,
  EmployerModel,
  EmployerPositionReferralModel,
  MonitoringPhaseModel,
  OutcomeClaimModel,
  TaskModel,
} from '@api/models';
import { DataService } from '@core/services/data.service';
import { LoggingService } from '@core/services/logging.service';
import { environment } from '@environments/environment';
import { debounceTime, delay, map, take, tap } from 'rxjs/operators';
import { Globals } from 'src/app/globals';

import * as R from 'remeda';
import { MonitoringPhaseForm, MonitoringPhaseLabelsType } from '../models/monitoring-phase-form';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ERROR_DIALOG, ModalService } from '@shared/services/modal.service';
import { LinkEmploymentDialogComponent } from '../components/link-employment-modal/link-employment-dialog.component';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';
import { BehaviorSubject, of, queueScheduler, scheduled } from 'rxjs';
import { CaseService, EmploymentService, OutcomeService } from '@api/services';
import { RolesType, WorkflowActions } from '@core/models/workflow-actions.model';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import { CaseNotesDataService } from '@shared/components/case-notes/services/case-notes-data.service';
import { FormsFns } from '@core/models/forms-fns.model';
import { UserStateService } from '@core/services/user-state.service';
import { errorMapFn } from '@core/models/cf2-validators.model';
import { TranslocoService } from '@ngneat/transloco';
import { OutcomesDataService } from './outcomes-data.service';

export type MonitoringPhaseStateType = 'create' | 'edit' | 'view';

@Injectable({
  providedIn: 'root',
})
export class MonitoringPhaseDataService {
  private _isLoading = false;
  private _isEmployed = false;
  private _outcomeResultIsEmployed = false;

  caseData: CaseModel;

  set isEmployed(bool: boolean) {
    this._isEmployed = bool;
  }

  get isEmployed() {
    return this._isEmployed;
  }

  set outcomeResultIsEmployed(bool: boolean) {
    this._outcomeResultIsEmployed = bool;
  }

  get outcomeResultIsEmployed() {
    return this._outcomeResultIsEmployed;
  }

  private _state: MonitoringPhaseStateType = 'create';

  get hasEap() {
    return !!this._eap;
  }

  get hasMonitoringPhase() {
    return !!this._monitoringPhase;
  }

  get hasOutcomes() {
    return this?._outcomeClaims && this?._outcomeClaims?.length > 0;
  }

  get hasWorkflow() {
    return !!this._workflowActions;
  }

  get isReady() {
    return !this._isLoading;
  }

  get caseKey() {
    const { caseKey = 0 } = this.globals;
    return caseKey;
  }

  get eapKey() {
    return this.globals.eapKey;
  }

  get monitoringPhaseKey() {
    return this.globals.monitoringPhaseKey;
  }

  set monitoringPhaseKey(key: number) {
    this.globals.monitoringPhaseKey = key;
  }

  get clientKey() {
    return this.globals.clientKey;
  }

  required: MonitoringPhaseLabelsType;
  fg: UntypedFormGroup;
  labels: MonitoringPhaseLabelsType;
  disabled: MonitoringPhaseLabelsType;

  get formValues() {
    return this.fg?.getRawValue();
  }
  get formValid() {
    if (this.isEmployed) {
      const fg = this.fg;
      let isValid = true;
      if (fg) {
        Object.keys(fg.controls).forEach((key) => {
          if (fg.controls[key].invalid && fg.controls[key].dirty) {
            isValid = false;
          }
        });
      }

      return this.linkedPositions.length > 0 && isValid;
    } else {
      
      return this.errorList(false).length === 0;
    }
  }
  get state() {
    return this._state;
  }
  private _eap: EapModel = null;

  set eap(eap: EapModel) {
    if (!eap) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.eap has no eap value passed` });
    }
    const { eapKey = 0 } = eap || {};
    this.globals.eapKey = eapKey;
    this._eap = eap ? eap : null;
  }

  private _monitoringPhase: MonitoringPhaseModel = null;
  set monitoringPhase(phase: MonitoringPhaseModel) {
    if (!phase) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.monitoringPhase has no value passed` });
    }
    const { parentMonitoringPhaseKey: monitoringPhaseKey = 0 } = phase || {};

    this._monitoringPhase = phase ? phase : null;
    this.monitoringPhaseKey = monitoringPhaseKey;
  }
  get monitoringPhase() {
    return this._monitoringPhase;
  }
  get outcomeClaims() {
    return this._outcomeClaims;
  }
  set outcomeClaims(claims: OutcomeClaimModel[]) {
    if (claims.length) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.outcomeClaims has no outcome claims` });
    }

    this._outcomeClaims = claims;
  }
  previousPositions: EmployerPositionReferralModel[] = [];

  private _linkedPositions = new BehaviorSubject<EmployerPositionReferralModel[]>([]);
  set linkedPositions(linkedEmployers: EmployerPositionReferralModel[]) {
    this._linkedPositions.next(linkedEmployers);
  }
  get linkedPositions() {
    return this._linkedPositions.value;
  }
  linkedPositions$ = this._linkedPositions.asObservable();

  private _outcomeClaims = [];

  /* needs an EAP */

  get hasEmployment() {
    return this?._linkedPositions && this?._linkedPositions?.value.length > 0;
  }

  get monitoringPhase$() {
    const caseKey = this.globals.caseKey;
    return this.dataSvc
      .getMonitoringPhase$(caseKey)
      .pipe(tap((res) => (res?.monitoringPhaseCode !== 'ERROR' ? (this.monitoringPhase = res) : {})));
  }

  private _activeTask: TaskModel;

  private _workflowActions: WorkflowActions;

  get workflowActions() {
    return this._workflowActions;
  }
  setActiveTask(task: TaskModel, outcome: OutcomeClaimModel) {
    const wfActions = new WorkflowActions(task, {
      type: 'outcome',
      role: this.globals.roleCode as RolesType,
      status: outcome.outcomeStatusCode,
      claimTypeCode: outcome.claimTypeCode,
      loggedInUserOrg: this.globals.organizationKey,
      caseOrg: this.caseData?.parentOrganizationalUnitKey,
      caseStatus: this.caseData?.caseStatusCode,
      isBia: outcome.isBiaActive
    });
    this._workflowActions = wfActions;
    this._activeTask = task;
  }
  get activeTask$() {
    return this.outcomeSvc
      .apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json({
        parentOutcomeClaimKey: this.globals.outcomeKey,
      })
      .pipe(tap((res) => (this._activeTask = res)));
  }

  startReasonCodeOpts$ = this.dataSvc
    .lookupRecords('StartReasonType')
    .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));

  monitoringPhaseCodeOpts$ = this.dataSvc
    .lookupRecords('MonitoringPhaseType')
    .pipe(
      map((result) =>
        DataService.lookupToOptions(result, this.translocoService.getActiveLang()).filter((opt) =>
          ['MON_EMP', 'MON_EXIT', 'MON_UNEMP'].some((val) => opt.value === val)
        )
      )
    );

  async mpEmployers() {
    const parentCaseKey = this.caseKey;
    try {
      const result = await this.dataSvc
        .getEmploymentReferralsByCase(parentCaseKey)
        .pipe(
          map((result) => R.reverse(R.sortBy(R.clone(result), (rec) => rec.startDateFormatted))),
          map((sorted) => R.filter(sorted, (value) => value.positionOutcomeCode === 'START_CONFIRMED')),
          debounceTime(500)
        )
        .toPromise();

      return result;
    } catch (err) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.mpEmployers() failed with error: ${err}` });

      return null;
    }
  }

  get monitoringPhaseOutcomes$() {
    const parentMonitoringPhaseKey = this.globals.monitoringPhaseKey;
    const clientEapKey = this.eapKey;
    if (!parentMonitoringPhaseKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: `${this.constructor.name}.monitoringPhaseOutcome has no case key set in globals.monitoringPhaseKey `,
      });
    }

    return this.outcomeSvc.apiOutcomeGet$Json({ parentMonitoringPhaseKey, clientEapKey }).pipe(
      tap((outcomes) => {
        // set outcomes in document service in order to have access to outcome status
        this.docsSvc.outcomeClaims = outcomes;
        // set outcomes in casenotes service in order to have access to outcome status
        this.caseNotesSvc.outcomeClaims = outcomes;
        this._outcomeClaims = outcomes;
      })
    );
  }

  constructor(
    private dataSvc: DataService,
    private globals: Globals,
    private logSvc: LoggingService,
    private fb: RxFormBuilder,
    private modalSvc: ModalService,
    private caseSvc: CaseService,
    private outcomeSvc: OutcomeService,
    private employmentSvc: EmploymentService,
    private docsSvc: DocumentsDataService,
    private caseNotesSvc: CaseNotesDataService,
    private userStateSvc: UserStateService,
    private translocoService: TranslocoService,
    private outcomeDataSvc: OutcomesDataService
  ) { }

  toggleLoading() {
    this._isLoading = !this._isLoading;
  }

  initForms(
    state: MonitoringPhaseStateType = 'create',
    opts: { value: Partial<MonitoringPhaseModel> } = {
      value: { monitoringStartDateFormatted: parseDateFormattedFromDate(new Date(Date.now())) },
    }
  ) {
    const { value } = opts;

    const fields = new MonitoringPhaseForm(this.fb, this.translocoService, { value });

    this._state = state;

    const { required, fg, labels, disabled } = fields;
    if (this.outcomeClaims.length > 1) {
      ['monitoringStartDate', 'startReasonCode'].forEach((key) => {
        disabled[key] = true && this.userStateSvc.roleCode !== 'HD';
      });
      fg.updateValueAndValidity(FormsFns.formUpdateOpts);
    }
    this.required = required;
    this.fg = fg;
    this.labels = labels;
    this.disabled = disabled;
  }
  
  sanitizeData() {
    this._eap = null;
    this._isLoading = false;
    this._monitoringPhase = null;
    this._state = null;

    this.fg = null;
    this.labels = null;
    this.required = null;
    this.disabled = null;
    this._linkedPositions.next([]);
    this._outcomeClaims = [];

    this.docsSvc.sanitizeData();
    this.caseNotesSvc.sanitizeData();
  }

  getCase() {
    const parentCaseKey = this.globals.caseKey;
    return this.caseSvc
      .apiCaseParentCaseKeyGet$Json({ parentCaseKey })
      .pipe(
        map((res) => res[0]),
        tap((res) => {
          // caseData = res;
          this.caseData = res;
        })
      )
      .toPromise();
  }

  employment$(monitoringPhaseKey: number) {
    return this.employmentSvc
      .apiEmploymentEmployersPositionsGet$Json({ monitoringPhaseKey: [monitoringPhaseKey] })
      .pipe(
        map((emps) => R.uniqBy(emps, (emp) => emp.parentEmployerPositionKey)),
        tap((obs: any) => {
          this.linkedPositions = obs as EmployerPositionReferralModel[];
        })
      );
  }

  linkEmployment(raw: EmployerModel[]) {
    const employerPositions = R.concat(raw, []);

    const data = { employerPositions, selectedFields: this.linkedPositions };
    return this.modalSvc
      .openCustomDialog(LinkEmploymentDialogComponent, { data, disableClose: true })
      .afterClosed()
      .pipe(debounceTime(500));
  }

  async saveLinkedEmployment(data: EmployerModel[]) {
    const isCreate = this._state === 'create';
    if (isCreate) {
      return (this.linkedPositions = data);
    }

    this.previousPositions = this.linkedPositions;

    this.linkedPositions = [...data];
    //if (this.previousPositions.length > 0) this.unlinkEmployersToMonitoringPhase();
    await this.linkEmployersToMonitoringPhase();
  }
  linkEmployerToMonitoringPhase$(
    isAdd = true,
    keys: { parentCaseKey; parentMonitoringPhaseKey; parentEmployerPositionKey }
  ) {
    console.log(keys);
    return isAdd
      ? this.caseSvc.apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json$Response(keys)
      : this.caseSvc.apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json$Response(keys);
  }

  async unlinkEmployersToMonitoringPhase() {
    const parentMonitoringPhaseKey = this.monitoringPhaseKey;
    const parentCaseKey = this.caseKey;
    const promises = this.previousPositions
      .map((pos) => ({
        parentCaseKey,
        parentMonitoringPhaseKey,
        parentEmployerPositionKey: pos.parentEmployerPositionKey,
      }))
      .map((keys) => this.linkEmployerToMonitoringPhase$(false, keys).toPromise());

    try {
      const res = await Promise.all(promises);
      return res;
    } catch (error) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.unlinkEmployersToMonitoringPhase() failed with error ${error.message}`,
      });
      this.modalSvc.openDialog({ data: ERROR_DIALOG });
    }
  }

  async linkEmployersToMonitoringPhase() {
    const parentMonitoringPhaseKey = this.monitoringPhaseKey;
    const parentCaseKey = this.caseKey;
    const promises = this.linkedPositions
      .map((pos) => ({
        parentCaseKey,
        parentMonitoringPhaseKey,
        parentEmployerPositionKey: pos.parentEmployerPositionKey,
      }))
      .map((keys) => this.linkEmployerToMonitoringPhase$(true, keys).toPromise());
    try {
      const res = await Promise.all(promises);
      return res;
    } catch (error) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.linkEmployersToMonitoringPhase() failed with error ${error.message}`,
      });
      this.modalSvc.openDialog({ data: ERROR_DIALOG });
    }
    //
  }

  async deleteEmployerLink(employer: EmployerPositionReferralModel = null) {
    const remaining = R.difference(this.linkedPositions, [employer]);
    const { parentEmployerPositionKey } = employer;
    const parentCaseKey = this.caseKey;
    this.linkedPositions = remaining;

    this.linkEmployerToMonitoringPhase$(false, {
      parentMonitoringPhaseKey: this.monitoringPhaseKey,
      parentCaseKey,
      parentEmployerPositionKey,
    }).toPromise();
  }

  async save(type: 'create' | 'save' = 'create') {
    if (!this.formValid) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.save() has no valid form` });
    }
    const isCreate = type === 'create';

    const phase = this.formValues;
    const parentClientKey = this.clientKey;
    const parentCaseKey = this.caseKey;
    const parentEapKey = this.eapKey;
    const parentMonitoringPhaseKey = this.monitoringPhaseKey;

    const main: Partial<MonitoringPhaseModel> = R.merge({ parentClientKey, parentCaseKey, parentEapKey, parentMonitoringPhaseKey }, phase);

    const body = this.monitoringPhase ? { ...this.monitoringPhase, ...main } : main;

    try {
      const res = await this.caseSvc
        .apiCaseParentCaseKeyMonitoringPhasePost$Json$Response({
          parentCaseKey,
          body: isCreate ? body : { ...body, parentMonitoringPhaseKey },
        })
        .toPromise();

      if (res.status !== 200) {
        this.logSvc.logError({ lvl: 'ERROR', mssg: `Error in ${this.constructor.name}.submit: ` + res.body });
      }
      const key = res.body.split('=')[1];

      return parseInt(key);
    } catch (err) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `Error in ${this.constructor.name}.submit: ` + err.mssg });
    }
  }

  hasErrors() {
    return !!this.fg?.invalid || ((this.isEmployed) && !(this.linkedPositions.length > 0));
  }

  errorList(checkModifiedField : boolean) {
    const errors: string[] = [];
    const fg = this.fg;

    if (((this.outcomeDataSvc.isStartClaim && this.isEmployed) || this.outcomeResultIsEmployed) && !(this.linkedPositions.length > 0)) {
      errors.push('Client Employment is required.');
    }

    if (fg) {
      Object.keys(fg.controls).forEach((key) => {
        if (fg.controls[key].invalid && (!checkModifiedField || fg.controls[key].dirty)) {
          const errorMessage = errorMapFn(fg.controls[key] as UntypedFormControl, this.labels?.[key]);
          errors.push(errorMessage);
        }
      });
    }

    return errors;
  }

  refreshValidation() {
      this?.fg?.markAllAsTouched();
  }

}
