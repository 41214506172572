import { Component } from '@angular/core';
import { OutcomeClaimModel } from '@api/models';
import { tableFieldFactory, Cf2TableConfig } from '@core/types/cf2-table-field.type';
import {
  MONITORING_CHECK_LIST_HEADERS,
  OutcomeClaimKeysType,
} from '@modules/home/models/monitoring-checks-card.datasource';
import { MonitoringPhaseDataService } from '@modules/outcomes/services/monitoring-phase-data.service';
import { OutcomesDataService } from '@modules/outcomes/services/outcomes-data.service';
import { BaseComponent } from '@shared/components/base/base.component';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { filter, pluck } from 'rxjs/operators'; 
import { TranslocoService } from '@ngneat/transloco';
import {parseDateFormattedFromDate} from "@shared/models/date-formats.model";

@Component({
  selector: 'cf2-monitoring-checks-card',
  template: `
    <cf2-table
      [dataSub]="data$ | async"
      [columnConfig]="columnConfig"
      sortColumn="scheduledStartDateFormatted"
      (selected)="viewOutcome($event)"
    ></cf2-table>
  `,
  styleUrls: ['./monitoring-checks-card.component.scss'],
})
export class MonitoringChecksCardComponent extends BaseComponent {
  data$ = this.ocDataSvc
    .getMonitoringChecks$()
      .pipe(
          map((ocs) =>
              ocs.map((oc) => ({ ...oc, scheduledStartDateFormatted: oc.scheduledStartDateFormatted = oc.scheduledStartDate
                    ? parseDateFormattedFromDate(new Date(oc.scheduledStartDate.toString().indexOf('z') !== -1 
                        ? oc.scheduledStartDate : oc.scheduledStartDate + 'z'))
                    : oc.scheduledStartDateFormatted }))
          )
      );

  setupMap: Cf2TableConfig<OutcomeClaimKeysType>;
  columnConfig: Cf2TableConfig<OutcomeClaimKeysType>;

  constructor(private mpDataSvc: MonitoringPhaseDataService, private ocDataSvc: OutcomesDataService, private translocoService: TranslocoService) {
    super();

    
      const tableConfig = MONITORING_CHECK_LIST_HEADERS.map((tuples) =>
        tableFieldFactory(tuples[0], { label: this.translocoService.translate(tuples[1]), type: tuples[2], sortable: true })
      );

      // this.columnConfig = new MonitoringChecksCardDataSource().columnConfig;
      const ocMap = new Cf2TableConfig<OutcomeClaimKeysType>();
      tableConfig.forEach((field) => ocMap.set(field.key, field));
      this.setupMap = ocMap;

      this.columnConfig = ocMap;
    
  }

  viewOutcome(oc: OutcomeClaimModel) {
    const {
      parentMonitoringPhaseKey: monitoringPhaseKey = null,
      parentOutcomeClaimKey: outcomeKey = null,
      parentCaseKey: caseKey = null,
      parentClientKey: clientKey = null,
    } = oc;

    const keys = { caseKey, clientKey, outcomeKey, monitoringPhaseKey };

    this.setGlobalKeys(keys);

    this.mpDataSvc.monitoringPhase$
      .pipe(
        take(1),
        switchMap(() => this.mpDataSvc.monitoringPhase$),
        switchMap(() => of(this.navigate('clients/outcomes/view', keys)))
      )

      .subscribe();
  }
}
