import { Component, Input, EventEmitter, Output, ViewChild, OnInit, AfterViewChecked, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchInputComponent } from '../search-input/search-input.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { DataService } from '@core/services/data.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CaseDataService } from '@modules/client/views/case/case-data.service';
import { CaseHeaderModel } from '@api/models';
import { CaseHeaderService } from '@core/services/case-header.service';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';


@Component({
  selector: 'cf2-header-row',
  template: `
    <div
      class="combined-header"
      [class.production]="globals.isProduction"
      [class.header-hidden]="headerVisible === false"
    >
      <mat-toolbar #header class="fixed-header">
        <div class="info-container">
          <span class="header-title" *ngIf="!caseHeader">
            <ng-content select="header-title"></ng-content>
          </span>

          <div class="case-header" *ngIf="caseHeader">
            <a class="header-title header-link" (click)="viewClient()">{{ displayName }}</a>
            <span *ngIf="['OW', 'ODSP'].indexOf(incomeSourceCode) !== -1"> {{ incomeSourceCode | transloco}} &nbsp;</span>
            <span>{{ caseCodeName }}</span>
            <div *ngIf="caseEmail != null">
              <span class="email"
                ><a href="mailto:{{ caseEmail }}" aria-label="Email client"><img class="email" src="assets/images/envelope-regular.svg" alt="email"/></a
              ></span>
            </div>
            <span class="business-key">{{ clientClientBusinessKey2 }}</span>
          </div>
        </div>

        <div class="button-container">
          <cf2-search-input
            *ngIf="search"
            class="search search-margin"
            #searchInput
            [ctrl]="ctrl"
            (keyup)="keyupEvent($event.target.value)"
          >
          </cf2-search-input>

          <span class="menu">
            <ng-content select="[slot='three']"></ng-content>
          </span>
          <span class="menu">
            <ng-content select="[slot='two']"></ng-content>
          </span>
          <span class="menu" [class.menu-noSearch]="search === false">
            <ng-content select="[slot='one']"></ng-content>
          </span>
          <span class="menu-filter" [class.filter-hidden]="drawer === false" [class.menu-noSearch]="search === false">
            <app-icon-button
              [on]="drawerIcons[0]"
              [off]="drawerIcons[1]"
              (click)="isDrawerActive = !isDrawerActive && !disableDrawerIcons"
              [disabled]="disableDrawerIcons"
            >
            </app-icon-button>
          </span>
        </div>
      </mat-toolbar>

      <div #pinnedContent class="pinned-content">
        <ng-content select="pinned-content"></ng-content>
      </div>
    </div>

    <div #pageContainer class="page-container">
      <div class="overlay" *ngIf="isLoading">
        <div class="spinner-wrapper"></div>
      </div>

      <div #pageContent class="page-content-container">
        <ng-content select="page-content"></ng-content>
      </div>

      <!-- filter drawer -->
      <div #filter class="sub-drawer-inactive" [class.sub-drawer-active]="isDrawerActive">
        <ng-content select="drawer-content"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./header-row.component.scss'],
})
export class HeaderRowComponent implements OnInit, AfterViewChecked {
  @ViewChild('header', { read: ElementRef }) header: ElementRef;
  @ViewChild('pageContainer', { read: ElementRef }) pageContainer: ElementRef;
  @ViewChild('pinnedContent', { read: ElementRef }) pinnedContent: ElementRef;
  @ViewChild('pageContent', { read: ElementRef }) pageContent: ElementRef;
  @ViewChild('filter', { read: ElementRef }) filter: ElementRef;
  @ViewChild('searchInput') input: SearchInputComponent;

  @Input() pinnedHeight = '0';
  @Input() pinnedTop = '0';
  @Input() caseHeader = false;
  @Input() isLoading: boolean;
  @Input() drawerIcons: string[] = [];
  @Input() disableDrawerIcons = false;
  @Input() search = false;
  @Input() ctrl = new UntypedFormControl();
  @Input() autoResetHeight = true;
  @Input() headerVisible = true;

  @Output() drawerChange = new EventEmitter<boolean>();
  @Output() clicked = new EventEmitter<boolean>();

  @Output() caseHeaderValue = new EventEmitter<CaseHeaderModel>();
  isDrawerActive = false;
  drawer = false;
  heightUpdated = false;
  displayName = '';
  // caseCodeName = '';
  get caseCodeName() {
    return this?.caseHeaderSvc?.caseHeader?.caseCodeName + ' (' + this.streamName + ')';
  }
  // streamName = '';
  get streamName() {
    return this?.caseHeaderSvc?.caseHeader?.streamName;
  }
  // clientBusinessKey = '';

  get clientClientBusinessKey2() {
    return this?.caseHeaderSvc?.caseHeader?.clientClientBusinessKey2;
  }

  get clientBusinessKey() {
    return this?.caseHeaderSvc?.caseHeader?.clientBusinessKey;
  }
  get incomeSourceCode() {
    return this?.caseHeaderSvc?.caseHeader?.incomeSourceCode;
  }

  caseEmail: any;

  public keyup = new BehaviorSubject<string>(null);

  constructor(
    public globals: Globals,
    private dataService: DataService,
    private router: Router,
    private caseSvc: CaseDataService,
    public caseHeaderSvc: CaseHeaderService
  ) {}

  public async ngOnInit() {
    if (this.drawerIcons !== undefined && this.drawerIcons.length > 0) {
      this.drawer = true;
    }

    // load case header data
    if (this.caseHeader && this.globals.caseKey) {
      const caseKey = this.globals.caseKey;
      if (caseKey) {
      //   this.mapHeader(this.caseHeaderSvc.caseHeader);
      // } else {
        this.loadHeaderData().subscribe();
      }
    }
  }

  /**
   * Reloads header data from the server
   * @returns An boolean observable that indicates the operation is complete
   */
  public  loadHeaderData(): Observable<boolean> {
    if (this.globals.caseKey > 0) {
      //  return this.dataService
      //   .getCaseHeader(this.globals.caseKey)
      //   .pipe(take(1))
      //   .pipe(mergeMap((result) => {
      //     this.caseEmail = result.primaryEmail;
      //     this.caseHeaderSvc.setCaseHeader(result);
      //     this.caseHeaderValue.emit(result);
      //     this.mapHeader(result);
      //     return of(true);
      //   }));
      this.dataService.getCaseHeader(this.globals.caseKey).subscribe((result) => {
        this.caseEmail = result.primaryEmail;
        this.caseHeaderSvc.setCaseHeader(result);
        this.caseHeaderValue.emit(result);
        this.mapHeader(result);
        return of(true);
      });
    }
    return of(true);
  }

  mapHeader(result: CaseHeaderModel) {
    if (!result) {
      return
    }

    var nameDisplay : string = result.displayName;
    if(typeof result.preferredName !== 'undefined' && result.preferredName !== null && result.preferredName !== '' )
    {
      nameDisplay += " (" + result.preferredName + ")";
    }
    this.displayName = nameDisplay;
    this.globals.incomeSourceCode = result.incomeSourceCode || '';
    this.caseEmail = result.primaryEmail;

    // set display name into globals
    if (this.globals.clientDisplayName === undefined || this.globals.clientDisplayName !== result.displayName) {
      this.globals.clientDisplayName = result.displayName;
    }
  }

  viewClient() {
    this.router.navigate(['clients']);
  }

  public ngAfterViewChecked(): void {
    if (!this.heightUpdated && this.autoResetHeight) {
      this.resetHeight();
    }

    this.resetFilterHeight();
  }

  public keyupEvent(text: string): void {
    this.keyup.next(text);
  }

  public drawerClick(): void {
    this.isDrawerActive = !this.isDrawerActive;
    this.drawerChange.emit(this.isDrawerActive);
  }

  // update the page height based on content
  public resetHeight(): void {
    let height = 0;
    let top = 0;

    try {
      height = parseInt(this.pinnedHeight);
      if (typeof height !== 'number') height = 0;
    } catch (error) {
      // do nothing
    }

    try {
      top = parseInt(this.pinnedTop);
      if (typeof top !== 'number') top = 0;
    } catch (error) {
      // do nothing
    }

    if (this.pinnedContent !== undefined && this.pageContainer !== undefined && height !== 0) {
      let pinnedContentHeight = 0;
      if(isNaN(parseInt(this.pinnedHeight))==false){
        //add extra 5 px so vertical scroll bar not showing if space is enough
        pinnedContentHeight = parseInt(this.pinnedHeight) + 1;
      }
      this.pinnedContent.nativeElement.style.paddingTop = '10px';
      this.pinnedContent.nativeElement.style.height = pinnedContentHeight + 'px';
      this.pinnedContent.nativeElement.style.minHeight = pinnedContentHeight + 'px';
      this.pageContainer.nativeElement.style.top = this.pinnedTop + 'px';

      if (top > 0) {
        this.pageContainer.nativeElement.style.height = 'calc(100% - ' + (top + 10) + 'px)';
        this.pageContainer.nativeElement.style.minHeight = 'calc(100% - ' + (top + 10) + 'px)';
      } else {
        this.pageContainer.nativeElement.style.height = 'calc(100% - ' + (height + 66) + 'px)';
        this.pageContainer.nativeElement.style.minHeight = 'calc(100% - ' + (height + 66) + 'px)';
      }

      // update visibility
      this.pinnedContent.nativeElement.style.visibility = 'visible';
      this.pinnedContent.nativeElement.style.display = 'inherit';
      this.pinnedContent.nativeElement.style.overflowY = 'scroll';

      this.heightUpdated = true;
    } else if (this.pinnedContent !== undefined && this.pageContainer !== undefined && height === 0) {
      this.pinnedContent.nativeElement.style.paddingTop = '0';
      this.pinnedContent.nativeElement.style.height = '0';
      this.pinnedContent.nativeElement.style.minHeight = '0';
      this.pageContainer.nativeElement.style.top = '0';
      this.pageContainer.nativeElement.style.paddingTop = '0';
      this.pageContainer.nativeElement.style.height = 'calc(100% - 66px)';
      this.pageContainer.nativeElement.style.minHeight = 'calc(100% - 66px)';

      this.heightUpdated = true;
    }
  }

  // update filter height using the page content height
  public resetFilterHeight(): void {
    if (this.pageContent !== undefined && this.filter !== undefined) {
      const pageHeight = this.pageContent.nativeElement.scrollHeight;
      const filterHeight = this.filter.nativeElement.scrollHeight;
      const height = pageHeight > filterHeight ? pageHeight - 12 : filterHeight - 10;
      this.filter.nativeElement.style.height = height + 'px';
      this.filter.nativeElement.style.minHeight = height + 'px';
    }
  }
}
