import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaseHeaderModel } from '@api/models';
import { CaseHeaderService } from '@core/services/case-header.service';

@Component({
  selector: 'cf2-case-compact-header',
  template: `<ng-container *ngIf="caseHeaderSvc.caseHeader">
    <div class="flex-row flex-wrap">
      <cf2-input-field-top
        [label]="'Site' | transloco"
        [value]="caseHeaderSvc?.caseHeader.siteName"
        disabled="true"
        fullWidth="true"
      ></cf2-input-field-top>

      <cf2-input-field-top
        [label]="'Status' | transloco"
        [value]="caseHeaderSvc?.caseHeader.caseStatusDescription"
        disabled="true"
        fullWidth="true"
      ></cf2-input-field-top>

      <cf2-input-field-top
        [label]="'PrimaryConsultant' | transloco"
        [value]="caseHeaderSvc?.caseHeader.ecDisplayName"
        disabled="true"
      ></cf2-input-field-top>

      <cf2-input-field-top
        [label]="'SiteManager' | transloco"
        [value]="caseHeaderSvc?.caseHeader.siteManagerDisplayName"
        disabled="true"
        style="margin-right: 20px;"
        class="site-manager"
      ></cf2-input-field-top>
    </div>
  </ng-container> `,
  styleUrls: ['./case-compact-header.component.scss'],
})
export class CaseCompactHeaderComponent {
  @Input() parentCaseKey: number;
  // @Input('caseHeader') set caseHeaderValue(caseHeader: CaseHeaderModel) {
  //   this.caseHeader = caseHeader;
  // }
  @Output() displayName = new EventEmitter<string>();
  @Output() ecName = new EventEmitter<string>();
  caseHeader: CaseHeaderModel;

  // caseHeader: CaseHeaderModel = {};
  dataLoaded = false;
  serviceTitle: string;

  constructor(public caseHeaderSvc: CaseHeaderService) {}
}
