import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CapturedIntegrationModel } from "@api/models/captured-integration-model";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'cf2-save-confirm',
    template: `
    <h2 mat-dialog-title>{{ type === 'cancelCheckpoint' ? 'Cancel Checkpoint' : type | titlecase }}</h2>
    <mat-dialog-content>
      <div class="flex-col">
        <div class="flex-row warning-row">
            <fa-icon class="warning-icon" [icon]="faExclamationTriangle"></fa-icon>
            <div class="warning-text">Warning! Clicking confirm will overwrite the client data in both Caseflo and CaMs.</div>
        </div>
          <div class="verify-text">The following changes will be committed for this client ({{ confirmData.casefloId }}):</div>
          <table class="confirm-table">
              <tbody>
              <tr *ngFor="let attrItem of integrationAttributes; let i = index">
                  <td class="attributeCol" *transloco="let t">
                      <div class="property-name.label">
                          <span>{{ t(attrItem.label) }} </span>
                      </div>
                  </td>
                  <td id="cfFirstnameLabel" class="caseFloCol">
                      {{ attrItem.dataType != 'date' ? attrItem.value.cf : (attrItem.value.cf | date : 'yyyy-MM-dd') }}
                  </td>
              </tr>
              </tbody>
          </table>
          <mat-checkbox color="warn" class="verify-text" [(ngModel)]="disabled" >I verify the information is accurate</mat-checkbox>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="clicked(true)" [disabled]="!disabled" >Confirm</button>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
  `,
    styleUrls: ['./save-confirm.component.scss'],
})
export class SaveConfirmComponent implements OnInit {
    type: string = '';
    module: string = '';
    disabled: boolean = false;

    confirmData: CapturedIntegrationModel;
    integrationAttributes = [];
    
    @Output() confirmEvent = new EventEmitter<any>();

    constructor(public dialogRef: MatDialogRef<SaveConfirmComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
        this.confirmData = data.data;
        this.integrationAttributes = data.integrationAttributes;
    }

    ngOnInit(): void {
        console.log(this.confirmData.cfFirstname);
        'abcdef';
    }

    clicked(evt: any) {
        this.confirmEvent.emit();
        this.dialogRef.close({close: true });
    }

    protected readonly icon = faExclamationTriangle;
    protected readonly faExclamationTriangle = faExclamationTriangle;
}
