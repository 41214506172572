import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { InputFieldTopComponent } from './components/label-top/input-field-top/input-field-top.component';
import { InputFieldTopNewComponent } from './components/label-top/input-field-top/input-field-top-new.component';
import { SinFieldTopComponent } from './components/label-top/sin-field-top/sin-field-top.component';
import { SinFieldTopNewComponent } from './components/label-top/sin-field-top/sin-field-top-new.component';
import { ClientIdFieldTopComponent } from './components/label-top/clientid-field-top/clientid-field-top.component';
import { ClientIdFieldTopNewComponent } from './components/label-top/clientid-field-top/clientid-field-top-new.component';
import { DateFieldTopComponent } from './components/label-top/date-field-top/date-field-top.component';
import { DateFieldTopNewComponent } from './components/label-top/date-field-top/date-field-top-new.component';
import { PostalFieldTopComponent } from './components/label-top/postal-field-top/postal-field-top.component';
import { PhoneFieldTopComponent } from './components/label-top/phone-field-top/phone-field-top.component';
import { PhoneFieldTopNewComponent } from './components/label-top/phone-field-top/phone-field-top-new.component';
import { SelectFieldTopComponent } from './components/label-top/select-field-top/select-field-top.component';
import { SelectFieldTopNewComponent } from './components/label-top/select-field-top/select-field-top-new.component';
import { SelectMultipleFieldTopComponent } from './components/label-top/select-multiple-field-top/select-multiple-field-top.component';
import { TextareaFieldTopComponent } from './components/label-top/textarea-field-top/textarea-field-top.component';
import { ButtonComponent } from './components/button/button.component';
import { RaisedButtonComponent } from './components/button/button.raised.component';
import { Cf2DownloadLinkButtonComponent } from './components/button/download-link-button.component';
import { ButtonErrorComponent } from './components/button-error/button-error.component';
import { BackendStatusComponent } from './components/backend-status/backend-status.component';
import { HeaderRowComponent } from './components/header-row/header-row.component';

import { DialogComponent } from './components/dialog-component/dialog.component';
import { TestBedComponent } from './components/test-bed/test-bed.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { DrawerListComponent } from './components/drawer-list/drawer-list.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { AddressFormCardComponent } from './components/address-form-card/address-form-card.component';
import { BaseComponent } from './components/base/base.component';
import { BaseFormComponent } from './components/base-form/base-form.component';
import { CaseCompactHeaderComponent } from './components/case-compact-header/case-compact-header.component';
import { MenuComponent } from './components/menu/menu.component';
import { UploadBoxComponent } from './components/upload-box/upload-box.component';

import { DocumentsListComponent } from './components/documents/documents-list/documents-list.component';
import { DocumentsCardComponent } from './components/documents/documents-card/documents-card.component';
import { LinkDocumentsDialogComponent } from './components/documents/link-documents-dialog/link-documents-dialog.component';

import { CaseNotesFormComponent } from './components/case-notes/case-notes-form/case-notes-form.component';
import { CaseNotesListComponent } from './components/case-notes/case-notes-list/case-notes-list.component';
import { CaseNotesCardComponent } from './components/case-notes/case-notes-card/case-notes-card.component';
import { LinkCaseNotesDialogComponent } from './components/case-notes/link-case-notes-dialog/link-case-notes-dialog.component';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FileRowComponent } from './components/file-row/file-row.component';
import { AltExpansionPanelComponent } from './components/expansion-panel/alt-expansion-panel.component';
import { CurrencyFieldTopComponent } from './components/label-top/currency-field-top/currency-field-top.component';
import { TypeaheadFieldTopComponent } from './components/label-top/typeahead-field-top/typeahead-field-top.component';
import { TypeaheadSearchFieldComponent } from './components/label-top/typeahead-search-field/typeahead-search-field.component';
import { NumberFieldTopComponent } from './components/label-top/number-field-top/number-field-top.component';
import { CamsFieldTopComponent } from './components/label-top/cams-field-top/cams-field-top.component';
import { CamsFieldTopNewComponent } from './components/label-top/cams-field-top/cams-field-top-new.component';
import { FileUploadDetailsComponent } from './components/file-upload-details/file-upload-details.component';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { ClientNameLookupComponent } from './components/client-name-lookup/client-name-lookup.component';
import { TimePickerFieldTopComponent } from './components/label-top/time-picker-field-top/time-picker-field-top.component';
import { WorkflowActionsComponent } from './components/workflow-actions/workflow-actions.component';
import { ApprovalTaskComponent } from './components/approval-task/approval-task.component';
import { WarningRowComponent } from './components/warning-row/warning-row.component';
import { AddressGroupComponent } from './components/address-group/address-group.component';
import { EmploymentHistoryListComponent } from './components/employment-history-list/employment-history-list.component';
import { DateFieldFixedComponent } from './components/input-field-fixed/date-field-fixed.component';
import { InputFieldFixedComponent } from './components/input-field-fixed/input-field-fixed.component';
import { NumberFieldFixedComponent } from './components/input-field-fixed/number-field-fixed.component';
import { SelectFieldFixedComponent } from './components/input-field-fixed/select-field-fixed.component';
import { AutoCompleteSelectFieldFixedComponent } from './components/input-field-fixed/autocomplete-select-field-fixed.component';
import { AutoCompleteSelectFieldFixedNewComponent } from './components/input-field-fixed/autocomplete-select-field-fixed-new.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DateTypeDirective } from './directives/date-type.directive';
import { FieldDirective } from './directives/field.directive';
import { ExpenditureWorkflowsListComponent } from '@shared/components/expenditure-workflows-list/expenditure-workflows-list.component';
import { CurrencyFieldFixedComponent } from './components/input-field-fixed/currency-field-fixed.component';
import { TimepickerWrapperComponent } from './components/label-top/time-picker-field-top/timepicker-wrapper/timepicker-wrapper.component';
import { AddressCardComponent } from './components/address/address-card/address-card.component';
import { AddressListComponent } from './components/address/address-list/address-list.component';
import { AddressFormComponent } from './components/address/address-form/address-form.component';
import { IdTypesListComponent } from './components/id-types/id-types-list/id-types-list.component';
import { IdTypesCardComponent } from './components/id-types/id-types-card/id-types-card.componet';
import { IdTypesFormComponent } from './components/id-types/id-types-form/id-types-form.component';
import { DisabilitiesListComponent } from './components/disabilities/disabilities-list/disabilities-list.component';
import { DisabilitiesCardComponent } from './components/disabilities/disabilities-card/disabilities-card.component';
import { DisabilitiesFormComponent } from './components/disabilities/disabilities-form/disabilities-form.component';
import { ExternalContactsListComponent } from './components/external-contacts/external-contacts-list/external-contacts-list.component';
import { ExternalContactsCardComponent } from './components/external-contacts/external-contacts-card/external-contacts-card.component';
import { ExternalContactsFormComponent } from './components/external-contacts/external-contacts-form/external-contacts-form.component';
import { CaseEmployeesCardComponent } from './components/case-employees/case-employees-card/case-employees-card.component';
import { CaseEmployeesListComponent } from './components/case-employees/case-employees-list/case-employees-list.component';
import { CaseEmployeesFormComponent } from './components/case-employees/case-employees-form/case-employees-form.component';
import { MultipleEntriesComponent } from './components/multiple-entries/multiple-entries-card.component';
import { Cf2MaterialModule } from '@modules/cf2-material/cf2-material.module';
import { Cf2KendoModule } from '@modules/cf2-kendo/cf2-kendo.module';
import { SpinnerDirective } from './directives/spinner.directive';
import { Cf2TableComponent } from './components/cf2-table/cf2-table.component';
import { DueDateDirective } from './directives/due-date.directive';
import { Cf2FormattedDate } from './pipes/cf2-formatted-date.pipe';
import { Cf2DateStringParsePipe } from './pipes/cf2-date-string-parse.pipe';
import { LogoutComponent } from './components/logout/logout.component';
import { HttpErrorDialogComponent } from './components/http-error-dialog/http-error-dialog.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { PositionSelectFieldsComponent } from './components/position-select-fields/position-select-fields.component';
import { OrgsSitesEmployeesComponent } from './components/orgs-sites-employees/orgs-sites-employees.component';
import { BookCatAppointmentComponent } from './components/book-cat-appointment/book-cat-appointment.component';
import { BookingFilterComponent } from './components/booking-filter/booking-filter.component';
import { ProgramTypePopupComponent } from './components/program-type-popup/program-type-popup.component';
import { ClientListPopupComponent } from './components/client-list-popup/client-list-popup.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgSelectFormlyComponent } from './components/formly-types/ng-select.type';
import { RepeatTypeComponent } from './components/formly-types/RepeatTypeComponent';
import { RepeatTypeCAComponent } from './components/formly-types/RepeatTypeCAComponent';
import { OrgsSitesEmployeesFilterComponent } from './components/orgs-sites-employees/orgs-sites-employees-filter.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AutocompletePositionDirective } from './directives/autocomplete-position-directive';
import { IntegrationResolverPanelComponent } from "@shared/components/integration-resolver/integration-resolver-panel.component";
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SaveConfirmComponent } from "@modules/integration-dashboard/components/save-confirm-dialog/save-confirm.component";
/**
 * @summary - add all components to the components array. Any components in here must be exported, or else they should go into a different location / considera different structure
 */

// vendor modules
const modules = [
  FormsModule,
  RxReactiveFormsModule,
  FontAwesomeModule,
  ReactiveFormsModule,
  NgxDropzoneModule,
  MatSnackBarModule,
  Cf2KendoModule,
  Cf2MaterialModule,
];

// custom shared components
const components = [
  InputFieldTopComponent,
  InputFieldTopNewComponent,
  SinFieldTopComponent,
  SinFieldTopNewComponent,
  CamsFieldTopComponent,
  CamsFieldTopNewComponent,
  ClientIdFieldTopComponent,
  ClientIdFieldTopNewComponent,
  DateFieldTopComponent,
  DateFieldTopNewComponent,
  PhoneFieldTopComponent,
  PhoneFieldTopNewComponent,
  PostalFieldTopComponent,
  SelectFieldTopComponent,
  SelectFieldTopNewComponent,
  SelectMultipleFieldTopComponent,
  TextareaFieldTopComponent,
  ButtonComponent,
  RaisedButtonComponent,
  Cf2DownloadLinkButtonComponent,
  ButtonErrorComponent,
  BackendStatusComponent,
  TestBedComponent,
  HeaderRowComponent,
  DialogComponent,
  IconButtonComponent,
  SearchInputComponent,
  CaseCompactHeaderComponent,
  UploadBoxComponent,
  AltExpansionPanelComponent,
  SelectFieldTopComponent,
  AddressFormCardComponent,
  BaseFormComponent,
  DrawerListComponent,
  MenuComponent,
  SafeHtmlPipe,
  FileRowComponent,
  TypeaheadFieldTopComponent,
  TypeaheadSearchFieldComponent,
  CurrencyFieldTopComponent,
  NumberFieldTopComponent,
  InfoBlockComponent,
  ClientNameLookupComponent,
  AddressCardComponent,
  AddressListComponent,
  AddressFormComponent,
  IdTypesListComponent,
  IdTypesCardComponent,
  IdTypesFormComponent,
  DisabilitiesListComponent,
  DisabilitiesCardComponent,
  DisabilitiesFormComponent,
  ExternalContactsListComponent,
  ExternalContactsCardComponent,
  ExternalContactsFormComponent,
  CaseEmployeesCardComponent,
  CaseEmployeesListComponent,
  CaseEmployeesFormComponent,
  MultipleEntriesComponent,
  DocumentsListComponent,
  DocumentsCardComponent,
  LinkDocumentsDialogComponent,
  CaseNotesFormComponent,
  CaseNotesListComponent,
  CaseNotesCardComponent,
  LinkCaseNotesDialogComponent,
  TimePickerFieldTopComponent,
  IntegrationResolverPanelComponent,
  SelectFieldTopComponent,
  AddressFormCardComponent,
  BaseFormComponent,
  SelectFieldTopComponent,
  DrawerListComponent,
  MenuComponent,
  SafeHtmlPipe,
  FileRowComponent,
  TypeaheadFieldTopComponent,
  CurrencyFieldTopComponent,
  NumberFieldTopComponent,
  FileUploadDetailsComponent,
  AddressGroupComponent,
  ClientNameLookupComponent,
  WorkflowActionsComponent,
  ApprovalTaskComponent,
  SaveConfirmComponent,
  EmploymentHistoryListComponent,
  WarningRowComponent,
  DateFieldFixedComponent,
  InputFieldFixedComponent,
  SelectFieldFixedComponent,
  AutoCompleteSelectFieldFixedComponent,
  AutoCompleteSelectFieldFixedNewComponent,
  NumberFieldFixedComponent,
  ExpenditureWorkflowsListComponent,
  CurrencyFieldFixedComponent,
  TimepickerWrapperComponent,
  BaseComponent,
  Cf2TableComponent,
  NotFoundComponent,
  Cf2TableComponent,
  LogoutComponent,
  HttpErrorDialogComponent,
  OrgsSitesEmployeesComponent,
  PositionSelectFieldsComponent,
  BookCatAppointmentComponent,
  BookingFilterComponent,
  ProgramTypePopupComponent,
  ClientListPopupComponent,
  OrgsSitesEmployeesFilterComponent,
  MaintenanceComponent
];

const directives = [
  DisableControlDirective,
  DateTypeDirective,
  FieldDirective,
  DueDateDirective,
  SpinnerDirective,
  AutocompletePositionDirective
];

const pipes = [Cf2FormattedDate, Cf2DateStringParsePipe];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
    Cf2DateStringParsePipe,
    LogoutComponent,
    HttpErrorDialogComponent,
    OrgsSitesEmployeesComponent,
    PositionSelectFieldsComponent,
    NgSelectFormlyComponent
  ],
  imports: [
    CommonModule,
    ...modules,
    Cf2MaterialModule,
    Cf2KendoModule,
    NgxMaskModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    FormlyMatFormFieldModule,
    TranslocoModule,
    FormlySelectModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'autocomplete',
          component: NgSelectFormlyComponent
        },
        {
          name: 'repeat',
          component: RepeatTypeComponent
        },
        {
          name: 'repeatca',
          component: RepeatTypeCAComponent
        }
      ]
    }),
    RouterModule]
   ,
  exports: [...modules, ...components, ...directives, ...pipes],
  entryComponents: [DialogComponent, ApprovalTaskComponent, LinkDocumentsDialogComponent, LinkCaseNotesDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
