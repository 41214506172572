import { CLOSURE_PLAN_ITEM } from '../views/edit-eap/edit-eap-model';

import { Globals } from '../../../globals';

import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { take, tap, map, concatMap, mergeAll, toArray, catchError } from 'rxjs/operators';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';

import { EapService, CaseService } from '@api/services';
import { CodeTableModel, EapModel, EapPlanItemModel, EapSubGoalModel, PlanItemType } from '@api/models';

import { LoggingService } from '@core/services/logging.service';
import { DataService } from '@core/services/data.service';
import { Cf2Forms } from '@core/models/cf2-forms.model';

import { UserStateService } from '@core/services/user-state.service';

/* constants */
import { PLAN_ITEM_FIELDS, EAP_FIELDS, SUB_GOAL_FIELD } from '../views/create-eap/create-eap.model';

/* es style module imports */
import * as R from 'remeda';
import { TranslocoService } from '@ngneat/transloco';

import moment from 'moment';
import {casefloDateFormat} from "@shared/models/date-formats.model";

// to retrieve sub goals use the regular lookup endpoint (SubGoalType)
// New api added to retrieve Plan Items: /api/Lookup/planItemType/{subGoalCode}

export function makeSubGoal(subGoalCode: number) {
  return (planItems: EapPlanItemModel[]) => ({ subGoalCode, planItems });
}

@Injectable({
  providedIn: 'root',
})
export class EapDataService extends Cf2Forms {
  private _subgoals: CodeTableModel[];
  private _subGoalsForSelect: { value: string, label: string }[];
  private _subGoalOutcomes: { value: string, label: string }[];
  private _eapStatus: CodeTableModel[];
  private _planItems: PlanItemType[] = [];
  private _planItemStatus: CodeTableModel[];
  private _planItemOutcomes: CodeTableModel[];
  private _nocTypes: CodeTableModel[];
  private _clientSatisfactionTypes: CodeTableModel[];  
  private _naicTypes: CodeTableModel[];
  private _employmentGoals: CodeTableModel[];
  private _employeePlacementType: { value: string, label: string }[];

  toDateParser(date: Date, index: number) {
    return date ? moment(date).format(casefloDateFormat) : null;
  }
  
  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  /**
   * Get the status of whether it can be closed
   *
   * @return {*}
   * @memberof EapDataService
   */
  public get canCloseEap() {
    return this.globals.roleCode === 'SM';
  }

  get allPlanItems() {
    return this._planItems;
  }

  /**
   * Get a list of historical eaps
   *
   * @readonly
   * @memberof EapDataService
   */
  get historicalEaps() {
    const caseKey = this.getCaseKey();
    /* TODO: AR - get historical eap's using case key */
    return this.caseSvc.apiCaseParentCaseKeyEapsGet$Json$Response({
      parentCaseKey: caseKey,
    });
  }

  /**
   * Returns all active eaps for listed case in globals
   *
   * @readonly
   * @memberof EapDataService
   */
  get allEaps() {
    const caseKey = this.getCaseKey();
    return this.caseSvc.apiCaseParentCaseKeyEapsGet$Json$Response({
      parentCaseKey: caseKey,
    });
  }

  /* closure outcome codes */
  get eapNocOutComes() {
    return this.dataSvc.lookupRecords('EapOutcomeType').pipe(map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())));
  }

  /* eap goal codes */
  get eapGoalCodes() {
    return this.dataSvc.lookupRecords('EapGoalCodeType').pipe(map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())));
  }

  /* closure types */
  get eapClosureTypes() {
    return this.dataSvc.lookupRecords('ClosureReasonType').pipe(map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())));
  }

  get eapStatusCodes() {
    return this.dataSvc.lookupRecords('EapStatusType').pipe(map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())));
  }

  /* TODO: hookup to the correct data call */
  subGoals$: Observable<CodeTableModel[]> = this.dataSvc.lookupRecords('SubGoalType');
  //subGoals$: Observable<CodeTableModel[]> = this.dataSvc.lookupRecords('SubGoalType').pipe(map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())));

  subGoalsForSelect$: Observable<{ value: string, label: string }[]> = this.dataSvc.lookupRecords('SubGoalType')
    .pipe(
      map((res) => {
        return res.map((opt) => ({
          label: `${opt.description}`,
          value: opt.code,
        }))
      })
    );

  /* mapped "desired noc code" */
  nocTypes$ = this.dataSvc.lookupRecords('NocType').pipe(
    map((res) =>
      res.map((opt) => ({
        description: `${opt.code} - ${opt.description}`,
        label: `${opt.description}`,
        value: opt.code,
      }))
    )
  );

  csTypes$ = this.dataSvc.lookupRecords('ClientSatisfaction').pipe(
    map((res) =>
      res.map((opt) => ({
        description: `${opt.code} - ${opt.description}`,
        label: `${opt.description}`,
        value: opt.code,
      }))
    )
  );

  get naicTypesForSelect$() {
    const nocTypes = this.dataSvc.lookupRecords('NaicsType').pipe(map((res) => R.sortBy(res, (re) => re.description)));
    return nocTypes.pipe(
      map((res) =>
        res.map((opt) => ({
          description: `${opt.code} - ${opt.description}`,
          label: `${opt.description}`,
          value: opt.code,
        }))
      )
    );
  }

  get employmentGoalTypes() {
    return this.dataSvc.lookupRecords('EapGoalCodeType').pipe(
      map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang()))
    );
  }

  /* mapped plan item status */
  planItemStatusOpts$ = this.dataSvc
    .lookupRecords('PlanItemStatusType')
    .pipe(
      tap((res) => {
        return res.push({
          code: "",
          description: ""
        });
      }),
      map((res) => DataService.lookupToOptions(res.filter(opt => opt.code !== 'CANC'), this.translocoService.getActiveLang())),
      map((res) => R.sortBy(res, (re) => re.description))
    );

  /* mapped plan item outcome */
  planItemOutcomeOpts$ = this.dataSvc
    .lookupRecords('PlanItemOutcomeType')
    .pipe(
      map((res) => DataService.lookupToOptions(res, this.translocoService.getActiveLang()))
    );

  get subGoalsOptions() {
    return DataService.lookupToOptions(this._subgoals, this.translocoService.getActiveLang());
  }

  isCamsEapPullEnabled() {
    return this.eapSvc.apiEapEnabledGet$Json().toPromise();
  }

  isEapAlreadyCreated(clientKey: number) {
    return this.eapSvc.apiEapAlreadyCreatedGet$Json({ clientKey });
  }

  /* Get methods */

  getCaseKey() {
    const caseKey = this.globals.caseKey;

    if (!caseKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No key in EapDataService globals globals.caseKey',
      });
    return caseKey;
  }

  getEapKey() {
    const eapKey = this.globals.eapKey;
    if (!eapKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No key in EapDataService globals globals.eapKey',
      });
    return eapKey;
  }

  getSubGoals() {
    return this._subGoalsForSelect;
  }

  /**
   * Get a list of plan items by subgoal code
   * @param subgoalCode - a valid subgoal code
   *
   */
  getPlanItems(subgoalCode: string) {
    const planItems = this._planItems
      .filter((item) => item.subGoalCode === subgoalCode)
      .map((planItem) => ({
        value: planItem.planItemCode,
        description: planItem.planItemDescription,
        label: planItem.planItemDescription,
        disabled: false,
      }));
    return planItems.filter(x => !['EMJP', 'EMJT'].includes(x.value));
  }

  getPlanItemByPlanItemCode(planItemCode: string) {
    const planItems = this._planItems;
    const planItem = R.find(planItems, (planItem: PlanItemType) => planItem.planItemCode === planItemCode);

    return planItem;
  }

  getPlanItemDetails(planItemCode: string) {
    const planItems = this._planItems;
    const planItem = R.find(planItems, (planItem: PlanItemType) => planItem.planItemCode === planItemCode);
    if (!planItem) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'No plan item detail result for EapDataService.getPlanItemDetails',
      });
    }
    return planItem
      ? {
        planItemDetail: planItem.planItemDetail,
        hasExpectedCost: planItem.expectedCostIndicator,
      }
      : { planItemDetail: 'no plan item detail set', hasExpectedCost: false };
  }

  getNocTypes() {
    return this._nocTypes;
  }

  getClientSatisfactionTypes() {
    return this._clientSatisfactionTypes;
  }  

  getNaicsTypes() {
    return this._naicTypes;
  }

  getEmploymentGoals() {
    return this._employmentGoals;
  }

  getSubGoalOutcomes() {
    return this._subGoalOutcomes;
  }

  getPlanItemStatus() {
    return this._planItemStatus;
  }

  getPlanItemOutcomes() {
    return this._planItemOutcomes;
  }

  getEmployeePlacementTypes() {
    return this._employeePlacementType;
  }

  getEapStatus() {
    return this._eapStatus;
  }

  /* Set methods */

  /**
   * Set case key value in globals
   * @param key - the key for the case to set in globals
   */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }

  setEapKey(key: number) {
    this.globals.eapKey = key;
  }

  /* set all the subgoals */
  setSubGoals() {
    return this.subGoals$.pipe(
      take(1),
      tap((subGoals) => {
        this.logSvc.logError({ lvl: 'DEBUG', mssg: 'subgoals loaded' });
        this._subgoals = subGoals;
      }),
      map((subgoals) => subgoals.map((goal) => goal.code)),
      concatMap((subgoalCodes) => subgoalCodes.map((code) => this.dataSvc.lookupPlanItem(code))),
      mergeAll(),
      mergeAll(),
      toArray(),
      tap((res) => (this._planItems = res)),
      catchError((err) => of(err))
    );
  }

  /* set sub goals for dropdown */
  setSubGoalsForSelect() {
    return this.dataSvc.lookupRecords('SubGoalType')
      .pipe(
        map((res) => {
          // skip if NOT SCA/SCM 
          //    and its either ERFS or EFST
          var unauth =  (!['SCA', 'SCM'].includes(this.userStateSvc.roleCode)
            )
            
          return res.map((opt) => ({
            label: (unauth && ['ERFS', 'EFST'].includes(opt.code))?
              null:`${opt.description}`,
            value: (unauth && ['ERFS', 'EFST'].includes(opt.code))?
              null:opt.code,
          }))
        }),
        map((res) => res.filter(opt => opt.label != null)),
        
        tap((res) => (this._subGoalsForSelect = res))
      );
  }

  /* set sub goal outcomes */
  setSubGoalOutcomes() {
    return this.dataSvc.lookupRecords('SubGoalOutcomeType')
      .pipe(
        map((res) => res.filter(opt => opt.code !== 'CANCELLED')),
        map((res) => {
          return res.map((opt) => ({
            label: `${opt.description}`,
            value: opt.code,
          }))
        }),
        tap((res) => {
          return res.push({
            label: "",
            value: ""
          });
        }),
        map((res) => R.sortBy(res, (re) => re.label)),
        tap((res) => (this._subGoalOutcomes = res))
      );
  }

  /* set noc types */
  setNocTypes() {
    const nocTypes = this.dataSvc.lookupRecords('NocType').pipe(map((res) => R.sortBy(res, (re) => re.description)));
    return nocTypes.pipe(
      map((res) =>
        res.map((opt) => ({
          description: `${opt.code} - ${opt.description}`,
          label: `${opt.description}`,
          value: opt.code,
        }))
      ),
      tap((res) => (this._nocTypes = res))
    );
  }

  /* set Client Satisfaction types */
  setClientSatisfactionTypes() {
    const csTypes = this.dataSvc.lookupRecords('ClientSatisfaction').pipe(map((res) => R.sortBy(res, (re) => re.description)));
    return csTypes.pipe(
    //const nocTypes = this.dataSvc.lookupRecords('NocType').pipe(map((res) => R.sortBy(res, (re) => re.description)));
    //return nocTypes.pipe(      
      map((res) =>
        res.map((opt) => ({
          description: `${opt.code} - ${opt.description}`,
          label: `${opt.code} - ${opt.description}`,
          value: opt.code,
        }))
      ),
      
      tap((res) => {this._clientSatisfactionTypes = res})
    );
  }  

  /* */
  setNaicsTypes() {
    const nocTypes = this.dataSvc.lookupRecords('NaicsType').pipe(map((res) => R.sortBy(res, (re) => re.description)));
    return nocTypes.pipe(
      map((res) =>
        res.map((opt) => ({
          description: `${opt.code} - ${opt.description}`,
          label: `${opt.description}`,
          value: opt.code,
        }))
      ),
      tap((res) => (this._naicTypes = res))
    );
  }

  /* set employment goal types */
  setEmploymentGoalTypes() {
    return this.dataSvc.lookupRecords('EapGoalCodeType').pipe(
      map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())),
      tap((res) => (this._employmentGoals = res))
    );
  }

  /* set plan item status dropdown */
  setPlanItemStatus() {
    return this.dataSvc.lookupRecords('PlanItemStatusType').pipe(
      tap((res) => {
        return res.push({
          code: "",
          description: ""
        });
      }),
      map((res) => DataService.lookupToOptions(res.filter(opt => opt.code !== 'CANC' && opt.code !== 'NOT_START'), this.translocoService.getActiveLang())),
      map((res) => R.sortBy(res, (re) => re.description)),
      tap((res) => (this._planItemStatus = res))
    );
  }

  /* set plan item outcome */
  setPlanItemOutcome() {
    return this.dataSvc.lookupRecords('PlanItemOutcomeType').pipe(
      tap((res) => {
        return res.push({
          code: "",
          description: ""
        });
      }),
      map((res) => DataService.lookupToOptions(res.filter(opt => opt.code !== 'CANCELLED'), this.translocoService.getActiveLang())),
      map((res) => R.sortBy(res, (re) => re.description)),
      tap((res) => (this._planItemOutcomes = res)));
  }

  /* set employee placement types  */
  setEmployeePlacementTypes() {
    return this.dataSvc.lookupRecords('EmployeePlacementType').pipe(
      map((res) => {
        return res.map((opt) => ({
          label: `${opt.description}`,
          value: opt.code,
        }))
      }),
      tap((res) => (this._employeePlacementType = res)));
  }

  /* set eap status codes*/
  setEapStatusCodes() {
    return this.dataSvc.lookupRecords('EapStatusType').pipe(
      map((results) => DataService.lookupToOptions(results, this.translocoService.getActiveLang())),
      tap((res) => (this._eapStatus = res.filter(opt => opt.value !== 'COMPL')))
    );
  }

  /**
   * Map subGoalCodes to their appropriate values
   */
  static mapSubGoalCodes(codes: string[]) {
    return (planItems: Partial<EapPlanItemModel>[][]): Partial<EapSubGoalModel[]> =>
      codes.map((code: string, index: number) => ({
        subGoalCode: code,
        planItems: planItems[index]
          ? planItems[index].map((pi) => Cf2Forms.sanitizeForm(EapDataService.mapPlanItem(pi)))
          : [],
      }));
  }

  /**
   * Map plan item
   */
  static mapPlanItem(object: unknown): Partial<EapPlanItemModel> {
    const mappedPlanItem = R.pipe(
      PLAN_ITEM_FIELDS,
      R.map((planItem) => planItem.key),
      R.map((key) => ({ [key]: object[key] })),
      R.reduce((acc, item) => ({ ...acc, ...item }), {})
    );

    return mappedPlanItem;
  }

  static hasOtherDescription(code: string) {
    return code.toLowerCase().includes('oth');
  }

  constructor(
    formBuilder: RxFormBuilder,
    private globals: Globals,
    private logSvc: LoggingService,
    private eapSvc: EapService,
    private dataSvc: DataService,
    private userStateSvc: UserStateService,
    private caseSvc: CaseService,
    translocoService : TranslocoService
  ) {
    super(formBuilder, translocoService);
  }

  /**
   * function to trim subgoals from the list
   * @param codes - an array of the active subgoal codes
   * @param isRemove - a yes/no flag to indicate whether they're being removed
   */
  trimSubGoals(codes: string[], isRemove: boolean) {
    const subGoals = this._subgoals;
    // const index = subGoals.findIndex((val) => val.code === code);
    const mappedSubgoals = DataService.lookupToOptions(subGoals, this.translocoService.getActiveLang());

    return mappedSubgoals.map((opt) => ({
      ...opt,
      disabled: codes.includes(opt.value) ? isRemove : opt.disabled,
    }));
  }

  /**
   * trim the plan items to remove or add a plan item from an array of plan items based on subgoal codes
   */
  trimPlanItems(subgoalCode: string, codes: string[], isRemove = true) {
    const planItems = this.getPlanItems(subgoalCode);
    const mappedPlanItems = planItems.map((opt) => ({
      ...opt,
      // disabled: codes.includes(opt.value) ? isRemove : opt.disabled,
    }));
    return mappedPlanItems;
  }

  mapEap(
    eapDetails: Pick<
      EapModel,
      | 'parentCaseKey'
      | 'desiredNocCode'
      | 'desiredOccupation'
      | 'secondaryGoal'
      | 'primaryGoal'
      | 'parentEapKey'
      | 'eapStatusCode'
    >
  ) {
    if (!eapDetails && !eapDetails.parentCaseKey) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'no parent case key' });
    }
    return (planItems) => (subGoals: string[]) => ({
      ...eapDetails,
      desiredNocCode: this.sliceNocCode(eapDetails.desiredNocCode),
      subGoals: EapDataService.mapSubGoalCodes(subGoals)(planItems),
      eapStatusCode: eapDetails.eapStatusCode ? eapDetails.eapStatusCode : 'ACT',
    });
  }

  sliceNocCode(codeDesc: string) {
    if (!codeDesc) return;
    if (codeDesc.length < 4) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Invalid noc code in EapDataService.sliceNocCode',
      });
      return '0001';
    }
    const int = codeDesc.slice(0, 4);
    if (isNaN(parseInt(int))) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Noc code is NaN in EapDataService.sliceNocCode',
      });
      return '0001';
    }
    return int;
  }

  getPlanItemDate(subGoals: EapSubGoalModel[], opts: { oldest: boolean } = { oldest: true }) {
    const { oldest } = opts;
    if (subGoals.length < 1) return null;
    const flattened = this.flattenPlanItems(subGoals)(oldest);

    return flattened[0];
  }

  flattenPlanItems(subGoals: EapSubGoalModel[]) {
    return (oldest: boolean) =>
      R.pipe(
        subGoals,
        R.map((subGoal) => subGoal.planItems),
        R.flatten(),
        R.sortBy((planItem) => (oldest ? planItem.actualStartDate : planItem.actualEndDate))
      );
  }

  makePlanItemFg(
    value: any = { planItemStatusCode: 'INPR' },
    opts: { rawFields: typeof PLAN_ITEM_FIELDS | typeof CLOSURE_PLAN_ITEM } = {
      rawFields: PLAN_ITEM_FIELDS,
    }
  ) {
    const { rawFields: raw } = opts;
    const config = [...raw];
    const fields = this.makeFields(
      config.map((field) => ({
        ...field,
        value: value[field.key] ? value[field.key] : null,
      }))
    );

    return fields;
  }

  isDeleteSubGoalEnabled(fa: UntypedFormGroup[]) {
    const values = fa.map((fg) => ({
      status: fg.value.planItemStatusCode,
      outcome: fg.value.planItemOutcomeCode,
    }));
    const hasAllInprogress = values.some((value) => value.status !== 'INPR');
    const hasOutcome = values.some((value) => value.outcome !== '');
    return hasAllInprogress && hasOutcome;
  }

  createEapForm(values?: any) {
    /* TODO: change this to create the new EAP form based on a new data model for this particular form */
    const eapConfig = EAP_FIELDS;
    const mapped = eapConfig.map((field) => ({
      ...field,
      value: values ? (values[field.key] ? values[field.key] : null) : null,
    }));
    const eapFields = this.makeFields([...mapped]);
    return eapFields;
  }

  makeSubGoalFc(): UntypedFormControl {
    const field = SUB_GOAL_FIELD;
    return new UntypedFormControl(null, this.fieldTypeMap[field.type]);
  }

  setFormattedPiDates(subGoals: EapSubGoalModel[]) {
    return subGoals.map((goal) => ({
      ...goal,
      planItems: goal.planItems.map((pi) => ({
        ...pi,
        expectedStartDate: pi.expectedStartDateFormatted ? pi.expectedStartDateFormatted : null,
        expectedEndDate: pi.expectedEndDateFormatted ? pi.expectedEndDateFormatted : null,
        actualEndDate: pi.actualEndDate ? pi.actualEndDateFormatted : null,
        actualStartDate: pi.actualStartDate ? pi.actualStartDateFormatted : null,
      })),
    }));
  }

  filterEaps(eaps: EapModel[]) {
    return (filter: 'ACT' | 'COMPL' | 'ONHOLD') => eaps.filter((eap) => eap.eapStatusCode === filter);
  }

  findEap(eaps: EapModel[], key: number) {
    const item = R.find(eaps, (eap) => eap.parentEapKey === key);
    if (!item) return eaps[0];
    return item;
  }

  submit(value: Partial<EapModel>) {
    const result = this.eapSvc.apiEapPost$Json$Response({ body: value });
    return result;
  }

  getIntegrationPushRecords(eapReferenceNumber?: number) {
    return this.eapSvc.apiEapPushIntegrationGet$Json$Response({ eapReferenceNumber });
  }

  integrationPushRecords(parentEapKey?: string) {
    return this.eapSvc.apiEapPushIntegrationPost$Json$Response({ body: { parentEapKeys: parentEapKey } })
  }

  getIntegrationPullRecords(eapReferenceNumber?: number) {
    return this.eapSvc.apiEapPullIntegrationGet$Json$Response({ eapReferenceNumber });
  }

  integrationPullRecords(clientReferenceNumber?: string) {
    return this.eapSvc.apiEapPullIntegrationPost$Json$Response({ body: { clientReferenceNumbers: clientReferenceNumber }});
  }

  cleanFg(fg) {
    fg.markAsPristine();
    fg.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  getFgErrorList(fg: UntypedFormGroup | UntypedFormArray, errors, isFa?) {
    if (!isFa && !this.invalidExceptFor(fg as UntypedFormGroup, ['planStartDate'])) return;
    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (!errors.includes(this.errorMap(control, this.controlLabel(key)))) {
            if (!isFa || (key as any) < (fg.controls.length as number) - 1) {
              errors.push(this.errorMap(control, this.controlLabel(key)));
              const endctrl = fg.get('actualEndDate') as UntypedFormControl;
              if (
                key === 'actualStartDate' &&
                !endctrl.errors &&
                !endctrl.value &&
                !errors.includes(
                  this.errorMap(
                    fg.get('actualEndDate') as UntypedFormControl,
                    this.controlLabel('actualEndDate'),
                    'is required'
                  )
                )
              ) {
                errors.push(this.errorMap(endctrl, this.controlLabel('actualEndDate'), 'is required'));
              }
            }
          }
        });
      }
    });
  }

  invalidExceptFor(fg: UntypedFormGroup, exceptions: string[]) {
    const ctrls = fg.controls;
    let any_err = false;
    Object.keys(ctrls).forEach((key) => {
      if (exceptions.includes(key)) {
        any_err = any_err || (ctrls[key].invalid && ctrls[key].touched);
      } else {
        any_err = any_err || ctrls[key].invalid;
      }
    });

    return any_err;
  }

  getFgErrorListEditEap(fg: UntypedFormGroup | UntypedFormArray, errors, isFa?) {
    Object.keys(fg.controls).forEach((key) => {
      const control = fg.get(key) as UntypedFormGroup;

      if (key === 'subGoals') {
        //TODO: Improve
        //traverse through the sub goal form
        Object.keys(control.controls).forEach((c) => {
          const subGoalControl = control.get(c) as UntypedFormGroup;
          if (subGoalControl.invalid) {
            Object.keys(subGoalControl.controls).forEach((item) => {
              const itemControl = subGoalControl.get(item) as UntypedFormGroup;
              if (itemControl.invalid && itemControl.controls !== undefined) {
                Object.keys(itemControl.controls).forEach((planItem) => {
                  const planItemControl = itemControl.get(planItem) as UntypedFormGroup;
                  this.getFgErrorListEditEap(planItemControl, errors);
                });
              } else {
                if (itemControl.invalid && itemControl.errors !== null && itemControl.errors.required) {
                  item === 'subGoalCode' ? errors.push('Sub-goal is required') : null;
                }
                if (itemControl.invalid && itemControl.errors !== null && itemControl.errors.multipleNotDefinedSubGoals) {
                  item === 'subGoalCode' ? errors.push('Duplicate sub-goals without an defined outcome cannot be added.') : null;
                }
                if(item === 'subGoalOutcomeCode' &&itemControl.errors !== null && itemControl.errors.SubGoalAndPlanItemNotInSync){          
                  errors.push('The Plan-Item and Sub-Goal outcomes must match where this is only one plan-item');

                }
              }
            });
          }

          Object.keys(subGoalControl.controls).forEach((item) => {
            if (item === 'planItems') {
              const planItemControl = subGoalControl.get(item) as UntypedFormGroup;
              if (Object.keys(planItemControl.controls).length === 0) {
                errors.push('Plan item is required');
              }
            }
          });
        });
      } else {
        if (fg.controls[key].invalid) {
          const errorMessage = this.errorMap(fg.controls[key] as UntypedFormControl, this.controlLabel(key));
          errors.push(errorMessage);
        }
      }
    });
  }

  public errorMap(fc: UntypedFormControl, label: string, message?: string): string {
    if (!fc.errors && !message) return '';
    if (message) return `${label} ${message}`;
    if (label === 'planItemStatusCode') return 'All Plan Items needs to be closed before closing EAP';
    //if (fc.errors.required) return `${label} is required`; 
    if (fc.errors.required) return this.translocoService.translate("Message.Error.ControlIsNotValid", {ControlName: label});
    if (fc.errors.duplicateSin) return this.translocoService.translate("DuplicateSin");
    if (fc.errors.sin && fc.errors.sin.message) return this.translocoService.translate("Message.Error.InvalidSin");
    if (fc.errors.minDate) return 'Plan Start cannot be before the earliest Expected Start date of any Plan Items';
    if (fc.errors.maxDate) return 'End date must be after start date and before most recent actual end date';
    if (fc.errors.minLength && fc.errors.minLength.message) return `${label} ${fc.errors.minLength.message}`;

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translate("Message.Error.IncompletePhoneNumber");
    }

    if (fc.errors.minlength) return `Minimum of ${fc.errors.minlength.requiredLength} characters`;
    if (fc.errors.maxlength) return `Maximum of ${fc.errors.maxlength.requiredLength} characters`;
    if (fc.errors.email) return `${label} must be in the format "example@mail.com"`;

    if (fc.errors.name) return `Invalid name.`;

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format ${casefloDateFormat}`;

    if (fc.errors.SubGoalAndPlanItemNotInSync) return `The Plan-Item and Sub-Goal outcomes must match where this is only one plan-item`;
    if (fc.errors.validateEndDate) return `${label} cannot be prior to Actual Start Date`;
    if (fc.errors.cost) return `${label} cannot be less than 0.00 or greater than 100,000.00`;
    if (fc.errors.outcomeCancelled) return `${label} cannot be Cancelled. To cancel a plan item, the Actual Start Date and Actual End Date must be cleared`;
    if (fc.errors.closingEap) return `EAP cannot be closed. All Plan Items must be either in Complete or Cancelled status to close the EAP`;
    if (fc.errors.minMaxHours) return `Employment Hours per Week' must be between '1' and '99`;
    if (fc.errors.costCancelled) return `Cost cannot be greater than 0.00 if either plan item status and outcome is cancelled`;
    if (fc.errors.minWage) return `Wage cannot be 0.`; 
    if (fc.errors.validateActualEndDateComplete) return `Plan Item Status cannot be 'IN PROGRESS' once Plan Item Actual End Date has been defined`; 
  }

  public controlLabel(control: string): string {
    switch (control) {
      case 'desiredNocCode':
        return 'Desired Noc Code';
      case 'expectedCost':
        return 'Expected Cost';
      case 'primaryGoal':
        return 'Primary Employment Goal';
      case 'expectedStartDate':
        return 'Expected Start Date';
      case 'expectedEndDate':
        return 'Expected End Date';
      case 'actualEndDate':
        return 'Actual End Date';
      case 'actualStartDate':
        return 'Actual Start Date';
      case 'actualCost':
        return 'Actual Cost';
      case 'desiredOccupation':
        return 'Desired Occupation';
      case 'planItemCode':
        return 'Plan Item';
      case 'planItemOutcomeCode':
        return 'Outcome';
      case 'closureReasonCode':
        return 'Closure Reason';
      case 'outcomeTypeCode':
        return 'Closure Outcome';
      case 'comment':
        return 'Description';
      case 'planStartDate':
        return 'Plan End Date';
      case 'planEndDate':
        return 'Plan End Date';
      case 'camsEapGoalHourlyWage':
        return 'Wage per Hour';
      case 'camsEapGoalWorkHours':
        return 'Number of Hours Worked';
      case 'hourlyPay':
        return 'Hourly Rate';
      case 'hoursPerWeek':
        return 'Hours Per Week';
      case 'nocCode':
        return 'NOC Code';
      case 'naicsCode':
        return 'NAICS Code';
      case 'camsPlacementCategoryCd':
        return 'Placement Category';
      case 'jobTitle':
        return 'Job Title';
      case 'rationale':
        return 'Rationale';
    }

    return control;
  }

  // hasErrors() {
  //   return !!this.eapFg?.invalid;
  // }
}