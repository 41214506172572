import { Injectable } from '@angular/core';
import {ClientFilterParams, ClientService} from '@api/services/client.service';
import { CaseService } from '@api/services/case.service';
import { AddressDataService } from '@shared/components/address/services/address-data.service';
import { IdTypesDataService } from '@shared/components/id-types/services/id-types-data.services';
import {
  ClientCaseDetailsDataService,
  CLIENT_FORM_FIELDS,
  CASE_FORM_FIELDS,
} from '../components/client-case-details-form/client-case-details-data.service';
import {
  CaseDesignationModel,
  CaseModel,
  CaseServiceInformationModel,
  ClientCaseModel,
  ClientContactModel,
  ClientModel,
  ClientSelfIdentificationModel,
} from '@api/models';
import { ClientKeysType } from '../components/client-case-details-form/client-case-details-form.model';

import * as R from 'remeda';
import { DisabilitiesDataService } from '@shared/components/disabilities/services/disabilities-data.service';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, from, of, throwError } from 'rxjs';
import { ExternalContactsDataService } from '@shared/components/external-contacts/services/external-contacts-data.service';
import { ModalService } from '@shared/services/modal.service';
import { CaseEmployeesDataService } from '@shared/components/case-employees/services/case-employees-data.service';
import { LoggingService } from '@core/services/logging.service';
import { ViewType } from '@core/models/view-type.model';
import { Globals } from 'src/app/globals';
import { FormsFns } from '@core/models/forms-fns.model';
import { UserStateService } from '@core/services/user-state.service';
import { DataService } from '@core/services/data.service';
import { MonitoringPhaseDataService } from '@modules/outcomes/services/monitoring-phase-data.service';

@Injectable({ providedIn: 'root' })
export class ClientCaseDataService {
  public viewType: ViewType;
  public clientCase: ClientCaseModel;
  public serviceInformation$ = new BehaviorSubject<CaseServiceInformationModel>({});
  showErrors$ = new BehaviorSubject<boolean>(false);
  showLoading$ = new BehaviorSubject<boolean>(null);
  stepperFormIndex = 0;
  private _caseHistory: CaseModel[] = [];
  showRecentlyCreatedCase = false;
  showDetailsTab = false;

  constructor(
    private caseSvc: CaseService,
    private clientSvc: ClientService,
    private detailsSvc: ClientCaseDetailsDataService,
    private addressSvc: AddressDataService,
    private idTypesSvc: IdTypesDataService,
    private disabilitySvc: DisabilitiesDataService,
    private externalContactsSvc: ExternalContactsDataService,
    private caseEmployeesSvc: CaseEmployeesDataService,
    private modalSvc: ModalService,
    private logSvc: LoggingService,
    private globals: Globals,
    private userStateSvc: UserStateService,
    private dataSvc: DataService,
    private monitoringPhaseDataSvc: MonitoringPhaseDataService
  ) {}

  setViewType(view: ViewType) {
    this.viewType = view;
  }

  get caseKey() {
    const key = this.globals.caseKey;
    if (!key)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No caseKey set in globals.caseKey for client',
      });
    return key;
  }

  get clientKey() {
    const key = this.globals.clientKey;
    if (!key)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No clientKey set in globals.clientKey for client',
      });
    return key;
  }

  getClient(parentClientKey: number, parentCaseKey?: number) {
    if (!parentClientKey) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    return this.clientSvc.apiClientParentClientKeyGet$Json({
      parentClientKey,
      parentCaseKey
    });
  }

  set caseHistory(caseHistory: CaseModel[]) {
    this._caseHistory = caseHistory;
  }

  get caseHistory() {
    return this._caseHistory;
  }

  getCaseStatusHistory(parentCaseKey: number) {
    if (!parentCaseKey) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    return this.caseSvc.apiCaseParentCaseKeyStatusHistoryGet$Json({ parentCaseKey });
  }

  getClientSelfIdentifications(parentClientKey: number) {
    if (!parentClientKey) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    return this.clientSvc.apiClientParentClientKeyClientSelfIdentificationsGet$Json({ parentClientKey });
  }

  async saveSelfIdentifications(parentClientKey: number) {
    const getOnlyChanges = (original, changes) => {
      // compare changes to original
      const onlyChanged = {};
      const checkedValues = {};
      const unCheckedValues = {};
      // compate oringal and changed and get changed values
      Object.keys(changes).forEach((key) => {
        if (changes[key] !== original[key]) onlyChanged[key] = changes[key];
      });

      // split checked and unchecked values
      Object.keys(onlyChanged).forEach((key) => {
        if (onlyChanged[key]) checkedValues[key] = onlyChanged[key];
        else unCheckedValues[key] = onlyChanged[key];
      });

      return [checkedValues, unCheckedValues];
    };

    const { original, changes } = this.detailsSvc.getSelfIdentficationValue();

    const [checkedValues, unCheckedValues] = getOnlyChanges(original, changes);
    console.log('Final Changes:', checkedValues, unCheckedValues);

    const promises = [];
    for(var i in changes) {
      original[i] = changes[i];
    }
    const newObj = Object.fromEntries(Object.entries(original).filter(([, v]) => v == true))
    const body: ClientSelfIdentificationModel[] = Object.keys(newObj).map((item) => ({
      code: item,
    }));

    if (Object.keys(checkedValues).length > 0 || Object.keys(unCheckedValues).length > 0) {
        promises.push(
          this.clientSvc
            .apiClientParentClientKeyClientSelfIdentificationsPost$Json$Response({
              parentClientKey,
              body,
            })
            .toPromise()
        );
        
      try {
        console.log(promises);
        if (promises.length === 0) {
          return Promise.resolve({
            status: 200,
          });
        } else {
          const response = await Promise.all(promises);
          let status = 200;
          response.forEach((res) => (res.status !== 200 ? (status = 500) : ''));

          return Promise.resolve({ status });
        }
      } catch (e) {
        Promise.reject({ status: 500 });
      }
    } else {
      return Promise.resolve({ status: 200 });
    }
  }

  prepareContacts(detailsFormValue) {
    const getOldContact = (type: 'EMAIL' | 'HOME' | 'MOBI') => {
      return this.clientCase?.client?.clientContacts?.length > 0
        ? R.find(this.clientCase.client.clientContacts, (contact) => contact.code === type)
        : null;
    };

    const clientContacts = [];
    if (detailsFormValue.primaryEmail) {
      const primaryEmailObj: ClientContactModel = {
        value: detailsFormValue.primaryEmail,
        code: 'EMAIL',
        //key: this.clientCase?.client?.clientContacts.find(c => c.primaryEmail === true).key,
        primaryContact: detailsFormValue.primaryContactType === 'EMAIL',
        primaryEmail: true,
        primaryPhone: false,
      };

      if (this.viewType !== 'CREATE') {
        primaryEmailObj.key = this.clientCase.client.clientContacts.filter(x => x.value == primaryEmailObj.value)?.[0]?.key || 0;
      }

      clientContacts.push(primaryEmailObj);
    }

    if (detailsFormValue.primaryPhone) {
      const primaryPhoneObj: ClientContactModel = {
        value: detailsFormValue.primaryPhone,
        code: 'HOME',
        // parentClientContactKey: null,
        primaryContact: detailsFormValue.primaryContactType === 'HOME',
        primaryEmail: false,
        primaryPhone: true,
      };

      if (this.viewType !== 'CREATE') {
        primaryPhoneObj.key = this.clientCase.client.clientContacts.filter(x => x.value == primaryPhoneObj.value)?.[0]?.key || 0;
      }

      clientContacts.push(primaryPhoneObj);
    }

    // mobile phone field does not need a null check
    // if a null check is done here then user cannot remove the number
    const primaryMobileObj: ClientContactModel = {
      value: detailsFormValue.primaryMobile,
      code: 'MOBI',
      // parentClientContactKey: null,
      primaryContact: detailsFormValue.primaryContactType === 'MOBI',
      primaryEmail: false,
      primaryPhone: false,
    };

    if (this.viewType !== 'CREATE') {
      primaryMobileObj.key = this.clientCase.client.clientContacts.filter(x => x.value == primaryMobileObj.value)?.[0]?.key || 0;
    }

    clientContacts.push(primaryMobileObj);
    

    if (this.viewType !== 'CREATE') {
      this.clientCase.client.clientContacts.filter(x => !clientContacts.find(cc => cc.key == x.key)).forEach((con => {
        const otherContacts: ClientContactModel = {
          value: con.value,
          code: con.code,
          primaryContact: false,
          primaryEmail: false,
          primaryPhone: false,
          key: con.key
        };
  
        clientContacts.push(otherContacts);
      }));
    }
    
    return clientContacts;
  }

  prepareClientCaseData() {
    const sanitizeForm = (formData: any = {}) => {
      const blacklist = Object.keys(formData).filter(
        (key) =>
          formData[key] == undefined ||
          formData[key] == null ||
          formData[key].length < 1 ||
          (typeof formData[key] === 'string' && formData[key] === '')
      ) as any;

      const sanitized = R.omit(formData, blacklist);
      return sanitized;
    };

    const detailsFormValue = this.detailsSvc.getFormValue();
    const addressesFormValue = this.addressSvc
      .getEntityData({
        sanitizeParentKey: true,
      })
      .map(sanitizeForm);

    const idTypesFormValue = this.idTypesSvc
      .getEntityData({
        sanitizeParentKey: true,
      })
      .map(sanitizeForm);

    let clientFormValues = R.pick<
      ClientModel,
      ClientKeysType | 'clientContacts' | 'clientAddresses' | 'clientIdentifiers'
    >(detailsFormValue, CLIENT_FORM_FIELDS);

    const caseFormValues = R.pick<CaseModel, keyof CaseModel>(detailsFormValue, CASE_FORM_FIELDS);

    // Contacts
    clientFormValues.clientContacts = [...this.prepareContacts(detailsFormValue)];

    // Address
    clientFormValues.clientAddresses = [...addressesFormValue];

    // Client Identifiers
    clientFormValues.clientIdentifiers = [...idTypesFormValue];

    // Edit view
    if (this.viewType !== 'CREATE') {
      // get prev client case values retrieved from backend
      const { client: prevClient, case: prevCase } = this.clientCase;
      // Empty stirngs are throwing error in the backend api so just assign null if its empty string
      const removeNullValues = (clientData) => {
        const cloneClient = clientData;
        Object.keys(cloneClient).forEach((item) =>
          typeof cloneClient[item] === 'string' && cloneClient[item].length === 0 ? (cloneClient[item] = null) : null
        );

        return cloneClient;
      };

      let pickPrevClient = removeNullValues(prevClient);
      const pickPrevCase = prevCase;

      // Use existing client,  Create new Case
      if (this.detailsSvc.viewType === 'CREATE') {
        // If creating a new case then dont update client values just take the existing client values
        clientFormValues = {};

        // else edit client and case view
      } else {
        // omit arrays, these are managed seperately
        pickPrevClient = R.omit(pickPrevClient, ['clientAddresses', 'clientContacts', 'clientIdentifiers']);
      }

      return {
        client: R.merge(pickPrevClient, clientFormValues),
        case: R.merge(pickPrevCase, caseFormValues),
      };
    } else {
      return {
        client: clientFormValues,
        case: caseFormValues,
      };
    }
  }

  async save() {
    this.touchAll();

    // check is form valid, if not resolve with invalid message
    if (!this.isFormValid()) {
      this.showErrors$.next(true);
      return Promise.reject({
        status: 'InvalidForm',
      });
    }

    this.showLoading$.next(true);

    const body: any = this.prepareClientCaseData();

    return this.clientSvc
      .apiClientPost$Json({
        body,
      })
      .pipe(
        catchError((err) => {
          console.log('First catch:', err);
          this.logSvc.logError({
            lvl: 'ERROR',
            mssg: `Error Saving client/case ${this.constructor.name}.save}`,
          });

          // stop showing load screen
          this.showLoading$.next(false);

          this.modalSvc.openDialog({ data: this.modalSvc.errorDialog }).afterClosed();

          return throwError(new Error('Client Case request failed'));
        }),
        mergeMap((res: ClientCaseModel) => {
          if ((JSON.stringify(res)).includes('FailedConcurrentUpdate')) {
            return throwError(new Error('Failed concurrent update'));
          } else {
            if (res?.client?.parentClientKey && res?.case?.parentCaseKey) {
              this.clientCase = res as ClientCaseModel;
              console.log(this.clientCase);

              const parentClientKey = res.client.parentClientKey;
              const parentCaseKey = res.case.parentCaseKey;

              this.globals.caseKey = parentCaseKey;
              this.globals.clientKey = parentClientKey;

              this.userStateSvc.setItem({ key: 'caseKey', value: parentCaseKey });
              this.userStateSvc.setItem({ key: 'clientKey', value: parentClientKey });

              return forkJoin({
                clientIdentification$: this.saveSelfIdentifications(parentClientKey),
                disabilities$: from(this.disabilitySvc.saveParentClientDisabilities(parentClientKey)),
                externalContacts$: from(this.externalContactsSvc.saveParentCaseKeyExternalContacts(parentCaseKey)),
              }).pipe(
                map((depsResponse) => {
                  const overAllResponse = {
                    status: 200,
                  };

                  // if atlease one of the backend request fail then set the status to 500
                  R.forEachObj.indexed(depsResponse, (response, key) => {
                    if (response?.status !== 200) {
                      this.logSvc.logError({
                        lvl: 'ERROR',
                        mssg: `Error saving ${key}`,
                      });
                      overAllResponse;
                    }
                  });

                  return overAllResponse;
                }),
                catchError((err) => {
                  console.log('Second catch: ', err);
                  this.logSvc.logError({
                    lvl: 'ERROR',
                    mssg: `Error Saving client/case dependants ${this.constructor.name}.save}`,
                  });

                  // stop showing load screen
                  this.showLoading$.next(false);

                  this.modalSvc.openDialog({ data: this.modalSvc.errorDialog }).afterClosed();

                  return throwError(new Error('Client Case dependants: Request failed'));
                }),
                switchMap((overallResponse) => {
                  if (overallResponse.status === 200) {
                    return this.clientCase.case?.caseStatusCode === 'MON'
                      ? from(this.monitoringPhaseDataSvc.save('create')).pipe(
                          catchError((err) => {
                            console.log('third catch: ', err);
                            this.logSvc.logError({
                              lvl: 'ERROR',
                              mssg: `Error Saving Monitoring Phase ${this.constructor.name}.save}`,
                            });

                            // stop showing load screen
                            this.showLoading$.next(false);

                            this.modalSvc.openDialog({ data: this.modalSvc.errorDialog }).afterClosed();

                            return throwError(new Error('Monitoring Phase Update: Request failed'));
                          }),
                          map((monitoringPhaseKey) =>
                            typeof monitoringPhaseKey === 'number' ? { status: 200 } : { status: 500 }
                          )
                        )
                      : of({ status: 200 });
                  } else {
                     return of(overallResponse);
                  }
                })
              );
            }
          }
        }),
        tap((res) => {
          this.showLoading$.next(false);
        })
      )
      .toPromise();
  }

  async triggerCamsIntegration(parentClientKey: number, parentCaseKey: number) {
    return this.clientSvc.apiClientIntegrationPost$Json({ parentClientKey, parentCaseKey }).toPromise();
  }

  sanitize() {
    this.viewType = null;
    this.clientCase = null;
    this._caseHistory = [];
    this.serviceInformation$.next({});
    this.showErrors$.next(null);
    this.showLoading$.next(null);
  }

  isFormValid() {
    return (
      this.detailsSvc.isFormValid() &&
      this.addressSvc.isFormValid() &&
      this.idTypesSvc.isFormValid() &&
      this.externalContactsSvc.isFormValid() &&
      this.disabilitySvc.isFormValid()
    );
  }

  isFormDirty() {
    console.log({
      details: this.detailsSvc?.isFormDirty(),
      add: this.addressSvc?.isFormDirty(),
      adde: this.addressSvc?.isFormDirty(true),
      id: this.idTypesSvc?.isFormDirty(),
      ext: this.externalContactsSvc?.isFormDirty(),
      dis: this.disabilitySvc?.isFormDirty(),
    });
    return (
      this.detailsSvc?.isFormDirty() ||
      this.addressSvc?.isFormDirty() ||
      this.addressSvc?.isFormDirty(true) ||
      this.idTypesSvc?.isFormDirty() ||
      this.externalContactsSvc?.isFormDirty() ||
      this.disabilitySvc?.isFormDirty()
    );
  }

  touchAll() {
    this.detailsSvc.clientCaseFormGroup.markAllAsTouched();
    this.addressSvc.formGroup?.markAllAsTouched();
    this.addressSvc.externalFormGroup?.markAllAsTouched();
    this.idTypesSvc.formGroup?.markAllAsTouched();
    this.externalContactsSvc.formGroup?.markAllAsTouched();
    this.disabilitySvc.formGroup?.markAllAsTouched();
  }

  markAsPristine() {
    this.detailsSvc.clientCaseFormGroup.markAsPristine();
    this.addressSvc.formGroup?.markAsPristine();
    this.addressSvc.externalFormGroup?.markAsPristine();
    this.idTypesSvc.formGroup?.markAsPristine();
    this.externalContactsSvc.formGroup?.markAsPristine();
    this.disabilitySvc.formGroup?.markAsPristine();
  }

  disableAll() {
    this.detailsSvc.clientCaseFormGroup?.disable();
    this.addressSvc.formGroup?.disable();
    this.addressSvc.externalFormGroup?.disable();
    this.idTypesSvc.formGroup?.disable();
    this.externalContactsSvc.formGroup?.disable();
    this.disabilitySvc.formGroup?.disable();
  }

  // enableAll() {
  //   this.detailsSvc.clientCaseFormGroup.enable();
  //   this.addressSvc.formGroup?.enable();
  //   this.addressSvc.externalFormGroup?.enable();
  //   this.idTypesSvc.formGroup?.enable();
  //   this.externalContactsSvc.formGroup?.enable();
  //   this.disabilitySvc.formGroup?.enable();
  // }

  updateAllValueAndValidity() {
    this.detailsSvc.clientCaseFormGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.addressSvc.formGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.addressSvc.externalFormGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.idTypesSvc.formGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.externalContactsSvc.formGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
    this.disabilitySvc.formGroup?.updateValueAndValidity(FormsFns.formUpdateOpts);
  }

  parseDate = (eventDate: string): Date => {
    return new Date(eventDate);
  };

  // TODO: Remove deprecated functions
/*  parseDateFormatted = (eventDate: string): string => {
    if (!eventDate || eventDate === '0001-01-01T00:00:00+00:00') return '';

    // get parsed time zone date
    const date = parseDateFormattedFromString(eventDate);

    // format: 8:30 AM
    const time = this.parseDate(eventDate).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    //yyyy-MMM-dd h:mm tt
    return `${date} ${time}`;
  };*/

/*  parseDateOnlyFormatted = (eventDate: string): string => {
    if (!eventDate || eventDate === '0001-01-01T00:00:00+00:00') return '';

    // get parsed time zone date
    const dateParsed = this.parseDate(eventDate);
    const month = (dateParsed.getMonth() + 1) < 10 ? '0' + (dateParsed.getMonth() + 1) : (dateParsed.getMonth() + 1)
    const day = dateParsed.getDate() < 10 ? '0' + dateParsed.getDate() : dateParsed.getDate()
    const date = dateParsed.getFullYear() + '-' + month + '-' + day;

    //ISO-8601 format
    //yyyy-MMM-dd
    return `${date}`;
  };*/

  getFilteredClients(params: ClientFilterParams) {
    return this.clientSvc.apiClientFilteredGet$Json(params);
  }
}
