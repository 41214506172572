import { Injectable } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { Globals } from 'src/app/globals';
import { UserService, SchedulingService } from '@api/services';
import { LoggingService } from '@core/services/logging.service';
import { map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MeetingOccurrenceModel, MeetingModel, CodeTableModel, MeetingAttendeeModel, ProcessResponse, MeetingSubjectTypes } from '@api/models';
import { StrictHttpResponse } from '@api/strict-http-response';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { casefloDateFormat, parseDateTimeAsDateFromString, parseTimeFormatted } from '@shared/models/date-formats.model';
import { LOCKED_APPOINTMENT_DIALOG, ModalService } from '@shared/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulingDataService {
  private _isRecurrenceSeries = false;
  
  // meeting data & events
  public meetings: MeetingOccurrenceModel[];
  public meetingDates: string[];
  public meetingsReady$ = new BehaviorSubject<boolean>(null);
  public meetingDatesReady$ = new BehaviorSubject<boolean>(null);
  public meetingLocked$ = new BehaviorSubject<boolean>(null);

  // track locked meeting
  public lockedMeetingOccurrenceKey: number;

  get isRecurrenceSeries() {
    return this._isRecurrenceSeries;
  }

  get orgKey() {
    return this.globals.organizationKey;
  }
  eventCategoryTypeOptions$ = this.dataSvc
    .lookupRecords('MeetingCategoryType')
    .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  eventSubjectTypeOptions$ = this.dataSvc
    .lookupRecords('MeetingSubjectType')
    .pipe(map((result) => this.optionsAddField(DataService.lookupToOptions(result, this.translocoService.getActiveLang()), result, 'parentCode')));
  meetingTypeOptions$ = this.dataSvc
    .lookupRecords('MeetingType')
    .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  meetingStatusTypeOptions$ = this.dataSvc
    .lookupRecords('MeetingStatusType')
    .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));



  attendanceResultTypesOptions$ = this.dataSvc
    .lookupRecords('AttendanceResultType')
    .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));

  sites$ = this.dataSvc
    .getSites(this.getOrganizationKey())
    .pipe(map((sites) => sites.map((s) => ({ description: s.siteName, value: s.parentSiteKey }))));

  user$ = this.userService.apiUserGet$Json().pipe();

  newAppointment: { start; end; isAllDay };

  preferredLanguage$ = this.dataSvc.getPreferredLangOpts();

  filters = {};

  constructor(
    private dataSvc: DataService,
    private globals: Globals,
    private modalSvc: ModalService,
    private schedulingSvc: SchedulingService,
    private logSvc: LoggingService,
    private userService: UserService,
    private translocoService: TranslocoService
  ) { }

  /* adds a field to an option list from DataService.lookupToOptions(result, this.translocoService.getActiveLang()) */
  optionsAddField(opList: any[], origList: CodeTableModel[], key: string) {
    const opListClone = opList;
    opListClone.forEach((item, i) => {
      item[key] = origList[i][key];
    });
    return opListClone;
  }

  /* State handlers - state is loaded from local storage */
  getMeetingKey() {
    const meetingKey = this.globals.meetingKey;
    if (!meetingKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no meetingKey set in globals for scheduling-service data service',
      });
    }

    return meetingKey;
  }

  setMeetingKey(key: number) {
    this.globals.meetingKey = key;
  }

  getMeetingOccurrenceKey() {
    const meetingOccurrenceKey = this.globals.meetingOccurrenceKey;
    if (!meetingOccurrenceKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no meetingOccurencyKey set in globals for scheduling-service data service',
      });
    }

    return meetingOccurrenceKey;
  }

  /* If isRecurrenceSeries is false -
   *  Recurring Appointment Edit response is to update specific occurrence
   *    NOT all Series occurrences
   * If isRecurrenceSeries is ture - Update all occurrences
   */
  setMeetingOccurrenceKey(key: number, isRecurrenceSeries = true) {
    this.globals.meetingOccurrenceKey = key;
    this._isRecurrenceSeries = isRecurrenceSeries;
  }

  /* get the case key from local storage */
  getCaseKey() {
    const caseKey = this.globals.caseKey;
    if (!caseKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for scheduling-service service',
      });
    return caseKey;
  }

  /* set the case key in local storage */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }

  getClientKey() {
    return this.globals.clientKey;
  }

  getClientName() {
    return this.globals.clientDisplayName;
  }

  getSiteKey(): number {
    return this.globals.siteKey;
  }

  getOrganizationKey(): number {
    return this.globals.organizationKey;
  }

  getRooms(parentSiteKey: number) {
    return this.schedulingSvc
      .apiSchedulingParentSiteKeyRoomsGet$Json({
        parentSiteKey: parentSiteKey,
      })
      .pipe(map((rooms) => rooms.map((r) => ({ description: r.roomDescription, value: r.siteRoomKey }))));
  }

  getMeeting(): Observable<MeetingModel> {
    const parentMeetingKey = this.getMeetingKey();
    if (!parentMeetingKey || isNaN(parentMeetingKey)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'parentMeetingKey value in get getMeeting() scheduling-data.service isNan or invalid',
      });
    }

    return this.schedulingSvc.apiSchedulingParentMeetingKeyGet$Json({
      parentMeetingKey: parentMeetingKey,
    });
  }

  getMeetingOccurrence(): Observable<MeetingOccurrenceModel> {
    const key = this.getMeetingOccurrenceKey();

    if (!key || isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in get getMeetingOccurrence() scheduling-data.service isNan or invalid',
      });
    }

    return this.schedulingSvc.apiSchedulingMeetingOccurrenceParentMeetingOccurrenceKeyGet$Json({
      parentMeetingOccurrenceKey: key,
    });
  }

  getSiteMeetingOccurrences(
    siteKey: number[] = null,
    parentEmployeeKey: number[] = null,
    meetingCategoryType: string[] = null,
    meetingSubjectType: string[] = null,
    fromDate: Date = null,
    toDate: Date = null
  ): Observable<StrictHttpResponse<MeetingOccurrenceModel[]>> {
    let params: Parameters<SchedulingService['apiSchedulingMeetingOccurrenceGet$Json$Response']>[0] = {
      parentEmployeeKey: parentEmployeeKey,
      meetingCategoryType: meetingCategoryType,
      meetingSubjectType: meetingSubjectType,
      fromDate: fromDate ? fromDate.toDateString() : null,
      toDate: toDate ? toDate.toDateString() : null,
    };

    // site
    siteKey.filter((key) => key !== null).length > 0 ? (params['parentSiteKey'] = siteKey) : null;

    // org
    this.globals.roleCode === 'EC' || this.globals.roleCode === 'SM' || this.globals.roleCode === 'OM'
      ? (params['parentOrganizationalUnitKey'] = [this.globals.organizationKey])
      : null;

    return this.schedulingSvc.apiSchedulingMeetingOccurrenceGet$Json$Response({
      ...params,
    });
  }

  getMeetingList(
    organization: number[] = null,
    siteKey: number[] = null,
    parentEmployeeKey: number[] = null,
    meetingCategoryType: string[] = null,
    meetingSubjectType: string[] = null,
    fromDate: Date = null,
    toDate: Date = null
  ): Observable<StrictHttpResponse<MeetingOccurrenceModel[]>> {
    const params: Parameters<SchedulingService['apiSchedulingMeetingOccurrenceGet$Json$Response']>[0] = {
      parentOrganizationalUnitKey: organization,
      parentSiteKey: siteKey,
      parentEmployeeKey: parentEmployeeKey,
      meetingCategoryType: meetingCategoryType,
      meetingSubjectType: meetingSubjectType,
      fromDate: fromDate ? fromDate.toDateString() : null,
      toDate: toDate ? toDate.toDateString() : null,
    };

    // site
    // siteKey.filter((key) => key !== null).length > 0 ? (params['parentSiteKey'] = siteKey) : null;

    // // org
    // this.globals.roleCode === 'EC' || this.globals.roleCode === 'SM' || this.globals.roleCode === 'OM'
    //   ? (params['parentOrganizationalUnitKey'] = [this.globals.organizationKey])
    //   : null;

    return this.schedulingSvc.apiSchedulingMeetingOccurrenceGet$Json$Response({
      ...params,
    });
  }

  getEmployeeMeetingOccurrences(parentEmployeeKey: number, fromDate: Date, toDate: Date) {
    return this.schedulingSvc.apiSchedulingEmployeeParentEmployeeKeyGet$Json$Response({
      parentEmployeeKey: parentEmployeeKey,
      fromDate: fromDate.toDateString(),
      toDate: toDate.toDateString(),
    });
  }

  setNewAppointment({ start, end, isAllDay }) {
    this.newAppointment = { start, end, isAllDay };
  }

  // find available appointments
  public loadSiteAppointments(parentSiteKey: number, fromDate: Date, toDate: Date, subject: MeetingSubjectTypes): void {
    this.schedulingSvc
      .apiSchedulingSiteParentSiteKeyAvailableMeetingsGet$Json({
        parentSiteKey: parentSiteKey,
        fromDate: fromDate.toDateString(),
        toDate: toDate.toDateString(),
        meetingSubjectType: subject,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.meetings = data;

          // ready!
          this.meetingsReady$.next(true);
        },
        (err) => {
          console.error('Error reading meeting occurrences: ' + err);
        }
      );
  }

  // find available appointments (first 3 only)
  public loadLimitedSiteAppointments(
    parentSiteKey: number,
    fromDate: Date,
    toDate: Date,
    subject: MeetingSubjectTypes
  ): void {
    this.schedulingSvc
      .apiSchedulingSiteParentSiteKeyMeetingCountGet$Json({
        parentSiteKey: parentSiteKey,
        fromDate: fromDate.toDateString(),
        toDate: toDate.toDateString(),
        meetingSubjectType: subject,
        meetingCount: 3,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.meetings = data;

          // ready!
          this.meetingsReady$.next(true);
        },
        (err) => {
          console.error('Error reading meeting occurrences: ' + err);
        }
      );
  }

  // find dates with available appointments
  public loadSiteAppointmentDates(parentSiteKey: number, fromDate: Date, toDate: Date, subject: MeetingSubjectTypes): void {
    this.schedulingSvc
      .apiSchedulingSiteParentSiteKeyAvailableDatesGet$Json({
        parentSiteKey: parentSiteKey,
        fromDate: fromDate.toDateString(),
        toDate: toDate.toDateString(),
        meetingSubjectType: subject,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.meetingDates = data;

          // ready!
          this.meetingDatesReady$.next(true);
        },
        (err) => {
          console.error('Error reading available meeting dates: ' + err);
        }
      );
  }

  // lock an appointment
  public lockAppointment(parentMeetingOccurrenceKey: number): void {
    this.lockedMeetingOccurrenceKey = undefined;

    this.schedulingSvc
      .apiSchedulingParentMeetingOccurrenceKeyLockPost$Json({
        parentMeetingOccurrenceKey: parentMeetingOccurrenceKey,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          // locked!
          if (data === ProcessResponse.Success) {
            this.lockedMeetingOccurrenceKey = parentMeetingOccurrenceKey;

            // allow the user to complete the selection process
            this.meetingLocked$.next(true);
          }
          // record not available...(refresh the list of appointments)
          else if (data === ProcessResponse.FailedRecordNotFound) {
            const dialog = LOCKED_APPOINTMENT_DIALOG;
            const ref = this.modalSvc.openDialog({ data: dialog });

            typeof ref === 'string' ? undefined : ref.afterClosed().subscribe(() => this.meetingLocked$.next(true));
          }
        },
        (err) => {
          console.error('Error locking meeting occurrence: ' + err);
        }
      );
  }

  // unlock an appointment
  public unlockAppointment(parentMeetingOccurrenceKey: number): void {
    this.schedulingSvc
      .apiSchedulingParentMeetingOccurrenceKeyUnlockPost$Json({
        parentMeetingOccurrenceKey: parentMeetingOccurrenceKey,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          // unlocked!
          if (data === ProcessResponse.Success) {
            this.lockedMeetingOccurrenceKey = undefined;
          }
        },
        (err) => {
          console.error('Error unlocking meeting occurrence: ' + err);
        }
      );
  }

  // save intake meeting
  public saveIntakeMeeting(parentMeetingOccurrenceKey: number, parentInquiryKey: number): Observable<ProcessResponse> {
    return this.schedulingSvc.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyPost$Json({
      parentMeetingOccurrenceKey,
      parentInquiryKey,
    });
  }

  saveIntakeMeetingUsingExistingClient(
    params: Parameters<
      SchedulingService['apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json']
    >[0]
  ) {
    return this.schedulingSvc.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyExistingClientParentClientKeyPost$Json(
      params
    );
  }

  // TODO: Remove deprecated functions
/*  parseDate = (eventDate: string): Date => {
    return new Date(eventDate);
  };*/

/*  parseDateFormatted = (eventDate: string): string => {
    parseDateTimeFormattedFromString(eventDate);
    // return format: 8:30 AM
    return this.parseDate(eventDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  };*/

  mapMeetings(meetings: MeetingOccurrenceModel[]) {
    const parseDateTime = (date: string, time: number) => {
      const offset = new Date().getTimezoneOffset();

      const onOffset = 300;

      // TODO: what is this line doing?
      const diff = new Date(onOffset * 60000).valueOf() - new Date(offset * 60000).valueOf();

      const comb = new Date();
      // TODO: verify this is parsing the date-time correctly
      comb.setTime(parseDateTimeAsDateFromString(date).getTime() + time);
      return new Date(comb.valueOf() + diff);
    };

    // meetingDateFormatted;
    // startTimeMilliseconds;
    // endTimeMilliseconds;
    return meetings.map(
      (
        dataItem: MeetingOccurrenceModel & {
          employeeDisplayName: string;
          meetingDateFormatted: string;
          startTimeMilliseconds: number;
          endTimeMilliseconds: number;
        }
      ) => {
        const {
          parentMeetingOccurrenceKey: id = 0,
          startISOFormatted = null,
          endISOFormatted = null,
          isAllDay: isAllDay = null,
          calendarDescription: description = null,
          recurrenceRule: recurrenceRule = '',
          parentMeetingKey: recurrenceId,
          meetingCategoryDescription: category,
          clientAttendees = [],
          employeeDisplayName = '',
          subjectDescription = '',
          subjectCode = '',
          meetingDateFormatted = '',
          startTimeMilliseconds = 0,
          endTimeMilliseconds = 0,
        } = dataItem;

        const meeting = {
          id,
          start: new Date(startISOFormatted),
          end: new Date(endISOFormatted),
          startTimezone: null,
          endTimezone: null,
          isAllDay: isAllDay,
          title: this.meetingTitle(dataItem),
          description: description,
          recurrenceRule: recurrenceRule,
          recurrenceId: recurrenceId,
          category,
          attendee: clientAttendees.length > 0 ? clientAttendees[0].displayName : '',
          employee: employeeDisplayName,
          type: subjectDescription,
          subjectCode: subjectCode,
        };
        return meeting;
      }
    );
  }

  private meetingTitle(meetingOccurrence: MeetingOccurrenceModel) {
    return `${parseTimeFormatted(meetingOccurrence.startISOFormatted)} ${meetingOccurrence.meetingCategoryDescription
      } ${meetingOccurrence.subjectDescription}`;
  }

  submitMeeting(meeting: MeetingModel) {
    if (!meeting) console.log('no meeting has been passed');

    return this.schedulingSvc.apiSchedulingPost$Json$Response({
      body: meeting,
    });
  }

  submitMeetingOccurrence(meetingOccurrence: MeetingOccurrenceModel) {
    if (!meetingOccurrence) return console.log('no meetingOccurrence has been passed');

    return this.schedulingSvc.apiSchedulingMeetingOccurrencePost$Json$Response({
      body: meetingOccurrence,
    });
  }

  submitGroupMeetingOccurrence(meetingOccurrence: MeetingOccurrenceModel) {
    if (!meetingOccurrence) return console.log('no meetingOccurrence has been passed');

    return this.schedulingSvc.apiSchedulingGroupMeetingOccurrencePost$Json$Response({
      body: meetingOccurrence,
    });
  }

  submitClientAttendee(meetingOccurrenceKey: number, clientKey: number) {
    return this.schedulingSvc.apiSchedulingParentMeetingKeyClientPost$Json$Response({
      parentMeetingKey: meetingOccurrenceKey,
      parentClientKey: clientKey,
    });
  }

  updateClientAttendee(meetingAttendee: MeetingAttendeeModel) {
    const clientKey = this.globals.clientKey;

    return this.schedulingSvc.apiSchedulingAttendeeClientPost$Json$Response({
      body: meetingAttendee,
    });
  }

  submitGroupClientAttendees(parentMeetingOccurrenceKey: number, meetingAttendees: MeetingAttendeeModel[]) {
    return this.schedulingSvc.apiSchedulingMeetingOccurrencesParentMeetingOccurrenceKeyClientsPost$Json$Response({
      parentMeetingOccurrenceKey: parentMeetingOccurrenceKey,
      body: meetingAttendees,
    });
  }

  updateGroupClientAttendees(
    parentMeetingKey: number,
    removedMeetingAttendeeKeys: number[],
    updatedMeetingAttendees: MeetingAttendeeModel[]
  ): Observable<StrictHttpResponse<string>> {
    const updatedAttendees = updatedMeetingAttendees.filter(
      (a) => a.parentMeetingAttendeeKey !== undefined && a.parentMeetingAttendeeKey
    );
    const newAttendees = updatedMeetingAttendees.filter(
      (a) => a.parentMeetingAttendeeKey === undefined || !a.parentMeetingAttendeeKey
    );

    const removeCall = this.schedulingSvc.apiSchedulingAttendeeDelete$Json$Response({
      body: removedMeetingAttendeeKeys,
    }) as Observable<StrictHttpResponse<string>>;

    const updateAttendeesResult$ = removeCall.pipe(
      switchMap((result) => {
        if (result.status !== 200) {
          return of(result);
        }

        return this.schedulingSvc.apiSchedulingAttendeeClientsPost$Json$Response({
          body: updatedAttendees,
        });
      })
    );

    return updateAttendeesResult$.pipe(
      switchMap((result) => {
        if (result.status !== 200) {
          return of(result);
        }

        return this.submitGroupClientAttendees(parentMeetingKey, newAttendees);
      })
    );
  }

  deleteGroupClientAttendees(removedMeetingAttendees: MeetingAttendeeModel[]) {
    const removedMeetingAttendeeKeys = removedMeetingAttendees.map((ma) => ma.parentClientKey);
    return this.schedulingSvc.apiSchedulingAttendeeDelete$Json$Response({
      body: removedMeetingAttendeeKeys,
    }) as Observable<StrictHttpResponse<string>>;
  }

  submitEmployeeAttendee(meetingOccurrenceKey: number, parentEmployeeKey: number) {
    return this.schedulingSvc.apiSchedulingParentMeetingKeyEmployeePost$Json$Response({
      parentMeetingKey: meetingOccurrenceKey,
      parentEmployeeKey: parentEmployeeKey,
    });
  }

  cleanForm(fg: RxFormGroup): void {
    fg.markAsPristine();
    fg.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public fgErrorList(fg, labels, errors) {
    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;
      //console.log(control)

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (!errors.includes(this.errorMap(control, this.controlLabel(labels[key])))) {
            errors.push(this.errorMap(control, this.controlLabel(labels[key])));
          }
        });
      }
    });
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    if (!fc.errors) return '';
    if (fc.errors.required) return this.translocoService.translateObject("Message.Error.ControlRequired", {ControlName: label});
    if (fc.errors.minLength && fc.errors.minLength.message) return `${label} ${fc.errors.minLength.message}`;

    if (fc.errors.minlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMinimumCharacters", {Minimum: fc.errors.minlength.requiredLength});
    if (fc.errors.maxlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMaximumCharacters", {Maximum: fc.errors.maxlength.requiredLength});

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format ${casefloDateFormat}.`;
    if (fc.errors.minDate) return `End Time cannot be before Start Time`;
  }

  public controlLabel(control: string): string {
    return control;
  }
}
