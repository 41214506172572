import { ListConfig } from '@modules/outcomes/views/all-outcomes/all-outcomes.datasource';

export type CF2TableFieldType = 'date' | 'link' | 'text' | 'stringdate';

export interface Cf2TableField<T extends string> {
  type: CF2TableFieldType;
  key: T;
  sortable?: boolean;
  label?: string;
}

export function tableFieldFactory<T extends string>(
  key: T,
  options: { type?: CF2TableFieldType; sortable?: boolean; label?: string }
): Cf2TableField<T> {
  const { type = 'text', label = '', sortable = true } = options;

  return {
    key,
    type,
    label,
    sortable,
  };
}
export class Cf2TableConfig<T extends string> extends Map<T, Cf2TableField<T>> {
  constructor() {
    super();
  }
}
