import { Component, Input } from '@angular/core';
import { ModalService, ERROR_DIALOG } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'cf2-button-error',
  template: `
    <div class="error" [class.hidden]="visible === false">
      <button mat-icon-button aria-label="Show error list">
        <mat-icon (click)="buttonClick()">error</mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./button-error.component.scss'],
})
export class ButtonErrorComponent {
  @Input() visible = false;
  @Input() errors: string[];

  constructor(private modalSvc: ModalService) {}

  public buttonClick(): void {
    if (this.errors !== undefined && this.errors.length > 0) {
      let error = '<ul>';

      this.errors.forEach((value) => {
        error += '<li>' + value + '</li>';
      });

      error += '</ul>';

      // display dialog
      const dialog = ERROR_DIALOG;
      dialog.title = '';
      dialog.message = error;
      const ref = this.modalSvc.openDialog({ data: dialog });

      typeof ref === 'string' ? undefined : ref.afterClosed();
    }
  }
}
