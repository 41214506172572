import { SelectionChange } from '@angular/cdk/collections';
import { Component, Input, ViewEncapsulation, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';


@Component({
  selector: 'cf2-select-field-fixed',
  template: `
    <div #container class="input-field-fixed">
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label>
          {{ label }}
          <span *ngIf="required" class="required-asterisk">*</span>
        </mat-label>

        <mat-select
          class="select-field-infix"
          [multiple]="multi"
          matNativeControl
          autocomplete="off"
          [formControl]="ctrl"
          [disableControl]="disabled"
          [disableOptionCentering]="true"
          [class.blue-background]="backgroundColor === 'blue'"
          [class.grey-background]="backgroundColor === 'grey'"
          #selection="matSelect"
          (selectionChange)="onChange.emit($event)"
        >
          <mat-option (click)="selectAll(allOption)" #allOption *ngIf="multi && options?.length > 1"
            >{{ 'SelectAll' | transloco }}</mat-option
          >

          <ng-container *ngFor="let type of options">
            <mat-option [value]="type.value" [disabled]="type?.disabled">
              <mat-icon matListIcon *ngIf="type.icon">{{ type.icon }}</mat-icon>
              {{ type.description }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error> {{ ctrl['errorMessage'] ? ctrl['errorMessage'] : '' }}</mat-error>
        <mat-error *ngIf="ctrl.errors?.requiredFilter">{{ ctrl.errors?.message }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./input-field-fixed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'selectField',
})
export class SelectFieldFixedComponent {
  @ViewChild('select') input: MatSelect;
  @ViewChild('container') container: ElementRef;
  @Input() options: { value: string; description: string; icon?: string; disabled?: boolean }[];
  @Input() ctrl = new UntypedFormControl();
  @Input() multi = false;
  @Input() required = new UntypedFormControl();

  @Input() label: string;
  @Input() value = '';
  @Input() disabled = false;
  @Input() icon: string;
  @Input() backgroundColor: 'blue' | 'grey' | 'none' = 'none';
  @Output() onChange = new EventEmitter<SelectionChange<{ value: string; description: string }>>();
  allSelected = false;

  selectAll(allOption: MatOption) {
    const selected = allOption.selected;
    this.ctrl.patchValue(selected ? this.options.map((val) => val.value) : []);
    selected ? allOption.select() : allOption.deselect();

    this.ctrl.updateValueAndValidity({ emitEvent: true, onlySelf: false });
  }
}
