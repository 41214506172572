import { DocumentDto } from '@api/models/document-dto';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to document stores keys type. Two values added for display isLocked & icon */
type DocumentStoreKeysType = keyof DocumentDto | 'select' | 'isLocked' | 'icon' | 'deleteIcon';

/* tuple setup for document config */
type DocumentListHeaderTupleType = [DocumentStoreKeysType, string];

/* actual display configuration */
const DOCUMENT_LIST_HEADERS: readonly DocumentListHeaderTupleType[] = [
  ['select', ''],
  ['documentDateFormatted', 'DocumentDate'],
  ['documentTypeDescription', 'DocumentType'],
  ['documentSectionDescription', 'Category'],
  ['comment', 'Description'],
  ['displayName', 'UploadedBy'],
  ['icon', ''],
  ['isLocked', ''],
  ['deleteIcon', '']
];

/* list of columns that can be linked */
const DOCUMENT_LIST_LINK_COLUMNS = ['documentTypeDescription'];

/* list of columns that can be sorted */
const DOCUMENT_LIST_SORT_COLUMNS = [
  'documentDateFormatted',
  'documentTypeDescription',
  'documentSectionDescription',
  'displayName',
];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class DocumentsListData extends DataTableSetup {
  /* set the list headers */
  private documentsListHeaders = [...DOCUMENT_LIST_HEADERS];
  private documentsListLinkColumns = [...DOCUMENT_LIST_LINK_COLUMNS];
  private documentsListSortColumns = [...DOCUMENT_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private documentListSetup() {
    const setup = DocumentsListData.linkableColumns(
      DocumentsListData.hiddenColumns(
        DocumentsListData.sortColumns(
          DocumentsListData.initColumns(this.documentsListHeaders.map((item) => this.translocoService.translate(item))),
          this.documentsListSortColumns
        ),
        []
      ),
      this.documentsListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.documentListSetup();
  }
}
