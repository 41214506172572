const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDay = new Date().getDate();

export function dateBuilder() {
  const dateTuple = [currentYear, currentMonth, currentDay];
  return dateTuple;
}
export function setDate(y = 0, m = 0, d = 0) {
  const today = dateBuilder();
  const date = new Date(today[0] + y, today[1] + m, today[2] + d);
  return () => date;
}

export const maxDateFn = (current: number, month, day) => {
  const year = current;
  return () => new Date(year, month, day);
};

const minDateFn = (current: number, month, day) => {
  const year = current;
  return () => new Date(year, month, day);
};

export const minDate = minDateFn(currentYear, currentMonth, currentDay);
export const maxDate = maxDateFn(currentYear, currentMonth, currentDay);

export const maxDatePlus = maxDateFn(currentYear + 1, currentMonth, currentDay);

export const maxExpiryDate = maxDateFn(currentYear + 10, currentMonth, currentDay);
