import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { dateBuilder } from '@core/models/date.model';
import { ExternalContactsDataService } from '../services/external-contacts-data.service';

import { ExternalContactsLabelsType } from '../models/external-contacts-form.model';
import { TranslocoService } from '@ngneat/transloco';
export interface ExternalContactsFormConfig {}

@Component({
  selector: 'cf2-external-contacts-form',
  template: `
    <div class="flex-row flex-wrap">
      <cf2-input-field-top
        [ctrl]="getFc('firstName')"
        [label]="labels.firstName"
        [required]="required.firstName"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-input-field-top
        [ctrl]="getFc('lastName')"
        [label]="labels.lastName"
        [required]="required.lastName"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>
    </div>

    <div class="flex-row flex-wrap">
      <cf2-input-field-top
        [ctrl]="getFc('title')"
        [label]="labels.title"
        [required]="required.title"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-input-field-top
        [ctrl]="getFc('organizationName')"
        [label]="labels.organizationName"
        [required]="required.organizationName"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>
    </div>

    <div class="flex-row flex-wrap">
      <cf2-input-field-top
        [ctrl]="getFc('email')"
        [label]="labels.email"
        [required]="required.email"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-phone-field-top
        [ctrl]="getFc('phone')"
        [label]="labels.phone"
        [required]="required.phone"
        [disabled]="isReadOnly"
      >
      </cf2-phone-field-top>
    </div>
  `,
})
export class ExternalContactsFormComponent extends BaseComponent implements OnInit {
  isInit = false;
  get isReadOnly() {
    return this.dataSvc.getIsReadOnly();
  }
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: ExternalContactsLabelsType;
    disabled: ExternalContactsLabelsType;
    required: ExternalContactsLabelsType;
  };

  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;
  getFc: (formKey: string) => AbstractControl;
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);

  constructor(private dataSvc: ExternalContactsDataService, private ts : TranslocoService) {
    super();
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-external-contacts-form>, you have to initalize external contacts form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc(fg);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
