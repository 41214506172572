import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowActions, WorkflowActionsType } from '@core/models/workflow-actions.model';
import { WorkflowActionsService } from '@core/services/workflow-actions.service';

@Component({
  selector: 'cf2-workflow-actions',
  template: `
    <div class="flex-row flex-end p-0">
      <cf2-button (click)="wfActSelected.emit('recommend')" *ngIf="wfActs?.recommend">Recommend</cf2-button>
      <cf2-button (click)="wfActSelected.emit('approve')" *ngIf="wfActs?.approve">Approve</cf2-button>
      <cf2-button (click)="wfActSelected.emit('return')" *ngIf="wfActs?.return">Return</cf2-button>
      <cf2-button (click)="wfActSelected.emit('cancel')" *ngIf="wfActs?.cancel">Cancel</cf2-button>
      <cf2-button (click)="wfActSelected.emit('finalize')" *ngIf="wfActs?.finalize">Finalize</cf2-button>
    </div>
  `,
  styleUrls: ['./workflow-actions.component.scss'],
})
export class WorkflowActionsComponent implements OnInit {
  /* Workflow Actions Class */
  @Input() wfActs: WorkflowActions;

  @Output() wfActSelected = new EventEmitter<WorkflowActionsType>();

  constructor(public wfActSvc: WorkflowActionsService) {}

  ngOnInit(): void {
    if (!this.wfActs) {
      throw new Error('no workflow actions added to the WorkflowActions component');
    }
  }
}
