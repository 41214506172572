import { ExpenditureItemModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { FormsFns } from '@core/models/forms-fns.model';
import { RolesType, ExpenditureStatusType } from '@core/models/workflow-actions.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TranslocoService } from '@ngneat/transloco';
import * as R from 'remeda';
import { ExpenditureStateType } from './expenditure-state.model';
/**
 * @summary - Expenditure Item definition
 */

export type ExpenditureItemKeysType = keyof ExpenditureItemModel | 'piCode';

export type ExpenditureItemLabelsType = Record<ExpenditureItemKeysType, boolean | string>;

export interface ExpenditureItemFormField extends FormsComponentField {
  key: ExpenditureItemKeysType;
}

export const EXPENDITURE_ITEM_FORM_CONFIG: readonly ExpenditureItemFormField[] = [
  {
    key: 'assistanceTypeCode',
    /*
            The Expenditure Type (Client or Employer) determines the list of Assistance Types displayed in the list box. The ExpenditureTypeCode in the AssistanceType table is used to filter the values displayed in the list box.
            */
    label: 'AssistanceTypeClientOrEmployer',
    type: 'select',
  },
  /* TODO: calculated assistance code details */
  {
    key: 'expenditureItemDescription',
    label: 'ItemDescription',
    type: 'string',
  },
  {
    key: 'amount',
    label: 'TotalAmount',
    type: 'currency',
  },
  {
    key: 'hstGstAmount',
    label: 'HSTAmount',
    type: 'currency',
  },
  {
    key: 'piCode',
    label: 'PlanItem',
    type: 'select',
    disabled: true,
    required: false,
  },
  {
    key: 'recoverableIndicator',
    label: 'Recoverable?',
    type: 'checkbox',
    disabled: true,
  },
  {
    key: 'bulkPurchaseDescription',
    label: 'BulkReference',
    type: 'string',
    required: false,
  },
  {
    key: 'parentExpenditureItemKey',
    label: 'DontShowInUI',
    type: 'display',
    required: false,
  },
];

const editConfig = () =>
  function (role: RolesType = 'EC') {
    const isEc = role === 'EC';
    const enabledFields: ExpenditureItemKeysType[] = ['hstGstAmount', 'amount', 'recoverableIndicator'];
    const config = EXPENDITURE_ITEM_FORM_CONFIG;

    return isEc
      ? FormsFns.disableAll([...config])
      : config.map((field) => ({
          ...field,
          disabled: !enabledFields.some((key) => key === field.key),
        }));
  };

const mappedEditConfig = editConfig();
export class ExpenditureItemForm extends Cf2Forms {
  private _fields: any;
  get fields() {
    return this._fields;
  }
  constructor(
    fb: RxFormBuilder, ts : TranslocoService,
    opts: {
      value?: ExpenditureItemModel;
      state?: ExpenditureStateType;
      role?: RolesType;
      status?: ExpenditureStatusType;
    } = {}
  ) {
    super(fb, ts);
    const { value = null, role = 'EC', status = 'INPR', state = 'create' } = opts;

    const isCreate = state === 'create';
    const isInpr = status === 'INPR';
    // const config = isCreate || isInpr ? EXPENDITURE_ITEM_FORM_CONFIG : mappedEditConfig(role);
    const config = EXPENDITURE_ITEM_FORM_CONFIG;

    this._fields = value
      ? this.makeFields(config.map((fields) => ({ ...fields, value: value[fields.key] })))
      : this.makeFields([...config]);
  }
}
