import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'cf2-address-group',
  template: `
    <mat-toolbar *ngIf="addressFa.length > 1">
      <div class="toolbar-fields">
        <span class="title-span">
          <b>Additional Addresses</b>
        </span>
      </div>
    </mat-toolbar>
    <ng-container *ngFor="let fg of addressFa; index as i; first as isFirst">
      <div class="flex-col" *ngIf="!isFirst">
        <div class="flex-row make-primary">
          <mat-checkbox (change)="emitSelection(i, fg)">Make Primary</mat-checkbox>
        </div>

        <cf2-address-form-card *ngIf="!isFirst" [labels]="labels" [fg]="fg"></cf2-address-form-card>
      </div>
    </ng-container>
  `,
  styleUrls: ['./address-group.component.scss'],
})
export class AddressGroupComponent implements OnInit {
  @Output() makeAddressPrimary = new EventEmitter<{ i: number; fg: UntypedFormGroup }>();
  @Input() addressFa: any;
  @Input() labels: any;

  options: any;

  emitSelection(i, fg) {
    this.makeAddressPrimary.emit({ i, fg });
  }
  ngOnInit() {}
  constructor(private dataSvc: DataService) {}
}
