<div class="grid-2">
  <cf2-select-field-fixed
    [multi]="true"
    [ctrl]="fg.controls.organization"
    [label]="'Organizations' | transloco"
    [options]="orgOpts"
    [required]="getFieldRule('organization', 'required')"
    #orgsSelect="selectField"
  ></cf2-select-field-fixed>

  <cf2-select-field-fixed
    [multi]="true"
    [ctrl]="fg.controls.site"
    [label]="'Sites' | transloco"
    [required]="getFieldRule('sites', 'required')"
    [options]="siteOpts$ | async"
    #sitesSelect="selectField"
  ></cf2-select-field-fixed>
  </div>
  <div>
    <cf2-autocomplete-select-field-fixed
      [multi]="true"
      #empSelect="autcompleteSelectField"
      [ctrl]="fg.controls.employee"
      [label]="'Employees' | transloco"
      [required]="getFieldRule('employees', 'required')"
      [options]="empOpts$ | async"
      [removable]="employeeRemovable"
      [compatibleWithInputFieldTop]="false"
    ></cf2-autocomplete-select-field-fixed>
  <div>
    <ng-content></ng-content>
  </div>
</div>