import { Injectable } from '@angular/core';
import { ClientIdentifierModel } from '@api/models';
import { SelectOptions } from '@core/models/select-options.model';
import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { IdTypesForm, ClientIdentifierLabelsType } from '@shared/components/id-types/models/id-types-form.model';
import * as R from 'remeda';
import { map, tap } from 'rxjs/operators';
import { MultipleEntriesData } from '@shared/components/multiple-entries/multiple-entries.model';
import { MultipleEntriesService } from '@shared/components/multiple-entries/multiple-entries-data.service';
import { Globals } from 'src/app/globals';
import { TranslocoService } from '@ngneat/transloco';

interface EntityData extends MultipleEntriesData<ClientIdentifierModel> {}

@Injectable({ providedIn: 'root' })
export class IdTypesDataService extends MultipleEntriesService<
  ClientIdentifierModel,
  ClientIdentifierLabelsType,
  EntityData
> {
  clientIdentifierTypeList: SelectOptions[];
  getIsReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  constructor(private fb: RxFormBuilder, private dataSvc: DataService, private globals: Globals, private translocoService: TranslocoService) {
    super();
  }

  get clientIdentifierTypes$() {
    return this.dataSvc.lookupRecords('IdType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => (this.clientIdentifierTypeList = result))
    );
  }

  initForm(value?: Partial<ClientIdentifierModel>) {
    this.formFields = value ? new IdTypesForm(this.fb, this.translocoService,  { value: value }) : new IdTypesForm(this.fb, this.translocoService);
    this.formFields.fields.fg.patchValue(value);
    this.formGroup = this.formFields.fields.fg;

    return this.formFields;
  }

  getEntityDataByKey(key: number) {
    return this.entityData.filter((entityData) => entityData.entity.key === key)[0];
  }

  addEntityData(entityData: EntityData) {
    // create new casenote
    entityData.action = 'CREATE';
    const newEntry = this.constructEntityData(entityData);
    this.entityData.push(newEntry);

    return newEntry;
  }

  constructEntityData(data: EntityData) {
    const entityData = data;
    entityData.entity.key = entityData.tempKey;

    // check if list values already exists
    if (this.clientIdentifierTypeList.length > 0) {
      this.clientIdentifierTypeList.forEach((listItem) => {
        if (listItem.value === entityData.entity.code) {
          entityData.entity.identifierTypeDescription = listItem.description;
        }
      });
    }

    return entityData;
  }

  getEntityData(config: { sanitizeParentKey: boolean } = { sanitizeParentKey: false }) {
    const filtered = R.reject(this.entityData, (entity) => {
      return !!entity.action && entity.action === 'DELETE';
    });
    return filtered.map((obj) => {
      if (config.sanitizeParentKey)
        return {
          ...obj.entity,
          key:
            // send null for newly created entities
            obj.entity.key >= 1 ? obj.entity.key : null,
        } as ClientIdentifierModel;
      else return obj.entity;
    });
  }

  updateEntityData(entityData: EntityData) {
    this.entityData = this.entityData.map((data : any) => {
      if (data.key === entityData.tempKey) {

        // construct updated caseNoteData
        data.entity = {
          ...data.entity,
          ...this.constructEntityData(entityData).entity,
        };


        // Update exising case notes
        if (data.tempKey >= 1) {
          data.action = 'UPDATE';
        }
      }

      return data;
    });
  }

  mapEntityData(entities: ClientIdentifierModel[]): EntityData[] {
    const entityData = entities.map((data) => {
      return {
        key: data.key,
        entity: data,
      };
    });

    return entityData;
  }
}
