import { ClientModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

export interface ClientListConfig {
  showSelection?: boolean;
}

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type ClientModelKeysType = keyof ClientModel | 'programStatus' | 'select';

/* tuple setup for casenote config */
type ClientModelHeaderTupleType = [ClientModelKeysType, string];

/* actual display configuration */
const CLIENT_LIST_HEADERS: readonly ClientModelHeaderTupleType[] = [
  ['select', 'Select'],
  ['clientBusinessKey2', 'CaseFLOId'],
  ['firstName', 'FirstName'],
  ['lastName', 'LastName'],
  ['email', 'Email'],
  ['birthDateFormatted', 'DateofBirth'],
  ['clientBusinessKey', 'SIN'],
  ['phoneNumber', 'Phone#'],
  ['address', 'Address'],
  ['city', 'City'],
  ['programStatus', 'Program/Status'],
  ['siteName', 'Site'],
];

/* list of columns that can be linked */
const CLIENT_LIST_LINK_COLUMNS = [];

/* list of columns that can be sorted */
const CLIENT_LIST_SORT_COLUMNS = [];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class ClientModelListData extends DataTableSetup {
  /* set the list headers */
  private ClientModelListHeaders = [...CLIENT_LIST_HEADERS];
  private ClientModelListLinkColumns = [...CLIENT_LIST_LINK_COLUMNS];
  private ClientModelListSortColumns = [...CLIENT_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = ClientModelListData.linkableColumns(
      ClientModelListData.hiddenColumns(
        ClientModelListData.sortColumns(
          ClientModelListData.initColumns(this.ClientModelListHeaders.map((item) => this.translocoService.translate(item))),
          this.ClientModelListSortColumns
        ),
        []
      ),
      this.ClientModelListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
