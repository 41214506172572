import { Inject, Injectable } from '@angular/core';
import { CaseHeaderModel, CaseModel, UserModel } from '@api/models';
import { OPEN_CASE_STATUS_CODES } from '@core/constants/cf2-case.constants';

import { Cf2Store } from '@core/models/store.model';

import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { Cf2RoleType } from '@core/types/cf2-role.type';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

import { LoggingService } from './logging.service';
import { filter, pluck } from 'rxjs/operators'; 
import { ADMIN_MODULES, MODULE_CONFIG, AUDIT_MODULE_CONFIG, NavListModule, SC_MODULE_CONFIG, NavListItem } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class UserStateService extends Cf2Store {
  // user: UserModel;

  modules$ = new BehaviorSubject<NavListModule[]>(null);

  private _user: UserModel;
  set user(user: UserModel) {
    this._user = user;
  }
  get user() {
    return this._user;
  }
  roleMap: Map<'admin' | 'user', Cf2RoleType>;
  role: string | boolean;
  md: NavListModule[];
  roleCode: Cf2RoleType;
  constructor(@Inject(CF2_CONFIG) config: Cf2ConfigType, private logSvc: LoggingService, private translocoService : TranslocoService) {
    super();
    const roleMap = new Map();
    roleMap.set('user', config.roles.user);
    roleMap.set('admin', config.roles.admin);
    // this.ready$.subscribe((obs) => console.log('user state service ready status ', obs));
    this.roleMap = roleMap;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  init(user: UserModel, translocoService : TranslocoService) {
    if (!user) {
      this.logSvc.logError({ lvl: 'ERROR', mssg: `${this.constructor.name}.init() has no user passed` });
      throw new Error('no user model passed to user state service');
    }
    this.user = user;

    this.roleCode = user.roleCode as Cf2RoleType;
    this.role = this.appRole(user.roleCode as Cf2RoleType);
      if (this.role === 'admin' || ['SCM', 'SCO'].includes(this.roleCode)) {
        this.md = ADMIN_MODULES.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule ));
      }
      else 
      {
        if (['AUD'].includes(this.roleCode)) {
          this.md = AUDIT_MODULE_CONFIG.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule ));
        }
        else 
        {
          this.md = MODULE_CONFIG.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule ));
        }
      }

      // let md = (this.role === 'admin' || ['SCM', 'SCO'].includes(this.roleCode) 
      //   ? ADMIN_MODULES.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule )) 
      //   : (['AUD'].includes(this.roleCode) 
      //     ? AUDIT_MODULE_CONFIG.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule )) 
      //     : MODULE_CONFIG.map((item) => ({ ...item, label : this.translocoService.translate(item.label) } as NavListModule )) 
      //   ));
      
      // hard coded for now to limit users that can access this feature
      this.md.forEach((item) => {
        if (item.key === 'admin') {
          if (!['elijah.kayguan@wcgservices.com', 
            'chris.blasko@wcgservices.com', 
            'robert.trigo@wcgservices.com',
            'edmund.ayiku@wcgservices.com',
            'shams.ahsanullah@wcgservices.com',
            'segun.oyafajo@wcgservices.com',
            'adam.goralczyk@wcgservices.com',
            'norman.herrera@wcgservices.com',
            'michael.stiver-balla@wcgservices.com'].includes(this.user.email)) {
            item.children.filter(i => i.key !== 'integration');
          }
        }
        item.children = item.children?.map((subitem) => 
        (
          { ...subitem, label : this.translocoService.translate(subitem.label) } as NavListItem )
        )
      });
    this.modules$.next(this.md);
  }

  appRole(roleCode: Cf2RoleType) {
    const admin = this.roleMap.get('admin');

    return admin.includes(roleCode) ? 'admin' : 'user';
  }

  getProp(key: keyof UserModel) {
    if (!this._user) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: `${this.constructor.name}.getProp() has no user set to retrieve from`,
      });
    }
    if (key == 'roleCode') return 'SCM';
    return this._user ? this._user[key] : null;
  }

  editAllCaseRoles = ['SCO', 'SCM', 'HD'];
  editElevatedRoles = ['SSC', 'OM', 'RM'];
  editOrgSiteRoles = ['EC', 'SM'];

  isSuperUser() {
    return this.editAllCaseRoles.includes(this.roleCode);
  }

  isElevatedUser() {
    return this.editElevatedRoles.includes(this.roleCode);
  }

  isOrgAndSiteRestricted() {
    return this.editOrgSiteRoles.includes(this.roleCode);
  }

  canEditCase(clientCase: CaseModel | CaseHeaderModel) {
    if (!clientCase) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.canEditCase() has no case set in value` });
      return false;
    }
    if (!this._user) {
      this.logSvc.logError({ lvl: 'WARN', mssg: `${this.constructor.name}.canEditCase() has no user set in service` });
      return false;
    }

    const role = this.getProp('roleCode') as string;
    if (this.editAllCaseRoles.includes(role)) return true;

    const caseOrgKey = Object.getOwnPropertyNames(clientCase).includes('parentOrganizationalUnitKey')
      ? clientCase['parentOrganizationalUnitKey']
      : clientCase['parentOrganizationKey'];
    this.setItem({ key: 'caseOrgKey', value: caseOrgKey });

    // this.globals.caseOrg
    //const loggedInUserOrgKey = this._user.roleCode === 'OM' ? this.getProp('parentOrganizationalUnitKey'): this.getProp('reportsToOrganizationalUnitKey');
    //const isSameOrg = caseOrgKey === loggedInUserOrgKey;
    const parentOrg = this.getProp('parentOrganizationalUnitKey');
    const reportsToOrg = this.getProp('reportsToOrganizationalUnitKey');

    const isSameOrg = caseOrgKey === parentOrg || caseOrgKey === reportsToOrg;

    if (!isSameOrg) return false;

    return true;
  }

  checkCaseOrgRolePermissions(caseData: CaseModel) {
    // Case
    const isCaseOpen = OPEN_CASE_STATUS_CODES.includes(caseData?.caseStatusCode);
    const caseOrg = caseData?.parentOrganizationalUnitKey;

    // Is Same Org
    const loggedInUserOrgKey =
      this._user.roleCode === 'OM'
        ? this.getProp('parentOrganizationalUnitKey')
        : this.getProp('reportsToOrganizationalUnitKey');

    const isSameOrg = loggedInUserOrgKey === caseOrg;

    // Role
    const role = this.roleCode;
    const isSuperUser = ['SCO', 'SCM'].includes(role);

    const canPerformActionOnSameOrgCase = ['EC', 'SM', 'OM'].includes(role) && isCaseOpen && isSameOrg;

    return isSuperUser || canPerformActionOnSameOrgCase;
  }

  clearStore() {
    // this.clear();
  }
}
