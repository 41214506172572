import { ExpenditureItemKeysType } from './expenditure-item-form.model';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { ListConfig } from '@shared/components/documents/documents-list/documents-datasource';
import { TranslocoService } from '@ngneat/transloco';

type FinalizeExpendituresListHeaderTupleType = [ExpenditureItemKeysType, string | number | boolean];

const FIN_LIST_HEADERS: readonly FinalizeExpendituresListHeaderTupleType[] = [
  ['assistanceTypeDescription', 'AssistanceType'],
  ['expenditureItemDescription', 'ItemDescription'],
  ['amount', 'Amount'],
  ['hstGstAmount', 'HSTAmount'],
  ['recoverableIndicator', 'Recoverable'],
];

export class FinalizeExpenditureListData extends DataTableSetup {
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  get columnTuples() {
    return this.columnConfig;
  }

  get columns() {
    return this.columnConfig.map((col) => col[0]);
  }
  private columnConfig = FIN_LIST_HEADERS;
  private get finalizeExpenditureListSetup() {
    const exp = FinalizeExpenditureListData;
    const setup = exp.linkableColumns(exp.initColumns(this.columnConfig), []);
    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.finalizeExpenditureListSetup;
  }
}
