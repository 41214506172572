import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationModel, PositionModel, SiteModel } from '@api/models';
import { UserStateService } from '@core/services/user-state.service';
import { OptionsDataService } from '@modules/cf2-cache/services/options-data.service';
import { IFormGroup, RxFormBuilder, ResetFormType, prop, required, minLength } from '@rxweb/reactive-form-validators';
import { SelectOptionType } from '@shared/models/select-options.model';
import { map } from 'rxjs/operators';
import { SelectFieldFixedComponent } from '../input-field-fixed/select-field-fixed.component';
import { AutoCompleteSelectFieldFixedComponent } from '../input-field-fixed/autocomplete-select-field-fixed.component';
import * as R from 'remeda';

export interface SitesOptions extends SiteModel {
  description: string;
  value: number;
}
export interface OrgsOptions extends SelectOptionType {
  sites: SitesOptions[];
}
export function resetFormType() {
  return { resetType: ResetFormType.All };
}
export class OrgSitesForm {
  @prop()
  organization: number[] = [];
  @prop()
  site: number[] = [];
  @prop()
  employee: number[] = [];
}

@Component({
  selector: 'cf2-orgs-sites-employees-filter',
  template: `
<!-- <ng-container *transloco="let t"> -->
    <div class="grid-2">
      <cf2-select-field-fixed
        [multi]="isOrgMultiple"
        [ctrl]="fg.controls.organization"
        [label]="'Organizations' | transloco"
        [options]="orgOpts"
        [required]="false"
        #orgsSelect="selectField"
      ></cf2-select-field-fixed>
      <cf2-select-field-fixed
        [multi]="isSiteMultiple"
        [ctrl]="fg.controls.site"
        [label]="'Sites' | transloco"
        [required]="false"
        [options]="siteOpts$ | async"
        #sitesSelect="selectField"
      ></cf2-select-field-fixed>
    </div>
    <div class="grid-1">
      <cf2-autocomplete-select-field-fixed
          [multi]="isEmployeeMultiple"
          #empSelect="autcompleteSelectField"
          [ctrl]="fg.controls.employee"
          [label]="'Employees' | transloco"
          [required]="false"
          [options]="empOpts$ | async"
          [removable]="true"
          [compatibleWithInputFieldTop]="false"
          [ngClass]="hasEmployeeFilter ? '' : 'emp-hidden'"
      ></cf2-autocomplete-select-field-fixed>
    </div>
    <div>
      <ng-content></ng-content>
    </div>

  `,
  exportAs: 'orgComponent',
  styleUrls: ['./orgs-sites-employees.component.scss'],
})
export class OrgsSitesEmployeesFilterComponent implements OnInit {
  @ViewChild('sitesSelect') private sitesSelect: SelectFieldFixedComponent;
  @ViewChild('empSelect') private empSelect: AutoCompleteSelectFieldFixedComponent;
  @Input() horizontal = true;
  @Input() fg: IFormGroup<OrgSitesForm> = this.formBuilder.formGroup(new OrgSitesForm()) as IFormGroup<OrgSitesForm>;
  @Input() hasEmployeeFilter: false;
  @Input() savedOrgs: any;
  @Input() savedSites: any;
  @Input() savedEmployees: any;
  @Input() isOrgMultiple = true;
  @Input() isSiteMultiple = true;
  @Input() isEmployeeMultiple = true;
  @Input() hasUnassigned = false;
  employeeRemovable = false;
  orgOpts: OrgsOptions[] = [];
  siteOpts: any[] = [];
  siteOpts$;
  empOpts$;
  empOpts: { value: number; description: string }[] = [];
  role: string;
  parentOrganizationKey;
  parentSiteKey;
  parentEmployeeKey;

  @Input() set orgsValue(orgs: OrganizationModel[]) {
    if (!orgs) return;
    if (!this.sitesSelect || !this.empSelect) {
      setTimeout(() => (this.orgsValue = orgs));
      return;
    }

    const orgOpts = orgs.map((org) => ({
      value: org.parentOrganizationalUnitKey,
      description: org.organizationName,
      disabled: !this.isOrgMultiple && ['EC', 'SM', 'OM'].includes(this.role) ? true : false,
      sites: org.sites.map(
        (site) =>
          ({
            ...site,
            description: `${org.organizationName} - ${site.siteName}`,
            value: site.parentSiteKey,
            orgKey: org.parentOrganizationalUnitKey,
          } as SitesOptions)
      ),
    }));

    this.orgOpts = orgOpts;
    this.siteOpts = orgOpts.reduce((prev, curr) => prev.concat(...curr.sites), []);

    //Sets the default org for EC, SM, OM and sets the saved org filter from the user
    if (this.isOrgMultiple) {
      this.fg.controls.organization.setValue(
        ['EC', 'SM', 'OM'].includes(this.role) ? [this.parentOrganizationKey] : this.savedOrgs === undefined ? [] : this.savedOrgs
      );
    } else {
      if (['EC', 'SM', 'OM'].includes(this.role)) {
        this.fg.controls.organization.setValue(this.parentOrganizationKey);
      } else {
        this.fg.controls.organization.setValue(this.savedOrgs === undefined ? [] : this.savedOrgs);
      }
    }
    this.fg.controls.organization.updateValueAndValidity();

    //Sets the default site for EC, SM, OM and sets the saved site filter from the user
    if (this.isSiteMultiple) {
      this.fg.controls.site.patchValue(
        ['EC', 'SM'].includes(this.role)
          ? [this.parentSiteKey]
          : this.role === 'OM'
            ? this.siteOpts.filter((site) => site.orgKey === this.parentOrganizationKey).map((site) => site.value)
            : this.savedSites === undefined
              ? []
              : this.savedSites
      );
    } else {
      if (['EC', 'SM'].includes(this.role)) {
        this.fg.controls.site.setValue(this.parentSiteKey);
        // } else if (isOm) {
        //   this.fg.controls.site.setValue(this.siteOpts.filter((site) => site.orgKey === orgKey).map((site) => site.value));
        // } else {
      } else {
        this.fg.controls.site.setValue(this.savedSites === undefined ? [] : this.savedSites);
      }
    }
    this.fg.controls.site.updateValueAndValidity();

    //Sets the default employee for EC, SM, OM and sets the saved employee filter from the user
    if (this.isEmployeeMultiple) {
      this.fg.controls.employee.patchValue(
        this.role === 'EC' ? [this.parentEmployeeKey] : this.savedEmployees === undefined ? [] : this.savedEmployees
      );
    } else {
      this.fg.controls.employee.setValue(
        this.role === 'EC' ? [this.parentEmployeeKey] : this.savedEmployees === undefined ? [] : this.savedEmployees
      );
    }
    this.fg.controls.employee.updateValueAndValidity();
  }
  @Input() set empsValues(emps: PositionModel[]) {
    if (!emps) return;

    const role = this.userStateSvc.getProp('roleCode') as string;

    // if (role === 'EC') {
    //   this.fg.controls.employee.setValidators([
    //     RxwebValidators.required(),
    //     RxwebValidators.minLength({ value: 1 }),
    //     RxwebValidators.greaterThanEqualTo({ value: 1, isArrayControl: true }),
    //   ]);

    //   //2021-10-18 KMW - CFO-6961
    //   //this.fg.controls.employee.disable();
    // }

    this.empOpts =
      emps?.map((emp) => ({
        value: emp.parentEmployeeKey,
        description: emp.displayName,
        disabled: false,
        //2021-10-18 KMW - CFO-6961
        //disabled: role === 'EC' ? true : false,
        siteKey: emp.parentSiteKey,
        orgKey: emp.parentOrganizationalUnitKey,
      })) || [];

    if (this.isEmployeeMultiple) {
      this.fg.controls.employee.patchValue(this.savedEmployees === undefined ? [] : this.savedEmployees);
    }
  }

  constructor(
    private userStateSvc: UserStateService,
    private formBuilder: RxFormBuilder,
    public optionsCacheSvc: OptionsDataService
  ) {}

  ngOnInit(): void {
    this.role = this.userStateSvc.roleCode;
    this.parentOrganizationKey = this.role === 'OM' ? this.userStateSvc.user.parentOrganizationalUnitKey : this.userStateSvc.user.reportsToOrganizationalUnitKey;
    this.parentSiteKey = this.userStateSvc.user.parentSiteKey;
    this.parentEmployeeKey = this.userStateSvc.user.parentEmployeeKey;

    this.siteOpts$ = this.fg.controls.organization.valueChanges.pipe(
      map(() => {
        let filtered;
        if (this.isOrgMultiple) {
          filtered = this.siteOpts.filter((site) => this.fg.controls.organization.value.includes(site.orgKey));
        } else {
          filtered = this.siteOpts.filter(
            (site) => site.orgKey.toString() === this.fg.controls.organization.value.toString()
          );
        }

        if (this.isSiteMultiple) {
          const dropdownListValues = filtered.map((site) => site.value);
          const existingValues = R.reject(
            this.fg.controls.site.value,
            (val: number) => !dropdownListValues.includes(val)
          );

          this.fg.controls.site.setValue(existingValues);
          this.fg.controls.site.updateValueAndValidity();
        } else {
          if (this.savedSites !== undefined) {
            this.fg.controls.site.setValue(this.savedSites);
            this.fg.controls.site.updateValueAndValidity();
          }
        }

        return filtered;
      })
    );
    this.empOpts$ = this.fg.controls.site.valueChanges.pipe(
      map(() => {
        //reset employee selection everytime the org or site changes
        this.fg.controls.employee.setValue([]);

        let filtered;
        if (this.isOrgMultiple) {
          filtered = this.empOpts?.filter((emp: any) => this.fg.controls.organization.value.includes(emp.orgKey));
        } else {
          filtered = this.empOpts?.filter(
            (emp: any) => emp.orgKey.toString() === this.fg.controls.organization.value.toString()
          );
        }

        const orgMngrs = filtered?.filter((emp: any) => emp.siteKey === null);
        const emps = filtered?.filter((emp: any) => this.fg.controls.site?.value?.toString().includes(emp.siteKey));
        filtered = orgMngrs.concat(emps);

        if (this.hasUnassigned) {
          filtered = filtered.concat([{ value: -1, description: 'Unassigned' }]);
          //this.fg.controls.employee.setValue(-1);
          this.fg.controls.employee.updateValueAndValidity();
        }
        // Check if fg.employee.value exists in the dropdonnlist
        // const dropdownListValues = filtered.map((emp) => emp.value);
        // const existingValues = R.reject(
        //   this.fg.controls.employee.value,
        //   (val: number) => !dropdownListValues.includes(val)
        // );
        // this.fg.controls.employee.setValue(existingValues, {
        //   emitEvent: false,
        //   onlySelf: false,
        // });
        // this.fg.controls.employee.updateValueAndValidity({
        //   onlySelf: false,
        //   emitEvent: false,
        // });

        return filtered.sort((a, b) => a.description.localeCompare(b.description));
      })
    );
  }
}
