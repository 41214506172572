import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalService } from 'src/app/shared/services/modal.service';
import { DIALOGS } from 'src/app/core/models/dialogs.model';
import { DOCUMENT } from '@angular/common';
import { PositionModel, ProcessResponse } from '@api/models';
import { SecurityService } from '@api/services';
import { Globals } from 'src/app/globals';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-home-positions',
  templateUrl: './view-positions.component.html',
  styleUrls: ['./view-positions.component.scss'],
})
export class ViewPositionsComponent implements OnInit, OnDestroy {
  public pageTitle = 'UserPositions';
  public dataSource$ = new BehaviorSubject<PositionModel[]>([]);
  public masterData: PositionModel[];
  public submitting = false;
  public ready = false;
  public searchForm: UntypedFormGroup;

  public columnDefs = ['positionName', 'roleDescription', 'organizationName', 'siteName', 'displayName'];

  constructor(
    private securityService: SecurityService,
    private modalSvc: ModalService,
    private globals: Globals,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    // setup search form
    this.searchForm = this.createFg();

    this.securityService.apiSecurityPositionsGet$Json().subscribe((data) => {
      // sort the results
      data = data.sort((a: PositionModel, b: PositionModel) => {
        //return this.compare(a.parentOrganizationalUnitKey, b.parentOrganizationalUnitKey, true);
        return this.compareTwoKeys(
          a.parentOrganizationalUnitKey,
          b.parentOrganizationalUnitKey,
          a.reportsToPositionKey,
          b.reportsToPositionKey,
          true
        );
      });

      this.dataSource$.next(data);
      this.masterData = data;

      this.ready = true;
      this.submitting = false;
    });
  }

  public ngOnDestroy(): void {
    // unsubscribe from data events
    this.dataSource$.unsubscribe;
  }

  private createFg(): UntypedFormGroup {
    const fcBuild = (val: string | Date, ...validators): UntypedFormControl => new UntypedFormControl(val, validators);

    const searchField = fcBuild(null);

    return new UntypedFormGroup({
      searchField,
    });
  }

  // sort table
  public onSortData(sort: Sort): void {
    let data = this.dataSource$.value.slice();

    if (sort.active && sort.direction !== '') {
      data = data.sort((a: PositionModel, b: PositionModel) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'positionName':
            return this.compareText(a.positionName, b.positionName, isAsc);
          case 'roleDescription':
            return this.compareText(a.roleDescription, b.roleDescription, isAsc);
          case 'organizationName':
            return this.compareText(a.organizationName, b.organizationName, isAsc);
          case 'siteName':
            return this.compareText(a.siteName, b.siteName, isAsc);
          case 'displayName':
            return this.compareText(a.displayName, b.displayName, isAsc);
          default:
            return 0;
        }
      });
    }

    this.dataSource$.next(data);
  }

  // sorting functions (arrays)
  public compare(a, b, isAsc): any {
    if (a === null) a = 0;
    if (b === null) b = 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public compareTwoKeys(a, b, c, d, isAsc): any {
    if (a === null) a = 0;
    if (b === null) b = 0;
    if (c === null) c = 0;
    if (d === null) d = 0;
    if (a === b) return (c < d ? -1 : 1) * (isAsc ? 1 : -1);
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public compareText(a, b, isAsc): any {
    if (a === null) a = '';
    if (b === null) b = '';
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }

  // search data
  public keyupEvent(filter: string): void {
    filter = filter.trim();
    filter = filter.toLowerCase();

    if (filter === '') {
      this.dataSource$.next(this.masterData);
      return;
    }

    const temp = this.masterData.filter(function (el) {
      return (
        (el.positionName !== null && el.positionName.toLowerCase().indexOf(filter) >= 0) ||
        (el.roleDescription !== null && el.roleDescription.toLowerCase().indexOf(filter) >= 0) ||
        (el.organizationName !== null && el.organizationName.toLowerCase().indexOf(filter) >= 0) ||
        (el.siteName !== null && el.siteName.toLowerCase().indexOf(filter) >= 0) ||
        (el.displayName !== null && el.displayName.toLowerCase().indexOf(filter) >= 0)
      );
    });

    this.dataSource$.next(temp);
  }

  // position record selected (from list)
  async selected(itm: PositionModel): Promise<void> {
    const parentPositionKey = itm.parentPositionKey;

    const dialog = DIALOGS.defaultDialog;
    dialog.title = '';
    dialog.message = 'Switch to this position?';

    dialog.buttons = {
      cancel: {
        text: 'Cancel',
      },
      confirm: {
        text: 'Switch',
      },
    };

    const ref = this.modalSvc.openDialog({ data: dialog });

    typeof ref === 'string'
      ? undefined
      : ref.afterClosed().subscribe((obs) => (obs ? this.switchPosition(parentPositionKey) : 'cancelled'));
  }

  public switchPosition(parentPositionKey: number): void {
    this.submitting = true;

    this.securityService
      .apiSecurityPositionsSwitchPost$Json({
        body: parentPositionKey,
      })
      .subscribe((response) => {
        if (response === ProcessResponse.Success) {
          // clear the users session and refresh the app
          this.globals.clearSession();
          this.document.location.href = '/positions';
        }

        this.submitting = false;
      });
  }
}
