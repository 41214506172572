import * as R from 'remeda';
import { DataTableColumn } from './data-table.model';
import { PaginatorConfig, paginatorConfigFn } from 'src/app/core/models/paginator.model';

export function titleCaseString(str: string) {
  const mapString = (str, fn) =>
    str
      .split('')
      .map((c, i) => fn(c, i, str))
      .join('');

  const regExp = new RegExp(/[A-Z]/);
  return mapString(str, (c) => (regExp.test(c) ? ' ' + c : c));
}
/**
 * collection of functions for creating a datatable config object.
 */
export class DataTableSetup {
  /**
   * initialize with the values you're including in your table.
   * @param columns - a tuple array of columns ([0]: key, [1]: optional label) to display on the data table
   */
  static initColumns(columns: any) {
    return R.map([...columns], (key) => ({
      key: key[0],
      sort: false,
      hidden: false,
      label: key[1] || '',
    }));
  }

  /**
   * a list of columns you want to hide or enable sorting by.
   * Options
   * @param columns - a previously made columns instance.
   * @param sortColumns - array of columns to serach by.
   *
   */

  static sortColumns(columns: Partial<DataTableColumn[]>, sortColumns) {
    const incrementFn = (num) => () => num++;
    const counter = incrementFn(0);
    const sortable = columns.map((col) =>
      sortColumns.some((key) => key === col.key) ? { ...col, sort: true, pos: counter() } : { ...col }
    );
    return sortable;
  }
  /**
   * a list of columns you want to hide or enable sorting by.
   * Options
   * @param columns - a previously made columns instance.
   * @param hideColumns - columns to hide from display.
   */
  static hiddenColumns(columns: Partial<DataTableColumn[]>, hiddenColumns: string[]) {
    const hidden = columns.map((col) =>
      hiddenColumns.some((key) => key === col.key) ? { ...col, visible: false } : { ...col }
    );

    return hidden;
  }
  /**
   * a list of columns you want to enable linking for.
   * Options
   * @param columns - a previously made columns instance.
   * @param linkableColumns - columns that can be clinked to be linked out to other pages
   */
  static linkableColumns(columns: Partial<DataTableColumn[]>, linkableColumns) {
    const linkable = columns.map((col) =>
      linkableColumns.some((key) => key === col.key) ? { ...col, linkable: true } : { ...col, linkable: false }
    );
    return linkable;
  }

  /**
   * List of centered styled columns
   * @param columns - a previously made columns instance.
   * @param linkableColumns - columns that have center styling applied
   */
  static centeredColumns(columns: Partial<DataTableColumn[]>, linkableColumns) {
    const linkable = columns.map((col) =>
      linkableColumns.some((key) => key === col.key) ? { ...col, centered: true } : { ...col, linkable: false }
    );
    return linkable;
  }

  /**
   * Completed table configuration object for use in data table.
   *
   * @param columns - the configured data table columns
   * @param options - options object for configuring.
   *
   * @returns - a valid data table config
   */
  static tableConfig(columns: DataTableColumn[], opts: { searchable?: boolean; paginator?: PaginatorConfig }) {
    const { paginator = paginatorConfigFn(), searchable = true } = opts;
    return { columns, paginatorConfig: paginator, searchable };
  }

  static makeDataSource<T>(data: T[], pkey: keyof T) {
    return data.map((data, i) => ({ ...data, _id: data[pkey], pos: i }), []);
  }

  static parseDateString(dateStr: string) {
    const currDate: Date = new Date();
    const testDate: Date = new Date(Date.parse(dateStr));
    return testDate.getTime() < currDate.getTime();
  }
}

const columns = ['_id', 'description', 'other', 'pos'];
