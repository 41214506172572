import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkflowActionsType } from '@core/models/workflow-actions.model';

export interface ApprovalTaskData {
  type: WorkflowActionsType;
  module?: 'expenditure' | 'outcome';
}

export interface ApprovalTaskResult {
  type: WorkflowActionsType;
  comment?: string;
}

@Component({
  selector: 'cf2-approval-task',
  template: `
    <h2 mat-dialog-title>{{ type === 'cancelCheckpoint' ? 'Cancel Checkpoint' : type | titlecase }}</h2>
    <mat-dialog-content>
      <div class="flex-col">
        <cf2-warning-row *ngIf="hasWarnRow"
          >{{
            type === 'return'
              ? 'Returning this request will notify the user to update and re-submit for approval. Please include specific details in the Return Reason below'
              : module === 'outcome'
              ? type === 'cancel' 
                ? 'Warning - Once this Monitoring Phase is cancelled, the Monitoring Phase will be Archived and no longer Accessible'
                : 'Warning - Once this Checkpoint is cancelled, all details will be removed'
              : 'Warning - Once the Expenditure Request has been Cancelled, it cannot be re-opened'
          }}.</cf2-warning-row
        >

        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          <label
            >{{ type === 'cancel' || type === 'return' ? 'Reason' : 'Comment' }}
            <span class="required-asterisk" *ngIf="isRequired">*</span></label
          >
          <textarea
            rows="3"
            matInput
            [(ngModel)]="comment"
            class="comment-field"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="clicked(true)" [disabled]="!isValid">Submit</button>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./approval-task.component.scss'],
})
export class ApprovalTaskComponent implements OnInit {
  type: string = '';
  module: string = '';
  comment = '';

  get requiredType() {
    return this.type === 'cancel' || this.type === 'return';
  }
  get hasWarnRow() {
    return this.type === 'cancel' || this.type === 'return' || this.type === 'cancelCheckpoint';
  }

  get isRequired() {
    return this.requiredType;
  }

  get isValid() {
    return this.requiredType ? this.comment.length >= 3 : true;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApprovalTaskData,
    public dialogRef: MatDialogRef<ApprovalTaskComponent>
  ) {
    if (!data) throw new Error('No data passed to Approval Task Component');

    this.type = data.type;
    this.module = data?.module ?? '';
  }

  ngOnInit(): void {
    'abcdef';
  }

  clicked(evt: any) {
    this.dialogRef.close({ comment: this.comment, close: true });
  }
}
