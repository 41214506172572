/* tslint:disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApplicationSettingsService } from './services/application-settings.service';
import { ApplicationStatusService } from './services/application-status.service';
import { AssessmentService } from './services/assessment.service';
import { CaseService } from './services/case.service';
import { CaseNotesService } from './services/case-notes.service';
import { ClientService } from './services/client.service';
import { ClientSiteService } from './services/client-site.service';
import { CommonAssessmentService } from './services/common-assessment.service';
import { DocumentService } from './services/document.service';
import { EapService } from './services/eap.service';
import { EmployeeService } from './services/employee.service';
import { EmploymentService } from './services/employment.service';
import { ExpenditureService } from './services/expenditure.service';
import { InquiryService } from './services/inquiry.service';
import { LookupService } from './services/lookup.service';
import { OrganizationService } from './services/organization.service';
import { OutcomeService } from './services/outcome.service';
import { PropertyService } from './services/property.service';
import { ReportsService } from './services/reports.service';
import { SchedulingService } from './services/scheduling.service';
import { SecurityService } from './services/security.service';
import { StateService } from './services/state.service';
import { UserService } from './services/user.service';
import { WorkflowService } from './services/workflow.service';
import {IntegrationDashboardService} from "@api/services/integration-dashboard.service";

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApplicationSettingsService,
    ApplicationStatusService,
    AssessmentService,
    CaseService,
    CaseNotesService,
    ClientService,
    ClientSiteService,
    CommonAssessmentService,
    DocumentService,
    EapService,
    EmployeeService,
    EmploymentService,
    ExpenditureService,
    InquiryService,
    LookupService,
    OrganizationService,
    OutcomeService,
    PropertyService,
    ReportsService,
    SchedulingService,
    SecurityService,
    StateService,
    UserService,
    WorkflowService,
    ApiConfiguration,
    IntegrationDashboardService
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
