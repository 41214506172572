import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'cf2-input-field-top',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small,
        'full-width-no-left-margin': fullWidth && noLeftMargin
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          type="text"
          autocomplete="off"
          [formControl]="ctrl"
          [disableControl]="disabled"
        />
        <mat-icon matSuffix *ngIf="icon">{{ icon }}</mat-icon>
        <fa-icon *ngIf="faIcon" [icon]="faIcon" matSuffix (click)="iconClick.emit(true)"></fa-icon>
        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
        <mat-error *ngIf="ctrl && ctrl['errorMessage']"> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputFieldTopComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() faIcon: IconDefinition;
  @Input() icon: string;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = '';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() noLeftMargin = false;
  @Input() labelVisible = true;

  @Output() iconClick = new EventEmitter<boolean>();

  public ngAfterViewChecked(): void {
    if (this.value && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }
}
