import { ClientIdentifierModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type ClientIdentifierKeysType =
  | keyof ClientIdentifierModel
  | 'select'
  | 'deleteIcon'
  | 'createDate'
  | 'updateDate'
  | 'updateUserId';

/* tuple setup for casenote config */
type ClientIdentifierHeaderTupleType = [ClientIdentifierKeysType, string];

/* actual display configuration */
const ID_TYPES_LIST_HEADERS: readonly ClientIdentifierHeaderTupleType[] = [
  ['key', 'Id'],
  ['identifierTypeDescription', 'Type'],
  ['comment', 'Value'],
  ['createDateFormated', 'CreateDate'],
  ['createUserName', 'CreatedBy'],
  ['updateDateFormatted', 'UpdatedDate'],
  ['updateUserName', 'UpdatedBy'],
];

/* list of columns that can be linked */
const ID_TYPES_LIST_LINK_COLUMNS = ['identifierTypeDescription'];

/* list of columns that can be sorted */
const ID_TYPES_LIST_SORT_COLUMNS = ['identifierTypeDescription', 'comment'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class IdTypesListData extends DataTableSetup {
  /* set the list headers */
  private idTypesListHeaders = [...ID_TYPES_LIST_HEADERS];
  private idTypesListLinkColumns = [...ID_TYPES_LIST_LINK_COLUMNS];
  private idTypesListSortColumns = [...ID_TYPES_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = IdTypesListData.linkableColumns(
      IdTypesListData.hiddenColumns(
        IdTypesListData.sortColumns(IdTypesListData.initColumns(this.idTypesListHeaders.map((item) => this.translocoService.translate(item))), this.idTypesListSortColumns),
        []
      ),
      this.idTypesListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
