import { UntypedFormGroup } from '@angular/forms';
import { ExternalContactModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';

export type ExternalContactsKeysType = keyof ExternalContactModel;
export type ExternalContactsLabelsType = Record<ExternalContactsKeysType, string | boolean>;

export interface ExternalContactsComponentField extends FormsComponentField {
  key: ExternalContactsKeysType | 'deleteIcon';
}

export const EXTERNAL_CONTACTS_CONFIG: readonly ExternalContactsComponentField[] = [
  {
    key: 'firstName',
    label: 'FirstName',
    type: 'string',
    required: true,
  },
  {
    key: 'lastName',
    label: 'LastName',
    type: 'string',
    required: true,
  },
  {
    key: 'title',
    label: 'Title',
    type: 'string',
    required: true,
  },
  {
    key: 'organizationName',
    label: 'Organization',
    type: 'string',
    required: true,
  },
  {
    key: 'email',
    label: 'Email',
    type: 'email',
    required: false,
  },
  {
    key: 'phone',
    label: 'PhoneNo.',
    type: 'phone',
    required: false,
  },
  {
    key: 'deleteIcon',
    label: 'PhoneNo.',
    type: 'phone',
    required: false,
  },
];

export class ExternalContactsForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: ExternalContactsLabelsType;
    required: ExternalContactsLabelsType;
    disabled: ExternalContactsLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, ts : TranslocoService, opts?: { value?: any }) {
    super(fb, ts);
    const config = EXTERNAL_CONTACTS_CONFIG;

    this._fields = opts ? (this.makeFields([...config], opts) as any) : (this.makeFields([...config]) as any);
    // console.log(this._fields);
  }
}
