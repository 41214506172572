import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-home',
  template: `
  
    <cf2-header-row>
      
        <header-title><ng-container *transloco="let t;">{{t('Home')}}</ng-container></header-title>
        
      <page-content>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="25ppx">
          <cf2-alt-expansion-panel [titleText]="'MyTasks' | transloco" [disableExpansion]="true">
            <panel-content>
              <cf2-expenditure-workflows></cf2-expenditure-workflows>
            </panel-content>
          </cf2-alt-expansion-panel>
          <cf2-alt-expansion-panel [titleText]="'UpcomingAppointments' | transloco" [disableExpansion]="true">
            <panel-content>
              <cf2-meeting-occurance-card></cf2-meeting-occurance-card>
            </panel-content>
          </cf2-alt-expansion-panel>
          <cf2-alt-expansion-panel [titleText]="'UpcomingMonitoringCheckpoints' | transloco" [disableExpansion]="true">
            <panel-content>
              <cf2-monitoring-checks-card></cf2-monitoring-checks-card>
            </panel-content>
          </cf2-alt-expansion-panel>
          <cf2-alt-expansion-panel [titleText]="'InactiveCases' | transloco" [disableExpansion]="true">
            <panel-content>
              <cf2-inactive-cases-card></cf2-inactive-cases-card>
            </panel-content>
          </cf2-alt-expansion-panel>
        </div>
      </page-content>
    </cf2-header-row>

  `,
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(public transloco: TranslocoService) { }
}
