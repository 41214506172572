import { MeetingOccurrenceModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type MeetingOccuranceKeysType = keyof MeetingOccurrenceModel | 'clientName';

/* tuple setup for casenote config */
type MeetingOccuranceHeaderTupleType = [MeetingOccuranceKeysType, string];

/* actual display configuration */
const MEETING_OCCURANCE_LIST_HEADERS: readonly MeetingOccuranceHeaderTupleType[] = [
  ['parentMeetingOccurrenceKey', 'ApptID'],
  ['subjectDescription', 'Type'],
  ['meetingDateFormatted', 'Date'],
  ['startISOFormatted', 'StartTime'],
  ['endISOFormatted', 'EndTime'],
  ['clientName', 'Client'],
  ['meetingDescription', 'MeetingType'],
];

/* list of columns that can be linked */
const MEETING_OCCURANCE_LIST_LINK_COLUMNS = ['parentMeetingOccurrenceKey'];

/* list of columns that can be sorted */
const MEETING_OCCURANCE_LIST_SORT_COLUMNS = ['identifierType', 'identifier'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class MeetingOccuranceListData extends DataTableSetup {
  /* set the list headers */
  private meetingOccuranceListHeaders = [...MEETING_OCCURANCE_LIST_HEADERS];
  private meetingOccuranceListLinkColumns = [...MEETING_OCCURANCE_LIST_LINK_COLUMNS];
  private meetingOccuranceListSortColumns = [...MEETING_OCCURANCE_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = MeetingOccuranceListData.linkableColumns(
      MeetingOccuranceListData.hiddenColumns(
        MeetingOccuranceListData.sortColumns(
          MeetingOccuranceListData.initColumns(this.meetingOccuranceListHeaders.map((item) => this.translocoService.translate(item))),
          this.meetingOccuranceListSortColumns
        ),
        []
      ),
      this.meetingOccuranceListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
