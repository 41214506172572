import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-timepicker-wrapper',
  template: `
    <div
      #container
      style="height: 82.4px !important"
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always">
        <mat-label><ng-content select="custom-label"></ng-content></mat-label>
        <input matInput style="display: none;" [formControl]="ctrl" />
        <ng-content select="custom-component"></ng-content>
        <mat-error>
          <ng-content select="custom-error"></ng-content> 
        </mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../../input-field-top.component.scss', 'timepicker-wrapper.component.scss'],
})
export class TimepickerWrapperComponent implements OnInit {
  @Input() ctrl: UntypedFormControl;
  @Input() fullWidth: boolean = false;
  @Input() small: boolean = false;
  constructor() { }

  ngOnInit(): void { }
}
