import * as R from 'remeda';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MeetingOccurrenceModel } from '@api/models';
import { SchedulingService } from '@api/services';
import { UntypedFormControl } from '@angular/forms';
import { Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CLIENT_MODULE } from '@core/services/navigation.service';
import { Globals } from '../../../../globals';
import { MeetingOccuranceListData, ListConfig } from '../../models/meeting-occurence-card.datasource';
import { dateBuilder } from '@core/models/date.model';
import { parseDateFormattedFromDate, parseTimeFormatted } from '@shared/models/date-formats.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { filter, pluck } from 'rxjs/operators'; 
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-meeting-occurance-card',
  template: `
    <mat-table [dataSource]="filteredData" matSort aria-label="Meeting Occurance List">
      <!-- column definitions -->
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
        <mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          [disabled]="!columnConfig[i].sort"
          [class.is-small-column]="columnConfig[i].key === 'parentMeetingOccurrenceKey'"
        >
          <ng-container>
            {{ columnConfig[i].label }}
          </ng-container>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let cell; index as j"
          [class.is-linkable]="columnConfig[i].linkable"
          [class.is-small-column]="columnConfig[i].key === 'parentMeetingOccurrenceKey'"
        >
          <ng-container *ngIf="columnConfig[i].key === 'parentMeetingOccurrenceKey'">
            <a
              href="javascript: void(0);"
              (click)="viewAppointment(cell.parentMeetingOccurrenceKey, cell.parentMeetingKey)"
            >
              {{ cell.parentMeetingOccurrenceKey }}
            </a>
          </ng-container>

          <ng-container
            *ngIf="columnConfig[i].key === 'startISOFormatted' || columnConfig[i].key === 'endISOFormatted'"
          >
            {{ cell[column] }}
          </ng-container>

          <ng-container *ngIf="columnConfig[i].key === 'clientName'">
            <a href="javascript: void(0);" (click)="viewClient(cell.parentClientKey)">
              {{ cell.clientName }}
            </a>
          </ng-container>

          <ng-container
            *ngIf="
              columnConfig[i].key !== 'startISOFormatted' &&
              columnConfig[i].key !== 'endISOFormatted' &&
              columnConfig[i].key !== 'parentMeetingOccurrenceKey' &&
              columnConfig[i].key !== 'clientName'
            "
          >
            {{ cell[column] }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Header Row-->
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

      <!-- Select Record -->
      <mat-row *matRowDef="let cell; columns: columns"></mat-row>
    </mat-table>

    <!-- No data found -->
    <ng-container *transloco="let t;">
    <ng-container *ngIf="isReady && filteredData.length < 1">
      <div class="noresult">
        <div class="center">{{t('NoResultsFound')}}.</div>
      </div>
    </ng-container>
    </ng-container>
  `,
})
export class MeetingOccuranceCardComponent extends BaseComponent implements OnInit, AfterViewInit {
  parseTimeFormatted = parseTimeFormatted;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() data: MeetingOccurrenceModel[];
  @Input() isReady = false;
  @Input() filteredData: MeetingOccurrenceModel[];
  @Input() caseKey: number;
  @Input() searchFc?: UntypedFormControl;
  // @Input() view?: ViewType = 'VIEW';
  // set default values for casenotes list configuration
  // @return number
  @Output() selectedItem = new EventEmitter<number>();
  // @return CaseNotes[]
  @Output() selectedItems = new EventEmitter<MeetingOccurrenceModel[]>();

  @Output() delete = new EventEmitter<number>();

  columnConfig: ListConfig[];
  columns: any;
  sortColumn: string;
  sortDirection: boolean;
  currentPath: string;

  selection = new SelectionModel<MeetingOccurrenceModel>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredData.forEach((row) => this.selection.select(row));

    this.selectedItems.emit(this.selection.selected);
  }

  selectionChanged(event, cell) {
    this.selection.toggle(cell);
    this.selectedItems.emit(this.selection.selected);
  }

  viewAppointment(meetingOccurrenceKey: number, meetingKey: number) {
    this.navigate('scheduling/view', {
      meetingOccurrenceKey,
      meetingKey,
    });
  }

  viewClient(key: number) {
    this.navigate('clients', { clientKey: key });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals,
    private schedulingSvc: SchedulingService, private translocoService: TranslocoService
  ) {
    super();
    if (this.route?.snapshot?.routeConfig?.path) {
      this.currentPath = this.route.snapshot.routeConfig.path;
    }

      const tableSetup = new MeetingOccuranceListData(translocoService);
      this.columnConfig = tableSetup.config;
      this.columns = this.columnConfig.map((column) => column.key);
  }

  ngOnInit(): void {
    // Display all the meetings that are taking place in the next 24 hours
    const fromDate = parseDateFormattedFromDate(new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]));
    const toDate = parseDateFormattedFromDate(new Date(dateBuilder()[0], dateBuilder()[1], (dateBuilder()[2] + 1)));

    this.schedulingSvc
      .apiSchedulingEmployeeParentEmployeeKeyGet$Json({
        parentEmployeeKey: this.globals.employeeKey,
        meetingStatusCode: 'SCHED',
        fromDate,
        toDate,
      })
      .subscribe((data) => {
        data.forEach((c) => {
          c.startISOFormatted = parseTimeFormatted(c.startTime);
          c.endISOFormatted = parseTimeFormatted(c.endTime);
          c.meetingDateFormatted = parseDateFormattedFromDate(new Date(c.meetingDate));
        })
        
        this.filteredData = data;

        setTimeout(() => {
          this.isReady = true;
        }, 100);
      });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((obs) => {
      this.sortData(obs.active, obs.direction === 'asc');
    });

    if (this.searchFc) {
      this.searchFc.valueChanges.subscribe((obs) => {
        this.updateSearch(obs);
      });
    }
  }

  sortData(column: string, asc: boolean): void {
    const data = this.data;
    const uniqVals = R.uniq(data.map((vals) => vals[column]));

    // avoid re-sorting a column that has one value.
    // TODO: move this out to a sorted value
    const nullChecked = data.map((itm) => ({ ...itm, [column]: itm[column] == null ? '' : itm[column] }));

    if (column === 'clientContactDateFormatted') {
      const sorted = this.sortResults('clientContactDate', nullChecked, asc);
      this.sortDirection = asc;
      this.sortColumn = column;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    } else {
      const sorted = this.sortResults(column, nullChecked, asc);
      this.sortColumn = column;
      this.sortDirection = asc;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    }
  }

  sortResults(column: string, data: any[], isAsc = true): any[] {
    return R.sort(data, (a, b) => this.compare(a[column], b[column], isAsc));
  }

  compare(a, b, isAsc): any {
    if (!a) return -1;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a.length < 1 ? -1 : a <= b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  async updateSearch(filterStr: string): Promise<void> {
    const filter = filterStr.toLowerCase();
    const data = this.data;
    const columns = this.columns;
    const filtered = data.filter((row) => {
      const picked = R.pick(row, columns);
      const mapped = R.toPairs(picked).map((val) => val[1]);
      const some = mapped.some((val: string) => {
        if (typeof val === 'string') {
          const test = val.toLowerCase().includes(filter);
          return test;
        } else return val === filter;
      });
      return some;
    });
    this.filteredData = filtered;
  }
}
