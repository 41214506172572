import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

const modules = [
  CommonModule,
  LayoutModule,
  EditorModule,
  SchedulerModule,
  DateInputsModule,
  EditorModule,
  PDFExportModule,
  SchedulerModule,
  DateInputsModule,
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
})
export class Cf2KendoModule {}
