import { CaseModel } from "@api/models";
import { CF2TableFieldType } from '@core/types/cf2-table-field.type';

export type InactiveCaseKeysType = keyof CaseModel | 'organization';

/* tuple setup for document config */
type InactiveCaseListHeaderTupleType = [InactiveCaseKeysType, string, CF2TableFieldType];

export const INACTIVE_CASE_LIST_HEADERS: readonly InactiveCaseListHeaderTupleType[] = [
  ['clientClientBusinessKey2', 'CaseFLOId', 'link'],
  ['clientDisplayName', 'ClientName', 'text'],
  ['daysSinceLastActivity', 'DaysInactive', 'text'],
  ['parentEmployeeName', 'PrimaryConsultant', 'text'],
  ['siteName', 'Org\/Site', 'text']
];
