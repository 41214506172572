import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { CommonAssessmentModel } from '../models/common-assessment-model';

@Injectable({
  providedIn: 'root',
})
export class CommonAssessmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCommonAssessmentCAReferenceNumberGetPath
   */
  static readonly ApiCommonAssessmentCAReferenceNumberGetPath = '/api/CommonAssessment/{caRefNum}';

  /**
   * Get a common assessment record
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCommonAssessmentCAReferenceNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCommonAssessmentCAReferenceNumberGet$Json$Response(params: {
    caReferenceNumber?: string;
  }): Observable<StrictHttpResponse<Array<CommonAssessmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CommonAssessmentService.ApiCommonAssessmentCAReferenceNumberGetPath, 'get');
    if (params) {
      rb.path('caRefNum', params.caReferenceNumber, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonAssessmentModel>>;
      })
    );
  }
}