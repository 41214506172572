import { ClientModel, CaseModel } from '@api/models';
import { ClientAddressModel } from '@api/models/client-address-model';
import { FormsComponentField } from '@core/models/base-component-field';

/**
 * TODO: validate if I should merge/flatten the client and case record. Client's pulled over.
 *
 */
type TempKeys = 'primaryMobile' | 'parentEmployeeKey';
export type CaseKeysType = keyof CaseModel;
export type ClientKeysType = keyof ClientModel | 'parentOrganizationKey' | 'parentSiteKey';
export type AddressKeysType = keyof ClientAddressModel;

export type ClientLabelsType = Record<CaseKeysType | ClientKeysType | TempKeys, string | boolean>;

export type AddressLabelsType = Record<AddressKeysType, string>;
export interface CaseComponentField extends FormsComponentField {
  key: CaseKeysType | ClientKeysType | TempKeys;
}

export interface AddressCardComponentField extends FormsComponentField {
  key: AddressKeysType;
}

export const CASE_CLIENT_HEADER: readonly CaseComponentField[] = [
  {
    key: 'firstName',
    label: 'FirstName',
    type: 'name',
  },
  {
    key: 'lastName',
    label: 'LastName',
    type: 'name',
  },
  {
    key: 'parentEmployeeKey',
    label: 'Employment Consultant',
    type: 'select',
    required: false,
  },
  {
    label: 'Organization',
    key: 'parentOrganizationKey',
    type: 'select',
    required: false,
  },
  {
    label: 'Organization/Site',
    key: 'parentSiteKey',
    type: 'select',
  },
];

export const CASE_CLIENT_CARD: readonly CaseComponentField[] = [
  {
    key: 'parentClientKey',
    type: 'display',
    required: false,
  },
  {
    key: 'displayName',
    type: 'string',
    required: false,
  },
  {
    key: 'clientClientBusinessKey',
    type: 'string',
    required: false,
  },
  {
    key: 'idValidated',
    label: 'Message.Notes.IdValidated',
    type: 'checkbox',
    required: false,
  },
  {
    key: 'birthDate',
    label: 'Date of Birth',
    type: 'birthdate',
  },
  {
    key: 'clientBusinessKey',
    label: 'SIN',
    type: 'sin',
  },
  {
    key: 'genderCode',
    label: 'Identify As',
    type: 'select',
  },
  {
    key: 'clientBusinessKey3',
    label: 'CaMS ID',
    type: 'string',
  },
  {
    key: 'primaryEmail',
    label: 'Email',
    type: 'email',
  },
  {
    key: 'primaryPhone',
    label: 'Phone No.',
    type: 'phone',
  },
  {
    key: 'primaryMobile',
    label: 'Mobile Phone',
    type: 'sms',
    required: false,
  },
  {
    key: 'primaryContactType',
    label: 'do not use',
    type: 'select',
    required: false,
  },
  {
    label: 'IDType',
    key: 'idCode',
    type: 'idValidated',
    required: false,
  },
];

export const CASE_INFO_CARD: readonly CaseComponentField[] = [
  {
    key: 'primaryContactType',
    label: 'Preferred Contact',
    type: 'select',
    required: false,
  },
  {
    key: 'languageCode',
    label: 'Preferred Language',
    type: 'select',
    required: false,
  },
  {
    key: 'intakeSourceCode',
    label: 'Intake Source',
    type: 'select',
    required: true,
  },
  {
    key: 'parentProgramStreamKey',
    label: 'Stream',
    type: 'select',
    required: true,
  },
  {
    key: 'parentProgramKey',
    label: 'Program',
    type: 'select',
    required: true,
  },
];

/* TODO: validate that this should be a client address model */
export const CASE_ADDRESS_CARD: readonly AddressCardComponentField[] = [
  {
    key: 'addressLine1',
    label: 'Street Address 1',
    type: 'string',
  },
  {
    key: 'addressLine2',
    label: 'Street Address 2',
    type: 'string',
    required: false,
  },
  {
    key: 'city',
    label: 'City',
    type: 'name',
  },
  {
    key: 'provinceCode',
    label: 'Province',
    type: 'select',
  },
  {
    key: 'postalCode',
    label: 'Postal Code',
    type: 'postal',
  },
  {
    key: 'key',
    label: '',
    type: 'select',
    required: false,
  },
];
