import { ExpenditureModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ExpenditureStateType } from './expenditure-state.model';
import { UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { RolesType, ExpenditureStatusType } from '@core/models/workflow-actions.model';
import { TranslocoService } from '@ngneat/transloco';

/**
 * @summary - field definitions for the expenditure model
 */
type ExpenditureKeysType =
  | keyof ExpenditureModel
  /* TODO: temporary keys - map to correct & remove */
  | 'supplierName'
  | 'supplierCity'
  | 'comment';

export type ExpenditureLabelsType = Record<ExpenditureKeysType, string | boolean>;
export interface ExpenditureFormField extends FormsComponentField {
  key: ExpenditureKeysType;
}

/* 7.	When creating a Client Expenditure type, the user must enter the Supplier name (mandatory) and can optionally enter Supplier City as well.  These are both text entry fields. */
export const EXPENDITURE_FIELD_CONFIG: readonly ExpenditureFormField[] = [
  {
    key: 'expenditureStatusCode',
    label: 'Status',
    type: 'select',
    required: false,
    disabled: true,
  },
  {
    key: 'expenditureTypeCode',
    label: 'ExpenditureType',
    type: 'string',
  },
  /*
  Expenditure.ExpenditureStatusCode (select), ExpenditureStatusType.ExpenditureStatusDescription (list, display)
  */
  /*
  Only visible for Client Expenditure Types.
  The value selected for this question determines the default value for the Recoverable? Yes/No answer. If the answer to MBM    question is Yes, then Recoverable is defaulted to Yes.  If MBM answer is No, Recoverable is defaulted to No.

  */
   {
    key: 'paymentTypeCode',
    label: 'PaymentMethod',
    type: 'select',
  },
  {
    key: 'expenditureDate',
    label: 'PurchaseDate',
    type: 'pastDate',
  },
  /* Expenditure.ParentSupplierKey (select, store), Supplier.SupplierName (store, Display) */
  {
    key: 'parentSupplierKey',
    label: 'SupplierName',
    type: 'select',
    required: false,
  },
  {
    key: 'supplierName',
    label: 'SupplierName',
    type: 'string',
  },
  {
    key: 'supplierCity',
    label: 'SupplierCity',
    type: 'string',
    required: false,
  },

  {
    key: 'parentEmployerKey',
    label: 'ClientEmployer',
    type: 'select',
    required: false,
  },

  /* employer contact phone */
  {
    key: 'employerContact',
    type: 'select',
    disabled: true,
    required: false,
  },
  /* employer contact name */
  /* employer contact job title */
  {
    key: 'comment',
    label: 'BusinessCase',
    type: 'midLongString',
  },
  {
    key: 'biaIndicator',
    label: 'BrokeredIncentiveAgreement',
    type: 'checkbox',
    required: true,
  },
  {
    key: 'biaStartDate',
    label: 'BIAStartDate',
    type: 'futureDate',
    required: false,
  },
  {
    key: 'biaEndDate',
    label: 'BIAEndDate',
    type: 'futureDate',
    required: false,
  },
];

const editConfig = () =>
  /**
   *
   *
   * @param {RolesType} [role='EC']
   * @return {*}
   */
  function ( role: RolesType = 'EC') {
    const isEc = role === 'EC';
    /*
      Supplier Name & City
      Validation of eligibility (MBM Attestation)
      */
    const enabledFields: ExpenditureKeysType[] = [
      'supplierCity',
      'supplierName',
      'parentSupplierKey',
      'expenditureDate',
      'expenditureDateFormatted',
    ];
    const config = EXPENDITURE_FIELD_CONFIG;
    return isEc
      ? FormsFns.disableAll([...config])
      : config.map((field) => ({ ...field, disabled: !enabledFields.some((key) => key === field.key) }));
  };

const mappedEditConfig = editConfig();

/**
 * The expenditure form item for use in expenditure forms data-service and components
 *
 * @export
 * @class ExpenditureForm
 * @extends {Cf2Forms}
 */
export class ExpenditureForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: ExpenditureLabelsType;
    required: ExpenditureLabelsType;
    disabled: ExpenditureLabelsType;
  };
  get fields() {
    return this._fields;
  }

  /**
   * Get the single value of a particular field
   * @param key - a valid Expenditure Model Key
   * @returns - the value of the selected field
   */
  value(key: keyof ExpenditureModel): string {
    this._fields.fg.updateValueAndValidity(FormsFns.formUpdateOpts);
    const value = this._fields.fg.getRawValue()[key];

    if (!value && value !== false) {
      return null;
    }
    return value;
  }

  get values() {
    return this._fields.fg.value;
  }
  constructor(
    fb: RxFormBuilder,
    ts : TranslocoService,
    opts?: {
      value?: ExpenditureModel;
      state?: ExpenditureStateType;
      role?: RolesType;
      status?: ExpenditureStatusType;
    }
  ) {
    super(fb, ts);

    
    const { value = null, state = 'create', role = 'EC', status = 'INPR' } = opts;
    const isCreate = state === 'create';
    const isInpr = status === 'INPR';
    const config = (isCreate || isInpr ? EXPENDITURE_FIELD_CONFIG : mappedEditConfig(role))
        .map((field) => ({...field, label: ts.translate(field.label)}));

    const supplierCity = value ? value?.supplier?.supplierCity : null;
    const supplierName = value ? value?.supplier?.supplierName : null;
    
    const flattened = value
      ? {
        ...value,
        supplierCity,
        supplierName,
        expenditureDate: value.expenditureDate ? new Date(value.expenditureDate) : null,
        biaStartDate: value.biaStartDate ? new Date(value.biaStartDate) : null,
        biaEndDate: value.biaEndDate ? new Date(value.biaEndDate) : null
      }
      : null;

    const mappedConfig = value
      ? config.map((fields) => ({ ...fields, value: flattened[fields.key] }))
      : ([...config] as any);
    /* TODO: map value */
    this._fields = this.makeFields(mappedConfig) as any;
    
  }
}
