<mat-autocomplete
autoActiveFirstOption
#auto="matAutocomplete"
(optionSelected)="lookupSelected.emit($event.option.value)"
>
<mat-option *ngFor="let option of filteredOptions | async" [value]="option.description" [displayWith]="displayFn">
  {{ option.description }}
</mat-option>
</mat-autocomplete>
<div
#container
[ngClass]="{
  'input-field-top-small': small && !fullWidth,
  'typeahead-field-top': !small && !fullWidth,
  'full-width': fullWidth,
  'full-width-small': fullWidth && small
}"
class="typeahead-field-top auto-complete"
>
<mat-form-field appearance="standard" floatLabel="always" #elRef>
  <mat-label *ngIf="labelVisible" for="{{ idField }}">
    {{ label }}
    <span *ngIf="required === true && labelVisible === true" class="required-asterisk">*</span>
  </mat-label>

  <input
    type="text"
    [placeholder]="placeholder"
    aria-label="Number"
    matInput
    [formControl]="ctrl"
    [matAutocomplete]="auto"
    #autocompleteTrigger="matAutocompleteTrigger"
    [autocompletePosition]="autocompleteTrigger"
    #autoComplete
  />
  <button
    mat-button
    *ngIf="ctrl.value && !disabled"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearValue()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
  <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
</mat-form-field>
</div>