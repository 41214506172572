/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseNoteModel } from '../models/case-note-model';
import { DocumentDto } from '../models/document-dto';
import { ExpenditureModel } from '../models/expenditure-model';
import { ExpenditureStatusHistoryModel, HistoryDetailModel } from '../models/expenditure-status-history-model';
import { ProcessResponse } from '../models/process-response';
import { TaskModel } from '../models/task-model';
import {PagingResult} from "@api/services/client.service";

@Injectable({
  providedIn: 'root',
})
export class ExpenditureService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete
   */
  static readonly ApiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDeletePath = '/api/Expenditure/{parentExpenditureKey}/link/{documentLinkKey}';

  /**
   * Delete a link to a document by key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json$Response(params: {
    parentExpenditureKey: number;
    documentLinkKey: number;
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDeletePath, 'delete');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.path('documentLinkKey', params.documentLinkKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a link to a document by key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json(params: {
    parentExpenditureKey: number;
    documentLinkKey: number;
  }): Observable<ProcessResponse> {

    return this.apiExpenditureParentExpenditureKeyLinkDocumentLinkKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiExpenditureFilteredGet
   */
  static readonly ApiExpenditureFilteredGetPath = '/api/Expenditure/filtered';

  /**
   * Returns a filtered list of all expenditure records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureFilteredGet$Json$Response(params?: {
    expenditureStatusCode?: null | string[];
    expenditureTypeCode?: null | string[];
    site?: null | string[];
    org?: null | string[];
    fromDate?: string;
    toDate?: string;
    pageNumber?: 1 | number;
    pageSize?: 15 | number;
    sortColumn?: '' | string;
    sortDirection?: 'asc' | string;
    local?: 'en-ca' | string;
    contractType?: null | string[];

  }): Observable<StrictHttpResponse<PagingResult<ExpenditureModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureFilteredGetPath, 'get');
    if (params) {
      rb.query('expenditureStatusCode', params.expenditureStatusCode, {});
      rb.query('expenditureTypeCode', params.expenditureTypeCode, {});
      rb.query('site', params.site, {});
      rb.query('org', params.org, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('local', params.local, {});
      rb.query('contractType', params.contractType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagingResult<ExpenditureModel>>;
      })
    );
  }

  /**
   * Returns a filtered list of all expenditure records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureFilteredGet$Json(params?: {
    org?: null | Array<string>;
    site?: null | Array<string>;
    expenditureTypeCode?: null | Array<string>;
    expenditureStatusCode?: null | Array<string>;
    pageNumber?: 0 | number;
    pageSize?: 15 | number;
    sortColumn?: '' | string;
    sortDirection?: 'asc' | string;
    fromDate?: '' | string;
    toDate?: '' | string;
    local?: 'en-ca' | string;
    contractType?: null | Array<string>;

  }): Observable<Array<ExpenditureModel>> {
    let url = `${this.rootUrl}${ExpenditureService.ApiExpenditureFilteredGetPath}?${this.objectToQueryString(params)}`;
    return this.http.get<ExpenditureModel[]>(url);
  }

  objectToQueryString(params: any): string {
    if (params) {
      const tempParams = [];
      for (const p in params)
        if (params.hasOwnProperty(p)) {
          let value = params[p];
          if (value === null || (Array.isArray(value) && value.length < 1)) continue;
          tempParams.push(encodeURIComponent(p) + '=' + value);
        }
      const queryString = tempParams.join('&');
      return queryString;
    }
    return '';
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyCaseNotesGet
   */
  static readonly ApiExpenditureParentExpenditureKeyCaseNotesGetPath = '/api/Expenditure/{parentExpenditureKey}/caseNotes';

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyCaseNotesGet$Json$Response(params: {
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyCaseNotesGetPath, 'get');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyCaseNotesGet$Json(params: {
    parentExpenditureKey: number;
  }): Observable<Array<CaseNoteModel>> {

    return this.apiExpenditureParentExpenditureKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyCaseNotesPost
   */
  static readonly ApiExpenditureParentExpenditureKeyCaseNotesPostPath = '/api/Expenditure/{parentExpenditureKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExpenditureParentExpenditureKeyCaseNotesPost$Json$Response(params: {
    parentExpenditureKey: number;
    body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyCaseNotesPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExpenditureParentExpenditureKeyCaseNotesPost$Json(params: {
    parentExpenditureKey: number;
    body?: CaseNoteModel
  }): Observable<ProcessResponse> {

    return this.apiExpenditureParentExpenditureKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyDocumentsGet
   */
  static readonly ApiExpenditureParentExpenditureKeyDocumentsGetPath = '/api/Expenditure/{parentExpenditureKey}/documents';

  /**
   * Get all documents associated with an expenditure record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyDocumentsGet$Json$Response(params: {
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyDocumentsGetPath, 'get');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents associated with an expenditure record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyDocumentsGet$Json(params: {
    parentExpenditureKey: number;
  }): Observable<Array<DocumentDto>> {

    return this.apiExpenditureParentExpenditureKeyDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyAvailableDocumentsGet
   */
  static readonly ApiExpenditureParentExpenditureKeyAvailableDocumentsGetPath = '/api/Expenditure/{parentExpenditureKey}/availableDocuments';


  /**
   * Get all documents that can be linked to this expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json$Response(params: {
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyAvailableDocumentsGetPath, 'get');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be linked to this expenditure.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json(params: {
    parentExpenditureKey: number;
  }): Observable<Array<DocumentDto>> {

    return this.apiExpenditureParentExpenditureKeyAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiExpenditureAvailableDocumentsGet
   */
  static readonly ApiExpenditureAvailableDocumentsGetPath = '/api/Expenditure/availableDocuments';

  /**
   * Get all documents that can be linked to a new expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureAvailableDocumentsGet$Json$Response(params?: {
    parentCaseKey?: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureAvailableDocumentsGetPath, 'get');
    if (params) {
      rb.query('parentCaseKey', params.parentCaseKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be linked to a new expenditure.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureAvailableDocumentsGet$Json(params?: {
    parentCaseKey?: number;
  }): Observable<Array<DocumentDto>> {

    return this.apiExpenditureAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyTaskHistoryGet
   */
  static readonly ApiExpenditureParentExpenditureKeyTaskHistoryGetPath = '/api/Expenditure/{parentExpenditureKey}/taskHistory';

  /**
   * Get task history for an expenditure record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyTaskHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyTaskHistoryGet$Json$Response(params: {
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<Array<ExpenditureStatusHistoryModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyTaskHistoryGetPath, 'get');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExpenditureStatusHistoryModel>>;
      })
    );
  }

  /**
   * Get task history for an expenditure record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyTaskHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyTaskHistoryGet$Json(params: {
    parentExpenditureKey: number;
  }): Observable<Array<ExpenditureStatusHistoryModel>> {

    return this.apiExpenditureParentExpenditureKeyTaskHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExpenditureStatusHistoryModel>>) => r.body as Array<ExpenditureStatusHistoryModel>)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyActiveTaskGet
   */
  static readonly ApiExpenditureParentExpenditureKeyActiveTaskGetPath = '/api/Expenditure/{parentExpenditureKey}/activeTask';

  /**
   * Get active task an expenditure record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyActiveTaskGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyActiveTaskGet$Json$Response(params: {
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<TaskModel>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyActiveTaskGetPath, 'get');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskModel>;
      })
    );
  }

  /**
   * Get active task an expenditure record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyActiveTaskGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyActiveTaskGet$Json(params: {
    parentExpenditureKey: number;
  }): Observable<TaskModel> {

    return this.apiExpenditureParentExpenditureKeyActiveTaskGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskModel>) => r.body as TaskModel)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyUploadPost
   */
  static readonly ApiExpenditureParentExpenditureKeyUploadPostPath = '/api/Expenditure/{parentExpenditureKey}/upload';

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiExpenditureParentExpenditureKeyUploadPost$Json$Response(params: {
    parentExpenditureKey: number;
    documentSectionCode?: string;
    documentType?: string;
    comment?: string;
    documentDate?: string;
    body?: { 'file'?: Blob }
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyUploadPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentType', params.documentType, {});
      rb.query('comment', params.comment, {});
      rb.query('documentDate', params.documentDate, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiExpenditureParentExpenditureKeyUploadPost$Json(params: {
    parentExpenditureKey: number;
    documentSectionCode?: string;
    documentType?: string;
    comment?: string;
    documentDate?: string;
    body?: { 'file'?: Blob }
  }): Observable<ProcessResponse> {

    return this.apiExpenditureParentExpenditureKeyUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost
   */
  static readonly ApiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPostPath = '/api/Expenditure/{parentExpenditureKey}/link/{parentDocumentStoreKey}';

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json$Response(params: {
    parentExpenditureKey: number;
    parentDocumentStoreKey: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.path('parentDocumentStoreKey', params.parentDocumentStoreKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json(params: {
    parentExpenditureKey: number;
    parentDocumentStoreKey: number;
  }): Observable<string> {

    return this.apiExpenditureParentExpenditureKeyLinkParentDocumentStoreKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeySubmitPost
   */
  static readonly ApiExpenditureParentExpenditureKeySubmitPostPath = '/api/Expenditure/{parentExpenditureKey}/submit';

  /**
   * Submit an expenditure record for approval.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeySubmitPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeySubmitPost$Json$Response(params: {

    /**
     * Expenditure
     */
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeySubmitPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Submit an expenditure record for approval.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeySubmitPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeySubmitPost$Json(params: {

    /**
     * Expenditure
     */
    parentExpenditureKey: number;
  }): Observable<number> {

    return this.apiExpenditureParentExpenditureKeySubmitPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiExpenditureParentExpenditureKeyReopenPost
   */
  static readonly ApiExpenditureParentExpenditureKeyReopenPostPath = '/api/Expenditure/{parentExpenditureKey}/reopen';

  /**
   * Reopen an expenditure record for approval.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentExpenditureKeyReopenPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyReopenPost$Json$Response(params: {

    /**
     * Expenditure
     */
    parentExpenditureKey: number;
  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentExpenditureKeyReopenPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Reopen an expenditure record for approval.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExpenditureParentExpenditureKeyReopenPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentExpenditureKeyReopenPost$Json(params: {

    /**
     * Expenditure
     */
    parentExpenditureKey: number;
  }): Observable<number> {

    return this.apiExpenditureParentExpenditureKeyReopenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiParentExpenditureKeyFinalizePost
   */
  static readonly ApiExpenditureParentKeyFinalizePostPath = '/api/Expenditure/{parentExpenditureKey}/finalize';

  /**
   * Finalize an expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExpenditureParentKeyFinalizePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExpenditureParentKeyFinalizePost$Response(params: {
    parentExpenditureKey: number;
    body: HistoryDetailModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentKeyFinalizePostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiParentExpenditureKeyReturnPost
   */
  static readonly ApiExpenditureParentKeyReturnPostPath = '/api/Expenditure/{parentExpenditureKey}/return';

  /**
   * Return an expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentExpenditureKeyReturnPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentExpenditureKeyReturnPost$Response(params: {
    parentExpenditureKey: number;
    body: HistoryDetailModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentKeyReturnPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiParentExpenditureKeyRecommendPost
   */
  static readonly ApiExpenditureParentKeyRecommendPostPath = '/api/Expenditure/{parentExpenditureKey}/recommend';

  /**
   * Recommend an expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentExpenditureKeyRecommendPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentExpenditureKeyRecommendPost$Response(params: {
    parentExpenditureKey: number;
    body: HistoryDetailModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentKeyRecommendPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiParentExpenditureKeyApprovePost
   */
  static readonly ApiExpenditureParentKeyApprovePostPath = '/api/Expenditure/{parentExpenditureKey}/approve';

  /**
   * Recommend an expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentExpenditureKeyApprovePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentExpenditureKeyApprovePost$Response(params: {
    parentExpenditureKey: number;
    body: HistoryDetailModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentKeyApprovePostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiParentExpenditureKeyCancelPost
   */
  static readonly ApiExpenditureParentKeyCancelPostPath = '/api/Expenditure/{parentExpenditureKey}/cancel';

  /**
   * Recommend an expenditure.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiParentExpenditureKeyCancelPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiParentExpenditureKeyCancelPost$Response(params: {
    parentExpenditureKey: number;
    body: HistoryDetailModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ExpenditureService.ApiExpenditureParentKeyCancelPostPath, 'post');
    if (params) {
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
}
