/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseNoteModel } from '../models/case-note-model';
import { DocumentDto } from '../models/document-dto';
import { EmployerModel } from '../models/employer-model';
import { EmployerPositionModel } from '../models/employer-position-model';
import { EmployerPositionReferralModel } from '../models/employer-position-referral-model';
import { EmployerTypeaheadModel } from '../models/employer-typeahead-model';
import { ProcessResponse } from '../models/process-response';
import { EmployerSearchModel } from '@api/models/employer-search-model';

@Injectable({
  providedIn: 'root',
})
export class EmploymentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete
   */
  static readonly ApiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDeletePath = '/api/Employment/{parentEmployerPositionReferralKey}/link/{documentLinkKey}';

  /**
   * Delete a link to a document by a document link key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json$Response(params: {
    parentEmployerPositionReferralKey: number;
    documentLinkKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentEmployerPositionReferralKey', params.parentEmployerPositionReferralKey, {});
      rb.path('documentLinkKey', params.documentLinkKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a link to a document by a document link key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json(params: {
    parentEmployerPositionReferralKey: number;
    documentLinkKey: number;

  }): Observable<ProcessResponse> {

    return this.apiEmploymentParentEmployerPositionReferralKeyLinkDocumentLinkKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersGet
   */
  static readonly ApiEmploymentEmployersGetPath = '/api/Employment/employers';

  /**
   * Get a list of all employers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<EmployerSearchModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerSearchModel>;
      })
    );
  }

  /**
   * Get a list of all employers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersGet$Json(params?: {

  }): Observable<EmployerSearchModel> {

    return this.apiEmploymentEmployersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerSearchModel>) => r.body as EmployerSearchModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPost
   */
  static readonly ApiEmploymentEmployersPostPath = '/api/Employment/employers';

  /**
   * Create a new employer record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPost$Json$Response(params?: {
    body?: EmployerModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a new employer record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPost$Json(params?: {
    body?: EmployerModel
  }): Observable<string> {

    return this.apiEmploymentEmployersPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersVerifiedGet
   */
  static readonly ApiEmploymentEmployersVerifiedGetPath = '/api/Employment/employers/verified';

  /**
   * Get a list of all "verified" employers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersVerifiedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersVerifiedGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<EmployerSearchModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersVerifiedGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerSearchModel>;
      })
    );
  }

  /**
   * Get a list of all "verified" employers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersVerifiedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersVerifiedGet$Json(params?: {

  }): Observable<EmployerSearchModel> {

    return this.apiEmploymentEmployersVerifiedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerSearchModel>) => r.body as EmployerSearchModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersFilteredGet
   */
  static readonly ApiEmploymentEmployersFilteredGetPath = '/api/Employment/employers/filtered';

  /**
   * Search all employers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersFilteredGet$Json$Response(params?: {
    businessName?: null | string;
    employerBusinessKey?: null | string;
    employerBusinessKey2?: null | string;
    legalName?: null | string;
    city?: null | string;
    postalCode?: null | string;
    verifiedIndicator?: null | boolean;
    pageSize?: null | number;
    pageNumber?: null | number;

  }): Observable<StrictHttpResponse<EmployerSearchModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersFilteredGetPath, 'get');
    if (params) {

      rb.query('businessName', params.businessName, {});
      rb.query('employerBusinessKey', params.employerBusinessKey, {});
      rb.query('employerBusinessKey2', params.employerBusinessKey2, {});
      rb.query('legalName', params.legalName, {});
      rb.query('city', params.city, {});
      rb.query('postalCode', params.postalCode, {});
      rb.query('verifiedIndicator', params.verifiedIndicator, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageNumber', params.pageNumber, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerSearchModel>;
      })
    );
  }

  /**
   * Search all employers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersFilteredGet$Json(params?: {
    businessName?: null | string;
    employerBusinessKey?: null | string;
    employerBusinessKey2?: null | string;
    legalName?: null | string;
    city?: null | string;
    postalCode?: null | string;
    verifiedIndicator?: null | boolean;
    pageSize?: null | number;
    pageNumber?: null | number;

  }): Observable<EmployerSearchModel> {
    return this.apiEmploymentEmployersFilteredGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerSearchModel>) => r.body as EmployerSearchModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersTypeaheadGet
   */
  static readonly ApiEmploymentEmployersTypeaheadGetPath = '/api/Employment/employers/typeahead';

  /**
   * Search all employers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersTypeaheadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersTypeaheadGet$Json$Response(params?: {
    employerName?: null | string;
    verifiedIndicator?: null | boolean;

  }): Observable<StrictHttpResponse<Array<EmployerTypeaheadModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersTypeaheadGetPath, 'get');
    if (params) {

      rb.query('employerName', params.employerName, {});
      rb.query('verifiedIndicator', params.verifiedIndicator, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerTypeaheadModel>>;
      })
    );
  }

  /**
   * Search all employers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersTypeaheadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersTypeaheadGet$Json(params?: {
    employerName?: null | string;
    verifiedIndicator?: null | boolean;

  }): Observable<Array<EmployerTypeaheadModel>> {

    return this.apiEmploymentEmployersTypeaheadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerTypeaheadModel>>) => r.body as Array<EmployerTypeaheadModel>)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersParentEmployerKeyGet
   */
  static readonly ApiEmploymentEmployersParentEmployerKeyGetPath = '/api/Employment/employers/{parentEmployerKey}';

  /**
   * Get a single employer (plus address and contacts).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersParentEmployerKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersParentEmployerKeyGet$Json$Response(params: {
    parentEmployerKey: number;

  }): Observable<StrictHttpResponse<EmployerModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersParentEmployerKeyGetPath, 'get');
    if (params) {

      rb.path('parentEmployerKey', params.parentEmployerKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerModel>;
      })
    );
  }

  /**
   * Get a single employer (plus address and contacts).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersParentEmployerKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersParentEmployerKeyGet$Json(params: {
    parentEmployerKey: number;

  }): Observable<EmployerModel> {

    return this.apiEmploymentEmployersParentEmployerKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerModel>) => r.body as EmployerModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsGet
   */
  static readonly ApiEmploymentEmployersPositionsGetPath = '/api/Employment/employers/positions';

  /**
   * Gets Employer Positions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsGet$Json$Response(params?: {
    reportsToOrganizationUnitKey?: null | Array<number>;
    parentSiteKey?: null | Array<number>;
    streams?: null | Array<number>;
    positionType?: null | Array<string>;
    positionOutcome?: null | Array<string>;
    referralOutcome?: null | Array<string>;
    monitoringPhaseKey?: null | Array<number>;
    contractType?: null | Array<string>;

  }): Observable<StrictHttpResponse<Array<EmployerPositionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsGetPath, 'get');
    if (params) {

      rb.query('reportsToOrganizationUnitKey', params.reportsToOrganizationUnitKey, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});
      rb.query('streams', params.streams, {});
      rb.query('positionType', params.positionType, {});
      rb.query('positionOutcome', params.positionOutcome, {});
      rb.query('referralOutcome', params.referralOutcome, {});
      rb.query('monitoringPhaseKey', params.monitoringPhaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerPositionModel>>;
      })
    );
  }

  /**
   * Gets Employer Positions (Duplicate for new employment module).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentPositionsGet$Json$Response(params?: {
    organization?: null | string[];
    parentSiteKey?: null | string[];
    streams?: null | string[];
    positionType?: null | string[];
    positionOutcome?: null | string[];
    referralOutcome?: null | string[];

  }): Observable<StrictHttpResponse<Array<EmployerPositionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsGetPath, 'get');
    if (params) {
      rb.query('reportsToOrganizationUnitKey', params.organization, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});
      rb.query('streams', params.streams, {});
      rb.query('positionType', params.positionType, {});
      rb.query('positionOutcome', params.positionOutcome, {});
      rb.query('referralOutcome', params.referralOutcome, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerPositionModel>>;
      })
    );
  }

  /**
   * Gets Employer Positions (Duplicate for new employment module).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentPositionsGet$Json(params?: {
    organization?: null | string[];
    parentSiteKey?: null | string[];
    streams?: null | string[];
    positionType?: null | string[];
    positionOutcome?: null | string[];
    referralOutcome?: null | string[];

  }): Observable<Array<EmployerPositionModel>> {

    return this.apiEmploymentPositionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerPositionModel>>) => r.body as Array<EmployerPositionModel>)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsGet
   */
  static readonly ApiPaginatedEmploymentEmployersPositionsGetPath = '/api/Employment/employers/positions-paginated';

  /**
   * Gets Employer Positions (Duplicate for new employment module).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
   apiPaginatedEmploymentPositionsGet$Json$Response(params?: {
    organization?: null | string[];
    parentSiteKey?: null | string[];
    streams?: null | string[];
    positionType?: null | string[];
    positionOutcome?: null | string[];
    contractTypes?: null | string[];
    referralOutcome?: null | string[];
    pageNumber: 1 | number,
    pageSize: 15 | number,
    sortColumn: '' | string,
    sortDirection: 'asc' | string
  }): Observable<StrictHttpResponse<any[]>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiPaginatedEmploymentEmployersPositionsGetPath, 'get');
    if (params) {
      rb.query('reportsToOrganizationUnitKey', params.organization, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});
      rb.query('streams', params.streams, {});
      rb.query('positionType', params.positionType, {});
      rb.query('positionOutcome', params.positionOutcome, {});
      rb.query('contractTypes', params.contractTypes, {});
      rb.query('referralOutcome', params.referralOutcome, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortDirection', params.sortDirection, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<any[]>;
        })
    );
  }

  /**
   * Gets Employer Positions Paginated (Duplicate for new employment module).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaginatedEmploymentPositionsGet$Json(params?: {
    organization?: null | string[];
    parentSiteKey?: null | string[];
    streams?: null | string[];
    positionType?: null | string[];
    positionOutcome?: null | string[];
    contractTypes?: null | string[];
    referralOutcome?: null | string[];
    pageNumber: 1 | number,
    pageSize: 15 | number,
    sortColumn: '' | string,
    sortDirection: 'asc' | string

  }): Observable<any[]> {

    return this.apiPaginatedEmploymentPositionsGet$Json$Response(params).pipe(
        map((r: StrictHttpResponse<any[]> ) => <any[]>(r.body))
    );
  }

  /**
   * Gets Employer Positions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsGet$Json(params?: {
    reportsToOrganizationUnitKey?: null | Array<number>;
    parentSiteKey?: null | Array<number>;
    streams?: null | Array<number>;
    positionType?: null | Array<string>;
    positionOutcome?: null | Array<string>;
    referralOutcome?: null | Array<string>;
    monitoringPhaseKey?: null | Array<number>;

  }): Observable<Array<EmployerPositionModel>> {

    return this.apiEmploymentEmployersPositionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerPositionModel>>) => r.body as Array<EmployerPositionModel>)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsParentEmployeePositionKeyGet
   */
  static readonly ApiEmploymentEmployersPositionsParentEmployeePositionKeyGetPath = '/api/Employment/employers/positions/{parentEmployeePositionKey}';

  /**
   * Get an Employer Position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsParentEmployeePositionKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsParentEmployeePositionKeyGet$Json$Response(params: {
    parentEmployeePositionKey: number;

  }): Observable<StrictHttpResponse<EmployerPositionModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsParentEmployeePositionKeyGetPath, 'get');
    if (params) {

      rb.path('parentEmployeePositionKey', params.parentEmployeePositionKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerPositionModel>;
      })
    );
  }

  /**
   * Get an Employer Position.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsParentEmployeePositionKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsParentEmployeePositionKeyGet$Json(params: {
    parentEmployeePositionKey: number;

  }): Observable<EmployerPositionModel> {

    return this.apiEmploymentEmployersPositionsParentEmployeePositionKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerPositionModel>) => r.body as EmployerPositionModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet
   */
  static readonly ApiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGetPath = '/api/Employment/employers/positions/referrals/{employerPositionReferralKey}';

  /**
   * Get an Employer Position Referral.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet$Json$Response(params: {
    employerPositionReferralKey: number;

  }): Observable<StrictHttpResponse<EmployerPositionReferralModel>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGetPath, 'get');
    if (params) {

      rb.path('employerPositionReferralKey', params.employerPositionReferralKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployerPositionReferralModel>;
      })
    );
  }

  /**
   * Get an Employer Position Referral.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet$Json(params: {
    employerPositionReferralKey: number;

  }): Observable<EmployerPositionReferralModel> {

    return this.apiEmploymentEmployersPositionsReferralsEmployerPositionReferralKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployerPositionReferralModel>) => r.body as EmployerPositionReferralModel)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet
   */
  static readonly ApiEmploymentEmployersPositionsEmployerPositionKeyReferralsGetPath = '/api/Employment/employers/positions/{employerPositionKey}/referrals';

  /**
   * Get all Employer Position Referrals for an Employer Position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet$Json$Response(params: {
    employerPositionKey: number;

  }): Observable<StrictHttpResponse<Array<EmployerPositionReferralModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsEmployerPositionKeyReferralsGetPath, 'get');
    if (params) {

      rb.path('employerPositionKey', params.employerPositionKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerPositionReferralModel>>;
      })
    );
  }

  /**
   * Get all Employer Position Referrals for an Employer Position.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet$Json(params: {
    employerPositionKey: number;

  }): Observable<Array<EmployerPositionReferralModel>> {

    return this.apiEmploymentEmployersPositionsEmployerPositionKeyReferralsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerPositionReferralModel>>) => r.body as Array<EmployerPositionReferralModel>)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmploymentKeyCaseNotesGet
   */
  static readonly ApiEmploymentParentEmploymentKeyCaseNotesGetPath = '/api/Employment/{parentEmploymentKey}/caseNotes';

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmploymentKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmploymentKeyCaseNotesGet$Json$Response(params: {
    parentEmploymentKey: number;

  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmploymentKeyCaseNotesGetPath, 'get');
    if (params) {

      rb.path('parentEmploymentKey', params.parentEmploymentKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmploymentKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmploymentKeyCaseNotesGet$Json(params: {
    parentEmploymentKey: number;

  }): Observable<Array<CaseNoteModel>> {

    return this.apiEmploymentParentEmploymentKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmploymentKeyCaseNotesPost
   */
  static readonly ApiEmploymentParentEmploymentKeyCaseNotesPostPath = '/api/Employment/{parentEmploymentKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmploymentKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentParentEmploymentKeyCaseNotesPost$Json$Response(params: {
    parentEmploymentKey: number;
    body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmploymentKeyCaseNotesPostPath, 'post');
    if (params) {

      rb.path('parentEmploymentKey', params.parentEmploymentKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmploymentKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentParentEmploymentKeyCaseNotesPost$Json(params: {
    parentEmploymentKey: number;
    body?: CaseNoteModel
  }): Observable<ProcessResponse> {

    return this.apiEmploymentParentEmploymentKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmployerPositionReferralKeyDocumentsGet
   */
  static readonly ApiEmploymentParentEmployerPositionReferralKeyDocumentsGetPath = '/api/Employment/{parentEmployerPositionReferralKey}/documents';

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json$Response(params: {
    parentEmployerPositionReferralKey: number;

  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmployerPositionReferralKeyDocumentsGetPath, 'get');
    if (params) {

      rb.path('parentEmployerPositionReferralKey', params.parentEmployerPositionReferralKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json(params: {
    parentEmployerPositionReferralKey: number;

  }): Observable<Array<DocumentDto>> {

    return this.apiEmploymentParentEmployerPositionReferralKeyDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet
   */
  static readonly ApiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGetPath = '/api/Employment/{parentEmployerPositionReferralKey}/availableDocuments';

  /**
   * Get all documents that can be associated with an employment record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json$Response(params: {
    parentEmployerPositionReferralKey: number;

  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGetPath, 'get');
    if (params) {

      rb.path('parentEmployerPositionReferralKey', params.parentEmployerPositionReferralKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be associated with an employment record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json(params: {
    parentEmployerPositionReferralKey: number;

  }): Observable<Array<DocumentDto>> {

    return this.apiEmploymentParentEmployerPositionReferralKeyAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiEmploymentAvailableDocumentsGet
   */
  static readonly ApiEmploymentAvailableDocumentsGetPath = '/api/Employment/availableDocuments';

  /**
   * Get all documents that can be linked to a new employment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentAvailableDocumentsGet$Json$Response(params?: {
    parentCaseKey?: number;

  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentAvailableDocumentsGetPath, 'get');
    if (params) {

      rb.query('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be linked to a new employment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentAvailableDocumentsGet$Json(params?: {
    parentCaseKey?: number;

  }): Observable<Array<DocumentDto>> {

    return this.apiEmploymentAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionPost
   */
  static readonly ApiEmploymentEmployersPositionPostPath = '/api/Employment/employers/position';

  /**
   * Create a new Employer Position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionPost$Json$Response(params?: {
    ignoreEmployer?: boolean;
    body?: EmployerPositionModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionPostPath, 'post');
    if (params) {

      rb.query('ignoreEmployer', params.ignoreEmployer, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a new Employer Position.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionPost$Json(params?: {
    ignoreEmployer?: boolean;
    body?: EmployerPositionModel
  }): Observable<string> {

    return this.apiEmploymentEmployersPositionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsReferralPost
   */
  static readonly ApiEmploymentEmployersPositionsReferralPostPath = '/api/Employment/employers/positions/referral';

  /**
   * Create a new Employer Position Referral.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsReferralPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionsReferralPost$Json$Response(params?: {
    body?: EmployerPositionReferralModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsReferralPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create a new Employer Position Referral.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsReferralPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionsReferralPost$Json(params?: {
    body?: EmployerPositionReferralModel
  }): Observable<string> {

    return this.apiEmploymentEmployersPositionsReferralPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiEmploymentEmployersPositionsReferralsPost
   */
  static readonly ApiEmploymentEmployersPositionsReferralsPostPath = '/api/Employment/employers/positions/referrals';

  /**
   * Create/Update a collection of Employer Position Referrals.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentEmployersPositionsReferralsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionsReferralsPost$Json$Response(params?: {
    body?: null | Array<EmployerPositionReferralModel>
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentEmployersPositionsReferralsPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create/Update a collection of Employer Position Referrals.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentEmployersPositionsReferralsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmploymentEmployersPositionsReferralsPost$Json(params?: {
    body?: null | Array<EmployerPositionReferralModel>
  }): Observable<string> {

    return this.apiEmploymentEmployersPositionsReferralsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmployerPositionReferralKeyUploadPost
   */
  static readonly ApiEmploymentParentEmployerPositionReferralKeyUploadPostPath = '/api/Employment/{parentEmployerPositionReferralKey}/upload';

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json$Response(params: {
    parentEmployerPositionReferralKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmployerPositionReferralKeyUploadPostPath, 'post');
    if (params) {

      rb.path('parentEmployerPositionReferralKey', params.parentEmployerPositionReferralKey, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentType', params.documentType, {});
      rb.query('comment', params.comment, {});
      rb.query('documentDate', params.documentDate, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json(params: {
    parentEmployerPositionReferralKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<ProcessResponse> {

    return this.apiEmploymentParentEmployerPositionReferralKeyUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost
   */
  static readonly ApiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPostPath = '/api/Employment/{parentEmployerPositionReferralKey}/link/{parentDocumentStoreKey}';

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json$Response(params: {
    parentEmployerPositionReferralKey: number;
    parentDocumentStoreKey: number;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, EmploymentService.ApiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPostPath, 'post');
    if (params) {

      rb.path('parentEmployerPositionReferralKey', params.parentEmployerPositionReferralKey, {});
      rb.path('parentDocumentStoreKey', params.parentDocumentStoreKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json(params: {
    parentEmployerPositionReferralKey: number;
    parentDocumentStoreKey: number;

  }): Observable<number> {

    return this.apiEmploymentParentEmployerPositionReferralKeyLinkParentDocumentStoreKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
