import { Component, Input, ViewChild, ElementRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-postal-field-top',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label | titlecase }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          type="text"
          autocomplete="off"
          mask="A0A 0A0"
          [disableControl]="disabled"
          [dropSpecialCharacters]="true"
          [formControl]="ctrl"
        />
        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
        <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostalFieldTopComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = 'C9C-9C9';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;

  public ngAfterViewChecked(): void {
    // if (this.value !== undefined && this.value.length > 0) this.updateValue();
    // if (this.disabled) this.setDisabled();
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }
}
