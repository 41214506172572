import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Cf2TableConfig, Cf2TableField } from '@core/types/cf2-table-field.type';
import { TranslocoService } from '@ngneat/transloco';

const OVERDUE_DATE = new Date();

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

import * as R from 'remeda';

@Component({
  selector: 'cf2-table',
  template: `
    <ng-container>
      <mat-table [dataSource]="dataSource" matSort cf2Spinner>
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
          <mat-header-cell mat-sort-header *matHeaderCellDef disableClear="true" [matSortActive]="sortColumn">{{
            listColumns[i][1].label
          }}</mat-header-cell>
          <mat-cell *matCellDef="let cell; index as j">
            <ng-container [ngSwitch]="listColumns[i][1].type">
              <ng-container *ngSwitchCase="'date'">
                <span [cf2DueDate]="{ date: cell[column], overdueBy: overdueDate }">
                  {{ cell[column] | formattedDate }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'link'">
                <a (click)="selected.emit(cell)" class="is-linkable"> {{ cell[column] }}</a>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <span>{{ cell[column] }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'stringdate'">
                <!--{{ cell[column] | date: 'd-MMM-y' | uppercase }}-->
                {{ cell[column] | date: 'y-MM-dd' }} <!-- ISO-8601 date format -->
              </ng-container>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Header Row-->
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

        <!-- Detail Rows -->
        <mat-row *matRowDef="let cell; columns: columns"></mat-row>
      </mat-table>
    </ng-container>
    <ng-container *transloco="let t;"> 
    <ng-container *ngIf="isInit && dataSource?.data?.length < 1">
      <div style="text-align: center">
        <p>{{t('NoResultsFound')}}.</p>
      </div>
    </ng-container>
  </ng-container>
  `,
  styleUrls: ['./cf2-table.component.scss'],
  viewProviders: [MatSort],
  exportAs: 'cf2Table',
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class Cf2TableComponent<T extends Object, K extends string> extends MatTableDataSource<T> {
  overdueDate = yesterday;
  @ViewChild(MatSort) matSort: MatSort;
  listColumns: [K, Cf2TableField<K>][];
  @Input() set columnConfig(cfg: Cf2TableConfig<K>) {
    this.listColumns = Array.from(cfg);
    this.columns = Array.from(cfg.keys());
  }
  @Input() sortColumn: string;
  @Output() selected = new EventEmitter<T>();
  dataSource: MatTableDataSource<T>;
  columns: string[];

  isInit = false;

  @Input() set dataSub(data: T[]) {
    if (!data) return;
    this.dataSource = new MatTableDataSource(R.sortBy(data, (itm) => itm[this.sortColumn]));
    this.matSort.start = 'asc';
    this.matSort.active = this.sortColumn;
    this.matSort.disableClear = true;

    this.dataSource.sort = this.matSort;
    this.isInit = true;
  }

  constructor(private service: TranslocoService) {
    super();
  }

  ngOnInit(): void { }
}
