import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { dateBuilder, maxExpiryDate } from '@core/models/date.model';
import { AddressDataService } from '../services/address-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientAddressModel } from '@api/models';
import { AddressLabelsType } from '../models/address-form.model';
import { TranslocoService } from '@ngneat/transloco';

export interface AddressFormConfig {
  showEffectiveDate?: boolean;
  showExpiryDate?: boolean;
  showPrimaryField?: boolean;
  showAddressType?: boolean;
}

@Component({
  selector: 'cf2-address-form',
  template: `
    <div class="flex-row flex-wrap">
      <ng-container *ngIf="addressTypes$ | async as options">
        <!-- need addressTypes at the top before checking Show address type: inorder to get the list of addresstypes -->
        <ng-container *ngIf="config.showAddressType">
          <cf2-select-field-top
            [ctrl]="getFc('code')"
            [label]="labels.code"
            [required]="required.code"
            [disabled]="disabled.code"
            [options]="options"
            [disabled]="isReadOnly"
          ></cf2-select-field-top>
        </ng-container>
      </ng-container>

      <mat-checkbox
        *ngIf="config.showPrimaryField"
        [formControl]="getFc('primary')"
        [required]="required.primary"
        [disableControl]="getFc('primary').value"
        (change)="primaryAddressChanged($event)"
        [disabled]="isReadOnly"
      >
        {{ getFc('primary').value ? 'Primary' : labels.primary }}
      </mat-checkbox>
    </div>
    <div class="flex-row flex-wrap">
      <cf2-input-field-top
        [ctrl]="getFc('unit')"
        [label]="labels.unit"
        [required]="required.unit"
        [small]="false"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-input-field-top
        [ngStyle]="{ flex: '0.9' }"
        [ctrl]="getFc('addressLine1')"
        [label]="labels.addressLine1"
        [required]="required.addressLine1"
        [fullWidth]="true"
        [small]="true"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-input-field-top
        [ngStyle]="{ flex: '0.9' }"
        [ctrl]="getFc('addressLine2')"
        [label]="labels.addressLine2"
        [required]="required.addressLine2"
        [fullWidth]="true"
        [small]="true"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>

      <cf2-input-field-top
        [ctrl]="getFc('city')"
        [label]="labels.city"
        [required]="required.city"
        [disabled]="isReadOnly"
        [small]="true"
      >
      </cf2-input-field-top>

      <ng-container *ngIf="provinceTypes$ | async as options">
        <cf2-select-field-top
          [ctrl]="getFc('provinceCode')"
          [label]="labels.provinceCode"
          [required]="required.provinceCode"
          [disabled]="disabled.provinceCode"
          [options]="options"
          [small]="true"
          [disabled]="isReadOnly"
        ></cf2-select-field-top>
      </ng-container>

      <cf2-postal-field-top
        [ctrl]="getFc('postalCode')"
        [label]="labels.postalCode"
        [required]="required.postalCode"
        [small]="false"
        [disabled]="isReadOnly"
      >
      </cf2-postal-field-top>
    </div>
    <div class="flex-row flex-wrap">
      <cf2-date-field-top
        *ngIf="config.showEffectiveDate"
        [ctrl]="getFc('addressEffectiveDate')"
        [label]="labels.addressEffectiveDate"
        [required]="required.addressEffectiveDate"
        [disabled]="disabled.addressEffectiveDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [disabled]="isReadOnly"
      ></cf2-date-field-top>

      <cf2-date-field-top
        *ngIf="config.showExpiryDate"
        [ctrl]="getFc('addressExpiryDate')"
        [label]="labels.addressExpiryDate"
        [required]="required.addressExpiryDate"
        [disabled]="disabled.addressExpiryDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [disabled]="isReadOnly"
      ></cf2-date-field-top>
    </div>
  `,
})
export class AddressFormComponent extends BaseComponent implements OnInit {
  @Input() set isDisabled(bool: boolean) {
    if (bool) {
      this.fields.fg.disable(FormsFns.formUpdateOpts);
      this.fields.fg.updateValueAndValidity(FormsFns.formUpdateOpts);
    }
  }
  isInit = false;
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: AddressLabelsType;
    disabled: AddressLabelsType;
    required: AddressLabelsType;
  };

  @Input() config: AddressFormConfig = {
    showEffectiveDate: true,
    showExpiryDate: true,
    showPrimaryField: true,
    showAddressType: true,
  };

  @Input() showPrimaryCategoryField = false;

  get isReadOnly() {
    return this.dataSvc.getIsReadOnly();
  }

  addressTypes$ = this.dataSvc.addressTypes$;
  provinceTypes$ = this.dataSvc.provinceTypes$;

  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;
  getFc: (formKey: string) => AbstractControl;
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = maxExpiryDate();

  constructor(private dataSvc: AddressDataService, private service: TranslocoService) {
    super();
  }

  primaryAddressChanged($event) {
    console.log($event);
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-address-form>, you have to initalize address form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc(fg);
    const { provinceCode } = fg.controls;
    provinceCode.patchValue(provinceCode.value ? provinceCode.value : 'ON', FormsFns.formUpdateOpts);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
