import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { EmployerPositionReferralModel } from '@api/models';
import * as R from 'remeda';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to Position Referral Model stores keys type. */
export type EmploymentPositionReferralKeysType =
  | keyof EmployerPositionReferralModel
  | 'select'
  | 'clear'
  | 'hourlyPay'
  | 'employerDisplayName'
  | 'actualStartDateFormatted';

/* tuple setup for employment history config */
export type EmploymentHistoryListHeaderType = [EmploymentPositionReferralKeysType, string];

/* actual display configuration */
const EMPLOYMENT_HISTORY_LIST_HEADERS: readonly EmploymentHistoryListHeaderType[] = [
  ['positionTitle', 'Position'],
  ['employerName', 'EmployersDisplayName'],
  ['positionTypeDescription', 'PositionType'],
  ['startDateFormatted', 'ActualStartDate'],
  ['endDateFormatted', 'ActualEndDate'],
  ['hoursPerWeek', 'Hrs/Wk'],
  ['hourlyRate', 'HrlyRate'],
  ['referralOutcomeDescription', 'PositionReferralOutcome'],
  ['totalClientsReferredToPosition', 'RefNumber'],
];

/* list of columns that can be linked */
const EMPLOYMENT_HISTORY_LIST_LINK_COLUMNS = ['positionTitle'];

/* list of columns that can be sorted */
const EMPLOYMENT_HISTORY_LIST_SORT_COLUMNS = [
  'positionTitle',
  'employerName',
  'positionTypeDescription',
  'startDateFormatted',
  'endDateFormatted',
  'hoursPerWeek',
  'hourlyRate',
  'referralOutcomeDescription',
  'totalClientsReferredToPosition',
];

export interface ListConfig {
  linkable?: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class EmploymentHistoryListData extends DataTableSetup {
  /* set the list headers */
  private employmentListHeaders = [...EMPLOYMENT_HISTORY_LIST_HEADERS];
  private employmentListLinkColumns = [...EMPLOYMENT_HISTORY_LIST_LINK_COLUMNS];
  private employmentListSortColumns = [...EMPLOYMENT_HISTORY_LIST_SORT_COLUMNS];
  private _config: ListConfig[];
  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private employmentHistoryListSetup() {
    const setup = EmploymentHistoryListData.linkableColumns(
      EmploymentHistoryListData.hiddenColumns(
        EmploymentHistoryListData.sortColumns(
          EmploymentHistoryListData.initColumns(this.employmentListHeaders.map((item) => {
            let tmp = item;
            tmp[1] = this.translocoService.translate(tmp[1]);
            return tmp;
          })),
          this.employmentListSortColumns
        ),
        []
      ),
      this.employmentListLinkColumns
    );

    return setup;
  }

  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.employmentHistoryListSetup();
  }
}
