import { Injectable } from '@angular/core';
import { LoggingService } from '@core/services/logging.service';
import { ClientCaseModel, ExpenditureModel, OrganizationModel } from '@api/models';
import {
  CASE_CLIENT_CARD,
  CASE_CLIENT_HEADER,
  CASE_INFO_CARD,
  CASE_ADDRESS_CARD,
  AddressCardComponentField,
} from './case-data.model';

import * as R from 'remeda';

import { DataService } from '@core/services/data.service';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ClientService } from '@api/services/client.service';
import { merge, Observable } from 'rxjs';
import { CaseService, OutcomeService } from '@api/services';
import { Globals } from '../../../../globals';
import { ExpenditureService } from '@api/services/expenditure.service';
import { StrictHttpResponse } from '@api/strict-http-response';
import { UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {casefloDateFormat} from "@shared/models/date-formats.model";

const header = CASE_CLIENT_HEADER;
const clientCard = CASE_CLIENT_CARD;
const caseInfo = CASE_INFO_CARD;
const caseAddressCardConfig = CASE_ADDRESS_CARD;

const configFields: readonly string[] = ['header', 'clientCard', 'caseInfo', 'caseAddressCardConfig'] as const;

export type ConfigFieldsType = typeof configFields[number];

@Injectable({
  providedIn: 'root',
})
export class CaseDataService extends Cf2Forms {
  private header: readonly FormsComponentField[] = header;
  private clientCard: readonly FormsComponentField[] = clientCard;
  private caseInfo: readonly FormsComponentField[] = caseInfo;
  private caseAddressCardConfig: readonly AddressCardComponentField[] = caseAddressCardConfig;

  private _expenditures = [];
  get expenditures() {
    return this._expenditures;
  }
  set expenditures(expenditures: ExpenditureModel[]) {
    this._expenditures = expenditures;
  }

  get displayName() {
    const clientDisplayName = this.globals.clientDisplayName;
    if (!clientDisplayName)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for documents data service',
      });
    return clientDisplayName;
  }

  /* set the case key in local storage */
  set displayName(name: string) {
    this.globals.clientDisplayName = name;
  }
  get caseKey() {
    const key = this.globals.caseKey;
    if (!key)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No caseKey set in globals.caseKey for client',
      });
    return key;
  }

  set expenditureKey(key: number) {
    if (!key || typeof key !== 'number' || isNaN(key)) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'Expenditure key invalid in set CaseDataService.expenditureKey, ' + key,
      });
    }

    this.globals.expenditureKey = key;
  }

  configFields: readonly ConfigFieldsType[] = configFields;

  organizations: OrganizationModel[];

  constructor(
    formbulder: RxFormBuilder,
    private logSvc: LoggingService,
    private dataSvc: DataService,
    public clientSvc: ClientService,
    private caseSvc: CaseService,
    private expSvc: ExpenditureService,
    private globals: Globals,
    private ts:TranslocoService
  ) {
    super(formbulder, ts);
  }

  /**
   * get the field configuration for a form/card for the create client/case components
   * the field configuration can be obtained from the config fields type.
   *
   * @param field - the field type to setup.
   * @param values - values to input into the new formcontrol. this is optional and the
   * form control will have a null value if no value is passed.
   */
  fieldConfig(field: ConfigFieldsType | 'caseAddressCardConfig', values: any = null) {
    const fields = this.configFields.some((field) => field === field)
      ? this[field]
      : this.configFields['header'];
    const mapped = fields.map((field) => ({
      ...field,
      value: values != null ? values[field.key] : null,
    }));
    return this.makeFields(mapped);
  }

  /**
   * The available client options to use for select fields
   */
  get clientOptions() {
    const lookupRecordsToOptionsMap = DataService.lookupToOptions;

    return {
      parentProgramKey: this.dataSvc.programs$.pipe(
        map((result) =>
          result.body.map((val) => ({
            value: val.parentProgramKey,
            description: val.programDescription,
          }))
        )
      ),
      parentProgramStreamKey: this.dataSvc
        .getStreams()

        .pipe(
          map((result) =>
            result.map((val) => ({
              value: val.parentProgramStreamKey,
              description: val.programStreamName,
            }))
          )
        ),
      primaryContactType: this.dataSvc
        .lookupRecords('PreferredContactType')
        .pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      genderCode: this.dataSvc.lookupRecords('GenderType').pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      preferredContact: this.dataSvc
        .lookupRecords('ContactType')
        .pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      languageCode: this.dataSvc.lookupRecords('LanguageType').pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      provinceCode: this.dataSvc.lookupRecords('ProvinceType').pipe(map((record) => lookupRecordsToOptionsMap(record, this.translocoService.getActiveLang()))),
      idCode: this.dataSvc.lookupRecords('IdType').pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      idType: this.dataSvc.lookupRecords('IdType').pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      intakeSourceCode: this.dataSvc
        .lookupRecords('IntakeSource')
        .pipe(map((result) => lookupRecordsToOptionsMap(result, this.translocoService.getActiveLang()))),
      parentSiteKey: this.dataSvc.getOrganizations().pipe(
        tap((val) => {
          this.organizations = val;
        }),
        map((orgs) =>
          R.flattenDeep(
            orgs.map((org) =>
              org.sites.map((site) => ({
                value: site.parentSiteKey,
                description: `${org.organizationName} - ${site.siteName}`,
              }))
            )
          )
        ),

        map((records: { value: string | number; description: string }[]) =>
          R.sortBy<{ value: string | number; description: string }>(records, (record) => record.description)
        ),
        map((records: { value: string; description: string }[]) => R.uniq(records))
      ),
    };
  }

  getClient(key: number) {
    if (!key) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    return this.clientSvc.apiClientParentClientKeyGet$Json({
      parentClientKey: key,
    });
  }

  getCase(key: number) {
    if (!key) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    this.setCaseKey(key);
    return this.caseSvc.apiCaseParentCaseKeyGet$Json({ parentCaseKey: key });
  }

  getExpenditure(key: number) {
    if (!key) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    this.setCaseKey(key);
    return this.expSvc.apiExpenditureFilteredGet$Json({
      org: undefined,
      site: [key.toString()],
      expenditureTypeCode: undefined,
      expenditureStatusCode: undefined,
      pageNumber: 1,
      pageSize: undefined,
      sortColumn: undefined,
      sortDirection: undefined,
      fromDate: undefined,
      toDate: undefined,
      local: 'en-ca' });
  }

  async submitNewClient(body: Partial<ClientCaseModel>) {
    const res = this.clientSvc.apiClientPost$Json$Response({ body }).toPromise();
    return res;
  }

  getEmployees(keys: number) {
    return merge(this.dataSvc.getSiteEmployees(keys));
  }

  setCaseKey(value: number) {
    this.globals.caseKey = value;
  }

  setClientKey(value: number) {
    this.globals.clientKey = value;
  }

  getAllExpendituresByCase(): Observable<StrictHttpResponse<ExpenditureModel[]>> {
    
    const key = this.caseKey;

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllExpenditures in expenditures dataSvc isNan',
      });
    }
    return this.caseSvc.apiCaseParentCaseKeyExpendituresGet$Json$Response({
      parentCaseKey: key,
    });
  }

  public fieldError(field): boolean {
    // return (!field.valid && field.touched);
    let error = false;
    Object.keys(field).forEach((key) => {
      const fg = field[key].fg;
      const fa = field[key].fa;
      if (fa !== undefined && fa !== null && fa.length > 0) {
        //iterate over all fgs in each fa
        for (let i = 0; i < fa.length; i++) {
          error = error || (!fa[i].valid && fa[i].touched);
        }
      }
      error = error || (!fg.valid && fg.touched);
    });

    return error;
  }

  public fieldErrorList(field, idFa = new UntypedFormArray([]), idTypeFa = new UntypedFormArray([])): string[] {
    //if (!this.formError(field)) return [];
    const errors = [];

    Object.keys(field).forEach((fieldKey) => {
      const labels = field[fieldKey].labels;
      const fg = field[fieldKey].fg;
      if (field[fieldKey].fa !== undefined && field[fieldKey].fa !== null) {
        //get errors for form array
        //fa doesn't have foreach :(
        for (let i = 0; i < field[fieldKey].fa.length; i++) {
          this.formErrorList(field[fieldKey].fa[i], labels, errors);
        }
      } else {
        this.formErrorList(fg, labels, errors);
      }
    });

    this.faErrorList(idFa, errors, 'Other ID');
    this.faErrorList(idTypeFa, errors, 'Other ID Type');

    return errors;
  }

  public faError(fa: UntypedFormArray) {
    return !fa.valid && fa.touched;
  }

  public faErrorList(fa: UntypedFormArray = null, errors = null, label = null) {
    if (fa !== null) {
      fa.controls.forEach((ctrl) => {
        if (ctrl.errors !== null && !errors.includes(this.errorMap(ctrl as UntypedFormControl, this.controlLabel('test')))) {
          errors.push(this.errorMap(ctrl as UntypedFormControl, this.controlLabel(label)));
        }
      });
    }
  }

  public formErrorList(fg, labels, errors) {
    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;
      //console.log(control)

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          if (!errors.includes(this.errorMap(control, this.controlLabel(labels[key])))) {
            errors.push(this.errorMap(control, this.controlLabel(labels[key])));
          }
        });
      }
    });
  }

  public cleanField(field, idFa: UntypedFormArray, idTypeFa: UntypedFormArray) {
    //save sin as it gets erased on reset
    // const sin = field.clientCard.fg.value.clientBusinessKey;
    // console.log(sin);
    Object.keys(field).forEach((fieldKey) => {
      const labels = field[fieldKey].labels;
      field[fieldKey].fg.markAsPristine();
      field[fieldKey].fg.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      });
      if (field[fieldKey].fa !== undefined && field[fieldKey].fa !== null) {
        //console.log(field[fieldKey].fa[0])
        //fa doesn't have foreach :(
        for (let i = 0; i < field[fieldKey].fa.length; i++) {
          field[fieldKey].fa[i].markAsPristine();
          field[fieldKey].fa[i].updateValueAndValidity({
            onlySelf: false,
            emitEvent: true,
          });
        }
      }
    });
    idFa.markAsPristine();
    idFa.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    idTypeFa.markAsPristine();
    idTypeFa.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    // field.clientCard.fg.controls.clientBusinessKey.value = sin;
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    if (!fc.errors) return '';
    if (fc.errors.required) return this.translocoService.translate("Message.Error.ControlIsNotValid", {ControlName: label});
    if (fc.errors.duplicateSin) return this.translocoService.translate("DuplicateSin");
    if (fc.errors.sin && fc.errors.sin.message) return this.translocoService.translate("Message.Error.InvalidSin");
    if (fc.errors.minLength && fc.errors.minLength.message) return `${label} ${fc.errors.minLength.message}`;

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translate("Message.Error.IncompletePhoneNumber");
    }

    if (fc.errors.minlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMinimumCharacters", {Minimum: fc.errors.minlength.requiredLength});
    if (fc.errors.maxlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMaximumCharacters", {Maximum: fc.errors.maxlength.requiredLength});
    if (fc.errors.email) return `${label} must be in the format "example@mail.com".`;

    if (fc.errors.name) return `Invalid name.`;

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format ${casefloDateFormat}.`;
  }

  public controlLabel(control: string): string {
    switch (control) {
      case 'Identify As':
        return 'Gender';
      case 'Phone No.':
        return 'Phone Number';
      case 'Street Address 1':
        return 'Address';
    }

    return control;
  }
}
