/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PositionModel } from '../models/position-model';
import { ProcessResponse } from '../models/process-response';

@Injectable({
  providedIn: 'root',
})
export class SecurityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSecurityPositionsGet
   */
  static readonly ApiSecurityPositionsGetPath = '/api/Security/positions';

  /**
   * Get all positions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSecurityPositionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSecurityPositionsGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<PositionModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SecurityService.ApiSecurityPositionsGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PositionModel>>;
      })
    );
  }

  /**
   * Get all positions.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSecurityPositionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSecurityPositionsGet$Json(params?: {

  }): Observable<Array<PositionModel>> {

    return this.apiSecurityPositionsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PositionModel>>) => r.body as Array<PositionModel>)
    );
  }

  /**
   * Path part for operation apiSecurityPositionsSwitchPost
   */
  static readonly ApiSecurityPositionsSwitchPostPath = '/api/Security/positions/switch';

  /**
   * Switch user's position.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSecurityPositionsSwitchPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSecurityPositionsSwitchPost$Json$Response(params?: {
      body?: number
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SecurityService.ApiSecurityPositionsSwitchPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Switch user's position.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSecurityPositionsSwitchPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSecurityPositionsSwitchPost$Json(params?: {
      body?: number
  }): Observable<ProcessResponse> {

    return this.apiSecurityPositionsSwitchPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
