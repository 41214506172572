import {
  Component,
  Input,
  OnInit,
  AfterViewChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputFieldTopComponent } from '../input-field-top/input-field-top.component';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'cf2-typeahead-field-top',
  templateUrl: './typeahead-field-top.component.html',
  styleUrls: ['./typeahead-field-top.component.scss'],
})
export class TypeaheadFieldTopComponent extends InputFieldTopComponent implements OnInit, AfterViewChecked {
  @Input() options: { description: string; value: string }[];
  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Output() lookupSelected = new EventEmitter<string>();
  filteredOptions: Observable<{ description: string; value: string }[]>;
  get isValid() {
    const selection = this.ctrl.value;
    /* TODO: set this to check validity once the valid states are decided upon */
    const filtered = this.options.filter((opt) => opt.value === selection);
    return true;
  }

  displayFn(option: { description: string; value: string }) {
    return option.value || '';
  }

  clearValue() {
    this.ctrl.patchValue('');
  }

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.ctrl.disable();
  }
  private _filter(value: string) {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    const filter = this.options.filter((option) => option.description.toLowerCase().includes(filterValue));

    return filter;
  }
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filteredOptions = this.ctrl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map((value) => this._filter(value))
    );
  }
}