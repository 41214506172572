import { Component, OnInit } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cf2-warning-row',
  template: `
    <div class="flex-row warning-row">
      <fa-icon [icon]="icon"></fa-icon>
      <p>
        <ng-content></ng-content>
      </p>
    </div>
  `,
  styleUrls: ['./warning-row.component.scss'],
})
export class WarningRowComponent implements OnInit {
  icon = faExclamationTriangle;

  constructor() {}

  ngOnInit(): void {}
}
