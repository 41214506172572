import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getToday } from '@shared/models/date-formats.model';
import * as R from 'remeda';
import { LoggingService } from '@core/services/logging.service';
import { BaseComponent } from '@shared/components/base/base.component';
import {
  ModalService,
  CONFIRM_CASE_NOTES_DELETE_DIALOG,
  CANNOT_DELETE_OUTCOME_CASENOTES,
} from '@shared/services/modal.service';
import { BehaviorSubject } from 'rxjs';
import { CaseNotes } from '../models/case-notes.model';
import { CaseNotesListConfig } from '../case-notes-list/case-notes-list.component';
import { CaseNotesDataService } from '@shared/components/case-notes/services/case-notes-data.service';
import { CaseNoteModel } from '@api/models';
import { LinkCaseNotesDialogComponent } from '../link-case-notes-dialog/link-case-notes-dialog.component';
import { OutcomesDataService } from '@modules/outcomes/services/outcomes-data.service';

@Component({
  selector: 'cf2-case-notes-card',
  template: `
    <div class="cf2-case-notes-card" *ngIf="isReady; else loading">
      <div class="action-buttons">
        <!-- Link casenotes Button -->
        <cf2-button (callback)="openLinkableModal()" [disabled]="isReadOnly()">Link Case Notes</cf2-button>
        <!-- Add casenotes Button -->
        <cf2-button (callback)="addForm()" [disabled]="isReadOnly()">Add Case Note</cf2-button>
      </div>
      <ng-container *ngIf="dataSub | async as data">
        <cf2-case-notes-list
          [config]="listConfig"
          [isReady]="isReady"
          [caseKey]="caseKey"
          [data]="data"
          [filteredData]="data"
          [isReadOnly]="isReadOnly()"
          (selectedCaseNote)="showEditForm($event)"
          (delete)="canDelete($event)"
        >
        </cf2-case-notes-list>
      </ng-container>
      <div class="edit-block" *ngIf="showForm">
        <cf2-case-notes-form [fields]="fields" [showPrimaryCategoryField]="showPrimaryCategory"></cf2-case-notes-form>
        <div class="action-buttons">
          <!-- Cancel Button -->
          <cf2-button (callback)="cancelNewCaseNote()">Cancel</cf2-button>
          <!-- Add/Update -->
          <cf2-button *ngIf="!editMode" (callback)="addNew()">Add</cf2-button>
          <cf2-button *ngIf="editMode" [disabled]="!canEdit" (callback)="updateCaseNote()">Update</cf2-button>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <div class="loading">Case Notes Loading...</div>
    </ng-template>
  `,
  styleUrls: ['./case-notes-card.component.scss'],
})
export class CaseNotesCardComponent extends BaseComponent implements OnInit, OnDestroy {
  isReady = false;
  caseKey: number;
  displayName: string;
  @Input() categoryCode: string;
  listConfig: CaseNotesListConfig = {
    showDeleteIcon: true,
  };

  dataSub = new BehaviorSubject<CaseNotes[]>([]);
  showForm = false;
  showPrimaryCategory = false;
  fields: any;
  editMode = false;

  temporaryKey = 0;
  canEdit = false;
  fg: UntypedFormGroup;
  getFc: any;
  constructor(
    private dataSvc: CaseNotesDataService,
    private route: ActivatedRoute,
    private logSvc: LoggingService,
    private modalSvc: ModalService,
    public outcomeSvc: OutcomesDataService
  ) {
    super();
  }

  isReadOnly() {
    // outcomes
    if (this.categoryCode === 'CLAIMS_OUTC') return this.outcomeSvc.isReadOnly;
    else return false;
  }

  generateKey() {
    this.temporaryKey = this.temporaryKey + 0.001;
    return this.temporaryKey;
  }

  initForm(formValue) {
    this.dataSvc.initCaseNoteForm(formValue);
    this.fields = this.dataSvc.getCaseNoteFormFields();
    this.fg = this.fields.fg;

    //setTimeout(() => {
      this.showForm = true;
    //}, 100);
  }

  openLinkableModal() {
    //  Get linked documents and pass to custom dialog
    const linkedCaseNotes = this.dataSvc.getLinkedCaseNotes();
    const modalRef = this.modalSvc.openCustomDialog(LinkCaseNotesDialogComponent, {
      data: {
        linkedCaseNotes,
      },
      panelClass: 'link-dialog',
    });

    modalRef.afterClosed().subscribe((casenotes: CaseNotes[]) => {
      if (casenotes.length > 0) {
        // add / update link documents
        this.dataSvc.addLinkCaseNotes(casenotes);
        // show in documents list table
        this.dataSvc.setCaseNotesListDataSource(this.dataSvc.getCaseNotesData());
      }
    });
  }

  showEditForm(caseNoteKey: number) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedCaseNoteKey === caseNoteKey) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeCaseNoteForm();

    this.editMode = true;
    this.dataSvc.selectedCaseNoteKey = caseNoteKey;

    const caseNoteData = this.dataSvc.getCaseNoteDataByKey(caseNoteKey);
    this.canEdit = !caseNoteData.caseNote?.isLinkedCaseNote && caseNoteData.caseNote.logTypeCode !== 'SYSTEM';

    const caseNoteFormValue: Partial<CaseNoteModel> = {
      comment: caseNoteData.caseNote.comment,
      clientContactDate: caseNoteData.caseNote.clientContactDateFormatted
        ? caseNoteData.caseNote.clientContactDateFormatted
        : null,
      primaryCategoryCode: caseNoteData.caseNote.primaryCategoryCode,
      secondaryCategoryCode: caseNoteData.caseNote.secondaryCategoryCode,
      logTypeCode: caseNoteData.caseNote.logTypeCode,
    };

    setTimeout(() => this.initForm(caseNoteFormValue), 10);
  }

  addForm() {
    // if no default module is set or if on outcome module then show select section code field in the form
    if (!this.categoryCode || this.categoryCode === 'CLAIMS_OUTC') this.showPrimaryCategory = true;

    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeCaseNoteForm();
    // reset selected casenote
    this.dataSvc.selectedCaseNoteKey = null;
    this.editMode = false;

    const formValue: Partial<CaseNoteModel> = {
      primaryCategoryCode: this.categoryCode,
      clientContactDate: getToday(),
    };

    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    this.dataSvc.addCaseNoteData({
      tempKey: this.generateKey(),
      caseNote: this.fg.value,
    });

    this.dataSvc.sanitizeCaseNoteForm();
    this.showForm = false;

    console.log(this.dataSvc.getCaseNotesData());
    // update casenote list component
    this.dataSvc.setCaseNotesListDataSource(this.dataSvc.getCaseNotesData());
  }

  updateCaseNote() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    this.dataSvc.updateCaseNoteData({
      tempKey: this.dataSvc.selectedCaseNoteKey,
      caseNote: this.fg.value,
    });

    this.dataSvc.sanitizeCaseNoteForm();
    this.editMode = false;
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setCaseNotesListDataSource(this.dataSvc.getCaseNotesData());
  }

  cancelNewCaseNote() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeCaseNoteForm();
  }

  canDelete(key: number) {
    let canDeleteNote = true;

    canDeleteNote ? this.deleteCaseNote(key) : '';
  }

  deleteCaseNote(caseNoteKey: number) {
    const confirm_casenotes = CONFIRM_CASE_NOTES_DELETE_DIALOG;

    const note = this.dataSvc.getCaseNoteDataByKey(caseNoteKey);
    // set unlink title, if its a link document
    // #TODO: check casenote link
    if (note?.caseNote?.isLinkedCaseNote) {
      confirm_casenotes.title = 'Unlink case note';
      confirm_casenotes.message = 'Are you sure, you want to unlink case note?';
    }

    // Show confirm modal
    const confirmDeleteDialog = this.modalSvc.openDialog({
      data: confirm_casenotes,
    });

    confirmDeleteDialog.afterClosed().subscribe((res) => {
      if (res && res === true) {
        this.dataSvc.deleteCaseNoteData(caseNoteKey);
        // update casenote list component
        this.dataSvc.setCaseNotesListDataSource(this.dataSvc.getCaseNotesData());
      }
    });
  }

  processResponse(res) {
    const caseNotes: CaseNotes[] = this.dataSvc.mapCaseNotes(res);
    const caseNotesData = this.dataSvc.mapCaseNotesData(caseNotes);

    this.dataSvc.setCaseNotesData(caseNotesData);
    this.dataSvc.setCaseNotesListDataSource(this.dataSvc.getCaseNotesData());

    this.isReady = true;
  }

  ngOnInit() {
    this.caseKey = this.dataSvc.getCaseKey();

    if (!this.categoryCode) {
      throw new Error(
        `${this.constructor.name} Category code is not found. Set in <cf2-case-notes-card [categoryCode]='categoryCode'>`
      );
      return;
    }

    if (!this.dataSvc.caseNotesViewType) {
      throw new Error(
        `${this.constructor.name} casenote view type is not set. Use CaseNotesDataService.initDocumentCard to set`
      );
      return;
    }

    if (this.dataSvc.caseNotesViewType === 'view' || this.dataSvc.caseNotesViewType === 'edit') {
      // Note: Currently Expenditures doesn't have case notes attached to it
      if (this.categoryCode === 'EXPENDITURE') {
        // Get expenditure casenotes when business needs
      } else if (this.categoryCode === 'CLAIMS_OUTC') {
        this.showPrimaryCategory = true;

        const parentOutcomeClaimKey = this.dataSvc.outcomeClaimKey;
        if (!parentOutcomeClaimKey) {
          throw new Error(`${this.constructor.name} outcomeClaimKey is not set`);
          return;
        }

        this.addSubscription(
          this.dataSvc.getAllOutcomeClaimCaseNotes(parentOutcomeClaimKey).subscribe(
            (res) => {
              this.processResponse(res);
            },
            (err) => {
              this.logSvc.logError({
                lvl: 'ERROR',
                mssg: 'Error retriving outcome claim case notes',
              });
              console.log(err);
            }
          )
        );
      }

      // create view
    } else {
      this.isReady = true;
    }

    // casenotes list table data
    this.dataSub = this.dataSvc.caseNotesListDataSub$;
    this.dataSub.subscribe((res) => R.noop());
    this.dataSvc
      .loadSecondarySubCategories$(this.categoryCode)
      .subscribe((data) => (this.dataSvc.caseNoteSubcategoryList = data));
    this.dataSvc.loadContactTypes$().subscribe((data) => (this.dataSvc.caseNoteContactTypeList = data));
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear document form data and state
    this.dataSvc.sanitizeData();
  }
}
