import {
  Component,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  OnInit,
  AfterViewChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LookupService } from '@api/services';
import { CodeTableModel, CodeTables } from '@api/models';
import { MatSelect } from '@angular/material/select';
import * as R from 'remeda';

@Component({
  selector: 'cf2-select-field-top-new',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-container':'input-field-container'
      }"
    >
      <mat-form-field appearance="outline" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }}
          <span *ngIf="required === true && labelVisible === true" class="required-asterisk">*</span>
        </mat-label>

        <mat-select
          matNativeControl
          #select
          multiple="{{ selectMultiple }}"
          id="{{ idField }}"
          name="{{ idField }}"
          autocomplete="off"
          [formControl]="ctrl"
          class="select-field-infix"
          [disableControl]="disabled"
          [disableOptionCentering]="true"
          [class.blue-background]="backgroundColor === 'blue'"
          [class.grey-background]="backgroundColor === 'grey'"
          (selectionChange)="selectionChanged($event)"
        >
          <mat-option *ngFor="let type of codeTableTypes" [value]="type.code">
            {{ type.description }}
          </mat-option>

          <ng-container *ngFor="let type of options">
            <mat-option [value]="type.value" [disabled]="type?.disabled">
              <mat-icon matListIcon *ngIf="type.icon">{{ type.icon }}</mat-icon>
              {{ type.description }}
            </mat-option>
          </ng-container>
          <mat-select-trigger *ngIf="options && options.length > 0">
            <mat-icon matPrefix *ngIf="selectedIcon" class="prefix-icon"> {{ selectedIcon }}</mat-icon>
            <ng-container *ngIf="!selectMultiple">
              {{ selectedValue }}
            </ng-container>
            <ng-container *ngIf="selectMultiple"> Multiple {{ selectedValue }} </ng-container>
          </mat-select-trigger>
        </mat-select>
        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
        <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFieldTopNewComponent implements OnInit, AfterViewChecked {
  @ViewChild('select') input: MatSelect;
  @ViewChild('container') container: ElementRef;
  @Output() onChange = new EventEmitter<string>();
  @Input() options: { value: string; description: string; icon?: string; disabled?: boolean }[];
  @Input() ctrl = new UntypedFormControl();
  @Input() codeTable: string;
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() label: string;
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;
  @Input() icon: string;
  @Input() backgroundColor: 'blue' | 'grey' | 'none' = 'none';
  @Input() selectMultiple = false;

  public codeTableTypes: CodeTableModel[] = [];
  public dataLoaded = false;
  public loaded = false;

  constructor(private lookupService: LookupService) { }

  public ngOnInit(): void {
    if (this.codeTable !== undefined && !this.dataLoaded) {
      const codeTable = (this.codeTable as undefined) as CodeTables;

      this.lookupService
        .apiLookupCodeTableNameGet$Json({
          codeTableName: codeTable,
        })
        .subscribe((result) => {
          this.dataLoaded = true;
          this.codeTableTypes = result;
        });
    }
    if (this.value) {
      this.updateValue(false);
    }
  }

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0 && !this.loaded) {
      this.updateValue(false);
      this.loaded = true;
    }

    if (this.disabled) this.setDisabled();
  }

  public updateValue(emitEvent = true): void {
    console.log('Update Value', this.value);
    const input: MatSelect = this.input as MatSelect;
    if (input) input.value = this.value;
    if (this.ctrl) this.ctrl.patchValue(this.value, { emitEvent });
  }

  public setDisabled(): void {
    const input: MatSelect = this.input as MatSelect;
    this.disabled = true;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setEnabled(): void {
    const input: MatSelect = this.input as MatSelect;
    this.disabled = false;
    input.disabled = false;
  }

  public setFocus(): void {
    const input: MatSelect = this.input as MatSelect;
    input.focus();
  }

  get selectedValue() {
    if (!this.selectMultiple) {
      const selected = R.find(this.options, (opt) => opt.value === this.ctrl.value);
      return selected ? selected.description : null;
    }
  }

  get selectedIcon() {
    if (!this.selectMultiple) {
      const selected = R.find(this.options, (opt) => opt.value === this.ctrl.value);
      return selected?.icon ? selected.icon : null;
    }
  }

  selectionChanged($event: any) {
    this.onChange.emit(this.ctrl.value);
  }
}
