<cf2-header-row [isLoading]="!ready || submitting"
                search="true"
                [ctrl]="searchForm.controls.searchField"
                (keyup)="keyupEvent($event.target.value)">

  <header-title><ng-container *transloco="let t">{{ t(pageTitle) }}</ng-container></header-title>

  <page-content>

    <mat-table #table [dataSource]="dataSource$" matSort (matSortChange)="onSortData($event)">
      <ng-container matColumnDef="positionName">
        <ng-container *transloco="let t"> 
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('Position') }}</mat-header-cell>
        <mat-cell *matCellDef="let pos">
          <ng-container *ngIf="pos.displayName == null">
            <a href="javascript: void(0);" class="linkable" (click)="selected(pos)">{{ pos.positionName }}</a>
          </ng-container>
          <ng-container *ngIf="pos.displayName != null">{{ pos.positionName }}</ng-container>
        </mat-cell>
      </ng-container>
      </ng-container>

      <ng-container matColumnDef="roleDescription">
        <ng-container *transloco="let t"> 

        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('PositionRole') }}</mat-header-cell>
        <mat-cell *matCellDef="let pos">{{ pos.roleDescription }}</mat-cell>
      </ng-container>

      </ng-container>

      <ng-container matColumnDef="organizationName">
        <ng-container *transloco="let t"> 

        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('Organization') }}</mat-header-cell>
        <mat-cell *matCellDef="let pos">{{ pos.organizationName }}</mat-cell>
      </ng-container>
    </ng-container>

      <ng-container matColumnDef="siteName">
        <ng-container *transloco="let t"> 

        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('Site') }}</mat-header-cell>
        <mat-cell *matCellDef="let pos">{{ pos.siteName }}</mat-cell>
      </ng-container>
    </ng-container>

      <ng-container matColumnDef="displayName">
        <ng-container *transloco="let t"> 

        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('User') }}</mat-header-cell>
        <mat-cell *matCellDef="let pos">{{ pos.displayName }}</mat-cell>
      </ng-container>
      </ng-container>


      <!-- Header Row-->
      <mat-header-row *matHeaderRowDef="columnDefs"></mat-header-row>

      <!-- Detail Rows -->
      <mat-row *matRowDef="let row; columns: columnDefs"></mat-row>
    </mat-table>

  </page-content>
</cf2-header-row>

