/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseHeaderModel } from '../models/case-header-model';
import { FinalizeRequest } from '../models/finalize-request';
import { MetadataModel } from '../models/metadata-model';
import { ProcessResponse } from '../models/process-response';
import { TaskApprovalModel } from '../models/task-approval-model';
import { TaskModel } from '../models/task-model';
import { TaskReviewModel } from '../models/task-review-model';
import { TaskSearchModel } from '../models/task-search-model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiWorkflowGet
   */
  static readonly ApiWorkflowGetPath = '/api/Workflow';

  /**
   * Get tasks assigned to a user/employee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<TaskSearchModel>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowGetPath, 'get');

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskSearchModel>>;
      })
    );
  }

  /**
   * Get tasks assigned to a user/employee.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGet$Json(params?: {

  }): Observable<Array<TaskSearchModel>> {

    return this.apiWorkflowGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskSearchModel>>) => r.body as Array<TaskSearchModel>)
    );
  }

  /**
   * Path part for operation apiWorkflowGroupTasksGroupNameGet
   */
  static readonly ApiWorkflowGroupTasksGroupNameGetPath = '/api/Workflow/groupTasks/{groupName}';

  /**
   * Get tasks assigned to a group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowGroupTasksGroupNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGroupTasksGroupNameGet$Json$Response(params: {
    groupName: null | string;

  }): Observable<StrictHttpResponse<Array<TaskSearchModel>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowGroupTasksGroupNameGetPath, 'get');
    if (params) {

      rb.path('groupName', params.groupName, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskSearchModel>>;
      })
    );
  }

  /**
   * Get tasks assigned to a group.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowGroupTasksGroupNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGroupTasksGroupNameGet$Json(params: {
    groupName: null | string;

  }): Observable<Array<TaskSearchModel>> {

    return this.apiWorkflowGroupTasksGroupNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskSearchModel>>) => r.body as Array<TaskSearchModel>)
    );
  }

  /**
   * Path part for operation apiWorkflowRecordLinkCaseHeaderGet
   */
  static readonly ApiWorkflowRecordLinkCaseHeaderGetPath = '/api/Workflow/{recordLink}/caseHeader';

  /**
   * Returns the (parent) case information associated with this task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowRecordLinkCaseHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowRecordLinkCaseHeaderGet$Json$Response(params: {
    recordLink: null | string;

  }): Observable<StrictHttpResponse<CaseHeaderModel>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowRecordLinkCaseHeaderGetPath, 'get');
    if (params) {

      rb.path('recordLink', params.recordLink, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CaseHeaderModel>;
      })
    );
  }

  /**
   * Returns the (parent) case information associated with this task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowRecordLinkCaseHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowRecordLinkCaseHeaderGet$Json(params: {
    recordLink: null | string;

  }): Observable<CaseHeaderModel> {

    return this.apiWorkflowRecordLinkCaseHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CaseHeaderModel>) => r.body as CaseHeaderModel)
    );
  }

  /**
   * Path part for operation apiWorkflowParentTaskKeyGet
   */
  static readonly ApiWorkflowParentTaskKeyGetPath = '/api/Workflow/{parentTaskKey}';

  /**
   * Get Task Metadata.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowParentTaskKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowParentTaskKeyGet$Json$Response(params: {
    parentTaskKey: number;

  }): Observable<StrictHttpResponse<MetadataModel>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowParentTaskKeyGetPath, 'get');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MetadataModel>;
      })
    );
  }

  /**
   * Get Task Metadata.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowParentTaskKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowParentTaskKeyGet$Json(params: {
    parentTaskKey: number;

  }): Observable<MetadataModel> {

    return this.apiWorkflowParentTaskKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MetadataModel>) => r.body as MetadataModel)
    );
  }

  /**
   * Path part for operation apiWorkflowAdminQueueGet
   */
  static readonly ApiWorkflowAdminQueueGetPath = '/api/Workflow/adminQueue';

  /**
   * Get tasks in the admin queue (stuck tasks).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowAdminQueueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowAdminQueueGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<TaskModel>>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowAdminQueueGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskModel>>;
      })
    );
  }

  /**
   * Get tasks in the admin queue (stuck tasks).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowAdminQueueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowAdminQueueGet$Json(params?: {

  }): Observable<Array<TaskModel>> {

    return this.apiWorkflowAdminQueueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskModel>>) => r.body as Array<TaskModel>)
    );
  }

  /**
   * Path part for operation apiWorkflowReviewTaskParentTaskKeyGet
   */
  static readonly ApiWorkflowReviewTaskParentTaskKeyGetPath = '/api/Workflow/reviewTask/{parentTaskKey}';

  /**
   * Get review task detail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowReviewTaskParentTaskKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowReviewTaskParentTaskKeyGet$Json$Response(params: {
    parentTaskKey: number;

  }): Observable<StrictHttpResponse<TaskReviewModel>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowReviewTaskParentTaskKeyGetPath, 'get');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskReviewModel>;
      })
    );
  }

  /**
   * Get review task detail.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowReviewTaskParentTaskKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowReviewTaskParentTaskKeyGet$Json(params: {
    parentTaskKey: number;

  }): Observable<TaskReviewModel> {

    return this.apiWorkflowReviewTaskParentTaskKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskReviewModel>) => r.body as TaskReviewModel)
    );
  }

  /**
   * Path part for operation apiWorkflowApprovalTaskParentTaskKeyGet
   */
  static readonly ApiWorkflowApprovalTaskParentTaskKeyGetPath = '/api/Workflow/approvalTask/{parentTaskKey}';

  /**
   * Get approval task detail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowApprovalTaskParentTaskKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowApprovalTaskParentTaskKeyGet$Json$Response(params: {
    parentTaskKey: number;

  }): Observable<StrictHttpResponse<TaskApprovalModel>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowApprovalTaskParentTaskKeyGetPath, 'get');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskApprovalModel>;
      })
    );
  }

  /**
   * Get approval task detail.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowApprovalTaskParentTaskKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowApprovalTaskParentTaskKeyGet$Json(params: {
    parentTaskKey: number;

  }): Observable<TaskApprovalModel> {

    return this.apiWorkflowApprovalTaskParentTaskKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskApprovalModel>) => r.body as TaskApprovalModel)
    );
  }

  /**
   * Path part for operation apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost
   */
  static readonly ApiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPostPath = '/api/Workflow/groupTask/{parentTaskKey}/assign/{parentEmployeeKey}';

  /**
   * Assign tasks in a group queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost$Json$Response(params: {
    parentTaskKey: number;
    parentEmployeeKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPostPath, 'post');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});
      rb.path('parentEmployeeKey', params.parentEmployeeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Assign tasks in a group queue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost$Json(params: {
    parentTaskKey: number;
    parentEmployeeKey: number;

  }): Observable<ProcessResponse> {

    return this.apiWorkflowGroupTaskParentTaskKeyAssignParentEmployeeKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost
   */
  static readonly ApiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPostPath = '/api/Workflow/adminQueue/{parentTaskKey}/{parentEmployeeKey}';

  /**
   * Assign tasks in the admin queue (stuck tasks).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost$Json$Response(params: {
    parentTaskKey: number;
    parentEmployeeKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPostPath, 'post');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});
      rb.path('parentEmployeeKey', params.parentEmployeeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Assign tasks in the admin queue (stuck tasks).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost$Json(params: {
    parentTaskKey: number;
    parentEmployeeKey: number;

  }): Observable<ProcessResponse> {

    return this.apiWorkflowAdminQueueParentTaskKeyParentEmployeeKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiWorkflowReviewTaskPost
   */
  static readonly ApiWorkflowReviewTaskPostPath = '/api/Workflow/reviewTask';

  /**
   * Update/complete review task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowReviewTaskPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowReviewTaskPost$Json$Response(params?: {
      body?: TaskReviewModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowReviewTaskPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Update/complete review task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowReviewTaskPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowReviewTaskPost$Json(params?: {
      body?: TaskReviewModel
  }): Observable<ProcessResponse> {

    return this.apiWorkflowReviewTaskPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiWorkflowApprovalTaskPost
   */
  static readonly ApiWorkflowApprovalTaskPostPath = '/api/Workflow/approvalTask';

  /**
   * Update/complete approval task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowApprovalTaskPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowApprovalTaskPost$Json$Response(params?: {
      body?: TaskApprovalModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowApprovalTaskPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Update/complete approval task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowApprovalTaskPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowApprovalTaskPost$Json(params?: {
      body?: TaskApprovalModel
  }): Observable<ProcessResponse> {

    return this.apiWorkflowApprovalTaskPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiWorkflowFinalizeTaskParentTaskKeyPost
   */
  static readonly ApiWorkflowFinalizeTaskParentTaskKeyPostPath = '/api/Workflow/finalizeTask/{parentTaskKey}';

  /**
   * Complete finalize task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWorkflowFinalizeTaskParentTaskKeyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowFinalizeTaskParentTaskKeyPost$Response(params: {
    parentTaskKey: number;
      body?: FinalizeRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WorkflowService.ApiWorkflowFinalizeTaskParentTaskKeyPostPath, 'post');
    if (params) {

      rb.path('parentTaskKey', params.parentTaskKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Complete finalize task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWorkflowFinalizeTaskParentTaskKeyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWorkflowFinalizeTaskParentTaskKeyPost(params: {
    parentTaskKey: number;
      body?: FinalizeRequest
  }): Observable<void> {

    return this.apiWorkflowFinalizeTaskParentTaskKeyPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
