/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiApplicationStatusGet
   */
  static readonly ApiApplicationStatusGetPath = '/api/ApplicationStatus';

  /**
   * Return list of status checks results.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Return list of status checks results.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationStatusApplicationGet
   */
  static readonly ApiApplicationStatusApplicationGetPath = '/api/ApplicationStatus/application';

  /**
   * Returns Ok if the application is running.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusApplicationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusApplicationGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusApplicationGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Returns Ok if the application is running.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusApplicationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusApplicationGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusApplicationGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationStatusDatabaseGet
   */
  static readonly ApiApplicationStatusDatabaseGetPath = '/api/ApplicationStatus/database';

  /**
   * Returns Ok if can connect to the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusDatabaseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusDatabaseGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusDatabaseGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Returns Ok if can connect to the database.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusDatabaseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusDatabaseGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusDatabaseGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationStatusCamsGet
   */
  static readonly ApiApplicationStatusCamsGetPath = '/api/ApplicationStatus/cams';

  /**
   * Returns Ok if can connect to CAMS API interface.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusCamsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusCamsGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusCamsGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Returns Ok if can connect to CAMS API interface.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusCamsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusCamsGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusCamsGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationStatusStorageGet
   */
  static readonly ApiApplicationStatusStorageGetPath = '/api/ApplicationStatus/storage';

  /**
   * Returns Ok if can connect to Azure blob storage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusStorageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusStorageGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusStorageGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Returns Ok if can connect to Azure blob storage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusStorageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusStorageGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusStorageGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationStatusReportingGet
   */
  static readonly ApiApplicationStatusReportingGetPath = '/api/ApplicationStatus/reporting';

  /**
   * Returns Ok if can connect to Azure blob storage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationStatusReportingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusReportingGet$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationStatusService.ApiApplicationStatusReportingGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Returns Ok if can connect to Azure blob storage.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationStatusReportingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationStatusReportingGet(params?: {

  }): Observable<void> {

    return this.apiApplicationStatusReportingGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
