import { Values } from './helpers.model';
import { Params } from './tuples.model';

export const PAGE_SIZE_OPTIONS = {
  small: [10, 25, 100],
  default: [5, 10, 15],
} as const;

export type PageSizeOptions = Values<typeof PAGE_SIZE_OPTIONS>;

export function paginatorConfigFn(
  opts: {
    length?: number;
    pageSize?: PageSizeOptions[number];
    pageSizeOptions?: readonly [number, number, number];
  } = {}
) {
  const {
    length = PAGE_SIZE_OPTIONS.default[0],
    pageSizeOptions = PAGE_SIZE_OPTIONS.default,
    pageSize = PAGE_SIZE_OPTIONS.default[0],
  } = opts;
  return {
    length,
    pageSize,
    pageSizeOptions,
  };
}
export type PaginatorConfig = ReturnType<typeof paginatorConfigFn>;
export type PaginatorParams = Params<typeof paginatorConfigFn>;
