import { Injectable } from '@angular/core';
import { Globals } from '../../../globals';
import { CaseService } from '@api/services';
import { LoggingService } from '@core/services/logging.service';
import { StrictHttpResponse } from '@api/strict-http-response';
import { Observable } from 'rxjs';
import { EmployerPositionReferralModel } from '@api/models';

@Injectable({
  providedIn: 'root',
})
export class EmploymentHistoryDataService {
  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }
  constructor(private globals: Globals, private caseSvc: CaseService, private logSvc: LoggingService) {}

  /* State handlers - state is loaded from local storage */
  /* get the case key from local storage */
  getCaseKey() {
    const caseKey = this.globals.caseKey;
    if (!caseKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for EmploymentHistoryDataService',
      });
    return caseKey;
  }

  /* set the case key in local storage */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }
  setEmployerPositionReferralKey(key: number) {
    this.globals.employerPositionReferralKey = key;
  }

  getAllReferrals(): Observable<StrictHttpResponse<EmployerPositionReferralModel[]>> {
    const key = this.getCaseKey();

    if (isNaN(key)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAllReferrals in employment-history dataSvc is Nan',
      });
    }
    return this.caseSvc.apiCaseParentCaseKeyReferralsGet$Json$Response({
      parentCaseKey: key,
    });
  }
}
