import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpenditureModel } from '@api/models';
import { FinalizeExpenditureListData } from '@modules/expenditures/models/finalize-expenditure-datasource';
import { ExpendituresDataService } from '@modules/expenditures/services/expenditures-data.service';
import { ListConfig } from '@shared/components/documents/documents-list/documents-datasource';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-finalize-expenditure',
  template: `
    <h2 mat-dialog-title>Finalize</h2>
    <mat-dialog-content>
      <div class="flex-col">
        <span>Please confirm if each item below is recoverable or not</span>

        <mat-table [dataSource]="data">
          <ng-container *ngFor="let column of columns; index as i" matColumnDef="{{ column }}">
            <mat-header-cell *matHeaderCellDef>
              {{ columnConfig[i].label }}
            </mat-header-cell>
            <mat-cell *matCellDef="let cell; index as j">
              <span *ngIf="columnConfig[i].key !== 'recoverableIndicator'">{{ cell[column] }}</span>

              <!-- TODO: disable and link to indicator -->
              <mat-radio-group
                [(ngModel)]="cell.recoverableIndicator"
                *ngIf="columnConfig[i].key === 'recoverableIndicator'"
              >
                <mat-radio-button [value]="true" style="margin-right: 15px;" [selected]="cell.recoverableIndicator"
                  >Yes</mat-radio-button
                >
                <mat-radio-button [value]="false" [selected]="!cell.recoverableIndicator">No</mat-radio-button>
              </mat-radio-group>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let cell; columns: columns"></mat-row>
        </mat-table>
        <!-- <div style="margin-top: 20px;">
          <cf2-input-field-top
            matInput
            [(ngModel)]="comment"
            [label]="'Employer Reference Number'"
            fullWidth="true"
          ></cf2-input-field-top>
        </div> -->
        <div *ngIf="dataSvc.expenditure.expenditureTypeCode==='EMPLOYER'" class="employerReferenceNumber" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          <label>Employer Reference Number</label>
          <input style="height: 40px;"
            matInput
            [(ngModel)]="employerReferenceNumber"
            class="comment-field"
            type="number"
          />
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          <label>Comment</label>
          <textarea
            rows="3"
            matInput
            [(ngModel)]="comment"
            class="comment-field"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="finalize()" [disabled]="!isValid">Submit</button>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./finalize-expenditure.component.scss'],
})
export class FinalizeExpenditureComponent {
  columnConfig: ListConfig[];
  columnTuples: FinalizeExpenditureListData[];
  columns: string[];
  comment: string;
  employerReferenceNumber: string;

  get isValid() {
    return this.dataSvc.expenditure.expenditureTypeCode === 'EMPLOYER' ? this.employerReferenceNumber === undefined ? false : this.employerReferenceNumber.toString().length === 11 : true;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExpenditureModel,
    public dataSvc: ExpendituresDataService,
    public dialogRef: MatDialogRef<FinalizeExpenditureComponent>,
    private translocoService: TranslocoService
  ) {
    if (!this.data) {
      throw new Error('No expenditure added to FinalizeExpenditureComponent');
    }

    const ds = new FinalizeExpenditureListData(this.translocoService);
    this.columns = ds.columns;
    this.columnConfig = ds.config;
  }

  finalize() {
    return this.dialogRef.close({
      expenditureItems: this.data,
      comment: this.comment,
      employerReferenceNumber: this.employerReferenceNumber.toString(),
      close: true,
    });
  }
}
