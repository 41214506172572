import { Component, OnInit, Input } from '@angular/core';
import { AppIcon } from 'src/app/core/models/icons.model';
import { DevHelpers } from 'src/app/core/models/dev-helpers.model';

const randomId = DevHelpers.randomString(6);

@Component({
  selector: 'app-icon-button',
  template: `
    <button
      mdcRipple="false"
      mdcIconButton
      class="material-icons"
      id="{{ id }}"
      [iconOn]="on"
      [iconOff]="off"
      [disabled]="disabled"
      [(on)]="isActive"
      dense="dense">
    </button>
  `,
  styleUrls: ['./icon-button.component.css'],
})
export class IconButtonComponent implements OnInit {
  @Input() isActive = false;
  @Input() disabled = false;
  @Input() id: string;
  @Input() on: AppIcon = 'expand_more';
  @Input() off: AppIcon = 'expand_less';
  @Input() dense: boolean;

  constructor() {
    this.id = this.id ? this.id : randomId();
  }

  ngOnInit(): void {}
}
