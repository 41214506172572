import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SUBMENU_NAV, NavListModule, NavListItem, getBaseUrlClean } from 'src/app/core/services/navigation.service';
import { Globals } from 'src/app/globals';
import { ICON_CONFIG } from '@core/models/icons.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserStateService } from '@core/services/user-state.service';
import { NavigationStateService } from '@core/services/navigation-state.service';
import { MsalService } from '@azure/msal-angular';
import { TranslocoService } from '@ngneat/transloco';
import {IntegrationDashboardDataService} from "@modules/integration-dashboard/services/integration-dashboard-data.service";

const profileIcon = ICON_CONFIG.profile;
const langIcon = ICON_CONFIG.filterClose;

@Component({
  selector: 'app-navigation',
  template: `
    <ng-container *ngIf="navigationStateService.isLoggedIn">
      <div #viewport class="app-container" [class.production]="globals.isProduction">
        <header
          mdcTopAppBar
          [ngClass]="globals.applicationTitle == 'CaseFLO Training' ? 'training-app-header' : 'app-header'"
          dense
          [viewport]="viewport"
          [fixedAdjust]="content"
          mdcElevation="6"
        >
          <div mdcTopAppBarRow>
            <section mdcTopAppBarSection alignStart>
              <a
                href="javascript:void(0)"
                mdcTopAppBarNavIcon
                [attr.aria-label]="open == true ? 'Collapse app sidebar navigation' : 'Expand app sidebar navigation'"
                class="material-icons"
                (click)="open = !open"
              >
                <img
                  src="assets/images/caseflo.png"
                  width="25"
                  height="25"
                  class="d-inline-block align-top"
                  alt="{{ navigationStateService.applicationSettings?.applicationTitle }}"
                />
              </a>
              <a routerLink="/home" class="app-header appMainLink">
                <span mdcTopAppBarTitle>{{ navigationStateService.applicationSettings?.applicationTitle }}</span>
              </a>
            </section>

            <section mdcTopAppBarSection alignEnd>
              <span
                ><ng-container *transloco="let t">{{ t('Language') }}</ng-container></span
              >

              <a
                href="javascript:void(0)"
                mdcTopAppBarAction
                class="material-icons header-icon"
                [label]="'activeLanguage'"
                [matMenuTriggerFor]="langMenu"
                >{{ langIcon }}</a
              >
            </section>

            <section mdcTopAppBarSection alignEnd>
              <!-- <cf2-backend-status></cf2-backend-status> -->

              {{ navigationStateService.user.displayName }} {{ navigationStateService.user.userRoleAbbreviation }}:
              {{ navigationStateService.user.siteName }}

              <a
                href="javascript:void(0)"
                mdcTopAppBarAction
                class="material-icons header-icon"
                [label]="'Settings' | transloco"
                [matMenuTriggerFor]="authMenu"
                >{{ profileIcon }}</a
              >
            </section>
          </div>
        </header>

        <div #content class="content">
          <aside class="mdc-drawer" [class.small]="open === false" mdcElevation="2">
            <div class="mdc-drawer__content" [class.small]="open === false">
              <nav class="mdc-list">
                <ul
                  mdcList
                  [dense]="true"
                  style="padding: 0 !important;"
                  class="module-menu"
                  [class.small]="open === false"
                >
                  <ng-container *ngIf="userStateSvc.modules$ | async as sideNavLinks">
                    <ng-container *ngFor="let module of sideNavLinks">
                      <li>
                        <a
                          mdcListItem
                          class="mdc-list-item"
                          [class.small]="open === false"
                          [routerLink]="[module.path]"
                          routerLinkActive="selected"
                          [selected]="rla.isActive"
                          #rla="routerLinkActive"
                        >
                          <i mdcListItemGraphic class="material-icons" title="{{ module.label }}">{{ module.icon }}</i>
                          <div *ngIf="module.key === 'integration'" class="width-100">
                            <div [class.label-hidden]="open === false" class="badge-spacing"
                                 matBadge="{{globals.dashCount}}" [matBadgeHidden]="!module.hasBadge">{{ module.label }}
                            </div>
                          </div>
                          <div *ngIf="module.key !== 'integration'">
                            <div [class.label-hidden]="open === false" class="badge-spacing"
                                 matBadge="{{module.badgeCount}}" [matBadgeHidden]="!module.hasBadge">{{ module.label }}
                            </div>
                          </div>
                        </a>

                        <!-- Level 2 -->
                        <ul mdcList [dense]="true" class="children" style="padding: 0 !important;" *ngIf="rla.isActive">
                          <!-- [class.children-not-visible]="!childActive(module)" [class.children-visible]="childActive(module)" -->
                          <ng-container *ngFor="let item of module.children">
                            <li>
                              <a
                                class="mdc-list-item children"
                                [class.small]="open === false"
                                [routerLink]="[module.path, item.path]"
                                routerLinkActive="subSelected"
                                #childRla="routerLinkActive"
                              >
                                <i
                                  mdcListItemGraphic
                                  *ngIf="!item.fontAwesomeIcon"
                                  class="material-icons children-icon"
                                  title="{{ item.label }}"
                                  >{{ item.icon }}</i
                                >
                                <i
                                  mdcListItemGraphic
                                  *ngIf="item.fontAwesomeIcon"
                                  class="fa fa-{{ item.icon }} fa-icon children-icon"
                                  aria-hidden="true"
                                  title="{{ item.label }}"
                                ></i>

                                <div class="children" [class.label-hidden]="open === false">{{ item.label }}</div>
                              </a>

                              <!-- Level 3 -->
                              <ul
                                mdcList
                                [dense]="true"
                                class="children"
                                style="padding: 0 !important;"
                                *ngIf="childRla.isActive"
                              >
                                <!-- [class.children-not-visible]="!childActive(module)" [class.children-visible]="childActive(module)" -->
                                <ng-container *ngFor="let itemChild of item.children">
                                  <li>
                                    <a
                                      *ngIf="itemChild.external"
                                      class="mdc-list-item children item-children"
                                      [href]="getExternalUrl(itemChild.path)"
                                      target="_blank"
                                    >
                                      <i
                                        mdcListItemGraphic
                                        *ngIf="!itemChild.fontAwesomeIcon"
                                        class="material-icons children-icon"
                                        title="{{ itemChild.label }}"
                                        >{{ itemChild.icon }}</i
                                      >
                                      <i
                                        mdcListItemGraphic
                                        *ngIf="itemChild.fontAwesomeIcon"
                                        class="fa fa-{{ itemChild.icon }} fa-icon children-icon"
                                        aria-hidden="true"
                                        title="{{ itemChild.label }}"
                                      ></i>

                                      <div class="children" [class.label-hidden]="open === false">
                                        {{ itemChild.label }}
                                      </div>
                                    </a>
                                    <a
                                      *ngIf="!itemChild.external"
                                      class="mdc-list-item children item-children"
                                      [class.small]="open === false"
                                      [routerLink]="
                                        itemChild?.exactPath
                                          ? [itemChild.path]
                                          : [module.path, item.path, itemChild.path]
                                      "
                                      routerLinkActive="subSelected"
                                    >
                                      <i
                                        mdcListItemGraphic
                                        *ngIf="!itemChild.fontAwesomeIcon"
                                        class="material-icons children-icon"
                                        title="{{ itemChild.label }}"
                                        >{{ itemChild.icon }}</i
                                      >
                                      <i
                                        mdcListItemGraphic
                                        *ngIf="itemChild.fontAwesomeIcon"
                                        class="fa fa-{{ itemChild.icon }} fa-icon children-icon"
                                        aria-hidden="true"
                                        title="{{ itemChild.label }}"
                                      ></i>

                                      <div class="children" [class.label-hidden]="open === false">
                                        {{ itemChild.label }}
                                      </div>
                                    </a>
                                  </li>
                                </ng-container>
                              </ul>
                              <!-- /Level 3 -->
                            </li>
                          </ng-container>
                        </ul>
                        <!-- /Level 2 -->
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </nav>
            </div>
          </aside>

          <main class="main">
            <router-outlet></router-outlet>
            <!-- <ng-content></ng-content> -->
          </main>
        </div>
      </div>
    </ng-container>

    <!-- Define ng template for nested menu items  -->

    <mat-menu #langMenu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item (click)="switchLanguage('en')">English</button>
        <button mat-menu-item (click)="switchLanguage('fr')">Français</button>
      </ng-template>
    </mat-menu>

    <mat-menu #authMenu="matMenu">
      <ng-template matMenuContent>
        <mat-nav-list>
          <ng-container>
            <mat-list-item (click)="msalService.logoutRedirect().subscribe()">Logout</mat-list-item>
          </ng-container>
          <div mat-subheader *ngIf="headerMenuLinks.length > 0">Application Settings</div>
          <mat-list-item *ngFor="let link of headerMenuLinks">
            <a *ngIf="link.external == false" matLine mat-menu-item (click)="navigate(link.path, {})">{{
              link.label
            }}</a>
            <a *ngIf="link.external == true" matLine mat-menu-item href="{{ link.path }}">{{ link.label }}</a>
          </mat-list-item>
        </mat-nav-list>
      </ng-template>
    </mat-menu>

    <ng-template #loggedOut></ng-template>
  `,
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends BaseComponent implements OnDestroy, OnInit {
  public subscriptions: Subscription[] = [];
  public applicationTitle = '';
  public open = true;
  public isLoggedIn = false;
  public activeModule: string;
  public activeSubModule: string;
  public profileIcon: string;
  public langIcon: string;
  public activeLang: string = 'EN';
  // public sideNavLinks: NavListModule[];
  public headerMenuLinks: NavListItem[];

  constructor(
    public globals: Globals,
    public msalService: MsalService,
    public userStateSvc: UserStateService,
    public navigationStateService: NavigationStateService,
    private transloco: TranslocoService,
    private intDashSvc: IntegrationDashboardDataService
  ) {
    super();
    this.activeLang = 'asdfasdf';
    // if this is "home" or starting the app -> then reset all keys & navigation
    // listen for navigation update events
  }

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.sideNavLinks = MODULE_CONFIG;
    this.headerMenuLinks = SUBMENU_NAV;
    this.profileIcon = profileIcon;
    this.langIcon = langIcon;
    const isLoggedIn = !!this.msalService.instance.getActiveAccount();
    this.isLoggedIn = isLoggedIn;
    this.activeLang = 'this.transLoco.getActiveLang()';
    this.transloco.selectTranslation('fr').subscribe((x) => (this.activeLang = this.transloco.getActiveLang()));

    this.intDashSvc.capturedIntegrationCount$.subscribe((count) => {
      this.globals.dashCount = count;
    })

    await this.userStateSvc.modules$.forEach((item) => {
      item.forEach((nav) => {
        if (nav.key == 'integration') {

        }
      })
    });
  }

  public ngOnDestroy(): void {
    // unsubscribe from service events
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public isActive(module: string): boolean {
    if (module !== undefined && module.startsWith('/')) module = module.substring(1, module.length);

    if (this.globals.activeModule !== undefined && this.activeModule === undefined)
      this.activeModule = this.globals.activeModule;

    if (module === undefined || this.activeModule === undefined) return false;
    return module === this.activeModule;
  }

  public isSubactive(module: string): boolean {
    if (module !== undefined && module.startsWith('/')) module = module.substring(1, module.length);

    if (this.globals.activeSubModule !== undefined && this.activeSubModule === undefined)
      this.activeSubModule = this.globals.activeSubModule;

    if (module === undefined || this.activeSubModule === undefined) return false;
    return module === this.activeSubModule;
  }

  public childActive(module: NavListModule): boolean {
    if (module === undefined || module.children === undefined || module.children.length === 0) return false;

    // is this module active?
    if (module.key !== this.activeModule) return false;

    // do we need to have a parent key?
    let parentRequired = '';

    module.children.forEach((child) => {
      if (child.parent !== undefined && child.parent.length > 0) {
        parentRequired = child.parent;
      }
    });

    switch (parentRequired.toLowerCase()) {
      case '': {
        return true;
      }
      case 'casekey': {
        if (this.globals.caseKey > 0) return true;
      }
    }

    return false;
  }

  getExternalUrl(path: string) {
    return getBaseUrlClean() + path;
  }

  switchLanguage(lang: string) {
    localStorage.setItem('LangPref', lang);
    window.location.reload();
  }
}
