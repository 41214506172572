import { Component, OnInit } from '@angular/core';
import { CaseEmployeeModel } from '@api/models';
import { LoggingService } from '@core/services/logging.service';
import { MultipleEntriesComponent } from '@shared/components/multiple-entries/multiple-entries-card.component';
import { CaseEmployeesListConfig } from '../case-employees-list/case-employees-list.component';
import { CaseEmployeesDataService } from '../services/case-employees-data.service';
import { CaseEmployeeLabelsType } from '../models/case-employee-form.model';
import * as R from 'remeda';
import { CONFIRM_REMOVE_EMPLOYEE_DIALOG, ModalService } from '@shared/services/modal.service';
import { Router } from '@angular/router';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-case-employees-card',
  template: `
<ng-container *transloco="let t"> 
  
  <div class="cf2-multiple-entries-card flex-col">
  <div class="action-buttons flex-row flex-between">
    <h3>{{ t('Employees') }}</h3>
        <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addForm()">{{ t('Add') }}</button>
      </div>
      
      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub$ | async as data">
          <cf2-case-employees-list
          [isready]="isReady"
          [config]="listConfig"
          [data]="data"
          [filteredData]="data"
          (selectedItem)="showEditEntityForm($event)"
          (delete)="canDelete($event)"
          ></cf2-case-employees-list>
        </ng-container>
      </div>
      
      <div class="edit-block flex-col" *ngIf="showForm">
        <cf2-case-employees-form [fields]="fields"></cf2-case-employees-form>
        <div class="action-buttons flex-row flex-end">
          <button mat-button class="button--secondary" (click)="cancel()">{{ t('Cancel') }}</button>
          <button mat-button *ngIf="!editMode && !isReadOnly" class="button--secondary" (click)="addNew()">{{ t('Save') }}</button>
        </div>
      </div>
    </div>
    </ng-container>
  `,
})
export class CaseEmployeesCardComponent
  extends MultipleEntriesComponent<CaseEmployeeModel, CaseEmployeeLabelsType>
  implements OnInit {
  listConfig: CaseEmployeesListConfig = {
    showDeleteIcon: true,
  };

  constructor(
    private dataSvc: CaseEmployeesDataService,
    router: Router,
    modalSvc: ModalService,
    private translocoService: TranslocoService,
    clientCaseDataSvc: ClientCaseDataService
    ) {
      super(router, modalSvc, clientCaseDataSvc);
    }

    initForm(formValue = {}) {
      const form = this.dataSvc.initForm(formValue);
      this.fields = form.fields;
    this.fg = this.fields.fg;

    setTimeout(() => {
      this.showForm = true;
    }, 100);
  }

  showEditEntityForm(key: number) {
    const entityData = this.dataSvc.getEntityDataByKey(key);

    const formValue: Partial<CaseEmployeeModel> = {
      caseRoleCode: 'SC', // default to secondary consultant
    };

    this.showEditForm(key, formValue);
  }

  showEditForm(key: number, formValue: Partial<CaseEmployeeModel>) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedKey === key) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeForm();

    this.editMode = true;
    this.dataSvc.selectedKey = key;
    // TODO: remove timeout
    setTimeout(() => this.initForm(formValue), 10);
  }

  addForm() {
    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    // reset selected casenote
    this.dataSvc.selectedKey = null;
    this.editMode = false;

    // set default values for new entity
    const formValue: Partial<CaseEmployeeModel> = {};
    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }
    this.dataSvc.entityData.map(item => {
      item.entity.caseRoleCode = 'SC';
      item.entity.caseRoleDescription = 'Secondary Consultant';
      return item;
    });

    this.dataSvc.addEntityData({
      tempKey: this.fg.value.parentEmployeeKey, // we already have a key so dont need to generate
      entity: {
        ...this.fg.value,
        caseRoleCode: 'PC', 
        caseRoleDescription: 'Primary Consultant'
      }
    });

    this.dataSvc.sanitizeForm();
    this.showForm = false;

    // update list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }

  canDelete(key: number) {
    this.deleteEntity(key);
  }

  deleteEntity(key: number) {
    const confirm_modal_data = CONFIRM_REMOVE_EMPLOYEE_DIALOG;

    // Show confirm modal
    const confirmDeleteDialog = this.modalSvc.openDialog({
      data: confirm_modal_data,
    });

    confirmDeleteDialog.afterClosed().subscribe((res) => {
      if (res && res === true) {
        this.dataSvc.deleteEntityData(key);
        // update document list component
        this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

        // save all client case form data to backend
        this.save();
      }
    });
  }

  cancel() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeForm();
  }

  processResponse(res) {
    //const entity: ClientAddressModel[] = this.dataSvc.mapCaseNotes(res);
    const entityData = this.dataSvc.mapEntityData(res);

    this.dataSvc.setEntityData(entityData);
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnInit() {
    this.dataSub$ = this.dataSvc.entityListDataSub$;

    const sub = this.dataSvc.caseEmployeeRoles$.subscribe((res) => console.log('Init Case Employee Roles'));

    this.addSubscription(sub);
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear form data and state
    this.dataSvc.sanitizeData();
  }
}
