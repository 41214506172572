import { Component, Input, ViewChild, ElementRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LONG_STRING_LENGTH } from '@core/models/cf2-validators.model';

@Component({
  selector: 'cf2-textarea-field-top',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <textarea
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          autocomplete="off"
          rows="{{ rows }}"
          style="resize: none;"
          [formControl]="ctrl"
        >
        </textarea>
        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
        <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextareaFieldTopComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() icon: string;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = '';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;
  @Input() maxLength: number = LONG_STRING_LENGTH.maxLength;
  @Input() rows: number = 2;

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();

    // this.maxLength = 5;
    // this.maxLength = of(5)
    // this.rows = 2;
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }
}
