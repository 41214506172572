import { Injectable } from '@angular/core';
import { EnumLiteralsOf } from '../models/helpers.model';
import { environment } from '@environments/environment';

const { production: IS_PRODUCTION } = environment;
export const LOG_LEVELS = {
  warn: 'WARN',
  info: 'INFO',
  error: 'ERROR',
  debug: 'DEBUG',
} as const;

export type LogLevels = EnumLiteralsOf<typeof LOG_LEVELS>;

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private _isProduction = IS_PRODUCTION;
  constructor() {
    console.log('initialized');
  }

  logError(err: { lvl: LogLevels; mssg: string }): void {
    if (this._isProduction) {
      return;
    }
    console.log('placeholder logging module ', err);
  }
}
