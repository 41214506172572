/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssessmentDataModel } from '../models/assessment-data-model';
import { CaseNoteModel } from '../models/case-note-model';
import { DocumentDto } from '../models/document-dto';
import { OutcomeClaimModel } from '../models/outcome-claim-model';
import { ProcessResponse } from '../models/process-response';
import { TaskModel } from '../models/task-model';
import { MonitoringPhaseModel } from '../models';
import { OutcomeClaimOutcomeStatusHistoryModel } from '../models/outcome-claim-outcome-status-history-model';
import {PagingResult} from "@api/services/client.service";
import { OutcomeClaimIntegrationPushModel } from '@api/models/outcome-claim-integration-push-model';
import { MergeMessageQueueModel } from '@api/models/merge-message-queue-model';
import { IntegrationQueueModel } from '@api/models/integration-queue-model';

@Injectable({
  providedIn: 'root',
})
export class OutcomeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPostPath = '/api/Outcome/{parentOutcomeClaimKey}/linkableCaseNotes/{parentCaseNoteKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    parentCaseNoteKey: number;
  }): Observable<StrictHttpResponse<CaseNoteModel>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.path('parentCaseNoteKey', params.parentCaseNoteKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CaseNoteModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json(params: {
    parentOutcomeClaimKey: number;
    parentCaseNoteKey: number;
  }): Observable<CaseNoteModel> {
    return this.apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CaseNoteModel>) => r.body as CaseNoteModel)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDeletePath = '/api/Outcome/{parentOutcomeClaimKey}/linkableCaseNotes/{parentCaseNoteKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete$Response(params: {
    parentOutcomeClaimKey: number;
    parentCaseNoteKey: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDeletePath, 'delete');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.path('parentCaseNoteKey', params.parentCaseNoteKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete(params: {
    parentOutcomeClaimKey: number;
    parentCaseNoteKey: number;
  }): Observable<void> {
    return this.apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDeletePath = '/api/Outcome/{parentOutcomeClaimKey}/link/{documentLinkKey}';

  /**
   * Delete a link to a document by a document link key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json$Response(params: {
    parentOutcomeClaimKey: number;
    documentLinkKey: number;
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDeletePath, 'delete');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.path('documentLinkKey', params.documentLinkKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete a link to a document by a document link key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json(params: {
    parentOutcomeClaimKey: number;
    documentLinkKey: number;
  }): Observable<ProcessResponse> {
    return this.apiOutcomeParentOutcomeClaimKeyLinkDocumentLinkKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOutcomeMyMonitoringChecksGet
   */
  static readonly ApiOutcomeMyMonitoringChecksGetPath = '/api/Outcome/myMonitoringChecks';

  /**
   * Get a list of my upcoming monitoring checks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeMyMonitoringChecksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeMyMonitoringChecksGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<OutcomeClaimModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeMyMonitoringChecksGetPath, 'get');
    if (params) {
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutcomeClaimModel>>;
      })
    );
  }

  /**
   * Get a list of my upcoming monitoring checks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeMyMonitoringChecksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeMyMonitoringChecksGet$Json(params?: {
  }): Observable<Array<OutcomeClaimModel>> {
    return this.apiOutcomeMyMonitoringChecksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutcomeClaimModel>>) => r.body as Array<OutcomeClaimModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeGet
   */
  static readonly ApiOutcomeGetPath = '/api/Outcome';

  /**
   * Retrieves the Outcome Claims for a parent case/monitoring phase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeGet$Json$Response(params?: {
    parentMonitoringPhaseKey?: null | number;
    parentCaseKey?: null | number;
    clientEapKey?: null | number;

  }): Observable<StrictHttpResponse<Array<OutcomeClaimModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeGetPath, 'get');
    if (params) {
      rb.query('parentMonitoringPhaseKey', params.parentMonitoringPhaseKey, {});
      rb.query('parentCaseKey', params.parentCaseKey, {});
      rb.query('clientEapKey', params.clientEapKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutcomeClaimModel>>;
      })
    );
  }

  /**
   * Retrieves the Outcome Claims for a parent case/monitoring phase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeGet$Json(params?: {
    parentMonitoringPhaseKey?: null | number;
    parentCaseKey?: null | number;
    clientEapKey?: null | number;
  }): Observable<Array<OutcomeClaimModel>> {
    return this.apiOutcomeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutcomeClaimModel>>) => r.body as Array<OutcomeClaimModel>)
    );
  }

  /**
   * Path part for operation apiOutcomePost
   */
  static readonly ApiOutcomePostPath = '/api/Outcome';

  /**
   * Update an Outcome Claims for a Monitoring Phase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomePost$Json$Response(params?: {
    body?: OutcomeClaimModel
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update an Outcome Claims for a Monitoring Phase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomePost$Json(params?: {
    body?: OutcomeClaimModel
  }): Observable<string> {
    return this.apiOutcomePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyGetPath = '/api/Outcome/{parentOutcomeClaimKey}';

  /**
   * Retrieves the single outcome claim by key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<OutcomeClaimModel>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutcomeClaimModel>;
      })
    );
  }

  /**
   * Retrieves the single outcome claim by key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<OutcomeClaimModel> {
    return this.apiOutcomeParentOutcomeClaimKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<OutcomeClaimModel>) => r.body as OutcomeClaimModel)
    );
  }

  /**
   * Path part for operation apiOutcomeFilteredGet
   */
  static readonly ApiOutcomeFilteredGetPath = '/api/Outcome/filtered';

  /**
   * Returns a filtered list of all outcome claim records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeFilteredGet$Json$Response(params?: {
    claimTypeCode?: null | Array<string>;
    claimStatusCode?: null | Array<string>;
    parentSiteKey?: null | number[];
    outcomeStatusCode?: null | Array<string>;
    outcomeResultCode?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;
    organization?: null | Array<string>;
    employee?: null | Array<string>;
    contractTypes?: null | Array<string>;
    biaIndicator?: null | boolean;
    pageNumber?: 1 | number;
    pageSize?: 15 | number;
    sortColumn?: '' | string;
    sortDirection?: 'asc' | string;
    local?: 'en-ca' | string;
  }): Observable<StrictHttpResponse<Array<OutcomeClaimModel>>> {
    
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeFilteredGetPath, 'get');
    if (params) {
      rb.query('claimTypeCode', params.claimTypeCode, {});
      rb.query('claimStatusCode', params.claimStatusCode, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});
      rb.query('outcomeStatusCode', params.outcomeStatusCode, {});
      rb.query('outcomeResultCode', params.outcomeResultCode, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('organization', params.organization, {});
      rb.query('employee', params.employee, {});
      rb.query('contractTypes', params.contractTypes, {});
      rb.query('biaIndicator', params.biaIndicator, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortColumn', params.sortColumn, {});
      rb.query('sortDirection', params.sortDirection, {});
      rb.query('local', params.local, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutcomeClaimModel>>;
      })
    );
  }

  /**
   * Returns a filtered list of all outcome claim records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeFilteredGet$Json(params?: {
    claimTypeCode?: null | Array<string>;
    claimStatusCode?: null | Array<string>;
    parentSiteKey?: null | Array<number>;
    outcomeStatusCode?: null | Array<string>;
    outcomeResultCode?: null | Array<string>;
    fromDate?: null | string;
    toDate?: null | string;
    organization?: null | Array<string>;
    employee?: null | Array<string>;
    contractTypes?: null | Array<string>;
    biaIndicator?: null | boolean;
    pageNumber?: 1 | number;
    pageSize?: 15 | number;
    sortColumn?: '' | string;
    sortDirection?: 'asc' | string;
    local?: 'en-ca' | string;
  }): Observable<Array<OutcomeClaimModel>> {
    return this.apiOutcomeFilteredGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutcomeClaimModel>>) => r.body as Array<OutcomeClaimModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyCaseNotesGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyCaseNotesGetPath = '/api/Outcome/{parentOutcomeClaimKey}/caseNotes';

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyCaseNotesGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<CaseNoteModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyCaseNotesPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyCaseNotesPostPath = '/api/Outcome/{parentOutcomeClaimKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyCaseNotesPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json(params: {
    parentOutcomeClaimKey: number;
    body?: CaseNoteModel
  }): Observable<ProcessResponse> {
    return this.apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGetPath = '/api/Outcome/{parentOutcomeClaimKey}/linkableCaseNotes/{parentCaseKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
    parentCaseKey: number;
  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.path('parentCaseKey', params.parentCaseKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json(params: {
    parentOutcomeClaimKey: number;
    parentCaseKey: number;
  }): Observable<Array<CaseNoteModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyDocumentsGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyDocumentsGetPath = '/api/Outcome/{parentOutcomeClaimKey}/documents';

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyDocumentsGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<DocumentDto>> {
    return this.apiOutcomeParentOutcomeClaimKeyDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyAvailableDocumentsGetPath = '/api/Outcome/{parentOutcomeClaimKey}/availableDocuments';

  /**
   * Get all documents that can be associated with an outcome record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyAvailableDocumentsGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be associated with an outcome record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<DocumentDto>> {
    return this.apiOutcomeParentOutcomeClaimKeyAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiOutcomeAvailableDocumentsGet
   */
  static readonly ApiOutcomeAvailableDocumentsGetPath = '/api/Outcome/availableDocuments';

  /**
   * Get all documents that can be linked to a new outcome claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeAvailableDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeAvailableDocumentsGet$Json$Response(params?: {
    parentCaseKey?: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeAvailableDocumentsGetPath, 'get');
    if (params) {
      rb.query('parentCaseKey', params.parentCaseKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents that can be linked to a new outcome claim.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeAvailableDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeAvailableDocumentsGet$Json(params?: {
    parentCaseKey?: number;
  }): Observable<Array<DocumentDto>> {
    return this.apiOutcomeAvailableDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyTaskHistoryGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyTaskHistoryGetPath = '/api/Outcome/{parentOutcomeClaimKey}/taskHistory';

  /**
   * Get task history for an outcome record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyTaskHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyTaskHistoryGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<TaskModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyTaskHistoryGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskModel>>;
      })
    );
  }

  /**
   * Get task history for an outcome record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyTaskHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyTaskHistoryGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<TaskModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyTaskHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskModel>>) => r.body as Array<TaskModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGetPath = '/api/Outcome/{parentOutcomeClaimKey}/claimStatusHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<OutcomeClaimOutcomeStatusHistoryModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutcomeClaimOutcomeStatusHistoryModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<OutcomeClaimOutcomeStatusHistoryModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyClaimStatusHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutcomeClaimOutcomeStatusHistoryModel>>) => r.body as Array<OutcomeClaimOutcomeStatusHistoryModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyActiveTaskGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyActiveTaskGetPath = '/api/Outcome/{parentOutcomeClaimKey}/activeTask';

  /**
   * Get active task an expenditure record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<TaskModel>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyActiveTaskGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskModel>;
      })
    );
  }

  /**
   * Get active task an expenditure record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<TaskModel> {
    return this.apiOutcomeParentOutcomeClaimKeyActiveTaskGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskModel>) => r.body as TaskModel)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyAssessmentsGet
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyAssessmentsGetPath = '/api/Outcome/{parentOutcomeClaimKey}/assessments';
  static readonly ApiOutcomeParentOutcomeClaimKeyAssessmentsPreviousGetPath = '/api/Outcome/{parentOutcomeClaimKey}/previousassessments';

  /**
   * Get all assessments associated with an outcome claim record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<AssessmentDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyAssessmentsGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssessmentDataModel>>;
      })
    );
  }

  apiOutcomeParentOutcomeClaimKeyAssessmentsPreviousGet$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<Array<AssessmentDataModel>>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyAssessmentsPreviousGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssessmentDataModel>>;
      })
    );
  }

  /**
   * Get all assessments associated with an outcome claim record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<AssessmentDataModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AssessmentDataModel>>) => r.body as Array<AssessmentDataModel>)
    );
  }

  apiOutcomeParentOutcomeClaimKeyPreviousAssessmentsGet$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<Array<AssessmentDataModel>> {
    return this.apiOutcomeParentOutcomeClaimKeyAssessmentsPreviousGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AssessmentDataModel>>) => r.body as Array<AssessmentDataModel>)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyAssessmentsPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyAssessmentsPostPath = '/api/Outcome/{parentOutcomeClaimKey}/assessments';

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    body?: AssessmentDataModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyAssessmentsPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json(params: {
    parentOutcomeClaimKey: number;
    body?: AssessmentDataModel
  }): Observable<ProcessResponse> {
    return this.apiOutcomeParentOutcomeClaimKeyAssessmentsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyAssessmentsDeletePath
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyAssessmentsDeletePath = '/api/Outcome/{parentOutcomeClaimKey}/assessments';

  /**
   * Get all assessments associated with an outcome claim record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyAssessmentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyAssessmentsDelete$Json$Response(params: {
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyAssessmentsDeletePath, 'delete');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  apiOutcomeParentOutcomeClaimKeyAssessmentsDelete$Json(params: {
    parentOutcomeClaimKey: number;
  }): Observable<ProcessResponse> {
    return this.apiOutcomeParentOutcomeClaimKeyAssessmentsDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost
   */
  static readonly ApiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPostPath = '/api/Outcome/monitoringPhase/{parentMonitoringPhaseKey}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json$Response(params: {
    parentMonitoringPhaseKey: number;
  }): Observable<StrictHttpResponse<MonitoringPhaseModel>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPostPath, 'post');
    if (params) {
      rb.path('parentMonitoringPhaseKey', params.parentMonitoringPhaseKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringPhaseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json(params: {
    parentMonitoringPhaseKey: number;
  }): Observable<MonitoringPhaseModel> {
    return this.apiOutcomeMonitoringPhaseParentMonitoringPhaseKeyCancelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoringPhaseModel>) => r.body as MonitoringPhaseModel)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyUploadPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyUploadPostPath = '/api/Outcome/{parentOutcomeClaimKey}/upload';

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiOutcomeParentOutcomeClaimKeyUploadPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    //documentCategoryCode?: null | string;
    //documentSubCategoryCode?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyUploadPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentType', params.documentType, {});
      rb.query('comment', params.comment, {});
      rb.query('documentDate', params.documentDate, {});
      //rb.query('documentCategoryCode', params.documentCategoryCode, {});
      //rb.query('documentSubCategoryCode', params.documentSubCategoryCode, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiOutcomeParentOutcomeClaimKeyUploadPost$Json(params: {
    parentOutcomeClaimKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    //documentCategoryCode?: null | string;
    //documentSubCategoryCode?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<ProcessResponse> {
    return this.apiOutcomeParentOutcomeClaimKeyUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPostPath = '/api/Outcome/{parentOutcomeClaimKey}/link/{parentDocumentStoreKey}';

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    parentDocumentStoreKey: number;
  }): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.path('parentDocumentStoreKey', params.parentDocumentStoreKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Create a link to another document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json(params: {
    parentOutcomeClaimKey: number;
    parentDocumentStoreKey: number;
  }): Observable<number> {
    return this.apiOutcomeParentOutcomeClaimKeyLinkParentDocumentStoreKeyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeySubmitPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeySubmitPostPath = '/api/Outcome/{parentOutcomeClaimKey}/submit';

  /**
   * Submit an outcome claim for approval.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeySubmitPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeySubmitPost$Response(params: {
    /**
     * Outcome claim key
     */
    parentOutcomeClaimKey: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeySubmitPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Submit an outcome claim for approval.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeySubmitPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeySubmitPost(params: {
    /**
     * Outcome claim key
     */
    parentOutcomeClaimKey: number;
  }): Observable<void> {
    return this.apiOutcomeParentOutcomeClaimKeySubmitPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyReopenPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyReopenPostPath = '/api/Outcome/{parentOutcomeClaimKey}/reopen';

  /**
   * Reopen an outcome claim record for approval.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyReopenPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyReopenPost$Json$Response(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyReopenPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Reopen an outcome claim record for approval.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiOutcomeParentOutcomeClaimKeyReopenPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyReopenPost$Json(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<number> {
    return this.apiOutcomeParentOutcomeClaimKeyReopenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyApprovePost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyApprovePostPath = '/api/Outcome/{parentOutcomeClaimKey}/approve';

  /**
   * Approve an outcome claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyApprovePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyApprovePost$Response(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyApprovePostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyFinalizePost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyFinalizePostPath = '/api/Outcome/{parentOutcomeClaimKey}/finalize';

  /**
   * Approve an outcome claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyFinalizePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyFinalizePost$Response(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyFinalizePostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyReturnPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyReturnPostPath = '/api/Outcome/{parentOutcomeClaimKey}/return';

  /**
   * Approve an outcome claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyReturnPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyReturnPost$Response(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyReturnPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomeParentOutcomeClaimKeyCancelPost
   */
  static readonly ApiOutcomeParentOutcomeClaimKeyCancelCheckpointPostPath = '/api/Outcome/{parentOutcomeClaimKey}/cancelCheckpoint';

  /**
   * Cancel a non-start monitoring outcome claim.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomeParentOutcomeClaimKeyCancelPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomeParentOutcomeClaimKeyCancelCheckpointPost$Response(params: {
    parentOutcomeClaimKey: number;
    body: OutcomeClaimOutcomeStatusHistoryModel;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomeParentOutcomeClaimKeyCancelCheckpointPostPath, 'post');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomePushIntegrationGetPath
   */
  static readonly ApiOutcomePushIntegrationGetPath = '/api/Outcome/pushRecords/{parentOutcomeClaimKey}';

  /**
   * Returns a list of all outcome claim push records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomePushIntegrationGet$Json$Response(params?: {
    parentOutcomeClaimKey: number
  }): Observable<StrictHttpResponse<Array<OutcomeClaimIntegrationPushModel>>> {
    
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomePushIntegrationGetPath, 'get');
    if (params) {
      rb.path('parentOutcomeClaimKey', params.parentOutcomeClaimKey, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutcomeClaimIntegrationPushModel>>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomePushIntegrationGetPath
   */
  static readonly ApiOutcomePushIntegrationPostPath = '/api/Outcome/pushRecords';

  /**
   * Returns N/A. Pushes an outcome claim to CaMS
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomePushIntegrationPost$Json$Response(params?: {
    body: IntegrationQueueModel
  }): Observable<StrictHttpResponse<void>> {
    
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomePushIntegrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomePushIntegrationGetPath
   */
  static readonly ApiOutcomePullIntegrationGetPath = '/api/Outcome/pullRecords/{eapReferenceNumber}';

  /**
   * Returns a list of all outcome claim pull records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomePullIntegrationGet$Json$Response(params?: {
    eapReferenceNumber: number
  }): Observable<StrictHttpResponse<Array<MergeMessageQueueModel>>> {
    
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomePullIntegrationGetPath, 'get');
    if (params) {
      rb.path('eapReferenceNumber', params.eapReferenceNumber, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MergeMessageQueueModel>>;
      })
    );
  }

  /**
   * Path part for operation apiOutcomePushIntegrationGetPath
   */
  static readonly ApiOutcomePullIntegrationPostPath = '/api/Outcome/pullRecords';

  /**
   * Returns N/A. Pulls an OUTCOME and CHECKPOINT record from CaMS
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOutcomePullIntegrationPost$Json$Response(params?: {
    body: IntegrationQueueModel
  }): Observable<StrictHttpResponse<void>> {
    
    const rb = new RequestBuilder(this.rootUrl, OutcomeService.ApiOutcomePullIntegrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<void>;
      })
    );
  }
}