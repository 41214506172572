import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { dateBuilder } from '@core/models/date.model';
import moment from "moment";

export const casefloDateFormat = 'DD-MMM-YYYY';
export const caseflo8601DateFormat = 'YYYY-MM-DD';

export const monthVal = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// TODO: Remove deprecated functions
/*export function parseFormattedDate(date: string) {
  const separatedWhiteSpace = date ? date.split(' ') : null;
  const separatedDates = separatedWhiteSpace ? separatedWhiteSpace[0].split('-') : null;

  const day = separatedDates ? parseInt(separatedDates[0]) : null;
  const month = separatedDates ? monthVal.findIndex((month) => month === separatedDates[1].replace('.', '')) : null;
  const year = separatedDates ? parseInt(separatedDates[2]) : null;
  return new Date(year, month, day) as any;
}*/

// TODO: Remove deprecated functions
/*export function parseDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const formatted = day + '-' + monthVal[month] + '-' + year;

  return formatted;
}*/

/*export function parseDateFromString(eventDate: string): Date {
  return new Date(eventDate);
}*/

export function parseDateTimeAsDateFromString(eventDate: string): Date {
  return moment(eventDate).utc(false).toDate();
}

export function parseDateTimeFormattedFromString(eventDate: string): string {
  // check if eventData is null or "zero value"
  if (!eventDate || eventDate === '0001-01-01T00:00:00+00:00') return '';

  // get parsed date
  const date = parseDateFormattedFromString(eventDate);

  // format: 8:30 AM
  const time = parseTimeFormatted(eventDate);

  //DD-MMM-YYYY h:mm tt
  return `${date} ${time}`;
}

export function parseDateFormattedFromDate(eventDate: Date): string {
    if (!eventDate) return '';  // check if eventData is null or "zero value"
  return moment(eventDate).utc(false).format(casefloDateFormat);
};


export function parseDateFormattedFromString(eventDate: string): string {
  // check if eventData is null or "zero value"
  if (!eventDate || eventDate === '' || eventDate === '0001-01-01T00:00:00+00:00') return '';
  // return date in ISO-8601 format
  return moment(eventDate).utc(false).format(casefloDateFormat);
};

export function parseDateFormatted8601FromString(eventDate: string): string {
  // check if eventData is null or "zero value"
  if (!eventDate || eventDate === '' || eventDate === '0001-01-01T00:00:00+00:00') return '';
  // return date in ISO-8601 format
  return moment(eventDate).utc(false).format(caseflo8601DateFormat);
};

/**
 * @param date: Date
 *
 * @return string 'DD-MMM-yyyy'
 */
export function formatDate(date: Date) {
  return parseDateFormattedFromDate(date);
}

export function parseTimeFormatted(eventDate: string): string {
  // check if eventData is null or "zero value"
  if (!eventDate || eventDate === '0001-01-01T00:00:00+00:00') return '';
  // return format: 8:30 AM
  // TODO: Verify we are handling BOTH the time from the server and the user's timezone correctly 
  //const date = moment(eventDate).utc(false).toDate();
  return new Date(eventDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

export function getToday() {
  const dateTuple = dateBuilder();
  const today = new Date(dateTuple[0], dateTuple[1], dateTuple[2]) as any;

  return today;
}
@Injectable()
export class CustomDatePickerAdapter extends NativeDateAdapter {
  separator = '-';

  format(date: Date, displayFormat: any): string {
    //debugger;
    if (displayFormat == 'input') {
      const day = date.getDate();const month = date.getMonth();const year = date.getFullYear();
      const formatted = this._to2digit(day) + this.separator + this.monthVal[month] + this.separator + year;
      // Changed date display format to ISO-8601 international format
      // https://en.wikipedia.org/wiki/ISO_8601
      return moment(date).format(casefloDateFormat);
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  monthVal = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  deserialize(value: any) {
    //debugger;
    const parsedDate = moment(value).utc(false).toDate();
    return value != null ? new Date(parsedDate) : null;
  }

  parse(value: string | number): Date | null {
    //debugger;
    if (!value || (typeof value === 'string' && value.length < 1)) return null;
    const values = typeof value === 'string' ? value.split(this.separator) : value;
    const month = this.monthVal.findIndex((month) => month.toLowerCase() === values[1].toLowerCase());
    const date = value != null ? new Date(parseInt(values[2]), month, parseInt(values[0])) : null;
    return date;
  }
}

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { day: 'numeric', month: 'short', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};
