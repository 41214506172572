import { Injectable } from '@angular/core';
import { CaseHeaderModel } from '@api/models';
import { caseKeysFilter } from '@core/models/case-keys.function';
import { LoggingService } from './logging.service';
import { Globals } from 'src/app/globals';
import { DataService } from './data.service';
import { catchError, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaseHeaderService {
  caseHeader: CaseHeaderModel = {};

  setCaseHeader(header: CaseHeaderModel) {
    if (!header) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: `${this.constructor.name}.caseKeys does not contain a caseHeader model in ${this.constructor.name}._caseHeader`,
      });
    }
    this.caseHeader = { ...header };

    this.globals.setViewType(header);
  }

  get caseKeys() {
    if (!this.caseHeader) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: `${this.constructor.name}.caseKeys does not contain a caseHeader model in ${this.constructor.name}._caseHeader`,
      });
    }
    return caseKeysFilter(this.caseHeader);
  }

  constructor(private logSvc: LoggingService, private globals: Globals, private dataSvc: DataService) {}

  updateCaseHeader() {
    this.dataSvc
      .getCaseHeader(this.globals.caseKey)
      .pipe(
        take(1),
        tap((header) => {
          this.globals.caseOrgKey = header.parentOrganizationKey;
        }),
        catchError((err) => {
          this.logSvc.logError({ lvl: 'WARN', mssg: err.message });
          return of(this.caseHeader);
        })
      )
      .subscribe((header) => this.setCaseHeader(header));
  }
  GetCaseOrgKey() {
    return this.dataSvc
      .getCaseHeader(this.globals.caseKey)
      .pipe(
        take(1),
        tap((header) => {
          this.globals.caseOrgKey = header.parentOrganizationKey;
        }),
        catchError((err) => {
          this.logSvc.logError({ lvl: 'WARN', mssg: err.message });
          return of(this.caseHeader);
        })
      );
  }
}
