import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormControl } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'cf2-input-field-fixed',
  template: `
    <div #container class="input-field-fixed">
      <mat-form-field appearance="standard" floatLabel="always">
        <mat-label for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          matInput
          [placeholder]="placeholder"
          type="text"
          autocomplete="off"
          [formControl]="ctrl"
          [disableControl]="disabled"
          [cf2Field]="field"
          [isRequired]="required"
        />

        <mat-error *ngIf="ctrl && ctrl['errorMessage']"> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./input-field-fixed.component.scss'],
})
export class InputFieldFixedComponent {
  @Input() field: FormsComponentField;
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() label: string;

  @Input() ctrl: RxFormControl;
  @Input() required = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeholder = '';

  @Output() iconClick = new EventEmitter<boolean>();
  @Input() disabled;
}
