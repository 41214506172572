import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { RedirectComponent } from '@core/components/redirect/redirect.component';
import { TransitionScreenComponent } from '@core/components/transition-screen/transition-screen.component';
import { Cf2AdminGuard } from '@core/guards/cf2-admin.guard';
import { Cf2CanActivateGuard } from '@core/guards/cf2-can-activate.guard';
import { InquiryGuard } from '@core/guards/inquiry.guard';
import { NavigationComponent } from '@core/navigation/navigation.component';
import { LogoutComponent } from '@shared/components/logout/logout.component';
import { MaintenanceComponent } from '@shared/components/maintenance/maintenance.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'redirect',
    component: RedirectComponent,
    pathMatch: 'full',
  },
  {
    path: 'logout',
    component: LogoutComponent,
    pathMatch: 'full',
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    pathMatch: 'full',
  },
  // ClientFacing route: route is accessed without a login tokoen
  {
    path: 'client-inquiry',
    loadChildren: () =>
      import('./modules/client-inquiry/client-inquiry.module')
        .then((m) => m.ClientInquiryModule),
  },
  // ClientFacing route: route is accessed without a login tokoen
  {
    path: 'client-assessment',
    loadChildren: () =>
      import('./modules/guidedAssessment/guidedAssessment.module')
        .then((m) => m.GuidedAssessmentModule),
  },

  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        /* this becomes home */
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [Cf2CanActivateGuard],
      },

      {
        path: 'inquiries',
        loadChildren: () =>
          import('./modules/inquiries/inquiries.module')
            .then((m) => m.InquiriesModule),
        canActivate: [Cf2CanActivateGuard, InquiryGuard],
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./modules/search/search.module').then((m) => m.SearchModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'clients',

        loadChildren: () =>
          import('./modules/client/client.module').then((m) => m.ClientModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'caseload',
        loadChildren: () =>
          import('./modules/caseload/caseload.module')
            .then((m) => m.CaseloadModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/administration/administration.module')
            .then((m) => m.AdministrationModule),
        canActivate: [Cf2CanActivateGuard, Cf2AdminGuard],
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./modules/reports/reports.module')
            .then((m) => m.ReportsModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'eap',
        loadChildren: () =>
          import('./modules/eap/eap.module').then((m) => m.EapModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'common-assessment',
        loadChildren: () =>
          import('./modules/common-assessment/common-assessment.module').then((m) => m.CommonAssessmentModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'expenditures',
        loadChildren: () =>
          import('./modules/expenditures/expenditures.module')
            .then((m) => m.ExpendituresModule),
        canActivate: [Cf2CanActivateGuard],
      },
      /* FIXME: adjust the module routing for this */
      {
        path: 'outcomes',
        loadChildren: () =>
          import('./modules/outcomes/outcomes.module')
            .then((m) => m.OutcomesModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'employment',
        loadChildren: () =>
          import('./modules/employment/employment.module')
            .then((m) => m.EmploymentModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'scheduling',
        loadChildren: () =>
          import('./modules/scheduling/scheduling.module')
            .then((m) => m.SchedulingModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'assessment',
        loadChildren: () =>
          import('./modules/guidedAssessment/guidedAssessment.module')
            .then((m) => m.GuidedAssessmentModule),
        canActivate: [Cf2CanActivateGuard],
      },
      {
        path: 'integration-dashboard',
        loadChildren: () =>
            import('./modules/integration-dashboard/integration-dashboard.module')
                .then((m) => m.IntegrationDashboardModule),
        canActivate: [Cf2CanActivateGuard],
      },

      {
        path: 'transit',
        component: TransitionScreenComponent
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRouterModule { }
