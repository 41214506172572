import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { LoggingService } from '@core/services/logging.service';
import { MeetingOccurrenceModel, CaseModel } from '@api/models';
import { Observable } from 'rxjs';
import { StrictHttpResponse } from '@api/strict-http-response';
import { SchedulingService, CaseService } from '@api/services';
import { map, tap } from 'rxjs/operators';
import {parseTimeFormatted} from "@shared/models/date-formats.model";

@Injectable({
  providedIn: 'root',
})
export class AppointmentsDataService {
  public case: CaseModel;

  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }
  constructor(
    private globals: Globals,
    private schedulingSvc: SchedulingService,
    private caseSvc: CaseService,
    private logSvc: LoggingService
  ) {}

  getMeetingOccurenceKey() {
    const meetingOccurrenceKey = this.globals.meetingOccurrenceKey;
    if (!meetingOccurrenceKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no meetingOccurencyKey set in globals for scheduling-service data service',
      });
    }

    return meetingOccurrenceKey;
  }

  setMeetingOccurenceKey(key: number) {
    this.globals.meetingOccurrenceKey = key;
  }
  getMeetingKey() {
    const meetingKey = this.globals.meetingKey;
    if (!meetingKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no meetingKey set in globals for scheduling-service data service',
      });
    }

    return meetingKey;
  }

  setMeetingKey(key: number) {
    this.globals.meetingKey = key;
  }

  get caseKey() {
    const caseKey = this.globals.caseKey;
    if (!caseKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no caseKey set in globals for EmploymentHistoryDataService',
      });
    return caseKey;
  }

  /* set the case key in local storage */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }

  setClientKey(key: number) {
    this.globals.clientKey = key;
  }

  getSiteKey(): number {
    return this.globals.siteKey;
  }

  getAppointments(): Observable<StrictHttpResponse<MeetingOccurrenceModel[]>> {
    const caseKey = this.caseKey;

    if (isNaN(caseKey)) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'key value in getAppointments in employment-history dataSvc is Nan',
      });
    }

    return this.schedulingSvc.apiSchedulingCaseParentCaseKeyGet$Json$Response({
      parentCaseKey: caseKey,
    });
  }

  getCase(parentCaseKey: number) {
    if (!parentCaseKey) this.logSvc.logError({ lvl: 'ERROR', mssg: 'No key provided' });
    return this.caseSvc.apiCaseParentCaseKeyGet$Json({ parentCaseKey });
  }
  
  // TODO: Remove deprecated function
/*  parseDate = (eventDate: string): Date => {
    return new Date(eventDate);
  };*/

  parseTimeFormatted = (eventDate: string): string => {
    // date-formats.model.ts
    return parseTimeFormatted(eventDate);
  };

  scheduleClientIntakeMeeting(parentClientKey, parentMeetingOccurrenceKey) {
    return this.schedulingSvc.apiSchedulingIntakeMeetingParentMeetingOccurrenceKeyParentClientKeyPost$Json({
      parentClientKey,
      parentMeetingOccurrenceKey,
    });
  }
}
