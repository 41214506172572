import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { NavigationStateService } from '@core/services/navigation-state.service';
import { Cf2Store } from '@core/models/store.model';
import { first } from 'rxjs/operators';
import { Globals } from 'src/app/globals';
import { AccountInfo } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';


const authRq = { scopes: ['user.read'], prompt: 'select_account' };

@Injectable({
  providedIn: 'root',
})
export class Cf2CanActivateGuard extends Cf2Store implements CanActivate {
  user: AccountInfo;
  authRq: { scopes: string[]; prompt: string };
  unsubscribe = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private bcService: MsalBroadcastService,
    private navigationStateService: NavigationStateService,
    private globals: Globals,
    private router: Router,
  ) {
    super();
    this.authRq = authRq;
  }



  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = !!this.msalService.instance.getActiveAccount();
    await this.ready$.pipe(first((x) => !!x)).toPromise();

    if (!isLoggedIn) {
      // this.authStateSvc.login();
      return false;
    } else if (this.user) {
      // this.msalService.location = null;
      //await this.isMaintenance();
      return true;
    } else {
      try {
        // get the user from MS Active Directory
        this.user = this.msalService.instance.getActiveAccount();

        if (this.user) {
          // await for settings
          await this.navigationStateService.getApplicationSettings();

          // check if system is maintenance
          //await this.isMaintenance();

          // await for user details
          await this.navigationStateService.getLoggedInUserDetails();
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      } finally {
        // set user loggedin in userState service
        this.navigationStateService.isLoggedIn = isLoggedIn;
      }
    }
  }

  async isMaintenance() {
    await this.navigationStateService.getIsMaintenance();

    if (this.globals.isMaintenance) {
      this.router.navigate(['maintenance']);
    }
  }
}
