import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientModel } from '@api/models';
import { OPEN_CASE_STATUS_CODES } from '@core/constants/cf2-case.constants';
import { ClientWithCaseHistory } from '@core/models/ClientWithCaseHistory.model';
import { ClientListConfig, ClientModelListData } from './client-list.datasource';
import { TranslocoService } from '@ngneat/transloco';

export const checkDuplicateClientListPopupMessage = `
  <div class="header-message">
    <p>
      <strong>WARNING!</strong> One or more record(s) exist with the same name and date of birth. Please review the record(s) listed below before proceeding to avoid creating duplicate records.
    </p>
    <p>
      A new program cannot be created if the client is already open in the same program (program status is Referral, Active or Monitoring). To view the client record and/or open a new program for the client, <strong>select</strong> the applicable record below and click <strong>‘Continue’</strong>. This will discard the current entry and navigate you to the selected record. 
    </p>
    <p>
      If the record(s) below is not your client, click ‘Create New Client’ to proceed with creating a new client record and program. 
    </p>
    <p>Click ‘Cancel’ to close this popup.</p>
  </div>
`;

export const checkDuplicateClientListPopupMessageOnInquiry = `
  WARNING! More than one record exist with the same name and email address. 
  Please review the records below before proceeding to  avoid duplicates. 
  To open a new referral from and existing record, select the record from the list below and click 'Continue'. 
  Click 'Create new Referral' to proceed with creating a completely new referral record.
`;

export const cannotCreateClientCaseForExistingEOActive = `
  WARNING! An existing client record with the same first name, last name, and email address exists with an open 'EO Peel' program. 
  Creating a new referral may lead to a duplicate client record in the system. 
  Select 'Cancel' to stop or 'Continue' to proceed with creating the record.
`;

interface CancelDialog {
  cancel: boolean;
  action: 'cancel';
}

interface UseExistingClient {
  client: ClientWithCaseHistory;
  action: 'useExistingClient';
}

interface CreateNewClient {
  client: null;
  action: 'createNewClient';
}

interface NavigateToOpenCase {
  client: ClientWithCaseHistory;
  action: 'navigateToOpenCase';
}

export type DialogResultOptions = CancelDialog | UseExistingClient | CreateNewClient | NavigateToOpenCase;

@Component({
  selector: 'cf2-client-list-popup',
  template: `
    <mat-dialog-content class="client-list-popup">
      <div class="warning mat-body-1">
        <mat-icon class="icon">warning</mat-icon>
        <div [innerHTML]="dialogData.message"></div>
      </div>
      <mat-table [dataSource]="data" aria-label="Id Types List">
        <!-- column definitions -->
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
          <mat-header-cell *matHeaderCellDef [class.is-select-column]="column === 'select'">
            <ng-container>
              {{ columnConfig[i].label }}
            </ng-container>
          </mat-header-cell>

          <mat-cell *matCellDef="let cell; index as j" [class.is-select-column]="column === 'select'">
            <ng-container *ngIf="column === 'select'">
              <!-- If there is a active EO case then the client cannot be selected -->
              <mat-checkbox [checked]="selected === cell.parentClientKey" (change)="onSelect(cell)"> </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="column !== 'programStatus'">
              {{ cell[column] }}
            </ng-container>
            <ng-container *ngIf="column === 'programStatus'">
              <ul [ngStyle]="{ 'list-style': 'none', padding: 0 }">
                <li *ngFor="let computedProgram of cell.programs | keyvalue">
                  {{ computedProgram?.value?.mostRecentComputedProgram }}
                  {{ computedProgram?.value?.mostRecentComputedRegion }} -
                  {{ computedProgram?.value?.mostRecentCaseStatusDescription }}
                </li>
              </ul>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Header Row-->
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

        <!-- Select Record -->
        <mat-row *matRowDef="let cell; columns: columns"></mat-row>
      </mat-table>

      <!-- No data found -->
      <ng-container *ngIf="ready && data.length < 1">
        <div class="noresult">
          <div class="center">No results found</div>
        </div>
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button *ngIf="!dialogData.readonly" [disabled]="!!selected" (click)="createNewClient()">
        {{ buttonText }}
      </button>
      <button mat-button [disabled]="!dialogData.readonly && !selected" (click)="continue()">Continue</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./client-list-popup.component.scss'],
})
export class ClientListPopupComponent {
  public ready = false;
  public data: ClientWithCaseHistory[] = [];
  tableSetup = new ClientModelListData(this.translocoService);
  columns: any;
  columnConfig: any = this.tableSetup.config;
  config: ClientListConfig = { showSelection: true };
  selected: number;

  inquiryButtonText = 'Create New Referral';
  clientButtonText = 'Create New Client';
  buttonText = this.clientButtonText;

  constructor(
    public dialogRef: MatDialogRef<ClientListPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      module: string;
      clients: ClientWithCaseHistory[];
      message: string;
      readonly?: boolean;
      newProgramKey?: number;
    },
    private translocoService: TranslocoService
  ) {
    this.data = dialogData.clients;

    if (dialogData.module === 'INQUIRY') {
      this.buttonText = this.inquiryButtonText;
    }

    this.columns = this.columnConfig.map((column) => column.key);
  }

  ngOnInit() {
    this.configureDataTable();
  }

  configureDataTable() {
    // show delete icon
    if (this.dialogData?.module === 'CLIENT') {
      this.columnConfig = this.columnConfig.filter((col) => !['email', 'phoneNumber'].includes(col.key));
    } else if (this.dialogData?.module === 'INQUIRY') {
      // dont need select and client businesskey if it is readonly
      if (this.dialogData.readonly) {
        this.columnConfig = this.columnConfig.filter((column) => !['select'].includes(column.key));
        // dont need phone number if not readonly
      } else {
        this.columnConfig = this.columnConfig.filter((column) => !['phoneNumber'].includes(column.key));
      }
    }

    this.columns = this.columnConfig.map((column) => column.key);
  }

  onSelect(cell: ClientModel) {
    if (this.selected === cell.parentClientKey) {
      this.selected = null;
    } else {
      this.selected = cell.parentClientKey;
    }
  }

  closeDialog(dialogResult: DialogResultOptions) {
    this.dialogRef.close(dialogResult);
  }

  cancel() {
    this.closeDialog({ cancel: true, action: 'cancel' });
  }

  createNewClient() {
    this.closeDialog({ client: null, action: 'createNewClient' });
  }

  continue() {
    // @NOTE: if it is readonly then we are only showing one client and continue button creates a new client
    if (this.dialogData?.readonly) {
      this.closeDialog({ client: null, action: 'createNewClient' });
    } else {
      const client = this.selected
        ? this.dialogData.clients.find((x) => x.parentClientKey === this.selected)
        : this.dialogData.clients[0];

      const openCases = client?.cases.filter(
        (caseData) =>
          caseData.parentProgramKey === this.dialogData?.newProgramKey &&
          OPEN_CASE_STATUS_CODES.includes(caseData.caseStatusCode)
      );

      const hasSameProgramOpen = openCases?.length > 0;

      if (this.dialogData.module === 'CLIENT' && hasSameProgramOpen) {
        client.parentCaseKey = openCases?.[0]?.parentCaseKey;
        this.closeDialog({ client, action: 'navigateToOpenCase' });
      } else {
        this.closeDialog({ client, action: 'useExistingClient' });
      }
    }
  }
}
