import { Injectable } from '@angular/core';
import { WorkflowService } from '@api/services';
import { LoggingService } from './logging.service';
import { WorkflowActionsType } from '@core/models/workflow-actions.model';
import { TaskApprovalModel, TaskModel, TaskReviewModel } from '@api/models';

import * as R from 'remeda';
import { of } from 'rxjs';
import { Globals } from 'src/app/globals';

@Injectable({
  providedIn: 'root',
})
export class WorkflowDataService {
  private _workflow: TaskModel;
  private _approvalTask: TaskApprovalModel;

  set workflow(wf: TaskModel) {
    if (!wf) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'No workflow passed to WorkflowDataSvc.workflow' });
    }
    this._workflow = wf;
  }

  set approvalTask(appr: TaskApprovalModel) {
    if (!appr) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'No workflow passed to WorkflowDataSvc.workflow' });
    }
    this._approvalTask = appr;
  }

  get role() {
    return this.globals.roleCode as any;
  }
  get approvalTask$() {
    if (!this._workflow) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No workflow added to WorkflowDataService.#workflow in WOrkflowDataService.approvalTask',
      });
      return of(null);
    }
    const { parentTaskKey } = this._workflow;

    if (!parentTaskKey) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'No parentTaskKey added to WorkflowDataService.#workflow in WOrkflowDataService.approvalTask',
      });
    }
    return this.workflowSvc.apiWorkflowApprovalTaskParentTaskKeyGet$Json({ parentTaskKey: parentTaskKey }).pipe();
  }

  constructor(
    private workflowSvc: WorkflowService,
    private logSvc: LoggingService,
    // private expenditureSvc: ExpenditureService,
    private globals: Globals
  ) { }

  actionMap(type: WorkflowActionsType) {
    const callMap = {
      recommend: (wfSvc: WorkflowService) => (body: Partial<TaskApprovalModel>) =>
        wfSvc.apiWorkflowApprovalTaskPost$Json$Response({ body }).toPromise(),
      approve: (wfSvc: WorkflowService) => (body: Partial<TaskApprovalModel>) =>
        wfSvc.apiWorkflowApprovalTaskPost$Json$Response({ body }).toPromise(),

      return: (wfSvc: WorkflowService) => (body: Partial<TaskApprovalModel>) =>
        wfSvc.apiWorkflowApprovalTaskPost$Json$Response({ body }).toPromise(),

      finalize: (wfSvc: WorkflowService) => (body: Partial<TaskApprovalModel>) => {
        // console.log({ parentTaskKey: parentTaskKey, comment: comment });
        const { parentTaskKey = 0, comment = null, referenceNumber = null } = body;
        return wfSvc
          .apiWorkflowFinalizeTaskParentTaskKeyPost({
            parentTaskKey: typeof parentTaskKey === 'number' ? parentTaskKey : parseInt(parentTaskKey),
            body: { comment: comment, referenceNumber: referenceNumber },
          })
          .toPromise();
      },
      cancel: (wfSvc: WorkflowService) => (body: Partial<TaskApprovalModel>) =>
        wfSvc.apiWorkflowApprovalTaskPost$Json$Response({ body }).toPromise(),
      review: (wfSvc: WorkflowService) => (body: Partial<TaskReviewModel>) =>
        wfSvc.apiWorkflowReviewTaskPost$Json$Response({ body }).toPromise(),
    };

    return callMap[type](this.workflowSvc);
  }

  sanitizeData() {
    this._workflow = null;
    this._approvalTask = null;
  }
}
