import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';
import { AddressLabelsType } from '@modules/client/views/case/case-data.model';
import { FormsFns } from '@core/models/forms-fns.model';
import { DataService } from '../../../core/services/data.service';
import { map } from 'rxjs/operators';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TranslocoService } from '@ngneat/transloco';
@Component({
  selector: 'cf2-address-form-card',
  template: `
    <div class="outer-container">
      <form [formGroup]="fg">
        <div class="detail-container">

          <cf2-input-field-top
            class="address-line"
            [label]="labels.addressLine1"
            [ctrl]="fg.controls.addressLine1"
            [required]="true"></cf2-input-field-top>

          <cf2-input-field-top
            class="address-line"
            [label]="labels.addressLine2"
            [ctrl]="fg.controls.addressLine2"
            [required]="false"></cf2-input-field-top>    

          <cf2-input-field-top
            [label]="labels.city"
            [ctrl]="fg.controls.city"
            [required]="true"></cf2-input-field-top>

          <ng-container *ngIf="options$ | async as opts">
            <cf2-select-field-top
              [label]="labels.provinceCode"
              [options]="opts"
              [ctrl]="fg.controls.provinceCode"
              [required]="true"></cf2-select-field-top>
          </ng-container>

          <cf2-postal-field-top
            [label]="labels.postalCode"
            [ctrl]="fg.controls.postalCode"
            [required]="true"
          ></cf2-postal-field-top>
        </div>
      </form>
    </div>
  `,
  styleUrls: ['./address-form-card.component.scss'],
})
export class AddressFormCardComponent extends BaseFormComponent<AddressLabelsType> implements OnInit {
  options$: any;
  constructor(formBuilder: RxFormBuilder, private dataSvc: DataService, translocoService : TranslocoService) {
    super(formBuilder, translocoService);
  }

  ngOnInit(): void {
    this.getFcFn = FormsFns.getFc(this.fg);
    this.labels = {
      city: 'City',
      addressLine1: 'Street Address 1',
      addressLine2: 'Street Address 2',
      key: '',
      postalCode: 'Postal Code',
      provinceCode: 'Province',
    };
    this.options$ = this.dataSvc.lookupRecords('ProvinceType').pipe(map((value) => DataService.lookupToOptions(value, this.translocoService.getActiveLang())));
  }
}
