import { CodeTables } from '@api/models/code-tables';
import { CodeTableModel } from '@api/models';

import * as R from 'remeda';
export type LookupCodeTableKeys = keyof typeof CodeTables;
export const LookupCodeTable = { ...CodeTables };
export function pickCodeTable(key: LookupCodeTableKeys): CodeTables {
  if (!key) throw new Error('No valid key passed to lookup code tables');
  return CodeTables[key];
}

export type CodeTableModelKey = keyof CodeTableModel;

const defaultLookupFields = ['canEdit', 'code', 'description', 'descriptionFr'] as const;
const contactTypeFields = defaultLookupFields;
const contactResultsFields = defaultLookupFields;

export function pickCodeModel(...keys: CodeTableModelKey[]) {
  const fn1 = (obj: CodeTableModel) => R.pick(obj, keys);
  return (obj: Partial<CodeTableModel>) => fn1(obj);
}

export const mapResult = (keys: readonly CodeTableModelKey[]) => {
  const one = pickCodeModel(...keys);
  return (models: Partial<CodeTableModel>[]) => models.map((model) => one(model));
};

export const contactResultsFieldMapper = mapResult(contactResultsFields);
export const contactTypeFieldMapper = mapResult(contactTypeFields);
