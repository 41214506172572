import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-number-field-top',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          type="text"
          autocomplete="off"
          [mask]="mask"
          [formControl]="ctrl"
        />
        <mat-error> {{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
      <!-- </div> -->
    </div>
  `,
  styleUrls: ['./number-field-top.component.scss'],
})
export class NumberFieldTopComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() faIcon: IconDefinition;
  @Input() icon: string;
  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = '';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;
  /*
    If a value is entered it will create a numeric mask to the length entered
    Defaults to '0*'
  */
  @Input() maximumLength = 0;
  @Output() iconClick = new EventEmitter<boolean>();

  mask = '0*'

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
    this.setMask();
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }

  setMask() {
    const maskSize = this.maximumLength;
    this.mask = maskSize > 0 ? new Array(maskSize + 1).join('0') : '0*';
  }
}
