import * as R from 'remeda';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, PipeTransform, Pipe } from '@angular/core';
import { WorkFlowListData, ListConfig } from './workflow.datasource';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TaskModel } from '@api/models';
import { Router } from '@angular/router';
import { filter, pluck } from 'rxjs/operators'; 
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-expenditure-workflows-list',
  template: `
    <mat-table [dataSource]="filteredData" matSort aria-label="Expenditure List">
      <!-- column definitions -->
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
        <mat-header-cell mat-sort-header *matHeaderCellDef [disabled]="!columnConfig[i].sort">
          {{ columnConfig[i].label }}
        </mat-header-cell>

        <mat-cell *matCellDef="let cell; index as j" [class.is-linkable]="columnConfig[i].linkable">
          <ng-container
            *ngIf="
              columnConfig[i].key !== 'createdDate' &&
              columnConfig[i].key !== 'dueDate' &&
              columnConfig[i].key !== 'recordNumber'
            "
          >
            {{ cell[column] }}
          </ng-container>

          <ng-container *ngIf="columnConfig[i].key === 'firstName'">
            {{ filteredData[j].lastName }}
          </ng-container>

          <ng-container *ngIf="columnConfig[i].key === 'createdDate'">
            <!--{{ cell[column] | date: 'd-MMM-y' | uppercase }}-->
            {{ cell[column] | date: 'y-MM-dd' }} <!-- ISO-8601 date format -->
          </ng-container>

          <ng-container *ngIf="columnConfig[i].key === 'dueDate'">
            <span [class.due]="ltCurrDate(cell[column])">
              <!--{{ cell[column] | date: 'd-MMM-y' | uppercase }}-->
              {{ cell[column] | date: 'y-MM-dd' }} <!-- ISO-8601 date format -->
            </span>
          </ng-container>

          <ng-container *ngIf="columnConfig[i].key === 'recordNumber'">
            <a (click)="refNavigate(cell)">{{ cell[column] }}</a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Header Row-->
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

      <!-- Select Record -->
      <mat-row *matRowDef="let cell; columns: columns"></mat-row>
    </mat-table>

    <!-- No data found -->
    <ng-container *transloco="let t"> 
    <ng-container *ngIf="isReady && data.length < 1">
      <div class="noresult">
        <div class="center">{{t('NoResultsFound')}}</div>
      </div>
    </ng-container>
    </ng-container>
  `,
  styleUrls: ['./expenditure-workflows-list.component.scss'],
})
export class ExpenditureWorkflowsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() data: TaskModel[];
  @Input() filteredData: TaskModel[];
  @Input() isReady = false;

  @Input() customColmns: ListConfig;

  @Output() selectedTask = new EventEmitter<TaskModel>();

  columnConfig: ListConfig[];
  lockIcon = faLock;
  lockOpenIcon = faLockOpen;

  columns: any;
  sortColumn: string;
  sortDirection: boolean;

  constructor(private translocoService: TranslocoService) {
    /* TODO: create event emitter to output click event  */

        const tableSetup = new WorkFlowListData(translocoService);

        this.columnConfig = tableSetup.config;
        /* TODO: add this to the base grid component */
        this.columns = this.columnConfig.map((column) => column.key);
    
  }

  ngOnInit(): void {
    console.log('got to exp lists');
  }

  /*return true if date string greater than curr date */
  ltCurrDate(dateStr: string) {
    const currDate: Date = new Date();
    const testDate: Date = new Date(Date.parse(dateStr));
    return testDate.getTime() < currDate.getTime();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((obs) => {
      this.sortData(obs.active, obs.direction === 'asc');
    });
  }

  public refNavigate(task: TaskModel) {
    this.selectedTask.emit(task);
  }
  sortData(column: string, asc: boolean): void {
    const data = this.data;
    const uniqVals = R.uniq(data.map((vals) => vals[column]));

    // avoid re-sorting a column that has one value.
    // TODO: move this out to a sorted value
    const nullChecked = data.map((itm) => ({
      ...itm,
      [column]: itm[column] == null ? '' : itm[column],
    }));

    if (column === 'expenditureDateFormatted') {
      const sorted = this.sortResults('expenditureDate', nullChecked, asc);
      this.sortDirection = asc;
      this.sortColumn = column;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    } else {
      const sorted = this.sortResults(column, nullChecked, asc);
      this.sortColumn = column;
      this.sortDirection = asc;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    }
  }

  sortResults(column: string, data: any[], isAsc = true): any[] {
    return R.sort(data, (a, b) => this.compare(a[column], b[column], isAsc));
  }

  compare(a, b, isAsc): any {
    if (!a) return -1;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a.length < 1 ? -1 : a <= b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewExpenditure(data: TaskModel) {
    this.selectedTask.emit(data);
  }

  async updateSearch(filterStr: string): Promise<void> {
    const filter = filterStr.toLowerCase();
    const data = this.data;
    const columns = this.columns;
    const filtered = data.filter((row) => {
      const picked = R.pick(row, columns);
      const mapped = R.toPairs(picked).map((val) => val[1]);
      const some = mapped.some((val: string) => {
        if (typeof val === 'string') {
          const test = val.toLowerCase().includes(filter);
          return test;
        } else return val === filter;
      });
      return some;
    });
    this.filteredData = filtered;
  }
}
