import * as R from 'remeda';
import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ListConfig, EmploymentHistoryListData } from './employment-history-list.datasource';
import { EmployerPositionReferralModel } from '@api/models';
import { MatSort } from '@angular/material/sort';
import { EmploymentHistoryDataService } from '@modules/client/services/employment-history.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseHeaderService } from '@core/services/case-header.service';
import { TranslocoService } from '@ngneat/transloco';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'cf2-employment-history-list',
  template: `
    <div class="create-button">
      <div class="left">
        <cf2-button
          *ngIf="!dataSvc.isReadOnly"
          class="button1"
          [buttonText]="'AddEmployment' | transloco"
          isPrimary="true"
          (callback)="addEmployment()"
          [disabled]="!HasAccessPermissions()"

        ></cf2-button>
      </div>
    </div>

    <ng-container *ngIf="HasAccessPermissions()">
      <mat-table [dataSource]="filteredData" matSort aria-label="Employment History List">
        <!-- column definitions -->
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
          <mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            [disabled]="!columnConfig[i].sort"
            [class.is-icon-column]="columnConfig[i].key === 'icon' || columnConfig[i].key === 'isLocked'"
          >
            {{ columnConfig[i].label }}
          </mat-header-cell>

          <mat-cell
            *matCellDef="let cell; index as j"
            [class.is-linkable]="columnConfig[i].linkable"
          >
            <ng-container *ngIf="columnConfig[i].key !== 'employmentTypeDescription'">
              <span *ngIf="!columnConfig[i].linkable">{{ cell[column] }}</span>
              <a [routerLink]="['/clients', 'employment-history', 'view']" [queryParams]="getQueryParams(cell.parentEmployerPositionReferralKey)" *ngIf="columnConfig[i].linkable">{{ cell[column] }}</a>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Header Row-->
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

        <!-- Select Record -->
        <mat-row *matRowDef="let cell; columns: columns"></mat-row>
      </mat-table>
    </ng-container>

    <ng-container *ngIf="!HasAccessPermissions()">
      <ng-container *transloco="let t"> 
      <br />
      <div>{{ t('NoAccessToPage') }}</div>
      </ng-container>
    </ng-container>

    <!-- No data found -->
    <ng-container *ngIf="isReady && filteredData.length < 1">
      <div class="noresult"><ng-container *transloco="let t">{{ t('NoResultsFound') }}</ng-container></div>
    </ng-container>
  `,
  styleUrls: ['./employment-history-list.component.scss'],
})
export class EmploymentHistoryListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() data: EmployerPositionReferralModel[];
  @Input() isReady = false;
  @Input() filteredData: EmployerPositionReferralModel[];
  @Input() caseKey: number;
  @Input() searchFc: UntypedFormControl;

  columnConfig: ListConfig[];

  columns: any;
  sortColumn: string;
  sortDirection: boolean;
  selected(key: number) {
    this.setEmployerPositionReferralKey(key);
    return this.router.navigate(['view'], { relativeTo: this.route });
  }

  getQueryParams(key: string) {
    return { employerPositionReferralKey: key };
  }

  addEmployment() {
    this.router.navigate(['clients/employment-history/new']);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dataSvc: EmploymentHistoryDataService,
    private headerSvc: CaseHeaderService,
    private globals: Globals,
    translocoService: TranslocoService

  ) {
    const tableSetup = new EmploymentHistoryListData(translocoService);

    this.columnConfig = tableSetup.config;
    this.columns = this.columnConfig.map((column) => column.key);
  }

  ngOnInit(): void {
    if (!this.caseKey) throw new Error('No case key set in EmploymentHistoryListComponent');

    this.headerSvc.updateCaseHeader();

    // this.headerSvc.updateCaseHeader();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((obs) => {
      this.sortData(obs.active, obs.direction === 'asc');
    });
    this.searchFc.valueChanges.subscribe((obs) => {
      this.updateSearch(obs);
    });
  }

  setEmployerPositionReferralKey(key: number) {
    this.dataSvc.setEmployerPositionReferralKey(key);
  }

  sortData(column: string, asc: boolean): void {
    const data = this.data;
    const uniqVals = R.uniq(data.map((vals) => vals[column]));

    const numericColumns = ['totalClientsReferredToPosition'];
    const dateColumns = ['startDateFormatted', 'endDateFormatted'];
    const defaultValue = numericColumns.includes(column) ? -1 : dateColumns.includes(column) ? '-1' : '';

    if (column === 'startDateFormatted') {
      column = 'startDate';
    } else if (column === 'endDateFormatted') {
      column = 'endDate';
    }
    const nullChecked = data.map((itm) => ({
      ...itm,
      [column]: itm[column] == null ? defaultValue : itm[column],
    }));

    const sorted = this.sortResults(column, nullChecked, asc);
    this.sortColumn = column;
    this.sortDirection = asc;
    this.filteredData = uniqVals.length <= 1 ? data : sorted;
  }
  sortResults(column: string, data: any[], isAsc = true): any[] {
    return R.sort(data, (a, b) => this.compare(a[column], b[column], isAsc));
  }

  compare(a, b, isAsc): any {
    if (!a) return -1;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a.length < 1 ? -1 : a <= b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  async updateSearch(filterStr: string): Promise<void> {
    const filter = filterStr.toLowerCase();
    const data = this.data;
    const columns = this.columns;
    const filtered = data.filter((row) => {
      const picked = R.pick(row, columns);
      const mapped = R.toPairs(picked).map((val) => val[1]);
      const some = mapped.some((val: string) => {
        if (typeof val === 'string') {
          const test = val.toLowerCase().includes(filter);
          return test;
        } else return val === filter;
      });
      return some;
    });
    this.filteredData = filtered;
  }
  
  public HasAccessPermissions() : Boolean {
    var orgkey = this.globals.caseOrgKey;
    var ingroup = ['EC','SM','OM'].includes(this.globals.roleCode);
    var userrole = this.globals.organizationKey;
    return (ingroup)? userrole == orgkey : true;

  }
}
