/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode, InjectionToken } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

export function getBaseUrl(): string {
  return document.getElementsByTagName('base')[0].href;
}

export function getApiUrl(): string {
  let baseUrl = getBaseUrl().toString();
  baseUrl += 'api/';
  return baseUrl;
}

// console.log(getBaseUrl());
// console.log(window.location.href);
// const href = window.location.href;

// export type BaseUrl

const url = window.location.href;

export let azureClientId = '';
export let azureTenantId = '';

export function setClientId(value: string) {
  azureClientId = value;
}
export function setTenantId(value: string) {
  azureTenantId = value;
}

export const cf2BaseUrl = url;

export type BaseUrlType = typeof cf2BaseUrl;

export const BASE_URL = new InjectionToken<BaseUrlType>('base url');

const providers = [
  {
    provide: cf2BaseUrl,
    useValue: 'abcdef',
  },
  //   { provide: BASE_URL, useFactory:  },
  { provide: 'BASE_URL_API', useFactory: getApiUrl, deps: [] },
];

if (environment.production) {
  enableProdMode();
}


try {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", getBaseUrl() + "api/ApplicationSettings/theme", false); // false for synchronous request
  xmlHttp.send(null);
  let newColours = JSON.parse(xmlHttp.responseText);
  document.documentElement.style.setProperty('--production-primary-color', newColours.primaryColour);
  document.documentElement.style.setProperty('--secondary-color', newColours.secondaryColour);
  document.documentElement.style.setProperty('--production-accent-color', newColours.accentColour);
  document.documentElement.style.setProperty('--alternating-row-color', newColours.alternatingRowColour);
  document.documentElement.style.setProperty('--panel-header-color', newColours.panelHeaderColour);
} catch (e) {
  console.log('Error setting dynamic theme');
}

/*Array.from(document.styleSheets).forEach(sheet =>
    Array.from((sheet as CSSStyleSheet).cssRules).forEach(rule =>
        (rule as CSSStyleRule).selectorText === '.production' && ((rule as CSSStyleRule).style.backgroundColor = '#ff2e6f')
    )
);*/

platformBrowserDynamic(providers).bootstrapModule(AppModule);
