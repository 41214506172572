import { Injectable } from '@angular/core';
import { WorkflowActionsType, DEFAULT_WORKFLOW_ACTIONS } from '@core/models/workflow-actions.model';
import { MatDialog } from '@angular/material/dialog';
import { LoggingService } from './logging.service';
import { ApprovalTaskData, ApprovalTaskComponent } from '@shared/components/approval-task/approval-task.component';
import { of } from 'rxjs';
import { FinalizeExpenditureComponent } from '@modules/expenditures/components/finalize-expenditure/finalize-expenditure.component';
import { ExpenditureModel } from '@api/models';

import { Globals } from 'src/app/globals';
import { FinalizeOutcomeDialogComponent } from '@modules/outcomes/components/finalize-outcome-dialog/finalize-outcome-dialog.component';

const DIALOG_WIDTH = '320px';

export type WorkflowModuleType = 'expenditure';
/**
 * Service for handing actions in Task based entities
 *
 * @export
 * @class WorkflowActionsService
 */
@Injectable({
  providedIn: 'root',
})
export class WorkflowActionsService {
  private _type: WorkflowModuleType = 'expenditure';
  private _expenditure: ExpenditureModel;
  private _actions: WorkflowActionsType[] = [...DEFAULT_WORKFLOW_ACTIONS];

  /**
   * Valid actions
   *
   * @memberof WorkflowActionsService
   */
  set actions(actions: WorkflowActionsType[]) {
    this.actions = actions;
  }

  /**
   * Valid actions
   *
   * @memberof WorkflowActionsService
   */
  get actions() {
    return this._actions;
  }

  /**
   * role-code for permissions based workflows & expenditures
   *
   * @readonly
   * @memberof WorkflowActionsService
   */
  get roleCode() {
    return this.globals.roleCode;
  }

  /**
   * Set the expendiuter
   *
   * @memberof WorkflowActionsService
   * @param expenditure - valid expenditure model
   */
  set expenditure(expenditure: ExpenditureModel) {
    if (!expenditure) {
      this.logSvc.logError({ lvl: 'WARN', mssg: 'No expenditure set in WorkflowActionService.expenditure' });
    }
    this._expenditure = expenditure;
  }

  private hasAction(action: WorkflowActionsType) {
    return this._actions.includes(action);
  }

  private _approvalTaskDialog(data: ApprovalTaskData) {
    return this.dialog.open<ApprovalTaskComponent>(ApprovalTaskComponent, { data, width: '90%' });
  }

  /**
   * Get the finalize expenditure document
   *
   * @private
   * @memberof WorkflowActionsService
   */
  private _finalizeExpenditureDialog() {
    const data = this._expenditure?.expenditureItems;
    if (!data) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: 'No expenditure added to #expenditure in WorkflowActionsService._finalizeExpenditureDialog()',
      });
    }
    return this.dialog.open<FinalizeExpenditureComponent>(FinalizeExpenditureComponent, {
      data,
      width: '90%',
    });
  }

  finalizeOutcomeDialog() {
    return this.dialog.open<FinalizeOutcomeDialogComponent>(FinalizeOutcomeDialogComponent).afterClosed();
  }

  constructor(private dialog: MatDialog, private logSvc: LoggingService, private globals: Globals) {}

  /**
   * sanitize data on destroy component
   *
   * @memberof WorkflowActionsService
   */
  sanitizeData(): void {
    this._type = null;
    this._actions = null;
    this._type = null;
  }

  /**
   * Expenditure approval data component
   * @param data - approvalTaskData type. Defautls to cancel type.
   */
  private _expenditureDialog(data: ApprovalTaskData = { type: 'cancel' }) {
    const { type } = data;
    const dialogRef = type === 'finalize' ? this._finalizeExpenditureDialog() : this._approvalTaskDialog(data);

    return dialogRef.afterClosed();
  }

  private _outcomeDialog(data: ApprovalTaskData = { type: 'approve', module: 'outcome' }) {
    return this._approvalTaskDialog(data).afterClosed();
  }

  /**
   * Execute a workflow action based on the workflow action type.
   *
   * @param {ApprovalTaskData} [params={ type: 'approve' }]
   * @returns Observable of the open dialog for the workflow task
   * @memberof WorkflowActionsService
   */
  executeAction(params: ApprovalTaskData = { type: 'approve', module: 'expenditure' }) {
    const { type = 'approve', module = 'expenditure' } = params;
    const isExpenditure = module === 'expenditure';
    const hasAction = this.hasAction(type);
    if (!hasAction) {
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'Workflow type is not allowed for this expenditure and user in WorkflowActionsService.executeAction()',
      });
      return of(null);
    }

    return isExpenditure ? this._expenditureDialog({ type }) : this._outcomeDialog({ type, module });
  }
}
