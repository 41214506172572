import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserStateService } from '@core/services/user-state.service';
import { InquiriesService } from '@modules/inquiries/services/inquiries.service';

@Injectable({
  providedIn: 'root',
})
export class InquiryGuard implements CanActivate {
  constructor(
    // @Inject(CF2_AUTH_REQUEST) private authRq: Cf2AuthRequestType,
    private inquiriesService: InquiriesService,
    private userState: UserStateService,
    private router: Router,
    private authSvc: MsalService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authSvc.instance.getActiveAccount()) {
      return false;
    }
    try {
      const { parentInquiryKey } = route.queryParams;
      if (parentInquiryKey) {
        this.inquiriesService.parentInquiryKey = parentInquiryKey;
        await this.inquiriesService.loadInquiryRecord(parseInt(parentInquiryKey), false);
      }
    } catch (err) {
      console.error('Failed to load inquiry details', route.queryParams, err);
    }

    return new Promise<boolean>((resolve) => {
      this.userState.modules$.subscribe((res) => {
        if (res?.length > 0) {
          const roleCode = this.userState.getProp('roleCode');
          if (!roleCode || !['HD', 'SCM', 'SCO'].includes(roleCode as string)) this.router.navigate(['home']);
          resolve(['HD', 'SCM', 'SCO'].includes(roleCode as string));
        }
      });
    });
  }
}
