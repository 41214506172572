import { TaskApprovalModel, TaskModel, ExpenditureItemModel } from '@api/models';
import { CF2_ROLES } from '@core/constants/cf2-roles.constants';
import { ExpenditureType } from '@modules/expenditures/models/expenditure-state.model';

export const ROLES = [...CF2_ROLES];

export type RolesType = typeof ROLES[number];

export const DEFAULT_WORKFLOW_ACTIONS = ['recommend', 'approve', 'return', 'cancel', 'finalize', 'review', 'cancelCheckpoint'] as const;

export type WorkflowActionsType = typeof DEFAULT_WORKFLOW_ACTIONS[number];

const WORKFLOW_STATUS_MAP = {
  approve: 'APPR',
  reject: 'REJE',
  return: 'RETN',
  cancel: 'REJE',
  recommend: 'APPR',
  review: 'RETN',
};

export const EXPENDITURE_STATUS_OPTS = [
  'CANC',
  'COMPL_APPR',
  'FINAL',
  'INPR',
  'PENDING',
  'RETN',
  'SUBM',
  'RECOMMEND',
] as const;

export type ExpenditureStatusType = typeof EXPENDITURE_STATUS_OPTS[number];

export interface ExpenditurePermissionProperties {
  approveAmount?: {
    client?: number;
    employer?: number;
  };

  recommendAmount?: {
    client?: number;
    employer?: number;
  };
  allowedWorkflowActions?: Record<ExpenditureStatusType, WorkflowActionsType[]>;
}

export type WfExpenditurePermissionRecordType = Record<RolesType, ExpenditurePermissionProperties>;

const EXPENDITURE_ROLE_MAP: WfExpenditurePermissionRecordType = {
  HD: {
    allowedWorkflowActions: {
      INPR: ['cancel'],
      SUBM: ['cancel'],
      CANC: [],
      COMPL_APPR: ['cancel'],
      FINAL: [],
      PENDING: [],
      RECOMMEND: ['cancel'],
      RETN: ['cancel'],
    },
  },
  AUD: {},
  SSM: {},
  EC: {
    allowedWorkflowActions: {
      INPR: ['cancel'],
      SUBM: ['cancel'],
      CANC: [],
      COMPL_APPR: ['cancel'],
      FINAL: [],
      PENDING: [],
      RECOMMEND: ['cancel'],
      RETN: ['cancel'],
    },
  },
  OM: {
    approveAmount: { client: 1000, employer: 3500 },
    recommendAmount: { client: 1000, employer: 3500 },
    allowedWorkflowActions: {
      CANC: [],
      PENDING: [],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel', 'recommend'],
      RECOMMEND: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel'],
      FINAL: [],
    },
  },
  RM: {},
  SC: {},
  SCM: {
    approveAmount: { client: 1000, employer: 3500 },
    recommendAmount: { client: 1000, employer: 3500 },
    allowedWorkflowActions: {
      CANC: [],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel', 'recommend'],
      RECOMMEND: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel', 'return', 'finalize'],
      FINAL: [],
      PENDING: [],
    },
  },
  SCO: {
    approveAmount: { client: 1000, employer: 3500 },
    recommendAmount: { client: 1000, employer: 3500 },
    allowedWorkflowActions: {
      CANC: [],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel', 'recommend'],
      RECOMMEND: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel', 'return', 'finalize'],
      FINAL: [],
      PENDING: [],
    },
  },
  SM: {
    approveAmount: { client: 1000, employer: 3500 },
    recommendAmount: { client: 1000, employer: 3500 },
    allowedWorkflowActions: {
      CANC: [],
      INPR: ['cancel'],
      SUBM: ['cancel', 'approve', 'recommend', 'return'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel'],
      RECOMMEND: ['cancel'],
      FINAL: [],
      PENDING: [],
    },
  },
} as WfExpenditurePermissionRecordType;

export const OUTCOME_STATUS_OPTS = [
  'CANC',
  'COMPL_APPR',
  'FINAL',
  'INPR',
  'PENDING',
  'RETN',
  'SUBM',
  'NOT_START',
] as const;

export type OutcomeStatusType = typeof OUTCOME_STATUS_OPTS[number];

export interface OutcomePermissionProperties {
  allowedWorkflowActions?: Record<OutcomeStatusType, WorkflowActionsType[]>;
}

export type WfOutcomePermissionRecordType = Record<RolesType, OutcomePermissionProperties>;

const OUTCOME_ROLE_PERMISSIONS_MAP: WfOutcomePermissionRecordType = {
  HD: {},
  SSM: {},
  AUD: {},
  EC: {
    allowedWorkflowActions: {
      INPR: ['cancel'],
      NOT_START: ['cancel'],
      SUBM: ['cancel'],
      CANC: [],
      COMPL_APPR: [],
      FINAL: [],
      PENDING: [],
      RETN: ['cancel'],
    },
  },
  OM: {
    allowedWorkflowActions: {
      CANC: [],
      NOT_START: ['cancel'],
      PENDING: [],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel'],
      FINAL: [],
    },
  },
  RM: {},
  SC: {},
  SCM: {
    allowedWorkflowActions: {
      CANC: [],
      NOT_START: ['cancel'],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel', 'return', 'finalize'],
      FINAL: [],
      PENDING: [],
    },
  },
  SCO: {
    allowedWorkflowActions: {
      CANC: [],
      NOT_START: ['cancel'],
      INPR: ['cancel'],
      SUBM: ['approve', 'return', 'cancel'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel', 'return', 'finalize'],
      FINAL: [],
      PENDING: [],
    },
  },
  SM: {
    allowedWorkflowActions: {
      CANC: [],
      NOT_START: ['cancel'],
      INPR: ['cancel'],
      SUBM: ['cancel', 'approve', 'return'],
      RETN: ['cancel'],
      COMPL_APPR: ['cancel'],
      FINAL: [],
      PENDING: [],
    },
  },
} as WfOutcomePermissionRecordType;

export interface WorkflowActionsOpts {
  role: RolesType;
  loggedInUserOrg?: number;
  caseOrg?: number;
  caseStatus?: string;
  status?: ExpenditureStatusType | OutcomeStatusType | string;
  type?: ExpenditureType | 'outcome';
  approve?: number;
  amount?: number;
  claimTypeCode?: string;
  loggedInSiteID?: number;
  caseSiteID?: number;
  isBia?: boolean;
}

/**
 * Container for possible workflow actions
 * @param actions - actions to enable
 */
export class WorkflowActions {
  private _amount: number;
  private _appprovalTask: TaskApprovalModel;
  private _body: TaskModel;
  private expendituresPermissionProperties: ExpenditurePermissionProperties;
  private outcomePermissionProperties: OutcomePermissionProperties;
  private _status: ExpenditureStatusType | OutcomeStatusType | string;
  private _claimTypeCode: string = null;
  private _loggedInUserOrg: number;
  private _caseOrg: number;
  private _caseStatus: string;
  approve: boolean = false;
  cancel: boolean = false;
  finalize: boolean = false;
  recommend: boolean = false;
  return: boolean = false;
  review: boolean = false;
  type: ExpenditureType | 'outcome';
  role: RolesType;
  isSuperUser: boolean;
  isSameOrg: boolean;
  clientThresholdAmount = 1000;
  employerThresholdAmount = 3500;
  isBia: boolean = false;

  workflowStatus = WORKFLOW_STATUS_MAP;

  get parentTaskKey() {
    return this._body?.parentTaskKey;
  }

  get hasActions() {
    return this.recommend || this.approve || this.return || this.cancel || this.finalize || this.review;
  }
  /**
   * Set workflow & permissions for wf actions
   *
   * @memberof WorkflowActions
   */
  set workflow(task: TaskModel) {
    /* TODO: determine what required for valid body */
    const isOutcomeTask = this.type === 'outcome';
    if (isOutcomeTask) {
      this.outcomePermissions(task);
    } else this.expenditurePermissions(task);
  }
  get workflow() {
    return this._body;
  }

  get status() {
    return this._status;
  }

  set approvalTask(appTask: TaskApprovalModel) {
    this._appprovalTask = appTask;
  }

  constructor(
    task: TaskModel,
    opts: WorkflowActionsOpts = {
      role: 'EC',
      status: 'INPR',
      type: 'client',
      amount: 0,
      claimTypeCode: null,
      loggedInUserOrg: 0,
      caseOrg: 0,
      caseStatus: 'EXIT',
      loggedInSiteID: 0,
      caseSiteID: 0
    }
  ) {
    if (opts.type === 'outcome')
      this.outcomePermissionProperties = OUTCOME_ROLE_PERMISSIONS_MAP[opts.role] as OutcomePermissionProperties;
    else this.expendituresPermissionProperties = EXPENDITURE_ROLE_MAP[opts.role] as ExpenditurePermissionProperties;

    this.role = opts.role;
    this._status = opts.status;
    this.type = opts.type;
    this._amount = opts.amount;

    this._claimTypeCode = opts.claimTypeCode;

    this._loggedInUserOrg = opts.loggedInUserOrg;
    this._caseOrg = opts.caseOrg;
    this._caseStatus = opts.caseStatus;

    this.isSuperUser = ['SCO', 'SCM', 'HD'].includes(this.role);
    this.isSameOrg = this._loggedInUserOrg === this._caseOrg || opts.caseSiteID == opts.loggedInSiteID;

    this.isBia = opts.isBia;
    this.workflow = task ? task : {};
  }

  expenditurePermissions(task: TaskModel) {
    //debugger ;
    const isReturn = this._status === 'RETN';
    const isFinalized = this._status === 'FINAL';
    //this.role = 'OM';
    //TODO: check the approval count limits here
    const isRecommendTask = task?.taskName?.toLowerCase() === 'request review';
    console.log((task?.taskName?.toLowerCase()))
    isRecommendTask == true;

    this._body = task ? task : null;
    console.log((this.status))
    
      this.approve = this._status !== 'COMPL_APPR' &&
      (!this.isExpenditureAmountGreaterThanThreshold() || this._status === 'RECOMMEND') &&
      this.canPerformExpenditureAction('approve') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase();
    
    this.recommend = //isRecommendTask 
       (
        this.isExpenditureAmountGreaterThanThreshold()
       )
    && this.canPerformExpenditureAction('recommend') 
    && this.checkRoleAndOrg() 
    && this.isOpenCase();

    this.finalize =
      !isFinalized &&
      this.canPerformExpenditureAction('finalize') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase() &&
      !task.isBia;

    this.return =
      this.canPerformExpenditureAction('return') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase();

    // if in progress we dont need a task, if it is not in progress we need to reject the task
    this.cancel =
      this.canPerformExpenditureAction('cancel') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase();

    this.review = isReturn;
  }

  outcomePermissions(task: TaskModel) {
    const { reviewTask = false } = task;

    this.review = reviewTask;

    this.approve = this.canPerformOutcomeAction('approve') && this.checkRoleAndOrg() && this.isOpenCase();  

    this.return =
      this.canPerformOutcomeAction('return') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase();

    this.cancel =
      // this._claimTypeCode === 'START' &&
      this.canPerformOutcomeAction('cancel') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase(); 

    // this.finalize = (task.taskTypeCode === 'FINALIZE' && this.role === scStaff) || this.role === scManager;
    this.finalize =
      this.canPerformOutcomeAction('finalize') &&
      this.checkRoleAndOrg() &&
      this.isOpenCase() &&
      (this._claimTypeCode === 'START' ? true : !this.isBia);

    if (this.finalize) {
      this.return = true;
    }

    this._body = task ? task : null;
  }

  checkRoleAndOrg() {
    return this.isSuperUser || this.isSameOrg;
  }

  isOpenCase() {
    return !['COMPL', 'COMPL_INEL', 'EXT_EARLY', 'EXT_MON', 'INPR','RETN'].includes(this._caseStatus);
  }

  isExpenditureAmountGreaterThanThreshold() {
    if (this.type === 'client') return this._amount > this.expendituresPermissionProperties?.recommendAmount?.client;
    else if (this.type === 'employer')
      return this._amount > this.expendituresPermissionProperties?.recommendAmount?.employer;
    return false;
  }

  canPerformExpenditureAction(action: WorkflowActionsType) {
    return this.expendituresPermissionProperties?.allowedWorkflowActions?.[this._status]?.includes(action);
  }

  canPerformOutcomeAction(action: WorkflowActionsType) {
    return this.outcomePermissionProperties?.allowedWorkflowActions?.[this._status]?.includes(action);
  }

  getReviewTask(comment = '') {
    const { parentTaskKey } = this.workflow;

    const reviewComplete = true,
      reviewTask = true,
      completeTask = true;

    return {
      parentTaskKey,
      comment,
      reviewComplete,
      reviewTask,
      completeTask,
    };
  }

  set expTotals(expItems: ExpenditureItemModel[]) {
    if (!expItems) {
      throw new Error(`No exp amount in ${this.constructor.name}.expTotals`);
    }
    const values = expItems.map((ei) => ei.amount).reduce((accAmt, currAmt) => accAmt + currAmt, 0);
    this._amount = typeof values === 'number' ? values : parseInt(values, 10);
  }

  getApprovalTask(status: WorkflowActionsType, opts: { comment?: string }, referenceNumber: any = {}) {
    const { comment = null } = opts;
    const { parentTaskKey = null } = this.workflow;
    const approvalCode = this.workflowStatus[status];
    const completeTask = true;
    const approvalDate = new Date(Date.now());

    return {
      parentTaskKey,
      approvalCode,
      completeTask,
      referenceNumber,
      comment,
      approvalDate,
    };
  }
}
