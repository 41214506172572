import * as R from 'remeda';
export interface NavListItem {
  key: string;
  path: string;
  label: string;
  icon?: string;
  fontAwesomeIcon: boolean;
  parent: string;
  external: boolean;
  exactPath?: boolean;
  children?: NavListItem[];
}

export interface NavListModule {
  key: string;
  path: string;
  label: string;
  icon?: string;
  hasBadge: boolean;
  badgeCount: number;
  fontAwesomeIcon: boolean;
  children: NavListItem[];
}

// **********************************************
// PAGE HEADER NAVIGATION
// **********************************************
export const SUBMENU_NAV: NavListItem[] = [];

// **********************************************
// HOME ROUTES
// **********************************************
export const HOME_MODULE = {
  key: '',
  path: '/home',
  label: 'Home',
  icon: 'home',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  children: [],
};

// **********************************************
// INQUIRY ROUTES
// **********************************************
export const INQUIRIES_MODULE = {
  key: 'inquiries',
  path: '/inquiries',
  label: 'Inquiries',
  icon: 'contact_support',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  children: [],
};

// **********************************************
// CLIENT SEARCH ROUTES
// **********************************************
export const SEARCH_MODULE = {
  key: 'search',
  path: '/search',
  label: 'Search',
  icon: 'search',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  children: [],
};

// **********************************************
// CASELOAD ROUTES
// **********************************************
export const CASELOAD_MODULE = {
  key: 'caseload',
  path: '/caseload',
  label: 'Caseload',
  icon: 'work',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  parent: '',
  children: [
    {
      key: 'assign',
      path: 'assign',
      label: 'AssignCases',
      icon: 'assignment_ind',
      fontAwesomeIcon: false,
      parent: '',
      external: false,
    },
  ],
};

// **********************************************
// CLIENT ROUTES
// **********************************************
export const CLIENT_MODULE = {
  key: 'clients',
  path: '/clients',
  label: 'ClientSlashProgram',
  icon: 'person_pin',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  parent: 'caseKey',
  children: [
    {
      key: 'notes',
      path: 'case-notes',
      label: 'CaseNotes',
      icon: 'content_paste',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'appointments',
      path: 'appointments',
      label: 'Appointments',
      icon: 'event',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'common-assessment',
      path: 'common-assessment',
      label: 'CommonAssessment',
      icon: 'question_answer',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'eap',
      path: 'eap',
      label: 'EAP',
      icon: 'list_alt',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'expenditure',
      path: 'expenditure',
      label: 'Expenditures',
      icon: 'attach_money',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'employment',
      path: 'employment-history',
      label: 'Employment',
      icon: 'business_center',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'documents',
      path: 'documents',
      label: 'Documents',
      icon: 'edit',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
    {
      key: 'outcomes',
      path: 'outcomes',
      label: 'Outcomes',
      icon: 'check_box',
      fontAwesomeIcon: false,
      external: false,
      parent: 'caseKey',
    },
  ],
};

// **********************************************
// EMPLOYMENT ROUTES
// **********************************************
export const EMPLOYMENT_MODULE = {
  key: 'employment',
  path: '/employment',
  label: 'Employment',
  icon: 'business_center',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [],
};

// **********************************************
// EMPLOYMENT ROUTES
// **********************************************
export const EXPENDITURE_MODULE = {
  key: 'expenditure',
  path: '/expenditures/all',
  label: 'Expenditure',
  icon: 'attach_money',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [],
};

// **********************************************
// SCHEDULING ROUTES
// **********************************************
export const SCHEDULING_MODULE = {
  key: 'scheduling',
  path: '/scheduling',
  label: 'Scheduling',
  icon: 'event',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [],
};

export const REPORTING_MODULE = {
  key: 'reports',
  path: '/reports',
  label: 'Reporting',
  icon: 'download',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [
    {
      key: 'exportCases',
      path: 'exportCases',
      label: 'ExportCases',
      icon: 'work',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
    {
      key: 'exportEmployment',
      path: 'exportEmployment',
      label: 'ExportEmployment',
      icon: 'business_center',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
    {
      key: 'exportOutcomes',
      path: 'export-outcomes',
      label: 'ExportOutcomeClaims',
      icon: 'check_box',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
    {
      key: 'exportIntegrationErrors',
      path: 'export-integration-errors',
      label: 'ExportIntegrationErrors',
      icon: 'error',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },    
    {
      key: 'exportExpenditures',
      path: 'export-expenditures',
      label: 'ExportExpenditures',
      icon: 'attach_money',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
    {
      key: 'exportSchedule',
      path: 'export-schedule',
      label: 'ExportSchedule',
      icon: 'event',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
  ],
};

// **********************************************
// CaMS Integration Dashboard ROUTES
// **********************************************
export const INTEGRATION_DASHBOARD_MODULE = {
  key: 'integration',
  path: '/integration-dashboard',
  label: 'Integration',
  icon: 'sync',
  hasBadge: true,
  badgeCount: 4,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [],
};

// **********************************************
// ADMIN ROUTES
// **********************************************
export const ADMIN_MODULE = {
  key: 'admin',
  path: '/admin',
  label: 'Administration',
  icon: 'settings',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  children: [
    {
      key: 'organizations',
      path: 'organizations',
      label: 'Organizations',
      icon: 'folder_shared',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
    },
    {
      key: 'integration',
      path: 'integration',
      label: 'CaMS Integration',
      icon: 'sync',
      fontAwesomeIcon: false,
      external: false,
      parent: '',
      children: [
        {
          key: 'statusCheck',
          path: 'admin/integration/status',
          label: 'Status',
          icon: 'playlist_add_check',
          fontAwesomeIcon: false,
          parent: '',
          external: false,
          exactPath: true,
        },
        {
          key: 'outcomeclaim',
          path: 'admin/integration/outcome',
          label: 'Outcome Claims',
          icon: 'check_box',
          fontAwesomeIcon: false,
          parent: '',
          external: false,
          exactPath: true,
        },
        {
          key: 'eap',
          path: 'admin/integration/eap',
          label: 'EAP',
          icon: 'article',
          fontAwesomeIcon: false,
          parent: '',
          external: false,
          exactPath: true,
        },
      ]
    },
    {
      key: 'Tools',
      path: 'tools',
      label: 'Tools',
      icon: 'cogs',
      fontAwesomeIcon: true,
      external: false,
      parent: '',
      children: [
        {
          key: 'user',
          path: '/home/user',
          label: 'User Details',
          icon: 'account_box',
          fontAwesomeIcon: false,
          parent: '',
          external: false,
          exactPath: true,
        },
        {
          key: 'userPositions',
          path: '/home/positions',
          label: 'User Positions',
          icon: 'supervisor_account',
          fontAwesomeIcon: false,
          parent: '',
          external: false,
          exactPath: true,
        },
        {
          key: 'swagger',
          path: '/swagger',
          label: 'Swagger',
          icon: 'open_in_new',
          fontAwesomeIcon: false,
          parent: '',
          external: true,
        },
        {
          key: 'hangfire',
          path: '/hangfire',
          label: 'Hangfire',
          icon: 'open_in_new',
          fontAwesomeIcon: false,
          parent: '',
          external: true,
        },
      ],
    },
  ],
};

export const OUTCOMES_MODULE = {
  key: 'outcome',
  path: '/outcomes/all',
  label: 'Outcome',
  icon: 'check_box',
  hasBadge: false,
  badgeCount: 0,
  fontAwesomeIcon: false,
  external: false,
  parent: '',
  children: [],
};
export const MODULE_CONFIG: NavListModule[] = [
  HOME_MODULE,
  SEARCH_MODULE,
  CLIENT_MODULE,
  CASELOAD_MODULE,
  EMPLOYMENT_MODULE,
  EXPENDITURE_MODULE,
  SCHEDULING_MODULE,
  OUTCOMES_MODULE,
  REPORTING_MODULE,
  INTEGRATION_DASHBOARD_MODULE,
];

export const SC_MODULE_CONFIG: NavListModule[] = (function () {
  const modules = R.splitAt(MODULE_CONFIG, 2);
  return R.concat(R.concat(modules[0], [INQUIRIES_MODULE]), modules[1]);
})();

export const AUDIT_MODULE_CONFIG: NavListModule[] = [
  HOME_MODULE,
  SEARCH_MODULE,
  CLIENT_MODULE,
  CASELOAD_MODULE,
  EMPLOYMENT_MODULE,
  EXPENDITURE_MODULE,
  SCHEDULING_MODULE,
  OUTCOMES_MODULE,
  INTEGRATION_DASHBOARD_MODULE,
];

export const ADMIN_MODULES: NavListModule[] = [...SC_MODULE_CONFIG, ADMIN_MODULE];
export function getBaseUrlClean(): string {
  let baseUrl = document.getElementsByTagName('base')[0].href;

  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substr(0, baseUrl.length - 1);
  }

  return baseUrl;
}
