import {
  EmploymentHistoryListData,
  EmploymentHistoryListHeaderType,
  ListConfig,
} from '@shared/components/employment-history-list/employment-history-list.datasource';
import { DataTableSetup } from '@shared/services/data-table-setup.model';

import * as R from 'remeda';

const positionLabel = 'position';
const idLabel = 'id';

const BASE_FIELDS: readonly EmploymentHistoryListHeaderType[] = [
  ['positionTitle', 'Position'],
  ['employerName', 'Employer'],
  ['positionTypeDescription', 'Position Type'],
  ['startDateFormatted', 'Start Date'],
  ['endDateFormatted', 'End Date'],
  ['hoursPerWeek', 'Hours/Week'],
  ['hourlyRate', 'Hourly Pay'],
];

const VIEW_FIELDS: readonly EmploymentHistoryListHeaderType[] = [
  ['parentEmployerPositionKey', idLabel],
  ['positionTitle', 'Position'],
  ['employerDisplayName', 'Employer'],
  ['positionTypeDescription', 'Position Type'],
  ['actualStartDateFormatted', 'Start Date'],
  ['endDateFormatted', 'End Date'],
  ['hoursPerWeek', 'Hours/Week'],
  ['hourlyPay', 'Hourly Pay'],
];

const LINK_EMPLOYMENT_MODAL_HEADERS: readonly EmploymentHistoryListHeaderType[] = [['select', 'Select']];

const LINK_EMPLOYMENT_LIST_HEADERS: EmploymentHistoryListHeaderType[] = [
  ['parentEmployerPositionKey', 'Id'],
  ...BASE_FIELDS,
  ['clear', ''],
];
export class LinkEmploymentDatasource extends DataTableSetup {
  config: ListConfig[];
  constructor(type: 'modal' | 'card' | 'edit' = 'modal') {
    super();
    const isModal = type === 'modal';
    const isEditMode = type === 'edit';
    const config = R.concat(
      isModal
        ? R.concat(LINK_EMPLOYMENT_MODAL_HEADERS, BASE_FIELDS)
        : isEditMode
        ? LINK_EMPLOYMENT_LIST_HEADERS
        : VIEW_FIELDS,
      []
    );
    this.config = EmploymentHistoryListData.initColumns(config);
  }
}
