import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from '../components/dialog-component/dialog.component';
import { DialogData } from 'src/app/core/models/dialogs.model';
import { ComponentType } from '@angular/cdk/portal';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';


export const CONTACT_SUCCESS_DIALOG = {
  title: 'Your attempt has been saved.',
  message: 'Work on another inquiry record?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'Close' },
    confirm: {
      text: 'Next',
    },
  },
};

export const CONTACT_ERROR_DIALOG = {
  title: 'Something went wrong with the request.',
  message: 'Please try again.',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
    confirm: {
      text: 'Next',
    },
  },
};

export const ERROR_DIALOG = {
  title: 'Something went wrong with the request.',
  message: 'Please try again.',
  width: '40vw',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const DOCUMENTS_UPLOAD_ERROR_DIALOG = {
  title: 'Something went wrong while uploading the documents.',
  message: 'Please try uploading documents again.',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const CANNOT_DELETE_OUTCOME_DOCUMENTS = {
  title: 'Permission Denied',
  message: 'You can only delete/unlink document on outcome claim with status "In Progress" or "Returned"',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export function getConfirmCaseClosureConfig(translocoService: TranslocoService) {
  return {
    title: translocoService.translate("caseClosureDialogTitle"),
    isWarning: true,
    message: translocoService.translate("confirmCaseClosureMessage"),
    width: '600px',
    height: '400px',
    buttons: {
      cancel: { text: translocoService.translate("No"), },
      confirm: { text: translocoService.translate("Yes"), },
    },
  };
}

export const CONFIRM_DOCUMENTS_DELETE_DIALOG = {
  title: 'Delete Document',
  message: 'Are you sure, you want to delete document?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'No' },
    confirm: {
      text: 'Yes',
    },
  },
};

export const CONFIRM_CASE_NOTES_DELETE_DIALOG = {
  title: 'Delete Case note',
  message: 'Are you sure, you want to delete case note?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'No' },
    confirm: {
      text: 'Yes',
    },
  },
};

export const CONFIRM_REMOVE_EMPLOYEE_DIALOG = {
  title: 'Remove Employee',
  message: 'Are you sure, you want to remove Case Employee?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'No' },
    confirm: {
      text: 'Yes',
    },
  },
};

export const CONFIRM_REMOVE_EXPENDITURE_ITEM_DIALOG = {
  title: 'Remove Expenditure Item',
  isWarning: true,
  message: `
    
      WARNING! Removing the expenditure item will delete the item from expenditure. 
      Select Continue to proceed with removing the Expenditure item.
    
    `,
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'Cancel' },
    confirm: {
      text: 'Continue',
    },
  },
};

export const CONFIRM_EXTERNAL_CONTACTS_DELETE_DIALOG = {
  title: 'Delete External Contact',
  message: 'Are you sure, you want to delete external contact?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'No' },
    confirm: {
      text: 'Yes',
    },
  },
};

export const CANNOT_DELETE_OUTCOME_CASENOTES = {
  title: 'Permission Denied',
  message: 'You can only delete/unlink case-note on outcome claim with status "In Progress" or "Returned"',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const UPDATE_DIALOG = {
  title: 'Update Successful',
  message: 'Your updates have been saved.',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const LOCKED_DIALOG = {
  title: 'Record Locked',
  message: 'This record is being edited by another user.',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const LOCKED_APPOINTMENT_DIALOG = {
  title: 'Record Locked',
  message: 'This appointment has been selected by another user.',
  width: '240px',
  height: '300px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const LOCKED_EXPENDITURE_DIALOG = {
  title: 'Add Expenditure Locked',
  isWarning: true,
  message: 'Complete the In-Progress Employer/Jobseeker Financial Support (EAP page) before adding a new one.',
  width: '400px',
  height: '400px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const HELP_DIALOG = {
  title: '',
  message: '',
  width: '400px',
  height: '400px',
  buttons: {
    cancel: {
      text: 'Close',
    },
  },
};

export const DISCARD_CHANGES_DIALOG = {
  title: 'Unsaved Changes',
  message: 'Do you wish to discard changes?',
  width: '400px',
  height: '400px',

  buttons: {
    cancel: { text: 'No' },
    confirm: {
      text: 'Discard Changes',
    },
  },
};

export const dialogTypes = ['cancel'] as const;

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalOpen = false;
  dialogRefClose$: Observable<MatDialogRef<any>>;

  private _errorDialog: DialogData;

  get errorDialog() {
    return this._errorDialog;
  }

  constructor(public dialog: MatDialog) {
    this._errorDialog = ERROR_DIALOG;
  }

  /**
   * open custom dialog
   *
   * @param dialogComponent  accetps a component Class
   * @param params
   */
  openCustomDialog<T>(dialogComponent: ComponentType<T>, params: MatDialogConfig): MatDialogRef<any> {
    const { data = null, disableClose = false, width = '90%', maxWidth = '90%' } = params;

    return this.dialog.open(dialogComponent, {
      ...params,
      data,
      width,
      maxWidth,
      disableClose,
    });
  }

  openDialog(config: { data: DialogData }): MatDialogRef<any> {
    const { data } = config;
    const { width = null } = data;
    return this.dialog.open(DialogComponent, {
      data,
      width,
    });
  }

  updateDialogRefSubject(ref: MatDialogRef<any>): void {
    this.dialogRefClose$ = ref.afterClosed();
  }
}
