import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cf2-not-found',
  template: `
    <div style="height: 100%; display: flex;">
        <mat-card style="margin: auto" class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="cf2-avatar"></div>
            <mat-card-title>404</mat-card-title>
            <mat-card-subtitle>This page doesn't exist</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            If you believe that a link should be there or are unable to access the app please email support at
            <a href="mailto:EOPeel-ITsupport@wcgservices.com">EOPeel-ITsupport@wcgservices.com</a>
          </mat-card-content>
          <mat-card-content>
            <p>The page you're looking for doesn't exist in CaseFLO.</p>
          </mat-card-content>
          <mat-card-actions>
            <a [routerLink]="home">Home</a>
          </mat-card-actions>
        </mat-card>
    </div>
  `,
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  home = '/home';
  constructor() {}

  ngOnInit(): void {}
}
