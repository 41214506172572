import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { Observable, Subscription } from 'rxjs';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Inject } from '@core/decorators/cf2-inject.decorator';
import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import * as R from 'remeda';
import { TranslocoService } from '@ngneat/transloco';/**

/**
 * base form component for making of easy built forms
 */
@Component({
  selector: 'cf2-base-form',
  template: ``,
  styleUrls: ['./base-form.component.scss'],
})
export class BaseFormComponent<T> extends Cf2Forms implements OnDestroy {
  @Cf2Inject(CF2_CONFIG)
  private _config: Cf2ConfigType;
  selectConfig<T extends keyof Cf2ConfigType>(key: T) {
    return this._config[key];
  }

  public;
  isInit = false;
  isReady = false;
  subscriptions: Subscription[] = [];
  @Input() fg: UntypedFormGroup;
  @Input() labels: Partial<T>;
  @Input() options: Record<keyof Partial<T>, Observable<{ value: string; description: string }>>;
  @Input() required: Partial<T>;
  getFcFn: ReturnType<typeof FormsFns['getFc']>;

  getFc(key: keyof T) {
    return this.getFcFn(key);
  }

  constructor(formBuilder: RxFormBuilder, transloco : TranslocoService) {
    super(formBuilder, transloco);
  }

  private _subscriptions: Subscription[] = [];

  unsubscribe() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Add a subscription to the subscription list
   * @param sub - RXJS subscription
   */
  addSubscription(sub: Subscription) {
    this._subscriptions.push(sub);
  }

  /**
   * Add an array of subscriptions to be tracked for disposal on destruct
   * @param subs - array of subscriptions
   */

  addSubscriptions(subs: Subscription[]) {
    subs.forEach((sub) => this.addSubscription(sub));
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
