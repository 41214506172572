import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FileRecord } from '@shared/models/file-types.model';
import { FILE_TYPES, FileTypeFns } from '../../models/file-types.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'cf2-file-row',
  template: `
    <div class="file-row">
      <fa-icon [icon]="icon" class="file-icon"> </fa-icon>
      <div class="file-name-span">
        <span>
          <!-- client display name -->
          {{ displayName }}
        </span>
        &nbsp; - &nbsp;
        <span>
          <!-- doc type -->
          {{ docType }}
        </span>
        &nbsp; -&nbsp; <span>{{ this.date }}</span
        >.{{ fileExt }}
      </div>
      <!-- file size -->
      <span>{{ fileSizeCalc }}</span>
      <!-- remove item -->
      <button mat-icon-button (click)="remove.emit(index)">
        <mat-icon class="cancel-icon"> cancel </mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./file-row.component.scss'],
})
export class FileRowComponent implements OnInit {
  get fileSizeCalc() {
    return `${(this.fileSize / 1000).toString()} kb`;
  }

  fileMap: FileRecord = FILE_TYPES;

  @Input() index: number;
  @Input() fileTypeStr: string;
  @Input() displayName = 'No client selected';
  @Input() docType: string;
  @Input() date: string;
  @Input() fileSize: number;
  @Input() fileName: string;

  @Output() remove = new EventEmitter<number>();

  fileType: IconDefinition;
  fileExt: string;

  get icon() {
    const fileExt = FileTypeFns.fileExt(this.fileName);
    const icon = FileTypeFns.fileType(fileExt);
    return icon;
  }

  ngOnInit(): void {
    if (!this.fileTypeStr) {
      throw new Error('no file type string set');
    }

    this.setFileType(this.fileTypeStr);
    this.setFileExt();
  }

  setFileExt() {
    const fileExt = this.fileName.split('.');
    this.fileExt = fileExt[fileExt.length - 1];
  }

  setFileType(fileTypeStr: string) {
    if (
      [
        'image/png',
        'image/jpeg',
        'image/tiff',
        'image/x-tiff',
        'image/bmp',
        'image/x-windows-bmp',
        'image/gif',
      ].includes(fileTypeStr)
    ) {
      const fileType = this.fileMap.img;
      return (this.fileType = fileType);
    }
    if (fileTypeStr === 'text/plain') {
      return (this.fileType = this.fileMap.text);
    }
    if (fileTypeStr === 'application/pdf') {
      return (this.fileType = this.fileMap.pdf);
    }

    if (
      ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'].includes(
        fileTypeStr
      )
    ) {
      return (this.fileType = this.fileMap.word);
    }

    if (
      ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(
        fileTypeStr
      )
    ) {
      return (this.fileType = this.fileMap.excel);
    }

    return (this.fileType = this.fileMap.text);
  }
}
