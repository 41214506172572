import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { CaseEmployeesDataService } from '../services/case-employees-data.service';
import { CaseEmployeeLabelsType } from '../models/case-employee-form.model';

export interface CaseEmployeeFormConfig {
  //showPrimaryField?: boolean;
}

@Component({
  selector: 'cf2-case-employees-form',
  template: `
    <div class="flex-row flex-wrap">
      <ng-container *ngIf="employees$ | async as options">
        <cf2-autocomplete-select-field-fixed
          [ctrl]="getFc('parentEmployeeKey')"
          [label]="labels.parentEmployeeKey"
          [options]="options"
          [required]="required.parentEmployeeKey"
          [fullWidth]="true"
        >
        </cf2-autocomplete-select-field-fixed>
      </ng-container>
    </div>
  `
})
export class CaseEmployeesFormComponent extends BaseComponent implements OnInit {
  isInit = false;
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: CaseEmployeeLabelsType;
    disabled: CaseEmployeeLabelsType;
    required: CaseEmployeeLabelsType;
  };

  @Input() config: CaseEmployeeFormConfig = {};

  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;
  getFc: (formKey: string) => AbstractControl;

  employees$ = this.dataSvc.availableSiteEmployees$;

  constructor(private dataSvc: CaseEmployeesDataService) {
    super();
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-case-employee-form>, you have to initalize case employee form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc(fg);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
