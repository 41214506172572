import { ClientAddressModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type AddressKeysType = keyof ClientAddressModel | 'select' | 'deleteIcon';

/* tuple setup for casenote config */
type AddressListHeaderTupleType = [AddressKeysType, string];

/* actual display configuration */
const ADDRESS_LIST_HEADERS: readonly AddressListHeaderTupleType[] = [
  ['key', 'Id'],
  ['addressTypeDescription', 'Type'],
  ['unit', 'AptSuiteNum'],
  ['addressLine1', 'StreetAddress1'],
  ['addressLine2', 'StreetAddress2'],
  ['city', 'City'],
  ['provinceDescription', 'Province'],
  ['postalCode', 'PostalCode'],
  ['primary', 'Primary'],
];

/* list of columns that can be linked */
const ADDRESS_LIST_LINK_COLUMNS = ['addressTypeDescription'];

/* list of columns that can be sorted */
const ADDRESS_LIST_SORT_COLUMNS = ['city', 'provinceDescription', 'addressTypeDescription'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class AddressListData extends DataTableSetup {
  /* set the list headers */
  private AddressListHeaders = [...ADDRESS_LIST_HEADERS];
  private addressListLinkColumns = [...ADDRESS_LIST_LINK_COLUMNS];
  private addressListSortColumns = [...ADDRESS_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = AddressListData.linkableColumns(
      AddressListData.hiddenColumns(
        AddressListData.sortColumns(AddressListData.initColumns(this.AddressListHeaders.map((item) => this.translocoService.translate(item))), this.addressListSortColumns),
        []
      ),
      this.addressListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
  
}
