import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'cf2-raised-button',
  template: `
    <span [matTooltip]="tooltip" [matTooltipDisabled]="!disabled">
      <button
        mat-raised-button
        raised
        [attr.aria-label]="ariaLabel"
        [disabled]="disabled"
        (click)="buttonClick()"
        type="button"
        #button
      >
        {{ buttonText }}

        <ng-content></ng-content>
      </button>
    </span>
  `,
  styleUrls: []
})
export class RaisedButtonComponent {
  @ViewChild('button') buttonControl: ElementRef;

  @Output() callback = new EventEmitter();

  @Input() disabled = false;
  @Input() isPrimary = false;
  @Input() buttonText: string;
  @Input() tooltip: string;

  @Input() ariaLabel: string;

  public focus(): void {
    if (this.buttonControl !== undefined) {
      this.buttonControl.nativeElement.focus();
    }
  }

  public buttonClick() {
    this.callback.emit();
  }

  public disableButton() {
    this.disabled = true;
  }

  public enableButton() {
    this.disabled = false;
  }
}
