/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseNoteModel } from '../models/case-note-model';
import { DocumentDto } from '../models/document-dto';
import { EapModel } from '../models/eap-model';
import { ProcessResponse } from '../models/process-response';
import { EapIntegrationPushModel } from '@api/models/eap-integration-push-model';
import { MergeMessageQueueModel } from '@api/models/merge-message-queue-model';
import { IntegrationQueueModel } from '@api/models/integration-queue-model';

@Injectable({
  providedIn: 'root',
})
export class EapService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEapParentEapKeyGet
   */
  static readonly ApiEapParentEapKeyGetPath = '/api/Eap/{parentEapKey}';

  /**
   * Get an EAP record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapParentEapKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyGet$Json$Response(params: {
    parentEapKey: number;
  }): Observable<StrictHttpResponse<Array<EapModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapParentEapKeyGetPath, 'get');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EapModel>>;
      })
    );
  }

  /**
   * Get an EAP record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapParentEapKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyGet$Json(params: {
    parentEapKey: number;
  }): Observable<Array<EapModel>> {

    return this.apiEapParentEapKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EapModel>>) => r.body as Array<EapModel>)
    );
  }

  /**
   * Path part for operation apiEapParentEapKeyCaseNotesGet
   */
  static readonly ApiEapParentEapKeyCaseNotesGetPath = '/api/Eap/{parentEapKey}/caseNotes';

  /**
   * Get all case notes associated with an eap record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapParentEapKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyCaseNotesGet$Json$Response(params: {
    parentEapKey: number;
  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapParentEapKeyCaseNotesGetPath, 'get');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get all case notes associated with an eap record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapParentEapKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyCaseNotesGet$Json(params: {
    parentEapKey: number;
  }): Observable<Array<CaseNoteModel>> {

    return this.apiEapParentEapKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiEapParentEapKeyCaseNotesPost
   */
  static readonly ApiEapParentEapKeyCaseNotesPostPath = '/api/Eap/{parentEapKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapParentEapKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEapParentEapKeyCaseNotesPost$Json$Response(params: {
    parentEapKey: number;
    body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapParentEapKeyCaseNotesPostPath, 'post');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapParentEapKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEapParentEapKeyCaseNotesPost$Json(params: {
    parentEapKey: number;
    body?: CaseNoteModel
  }): Observable<ProcessResponse> {

    return this.apiEapParentEapKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiEapParentEapKeyDocumentsGet
   */
  static readonly ApiEapParentEapKeyDocumentsGetPath = '/api/Eap/{parentEapKey}/documents';

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapParentEapKeyDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyDocumentsGet$Json$Response(params: {
    parentEapKey: number;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapParentEapKeyDocumentsGetPath, 'get');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get all documents associated with an eap record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapParentEapKeyDocumentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapParentEapKeyDocumentsGet$Json(params: {
    parentEapKey: number;
  }): Observable<Array<DocumentDto>> {

    return this.apiEapParentEapKeyDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiEapEnabledGet
   */
  static readonly ApiEapEnabledGetPath = '/api/Eap/Enabled';

  /**
   * Returns the current EAP module state.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapEnabledGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapEnabledGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapEnabledGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns the current EAP module state.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapEnabledGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapEnabledGet$Json(params?: {
  }): Observable<boolean> {

    return this.apiEapEnabledGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiEapPost
   */
  static readonly ApiEapPostPath = '/api/Eap';

  /**
   * Create or Update an EAP record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEapPost$Json$Response(params?: {

    /**
     * EAP
     */
    body?: EapModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create or Update an EAP record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEapPost$Json(params?: {

    /**
     * EAP
     */
    body?: EapModel
  }): Observable<string> {

    return this.apiEapPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiEapParentEapKeyUploadPost
   */
  static readonly ApiEapParentEapKeyUploadPostPath = '/api/Eap/{parentEapKey}/upload';

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapParentEapKeyUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEapParentEapKeyUploadPost$Json$Response(params: {
    parentEapKey: number;
    moduleCode?: string;
    documentSectionCode?: string;
    documentType?: string;
    comment?: string;
    documentDate?: string;
    body?: { 'file'?: Blob }
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapParentEapKeyUploadPostPath, 'post');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
      rb.query('moduleCode', params.moduleCode, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentType', params.documentType, {});
      rb.query('comment', params.comment, {});
      rb.query('documentDate', params.documentDate, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapParentEapKeyUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEapParentEapKeyUploadPost$Json(params: {
    parentEapKey: number;
    moduleCode?: string;
    documentSectionCode?: string;
    documentType?: string;
    comment?: string;
    documentDate?: string;
    body?: { 'file'?: Blob }
  }): Observable<ProcessResponse> {

    return this.apiEapParentEapKeyUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiEapCamsParentEapKeyPost
   */
  static readonly ApiEapCamsParentEapKeyPostPath = '/api/Eap/cams/{parentEapKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapCamsParentEapKeyPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapCamsParentEapKeyPost$Response(params: {
    parentEapKey: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapCamsParentEapKeyPostPath, 'post');
    if (params) {
      rb.path('parentEapKey', params.parentEapKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapCamsParentEapKeyPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapCamsParentEapKeyPost(params: {
    parentEapKey: number;
  }): Observable<void> {

    return this.apiEapCamsParentEapKeyPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiEapAlreadyCreatedGet
   */
  static readonly ApiEapAlreadyCreatedGetPath = '/api/Eap/eapCreated/{clientKey}';

  /**
   * Returns true/false if an EAP is already created for a specific CAT
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapEnabledGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapAlreadyCreatedGet$Json$Response(params?: {
    clientKey: number;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapAlreadyCreatedGetPath, 'get');
    if (params) {
      rb.path('clientKey', params.clientKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Returns true/false if an EAP is already created for a specific CAT
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEapEnabledGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapAlreadyCreatedGet$Json(params?: {
    clientKey: number;
  }): Observable<boolean> {

    return this.apiEapAlreadyCreatedGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiEapPushIntegrationGetPath
   */
  static readonly ApiEapPushIntegrationGetPath = '/api/Eap/pushRecords/{eapReferenceNumber}';

  /**
   * Returns a list of all eap push records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapPushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapPushIntegrationGet$Json$Response(params?: {
    eapReferenceNumber: number
  }): Observable<StrictHttpResponse<Array<EapIntegrationPushModel>>> {
    
    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapPushIntegrationGetPath, 'get');
    if (params) {
      rb.path('eapReferenceNumber', params.eapReferenceNumber, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EapIntegrationPushModel>>;
      })
    );
  }

  /**
   * Path part for operation apiEapPullIntegrationGetPath
   */
  static readonly ApiEapPullIntegrationGetPath = '/api/Eap/pullRecords/{eapReferenceNumber}';

  /**
   * Returns a list of all eap pull records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapPullIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapPullIntegrationGet$Json$Response(params?: {
    eapReferenceNumber: number
  }): Observable<StrictHttpResponse<Array<MergeMessageQueueModel>>> {
    
    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapPullIntegrationGetPath, 'get');
    if (params) {
      rb.path('eapReferenceNumber', params.eapReferenceNumber, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MergeMessageQueueModel>>;
      })
    );
  }

  /**
   * Path part for operation apiEapPushIntegrationPostPath
   */
  static readonly ApiEapPushIntegrationPostPath = '/api/Eap/pushRecords/';

  /**
   * Returns a list of all eap pull records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapPullIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapPushIntegrationPost$Json$Response(params?: {
    body: IntegrationQueueModel
  }): Observable<StrictHttpResponse<void>> {
    
    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapPushIntegrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Path part for operation apiEapPullIntegrationPostPath
   */
  static readonly ApiEapPullIntegrationPostPath = '/api/Eap/pullRecords/';

  /**
   * Returns a list of all eap pull records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEapPullIntegrationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEapPullIntegrationPost$Json$Response(params?: {
    body: IntegrationQueueModel
  }): Observable<StrictHttpResponse<void>> {
    
    const rb = new RequestBuilder(this.rootUrl, EapService.ApiEapPullIntegrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<void>;
      })
    );
  }
}
