/* tslint:disable */
export enum MeetingSubjectTypes {
  GroupHirevent = 'GROUP_HIREVENT',
  GroupJobfair = 'GROUP_JOBFAIR',
  GroupWksp = 'GROUP_WKSP',
  TimeslotInt = 'TIMESLOT_INT',
  IndividInt = 'INDIVID_INT',
  SchedCa = 'SCHED_CA',
  UnavClosed = 'UNAV_CLOSED'
}
