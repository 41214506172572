/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiReportsExportHelloWorldGet
   */
  static readonly ApiReportsExportHelloWorldGetPath = '/api/Reports/exportHelloWorld';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportHelloWorldGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportHelloWorldGet$Response(params?: {

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportHelloWorldGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportHelloWorldGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportHelloWorldGet(params?: {

  }): Observable<Blob> {

    return this.apiReportsExportHelloWorldGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiReportsExportCaseloadReportGet
   */
  static readonly ApiReportsExportCaseloadReportGetPath = '/api/Reports/exportCaseload';

  /**
   * Create Export Caseload Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportCaseloadReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportCaseloadReportGet$Response(params?: {
    o?: null | Array<string>;
    st?: null | Array<string>;
    e?: null | Array<string>;
    s?: null | Array<string>;
    sp?: null | Array<string>;
    program?: null | Array<string>;
    locale?: null | string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportCaseloadReportGetPath, 'get');
    if (params) {

      rb.query('o', params.o, {});
      rb.query('st', params.st, {});
      rb.query('e', params.e, {});
      rb.query('s', params.s, {});
      rb.query('sp', params.sp, {});
      rb.query('program', params.program, {});
      rb.query('locale', params.locale, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Create Export Caseload Report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportCaseloadReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportCaseloadReportGet(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    's'?: null | Array<string>;
    sp?: null | Array<string>;

  }): Observable<Blob> {

    return this.apiReportsExportCaseloadReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiReportsExportEmploymentReportGet
   */
  static readonly ApiReportsExportEmploymentReportGetPath = '/api/Reports/exportEmployment';

  /**
   * Create Export Employment Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportEmploymentReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportEmploymentReportGet$Response(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    pt?: null | Array<string>;
    po?: null | Array<string>;
    ro?: null | Array<string>;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportEmploymentReportGetPath, 'get');
    if (params) {

      rb.query('o', params['o'], {});
      rb.query('st', params.st, {});
      rb.query('e', params['e'], {});
      rb.query('pt', params.pt, {});
      rb.query('po', params.po, {});
      rb.query('ro', params.ro, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Create Export Employment Report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportEmploymentReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportEmploymentReportGet(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    pt?: null | Array<string>;
    po?: null | Array<string>;
    ro?: null | Array<string>;

  }): Observable<Blob> {

    return this.apiReportsExportEmploymentReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiReportsExportExpendituresReportGet
   */
  static readonly ApiReportsExportExpendituresReportGetPath = '/api/Reports/exportExpenditures';

  /**
   * Create Export Expenditures Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportExpendituresReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportExpendituresReportGet$Response(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    et?: null | Array<string>;
    's'?: null | Array<string>;
    fd?: null | string;
    td?: null | string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportExpendituresReportGetPath, 'get');
    if (params) {

      rb.query('o', params['o'], {});
      rb.query('st', params.st, {});
      rb.query('e', params['e'], {});
      rb.query('et', params.et, {});
      rb.query('s', params['s'], {});
      rb.query('fd', params.fd, {});
      rb.query('td', params.td, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Create Export Expenditures Report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportExpendituresReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportExpendituresReportGet(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    et?: null | Array<string>;
    's'?: null | Array<string>;
    fd?: null | string;
    td?: null | string;

  }): Observable<Blob> {

    return this.apiReportsExportExpendituresReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation apiReportsExportOutcomeClaimsReportGet
   */
  static readonly ApiReportsExportOutcomeClaimsReportGetPath = '/api/Reports/exportOutcomeClaims';

  /**
   * Create Export Outcome Claims Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportOutcomeClaimsReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportOutcomeClaimsReportGet$Response(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    ct?: null | Array<string>;
    cs?: null | Array<string>;
    or?: null | Array<string>;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportOutcomeClaimsReportGetPath, 'get');
    if (params) {

      rb.query('o', params['o'], {});
      rb.query('st', params.st, {});
      rb.query('e', params['e'], {});
      rb.query('ct', params.ct, {});
      rb.query('cs', params.cs, {});
      rb.query('or', params.or, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Create Export Outcome Claims Report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportOutcomeClaimsReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportOutcomeClaimsReportGet(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'e'?: null | Array<string>;
    ct?: null | Array<string>;
    cs?: null | Array<string>;
    or?: null | Array<string>;

  }): Observable<Blob> {

    return this.apiReportsExportOutcomeClaimsReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }



  /**
   * Path part for operation apiReportsExportIntegrationErrorsReportGet
   */
   static readonly ApiReportsExportIntegrationErrorsReportGetPath = '/api/Reports/exportIntegrationErrors';

   /**
    * Create Export Integration Errors Report.
    *
    *
    *
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `apiReportsExportIntegrationErrorsReportGet()` instead.
    *
    * This method doesn't expect any request body.
    */
   apiReportsExportIntegrationErrorsReportGet$Response(params?: {
     'o'?: null | Array<string>;
     st?: null | Array<string>;
     'e'?: null | Array<string>;
     iet?: null | Array<string>;
     fd?: null | string;
     td?: null | string;
      ci?: null | string;
      en?: null | string;
   }): Observable<StrictHttpResponse<Blob>> {
 
     const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportIntegrationErrorsReportGetPath, 'get');
     if (params) {
 
       rb.query('o', params['o'], {});
       rb.query('st', params.st, {});
       rb.query('e', params['e'], {});
       rb.query('iet', params.iet, {});       
       rb.query('fd', params.fd, {});
       rb.query('td', params.td, {});       
       rb.query('ci', params.ci, {});       
       rb.query('en', params.en, {});       
 
     }
     return this.http.request(rb.build({
       responseType: 'blob',
       accept: 'application/octet-stream'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Blob>;
       })
     );
   }
 
   /**
    * Create Export Integration Errors Report.
    *
    *
    *
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `apiReportsExportIntegrationErrorsReportGet$Response()` instead.
    *
    * This method doesn't expect any request body.
    */
   apiReportsExportIntegrationErrorsReportGet(params?: {
     'o'?: null | Array<string>;
     st?: null | Array<string>;
     'e'?: null | Array<string>;
     iet?: null | Array<string>;
     fd?: null | string;
     td?: null | string;     
     ci?: null | string;
     en?: null | string;
   }): Observable<Blob> {
 
     return this.apiReportsExportIntegrationErrorsReportGet$Response(params).pipe(
       map((r: StrictHttpResponse<Blob>) => r.body as Blob)
     );
   }



  /**
   * Path part for operation apiReportsExportScheduleReportGet
   */
  static readonly ApiReportsExportScheduleReportGetPath = '/api/Reports/exportSchedule';

  /**
   * Create Export Schedule Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsExportScheduleReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportScheduleReportGet$Response(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'a'?: null | Array<string>;
    ec?: null | Array<string>;
    et?: null | Array<string>;
    sd?: null | string;
    ed?: null | string;

  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsExportScheduleReportGetPath, 'get');
    if (params) {

      rb.query('o', params['o'], {});
      rb.query('st', params.st, {});
      rb.query('a', params['a'], {});
      rb.query('ec', params.ec, {});
      rb.query('et', params.et, {});
      rb.query('sd', params.sd, {});
      rb.query('ed', params.ed, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Create Export Schedule Report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiReportsExportScheduleReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsExportScheduleReportGet(params?: {
    'o'?: null | Array<string>;
    st?: null | Array<string>;
    'a'?: null | Array<string>;
    ec?: null | Array<string>;
    et?: null | Array<string>;
    sd?: null | string;
    ed?: null | string;

  }): Observable<Blob> {

    return this.apiReportsExportScheduleReportGet$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
