import { CaseNoteModel } from '@api/models/case-note-model';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type CaseNoteKeysType = keyof CaseNoteModel | 'select' | 'isLocked' | 'icon' | 'deleteIcon';

/* tuple setup for casenote config */
type CaseNoteListHeaderTupleType = [CaseNoteKeysType, string];

/* actual display configuration */
const CASE_NOTE_LIST_HEADERS: readonly CaseNoteListHeaderTupleType[] = [
  ['select', ''],
  ['clientContactDateFormatted', 'ClientContactDate'],
  ['primaryCategoryDescription', 'Category'],
  ['secondaryCategoryDescription', 'SubCategory'],
  ['logTypeDescription', 'ContactedBy'],
  ['comment', 'Note'],
  ['isLocked', ''],
  ['deleteIcon', '']
];

/* list of columns that can be linked */
const CASE_NOTE_LIST_LINK_COLUMNS = [];

/* list of columns that can be sorted */
const CASE_NOTE_LIST_SORT_COLUMNS = [
  'clientContactDateFormatted',
  'primaryCategoryDescription',
  'secondaryCategoryDescription',
  'logTypeDescription'
];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class CaseNotesListData extends DataTableSetup {
  /* set the list headers */
  private caseNotesListHeaders = [...CASE_NOTE_LIST_HEADERS];
  private caseNotesListLinkColumns = [...CASE_NOTE_LIST_LINK_COLUMNS];
  private caseNotesListSortColumns = [...CASE_NOTE_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = CaseNotesListData.linkableColumns(
      CaseNotesListData.hiddenColumns(
        CaseNotesListData.sortColumns(
          CaseNotesListData.initColumns(this.caseNotesListHeaders.map((item) => this.translocoService.translate(item))),
          this.caseNotesListSortColumns
        ),
        []
      ),
      this.caseNotesListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
