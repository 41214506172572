/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssessmentDataModel } from '../models/assessment-data-model';
import { ProcessResponse } from '../models/process-response';
import { TemplateModel } from '../models/template-model';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAssessmentConfigurationTemplateTypeCodeGet
   */
  static readonly ApiAssessmentConfigurationTemplateTypeCodeGetPath = '/api/Assessment/configuration/{templateTypeCode}';

  /**
   * Returns the assessment template configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAssessmentConfigurationTemplateTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAssessmentConfigurationTemplateTypeCodeGet$Json$Response(params: {
    templateTypeCode: null | string;

  }): Observable<StrictHttpResponse<TemplateModel>> {

    const rb = new RequestBuilder(this.rootUrl, AssessmentService.ApiAssessmentConfigurationTemplateTypeCodeGetPath, 'get');
    if (params) {

      rb.path('templateTypeCode', params.templateTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateModel>;
      })
    );
  }

  /**
   * Returns the assessment template configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAssessmentConfigurationTemplateTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAssessmentConfigurationTemplateTypeCodeGet$Json(params: {
    templateTypeCode: null | string;

  }): Observable<TemplateModel> {

    return this.apiAssessmentConfigurationTemplateTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateModel>) => r.body as TemplateModel)
    );
  }

  /**
   * Path part for operation apiAssessmentParentAssessmentDataKeyGet
   */
  static readonly ApiAssessmentParentAssessmentDataKeyGetPath = '/api/Assessment/{parentAssessmentDataKey}';

  /**
   * Returns an assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAssessmentParentAssessmentDataKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAssessmentParentAssessmentDataKeyGet$Json$Response(params: {
    parentAssessmentDataKey: number;

  }): Observable<StrictHttpResponse<AssessmentDataModel>> {

    const rb = new RequestBuilder(this.rootUrl, AssessmentService.ApiAssessmentParentAssessmentDataKeyGetPath, 'get');
    if (params) {

      rb.path('parentAssessmentDataKey', params.parentAssessmentDataKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssessmentDataModel>;
      })
    );
  }

  /**
   * Returns an assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAssessmentParentAssessmentDataKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAssessmentParentAssessmentDataKeyGet$Json(params: {
    parentAssessmentDataKey: number;

  }): Observable<AssessmentDataModel> {

    return this.apiAssessmentParentAssessmentDataKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AssessmentDataModel>) => r.body as AssessmentDataModel)
    );
  }

  /**
   * Path part for operation apiAssessmentPost
   */
  static readonly ApiAssessmentPostPath = '/api/Assessment';

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAssessmentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAssessmentPost$Json$Response(params?: {
      body?: AssessmentDataModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AssessmentService.ApiAssessmentPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAssessmentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAssessmentPost$Json(params?: {
      body?: AssessmentDataModel
  }): Observable<ProcessResponse> {

    return this.apiAssessmentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
