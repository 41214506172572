import { Injectable, NgZone } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, Form } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';

import { CaseNoteModel, CodeTables, CodeTableModel, OutcomeClaimModel, ProcessResponse } from '@api/models';
import { CaseService, CaseNotesService, LookupService, OutcomeService } from '@api/services';
import { LoggingService } from '@core/services/logging.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CaseNotes } from '@shared/components/case-notes/models/case-notes.model';
import { CaseNotesViewType, CaseNoteForm } from '@shared/components/case-notes/models/case-notes-form.model';
import { ERROR_DIALOG, ModalService } from '@shared/services/modal.service';
import { filter, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Globals } from '../../../../globals';
import * as R from 'remeda';
import {casefloDateFormat, parseDateFormattedFromDate} from '@shared/models/date-formats.model';
import { DataService } from '@core/services/data.service';
import { StrictHttpResponse } from '@api/strict-http-response';
import { MultipleEntriesResponse } from '@shared/components/multiple-entries/multiple-entries.model';
import { TranslocoService } from '@ngneat/transloco';

interface CaseNotesData {
  /**
   * For newly created case notes, tempKey value is in between 0.0001 - 0.9999 which is generated on the front-end
   * For existing casenote tempKey = CaseNoteModel.parentCaseNoteKey
   */
  tempKey?: number;
  caseNote: CaseNotes;
  action?: 'CREATE' | 'UPDATE' | 'ARCHIVE' | 'DELETE' | 'LINK' | 'UNLINK';
}

/* TODO: temporary timer for timing issue */

function timeoutPromise() {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      return resolve();
    }, 300);
  });
}

@Injectable({
  providedIn: 'root',
})
export class CaseNotesDataService {
  ready$ = new BehaviorSubject<void>(null);

  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }
  caseNote: CaseNoteModel;

  _caseNoteKey: number;
  parentCaseNoteKey = -1;
  caseNoteModule: string;
  caseNoteParentModuleKey = -1;
  returnPage = [''];
  filters = {};

  private _parentCategoryCode: string;
  private _selectedCaseNoteKey: number;
  caseNotesData: CaseNotesData[] = [];
  caseNotesViewType: CaseNotesViewType = 'view';
  caseNoteForm: CaseNoteForm;
  caseNoteFormGroup: UntypedFormGroup;
  caseNotesListDataSub$ = new BehaviorSubject<CaseNotes[]>([]);
  caseNoteSubcategoryList: { value: string; description: string; disabled?: boolean }[] = [];

  caseNoteContactTypeList: { value: string; description: string; disabled?: boolean }[] = [];

  outcomeClaims: OutcomeClaimModel[] = [];
  selectedOutcomeClaimKey: number;

  constructor(
    private router: Router,
    private globals: Globals,
    private caseService: CaseService,
    private modalSvc: ModalService,
    private lookupService: LookupService,
    private authSvc: MsalService,
    private ngZone: NgZone,
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private logSvc: LoggingService,
    private outcomeSvc: OutcomeService,
    private caseNoteSvc: CaseNotesService,
    private translocoService: TranslocoService
  ) {}

  get primaryCategory$() {
    return this.dataSvc.lookupRecords('PrimaryCategory').pipe(map((value) => DataService.lookupToOptions(value, this.translocoService.getActiveLang())));
  }

  /* State handlers - state is loaded from local storage */
  /* get the case key from local storage */
  getCaseKey() {
    return this.globals.caseKey;
  }
  /* set the case key in local storage */
  setCaseKey(key: number) {
    this.globals.caseKey = key;
  }

  /* get the case note key from local storage */
  getCaseNoteKey() {
    return this._caseNoteKey;
  }

  get parentCategoryCode() {
    return this._parentCategoryCode;
  }

  /** set the module code  */
  set parentCategoryCode(code: string) {
    this._parentCategoryCode = code;
  }

  get selectedCaseNoteKey(): number {
    return this._selectedCaseNoteKey;
  }
  // set current slected casenote in the datatable
  set selectedCaseNoteKey(key: number) {
    this._selectedCaseNoteKey = key;
  }

  /* set the case note key in local storage */
  setCaseNoteKey(key: number) {
    this._caseNoteKey = key;
  }

  get expenditureKey() {
    const expenditureKey = this.globals.expenditureKey;
    if (!expenditureKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no expenditureKey set in globals for documents data service',
      });

    return expenditureKey;
  }

  get outcomeClaimKey() {
    const outcomeKey = this.globals.outcomeKey;
    if (!outcomeKey)
      this.logSvc.logError({
        lvl: 'WARN',
        mssg: 'no outcomeKey set in globals for documents data service',
      });

    return outcomeKey;
  }

  createFg(defaultDate?: Date): UntypedFormGroup {
    const fcBuild = (val: string | Date, ...validators): UntypedFormControl => new UntypedFormControl(val, validators);

    const defaultContactDate = defaultDate ? defaultDate : null;

    const clientContactDate = fcBuild(defaultContactDate, Validators.required);
    const primaryCategory = fcBuild(null, Validators.required);
    const secondaryCategory = fcBuild(null, Validators.required);
    const contactedBy = fcBuild(null, Validators.required);
    const comment = fcBuild(null, Validators.required);

    return new UntypedFormGroup({
      comment,
      clientContactDate,
      primaryCategory,
      secondaryCategory,
      contactedBy,
    });
  }

  setCaseNotesListDataSource(data: CaseNotes[]) {
    this.caseNotesListDataSub$.next(data);
  }

  setFgView(view: CaseNotesViewType) {
    this.caseNotesViewType = view;
  }

  getCaseNoteFormFields() {
    return this.caseNoteForm.fields;
  }

  // check if casenote form and files are valid
  isFormValid() {
    if (!this.caseNoteFormGroup || this.caseNoteFormGroup.valid) {
      return true;
    }

    this.caseNoteFormGroup.markAllAsTouched();
    return false;
  }

  initCaseNotesCard(obj: { view: CaseNotesViewType; category: string }) {
    const { view, category } = obj;
    this.caseNotesViewType = view;
    this.parentCategoryCode = category;
  }

  initCaseNoteForm(value: Partial<CaseNoteModel> = {}) {
    this.caseNoteForm = new CaseNoteForm(this.fb, this.translocoService, { value });
    this.caseNoteForm.fields.fg.patchValue(value);
    this.caseNoteFormGroup = this.caseNoteForm.fields.fg;
  }

  constructCaseNoteData(noteData: CaseNotesData) {
    const caseNoteData = noteData;

    caseNoteData.caseNote.parentCaseNoteKey = caseNoteData.tempKey;
    // caseNoteData.caseNote.displayName = this.globals.displayName ? this.globals.displayName : '';
    caseNoteData.caseNote.clientContactDateFormatted = parseDateFormattedFromDate(new Date(caseNoteData.caseNote.clientContactDate));

    // check if list values already exists
    if (this.caseNoteSubcategoryList.length > 0) {
      // get the secondaryCategoryDescription when secondaryCategoryCode is given
      this.caseNoteSubcategoryList.forEach((listItem) => {
        if (listItem.value === caseNoteData.caseNote.secondaryCategoryCode) {
          caseNoteData.caseNote.secondaryCategoryDescription = listItem.description;
        }
      });
    }

    if (this.caseNoteContactTypeList.length > 0) {
      this.caseNoteContactTypeList.forEach((listItem) => {
        if (listItem.value === caseNoteData.caseNote.logTypeCode) {
          caseNoteData.caseNote.logTypeDescription = listItem.description;
        }
      });
    }

    return caseNoteData;
  }

  addCaseNoteData(caseNoteData: CaseNotesData) {
    // create new casenote
    caseNoteData.action = 'CREATE';
    this.caseNotesData.push(this.constructCaseNoteData(caseNoteData));
    console.log(this.caseNotesData);
  }

  hasCaseNotes() {
    return this.caseNotesData.length > 0;
  }

  updateCaseNoteData(caseNoteData: CaseNotesData) {
    this.caseNotesData = this.caseNotesData.map((noteData) => {
      if (noteData.tempKey === caseNoteData.tempKey) {
        // construct updated caseNoteData
        noteData.caseNote = {
          ...noteData.caseNote,
          ...this.constructCaseNoteData(caseNoteData).caseNote,
        };

        // Update exising case notes
        if (noteData.tempKey >= 1) {
          noteData.action = 'UPDATE';
        }
      }

      return noteData;
    });
  }

  /**
   *
   * @param caseNoteKey
   * @param options {
   *   localLink: boolean  if it just linked locally and not saved to db yet
   * }
   */
  deleteCaseNoteData(caseNoteKey: number) {
    const noteData = this.getCaseNoteDataByKey(caseNoteKey);
    const localLink = noteData.action === 'LINK';

    // caseNoteKey with < 1 values are generated locally for newly created casenote
    // if linked locally then just remove from this.caseNoteData
    if (caseNoteKey < 1 || localLink) {
      this.caseNotesData = R.reject(this.caseNotesData, (note) => note.tempKey === caseNoteKey);
    } else {
      this.caseNotesData = this.caseNotesData.map((noteData) => {
        if (noteData.tempKey === caseNoteKey) {
          noteData.action = noteData.caseNote.isLinkedCaseNote ? 'UNLINK' : 'DELETE';
        }

        return noteData;
      });
    }
  }

  addLinkCaseNotes(casenotes: CaseNotes[]) {
    const mappedCaseNotes: CaseNotes[] = this.mapCaseNotes(casenotes);
    let linkCaseNotesData = this.mapCaseNotesData(mappedCaseNotes);
    linkCaseNotesData = linkCaseNotesData.map((note) => {
      note.action = 'LINK';
      note.caseNote.isLinked = true;
      return note;
    });

    linkCaseNotesData.forEach((linkNote) => {
      let found = false;
      for (let note of this.caseNotesData) {
        if (note.tempKey === linkNote.tempKey) {
          found = true;
          break;
        }
      }

      // if linked note is found then dont need to update this.caseNotesData
      if (!found) this.caseNotesData.push(linkNote);
    });
  }

  /**
   * getCaseNotesData
   *
   * get casenotes which ar enot deleted
   */
  getCaseNotesData() {
    //
    const filteredNotes = R.reject(this.caseNotesData, (noteData) => {
      return !!noteData.action && (noteData.action === 'DELETE' || noteData.action === 'UNLINK');
    });
    return filteredNotes.map((obj) => obj.caseNote);
  }

  getLinkedCaseNotes() {
    return R.filter(this.caseNotesData, (noteData) => noteData?.action === 'LINK').map((noteData) => noteData.caseNote);
  }

  setCaseNotesData(notesData: CaseNotesData[]) {
    this.caseNotesData = notesData;
  }

  getCaseNoteDataByKey(caseNoteKey) {
    return this.caseNotesData.filter((note) => note.caseNote.parentCaseNoteKey === caseNoteKey)[0];
  }

  mapCaseNotes(notes: CaseNoteModel[]): CaseNotes[] {
    const mapped = notes.map((note) => ({
      ...note,
    }));
    return mapped;
  }

  mapCaseNotesData(notes: CaseNotes[]): CaseNotesData[] {
    const caseNotesData = notes.map((data) => {
      return {
        tempKey: data.parentCaseNoteKey,
        caseNote: {
          ...data,
          isLocked: data.logTypeCode === 'SYSTEM',
        },
      };
    });

    return caseNotesData;
  }

  sanitizeCaseNoteForm() {
    this.caseNoteForm = null;
    this.caseNoteFormGroup = null;
    this.selectedCaseNoteKey = null;
  }

  sanitizeData() {
    // #HTODO: Sanitize casenotes forms, state, keys, currentkey, selectedkey, subscriptions
    this.sanitizeCaseNoteForm();
    this.caseNotesViewType = null;
    this.parentCategoryCode = null;
    this.caseNotesData = [];
    this.caseNotesListDataSub$.next([]);

    this.outcomeClaims = [];
    this.selectedOutcomeClaimKey = null;
  }

  loadAllCaseNotes$(parentModule: string, parentKey: number): Observable<Array<CaseNoteModel>> {
    switch (parentModule) {
      case 'case-load':
        return this.caseService.apiCaseParentCaseKeyCaseNotesGet$Json({
          parentCaseKey: parentKey,
        });
    }
  }

  loadSecondarySubCategories$(primaryCode: string): Observable<Array<{ value: string; description: string }>> {
    return this.lookupService
      .apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json({
        codeTableName: CodeTables.SecondaryCategory,
        parentCodeField: 'PrimaryCategoryCode',
        parentCode: primaryCode,
      })
      .pipe(
        map((codeTable: CodeTableModel[]) => {
          return codeTable.map((codeTable: CodeTableModel) => {
            let lookup = {
              value: codeTable.code,
              description: codeTable.description,
            };
            return lookup;
          });
        }),
        tap((data) => (this.caseNoteSubcategoryList = data))
      );
  }

  loadContactTypes$(): Observable<Array<{ value: string; description: string }>> {
    return this.lookupService
      .apiLookupCodeTableNameGet$Json({
        codeTableName: CodeTables.LogType,
      })
      .pipe(
        map((codeTable: CodeTableModel[]) => {
          return codeTable
            .map((codeTable: CodeTableModel) => {
              let lookup = {
                value: codeTable.code,
                description: codeTable.description,
              };
              return lookup;
            })
            .filter((option) => option.value !== 'SYSTEM');
        }),
        tap((data) => (this.caseNoteContactTypeList = data))
      );
  }

  /**
   * getParentExpenditureAvailableCaseNotes
   *
   * get available casenotes to link to expenditure
   */
  getParentExpenditureAvailableCaseNotes(parentExpenditureKey: number, parentCaseKey: number) {
    // #TODO: replace with actual api call when its ready
    // return this.caseService.apiCaseParentCaseKeyCaseNotesGet$Json$Response({
    //   parentExpenditureKey,
    //   parentCaseKey,
    // });
  }

  /**
   * getParentOutcomeClaimAvailableCaseNotes
   *
   * get available Casenotes to link to outcome claims
   */
  getParentOutcomeClaimAvailableCaseNotes(parentOutcomeClaimKey: number, parentCaseKey: number) {
    return this.outcomeSvc.apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseKeyGet$Json$Response({
      parentOutcomeClaimKey,
      parentCaseKey,
    });
  }

  async submitParentOutcomeClaimCaseNotes(parentOutcomeClaimKey: number) {
    if (this.caseNotesData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentOutcomeClaimKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} submitPrentOutcomeClaimCasenotes : parentOutcomeClaimKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    console.log('caseNotesData', this.caseNotesData);

    // Remove casenotes without action
    const notesWithAction = R.reject(this.caseNotesData, (note) => !note.action);
    console.log('notes With Action', notesWithAction);
    const caseNotesPromises = R.flatMap(notesWithAction, (noteData) => {
      const {
        clientContactDate,
        primaryCategoryCode,
        secondaryCategoryCode,
        logTypeCode,
        comment,
        parentCaseNoteKey,
      } = noteData.caseNote;

      // create new casenoe
      if (noteData.action === 'CREATE') {
        return this.outcomeSvc
          .apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json$Response({
            parentOutcomeClaimKey,
            body: {
              clientContactDate,
              primaryCategoryCode,
              secondaryCategoryCode,
              logTypeCode,
              comment,
            },
          })
          .toPromise();

        // Update casenote
      } else if (noteData.action === 'UPDATE') {
        return this.outcomeSvc
          .apiOutcomeParentOutcomeClaimKeyCaseNotesPost$Json$Response({
            parentOutcomeClaimKey,
            body: noteData.caseNote,
          })
          .toPromise();
        // delete casenote
      } else if (noteData.action === 'DELETE') {
        return this.caseNoteSvc
          .apiCaseNotesParentCaseNoteKeyDelete$Json$Response({
            parentCaseNoteKey,
          })
          .toPromise();

        // Link documents
      } else if (noteData.action === 'LINK') {
        return this.outcomeSvc
          .apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyPost$Json$Response({
            parentOutcomeClaimKey,
            parentCaseNoteKey,
          })
          .toPromise();

        // unlink
      } else if (noteData.action === 'UNLINK') {
        return this.outcomeSvc
          .apiOutcomeParentOutcomeClaimKeyLinkableCaseNotesParentCaseNoteKeyDelete$Response({
            parentOutcomeClaimKey,
            parentCaseNoteKey,
          })
          .toPromise();
      }
    });

    try {
      if (caseNotesPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        const response = await Promise.all<MultipleEntriesResponse<CaseNoteModel>>(caseNotesPromises);
        let status = 200;
        response.forEach((noteRes) => (noteRes.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      console.log(e);
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.submitParentOutcomeClaimCasenotes: Error saving Case Notes`,
      });
      return e;
    } finally {
      // sanitize document actions to prevent performing same updates to the db again
      this.caseNotesData = R.reject(
        this.caseNotesData,
        (note) => note.action === 'DELETE' || note.action === 'UNLINK'
      ).map((noteData) => {
        return {
          ...noteData,
          action: null,
          fileChanged: false,
        };
      });
    }
  }

  /**
   * TODO: this needs to be either converted to a promise from the subscription or have the callback pulled out of it. There
   * is a race condition with loading the saved casenots
   */
  submit(casenote: any, parentCaseNoteKey: number, moduleKey: number) {
    const caseNoteModel: CaseNoteModel = {};

    if (parentCaseNoteKey > 0) {
      caseNoteModel.parentCaseNoteKey = parentCaseNoteKey;
    }

    caseNoteModel.clientContactDate = casenote.clientContactDate;
    caseNoteModel.primaryCategoryCode = casenote.primaryCategory;
    caseNoteModel.secondaryCategoryCode = casenote.secondaryCategory;
    caseNoteModel.logTypeCode = casenote.contactedBy;
    caseNoteModel.comment = casenote.comment;

    // submit case note
    return this.caseService.apiCaseParentCaseKeyCaseNotesPost$Json({
      parentCaseKey: moduleKey,
      body: caseNoteModel,
    });
  }

  isLocked(caseNote: CaseNoteModel): boolean {
    var createdDateTime = new Date(caseNote.createDateFormatted);
    var datetimeNow = new Date();
    var usersMatch =
      this.globals.username &&
      caseNote.createUserId !== undefined &&
      this.globals.username.toLowerCase() === caseNote.createUserId.toLowerCase();
    var systemGenerated = caseNote?.logTypeCode?.toLowerCase() === 'system';
      var dow = createdDateTime.getDay();
      var days = 5;
      if (dow >= 1)
          days += 2;
      var milliseconds = days * 24 * 60 * 60 * 1000;
      //Greater that 5 business days in milliseconds
      return datetimeNow.getTime() - createdDateTime.getTime() > milliseconds || !usersMatch || systemGenerated;
  }

  getCreatedByText(caseNote: CaseNoteModel): string {
    const displayName = caseNote.logTypeCode.toLowerCase() === 'system' ? 'System' : caseNote.displayName;
    
    return this.translocoService.translateObject("Message.System.CreatedBy", 
    {
      DisplayName: displayName, 
      CreatedDate: caseNote.createDateFormatted
    });
  }

  getAllOutcomeClaimCaseNotes(parentOutcomeClaimKey) {
    return (
      this.outcomeSvc
        .apiOutcomeParentOutcomeClaimKeyCaseNotesGet$Json({
          parentOutcomeClaimKey,
        })
        // Dont show system generated case notes on outcome claims
        .pipe(map((notes) => R.reject(notes, (note) => note.logTypeCode === 'SYSTEM')))
    );
  }

  newModuleCaseNote(module: string, key: number, returnToUrl: string[]) {
    this.ngZone.run(() =>
      this.router.navigate(['clients', 'case-notes', 'new'], {
        queryParams: { caseNoteModule: module, returnTo: returnToUrl },
      })
    );
  }

  viewModuleCaseNote(key: number, returnToUrl: string[]) {
    this.router.navigate(['clients', 'case-notes', key], {
      queryParams: { returnTo: returnToUrl },
    });
  }

  viewAllModuleCaseNotes(module: string, key: number) {
    this.ngZone.run(() =>
      this.router.navigate(['clients', 'case-notes'], {
        queryParams: { caseNoteModule: module },
      })
    );
  }

  isCallingModuleCaseList(): boolean {
    return (
      this.returnPage !== undefined &&
      this.returnPage.findIndex((element) => element !== null && element.includes('case-notes')) > -1
    );
  }

  async backToCallingModule() {
    /* TODO: remove this promise when we fix the slight timing issue with the API */
    await timeoutPromise();
    if (this.isCallingModuleCaseList() || (this.returnPage === undefined && this.returnPage.length == 0)) {
      this.viewAllModuleCaseNotes(this.caseNoteModule, this.caseNoteParentModuleKey);
    } else {
      this.ngZone.run(() => this.router.navigate(this.returnPage));
    }
  }

  // sorting functions (arrays)
  compare(a, b, isAsc): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareText(a, b, isAsc): any {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    if (!fc.errors) return '';
    if (fc.errors.required) return this.translocoService.translateObject("Message.Error.ControlRequired", {ControlName: label});

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translate("Message.Error.IncompletePhoneNumber");
    }

    if (fc.errors.minlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMinimumCharacters", {Minimum: fc.errors.minlength.requiredLength});
    if (fc.errors.maxlength) return this.translocoService.translate("Inquiries.Message.Error.ControlMaximumCharacters", {Maximum: fc.errors.maxlength.requiredLength});
    if (fc.errors.email) return `${label} must be in the format "example@mail.com".`;

    if (fc.errors.name) return `Invalid name.`;

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format ${casefloDateFormat}.`;
  }

  public formError(fg: UntypedFormGroup): boolean {
    return !!fg && fg.invalid;
  }

  public formErrorList(fg: UntypedFormGroup, prefix = ''): string[] {
    if (!fg || fg?.valid) return [];

    const errors = [];

    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push(this.errorMap(control, prefix + this.controlLabel(key)));
        });
      }
    });

    return errors;
  }

  public controlLabel(control: string): string {
    switch (control) {
      case 'comment':
        return 'Comment';
      case 'clientContactDate':
        return 'Contact Date';
      case 'primaryCategory':
        return 'Category';
      case 'secondaryCategory':
        return 'Sub Category';
      case 'contactedBy':
        return 'Contacted By';
    }

    return control;
  }

  //unsets dirty, touched, etc flag
  public cleanForm(fg: UntypedFormGroup) {
    fg.markAsPristine();
    fg.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }
  public formDirty(fg$: Observable<UntypedFormGroup>): boolean {
    let dirty = false;

    fg$.forEach((fg) => {
      if (fg.dirty && fg.touched) dirty = true;
    });

    return dirty;
  }
}
