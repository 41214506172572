/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CapturedIntegrationModel } from "@api/models/captured-integration-model";
import { ProcessResponse } from "@api/models/process-response";
import { IntegrationStatusModel } from '@api/models/integration-status-model';

@Injectable({
    providedIn: 'root',
})
export class IntegrationDashboardService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * Path part for operation apiIntegrationDashboardGetList
     */
    static readonly ApiIntegrationDashboardGetList = '/api/IntegrationDashboard';

    /**
     * Get a list of captured integration records.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiIntegrationDashboardGetList$Json()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetList$Json$Response(): Observable<StrictHttpResponse<Array<CapturedIntegrationModel>>> {

        const rb = new RequestBuilder(this.rootUrl, IntegrationDashboardService.ApiIntegrationDashboardGetList, 'get');

        return this.http.request(rb.build({
            responseType: 'json',
            accept: 'text/json'
        })).pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
                return r as StrictHttpResponse<Array<CapturedIntegrationModel>>;
            })
        );
    }

    /**
     * Get a list of captured integration records.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `apiIntegrationDashboardGetList$Json$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetList$Json(): Observable<Array<CapturedIntegrationModel>> {

        return this.apiIntegrationDashboardGetList$Json$Response().pipe(
            map((r: StrictHttpResponse<Array<CapturedIntegrationModel>>) => r.body as Array<CapturedIntegrationModel>)
        );
    }

    /**
     * Path part for operation apiIntegrationDashboardGetList
     */
    static readonly ApiIntegrationDashboardGetById = '/api/IntegrationDashboard/view/{id}';

    /**
     * Get a single captured integration record by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiIntegrationDashboardGetById$Json()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetById$Json$Response(params: {
        id: number;

    }): Observable<StrictHttpResponse<CapturedIntegrationModel>> {

        const rb = new RequestBuilder(this.rootUrl, IntegrationDashboardService.ApiIntegrationDashboardGetById, 'get');

        if (params) {

            rb.path('id', params.id, {});

        }

        return this.http.request(rb.build({
            responseType: 'json',
            accept: 'text/json'
        })).pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
                return r as StrictHttpResponse<CapturedIntegrationModel>;
            })
        );
    }

    /**
     * Get a single captured integration record by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `apiIntegrationDashboardGetById$Json$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetById$Json(params: {
        id: number;

    }): Observable<CapturedIntegrationModel> {

        return this.apiIntegrationDashboardGetById$Json$Response(params).pipe(
            map((r: StrictHttpResponse<CapturedIntegrationModel>) => r.body as CapturedIntegrationModel)
        );
    }

    /**
     * Path part for operation apiIntegrationDashboardUpdateClient
     */
    static readonly ApiIntegrationDashboardUpdateClient = '/api/IntegrationDashboard/save/';

    /**
     * Get a single captured integration record by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiIntegrationDashboardGetById$Json()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardUpdateClient$Json$Response(params: {
        updatedClient: CapturedIntegrationModel;

    }): Observable<StrictHttpResponse<string>> {

        const rb = new RequestBuilder(this.rootUrl, IntegrationDashboardService.ApiIntegrationDashboardUpdateClient, 'post');

        rb.body(params.updatedClient, 'application/json');

        return this.http.request(rb.build({
            responseType: 'text',
            accept: 'text/json'
        })).pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
                return r as StrictHttpResponse<string>;
            })
        );
    }

    /**
     * Get a single captured integration record by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `apiIntegrationDashboardGetById$Json$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardUpdateClient$Json(params: {
        updatedClient: CapturedIntegrationModel;

    }): Observable<string> {

        return this.apiIntegrationDashboardUpdateClient$Json$Response(params).pipe(
            map((r: StrictHttpResponse<string>) => r.body as string)
        );
    }

    /**
     * Path part for operation apiIntegrationDashboardGetCount
     */
    static readonly ApiIntegrationDashboardGetCount = '/api/IntegrationDashboard/count/';

    /**
     * Get the number of integration issues for user.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `apiIntegrationDashboardGetById$Json()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetCount$Json$Response(): Observable<StrictHttpResponse<number>> {
        const rb = new RequestBuilder(this.rootUrl, IntegrationDashboardService.ApiIntegrationDashboardGetCount, 'get');

        return this.http.request(rb.build({
            responseType: 'text',
            accept: 'text/json'
        })).pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
                return r as StrictHttpResponse<number>;
            })
        );
    }

    /**
     * Get the number of integration issues for user.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `apiIntegrationDashboardGetCount$Json$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    apiIntegrationDashboardGetCount$Json(): Observable<number> {
        return this.apiIntegrationDashboardGetCount$Json$Response().pipe(
            map((r: StrictHttpResponse<number>) => r.body as number)
        );
    }

   /**
     * Path part for operation apiIntegrationStatusClient
     */
   static readonly ApiIntegrationStatusClient = '/api/IntegrationDashboard/status/';

  /**
   * Returns a list of all outcome claim pull records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOutcomePushIntegrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiIntegrationStatusClient$Json$Response(): Observable<StrictHttpResponse<IntegrationStatusModel>> {
    
    const rb = new RequestBuilder(this.rootUrl, IntegrationDashboardService.ApiIntegrationStatusClient, 'get');

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntegrationStatusModel>;
      })
    );
  }
}
