/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DistributionSiteModel } from '../models/distribution-site-model';
import { ProcessResponse } from '../models/process-response';
import { PropertyAssignmentModel } from '../models/property-assignment-model';
import { PropertyAttributeValueModel } from '../models/property-attribute-value-model';
import { PropertyItemModel } from '../models/property-item-model';
import { PropertyRequest } from '../models/property-request';

@Injectable({
  providedIn: 'root',
})
export class PropertyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPropertyDistributionSiteGet
   */
  static readonly ApiPropertyDistributionSiteGetPath = '/api/Property/DistributionSite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteGet$Json$Response(): Observable<StrictHttpResponse<Array<DistributionSiteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DistributionSiteModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteGet$Json(params): Observable<Array<DistributionSiteModel>> {

    return this.apiPropertyDistributionSiteGet$Json$Response().pipe(
      map((r: StrictHttpResponse<Array<DistributionSiteModel>>) => r.body as Array<DistributionSiteModel>)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSitePost
   */
  static readonly ApiPropertyDistributionSitePostPath = '/api/Property/DistributionSite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSitePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSitePost$Json$Response(params?: {
      body?: DistributionSiteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSitePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSitePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSitePost$Json(params?: {
      body?: DistributionSiteModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyDistributionSitePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteDelete
   */
  static readonly ApiPropertyDistributionSiteDeletePath = '/api/Property/DistributionSite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteDelete$Json$Response(params?: {
      body?: DistributionSiteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteDeletePath, 'delete');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteDelete$Json(params?: {
      body?: DistributionSiteModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyDistributionSiteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet
   */
  static readonly ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryGetPath = '/api/Property/DistributionSite/{parentDistributionSiteKey}/Inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet$Json$Response(params: {
    parentDistributionSiteKey: number;

  }): Observable<StrictHttpResponse<Array<PropertyItemModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryGetPath, 'get');
    if (params) {

      rb.path('parentDistributionSiteKey', params.parentDistributionSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyItemModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet$Json(params: {
    parentDistributionSiteKey: number;

  }): Observable<Array<PropertyItemModel>> {

    return this.apiPropertyDistributionSiteParentDistributionSiteKeyInventoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyItemModel>>) => r.body as Array<PropertyItemModel>)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost
   */
  static readonly ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryPostPath = '/api/Property/DistributionSite/{parentDistributionSiteKey}/Inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost$Json$Response(params: {
    parentDistributionSiteKey: string;
      body?: PropertyItemModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryPostPath, 'post');
    if (params) {

      rb.path('parentDistributionSiteKey', params.parentDistributionSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost$Json(params: {
    parentDistributionSiteKey: string;
      body?: PropertyItemModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyDistributionSiteParentDistributionSiteKeyInventoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete
   */
  static readonly ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryDeletePath = '/api/Property/DistributionSite/{parentDistributionSiteKey}/Inventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete$Json$Response(params: {
    parentDistributionSiteKey: string;
      body?: PropertyItemModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryDeletePath, 'delete');
    if (params) {

      rb.path('parentDistributionSiteKey', params.parentDistributionSiteKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete$Json(params: {
    parentDistributionSiteKey: string;
      body?: PropertyItemModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyDistributionSiteParentDistributionSiteKeyInventoryDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteParentDistributionSiteKeyGet
   */
  static readonly ApiPropertyDistributionSiteParentDistributionSiteKeyGetPath = '/api/Property/DistributionSite/{parentDistributionSiteKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteParentDistributionSiteKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyGet$Json$Response(params: {
    parentDistributionSiteKey: number;

  }): Observable<StrictHttpResponse<DistributionSiteModel>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteParentDistributionSiteKeyGetPath, 'get');
    if (params) {

      rb.path('parentDistributionSiteKey', params.parentDistributionSiteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DistributionSiteModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteParentDistributionSiteKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyGet$Json(params: {
    parentDistributionSiteKey: number;

  }): Observable<DistributionSiteModel> {

    return this.apiPropertyDistributionSiteParentDistributionSiteKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DistributionSiteModel>) => r.body as DistributionSiteModel)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet
   */
  static readonly ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGetPath = '/api/Property/DistributionSite/{parentDistributionSiteKey}/Inventory/{parentPropertyItemKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet$Json$Response(params: {
    parentDistributionSiteKey: number;
    parentPropertyItemKey: number;

  }): Observable<StrictHttpResponse<PropertyItemModel>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGetPath, 'get');
    if (params) {

      rb.path('parentDistributionSiteKey', params.parentDistributionSiteKey, {});
      rb.path('parentPropertyItemKey', params.parentPropertyItemKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PropertyItemModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet$Json(params: {
    parentDistributionSiteKey: number;
    parentPropertyItemKey: number;

  }): Observable<PropertyItemModel> {

    return this.apiPropertyDistributionSiteParentDistributionSiteKeyInventoryParentPropertyItemKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PropertyItemModel>) => r.body as PropertyItemModel)
    );
  }

  /**
   * Path part for operation apiPropertyPropertyAttributeTypesPropertyTypeCodeGet
   */
  static readonly ApiPropertyPropertyAttributeTypesPropertyTypeCodeGetPath = '/api/Property/PropertyAttributeTypes/{propertyTypeCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyPropertyAttributeTypesPropertyTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyAttributeTypesPropertyTypeCodeGet$Json$Response(params: {
    propertyTypeCode: null | string;

  }): Observable<StrictHttpResponse<Array<PropertyAttributeValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyPropertyAttributeTypesPropertyTypeCodeGetPath, 'get');
    if (params) {

      rb.path('propertyTypeCode', params.propertyTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyAttributeValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyPropertyAttributeTypesPropertyTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyAttributeTypesPropertyTypeCodeGet$Json(params: {
    propertyTypeCode: null | string;

  }): Observable<Array<PropertyAttributeValueModel>> {

    return this.apiPropertyPropertyAttributeTypesPropertyTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyAttributeValueModel>>) => r.body as Array<PropertyAttributeValueModel>)
    );
  }

  /**
   * Path part for operation apiPropertyPropertyAttributesPropertyTypeCodeGet
   */
  static readonly ApiPropertyPropertyAttributesPropertyTypeCodeGetPath = '/api/Property/PropertyAttributes/{propertyTypeCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyPropertyAttributesPropertyTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyAttributesPropertyTypeCodeGet$Json$Response(params: {
    parentPropertyItemKey: number;
    propertyTypeCode: string;

  }): Observable<StrictHttpResponse<Array<PropertyAttributeValueModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyPropertyAttributesPropertyTypeCodeGetPath, 'get');
    if (params) {

      rb.path('parentPropertyItemKey', params.parentPropertyItemKey, {});
      rb.path('propertyTypeCode', params.propertyTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyAttributeValueModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyPropertyAttributesPropertyTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyAttributesPropertyTypeCodeGet$Json(params: {
    parentPropertyItemKey: number;
    propertyTypeCode: string;

  }): Observable<Array<PropertyAttributeValueModel>> {

    return this.apiPropertyPropertyAttributesPropertyTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyAttributeValueModel>>) => r.body as Array<PropertyAttributeValueModel>)
    );
  }

  /**
   * Path part for operation apiPropertyAssignmentHistoryGet
   */
  static readonly ApiPropertyAssignmentHistoryGetPath = '/api/Property/AssignmentHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyAssignmentHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyAssignmentHistoryGet$Json$Response(params?: {
    parentPropertyItemKey?: number;

  }): Observable<StrictHttpResponse<Array<PropertyAssignmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyAssignmentHistoryGetPath, 'get');
    if (params) {

      rb.query('parentPropertyItemKey', params.parentPropertyItemKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyAssignmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyAssignmentHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyAssignmentHistoryGet$Json(params?: {
    parentPropertyItemKey?: number;

  }): Observable<Array<PropertyAssignmentModel>> {

    return this.apiPropertyAssignmentHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyAssignmentModel>>) => r.body as Array<PropertyAssignmentModel>)
    );
  }

  /**
   * Path part for operation apiPropertyEmployeePropertyParentEmployeeKeyGet
   */
  static readonly ApiPropertyEmployeePropertyParentEmployeeKeyGetPath = '/api/Property/EmployeeProperty/{parentEmployeeKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyEmployeePropertyParentEmployeeKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyEmployeePropertyParentEmployeeKeyGet$Json$Response(params: {
    parentEmployeeKey: number;

  }): Observable<StrictHttpResponse<Array<PropertyAssignmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyEmployeePropertyParentEmployeeKeyGetPath, 'get');
    if (params) {

      rb.path('parentEmployeeKey', params.parentEmployeeKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyAssignmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyEmployeePropertyParentEmployeeKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyEmployeePropertyParentEmployeeKeyGet$Json(params: {
    parentEmployeeKey: number;

  }): Observable<Array<PropertyAssignmentModel>> {

    return this.apiPropertyEmployeePropertyParentEmployeeKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyAssignmentModel>>) => r.body as Array<PropertyAssignmentModel>)
    );
  }

  /**
   * Path part for operation apiPropertyPropertyRequestParentPropertyRequestKeyGet
   */
  static readonly ApiPropertyPropertyRequestParentPropertyRequestKeyGetPath = '/api/Property/PropertyRequest/{parentPropertyRequestKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyPropertyRequestParentPropertyRequestKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyRequestParentPropertyRequestKeyGet$Json$Response(params: {
    parentPropertyRequestKey: number;

  }): Observable<StrictHttpResponse<PropertyRequest>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyPropertyRequestParentPropertyRequestKeyGetPath, 'get');
    if (params) {

      rb.path('parentPropertyRequestKey', params.parentPropertyRequestKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PropertyRequest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyPropertyRequestParentPropertyRequestKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPropertyPropertyRequestParentPropertyRequestKeyGet$Json(params: {
    parentPropertyRequestKey: number;

  }): Observable<PropertyRequest> {

    return this.apiPropertyPropertyRequestParentPropertyRequestKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PropertyRequest>) => r.body as PropertyRequest)
    );
  }

  /**
   * Path part for operation apiPropertyDistributionSiteBulkImportPost
   */
  static readonly ApiPropertyDistributionSiteBulkImportPostPath = '/api/Property/DistributionSite/BulkImport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyDistributionSiteBulkImportPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteBulkImportPost$Json$Response(params?: {
      body?: PropertyItemModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyDistributionSiteBulkImportPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyDistributionSiteBulkImportPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyDistributionSiteBulkImportPost$Json(params?: {
      body?: PropertyItemModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyDistributionSiteBulkImportPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyPropertyAssignmentPost
   */
  static readonly ApiPropertyPropertyAssignmentPostPath = '/api/Property/PropertyAssignment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyPropertyAssignmentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyPropertyAssignmentPost$Json$Response(params?: {
      body?: PropertyAssignmentModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyPropertyAssignmentPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyPropertyAssignmentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyPropertyAssignmentPost$Json(params?: {
      body?: PropertyAssignmentModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyPropertyAssignmentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertyPropertyRequestPost
   */
  static readonly ApiPropertyPropertyRequestPostPath = '/api/Property/PropertyRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertyPropertyRequestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyPropertyRequestPost$Json$Response(params?: {
      body?: PropertyItemModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertyPropertyRequestPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertyPropertyRequestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertyPropertyRequestPost$Json(params?: {
      body?: PropertyItemModel
  }): Observable<ProcessResponse> {

    return this.apiPropertyPropertyRequestPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiPropertySearchInventoryPost
   */
  static readonly ApiPropertySearchInventoryPostPath = '/api/Property/SearchInventory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPropertySearchInventoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertySearchInventoryPost$Json$Response(params?: {
      body?: PropertyItemModel
  }): Observable<StrictHttpResponse<Array<PropertyItemModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyService.ApiPropertySearchInventoryPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PropertyItemModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPropertySearchInventoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPropertySearchInventoryPost$Json(params?: {
      body?: PropertyItemModel
  }): Observable<Array<PropertyItemModel>> {

    return this.apiPropertySearchInventoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PropertyItemModel>>) => r.body as Array<PropertyItemModel>)
    );
  }

}
