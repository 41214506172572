import { Component, Input, ViewChild, ElementRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';

@Component({
  selector: 'cf2-phone-field-top',
  template: `
    <div
      #container
      [ngClass]="{
        'input-field-top-small': small && !fullWidth,
        'input-field-top': !small && !fullWidth,
        'full-width': fullWidth,
        'full-width-small': fullWidth && small
      }"
    >
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label | titlecase }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          type="text"
          autocomplete="off"
          mask="(000) 000-0000"
          [dropSpecialCharacters]="false"
          [formControl]="ctrl"
        />
        <mat-icon matSuffix *ngIf="icon" class="suffix-icon">{{ icon }}</mat-icon>
        <mat-error *ngIf="ctrl.errors?.required">{{ ctrl.errors?.required?.message }}</mat-error>
        <mat-error *ngIf="ctrl.errors?.minLength">Invalid {{ label }}</mat-error>
        <mat-error *ngIf="ctrl.errors?.startsWith">{{'Message.Error.PhoneStartsWith' | transloco}}</mat-error>

        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhoneFieldTopComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = '(999) 999-9999';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;

  @Input() icon: string;

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add('disabled');
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }
}
