import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cf2-info-block',
  template: `
    <button mdcIconButton [disabled]="true" class="info-icon">
      <i mdcTextFieldIcon class="material-icons">info</i>
    </button>
    <div mdcTextField [box]="true" [outlined]="true" [dense]="true">
      <input
        aria-label="info" 
        style="padding: 0px; padding-left: 5px;"
        mdcTextFieldInput
        type="text"
        [(ngModel)]="text"
        [disabled]="true"
        [required]="false"
      />
    </div>
  `,
  styleUrls: ['./info-block.component.scss'],
})
export class InfoBlockComponent implements OnInit {
  @Input() text: string;
  constructor() {}

  ngOnInit(): void {
    this.text = this.text ? this.text : '';
  }
}
