import { Component, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-search-input',
  template: `
    <mat-form-field floatLabel="never" appearance="none">
      <div class="container">
        <span class="material-icons search">search</span>
        <a href="javascript: void(0);" class="material-icons clear" (click)="clearForm()">clear</a>

        <input #searchInput matInput aria-label="Search" autocomplete="off" [formControl]="ctrl" />
      </div>
    </mat-form-field>
  `,
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent {
  @ViewChild('searchInput', { read: ElementRef }) searchInput;

  @Input() ctrl = new UntypedFormControl();

  public clearForm() {
    // clear the value then raise a keyup event
    if (this.searchInput !== undefined) {
      const input = (this.searchInput.nativeElement as undefined) as HTMLInputElement;

      const event = new KeyboardEvent('keyup', {
        bubbles: true,
        cancelable: true,
        shiftKey: false,
      });

      input.value = '';

      input.dispatchEvent(event);
    }
    if (this.ctrl) {
      this.ctrl.patchValue('');
    }
  }
}
