import { UntypedFormGroup } from '@angular/forms';
import { ClientAddressModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';
export type AddressViewType = 'create' | 'edit' | 'view';
export type AddressKeysType = keyof ClientAddressModel;
export type AddressLabelsType = Record<AddressKeysType, string | boolean>;

export interface AddressComponentField extends FormsComponentField {
  key: AddressKeysType;
}

export const ADDRESS_CONFIG: readonly AddressComponentField[] = [
  {
    key: 'code',
    label: 'Type',
    type: 'select',
    required: true,
  },
  {
    key: 'unit',
    label: 'AptSuiteNum',
    type: 'string',
    required: false,
    options: {
      minLength: 1,
    },
  },
  {
    key: 'primary',
    label: 'MakePrimary',
    type: 'checkbox',
    required: false,
  },
  {
    key: 'addressLine1',
    label: 'StreetAddress1',
    type: 'longString',
    required: true,
  },
  {
    key: 'addressLine2',
    label: 'StreetAddress2',
    type: 'longString',
    required: false,
  },
  {
    key: 'city',
    label: 'City',
    type: 'longString',
    required: true,
  },
  {
    key: 'provinceCode',
    label: 'Province',
    type: 'select',
    required: true,
  },
  {
    key: 'postalCode',
    label: 'PostalCode',
    type: 'postal',
    required: true,
  },
  {
    key: 'addressEffectiveDate',
    label: 'EffectiveDate',
    type: 'date',
    required: false,
  },

  {
    key: 'addressExpiryDate',
    label: 'ExpiryDate',
    type: 'date',
    required: false,
  },
];

export class AddressForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: AddressLabelsType;
    required: AddressLabelsType;
    disabled: AddressLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, ts : TranslocoService, opts?: { value?: any }) {
    super(fb, ts);
    const config = ADDRESS_CONFIG;

    this._fields = opts ? (this.makeFields([...config], opts) as any) : (this.makeFields([...config]) as any);
    // console.log(this._fields);
  }
}
