import { HttpResponse } from '@angular/common/http';
import * as R from 'remeda';
import { of } from 'rxjs';

export class DevHelpers {
  /**
   * Generate a random number.
   * @param max - the max value. Defaults to 100.
   * @returns - random number
   */

  static randomString(size?: number) {
    return () => R.randomString(size | 6);
  }
}
