import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { UserStateService } from '@core/services/user-state.service';
const authRq = { scopes: ['user.read'], prompt: 'select_account' };

@Injectable({
  providedIn: 'root',
})
export class Cf2AdminGuard implements CanActivate {
  user: AccountInfo;
  authRq: { scopes: string[]; prompt: string };
  constructor(
    // @Inject(CF2_AUTH_REQUEST) private authRq: Cf2AuthRequestType,
    private userState: UserStateService,
    private router: Router
  ) {
    this.authRq = authRq;
  }
  /* TODO: @SH - change the super user role to be an environment variable in config */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve) => {
      this.userState.modules$.subscribe((res) => {
        if (res?.length > 0) {
          const roleCode = this.userState.getProp('roleCode');
          if (!roleCode || !['HD', 'SCM', 'SCO'].includes(roleCode as string)) this.router.navigate(['home']);
          resolve(['HD', 'SCM', 'SCO'].includes(roleCode as string));
        }
      });
    });
  }
}
