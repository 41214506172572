import { Component, Input, ViewChild, ElementRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'cf2-sin-field-top-new',
  template: `
    <div 
      #container
      [ngClass]="{
        'input-field-container':'input-field-container'
      }"
    >
      <mat-form-field appearance="outline" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #input
          matInput
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          type="text"
          autocomplete="off"
          mask="000-000-000"
          [dropSpecialCharacters]="false"
          [formControl]="ctrl"
        />
        <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
        <mat-error *ngIf="ctrl && ctrl['errorMessage']">{{ ctrl['errorMessage'] }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['../input-field-top-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SinFieldTopNewComponent implements AfterViewChecked {
  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() hasError = false;
  @Input() errorText = '';
  @Input() idField: string;
  @Input() placeHolder = '999-999-999';
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth: false;
  @Input() labelVisible = true;

  validSinFormat: boolean;

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.setDisabled();
  }

  public updateValue(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.value = this.value;
  }

  public setDisabled(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.disabled = true;

    // update class
    const container: HTMLInputElement = this.container.nativeElement as HTMLInputElement;
    container.classList.add("disabled");
  }

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }
}
