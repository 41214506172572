import { UntypedFormGroup } from '@angular/forms';
import { ClientModel, CaseModel, CaseSiteModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { TranslocoService } from '@ngneat/transloco';

export type CaseKeysType = keyof CaseModel;
export type ClientKeysType = keyof ClientModel;
export type CaseSiteKeysType = keyof CaseSiteModel;

export type ClientCaseLabelsType = Record<CaseKeysType | ClientKeysType | CaseSiteKeysType, string | boolean>;

export interface ClientComponentField extends FormsComponentField {
  key: ClientKeysType;
}

export interface CaseComponentField extends FormsComponentField {
  key: CaseKeysType;
}

export interface CaseSiteComponentField extends FormsComponentField {
  key: CaseSiteKeysType;
}

export interface ClientCaseComponentField extends FormsComponentField {
  key: CaseKeysType | ClientKeysType | CaseSiteKeysType;
}

// Case Form Fields
export const CASE_DETAILS_FORM: readonly CaseComponentField[] = [
  {
    label: 'Organization',
    key: 'parentOrganizationalUnitKey',
    type: 'select',
    required: false,
  },
  {
    label: 'OrganizationSlashSite',
    key: 'parentSiteKey',
    type: 'select',
    required: true,
  },
  {
    key: 'caseStatusCode',
    label: 'Status',
    type: 'select',
    required: true,
  },
  {
    key: 'exitReasonCode',
    label: 'Exited Reason',
    type: 'select',
    required: false,
  },
  {
    key: 'exitReasonOthDescription',
    label: 'Other Reason',
    type: 'string',
    required: false,
  },
  {
    key: 'mbmAssessmentCode',
    label: 'MbmAssessmentCode',
    type: 'select',
    required: false,
  },
  {
    key: 'camsIntegrationDateFormatted',
    label: 'LastCaMSIntegrationDate',
    type: 'string',
    required: false,
  },
  {
    key: 'parentEmployeeKey',
    label: 'PrimaryConsultant',
    type: 'select',
    required: false,
  },
  {
    key: 'parentProgramStreamKey',
    label: 'Stream',
    type: 'select',
    required: false,
    disabled: true,
  },
  {
    key: 'parentProgramKey',
    label: 'Program',
    type: 'select',
    required: true,
  },
  {
    key: 'incomeSourceCode',
    label: 'IncomeSource',
    type: 'select',
    required: false,
    disabled: true,
  },
  {
    key: 'intakeSourceCode',
    label: 'IntakeSource',
    type: 'string',
    required: true,
  },
  {
    key: 'subIntakeSourceCode',
    label: 'Other-Description',
    type: 'string',
    required: false,
  },
  {
    key: 'intakeSourceCodeReferral',
    label: 'ReferralSource',
    type: 'string',
    required: false,
  },
  {
    key: 'intakeSourceCodeOther',
    label: 'Other-Description',
    type: 'string',
    required: false,
  },  
  {
    key: 'primaryContactType', /// Priamry Contact code
    label: 'PreferredContact',
    type: 'select',
    required: false,
  },
  {
    key: 'primaryEmail',
    label: 'Email',
    type: 'email',
    required: true,
  },
  {
    key: 'primaryPhone',
    label: 'PhoneNo',
    type: 'phone',
    required: true,
  },
  {
    key: 'primaryMobile',
    label: 'MobilePhone',
    type: 'sms',
    required: false,
  },
  {
    key: 'idValidated',
    label: 'Message.Notes.IdValidated',
    type: 'checkbox',
    required: false,
  },
  {
    label: 'IDType',
    key: 'idCode',
    type: 'idValidated',
    required: false,
  },
  {
    key: 'cwCollabComplete',
    label: 'SA Collaboration Confirmation',
    type: 'checkbox',
    required: false,
  },
  {
    key: 'cwCollabCompleteDate',
    label: 'Completion Date',
    type: 'date',
    required: true,
  },
  {
    key: 'contractTypeCode',
    label: 'Contract Type',
    type: 'select',
    required: true,
  },
];

// Client Form Fields
export const CLIENT_DETAILS_FORM: ClientComponentField[] = [
  {
    key: 'firstName',
    label: 'FirstName',
    type: 'name',
    required: true,
  },
  {
    key: 'lastName',
    label: 'LastName',
    type: 'name',
    required: true,
  },
  {
    key: 'preferredName',
    label: 'PreferredName',
    type: 'string',
    required: false,
  },
  {
    key: 'birthDate',
    label: 'DateofBirth',
    type: 'birthdate',
    required: true,
  },
  {
    key: 'clientBusinessKey',
    label: 'SIN',
    type: 'sin',
    required: false,
  },
  {
    key: 'clientBusinessKey3',
    label: 'CaMSPersonReference#',
    type: 'cams',
    required: false,
    disabled: true,
  },
  {
    key: 'genderCode',
    label: 'IdentifyAs',
    type: 'select',
    required: true,
  },
  {
    key: 'languageCode',
    label: 'PreferredLanguage',
    type: 'select',
    required: false,
  },
  {
    key: 'otherLanguage',
    label: 'OtherLanguage',
    type: 'otherLanguage',
    required: false,
  },
  {
    key: 'maritalStatusCode',
    label: 'Currently Not used in UI ',
    type: 'select',
    required: false,
  },
  {
    label: 'Deceased Date',
    key: 'deathDate',
    type: 'date',
    required: false,
  },
];

export const CLIENT_CASE_DETAILS_FORM: ClientCaseComponentField[] = [...CASE_DETAILS_FORM, ...CLIENT_DETAILS_FORM];

export class ClientCaseForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: ClientCaseLabelsType;
    required: ClientCaseLabelsType;
    disabled: ClientCaseLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, protected transloco : TranslocoService, opts?: { value?: any }) {
    super(fb, transloco);
    const config = CLIENT_CASE_DETAILS_FORM;
    this._fields = this.makeFields([...config]) as any;
  }
}
