import { Component } from '@angular/core';

@Component({
  selector: 'cf2-transition-screen',
  templateUrl: './transition-screen.component.html',
  styleUrls: ['./transition-screen.component.css']
})
export class TransitionScreenComponent  {


}
