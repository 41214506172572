import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { FormsComponentField } from '@core/models/base-component-field';
import { casefloDateFormat, CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from '../../models/date-formats.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@Component({
  selector: 'cf2-date-field-fixed',
  template: `
    <div #container class="input-field-fixed">
      <mat-form-field appearance="standard" floatLabel="always" #elRef>
        <mat-label *ngIf="labelVisible" for="{{ idField }}">
          {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
        </mat-label>
        <input
          #dateInput
          matInput
          [matDatepicker]="datepicker"
          [formControl]="ctrl"
          id="{{ idField }}"
          name="{{ idField }}"
          placeholder="{{ placeHolder }}"
          value="{{ value }}"
          [min]="minDate"
          [max]="maxDate"
          autocomplete="off"
          [disableControl]="disabled"
          [cf2Field]="field"
          [isRequired]="required"
        />

        <mat-datepicker-toggle matSuffix [for]="datepicker" [disabled]="disabled" #date></mat-datepicker-toggle>
        <!--<mat-datepicker [startAt]="defaultDate" startView="month" #datepicker></mat-datepicker>-->
        <mat-datepicker startView="month" #datepicker></mat-datepicker>
        <mat-error *ngIf="ctrl.errors?.cannotBeBeforeEapStartDate">Invalid Date</mat-error>
        <mat-error *ngIf="ctrl && ctrl['errorMessage'] && !ctrl.errors?.cannotBeBeforeEapStartDate">{{
          ctrl['errorMessages'][0] ? ctrl['errorMessages'][0] : ctrl.errors
        }}</mat-error>
      </mat-form-field>
    </div>
  `,
  styleUrls: ['./input-field-fixed.component.scss'],
  providers: [
    //{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
   // { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    {
      provide: DateAdapter,
      useClass: CustomDatePickerAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
})
export class DateFieldFixedComponent {
  @Input() field: FormsComponentField;
  @ViewChild('dateInput') input: ElementRef;
  @ViewChild('container') container: ElementRef;
  @Input() hasCustomError = false;

  @Input() label: string;
  @Input() ctrl = new UntypedFormControl();
  @Input() required = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() defaultDate = '';
  @Input() errorText: string;
  @Input() idField: string;
  @Input() placeHolder = casefloDateFormat;
  @Input() value = '';
  @Input() small = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() labelVisible = true;

  private trackDateValue = '';

  public setFocus(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.focus();
  }

  public select(): void {
    const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
    input.select();
  }

  pause() {
    let x = this;
    //debugger ;
  }
}
