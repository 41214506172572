import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    // timeout requird to update the disable or enable change to dom
    setTimeout(() => {
      this.ngControl.control[action]({ emitEvent: false, onlySelf: true });
    }, 100);
  }

  constructor(private ngControl: NgControl) {}
}
