import { Cf2ExternalContactsModel } from '../models/cf2-external-contacts.model';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type ExternalContactsKeysType =
  | keyof Cf2ExternalContactsModel
  | 'select'
  | 'deleteIcon'
  | 'createDate'
  | 'updateDate'
  | 'updateUserId';

/* tuple setup for casenote config */
type ExternalContactsHeaderTupleType = [ExternalContactsKeysType, string];

/* actual display configuration */
const EXTERNAL_CONTACTS_LIST_HEADERS: readonly ExternalContactsHeaderTupleType[] = [
  ['parentExternalContactKey', 'ExternalContactId'],
  ['fullName', 'Name'],
  ['title', 'TitleOrRole'],
  ['organizationName', 'Organization'],
  ['email', 'Email'],
  ['phone', 'PhoneNumber'],
  ['deleteIcon', ''],
];

/* list of columns that can be linked */
const EXTERNAL_CONTACTS_LIST_LINK_COLUMNS = ['fullName'];

/* list of columns that can be sorted */
const EXTERNAL_CONTACTS_LIST_SORT_COLUMNS = ['role', 'organizationName'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class ExternalContactsListData extends DataTableSetup {
  /* set the list headers */
  private externalContactsListHeaders = [...EXTERNAL_CONTACTS_LIST_HEADERS];
  private externalContactsListLinkColumns = [...EXTERNAL_CONTACTS_LIST_LINK_COLUMNS];
  private externalContactsListSortColumns = [...EXTERNAL_CONTACTS_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = ExternalContactsListData.linkableColumns(
      ExternalContactsListData.hiddenColumns(
        ExternalContactsListData.sortColumns(
          ExternalContactsListData.initColumns(this.externalContactsListHeaders.map((item) => this.translocoService.translate(item))),
          this.externalContactsListSortColumns
        ),
        []
      ),
      this.externalContactsListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
