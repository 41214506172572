import { Injectable } from '@angular/core';
import { CaseEmployeeModel, EmployeeModel } from '@api/models';
import { SelectOptions } from '@core/models/select-options.model';
import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { CaseEmployeeForm, CaseEmployeeLabelsType } from '../models/case-employee-form.model';
import * as R from 'remeda';
import { BehaviorSubject } from 'rxjs';
import {
  MultipleEntriesData,
  MultipleEntriesResponse,
} from '@shared/components/multiple-entries/multiple-entries.model';
import { MultipleEntriesService } from '@shared/components/multiple-entries/multiple-entries-data.service';
import { LoggingService } from '@core/services/logging.service';
import { CaseService } from '@api/services';
import { map, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

interface EntityData extends MultipleEntriesData<CaseEmployeeModel & EmployeeModel> { }

export interface ICaseToEmployeeMap {
  employeeId: string;
  caseIds: string[];
}

@Injectable({ providedIn: 'root' })
export class CaseEmployeesDataService extends MultipleEntriesService<
  CaseEmployeeModel | EmployeeModel,
  CaseEmployeeLabelsType,
  EntityData
> {
  /**
   * 1. currently set from client-case-detatils-form.component.ts
   * everytime you change the organizations/site in details form
   *
   * 2. Updated when Org/Site is changed. Or when you add employees to contact list
   */
  siteEmployeesList: EmployeeModel[] = [];
  availableSiteEmployeesList: EmployeeModel[] = [];
  /**
   * 1. currently set from client-case-detatils-form.component.ts
   * everytime you change the organizations/site in details form
   *
   * 2. Updated when Org/Site is changed. Or when you add employees to contact list
   */
  siteEmployees$ = new BehaviorSubject<SelectOptions[]>([]);
  availableSiteEmployees$ = new BehaviorSubject<SelectOptions[]>([]);

  caseEmployeeRolesList: SelectOptions[] = [];
  constructor(
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private logSvc: LoggingService,
    private caseSvc: CaseService,
    private translocoService: TranslocoService
  ) {
    super();
  }

  get caseEmployeeRoles$() {
    return this.dataSvc.lookupRecords('CaseRoleType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => {
        this.caseEmployeeRolesList = result;
        console.log(this.caseEmployeeRolesList);
      })
    );
  }

  initForm(value?: Partial<CaseEmployeeModel>) {
    this.formFields = value ? new CaseEmployeeForm(this.fb, this.translocoService, { value: value }) : new CaseEmployeeForm(this.fb, this.translocoService);
    this.formFields.fields.fg.patchValue(value);
    this.formGroup = this.formFields.fields.fg;

    return this.formFields;
  }

  // site employees list is set from client-case-details-form.component.ts
  setSiteEmployeesListOptions(employees: EmployeeModel[], employeesListOptions: SelectOptions[]) {
    // push list of employees contacts tab to show in Add Employee section
    this.siteEmployeesList = employees;
    this.siteEmployees$.next(employeesListOptions);

    this.updateAvailableEmployeesOptions();
  }

  updateAvailableEmployeesOptions() {
    const existingEmployees = this.getEmployeeKeys() || [];
    // get employes which are not already added to this Client/Case
    this.availableSiteEmployeesList = R.filter(
      this.siteEmployeesList,
      (emp) => existingEmployees.indexOf(emp.parentEmployeeKey) === -1
    );

    const availableSiteEmployeesOptions = this.availableSiteEmployeesList.map((emp) => ({
      description: emp.displayName,
      value: emp.parentEmployeeKey,
    }));

    this.availableSiteEmployees$.next(availableSiteEmployeesOptions);
  }

  getEmployeeKeys() {
    return this.entityData.filter((data) => data.action !== 'DELETE').map((data) => data.entity.parentEmployeeKey);
  }

  getEntityData() {
    const filtered = R.reject(this.entityData, (entity) => {
      return !!entity.action && entity.action === 'DELETE';
    });
    return filtered.map((obj) => obj.entity);
  }

  getEntityDataByKey(key: number) {
    return this.entityData.filter((entityData) => entityData.entity.parentEmployeeKey === key)[0];
  }

  addEntityData(entityData: EntityData) {
    const checkExisting = this.getEntityDataByKey(entityData.entity.parentEmployeeKey);
    if (checkExisting) {
      entityData = R.merge(checkExisting, entityData);

      // if action is delete implies its and existing employee which was already added and saved to database
      // so just change the aciton to display
      if (checkExisting.action === 'DELETE') {
        entityData.action = 'DISPLAY';
      } else {
        entityData.action = 'CREATE';
      }

      this.entityData = this.entityData.map((data) => {
        if (data.entity.parentEmployeeKey === entityData.entity.parentEmployeeKey) {
          data = entityData;
        }

        return data;
      });
    } else {
      // create new casenote
      entityData.action = 'CREATE';
      const newEntry = this.constructEntityData(entityData);
      this.entityData.push(newEntry);
    }

    // Update the list of employees to show in the Case employees form
    this.updateAvailableEmployeesOptions();
  }

  constructEntityData(data: EntityData) {
    const entityData = data;

    entityData.entity.parentEmployeeKey = entityData.tempKey;

    entityData.entity = {
      ...R.find(this.availableSiteEmployeesList, (emp) => emp.parentEmployeeKey === entityData.tempKey), // employee record
      ...entityData.entity, /// override employee data with new case employee data
    };

    if (this.caseEmployeeRolesList?.length > 0 && entityData.entity.caseRoleCode) {
      this.caseEmployeeRolesList.forEach((caseRole) => {
        if (caseRole.value === entityData.entity.caseRoleCode)
          entityData.entity.caseRoleDescription = caseRole.description;
      });
    }

    return entityData;
  }

  deleteEntityData(key: number) {
    // if created locally then just remove from this.entityData
    if (key < 1) {
      this.entityData = R.reject(this.entityData, (data) => data.tempKey === key);
    } else {
      this.entityData = this.entityData.map((data) => {
        if (data.tempKey === key) {
          data.action = 'DELETE';
        }

        return data;
      });
    }

    // Update the list of employees to show in the Case employees form
    this.updateAvailableEmployeesOptions();
  }

  mapEntityData(entities: CaseEmployeeModel[]): EntityData[] {
    const entityData = entities.map((data) => {
      return {
        tempKey: data.parentEmployeeKey,
        entity: data,
      };
    });

    return entityData;
  }

  resetPrimaryConsultant() {
    this.entityData = this.entityData.map((data) => {
      if (data.action === 'DISPLAY') {
        data.action = 'CREATE';
      }
      data.entity.caseRoleCode = 'SC';
      data.entity.caseRoleDescription = 'Secondary Consultant';
      return data;
    });
  }

  addUpdatePrimaryConsultant(parentEmployeeKey: number) {
    const existingEmployeeData = this.getEntityDataByKey(parentEmployeeKey);

    // reset to secondary consultant
    // this.resetPrimaryConsultant();

    // if employee is found change the primary consultant
    if (existingEmployeeData) {
      this.entityData = this.entityData.map((data) => {
        if (data.entity.parentEmployeeKey === existingEmployeeData.entity.parentEmployeeKey) {
          data = {
            tempKey: parentEmployeeKey,
            entity: {
              ...existingEmployeeData.entity,
              caseRoleCode: 'PC',
              caseRoleDescription: 'Primary Consultant',
            },
            action: 'DISPLAY',
          };
        } else {
          data.action = 'DISPLAY';
          data.entity.caseRoleCode = "SC";
          data.entity.caseRoleDescription = "Secondary Consultant";
        }

        return data;
      });
    } else {
      const entityData: EntityData = {
        tempKey: parentEmployeeKey,
        entity: {
          parentEmployeeKey,
          caseRoleCode: 'PC',
          caseRoleDescription: 'Primary Consultant',
        },
        action: 'DISPLAY',
      };

      const newEntry = this.constructEntityData(entityData);
      this.entityData.push(newEntry);
    }

    // update the table list
    this.entityListDataSub$.next(this.getEntityData());

    // Update the list of employees to show in the Case employees form
    this.updateAvailableEmployeesOptions();
  }
}
