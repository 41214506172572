import { Injectable, OnDestroy } from '@angular/core';
import { pickCodeTable } from '../models/lookups.model';
import { forkJoin, from, interval, Observable, of, zip } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import * as R from 'remeda';
import {
  CodeTableModel,
  InquiryContactAttemptModel,
  ProcessResponse,
  ProgramStreamModel,
  CodeTables,
  EmployeeModel,
  CaseHeaderModel,
  SiteModel,
  OrganizationModel,
  ClientModel
} from '@api/models';
import { ClientService, SecurityService } from '@api/services';
import { WorkflowDataService } from './workflow-data.service';
import { Globals } from 'src/app/globals';
import {
  LookupService,
  InquiryService,
  CaseService,
  ClientSiteService,
  ApplicationSettingsService,
} from '@api/services';
import { map, concatAll, tap, switchMap } from 'rxjs/operators';
import { OrganizationService } from '@api/services/organization.service';
import { ClientWithCaseHistory } from '@core/models/ClientWithCaseHistory.model';
import { OPEN_CASE_STATUS_CODES } from '@core/constants/cf2-case.constants';
import { TranslocoService } from '@ngneat/transloco';
import { parseDateFormatted8601FromString } from '@shared/models/date-formats.model';

export type LookupKeys = keyof typeof CodeTables;
const DataService_Lookup_Cache = 'DataService_Lookup_Cache_';

@Injectable({
  providedIn: 'root',
})
export class DataService implements OnDestroy {
  fields(): { required: any; fg: any; labels: any; disabled: any } {
    throw new Error('Method not implemented.');
  }
  interval: any;
  programs$ = this.caseSvc.apiCaseProgramsGet$Json$Response();
  defaultOrganizationKey: number;
  parentRegion: any;
  organizations: OrganizationModel[];

  constructor(
    private lookupSvc: LookupService,
    private inquirySvc: InquiryService,
    private caseSvc: CaseService,
    private clientSvc: ClientService,
    private orgSvc: OrganizationService,
    private clientSiteService: ClientSiteService,
    private wfDataSvc: WorkflowDataService,
    private secSvc: SecurityService,
    private globals: Globals,
    private appSettingsSvc: ApplicationSettingsService,
    private translocoService : TranslocoService
  ) {
    
  }

  //refresh the data for lookup over time
  setupLookupRefresh() {
    const refreshTime = 60 * 1000 * 5; // 5 minutes
    this.interval = setInterval(() => {
      const calls: Observable<CodeTableModel[]>[] = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(DataService_Lookup_Cache)) {
          const lookupName = key.substring(DataService_Lookup_Cache.length);
          localStorage.removeItem(key);
          calls.push(this.lookupRecords(lookupName as LookupKeys))
        }
      }

      // throttle the the call for data reload
      zip(interval(3000), from(calls))
        .pipe(map((a, b) => a))
        .subscribe((call) => {
          call[1].subscribe();
        });

    }, refreshTime);
  }

  /**
   * Lookuprecords retrieves codetables from the API/lookup/<codetablename>
   *
   * Backend needs to have the codetables model updated and then new code table object is generated with npm run gen-api.
   * @param {LookupKeys} key
   * @return {*}  {Observable<CodeTableModel[]>}
   * @memberof DataService
   */
  lookupRecords(key: LookupKeys): Observable<CodeTableModel[]> {
    const cachedValue = localStorage.getItem(DataService_Lookup_Cache + key)
    
    // Get data from DB if cached value doesn't exit
    if (!cachedValue) {
      return this.refreshLookupRecords(key);
    }

    let today = parseDateFormatted8601FromString(new Date().toISOString());
    let jsonValue = JSON.parse(cachedValue);

    // Check if data was fetched from DB recently
    if (!jsonValue[0].lastRefresh || today !== jsonValue[0].lastRefresh) {
      return this.refreshLookupRecords(key);
    }
      
    return of(jsonValue)
  }
  
  refreshLookupRecords(key: LookupKeys): Observable<CodeTableModel[]> {
    const rec = pickCodeTable(key);
    return this.lookupSvc.apiLookupCodeTableNameGet$Json({ codeTableName: rec })
      .pipe(map((value) => {
        value.forEach(val => val.lastRefresh = parseDateFormatted8601FromString(new Date().toISOString())); 
        localStorage.setItem(DataService_Lookup_Cache + key, JSON.stringify(value));
        return value;
      }));
  }

  /**
   * Lookup a mapped table
   * @param params - codeTableName is the codeTableName value, parentCodeField is
   * codeTableName of the parent field and parentCode maps to the parentCode for the table with code appended to the end of it, parentCode is the value of the parentCode.
   *
   */
  mappedParentCodeTable(params: { codeTableName: LookupKeys; parentCodeField: string; parentCode: string }) {
    const { codeTableName, parentCodeField, parentCode } = params;
    return this.lookupSvc.apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json({
      codeTableName: pickCodeTable(codeTableName),
      parentCodeField,
      parentCode,
    });
  }

  /**
   * Lookup planitems by subgoal
   * @param subgoal type
   */
  lookupPlanItem(subGoalCode: string) {
    return this.lookupSvc.apiLookupPlanItemTypeSubGoalCodeGet$Json({ subGoalCode });
  }

  lookupDocumentType(documentSectionCode: string) {
    return this.lookupSvc.apiLookupDocumentTypeDocumentSectionCodeGet$Json({ documentSectionCode });
  }

  getStreams(): Observable<ProgramStreamModel[]> {
    const streams = this.programs$.pipe(
      map((programs) =>
        programs.body
          .map((program) => program.parentProgramKey)
          .map((parentProgramKey) => this.caseSvc.apiCaseProgramsParentProgramKeyStreamsGet$Json({ parentProgramKey }))
      ),
      concatAll(),
      concatAll()
    );
    return streams;
  }

  get orgsAndSites() {
    return this.getOrganizations().pipe(
      tap((val: any) => {
        this.organizations = val;
      }),
      map((orgs: any) =>
        R.flattenDeep(
          orgs.map((org) =>
            org.sites.map((site) => ({
              value: site.parentSiteKey,
              description: `${org.organizationName} - ${site.siteName}`,
            }))
          )
        )
      )
    );
  }

  getOrgs$ = this.orgSvc.apiOrganizationOrganizationGet$Json();
  getMonitoringPhase$(caseKey: number) {
    return this.caseSvc.apiCaseParentCaseKeyMonitoringPhaseGet$Json({ parentCaseKey: caseKey });
  }

  get12MonthClaimStatus$(caseKey: number) {
    return this.caseSvc.apiCaseParentCaseKey12MonthClaimStatusGet$Plain({ parentCaseKey: caseKey });
  }

  regions$ = this.orgSvc.apiOrganizationRegionGet$Json({});
  getSites(parentOrganizationalUnitKey = 3) {
    if (!parentOrganizationalUnitKey) console.log('no unit key');
    return this.orgSvc.apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json({
      parentOrganizationalUnitKey: parentOrganizationalUnitKey > 0 ? parentOrganizationalUnitKey : 3,
    });
  }
  
  getOrganizations() {
    return this.parentRegion
      ? this.orgSvc.apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json({
        parentOrganizationalUnitKey: this.parentRegion,
      })
      : this.appSettingsSvc.apiApplicationSettingsGet$Json().pipe(
        switchMap((settings) =>
          this.orgSvc.apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json({
            parentOrganizationalUnitKey: this.parentRegion,
          })
        )
      );
  }

  getAllOrganizations() {
    return this.appSettingsSvc.apiApplicationSettingsGet$Json().pipe(
        switchMap((settings) =>
          this.orgSvc.apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json({
            parentOrganizationalUnitKey: this.parentRegion,
          })
        )
      );
  }

  getRoleOrganizations() {
    const restrRoles = ['EC', 'SM', 'OM'];
    if (restrRoles.includes(this.wfDataSvc.role)) {
      return this.getOrganizations().pipe(
        map((data) => data.filter((org) => org.parentOrganizationalUnitKey === this.globals.organizationKey))
      );
    }
    return this.getOrganizations();
  }

  getEmployeeConsultants() {
    return this.orgSvc.apiOrganizationEmployeesGet$Json();
  }

  filterOrgRole(orgs: OrganizationModel[]) {
    const restrRoles = ['EC', 'SM', 'OM'];
    if (restrRoles.includes(this.wfDataSvc.role)) {
      return orgs.filter((org) => org.parentOrganizationalUnitKey === this.globals.organizationKey);
    }
    return orgs;
  }

  getAllEmployees() {
    return this.secSvc.apiSecurityPositionsGet$Json();
  }

  getEmployees(orgKey: number) {
    return this.getAllEmployees().pipe(map((emps) => emps.filter((emp) => emp.parentOrganizationalUnitKey === orgKey)));
  }

  getOrgEmployees(parentOrganizationKey: number): Observable<EmployeeModel[]> {
    return this.orgSvc.apiOrganizationEmployeesGet$Json({ parentOrganizationKey: parentOrganizationKey });
    }

  getAllOrgEmployees(): Observable<EmployeeModel[]> {
      return this.orgSvc.apiOrganizationEmployeesGet$Json();
  }

  getPreferredLangOpts(): Observable<{ value: number, description: string }[]> {
    return of([
      { "value": 1, "description": "English" },
      { "value": 2, "description": "French" },
      { "value": 4, "description": "Bilingual" },
      { "value": 3, "description": "Other" }
    ]);
  }

  getSitesFromOrgs() {
    return this.orgSvc
      .apiOrganizationRegionParentOrganizationalUnitKeyOrganizationGet$Json({
        parentOrganizationalUnitKey: this.parentRegion,
      })
      .pipe(
        map((res) => {
          let sites: SiteModel[] = [];
          res.forEach((org) => {
            sites = sites.concat(org.sites);
          });
          return sites;
        })
      );
  }

  postContactAttempt(key: number, body: Partial<InquiryContactAttemptModel>): Observable<ProcessResponse> {
    const filtered = R.filter.indexed(Object.keys(body), (key) => body[key]) as any;
    const picked = R.pick(body, filtered) as any;

    if (!key) throw new Error('no key sent to the back-end');

    return this.inquirySvc.apiInquiryParentInquiryKeyContactAttemptPost$Json({ parentInquiryKey: key, body: picked });
  }

  transformContactForm(key: number, fg: UntypedFormGroup) {
    const val = fg.getRawValue();
    const nextContactTime = val.nextContactTime ? val.nextContactTime : '';
    const body = {
      contactCode: val.method,
      contactDate: val.contactDate,
      contactResultCode: val.result,
      callbackDate: val.nextContactDate,
      callbackTimeFormatted: nextContactTime,
      comment: val.comment,
    };

    return body;
  }

  nocTypes$ = this.lookupRecords('NocType').pipe(
    map((res) => res.map((opt) => ({ description: `${opt.code} - ${opt.description}`, value: opt.code })))
  );

  naicsTypes$ = this.lookupRecords('NaicsType').pipe(
    map((result) =>
      result.map((opt) => ({
        description: `${opt.code} - ${opt.description}`,
        value: opt.code,
      }))
    )
  );
  
  static lookupToOptions (result: CodeTableModel[], lang : string) {
    if(lang == "fr"){
      return result.map((value) => ({ value: value.code, description: value.descriptionFr, label: value.descriptionFr, disabled: false }));

    }
    return result.map((value) => ({ value: value.code, description: value.description, label: value.description, disabled: false }));
  }
  static lookupStreamOptions (result: ProgramStreamModel[], lang : string) {
    if(lang == "fr"){
      return result.map((value) => ({ ...value, description: value.programStreamDescriptionFr, label: value.programStreamDescription }));

    }
    return result.map((value) => ({ ...value, description: value.programStreamDescriptionFr, label: value.programStreamDescription }))
  }

  getSiteEmployees(siteKey: number) {
    return this.orgSvc.apiOrganizationSiteParentSiteKeyEmployeesGet$Json({ parentSiteKey: siteKey });
  }

  static employeesToOptions = (result: EmployeeModel[]) =>
    result.map((value) => ({ value: value.parentEmployeeKey.toString(), description: value.displayName }));

  static sliceNocCode(codeDesc: string) {
    if (codeDesc.length < 4) {
      console.log({ lvl: 'ERROR', mssg: 'Invalid noc code in DataService.sliceNocCode' });
      return '0001';
    }
    const int = codeDesc.slice(0, 5);
    if (isNaN(parseInt(int))) {
      console.log({ lvl: 'ERROR', mssg: 'Noc code is NaN in DataService.sliceNocCode' });
      return '0001';
    }
    return int;
  }

  public getCaseHeader(parentCaseKey: number): Observable<CaseHeaderModel> {
    return this.caseSvc.apiCaseParentCaseKeyHeaderGet$Json({ parentCaseKey: parentCaseKey });
  }

  // client facing app services
  public getSiteForClientSite(siteCode: string): Observable<SiteModel> {
    return this.clientSiteService.apiClientSiteSiteCodeGet$Json({ siteCode: siteCode });
  }

  getEmploymentReferralsByCase(caseKey: number) {
    return this.caseSvc.apiCaseParentCaseKeyReferralsGet$Json({
      parentCaseKey: typeof caseKey === 'number' ? caseKey : parseInt(caseKey),
    });
  }

  getEmploymentByCase(caseKey: number) {
    return this.caseSvc.apiCaseParentCaseKeyEmployersGet$Json({
      parentCaseKey: typeof caseKey === 'number' ? caseKey : parseInt(caseKey),
    });
  }

  getClientsWithCaseHistory(clients: ClientModel[]): Observable<ClientWithCaseHistory[]> {
    clients = clients.map((client) => {
      client.phoneNumber = client.phoneNumber
        ? client.phoneNumber
        : client.clientContacts
          ?.filter((contact) => !contact.primaryEmail)
          ?.find((contact) => contact.primaryContact || contact.code === 'HOME')
          ?.value;

      client.email = client.clientContacts
        ?.filter((contact) => contact.primaryEmail)
        ?.map((contact) => contact.code)[0];
      return client;
    });
    return clients.length > 0
      ? forkJoin([
        // get all cases for each client
        ...clients.map((client) =>
          this.clientSvc.apiClientParentClientKeyCasesGet$Json({ parentClientKey: client.parentClientKey })
        ),
      ]).pipe(
        map((clientCases) =>
          clientCases.map((caseHistory, index) => {
            const clientData = clients[index] as ClientWithCaseHistory;
            clientData.cases = caseHistory;
            const openCases = caseHistory.filter((caseData) =>
              OPEN_CASE_STATUS_CODES.includes(caseData.caseStatusCode)
            );

            clientData.hasOpenCase = openCases.length > 0;
            clientData.hasOnlyOneOpenCase = openCases.length === 1;

            if (clientData.hasOnlyOneOpenCase) {
              clientData.openCaseProgram = openCases[0].programName;
              clientData.openCaseStatus = openCases[0].caseStatusDescription;
            }

            clientData.hasActiveEOCase =
              caseHistory.filter((caseData) => caseData.caseStatusCode === 'ACT' && caseData.programName === 'EO')
                .length > 0;

            return clientData;
          })
        )
      )
      : of([]);
  }

  translateDataTables(result: CodeTableModel[]) {
    console.log(this.translocoService.getActiveLang());
    console.log("gfsdfgsdfgdsgf");
    if(this.translocoService.getActiveLang() == 'fr')
      return result.map((item) => ({ ...item, description : item.descriptionFr, canEdit:"false"  } as CodeTableModel ));
    return result;
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getSiteContractTypes(siteKey: number) {
    return this.orgSvc.apiOrganizationSiteContractTypeGet$Json({ siteKey: siteKey });
  }
}
