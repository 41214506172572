import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin } from 'rxjs';
import {
  InquiryModel,
  InquiryContactAttemptModel,
  AssessmentDataModel,
  OrganizationModel,
  SiteModel,
  CodeTables,
  CodeTableModel,
  ProcessResponse,
  OrganizationAreaModel,
} from '@api/models';
import {
  InquiryService,
  
  OrganizationService,
  LookupService,
  ClientService,
} from '@api/services';
import { ERROR_DIALOG, ModalService } from '@shared/services/modal.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { casefloDateFormat, parseDateFormattedFromString, parseTimeFormatted } from "@shared/models/date-formats.model";
import { IInquiryStatusUpdateMap} from '@api/services/inquiry.service';
// filter options
export interface Organization {
  name: string;
  sdos: string[];
  sites: string[];
  selected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InquiriesService {
  public parentInquiryKey = -1;
  public inquiry: InquiryModel;
  public contactAttempts: InquiryContactAttemptModel[];
  public assessments: AssessmentDataModel[];
  public filters = {};

  // inquiry data
  public inquiryReady$ = new BehaviorSubject<boolean>(null);
  public cancelCallAttempt$ = new BehaviorSubject<boolean>(null);
  public loadAssessment$ = new BehaviorSubject<boolean>(null);

  // inquiry contact types
  public contactTypes: CodeTableModel[];
  public statusTypes: CodeTableModel[];

  // organization data
  public regionKey = -1;
  public regions: OrganizationModel[];
  public subRegions: Organization[] = [];
  public organizations: Organization[] = [];
  public sites: SiteModel[] = [];

  // filters
  public statusArray = '';
  public fromDate = '';
  public toDate = '';

  constructor(
    private inquiryService: InquiryService,
    private organizationService: OrganizationService,
    private lookupSvc: LookupService,
    private modalSvc: ModalService,
    private clientSvc: ClientService,
    private translocoService: TranslocoService
  ) {
    // load contact types
    this.loadContactTypes();

    this.loadStatusTypes();

    // load organizations data
    this.loadRegions();
  }

  public loadStatusTypes(): void {
    // contact types
    this.lookupSvc
      .apiLookupCodeTableNameGet$Json({
        codeTableName: CodeTables.InquiryStatusType,
      })
      .subscribe((result) => {
        this.contactTypes = result;
      });
  }

  public loadContactTypes(): void {
    // contact types
    this.lookupSvc
      .apiLookupCodeTableNameGet$Json({
        codeTableName: CodeTables.ContactType,
      })
      .subscribe((result) => {
        this.statusTypes = result;
      });
  }

  public getAllInquiryRecords(): Observable<InquiryModel[]> {
    return this.inquiryService.apiInquiryGet$Json({});
  }

  public getPaginatedInquiryRecords(
      pageSize: number = 15, 
      pageNumber: number = 1, 
      sortColumn: string = "lastName", 
      sortDirection: string = "asc",
      searchStr: string
  ): Observable<Array<InquiryModel>> {
    return this.inquiryService.apiInquiryPaginatedGet$Json({
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchStr: searchStr
    });
  }

  public getFilteredInquiryRecords(
    fromDateString: string,
    toDateString: string,
    status: string
  ): Observable<InquiryModel[]> {
    return this.inquiryService.apiInquiryFilteredGet$Json({
      fromDate: fromDateString,
      toDate: toDateString,
      status: status,
    });
  }

  getAreas$(): Observable<OrganizationAreaModel[]> {
    return this.organizationService.apiOrganizationAreasGet$Json();
  }

  public getNextInquiry(): Observable<InquiryModel> {
    // load inquiry data
    return this.inquiryService.apiInquiryGetNextGet$Json({
      currentInquiryKey: this.parentInquiryKey,
      status: this.statusArray,
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
  }

  public loadInquiryRecord(parentInquiryKey: number, inquiryOnly = false): void {
    this.parentInquiryKey = parentInquiryKey;

    if (this.parentInquiryKey !== null) {
      if (this.parentInquiryKey !== null) {
        if (!inquiryOnly) {
          forkJoin([
            this.inquiryService.apiInquiryParentInquiryKeyGet$Json({
              parentInquiryKey: this.parentInquiryKey,
            }),
            this.inquiryService.apiInquiryParentInquiryKeyContactAttemptGet$Json({
              parentInquiryKey: this.parentInquiryKey,
            }),
            this.inquiryService.apiInquiryParentInquiryKeyAssessmentsGet$Json({
              parentInquiryKey: this.parentInquiryKey,
            }),
          ])
            .pipe(take(1))
            .subscribe(
              (data) => {
                this.inquiry = data[0];
                this.contactAttempts = data[1];
                this.assessments = data[2];

                this.inquiryReady$.next(true); // raise event
              },
              (err) => {
                console.error('Error reading inquiry: ' + err);

                const dialog = ERROR_DIALOG;
                const ref = this.modalSvc.openDialog({ data: dialog });

                typeof ref === 'string' ? undefined : ref.afterClosed();
              }
            );
        } else {
          // load inquiry data
          this.inquiryService
            .apiInquiryParentInquiryKeyGet$Json({
              parentInquiryKey: this.parentInquiryKey,
            })
            .pipe(take(1))
            .subscribe(
              (data) => {
                this.inquiry = data;
                this.inquiryReady$.next(true); // raise event
              },
              (err) => {
                console.error('Error reading contact attempts: ' + err);

                const dialog = ERROR_DIALOG;
                const ref = this.modalSvc.openDialog({ data: dialog });

                typeof ref === 'string' ? undefined : ref.afterClosed();
              }
            );
        }
      }
    }
  }

  // create inquiry record
  public createInquiry(inquiryModel: InquiryModel): Observable<string> {
    return this.inquiryService.apiInquiryPost$Json({ body: inquiryModel });
  }

  // update inquiry record
  public updateInquiry(inquiryModel: InquiryModel): Observable<string> {
    return this.inquiryService.apiInquiryPost$Json({ body: inquiryModel });
  }

  // create client case from Inquiry
  createClientCaseFromInqury(parentInquiryKey, preferredSiteKey) {
    return this.clientSvc.apiClientInquiryPost$Json({
      parentInquiryKey,
      preferredSiteKey,
    });
  }

  createClientCaseFromInquryUsingExistingClient(
    params: Parameters<ClientService['apiClientParentClientKeyInquiryPost$Json']>[0]
  ) {
    return this.clientSvc.apiClientParentClientKeyInquiryPost$Json(params);
  }

  // update inquiry contact attempts
  public updateContactAttempt(attemptModel: InquiryContactAttemptModel): Observable<ProcessResponse> {
    return this.inquiryService.apiInquiryParentInquiryKeyContactAttemptPost$Json({
      parentInquiryKey: this.parentInquiryKey,
      body: attemptModel,
    });
  }

  // load contact attempts
  public loadContactAttempts(parentInquiryKey: number): Observable<InquiryContactAttemptModel[]> {
    this.parentInquiryKey = parentInquiryKey;

    return this.inquiryService.apiInquiryParentInquiryKeyContactAttemptGet$Json({
      parentInquiryKey: this.parentInquiryKey,
    });
  }

  // load assessment data
  public loadAssessmentData(parentInquiryKey: number): Observable<AssessmentDataModel[]> {
    this.parentInquiryKey = parentInquiryKey;

    return this.inquiryService.apiInquiryParentInquiryKeyAssessmentsGet$Json({
      parentInquiryKey: this.parentInquiryKey,
    });
  }

  getCaseHistory(parentClientKey: number) {
    return this.clientSvc.apiClientParentClientKeyCasesGet$Json({ parentClientKey }).toPromise();
  }

  // unlock record
  public unlockInquiry(): void {
    this.inquiryService
      .apiInquiryParentInquiryKeyUnlockPost$Json({
        parentInquiryKey: this.parentInquiryKey,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          // do nothing
        },
        (err) => {
          console.error('Error unlocking inquiry: ' + err);
        }
      );

    // cleanup
    this.inquiry = null;
    this.contactAttempts = null;
    this.assessments = null;
    this.parentInquiryKey = -1;
  }

  public unlockPreviousInquiry(parentInquiryKey): void {
    this.inquiryService
      .apiInquiryParentInquiryKeyUnlockPost$Json({
        parentInquiryKey: parentInquiryKey,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          // do nothing
        },
        (err) => {
          console.error('Error unlocking inquiry: ' + err);
        }
      );
  }

  // load regions
  public loadRegions(): void {
    this.organizationService
      .apiOrganizationRegionGet$Json({})
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.regions = data;

          // for now we'll default to region 1 (if it exists)
          if (this.regions !== undefined && this.regions.length > 0) {
            this.loadSites(this.regions[0].parentOrganizationalUnitKey);
          }
        },
        (err) => {
          console.error('Error reading regions: ' + err);
        }
      );
  }

  // load sites
  public loadSites(parentOrganizationalUnitKey: number): void {
    this.organizationService
      .apiOrganizationRegionParentOrganizationalUnitKeySiteGet$Json({
        parentOrganizationalUnitKey: parentOrganizationalUnitKey,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.sites = data;
          this.buildOrganization();
        },
        (err) => {
          console.error('Error reading sites: ' + err);
        }
      );
  }

  // the master list of organizations and regions will contain everything
  // in the correct hierarchy (not filtered).
  // There will be duplicated Service Delivery Organizations
  private buildOrganization(): void {
    // build sub regions
    for (let i = 0; i < this.sites.length; i++) {
      if (this.subRegions.length === 0) {
        const suborg: Organization = {
          name: this.sites[i].subRegionName,
          sdos: [this.sites[i].organizationName],
          sites: [this.sites[i].siteName],
          selected: true,
        };

        this.subRegions.push(suborg);
      } else {
        const suborg: Organization = {
          name: this.sites[i].subRegionName,
          sdos: [this.sites[i].organizationName],
          sites: [this.sites[i].siteName],
          selected: true,
        };

        this.subRegions.push(suborg);
      }
    }

    // build organizations
    for (let i = 0; i < this.sites.length; i++) {
      if (this.organizations.length === 0) {
        const suborg: Organization = {
          name: this.sites[i].organizationName,
          sdos: [],
          sites: [this.sites[i].siteName],
          selected: true,
        };

        this.organizations.push(suborg);
      } else {
        const suborg: Organization = {
          name: this.sites[i].organizationName,
          sdos: [],
          sites: [this.sites[i].siteName],
          selected: true,
        };

        this.organizations.push(suborg);
      }
    }
  }

  // get the current site details
  public getSite(parentSiteKey: number): SiteModel {
    for (let i = 0; i < this.sites.length; i++) {
      if (this.sites[i].parentSiteKey === parentSiteKey) {
        return this.sites[i];
      }
    }
  }

  getSiteAttributes(parentSiteKey) {
    return this.organizationService.apiOrganizationSiteParentSiteKeyAttributesGet$Json({ parentSiteKey });
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    var transLabel = this.translocoService.translate(label);
    if (!fc.errors) return '';
    if (fc.errors.required) return this.translocoService.translateObject("Message.Error.ControlRequired", {ControlName: label});

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translateObject("Message.Error.IncompletePhoneNumber");
    }

    if (fc.errors.minlength) return  this.translocoService.translateObject("Inquiries.Message.Error.ControlMinimumCharacters", {Minimum: fc.errors.minlength.requiredLength.toString()});
    if (fc.errors.maxlength) return  this.translocoService.translateObject("Inquiries.Message.Error.ControlMaximumCharacters", {Maximum: fc.errors.maxlength.requiredLength.toString()});
    if (fc.errors.email) return this.translocoService.translateObject("Message.Error.EmailFormat", {Email: label});

    if (fc.errors.name) return this.translocoService.translateObject("Message.Error.InvalidName");

    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format YYYY-MM-DD.`;
    if (fc.errors.matDatepickerMin)  return this.translocoService.translateObject("Message.Error.MinimumDateTime", {DateTime: label.toString()});
    if (fc.errors.matDatepickerMax)  return this.translocoService.translateObject("Message.Error.MaximumDateTime", {DateTime: label.toString()});
    if (fc.errors.invalidDate) return this.translocoService.translateObject("Message.Error.DateTimeFormat", {DateTime: label.toString()});
    if (fc.errors.matDatepickerMin) return `${label} is too far in the past.`;
    if (fc.errors.matDatepickerMax) return `${label} is too far in the future.`;
    if (fc.errors.invalidDate) return `${label} is invalid. Please use format ${casefloDateFormat}.`;
  }

  public formError(fg: UntypedFormGroup): boolean {
    return !!fg?.invalid && fg?.touched;
  }

  public formErrorList(fg: UntypedFormGroup): string[] {
    if (!fg || fg.valid || !fg.touched) return [];

    const errors = [];

    Object.keys(fg.controls).forEach((key) => {
      const controlErrors = fg.get(key).errors;
      const control = fg.get(key) as UntypedFormControl;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push(this.errorMap(control, this.controlLabel(key)));
        });
      }
    });

    return errors;
  }

  public controlLabel(control: string): string {
    return this.translocoService.translate(control);
  }

  public formDirty(fg$, formContact?): boolean {
    let dirty = false;

    fg$.forEach((fg) => {
      if (fg.dirty && fg.touched) dirty = true;
    });
    if (formContact?.dirty && formContact.touched) dirty = true;

    return dirty;
  }

  public formReset(fg: UntypedFormGroup): void {
    // fg$.forEach((fg) => {
    fg.markAsPristine();
    // });
  }

  // sorting functions (arrays)
  public compare(a, b, isAsc): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public compareText(a, b, isAsc): any {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }

  public compareDates(a: string, b: string, isAsc): any {
    let aDate: Date;
    let bDate: Date;

    if (a === null) {
      aDate = new Date(2000, 1, 1);
    } else {
      aDate = new Date(a);
    }

    if (b === null) {
      bDate = new Date(2000, 1, 1);
    } else {
      bDate = new Date(b);
    }

    return (aDate > bDate ? -1 : 1) * (isAsc ? 1 : -1);
  }

  parseDate = (eventDate: string): Date => {
    return new Date(eventDate);
  };
  
  parseTimeFormatted = (eventDate: string): string => {
    return parseTimeFormatted(eventDate);
  }

  parseDateFormatted = (eventDate: string): string => {
        // return format: 8:30 AM
    //return this.parseDate(eventDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return parseDateFormattedFromString(eventDate);
  };

  updateInquiriesStatus(InquiryKeys, StatusCode): any{
    const ap = {
      InquiryKeys : InquiryKeys.map(el => el.parentInquiryKey),
      StatusCode : StatusCode
    } as IInquiryStatusUpdateMap;
    
    return this.inquiryService.updateInquiriesStatus(ap);
  }
}
