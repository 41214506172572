import { Component, Input } from '@angular/core';

@Component({
  selector: 'cf2-alt-expansion-panel',
  template: `
    <div mdcCard class="cf2-alt-expansion-panel custom-card">
      <!-- header with expand/collapse //-->
      <mat-toolbar
        mdcRipple
        (click)="toggle()"
        *ngIf="displayHeader && disableExpansion === false && displayTitleText() === false"
      >
        <div class="toolbar-fields">
          <button mat-icon-button>
            <span class="title-span">
              <mat-icon *ngIf="isCollapsable">{{ !isExpanded ? 'add' : 'remove' }}</mat-icon>
              &nbsp;<ng-content select="panel-title"></ng-content>
            </span>
          </button>
        </div>
      </mat-toolbar>

      <mat-toolbar
        mdcRipple
        (click)="toggle()"
        *ngIf="displayHeader && disableExpansion === false && displayTitleText()"
      >
        <div class="toolbar-fields">
          <button mat-icon-button>
            <span class="title-span">
              <mat-icon *ngIf="isCollapsable">{{ !isExpanded ? 'add' : 'remove' }}</mat-icon>
              {{ titleText }}
            </span>
          </button>
          <span class="spacer"></span>
          <span class="subtitle">{{ centreText }}</span>
          <span class="spacer"></span>
          <span class="subtitle">{{ subTitleText }}</span>
        </div>
      </mat-toolbar>

      <!-- header with no expand/collapse //-->
      <mat-toolbar class="no-expansion" *ngIf="displayHeader && disableExpansion">
        <div class="toolbar-fields">
          {{ titleText }}
          <span class="spacer"></span>
          <span class="subtitle">{{ subTitleText }}</span>
        </div>
      </mat-toolbar>

      <!-- card body //-->
      <ng-container *ngIf="isExpanded">
        <div class="card-content">
          <ng-content select="panel-content"></ng-content>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./alt-expansion-panel.component.scss'],
})
export class AltExpansionPanelComponent {
  @Input() isExpanded = true;
  @Input() displayHeader = true;
  @Input() disableExpansion = false;
  @Input() titleText = '';
  @Input() subTitleText: string;
  @Input() centreText: string;
  @Input() isCollapsable = true;
  displayTitleText(): boolean {
    if (this.titleText !== undefined && this.titleText.length > 0) return true;
    return false;
  }

  toggle() {
    if (this.isCollapsable) this.isExpanded = !this.isExpanded;
  }
}
