import { UntypedFormGroup } from '@angular/forms';
import { ClientDisabilityModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco';
export type ClientDisabilityKeysType = keyof ClientDisabilityModel;
export type ClientDisabilityLabelsType = Record<ClientDisabilityKeysType, string | boolean>;

export interface ClientDisabilityComponentField extends FormsComponentField {
  key: ClientDisabilityKeysType;
}

export const DISABILITIES_CONFIG: readonly ClientDisabilityComponentField[] = [
  {
    key: 'code',
    label: 'DisabilityType',
    type: 'select',
    required: true,
  },
  {
    key: 'primary',
    label: 'PrimaryDisability',
    type: 'checkbox',
    required: true,
  },
  {
    key: 'disclosable',
    label: 'Disclosable?',
    type: 'checkbox',
    required: true,
  },
  {
    key: 'comment',
    label: 'ClientCase.Form.Disabilities.DisabilityComment',
    type: 'longString',
    required: false,
  },
  {
    key: 'effectiveDate',
    label: 'EffectiveDate',
    type: 'date',
    required: false,
  },

  {
    key: 'clientDisabilityExpiryDate',
    label: 'ExpiryDate',
    type: 'date',
    required: false,
  },
];

export class DisabilitiesForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: ClientDisabilityLabelsType;
    required: ClientDisabilityLabelsType;
    disabled: ClientDisabilityLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, ts : TranslocoService, opts?: { value?: any }) {
    super(fb, ts);
    const config = DISABILITIES_CONFIG;

    this._fields = opts ? (this.makeFields([...config], opts) as any) : (this.makeFields([...config]) as any);
    // console.log(this._fields);
  }
}
