import { UntypedFormGroup } from '@angular/forms';
import { ClientIdentifierModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { TranslocoService } from '@ngneat/transloco'; 

export type ClientIdentifierViewType = 'create' | 'edit' | 'view';
type tempKeys = 'effectiveDate' | 'expiryDate';
export type ClientIdentifierKeysType = keyof ClientIdentifierModel | tempKeys;
export type ClientIdentifierLabelsType = Record<ClientIdentifierKeysType, string | boolean>;

export interface ClientIdentifierComponentField extends FormsComponentField {
  key: ClientIdentifierKeysType;
}

export const ID_TYPES_CONFIG: readonly ClientIdentifierComponentField[] = [
  {
    key: 'code',
    label: 'Type',
    type: 'select',
    required: true,
  },
  {
    key: 'comment',
    label: 'Value',
    type: 'string',
    required: false,
  },
  {
    key: 'effectiveDate',
    label: 'Effective Date',
    type: 'date',
    required: false,
  },

  {
    key: 'expiryDate',
    label: 'Expiry Date',
    type: 'date',
    required: false,
  },
];

export class IdTypesForm extends Cf2Forms {
  private _fields: {
    fg: UntypedFormGroup;
    labels: ClientIdentifierLabelsType;
    required: ClientIdentifierLabelsType;
    disabled: ClientIdentifierLabelsType;
  };
  get fields() {
    return this._fields;
  }

  constructor(fb: RxFormBuilder, ts : TranslocoService, opts?: { value?: any }) {
    super(fb, ts);
    const config = ID_TYPES_CONFIG;

    this._fields = opts ? (this.makeFields([...config], opts) as any) : (this.makeFields([...config]) as any);
    // console.log(this._fields);
  }
}
