import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalContactModel } from '@api/models';
import { MultipleEntriesComponent } from '@shared/components/multiple-entries/multiple-entries-card.component';
import { CONFIRM_EXTERNAL_CONTACTS_DELETE_DIALOG, ModalService } from '@shared/services/modal.service';
import { ExternalContactsListConfig } from '../external-contacts-list/external-contacts-list.component';
import { ExternalContactsDataService } from '../services/external-contacts-data.service';
import { ExternalContactsLabelsType } from '../models/external-contacts-form.model';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-external-contacts-card',
  template: `
  <ng-container *transloco="let t"> 
    <div class="cf2-multiple-entries-card flex-col">
      <div class="action-buttons flex-row flex-between">
        <h3>{{ t('ExternalContacts') }}</h3>
        <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addForm()">{{ t('Add') }}</button>
      </div>

      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub$ | async as data">
          <cf2-external-contacts-list
            [isready]="isReady"
            [config]="listConfig"
            [data]="data"
            [filteredData]="data"
            (selectedItem)="showEditEntityForm($event)"
            (delete)="canDelete($event)"
          ></cf2-external-contacts-list>
        </ng-container>
      </div>

      <div class="edit-block flex-col" *ngIf="showForm">
        <cf2-external-contacts-form [fields]="fields"></cf2-external-contacts-form>
        <div class="action-buttons flex-row flex-end">
          <button mat-button class="button--secondary" (click)="cancel()">{{ t('Cancel') }}</button>
          <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addNew()">{{ t('Save') }}</button>
          <button mat-button *ngIf="!isReadOnly && editMode" class="button--secondary" (click)="updateEntity()">
          {{ t('Save') }}
          </button>
        </div>
      </div>
    </div>
    </ng-container>
  `,
})
export class ExternalContactsCardComponent
  extends MultipleEntriesComponent<ExternalContactModel, ExternalContactsLabelsType>
  implements OnInit {
  listConfig: ExternalContactsListConfig = {
    showDeleteIcon: true,
  };

  constructor(
    private dataSvc: ExternalContactsDataService,
    router: Router,
    modalSvc: ModalService,
    clientCaseDataSvc: ClientCaseDataService,
    private translocoService: TranslocoService
  ) {
    super(router, modalSvc, clientCaseDataSvc);
  }

  ngOnInit() {
    this.dataSub$ = this.dataSvc.entityListDataSub$;
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear document form data and state
    this.dataSvc.sanitizeData();
  }

  initForm(formValue = {}) {
    const form = this.dataSvc.initForm(formValue);
    this.fields = form.fields;
    this.fg = this.fields.fg;

    setTimeout(() => {
      this.showForm = true;
    }, 100);
  }

  showEditEntityForm(key: number) {
    const entityData = this.dataSvc.getEntityDataByKey(key);

    const formValue: Partial<ExternalContactModel> = {
      parentExternalContactKey: entityData.entity.parentExternalContactKey,
      firstName: entityData.entity.firstName,
      lastName: entityData.entity.lastName,
      title: entityData.entity.title,
      organizationName: entityData.entity.organizationName,
      email: entityData.entity.email,
      phone: entityData.entity.phone,
    };

    this.showEditForm(key, formValue);
  }

  showEditForm(key: number, formValue: Partial<ExternalContactModel>) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedKey === key) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeForm();

    this.editMode = true;
    this.dataSvc.selectedKey = key;
    // TODO: remove timeout
    setTimeout(() => this.initForm(formValue), 10);
  }

  addForm() {
    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    // reset selected casenote
    this.dataSvc.selectedKey = null;
    this.editMode = false;

    const formValue: Partial<ExternalContactModel> = {};
    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    // add to dataSvc.entityData[]
    const newEntry = this.dataSvc.addEntityData({
      tempKey: this.generateKey(),
      entity: this.fg.value,
    });

    this.dataSvc.sanitizeForm();
    this.editMode = false;
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }

  updateEntity() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const entity = this.fg.value as ExternalContactModel;

    // update entity in dataSvc.entityData[]
    this.dataSvc.updateEntityData({
      tempKey: this.dataSvc.selectedKey,
      entity: entity,
    });

    this.dataSvc.sanitizeForm();
    this.editMode = false;
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }

  canDelete(key: number) {
    this.deleteEntity(key);
  }

  deleteEntity(key: number) {
    const confirm_modal_data = CONFIRM_EXTERNAL_CONTACTS_DELETE_DIALOG;

    // Show confirm modal
    const confirmDeleteDialog = this.modalSvc.openDialog({
      data: confirm_modal_data,
    });

    confirmDeleteDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res && res === true) {
          this.dataSvc.deleteEntityData(key);
          // update document list component
          this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

          // save all client case form data to backend
          this.save();
        }
      });
  }

  cancel() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeForm();
  }
}
