/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseNoteModel } from '../models/case-note-model';
import { ProcessResponse } from '../models/process-response';

@Injectable({
  providedIn: 'root',
})
export class CaseNotesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCaseNotesParentCaseNoteKeyGet
   */
  static readonly ApiCaseNotesParentCaseNoteKeyGetPath = '/api/CaseNotes/{parentCaseNoteKey}';

  /**
   * Get case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseNotesParentCaseNoteKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesParentCaseNoteKeyGet$Json$Response(params: {
    parentCaseNoteKey: number;

  }): Observable<StrictHttpResponse<CaseNoteModel>> {

    const rb = new RequestBuilder(this.rootUrl, CaseNotesService.ApiCaseNotesParentCaseNoteKeyGetPath, 'get');
    if (params) {

      rb.path('parentCaseNoteKey', params.parentCaseNoteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CaseNoteModel>;
      })
    );
  }

  /**
   * Get case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseNotesParentCaseNoteKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesParentCaseNoteKeyGet$Json(params: {
    parentCaseNoteKey: number;

  }): Observable<CaseNoteModel> {

    return this.apiCaseNotesParentCaseNoteKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CaseNoteModel>) => r.body as CaseNoteModel)
    );
  }

  /**
   * Path part for operation apiCaseNotesParentCaseNoteKeyDelete
   */
  static readonly ApiCaseNotesParentCaseNoteKeyDeletePath = '/api/CaseNotes/{parentCaseNoteKey}';

  /**
   * Delete case note (soft delete).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseNotesParentCaseNoteKeyDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesParentCaseNoteKeyDelete$Json$Response(params: {
    parentCaseNoteKey: number;

  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CaseNotesService.ApiCaseNotesParentCaseNoteKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentCaseNoteKey', params.parentCaseNoteKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Delete case note (soft delete).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseNotesParentCaseNoteKeyDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesParentCaseNoteKeyDelete$Json(params: {
    parentCaseNoteKey: number;

  }): Observable<ProcessResponse> {

    return this.apiCaseNotesParentCaseNoteKeyDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiCaseNotesFilteredGet
   */
  static readonly ApiCaseNotesFilteredGetPath = '/api/CaseNotes/filtered';

  /**
   * Get a filtered list of case notes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseNotesFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesFilteredGet$Json$Response(params?: {
    fromDate?: null | string;
    toDate?: null | string;
    moduleCode?: null | string;
    primaryCategoryCode?: null | string;
    secondaryCategoryCode?: null | string;
    createdByUserId?: null | string;
    fkrecordKey?: null | number;

  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseNotesService.ApiCaseNotesFilteredGetPath, 'get');
    if (params) {

      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('moduleCode', params.moduleCode, {});
      rb.query('primaryCategoryCode', params.primaryCategoryCode, {});
      rb.query('secondaryCategoryCode', params.secondaryCategoryCode, {});
      rb.query('createdByUserId', params.createdByUserId, {});
      rb.query('fkrecordKey', params.fkrecordKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get a filtered list of case notes.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseNotesFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseNotesFilteredGet$Json(params?: {
    fromDate?: null | string;
    toDate?: null | string;
    moduleCode?: null | string;
    primaryCategoryCode?: null | string;
    secondaryCategoryCode?: null | string;
    createdByUserId?: null | string;
    fkrecordKey?: null | number;

  }): Observable<Array<CaseNoteModel>> {

    return this.apiCaseNotesFilteredGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiCaseNotesPost
   */
  static readonly ApiCaseNotesPostPath = '/api/CaseNotes';

  /**
   * Create case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseNotesPost$Json$Response(params?: {
      body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CaseNotesService.ApiCaseNotesPostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseNotesPost$Json(params?: {
      body?: CaseNoteModel
  }): Observable<ProcessResponse> {

    return this.apiCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
