import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployerPositionReferralModel } from '@api/models';
import { DialogComponent } from '@shared/components/dialog-component/dialog.component';
import * as R from 'remeda';

export interface LinkEmploymentModalData {
  employerPositions: EmployerPositionReferralModel[];
  selectedFields?: EmployerPositionReferralModel[];
}

@Component({
  selector: 'cf2-link-employment-modal',
  template: `
    <h2 mat-dialog-title>Link Employment</h2>
    <mat-dialog-content>
      <cf2-link-employment-list
        (selectionChange)="updateFields($event)"
        [tableData]="tableData"
        [selectedFields]="selectedFields"
      ></cf2-link-employment-list>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="exit(true)">Add</button>
      <button mat-button (click)="exit(false)">Cancel</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./link-employment-dialog.component.scss'],
})
export class LinkEmploymentDialogComponent {
  tableData: EmployerPositionReferralModel[] = [];

  initialData: EmployerPositionReferralModel[] = [];

  selectedFields: EmployerPositionReferralModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LinkEmploymentModalData
  ) {
    if (!data) throw new Error('No data passed to link-employment component');
    const { employerPositions: positions = null, selectedFields = [] } = data;

    /* TODO: execute proper funciton once determined */
    // const mapData = (allPositions: EmployerPositionReferralModel[]) => allPositions;

    // this.selectedFields = selectedFields?.length > 0 ? mapData(positions) : selectedFields;

    this.tableData = R.pipe(
      positions,
      R.map((pos) => ({...pos, selected: false }))
    );
    this.initialData = [...selectedFields]
  }

  updateFields(fields) {
    this.selectedFields = fields;
    console.log(this);
  }

  exit(isAdd = true) {
      console.log(this.selectedFields);
      let initial = new Set(this.initialData.map(emp => emp.parentEmployerPositionKey));
      let newlyAdded = new Set(this.selectedFields.map(emp => emp.parentEmployerPositionKey));
      this.selectedFields = this.tableData.filter(emp => initial.has(emp.parentEmployerPositionKey) || newlyAdded.has(emp.parentEmployerPositionKey));

      this.dialogRef.close(isAdd ? this.selectedFields : this.initialData);
  }
}
