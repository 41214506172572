/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssistanceType } from '../models/assistance-type';
import { CodeTableModel } from '../models/code-table-model';
import { CodeTables } from '../models/code-tables';
import { DocumentType } from '../models/document-type';
import { ExitReasonType } from '../models/exit-reason-type';
import { PaymentType } from '../models/payment-type';
import { PlanItemType } from '../models/plan-item-type';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLookupCodeTableNameGet
   */
  static readonly ApiLookupCodeTableNameGetPath = '/api/Lookup/{codeTableName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupCodeTableNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupCodeTableNameGet$Json$Response(params: {
    codeTableName: CodeTables;

  }): Observable<StrictHttpResponse<Array<CodeTableModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupCodeTableNameGetPath, 'get');
    if (params) {

      rb.path('codeTableName', params.codeTableName, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeTableModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupCodeTableNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupCodeTableNameGet$Json(params: {
    codeTableName: CodeTables;

  }): Observable<Array<CodeTableModel>> {

    return this.apiLookupCodeTableNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CodeTableModel>>) => r.body as Array<CodeTableModel>)
    );
  }

  /**
   * Path part for operation apiLookupCodeTableNameParentCodeFieldParentCodeGet
   */
  static readonly ApiLookupCodeTableNameParentCodeFieldParentCodeGetPath = '/api/Lookup/{codeTableName}/{parentCodeField}/{parentCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json$Response(params: {
    codeTableName: CodeTables;
    parentCodeField: null | string;
    parentCode: null | string;

  }): Observable<StrictHttpResponse<Array<CodeTableModel>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupCodeTableNameParentCodeFieldParentCodeGetPath, 'get');
    if (params) {

      rb.path('codeTableName', params.codeTableName, {});
      rb.path('parentCodeField', params.parentCodeField, {});
      rb.path('parentCode', params.parentCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeTableModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json(params: {
    codeTableName: CodeTables;
    parentCodeField: null | string;
    parentCode: null | string;

  }): Observable<Array<CodeTableModel>> {

    return this.apiLookupCodeTableNameParentCodeFieldParentCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CodeTableModel>>) => r.body as Array<CodeTableModel>)
    );
  }

  /**
   * Path part for operation apiLookupDocumentTypeDocumentSectionCodeGet
   */
  static readonly ApiLookupDocumentTypeDocumentSectionCodeGetPath = '/api/Lookup/documentType/{documentSectionCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupDocumentTypeDocumentSectionCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupDocumentTypeDocumentSectionCodeGet$Json$Response(params: {
    documentSectionCode: null | string;

  }): Observable<StrictHttpResponse<Array<DocumentType>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupDocumentTypeDocumentSectionCodeGetPath, 'get');
    if (params) {

      rb.path('documentSectionCode', params.documentSectionCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupDocumentTypeDocumentSectionCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupDocumentTypeDocumentSectionCodeGet$Json(params: {
    documentSectionCode: null | string;

  }): Observable<Array<DocumentType>> {

    return this.apiLookupDocumentTypeDocumentSectionCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentType>>) => r.body as Array<DocumentType>)
    );
  }

  /**
   * Path part for operation apiLookupPlanItemTypeSubGoalCodeGet
   */
  static readonly ApiLookupPlanItemTypeSubGoalCodeGetPath = '/api/Lookup/planItemType/{subGoalCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupPlanItemTypeSubGoalCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupPlanItemTypeSubGoalCodeGet$Json$Response(params: {
    subGoalCode: null | string;

  }): Observable<StrictHttpResponse<Array<PlanItemType>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupPlanItemTypeSubGoalCodeGetPath, 'get');
    if (params) {

      rb.path('subGoalCode', params.subGoalCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlanItemType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupPlanItemTypeSubGoalCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupPlanItemTypeSubGoalCodeGet$Json(params: {
    subGoalCode: null | string;

  }): Observable<Array<PlanItemType>> {

    return this.apiLookupPlanItemTypeSubGoalCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlanItemType>>) => r.body as Array<PlanItemType>)
    );
  }

  /**
   * Path part for operation apiLookupAssistanceTypeExpenditureTypeCodeGet
   */
  static readonly ApiLookupAssistanceTypeExpenditureTypeCodeGetPath = '/api/Lookup/assistanceType/{expenditureTypeCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupAssistanceTypeExpenditureTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupAssistanceTypeExpenditureTypeCodeGet$Json$Response(params: {
    expenditureTypeCode: null | string;

  }): Observable<StrictHttpResponse<Array<AssistanceType>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupAssistanceTypeExpenditureTypeCodeGetPath, 'get');
    if (params) {

      rb.path('expenditureTypeCode', params.expenditureTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssistanceType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupAssistanceTypeExpenditureTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupAssistanceTypeExpenditureTypeCodeGet$Json(params: {
    expenditureTypeCode: null | string;

  }): Observable<Array<AssistanceType>> {

    return this.apiLookupAssistanceTypeExpenditureTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AssistanceType>>) => r.body as Array<AssistanceType>)
    );
  }

  /**
   * Path part for operation apiLookupPaymentTypeExpenditureTypeCodeGet
   */
  static readonly ApiLookupPaymentTypeExpenditureTypeCodeGetPath = '/api/Lookup/paymentType/{expenditureTypeCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupPaymentTypeExpenditureTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupPaymentTypeExpenditureTypeCodeGet$Json$Response(params: {
    expenditureTypeCode: null | string;

  }): Observable<StrictHttpResponse<Array<PaymentType>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupPaymentTypeExpenditureTypeCodeGetPath, 'get');
    if (params) {

      rb.path('expenditureTypeCode', params.expenditureTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupPaymentTypeExpenditureTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupPaymentTypeExpenditureTypeCodeGet$Json(params: {
    expenditureTypeCode: null | string;

  }): Observable<Array<PaymentType>> {

    return this.apiLookupPaymentTypeExpenditureTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentType>>) => r.body as Array<PaymentType>)
    );
  }

  /**
   * Path part for operation apiLookupExitReasonTypeCaseStatusCodeGet
   */
  static readonly ApiLookupExitReasonTypeCaseStatusCodeGetPath = '/api/Lookup/exitReasonType/{caseStatusCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLookupExitReasonTypeCaseStatusCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupExitReasonTypeCaseStatusCodeGet$Json$Response(params: {
    caseStatusCode: null | string;

  }): Observable<StrictHttpResponse<Array<ExitReasonType>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiLookupExitReasonTypeCaseStatusCodeGetPath, 'get');
    if (params) {

      rb.path('caseStatusCode', params.caseStatusCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExitReasonType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLookupExitReasonTypeCaseStatusCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLookupExitReasonTypeCaseStatusCodeGet$Json(params: {
    caseStatusCode: null | string;

  }): Observable<Array<ExitReasonType>> {

    return this.apiLookupExitReasonTypeCaseStatusCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExitReasonType>>) => r.body as Array<ExitReasonType>)
    );
  }

}
