import { Injectable } from '@angular/core';
import { OrganizationModel, PositionModel } from '@api/models';
import { DataService } from '@core/services/data.service';
import { SelectOptionType } from '@shared/models/select-options.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as R from 'remeda';
import { Globals } from 'src/app/globals';
import { OrganizationService } from '@api/services';
import {  CodeTableModel  } from '@api/models';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class OptionsDataService {
  private _employees: PositionModel[] = [];
  private _orgs: OrganizationModel[] = [];
  private _allSites: SelectOptionType[] = [];
  private _orgSites: SelectOptionType[] = [];
  private _streams: SelectOptionType[] = [];
  private _caseStatusType: SelectOptionType[] = [];
  private _contractTypes: SelectOptionType[] = [];

  private _positionType: SelectOptionType[] = [];
  private _positionOutcome: SelectOptionType[] = [];
  private _referralOutcome: SelectOptionType[] = [];
  private _claimTypeCode: SelectOptionType[] = [];
  private _outcomeResultCode: SelectOptionType[] = [];
  private _claimStatusCode: SelectOptionType[] = [];
  private _outcomeStatusCode: SelectOptionType[] = [];
  private _integrationErrorTypeCode: SelectOptionType[] = [];
  private _expenditureTypeCode: SelectOptionType[] = [];
  private _expenditureStatusCode: SelectOptionType[] = [];
  private _preferredContactType: SelectOptionType[] = [];
  private _inquiryStatusType: SelectOptionType[] = [];
  private _monitoringPhaseType: SelectOptionType[] = [];

  constructor(private dataSvc: DataService, private globals: Globals, private orgSvc: OrganizationService, private translocoService: TranslocoService) { }

  /** SITES & EMPLOYEE DATA TYPES */
  getEmployees$() {
    return this._employees.length > 0
      ? of(this._employees)
      : this.dataSvc.getAllEmployees().pipe(
        map((res) => R.sortBy(res, (re) => re.displayName)),
        tap((emps) => (this._employees = emps))
      );
  }

  getOrganizations$() {
    return this._orgs.length > 0
      ? of(this._orgs)
      : this.dataSvc.getOrganizations().pipe(tap((orgs) => (this._orgs = orgs)));
  }

  getAllOrganizations$() {
    return this._orgs.length > 0
      ? of(this._orgs)
      : this.dataSvc.getAllOrganizations().pipe(tap((orgs) => (this._orgs = orgs)));
  }

  getAllSites$() {
    return this._allSites.length > 0
      ? of(this._allSites)
      : this.dataSvc.getOrganizations().pipe(
        map((orgs: any) =>
          R.flattenDeep(
            orgs.map((org) =>
              org.sites.map((site) => ({
                value: site.parentSiteKey,
                description: (this.translocoService.getActiveLang()=="en")?site.siteName.toString():site.siteNameFr.toString()
              }))
            )
          )
        ),
        tap((sites: SelectOptionType[]) => (this._allSites = sites))
      )
  }

  getOrgSites$() {
    const parentOrganizationalUnitKey = this.globals.organizationKey;

    return this._orgSites.length > 0
      ? of(this._orgSites)
      : this.orgSvc
        .apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json({
          parentOrganizationalUnitKey,
        })
        .pipe(
          map((sites) =>
            sites.map((site) => ({
              value: site.parentSiteKey,
              description: site.siteName,
            }))
          ),
          tap((sites: SelectOptionType[]) => (this._orgSites = sites))
        );
  }

  getStreams$() {
    //debugger ;
    return this._streams.length > 0
      ? of(this._streams)
      : this.dataSvc.getStreams().pipe(
        map((streams) =>
          DataService.lookupStreamOptions(streams, this.translocoService.getActiveLang())
          .map((stream) => {
            return {
              value: stream.programStreamName,
              description: stream.programStreamDescription,
            };
          })
        ),
        tap((res) => (this._streams = res))
      );
  }

  /** CASE DATA TYPES */
  getCaseStatusType$() {
    return this._caseStatusType.length > 0
      ? of(this._caseStatusType)
      : this.dataSvc.lookupRecords('CaseStatusType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._caseStatusType = res))
      );
  }

  getContractTypes$() {
    return this._contractTypes.length > 0
      ? of(this._contractTypes)
      : this.dataSvc.lookupRecords('ContractType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._contractTypes = res))
      );
  }

  /** POSITION DATA TYPES */
  getPositionType$() {
    return this._positionType.length > 0
      ? of(this._positionType)
      : this.dataSvc.lookupRecords('PositionType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._positionType = res))
      );
  }

  getPositionOutcome$() {
    return this._positionOutcome.length > 0
      ? of(this._positionOutcome)
      : this.dataSvc.lookupRecords('PositionOutcomeType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),

        tap((res) => (this._positionOutcome = res))
      );
  }

  getReferralOutcome$() {
    return this._referralOutcome.length > 0
      ? of(this._referralOutcome)
      : this.dataSvc.lookupRecords('ReferralOutcomeType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._referralOutcome = res))
      );
  }

  /** OUTCOME DATA TYPES */
  getClaimTypeCode$() {
    return this._claimTypeCode.length > 0
      ? of(this._claimTypeCode)
      : this.dataSvc.lookupRecords('ClaimType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._claimTypeCode = res))
      );
  }

  getClaimStatusCode$() {
    return this._claimStatusCode.length > 0
      ? of(this._claimStatusCode)
      : this.dataSvc.lookupRecords('ClaimStatusType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._claimStatusCode = res))
      );
  }

  getOutcomeResultCode$() {
    return this._outcomeResultCode.length > 0
      ? of(this._outcomeResultCode)
      : this.dataSvc.lookupRecords('OutcomeResultType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._outcomeResultCode = res))
      );
  }

  getOutcomeStatusCode$() {
    return this._outcomeStatusCode.length > 0
      ? of(this._outcomeStatusCode)
      : this.dataSvc.lookupRecords('OutcomeStatusType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._outcomeStatusCode = res))
      );
  }

   /** CAMS INTEGRATION DATA TYPES */
   getIntegrationErrorTypeCode$() {
    return this._integrationErrorTypeCode.length > 0
      ? of(this._integrationErrorTypeCode)
      : this.dataSvc.lookupRecords('IntegrationErrorType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._integrationErrorTypeCode = res))
      );
  } 

  /** EXPENDITURE DATA TYPES */
  getExpenditureTypeCode$() {
    return this._expenditureTypeCode.length > 0
      ? of(this._expenditureTypeCode)
      : this.dataSvc.lookupRecords('ExpenditureType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._expenditureTypeCode = res))
      );
  }

  getExpenditureStatusCode$() {
    return this._expenditureStatusCode.length > 0
      ? of(this._expenditureStatusCode)
      : this.dataSvc.lookupRecords('ExpenditureStatusType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
        tap((res) => (this._expenditureStatusCode = res))
      );
  }

  getPreferredContactType$(): Observable<SelectOptionType[]> {
    return this._preferredContactType.length > 0
      ? of(this._preferredContactType)
      : this.dataSvc
        .lookupRecords('PreferredContactType')
        .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())))
        // Map locally to how database stores dbo.ClientContact.ContactCode
        // if SMS db store it as MOBI,
        // if PHONE db stores it as HOME
        .pipe(
          map((result) =>
            result.map((contactType) => {
              if (contactType.value === 'SMS') contactType['value'] = 'MOBI';
              if (contactType.value === 'PHONE') contactType['value'] = 'HOME';

              return contactType;
            })
          ),
          tap((res) => (this._preferredContactType = res))
        );
  }

  getInquiryStatusTypes$(): Observable<SelectOptionType[]> {
    return this._inquiryStatusType.length > 0
      ? of(this._inquiryStatusType)
      : this.dataSvc.lookupRecords('InquiryStatusType').pipe(
        map((res) => DataService.lookupToOptions(res, this.translocoService.getActiveLang())),
        tap((res) => (this._inquiryStatusType = res))
      );
  }

  get monitoringPhaseTypes$() {
    return this._monitoringPhaseType.length > 0
      ? this._monitoringPhaseType
      : this.dataSvc.lookupRecords('MonitoringPhaseType').pipe(
        map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang()).filter((opt) => !['ERROR'].includes(opt.value))),
        map((result) =>
          result.sort((a, b) => {
            if (a.description.toLowerCase() < b.description.toLowerCase()) {
              return -1;
            }

            if (a.description.toLowerCase() > b.description.toLowerCase()) {
              return 1;
            }

            return 0;
          })
        )
      );
  }
}
