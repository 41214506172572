import { Injectable } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { map } from 'rxjs/operators';

import * as R from 'remeda';
import { CapturedIntegrationModel } from '@api/models';
import { Globals } from "../../../globals";
import { IntegrationDashboardService } from "@api/services/integration-dashboard.service";

/**
 * rename to organizations data service
 */
@Injectable({
    providedIn: 'root',
})
export class IntegrationDashboardDataService {
    // make this private with a getter & setter
    private _selectedIntegration: CapturedIntegrationModel;

    constructor(private intDashService: IntegrationDashboardService, private globals: Globals) {}

    get selectedIntegration(): CapturedIntegrationModel {
        return this._selectedIntegration;
    }

    set selectedIntegration(integration: CapturedIntegrationModel)  {
        this._selectedIntegration = integration;
    }
    
    get capturedIntegrations$() {
        return this.intDashService.apiIntegrationDashboardGetList$Json().pipe(map((caps) => R.sortBy(caps, (caps) => caps.id)));
    }

    get capturedIntegrationById$() {
        const id = this.globals.dashCapturedId;
        return this.intDashService.apiIntegrationDashboardGetById$Json({id}).pipe();
    }

    get capturedIntegrationCount$() {
        return this.intDashService.apiIntegrationDashboardGetCount$Json().pipe();
    }

    public save(updatedClient: CapturedIntegrationModel) {
        return this.intDashService.apiIntegrationDashboardUpdateClient$Json({updatedClient})
    }    

    getIntegrationStatus() {
        return this.intDashService.apiIntegrationStatusClient$Json$Response();
    }
}
