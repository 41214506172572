import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import * as R from 'remeda';
import { FormsComponentField } from './base-component-field';

export class FormsFns {
  static mapFgResults(pair: [string, UntypedFormGroup]) {
    return pair.length < 2 ? { [pair[0]]: pair[1] } : {};
  }

  static getFc<T extends string>(fg: UntypedFormGroup): (T) => AbstractControl {
    return (key: T) => R.pipe(fg.controls, R.prop(key));
  }

  static keysToArr<T extends FormsComponentField>(arr: readonly T[]) {
    return arr.map((field) => field.key);
  }

  static get formUpdateOpts() {
    return { onlySelf: false, emitEvent: true };
  }

  static disableAll<T extends FormsComponentField>(config: T[]) {
    const cloned = config;
    return cloned.map((field) => ({ ...field, disabled: true }));
  }

  static mapValue<T extends FormsComponentField>(config: T[]) {
    return (value: any = {}) =>
      config.map((field) => ({
        ...field,
        value: typeof value[field.key] === 'number' ? value[field.key] : value[field.key] ? value[field.key] : null,
      }));
  }
}
