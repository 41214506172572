import { Injectable } from '@angular/core';
import { ExternalContactModel, ClientContactModel } from '@api/models';
import { Cf2ExternalContactsModel } from '../models/cf2-external-contacts.model';

import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  ExternalContactsForm,
  ExternalContactsLabelsType,
} from '@shared/components/external-contacts/models/external-contacts-form.model';
import * as R from 'remeda';
import {
  MultipleEntriesData,
  MultipleEntriesResponse,
} from '@shared/components/multiple-entries/multiple-entries.model';
import { MultipleEntriesService } from '@shared/components/multiple-entries/multiple-entries-data.service';
import { LoggingService } from '@core/services/logging.service';
import { CaseService } from '@api/services';
import { Globals } from 'src/app/globals';
import { TranslocoService } from '@ngneat/transloco';
interface EntityData extends MultipleEntriesData<Cf2ExternalContactsModel> {}

@Injectable({ providedIn: 'root' })
export class ExternalContactsDataService extends MultipleEntriesService<
  Cf2ExternalContactsModel,
  ExternalContactsLabelsType,
  EntityData
> {
  getIsReadOnly() {
    return this.globals.viewType === 'VIEW';
  }
  constructor(
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private logSvc: LoggingService,
    private caseSvc: CaseService,
    private globals: Globals,
    private ts : TranslocoService
  ) {
    super();
  }

  get caseKey() {
    return this.globals.caseKey;
  }

  initForm(value?: Partial<ExternalContactModel>) {
    this.formFields = value ? new ExternalContactsForm(this.fb, this.ts, { value: value }) : new ExternalContactsForm(this.fb, this.ts);
    this.formFields.fields.fg.patchValue(value);
    this.formGroup = this.formFields.fields.fg;

    return this.formFields;
  }

  getEntityDataByKey(key: number) {
    return this.entityData.filter((entityData) => entityData.entity.parentExternalContactKey === key)[0];
  }

  addEntityData(entityData: EntityData) {
    // create new casenote
    entityData.action = 'CREATE';
    const newEntry = this.constructEntityData(entityData);
    this.entityData.push(newEntry);

    return newEntry;
  }

  constructEntityData(data: EntityData) {
    const entityData = data;
    entityData.entity.parentExternalContactKey = entityData.tempKey;

    // construct full name
    if (entityData.entity.firstName && entityData.entity.lastName) {
      entityData.entity.fullName = `${entityData.entity.firstName} ${entityData.entity.lastName}`;
    }

    return entityData;
  }

  getEntityData(config: { sanitizeParentKey: boolean } = { sanitizeParentKey: false }) {
    const filtered = R.reject(this.entityData, (entity) => {
      return !!entity.action && entity.action === 'DELETE';
    });
    return filtered.map((obj) => {
      if (config.sanitizeParentKey)
        return {
          ...obj.entity,
          parentExternalContactKey:
            obj.entity.parentExternalContactKey >= 1 ? obj.entity.parentExternalContactKey : null,
        } as ExternalContactModel;
      else return obj.entity;
    });
  }

  updateEntityData(entityData: EntityData) {
    this.entityData = this.entityData.map((data) => {
      if (data.tempKey === entityData.tempKey) {

        // construct updated caseNoteData
        data.entity = {
          ...data.entity,
          ...this.constructEntityData(entityData).entity,
        };


        // Update exising case notes
        if (data.tempKey >= 1) {
          data.action = 'UPDATE';
        }
      }

      return data;
    });
  }

  deleteEntityData(key: number) {
    // if created locally then just remove from this.entityData
    if (key < 1) {
      this.entityData = R.reject(this.entityData, (data) => data.tempKey === key);
    } else {
      this.entityData = this.entityData.map((data) => {
        if (data.tempKey === key) {
          data.action = 'DELETE';
        }

        return data;
      });
    }
  }

  mapEntityData(entities: ExternalContactModel[]): EntityData[] {
    const entityData = entities.map((data) => {
      return {
        tempKey: data.parentExternalContactKey,
        entity: {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
        },
      };
    });

    return entityData;
  }

  getParentCaseKeyExternalContacts(parentCaseKey: number) {
    return this.caseSvc.apiCaseParentCaseKeyExternalContactsGet$Json({
      parentCaseKey,
    });
  }

  async saveParentCaseKeyExternalContacts(parentCaseKey: number) {
    if (this.entityData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentCaseKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} saveParentCaseKeyExternalContacts: parentCaseKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    try {
      // Remove entities without action
      const entitiesWithAction = R.reject(this.entityData, (data) => !data.action);
      console.log('Entities With Action', entitiesWithAction);
      const entityPromises = R.flatMap(entitiesWithAction, (data) => {
        const { firstName, lastName, organizationName, title, email, phone, parentExternalContactKey } = data.entity;

        // create new entity
        if (data.action === 'CREATE') {
          return this.caseSvc
            .apiCaseParentCaseKeyExternalContactsPost$Json$Response({
              parentCaseKey,
              body: {
                firstName,
                lastName,
                organizationName,
                title,
                email,
                phone,
                parentCaseKey,
              },
            })
            .toPromise();

          // update document metadata
        } else if (data.action === 'UPDATE') {
          return this.caseSvc
            .apiCaseParentCaseKeyExternalContactsPost$Json$Response({
              parentCaseKey,
              body: data.entity,
            })
            .toPromise();

          // archive document
        } else if (data.action === 'DELETE') {
          return this.caseSvc
            .apiCaseExternalContactsParentExternalContactKeyDelete$Response({
              parentExternalContactKey,
            })
            .toPromise();
        }
      });

      // console.log(entityPromises);
      if (entityPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        const response = await Promise.all<MultipleEntriesResponse<ClientContactModel>>(entityPromises);
        let status: number = 200;
        response.forEach((res) => (res.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.saveParentCaseKeyExternalContacts: Error saving external contacts`,
      });
      // console.log(e);
      return e;
    } finally {
      // sanitize  actions to prevent performing same updates to the db again
      this.entityData = R.reject(this.entityData, (data) => data.action === 'DELETE').map((data) => {
        return {
          ...data,
          action: null,
        };
      });
    }
  }
}
