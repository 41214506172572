import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Documents } from '@shared/components/documents/models/documents.model';
import { DocumentsListConfig } from '@shared/components/documents/documents-list/documents-list.component';
import { DocumentsDataService } from '@shared/components/documents/services/documents-data.service';
import * as R from 'remeda';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cf2-link-documents-dialog',
  template: `
    <h2 mat-dialog-title>Link Documents</h2>

    <mat-dialog-content>
      <ng-container *ngIf="dataSub | async as data">
        <cf2-documents-list
          [config]="documentsListConfig"
          [isReady]="isReady"
          [caseKey]="caseKey"
          [data]="data"
          [filteredData]="data"
          (selectedDocuments)="onSelectedDocuments($event)"
        >
        </cf2-documents-list>
      </ng-container>

      <div class="loading" *ngIf="!isReady">Documents Loading...</div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <!-- return empty array on cancel -->
      <button mat-button [mat-dialog-close]="[]">Cancel</button>
      <button mat-button [mat-dialog-close]="selectedDocuments" [disabled]="selectedDocuments.length === 0">
        Link
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host ::ng-deep mat-table {
        border: 1px solid $border-color;
      }

      :host .loading {
        text-align: center;
        font-size: 24px;
        padding: 16px;
      }
    `,
  ],
})
export class LinkDocumentsDialogComponent implements OnInit {
  isReady = false;
  documentsListConfig: DocumentsListConfig = {
    showSelection: true,
    showLockIcon: false,
  };
  caseKey: number;
  selectedDocuments: Documents[] = [];
  dataSub: BehaviorSubject<Documents[]> = new BehaviorSubject<Documents[]>([]);

  constructor(
    public dialogRef: MatDialogRef<LinkDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { linkedDocuments: Documents[] },
    public dataSvc: DocumentsDataService
  ) {
    console.log(this.constructor.name);
    console.log(dialogData);
  }

  clicked(evt: any) {
    this.dialogRef.close(true);
  }

  onSelectedDocuments(documents) {
    console.log('onSelectedDocuments', documents);
    this.selectedDocuments = documents;
  }

  /**
   * return documents which are not currently linked to show in the popup modal
   */
  getUnlinkedDocs(documents: Documents[]) {
    if (this.dialogData?.linkedDocuments && this.dialogData.linkedDocuments.length > 0) {
      const linkedDocumentKeys = this.dialogData.linkedDocuments.map((doc) => doc.parentDocumentStoreKey);
      // return documents which are not alreayd linked
      return documents.filter((doc) => linkedDocumentKeys.indexOf(doc.parentDocumentStoreKey) === -1);
    } else {
      return documents;
    }
  }

  processResposne(response) {
    if (response.status !== 200) {
      setTimeout(() => {
        this.isReady = true;
      }, 100);
      return console.log('Data failed to load with ', response.status);
    }

    const responseDocuments: Documents[] = response.body as Documents[];
    // hide already linked documents
    const unlinkedDocs = this.getUnlinkedDocs(responseDocuments);
    const mapped = this.dataSvc.mapDocuments(unlinkedDocs);
    const sorted = R.sortBy(mapped, (rec: Documents) => rec.documentDate).reverse();
    this.dataSub.next(sorted);
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnInit() {
    // get the form fields and send it to file-upload-details
    this.caseKey = this.dataSvc.getCaseKey();
    if (!this.caseKey) throw new Error(`no case key set in ${this.constructor.name} `);

    // console.log(this.dataSvc.documentsViewType);
    const viewType = this.dataSvc.documentsViewType;
    if (!viewType)
      throw new Error(`no view type set in ${this.constructor.name}; Use DocumentDataService.initDocumentCard`);

    // console.log(this.dataSvc.parentCategoryCode);
    const categoryCode: string = this.dataSvc.parentCategoryCode;
    if (!categoryCode) throw new Error(`no category code set in ${this.constructor.name}`);

    if (viewType === 'create') {
      // get filtered documens to show in link documents table
      if (categoryCode === 'EXPENDITURE') {
        this.dataSvc.getExpenditureAvailableDocuments(this.caseKey).subscribe((response) => {
          this.processResposne(response);
        });
      } else if (categoryCode === 'CLAIMMGMT') {
        this.dataSvc.getOutcomeClaimAvailableDocuments(this.caseKey).subscribe((response) => {
          this.processResposne(response);
        });
      } else if (categoryCode === 'EMPLOYMENT') {
        this.dataSvc.getEmploymentAvailableDocuments(this.caseKey).subscribe((response) => {
          this.processResposne(response);
        });
      }

      // view = edit
    } else {
      if (categoryCode === 'EXPENDITURE') {
        const expenditureKey = this.dataSvc.expenditureKey;
        if (!expenditureKey) throw new Error(`no expenditure key set in ${this.constructor.name}`);

        this.dataSvc
          .getParentExpenditureKeyAvailableDocuments(expenditureKey)
          .pipe(take(1))
          .subscribe((response) => {
            this.processResposne(response);
          });
      } else if (categoryCode === 'CLAIMMGMT') {
        const parentOutcomeClaimKey = this.dataSvc.outcomeClaimKey;
        if (!parentOutcomeClaimKey) throw new Error(`no outcome claim key set in ${this.constructor.name}`);

        this.dataSvc
          .getParentOutcomeClaimKeyAvailableDocuments(parentOutcomeClaimKey)
          .pipe(take(1))
          .subscribe((response) => {
            this.processResposne(response);
          });
      } else if (categoryCode === 'EMPLOYMENT') {
        const parentEmployerPositionReferralKey = this.dataSvc.employerPostionReferralKey;
        if (!parentEmployerPositionReferralKey) throw new Error(`no employment key set in ${this.constructor.name}`);

        this.dataSvc
          .getParentEmployerPositionReferralKeyAvailableDocuments(parentEmployerPositionReferralKey)
          .pipe(take(1))
          .subscribe((response) => {
            this.processResposne(response);
          });
      }
    }
  }
}
