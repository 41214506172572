import { ClientDisabilityModel } from '@api/models';
import { DataTableSetup } from '@shared/services/data-table-setup.model';
import { TranslocoService } from '@ngneat/transloco';

/* set keys type to case-ote model keys type. Two values added for display isLocked & icon */
type ClientDisabilityKeysType =
  | keyof ClientDisabilityModel
  | 'select'
  | 'deleteIcon'
  | 'createDate'
  | 'updateDate'
  | 'updateUserId';

/* tuple setup for casenote config */
type ClientDisabilityHeaderTupleType = [ClientDisabilityKeysType, string];

/* actual display configuration */
const DISABILITIES_LIST_HEADERS: readonly ClientDisabilityHeaderTupleType[] = [
  ['key', 'Id'],
  ['disabilityTypeDescription', 'DisabilityType'],
  ['primary', 'Primary'],
  ['disclosable', 'Disclosable?'],
  ['createdDateFormatted', 'CreatedDate'],
  ['createdUserName', 'CreatedBy'],
];

/* list of columns that can be linked */
const DISABILITIES_LIST_LINK_COLUMNS = ['disabilityTypeDescription'];

/* list of columns that can be sorted */
const DISABILITIES_LIST_SORT_COLUMNS = ['disabilityTypeDescription', 'primary', 'disclosable'];

export interface ListConfig {
  linkable: boolean;
  key: string;
  label: string;
  pos?: number;
  hidden?: boolean;
  sort?: boolean;
  centered?: boolean;
}

export class DisabilitiesListData extends DataTableSetup {
  /* set the list headers */
  private disabilitiesListHeaders = [...DISABILITIES_LIST_HEADERS];
  private disabilitiesListLinkColumns = [...DISABILITIES_LIST_LINK_COLUMNS];
  private disabilitiesListSortColumns = [...DISABILITIES_LIST_SORT_COLUMNS];
  private _config: ListConfig[];

  get config() {
    return [...this._config];
  }

  set config(value: ListConfig[]) {
    this._config = value;
  }

  private setupList() {
    const setup = DisabilitiesListData.linkableColumns(
      DisabilitiesListData.hiddenColumns(
        DisabilitiesListData.sortColumns(
          DisabilitiesListData.initColumns(this.disabilitiesListHeaders.map((item)=> [item[0], this.translocoService.translate(item[1])])),
          this.disabilitiesListSortColumns
        ),
        []
      ),
      this.disabilitiesListLinkColumns
    );

    return setup;
  }
  constructor(private translocoService: TranslocoService) {
    super();
    this.config = this.setupList();
  }
}
