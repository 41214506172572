/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CaseModel } from '../models/case-model';
import { CaseNoteModel } from '../models/case-note-model';
import { ClientCaseModel } from '../models/client-case-model';
import { ClientContactModel } from '../models/client-contact-model';
import { ClientDisability } from '../models/client-disability';
import { ClientDisabilityModel } from '../models/client-disability-model';
import { ClientModel } from '../models/client-model';
import { ClientSearchModel } from '../models/client-search-model';
import { ClientSelfIdentificationModel } from '../models/client-self-identification-model';
import { ClientTypeAheadModel } from '../models/client-type-ahead-model';
import { ExpenditureModel } from '../models/expenditure-model';
import { ProcessResponse } from '../models/process-response';

export class ClientFilterParams {
  lastName?: null | string;
  firstName?: null | string;
  email?: null | string;
  dob?: null | string;
  phoneNumber?: null | string;
  bk?: null | string;
  bk2?: null | string;
  soundex?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortColumn?: string;
  sortDirection?: string;
}
export class PagingResult<T> {
  count: number;
  data?: T[];
}

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete
   */
  static readonly ApiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDeletePath =
    '/api/Client/clientSelfIdentifications/{parentClientSelfIdentificationKey}';

  /**
   * Deletes Client Self Identification Model.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete$Response(params: {
    parentClientSelfIdentificationKey: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDeletePath,
      'delete'
    );
    if (params) {
      rb.path('parentClientSelfIdentificationKey', params.parentClientSelfIdentificationKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Deletes Client Self Identification Model.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete(params: {
    parentClientSelfIdentificationKey: number;
  }): Observable<void> {
    return this.apiClientClientSelfIdentificationsParentClientSelfIdentificationKeyDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiClientClientDisabilitiesParentClientDisabilityKeyGet
   */
  static readonly ApiClientClientDisabilitiesParentClientDisabilityKeyGetPath =
    '/api/Client/ClientDisabilities/{parentClientDisabilityKey}';

  /**
   * Gets Client Disability.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientClientDisabilitiesParentClientDisabilityKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientDisabilitiesParentClientDisabilityKeyGet$Json$Response(params: {
    parentClientDisabilityKey: number;
  }): Observable<StrictHttpResponse<ClientDisability>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientClientDisabilitiesParentClientDisabilityKeyGetPath,
      'get'
    );
    if (params) {
      rb.path('parentClientDisabilityKey', params.parentClientDisabilityKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientDisability>;
        })
      );
  }

  /**
   * Gets Client Disability.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientClientDisabilitiesParentClientDisabilityKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientDisabilitiesParentClientDisabilityKeyGet$Json(params: {
    parentClientDisabilityKey: number;
  }): Observable<ClientDisability> {
    return this.apiClientClientDisabilitiesParentClientDisabilityKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientDisability>) => r.body as ClientDisability)
    );
  }

  /**
   * Path part for operation apiClientClientDisabilitiesParentClientDisabilityKeyDelete
   */
  static readonly ApiClientClientDisabilitiesParentClientDisabilityKeyDeletePath =
    '/api/Client/ClientDisabilities/{parentClientDisabilityKey}';

  /**
   * Deletes Client Disabilty.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientClientDisabilitiesParentClientDisabilityKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientDisabilitiesParentClientDisabilityKeyDelete$Response(params: {
    parentClientDisabilityKey: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientClientDisabilitiesParentClientDisabilityKeyDeletePath,
      'delete'
    );
    if (params) {
      rb.path('parentClientDisabilityKey', params.parentClientDisabilityKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Deletes Client Disabilty.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientClientDisabilitiesParentClientDisabilityKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientClientDisabilitiesParentClientDisabilityKeyDelete(params: {
    parentClientDisabilityKey: number;
  }): Observable<void> {
    return this.apiClientClientDisabilitiesParentClientDisabilityKeyDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiClientSearchGet
   */
  static readonly ApiClientSearchGetPath = '/api/Client/search';

  searchForClients(params?: {
    lastName?: null | string;
    firstName?: null | string;
    dob?: null | string;
    sin?: null | string;
    casefloId?: null | string;
    email?: null | string;
    phoneNumber?: null | string;
    soundex?: boolean;
    checkDuplicates?: boolean;
    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortCol: string;
  }): Observable<Array<ClientSearchModel>> {
    let url = `${this.rootUrl}${ClientService.ApiClientSearchGetPath}?${this.objectToQueryString(params)}`;
    return this.http.get<ClientSearchModel[]>(url);
  }

  objectToQueryString(params: any): string {
    if (params) {
      const tempParams = [];
      for (const p in params)
        if (params.hasOwnProperty(p)) {
          tempParams.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
        }
      const queryString = tempParams.join('&');
      return queryString;
    }
    return '';
  }

  /**
   * Path part for operation apiClientTypeaheadGet
   */
  static readonly ApiMultipleClientsBasicInfoPath = '/api/Client/multiple/basic/{clientKeyArrayStr}';

  /**
   * Get a list of ClientTypeAheadModel records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientTypeaheadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMultipleClientsBasicInfoGet$Json$Response(params?: {
    clientKeyArrayStr: null | string;
  }): Observable<StrictHttpResponse<Array<ClientTypeAheadModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiMultipleClientsBasicInfoPath, 'get');
    if (params) {
      rb.path('clientKeyArrayStr', params.clientKeyArrayStr, {});
    }
    return this.http
        .request(
            rb.build({
              responseType: 'json',
              accept: 'text/json',
            })
        )
        .pipe(
            filter((r: any) => r instanceof HttpResponse),
            map((r: HttpResponse<any>) => {
              return r as StrictHttpResponse<Array<ClientTypeAheadModel>>;
            })
        );
  }

  /**
   * Path part for operation apiClientTypeaheadGet
   */
  static readonly ApiClientTypeaheadGetPath = '/api/Client/typeahead';

  /**
   * Get a list of ClientTypeAheadModel records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientTypeaheadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientTypeaheadGet$Json$Response(params?: {
    clientName?: null | string;
    parentSiteKey?: null | number;
  }): Observable<StrictHttpResponse<Array<ClientTypeAheadModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientTypeaheadGetPath, 'get');
    if (params) {
      rb.query('clientName', params.clientName, {});
      rb.query('parentSiteKey', params.parentSiteKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientTypeAheadModel>>;
        })
      );
  }

  /**
   * Get a list of ClientTypeAheadModel records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientTypeaheadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMultipleClientsBasicInfoGet$Json(params?: {
    clientKeyArrayStr: null | string;
  }): Observable<Array<ClientTypeAheadModel>> {
    return this.apiMultipleClientsBasicInfoGet$Json$Response(params).pipe(
        map((r: StrictHttpResponse<Array<ClientTypeAheadModel>>) => r.body as Array<ClientTypeAheadModel>)
    );
  }

  /**
   * Get a list of ClientTypeAheadModel records.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientTypeaheadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientTypeaheadGet$Json(params?: {
    clientName?: null | string;
    parentSiteKey?: null | number;
  }): Observable<Array<ClientTypeAheadModel>> {
    return this.apiClientTypeaheadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientTypeAheadModel>>) => r.body as Array<ClientTypeAheadModel>)
    );
  }

  /**
   * Path part for operation apiClientBusinessKeyGet
   */
  static readonly ApiClientBusinessKeyGetPath = '/api/Client/businessKey';

  /**
   * Get client records by Business Key (e.g. SIN) - returns minimal data only.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientBusinessKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientBusinessKeyGet$Json$Response(params?: {
    /**
     * Primary client business key (e.g. SIN)
     */
    bk?: null | string;
  }): Observable<StrictHttpResponse<Array<ClientModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientBusinessKeyGetPath, 'get');
    if (params) {
      rb.query('bk', params.bk, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientModel>>;
        })
      );
  }

  /**
   * Get client records by Business Key (e.g. SIN) - returns minimal data only.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientBusinessKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientBusinessKeyGet$Json(params?: {
    /**
     * Primary client business key (e.g. SIN)
     */
    bk?: null | string;
  }): Observable<Array<ClientModel>> {
    return this.apiClientBusinessKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientModel>>) => r.body as Array<ClientModel>)
    );
  }

  /**
   * Path part for operation apiClientSearchByEmailGet
   */
  static readonly ApiClientSearchByEmailGetPath = '/api/Client/searchByEmail';

  /**
   * Get client records by email address - returns minimal data only.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientSearchByEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSearchByEmailGet$Json$Response(params?: {
    /**
     * Client s email address
     */
    emailAddress?: null | string;
  }): Observable<StrictHttpResponse<Array<ClientModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientSearchByEmailGetPath, 'get');
    if (params) {
      rb.query('emailAddress', params.emailAddress, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientModel>>;
        })
      );
  }

  /**
   * Get client records by email address - returns minimal data only.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientSearchByEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSearchByEmailGet$Json(params?: {
    /**
     * Client s email address
     */
    emailAddress?: null | string;
  }): Observable<Array<ClientModel>> {
    return this.apiClientSearchByEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientModel>>) => r.body as Array<ClientModel>)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyGet
   */
  static readonly ApiClientParentClientKeyGetPath = '/api/Client/{parentClientKey}/{parentCaseKey}';

  /**
   * Get a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyGet$Json$Response(params: {
    parentClientKey: number;
    parentCaseKey?: number;
  }): Observable<StrictHttpResponse<ClientModel>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyGetPath, 'get');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
      rb.path('parentCaseKey', params.parentCaseKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientModel>;
        })
      );
  }

  /**
   * Get a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyGet$Json(params: { parentClientKey: number, parentCaseKey?: number }): Observable<ClientModel> {
    return this.apiClientParentClientKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientModel>) => r.body as ClientModel)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyCaseNotesGet
   */
  static readonly ApiClientParentClientKeyCaseNotesGetPath = '/api/Client/{parentClientKey}/caseNotes';

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyCaseNotesGet$Json$Response(params: {
    parentClientKey: number;
  }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyCaseNotesGetPath, 'get');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CaseNoteModel>>;
        })
      );
  }

  /**
   * Get all case notes associated with a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyCaseNotesGet$Json(params: { parentClientKey: number }): Observable<Array<CaseNoteModel>> {
    return this.apiClientParentClientKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyCaseNotesPost
   */
  static readonly ApiClientParentClientKeyCaseNotesPostPath = '/api/Client/{parentClientKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyCaseNotesPost$Json$Response(params: {
    parentClientKey: number;
    body?: CaseNoteModel;
  }): Observable<StrictHttpResponse<ProcessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyCaseNotesPostPath, 'post');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProcessResponse>;
        })
      );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyCaseNotesPost$Json(params: {
    parentClientKey: number;
    body?: CaseNoteModel;
  }): Observable<ProcessResponse> {
    return this.apiClientParentClientKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyCasesGet
   */
  static readonly ApiClientParentClientKeyCasesGetPath = '/api/Client/{parentClientKey}/cases';

  /**
   * Get all cases associated with a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyCasesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyCasesGet$Json$Response(params: {
    parentClientKey: number;
  }): Observable<StrictHttpResponse<Array<CaseModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyCasesGetPath, 'get');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CaseModel>>;
        })
      );
  }

  /**
   * Get all cases associated with a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyCasesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyCasesGet$Json(params: { parentClientKey: number }): Observable<Array<CaseModel>> {
    return this.apiClientParentClientKeyCasesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseModel>>) => r.body as Array<CaseModel>)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyExpendituresGet
   */
  static readonly ApiClientParentClientKeyExpendituresGetPath = '/api/Client/{parentClientKey}/expenditures';

  /**
   * Retrieves all the Expenditures for a Client (sorted by case).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyExpendituresGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyExpendituresGet$Json$Response(params: {
    parentClientKey: number;
  }): Observable<StrictHttpResponse<Array<ExpenditureModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyExpendituresGetPath, 'get');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ExpenditureModel>>;
        })
      );
  }

  /**
   * Retrieves all the Expenditures for a Client (sorted by case).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyExpendituresGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyExpendituresGet$Json(params: {
    parentClientKey: number;
  }): Observable<Array<ExpenditureModel>> {
    return this.apiClientParentClientKeyExpendituresGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExpenditureModel>>) => r.body as Array<ExpenditureModel>)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyClientSelfIdentificationsGet
   */
  static readonly ApiClientParentClientKeyClientSelfIdentificationsGetPath =
    '/api/Client/{parentClientKey}/clientSelfIdentifications';

  /**
   * Gets Client Self Identification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyClientSelfIdentificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyClientSelfIdentificationsGet$Json$Response(params: {
    parentClientKey: number;
  }): Observable<StrictHttpResponse<Array<ClientSelfIdentificationModel>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientParentClientKeyClientSelfIdentificationsGetPath,
      'get'
    );
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientSelfIdentificationModel>>;
        })
      );
  }

  /**
   * Gets Client Self Identification.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyClientSelfIdentificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyClientSelfIdentificationsGet$Json(params: {
    parentClientKey: number;
  }): Observable<Array<ClientSelfIdentificationModel>> {
    return this.apiClientParentClientKeyClientSelfIdentificationsGet$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<ClientSelfIdentificationModel>>) => r.body as Array<ClientSelfIdentificationModel>
      )
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyClientSelfIdentificationsPost
   */
  static readonly ApiClientParentClientKeyClientSelfIdentificationsPostPath =
    '/api/Client/{parentClientKey}/clientSelfIdentifications';

  /**
   * Creates a Client Self Indentification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyClientSelfIdentificationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyClientSelfIdentificationsPost$Json$Response(params: {
    parentClientKey: number;
    body?: null | Array<ClientSelfIdentificationModel>;
  }): Observable<StrictHttpResponse<Array<ClientSelfIdentificationModel>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientParentClientKeyClientSelfIdentificationsPostPath,
      'post'
    );
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientSelfIdentificationModel>>;
        })
      );
  }

  /**
   * Creates a Client Self Indentification.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyClientSelfIdentificationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyClientSelfIdentificationsPost$Json(params: {
    parentClientKey: number;
    body?: null | Array<ClientSelfIdentificationModel>;
  }): Observable<Array<ClientSelfIdentificationModel>> {
    return this.apiClientParentClientKeyClientSelfIdentificationsPost$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<ClientSelfIdentificationModel>>) => r.body as Array<ClientSelfIdentificationModel>
      )
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyClientDisabilitiesGet
   */
  static readonly ApiClientParentClientKeyClientDisabilitiesGetPath =
    '/api/Client/{parentClientKey}/ClientDisabilities';

  /**
   * Gets All Client Disabilities for a Parent Client Key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyClientDisabilitiesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyClientDisabilitiesGet$Json$Response(params: {
    parentClientKey: number;
  }): Observable<StrictHttpResponse<Array<ClientDisability>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyClientDisabilitiesGetPath, 'get');
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ClientDisability>>;
        })
      );
  }

  /**
   * Gets All Client Disabilities for a Parent Client Key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyClientDisabilitiesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyClientDisabilitiesGet$Json(params: {
    parentClientKey: number;
  }): Observable<Array<ClientDisability>> {
    return this.apiClientParentClientKeyClientDisabilitiesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ClientDisability>>) => r.body as Array<ClientDisability>)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyClientDisabilitiesPost
   */
  static readonly ApiClientParentClientKeyClientDisabilitiesPostPath =
    '/api/Client/{parentClientKey}/ClientDisabilities';

  /**
   * Creates or Updates Client Disability.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyClientDisabilitiesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyClientDisabilitiesPost$Json$Response(params: {
    parentClientKey: number;
    body?: ClientDisabilityModel;
  }): Observable<StrictHttpResponse<ClientDisabilityModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ClientService.ApiClientParentClientKeyClientDisabilitiesPostPath,
      'post'
    );
    if (params) {
      rb.path('parentClientKey', params.parentClientKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientDisabilityModel>;
        })
      );
  }

  /**
   * Creates or Updates Client Disability.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyClientDisabilitiesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientParentClientKeyClientDisabilitiesPost$Json(params: {
    parentClientKey: number;
    body?: ClientDisabilityModel;
  }): Observable<ClientDisabilityModel> {
    return this.apiClientParentClientKeyClientDisabilitiesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientDisabilityModel>) => r.body as ClientDisabilityModel)
    );
  }

  /**
   * Path part for operation apiClientPost
   */
  static readonly ApiClientPostPath = '/api/Client';

  /**
   * Create or Update a client record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientPost$Json$Response(params?: {
    /**
     * Client plus Case, Case Site and Case Employee
     */
    body?: ClientCaseModel;
  }): Observable<StrictHttpResponse<ClientCaseModel>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientCaseModel>;
        })
      );
  }

  /**
   * Create or Update a client record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientPost$Json(params?: {
    /**
     * Client plus Case, Case Site and Case Employee
     */
    body?: ClientCaseModel;
  }): Observable<ClientCaseModel> {
    return this.apiClientPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientCaseModel>) => r.body as ClientCaseModel)
    );
  }

  /**
   * Path part for operation apiClientInquiryPost
   */
  static readonly ApiClientInquiryPostPath = '/api/Client/inquiry';

  /**
   * Creates a Client and Case from an Inquiry Parent Key.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientInquiryPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientInquiryPost$Json$Response(params?: {
    parentInquiryKey?: number;
    preferredSiteKey?: number;
  }): Observable<StrictHttpResponse<ClientCaseModel>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientInquiryPostPath, 'post');
    if (params) {
      rb.query('parentInquiryKey', params.parentInquiryKey, {});
      rb.query('preferredSiteKey', params.preferredSiteKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientCaseModel>;
        })
      );
  }

  /**
   * Creates a Client and Case from an Inquiry Parent Key.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientInquiryPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientInquiryPost$Json(params?: {
    parentInquiryKey?: number;
    preferredSiteKey?: number;
  }): Observable<ClientCaseModel> {
    return this.apiClientInquiryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientCaseModel>) => r.body as ClientCaseModel)
    );
  }

  /**
   * Path part for operation apiClientParentClientKeyInquiryPost
   */
  static readonly ApiClientParentClientKeyInquiryPostPath = '/api/Client/{parentClientKey}/inquiry';

  /**
   * Creates Case from an Inquiry Parent Key for existing client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientParentClientKeyInquiryPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyInquiryPost$Json$Response(params: {
    parentInquiryKey?: number;
    preferredSiteKey?: number;
    parentClientKey: number;
  }): Observable<StrictHttpResponse<ClientCaseModel>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientParentClientKeyInquiryPostPath, 'post');
    if (params) {
      rb.query('parentInquiryKey', params.parentInquiryKey, {});
      rb.query('preferredSiteKey', params.preferredSiteKey, {});
      rb.path('parentClientKey', params.parentClientKey, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ClientCaseModel>;
        })
      );
  }

  /**
   * Creates Case from an Inquiry Parent Key for existing client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientParentClientKeyInquiryPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientParentClientKeyInquiryPost$Json(params: {
    parentInquiryKey?: number;
    preferredSiteKey?: number;
    parentClientKey: number;
  }): Observable<ClientCaseModel> {
    return this.apiClientParentClientKeyInquiryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ClientCaseModel>) => r.body as ClientCaseModel)
    );
  }

  /**
   * Path part for operation apiClientIntegrationPost
   */
  static readonly ApiClientIntegrationPostPath = '/api/Client/integration';

  /**
   * Trigger CaMS integration after client/case updates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientIntegrationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientIntegrationPost$Json$Response(params: {
    parentClientKey: number;
    parentCaseKey: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientIntegrationPostPath, 'post');
    if (params) {
      rb.query('parentClientKey', params.parentClientKey, {});
      rb.query('parentCaseKey', params.parentCaseKey, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * Trigger CaMS integration after client/case updates.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientIntegrationPost$Json$Response()` instead.
   *
   */
  apiClientIntegrationPost$Json(params: { parentClientKey: number; parentCaseKey: number }): Observable<void> {
    return this.apiClientIntegrationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCaseFilteredGet
   */
  static readonly ApiClientFilteredGetPath = '/api/Client/list';

  /**
   * Get a filtered list of clients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientFilteredGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseFilteredGet$Json$Response(
    params?: ClientFilterParams
  ): Observable<StrictHttpResponse<PagingResult<ClientModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ClientService.ApiClientFilteredGetPath, 'get');
    if (params) {
      const queryParams = [
        'lastName',
        'firstName',
        'email',
        'dob',
        'bk',
        'bk2',
        'soundex',
        'pageNumber',
        'pageSize',
        'sortColumn',
        'sortDirection',
      ];

      queryParams.forEach((qParam) => {
        rb.query(qParam, params[qParam], {});
      });
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'text/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PagingResult<ClientModel>>;
        })
      );
  }

  /**
   * Get a filtered list of clients.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseFilteredGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientFilteredGet$Json(params?: ClientFilterParams): Observable<PagingResult<ClientModel>> {
    return this.apiCaseFilteredGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PagingResult<ClientModel>>) => r.body as PagingResult<ClientModel>)
    );
  }
}
