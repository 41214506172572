import { Injectable } from '@angular/core';
import { UserPreferenceValue } from '@api/models/user-preference-value';
import { UserService } from '@api/services';
import { Cf2Store } from '@core/models/store.model';
import { equals } from 'remeda';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

function isArray(elm) {
  return Array.isArray(elm);
}

export function isAllFilterParamsSelected(filters, nonArrayFields: string[] = []) {
  const isArrayFields = Object.keys(filters).filter((key) => isArray(filters[key]));

  const isNotArrayFields = Object.keys(filters).filter((key) => !isArray(filters[key]));

  return (
    // check arrays fields
    isArrayFields.length > 0 && isArrayFields.every((key) => filters[key].length > 0)
    // Check string fields
    // (isNotArrayFields.length === 0 || isNotArrayFields.some((key) => !!filters[key]))
  );
}

export interface IFilterState {
  clientSearch?: any;
  inquirySearch?: any;
  caseLoadSearch?: any;
  caseNotesSearch?: any;
  employmentSearch?: any;
  expenditureSearch?: any;
  schedulingSearch?: any;
  outcomeClaimsSearch?: any;
  assignCases?: any;
}

@Injectable({
  providedIn: 'root',
})
export class FilterStateService extends Cf2Store {
  value: IFilterState = {};
  initialized = new BehaviorSubject<boolean>(false);

  isAllFilterParamsSelected = isAllFilterParamsSelected;

  constructor(private readonly userService: UserService) {
    super();
    this.ready$
      .pipe(first((ready) => ready === true))
      .toPromise()
      .then(async (_) => {
        this.value = await this.loadFromApi().toPromise();
        this.initialized.next(true);
      });
  }

  async saveFilter(key: keyof IFilterState, value: any, merge: boolean = false) {
    if (merge) {
      const currentValue = this.value[key];
      if (currentValue) {
        value = { ...currentValue, ...value };
      }
    }

    this.value = { ...this.value, [key]: value };
    return await this.userService.apiUserPreferencesPost$Json({ body: this.buildApiBody() }).toPromise();
  }

  async getFilterValue(key: keyof IFilterState): Promise<any> {
    await this.initialized.pipe(first((value) => !!value)).toPromise();
    return this.value[key] || {};
  }

  async initializeFormValue(key: keyof IFilterState, valueSetter: (value: any) => void) {
    this.initialized
      .pipe(first((value) => !!value))
      .toPromise()
      .then((_) => {
        const filterValue = this.value[key] || {};
        valueSetter(filterValue);
      });
  }

  private buildApiBody() {
    const keys = Object.keys(this.value).filter((f) => f !== 'clientSearch');

    const data = keys.reduce((acc, key) => {
      let value = this.value[key];
      if (typeof value !== 'string' && value !== null) {
        if (value === 'null') {
          value = {};
        } else {
          value = JSON.stringify(value);
        }
      }
      return { ...acc, [key]: value };
    }, {});

    return data;
  }

  private loadFromApi() {
    return this.userService.apiUserPreferencesGet$Json().pipe(map((value) => this.resolveApiBody(value)));
  }

  private resolveApiBody(data: UserPreferenceValue) {
    const keys = Object.keys(data).filter((x) => x !== 'clientSearch');
    return keys.reduce((acc, key) => {
      return { ...acc, [key]: !!data[key] ? JSON.parse(data[key]) : data[key] };
    }, {});
  }
}
