import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientIdentifierModel } from '@api/models';
import { MultipleEntriesComponent } from '@shared/components/multiple-entries/multiple-entries-card.component';
import { ModalService } from '@shared/services/modal.service';
import { IdTypesListConfig } from '../id-types-list/id-types-list.component';
import { IdTypesDataService } from '../services/id-types-data.services';
import { ClientIdentifierLabelsType } from '../models/id-types-form.model';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';
import { Globals } from 'src/app/globals';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-id-types-card',
  template: `
  <ng-container *transloco="let t">
    <div class="cf2-multiple-entries-card flex-col">
      <div class="action-buttons flex-row flex-between">
        <h3>{{ t('IdentificationTypes') }}

        </h3>
        <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addForm()">{{ t('Add') }}</button>
      </div>

      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub$ | async as data">
          <cf2-id-types-list
            [isready]="isReady"
            [data]="data"
            [filteredData]="data"
            (selectedItem)="showEditEntityForm($event)"
          ></cf2-id-types-list>
        </ng-container>
      </div>

      <div class="edit-block flex-col" *ngIf="showForm">
        <cf2-id-types-form [fields]="fields"></cf2-id-types-form>
        <div class="action-buttons flex-row flex-end">
          <button mat-button class="button--secondary" (click)="cancel()">{{ t('Cancel') }}</button>
          <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addNew()">{{ t('Save') }}</button>
          <button mat-button *ngIf="!isReadOnly && editMode" class="button--secondary" (click)="updateEntity()">
            Save
          </button>
        </div>
      </div>
    </div>
    </ng-container>
  `,
})
export class IdTypesCardComponent
  extends MultipleEntriesComponent<ClientIdentifierModel, ClientIdentifierLabelsType>
  implements OnInit {
  listConfig: IdTypesListConfig = {
    showDeleteIcon: true,
  };

  constructor(
    private dataSvc: IdTypesDataService,
    private globals: Globals,
    router: Router,
    modalSvc: ModalService,
    clientCaseDataSvc: ClientCaseDataService
  ) {
    super(router, modalSvc, clientCaseDataSvc);
  }

  initForm(formValue = {}) {
    const form = this.dataSvc.initForm(formValue);
    this.fields = form.fields;
    this.fg = this.fields.fg;
    if (this.clientCaseDataSvc.viewType === 'VIEW') {
      this.clientCaseDataSvc.disableAll();
    }
    setTimeout(() => {
      this.showForm = true;
    }, 100);
  }

  showEditEntityForm(key: number) {
    const entityData = this.dataSvc.getEntityDataByKey(key);

    const formValue: Partial<ClientIdentifierModel> = {
      key: entityData.entity.key,
      comment: entityData.entity.comment,
      code: entityData.entity.code,
      createUserId: entityData.entity.createUserId,
    };

    this.showEditForm(key, formValue);
  }

  showEditForm(key: number, formValue: Partial<ClientIdentifierModel>) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedKey === key) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeForm();

    this.editMode = true;
    this.dataSvc.selectedKey = key;
    // TODO: remove timeout
    setTimeout(() => this.initForm(formValue), 10);
  }

  addForm() {
    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    // reset selected casenote
    this.dataSvc.selectedKey = null;
    this.editMode = false;

    const formValue: Partial<ClientIdentifierModel> = {
      // parentClientIdentifierKey : false
    };
    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const newEntry = this.dataSvc.addEntityData({
      tempKey: this.generateKey(),
      entity: {
        ...this.fg.value,
        createDateFormated: parseDateFormattedFromDate(new Date()),
        createUserName: this.globals.displayName,
      },
    });

    this.dataSvc.sanitizeForm();
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }

  updateEntity() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const entity = this.fg.value as ClientIdentifierModel;

    this.dataSvc.updateEntityData({
      tempKey: this.dataSvc.selectedKey,
      entity: {
        ...entity,
        updateDateFormatted: parseDateFormattedFromDate(new Date()),
        updateUserName: this.globals.displayName,
      },
    });

    this.dataSvc.sanitizeForm();
    this.editMode = false;
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }

  cancel() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeForm();
  }

  processResponse(res) {
    //const entity: ClientAddressModel[] = this.dataSvc.mapCaseNotes(res);
    const entityData = this.dataSvc.mapEntityData(res);

    this.dataSvc.setEntityData(entityData);
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnInit() {
    this.dataSub$ = this.dataSvc.entityListDataSub$;
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear document form data and state
    this.dataSvc.sanitizeData();
  }
}
