<div
  #container
  [ngClass]="{
    'input-field-container': 'input-field-container'
  }"
>
  <mat-form-field appearance="{{ appearance }}" floatLabel="always" #elRef style="width: 100%">
    <mat-label *ngIf="labelVisible" for="{{ idField }}">
      {{ label }} <span *ngIf="required === true" class="required-asterisk">*</span>
    </mat-label>

    <mat-select [formControl]="ctrl" #singleSelect aria-label="{{ label }}">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No entries found"
          [formControl]="filterCtrl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
        {{ option.description }}
      </mat-option>
    </mat-select>

    <mat-icon matSuffix *ngIf="icon">{{ icon }}</mat-icon>
    <fa-icon *ngIf="faIcon" [icon]="faIcon" matSuffix (click)="iconClick.emit(true)"></fa-icon>
    <mat-error *ngIf="hasError">{{ errorText }}</mat-error>
    <mat-error *ngIf="ctrl && ctrl['errorMessage']"> {{ ctrl['errorMessage'] }}</mat-error>
  </mat-form-field>
</div>
