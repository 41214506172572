import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { map, tap } from 'rxjs/operators';
import { CaseDesignationModel, CaseModel, ClientModel, ClientSelfIdentificationModel } from '@api/models';
import { ViewType } from '@core/models/view-type.model';
import { DataService } from '@core/services/data.service';
import { ClientKeysType, CaseKeysType } from './client-case-details-form.model';
import { ClientCaseForm } from './client-case-details-form.model';
import { Globals } from 'src/app/globals';
import * as R from 'remeda';
import { CaseService, LookupService, OrganizationService, OutcomeService, SchedulingService } from '@api/services';
import { AddressDataService } from '@shared/components/address/services/address-data.service';
import { UserStateService } from '@core/services/user-state.service';
import { EapInclusionGroupModel } from '@api/models/eap-inclusion-group-model';
import { MonitoringPhaseDataService } from '@modules/outcomes/services/monitoring-phase-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { casefloDateFormat } from '@shared/models/date-formats.model';
import { forkJoin } from 'rxjs';

export const CLIENT_FORM_FIELDS: ClientKeysType[] = [
  'firstName',
  'lastName',
  'birthDate',
  'clientBusinessKey',
  'clientBusinessKey2',
  'clientBusinessKey3',
  'genderCode',
  'languageCode',
  'preferredName',
  'otherLanguage',
  'contactCode',
  'maritalStatusCode',
  'deathDate',
];

export const CASE_FORM_FIELDS: CaseKeysType[] = [
  'camsIntegrationDateFormatted',
  'caseStatusCode',
  'exitReasonCode',
  'exitReasonOthDescription',
  'parentSiteKey',
  'incomeSourceCode',
  'intakeSourceCode',
  'subIntakeSourceCode',
  'intakeSourceCodeOther',
  'intakeSourceCodeReferral',
  'parentProgramKey',
  'parentProgramStreamKey',
  'parentEmployeeKey',
  'primaryContactType',
  'idValidated',
  'idCode',
  'mbmAssessmentCode',
  'cwCollabComplete',
  'cwCollabCompleteDate',
  'contractTypeCode'
];
@Injectable({ providedIn: 'root' })
export class ClientCaseDetailsDataService {
  clientCaseForm: ClientCaseForm;
  clientCaseFormGroup: UntypedFormGroup;

  viewType: ViewType = 'VIEW';
  caseViewType: ViewType = 'VIEW';
  // to get and save data from backend
  clientSelfIdentifications: ClientSelfIdentificationModel[] = [];
  // orignalSelfIdentificationValues are data mapped from backend data
  originalSelfIdentificationValues: { [x: string]: boolean } = {};
  // to show in the UI
  selfIdentificationValues: { [x: string]: boolean } = {};
  // tracked changes to self identificationValues
  selfIdentificationChanges: { [x: string]: boolean } = {};
  // to get and save data from backend
  caseDesignations: CaseDesignationModel[] = [];
  eapInclusionGroup: EapInclusionGroupModel[] = [];
  // originalCaseDesignationValues are data mapped from backend data
  originalCaseDesignationValues: { [x: string]: boolean } = {};
  // to show values in the ui after getting from backend
  designationValues: { [x: string]: boolean } = {};
  designationChanges: { [x: string]: boolean } = {};
  caseData: any;
  caseStatus: string;
  currentCaseStatus: string;
  claimStatus: string;
  eap: any;
  outcomes: any;
  expenditures: any;
  caRefNum: string;

  constructor(
    private formBuilder: RxFormBuilder,
    private dataSvc: DataService,
    private globals: Globals,
    private orgSvc: OrganizationService,
    private addressSvc: AddressDataService,
    private caseSvc: CaseService,
    private lookupSvc: LookupService,
    private userStateSvc: UserStateService,
    private monitoringPhaseDataSvc: MonitoringPhaseDataService,
    private translocoService: TranslocoService,
    private outcomeSvc: OutcomeService,
    private schedulingSvc: SchedulingService,
  ) {}

  get allSites$() {
    return this.dataSvc.getOrganizations().pipe(
      tap((val: any) => {
        this.dataSvc.organizations = val;
      }),
      map((orgs: any) =>
        R.flattenDeep(
          orgs.map((org) =>
            org.sites.map((site) => ({
              value: site.parentSiteKey,
              description: ` ${site.siteName}`,
              organization: org,
            }))
          )
        )
      )
    );
  }

  get sites$() {
    const parentOrganizationalUnitKey = this.globals.organizationKey;
    return this.orgSvc
      .apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json({
        parentOrganizationalUnitKey,
      })
      .pipe(
        map((sites) =>
          sites.map((site) => ({
            value: site.parentSiteKey,
            description: site.siteName,
          }))
        )
      );
  }



  get caseStatusTypes$() {
    this.claimStatus$.subscribe();
    var currentCaseKey = this.globals.caseKey;
    var phaseCodeKey = this.globals.monitoringPhaseKey;
    var eapKey = this.globals.eapKey;

    //observable to retrieve casestatus list
    let caseStatuses$ = this.dataSvc.lookupRecords('CaseStatusType').pipe(
      map((result) => {
        return DataService.lookupToOptions(result, this.translocoService.getActiveLang());
      })
    );
    
    let meetings$ = this.schedulingSvc.apiSchedulingCaseParentCaseKeyGet$Json$Response({
      parentCaseKey: currentCaseKey,
    });

    //observable to retrieve outcomes
    let outcomes$ = this.outcomeSvc.apiOutcomeGet$Json({
      parentMonitoringPhaseKey: phaseCodeKey,
      clientEapKey: eapKey,
      parentCaseKey: currentCaseKey,
    });

    let monitoringState = ['MON', 'MON_EMP', 'MON_EXIT', 'MON_UNEMP'];
    let sa_intakeSourceCodes = ['OWORKS','ODSP','SA-ERFS_SUPPORT']

    if (!monitoringState.includes(this.currentCaseStatus)) {
      //not in monitoring state
      return forkJoin([caseStatuses$, meetings$]).pipe(
        map(([caseStatuses, meetings]) => {
          let statusToEnable = [];
          switch (this.currentCaseStatus) {
            case 'INPR':
              statusToEnable = ['COMPL_INEL'];

              /* TEMPORARILY COMMENTED OUT FOR CF-686 https://dev.azure.com/WCGServices/Central%20Hub/_workitems/edit/686
              
              if (sa_intakeSourceCodes.includes(this.caseData.case.intakeSourceCode)) {
                const count = meetings.body.filter(item => item.clientAttendanceResultDescription.toLowerCase() === 'no show').length;
                if (count >= 2) {
                  statusToEnable.push('SA_PENDING');
                }
              }*/
              break;
            case 'ACT':
              statusToEnable = ['EXT_EARLY'];
              break;
            case 'SA_PENDING':
              statusToEnable = ['COMPL_INEL', 'INPR', 'ACT'];
              break;
            default:
              statusToEnable = [];
          }

          return R.map(caseStatuses, (item) => {
            if (statusToEnable.includes(item.value)) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
            return item;
          });
        })
      );
    } else {
      //in monitoring state => then check 12 months check points
      return forkJoin([outcomes$, caseStatuses$]).pipe(
        map(([outcomes, caseStatuses]) => {
          let areAllWorkflowsFinalized = outcomes.every((x) => x.outcomeStatusCode == 'FINAL');
          let isItAt12MonthCheckPoint = outcomes.some((x) => x.claimTypeCode == 'CLAIM_12MNTH');
          if (areAllWorkflowsFinalized == true && isItAt12MonthCheckPoint == true) {
            return R.map(caseStatuses, (item) => {
              if (item.value == 'COMPL') {
                item.disabled = false;
              } else {
                item.disabled = true;
              }
              return item;
            });
          } else {
            return R.map(caseStatuses, (item) => {
              if (item.value == 'EXT_MON') {
                item.disabled = false;
              } else {
                item.disabled = true;
              }
              return item;
            });
          }
        })
      );
    }
  }

  get currentUserSites$() {
    const parentOrganizationalUnitKey = this.userStateSvc.user.parentOrganizationalUnitKey;
    return this.orgSvc
      .apiOrganizationOrganizationParentOrganizationalUnitKeySiteGet$Json({
        parentOrganizationalUnitKey,
      })
      .pipe(
        map((sites) =>
          sites.map((site) => ({
            value: site.parentSiteKey,
            description: site.siteName,
          }))
        )
      );
  }

  get claimStatus$() {
    return this.dataSvc.get12MonthClaimStatus$(this.globals.caseKey).pipe(map((res) => (this.claimStatus = res)));
  }

  getExitReasonType(caseStatusCode: string) {
    // return this.dataSvc.lookupRecords('ExitReasonType').pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
    return this.lookupSvc.apiLookupExitReasonTypeCaseStatusCodeGet$Json({
      caseStatusCode,
    });
  }

  get MbmAssessmentType() {
    return this.dataSvc
      .lookupRecords('MbmAssessmentType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }

  get genderTypes$() {
    return this.dataSvc
      .lookupRecords('GenderType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }

  get incomeSourceTypes$() {
    return this.dataSvc
      .lookupRecords('IncomeSourceType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }

  get intakeSourceCode$() {
    return this.dataSvc
      .lookupRecords('IntakeSource')
      .pipe(
        map((result) =>
          DataService.lookupToOptions(result, this.translocoService.getActiveLang()).sort((a, b) =>
            a.description < b.description ? -1 : a.description > b.description ? 1 : 0
          )
        )
      );
  }

  get subIntakeSourceCode$() {
    return this.dataSvc
      .lookupRecords('SubIntakeSource')
      .pipe(
        map((result) =>
          DataService.lookupToOptions(result, this.translocoService.getActiveLang()).sort((a, b) =>
            a.description < b.description ? -1 : a.description > b.description ? 1 : 0
          )
        )
      );
  }

  get parentProgramKey$() {
    return this.dataSvc.programs$.pipe(
      map((result) =>
        result.body.map((val) => ({
          value: val.parentProgramKey,
          description: val.programDescription,
        }))
      )
    );
  }

  getParentProgramStreamKey$(parentProgramKey: number) {
    return this.caseSvc.apiCaseProgramsParentProgramKeyStreamsGet$Json({ parentProgramKey }).pipe(
      map((result) =>
        DataService.lookupStreamOptions(result, this.translocoService.getActiveLang()).map((val) => ({
          value: val.parentProgramStreamKey,
          description: val.programStreamName,
        }))
      )
    );
  }

  get preferredContactType$() {
    return (
      this.dataSvc
        .lookupRecords('PreferredContactType')
        .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())))
        // Map locally to how database stores dbo.ClientContact.ContactCode
        // if SMS db store it as MOBI,
        // if PHONE db stores it as HOME
        .pipe(
          map((result) =>
            result.map((contactType) => {
              if (contactType.value === 'SMS') contactType['value'] = 'MOBI';
              if (contactType.value === 'PHONE') contactType['value'] = 'HOME';

              return contactType;
            })
          )
        )
    );
  }

  get idTypes$() {
    return this.dataSvc
      .lookupRecords('IdType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }

  get languageTypes$() {
    return this.dataSvc
      .lookupRecords('LanguageType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }

  get selfIdentificationTypes$() {
    return this.dataSvc.lookupRecords('SelfIdentificationType').pipe(
      //map((result) =>this.dataSvc.translateDataTables(result)),
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => {
        // default to false
        result.forEach((item) => {
          this.selfIdentificationValues[item.value] = false;
          this.originalSelfIdentificationValues[item.value] = false;
        });

        if (this.viewType !== 'CREATE') {
          // this.clientSelfIdentifications are retrived from backend
          this.clientSelfIdentifications.forEach((obj) => {
            this.selfIdentificationValues[obj.code] = true;
            this.originalSelfIdentificationValues[obj.code] = true;
          });
        }
      })
    );
  }

  get designationTypes$() {
    return this.dataSvc.lookupRecords('InclusionGroupType').pipe(
      //map((result) => this.transloco.translate(result)),
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      map((result) =>
        result.map((item) => {
          item.value === 'ODSP' ? (item.description = 'ODSP') : '';
          return item;
        })
      ),
      tap((result) => {
        result.forEach((item) => {
          this.designationValues[item.value] = false;
          this.originalCaseDesignationValues[item.value] = false;
        });

        if (this.viewType !== 'CREATE') {
          this.updateCaseDesignationValues();
        }
      })
    );
  }

  get contractType$() {
    return this.dataSvc
      .lookupRecords('ContractType')
      .pipe(map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())));
  }


  resetCaseDesignationValues() {
    this.designationValues = R.mapValues(this.designationValues, (value, key) => false);
    this.originalCaseDesignationValues = R.mapValues(this.originalCaseDesignationValues, (value, key) => false);
    this.designationChanges = {};
    // clear specialized populatin
    //this.caseDesignations = [];
    this.eapInclusionGroup = [];
  }

  updateCaseDesignationValues() {
    // this.caseDesignations.forEach((obj) => {
    //   this.designationValues[obj.caseDesignationCode] = true;
    //   this.originalCaseDesignationValues[obj.caseDesignationCode] = true;
    // });
    if (this.eapInclusionGroup) {
      this.eapInclusionGroup.forEach((obj) => {
        this.designationValues[obj.inclusionGroupCode] = true;
        this.originalCaseDesignationValues[obj.inclusionGroupCode] = true;
      });
    }
  }

  getEmployees(orgKey: number) {
    return this.dataSvc.getOrgEmployees(orgKey);
  }

  getAllOrgEmployees() {
    return this.dataSvc.getAllOrgEmployees();
  }

  getSiteEmployees(siteKey: number) {
    return this.dataSvc.getSiteEmployees(siteKey);
  }

  getSiteContractTypes(siteKey: number) {
    return this.dataSvc.getSiteContractTypes(siteKey);
  }

  setViewType(viewType: ViewType) {
    this.viewType = viewType;
  }

  initForm(value?: Partial<ClientModel & CaseModel>) {
    if (value) {
      this.clientCaseForm = new ClientCaseForm(this.formBuilder, this.translocoService, {
        value: value,
      });
      this.clientCaseForm.fields.fg.patchValue(value);
    } else {
      this.clientCaseForm = new ClientCaseForm(this.formBuilder, this.translocoService);
    }

    this.clientCaseFormGroup = this.clientCaseForm.fields.fg;

    return this.clientCaseForm;
  }

  getFormFields() {
    return this.clientCaseForm.fields;
  }

  sanitizeForm() {
    this.clientCaseForm = null;
    this.clientCaseFormGroup = null;
    this.addressSvc.externalForm = null;
    this.addressSvc.externalFormGroup = null;
  }

  sanitizeData() {
    this.sanitizeForm();
    this.viewType = null;
    this.caseViewType = null;

    this.clientSelfIdentifications = [];
    this.originalSelfIdentificationValues = {};
    this.selfIdentificationValues = {};
    this.selfIdentificationChanges = {};

    //this.caseDesignations = [];
    this.eapInclusionGroup = [];
    this.originalCaseDesignationValues = {};
    this.designationValues = {};
    this.designationChanges = {};
  }

  isFormValid() {
    var valid : boolean = true;
    Object.keys(this.clientCaseFormGroup?.controls).forEach((key) => {
      if (this.clientCaseFormGroup?.controls[key].invalid) {
        valid = false;
        return;
      }
    });

    this.clientCaseFormGroup.markAllAsTouched();
    //return this.clientCaseFormGroup?.valid;
    return valid;
  }

  isFormDirty() {
    return this.clientCaseFormGroup?.dirty;
  }

  getFormValue() {
    return this.clientCaseFormGroup.getRawValue();
  }

  getSelfIdentficationValue() {
    return {
      original: this.originalSelfIdentificationValues,
      changes: this.selfIdentificationChanges,
    };
  }

  getCaseDesignationValue() {
    return {
      original: this.originalCaseDesignationValues,
      changes: this.designationChanges,
    };
  }

  public errorMap(fc: UntypedFormControl, label: string): string {
    if (!fc.errors) return '';
    if (fc.errors.required)
      return this.translocoService.translateObject('Message.Error.ControlRequired', { ControlName: label });
    if (fc.errors.duplicateSin) return this.translocoService.translateObject('DuplicateSin');
    if (fc.errors.duplicateEmail) return this.translocoService.translateObject('DuplicateEmail');
    if (fc.errors.invalidSin)
      return this.translocoService.translateObject('Message.Error.InvalidSin') + ': ' + `${label}`;
    if (fc.errors.sin && fc.errors.sin.message)
      return this.translocoService.translateObject('Message.Error.InvalidSin') + ': ' + `${label}`;
    if (fc.errors.minLength && fc.errors.minLength.message)
      return this.translocoService.translateObject('Message.Error.MinLength', { 1: label });

    if ((label.startsWith('Phone') || label.startsWith('Mobile')) && fc.errors.minlength) {
      return this.translocoService.translate('Message.Error.IncompletePhoneNumber');
    }

    if (fc.errors.minlength)
      return this.translocoService.translateObject('Inquiries.Message.Error.ControlMinimumCharacters', {
        Minimum: fc.errors.minlength.requiredLength.toString(),
      });
    if (fc.errors.maxlength)
      return this.translocoService.translateObject('Inquiries.Message.Error.ControlMaximumCharacters', {
        Maximum: fc.errors.maxlength.requiredLength.toString(),
      });
    if (fc.errors.email) return this.translocoService.translateObject('Message.Error.EmailFormat', { Email: label });

    if (fc.errors.name) return this.translocoService.translate('Message.Error.InvalidName');

    if (fc.errors.matDatepickerMin)
      return this.translocoService.translateObject('Message.Error.MinimumDateTime', { DateTime: label.toString() });
    if (fc.errors.matDatepickerMax)
      return this.translocoService.translateObject('Message.Error.MaximumDateTime', { DateTime: label.toString() });
    if (fc.errors.invalidDate)
      return this.translocoService.translateObject('Message.Error.DateTimeFormat', { DateTime: label.toString() });
    // if (fc.errors.cannotCloseCase) return 'All EAP Sub-Goals & Plan Items must have a defined Outcome before closing the case.';
    // if (fc.errors.outcomesNotFinalized) return `All Outcomes must be in 'Not Start', 'Cancelled' or 'Finalized' status before closing the case`;
    if (fc.errors.startsWith) return this.translocoService.translate('Message.Error.PhoneStartsWith');
    if (fc.errors.cannotCloseCase) return this.translocoService.translate('ClientCase.Message.Error.CannotCloseCase');
  }

  hasErrors() {
    const ret = this.clientCaseFormGroup?.touched && this.clientCaseFormGroup?.invalid;
    return ret === undefined ? false : ret;
  }

  errorList(prefix: string = '') {
    const errors: string[] = [];

    prefix = this.translocoService.translate(prefix);

    if (this.clientCaseFormGroup) {
      Object.keys(this.clientCaseFormGroup?.controls).forEach((key) => {
        let isEapComplete = false;
        let isOutcomeComplete = false;
        let isExpenditureComplete = false;
        let isFSComplete = false;
        let isPBFComplete = false;
        let isCwCollabComplete = false;

        let fsCount = 0;
        let fsCountApproved = 0;
        if (
          key === 'caseStatusCode' &&
          ['COMPL', 'COMPL_INEL', 'EXT_EARLY', 'EXT_MON'].includes(this.clientCaseFormGroup?.controls[key].value) &&
          !['COMPL', 'COMPL_INEL', 'EXT_EARLY', 'EXT_MON', 'INPR'].includes(this.currentCaseStatus)
        ) {
          if (this.eap.length > 0) {
            isEapComplete = this.isEapComplete();
            //check if financial support plan items are all approved in CaMS
            this.eap[0].subGoals
              .filter((f) => ['ERFS', 'EFST'].includes(f.subGoalCode))
              .forEach((key) => {
                fsCount += key.planItems.length;
                fsCountApproved += key.planItems.filter(
                  (p) => p.camsSsmSubmitionStatusDescription === 'Approved'
                ).length;
              });
            isFSComplete = fsCount === fsCountApproved;
          }
          //check if PBFs are approved in CaMS
          isPBFComplete = this.outcomes.filter((o) => o.camsApprovalStatus === 'In Progress').length == 0;
          
          //check if there are in progress outcomes or expenditures
          isOutcomeComplete =
            this.outcomes.filter((o) => !['NOT_START', 'CANC', 'FINAL'].includes(o.outcomeStatusCode)).length == 0;
          isExpenditureComplete =
            this.expenditures.filter((e) => !['FINAL', 'CANC'].includes(e.expenditureStatusCode)).length == 0;

          if (
            !isEapComplete ||
            !isOutcomeComplete ||
            !isExpenditureComplete ||
            !isFSComplete ||
            !isPBFComplete ||
            this.eap[0].eapStatusCode !== 'ACT'          
          ) {
            this.clientCaseFormGroup?.controls[key].setErrors({ cannotCloseCase: true });
          }
        } else if (
          key === 'cwCollabComplete' && ['COMPL_INEL','INPR'].includes(this.currentCaseStatus)
        ) {
          isCwCollabComplete = this.clientCaseFormGroup?.controls[key].value;
          if (isCwCollabComplete!= null && !isCwCollabComplete){
            errors.push(`An OW referral cannot be exited until case collaboration with SA Caseworker is documented.`);
          }
        }

        if (this.clientCaseFormGroup?.controls[key].invalid) {
          const errorMessage = this.errorMap(
            this.clientCaseFormGroup?.controls[key] as UntypedFormControl,
            prefix + this.getFormFields().labels[key]
          );
          errors.push(errorMessage);

          if (
            !['COMPL', 'COMPL_INEL', 'EXT_EARLY', 'EXT_MON', 'INPR'].includes(this.currentCaseStatus) &&
            key === 'caseStatusCode'
          ) {
            if (!isEapComplete)
              errors.push('All EAP Sub-Goals & Plan Items must have a defined Outcome before closing the case.');
            if (!isOutcomeComplete)
              errors.push(`All pending Outcomes must Finalized or Cancelled before the account can be closed.`);
            if (!isExpenditureComplete)
              errors.push(`All pending Expenditures must Finalized or Cancelled before the account can be closed.`);
            if (!isFSComplete) errors.push(`This case cannot be closed as there is an Expenditure pending CaMS review`);
            if (!isPBFComplete) errors.push(`This case cannot be closed as there is an Outcome pending CaMS review`);
            if (this.eap[0]?.eapStatusCode !== 'ACT') errors.push(`Plan Items and Sub-Goal must be defined.`);
          }
        }
      });
    }

    return errors;
  }

  isEapComplete() {
    let subGoalCount = 0;
    let subGoalCompletedCount = 0;
    let planItemCount = 0;
    let planItemCompletedCount = 0;
    const eap = this.eap[0];

    subGoalCount = eap.subGoals.length;
    subGoalCompletedCount = eap.subGoals.filter((s) =>
      ['ATTAINED', 'NOT_ATTAINED'].includes(s.subGoalOutcomeCode)
    ).length;
    eap.subGoals.forEach((element) => {
      planItemCount += element.planItems.length;
      planItemCompletedCount += element.planItems.filter(
        (x) => x.planItemStatusCode === 'COMPL' && x.planItemOutcomeCode !== null
      ).length;
    });

    return planItemCompletedCount === planItemCount && subGoalCount === subGoalCompletedCount;
  }
}
