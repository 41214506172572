import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { catchError, debounceTime, filter, map, retry, switchMap, mergeMap, tap } from 'rxjs/operators';
import { Globals } from 'src/app/globals';

import { Cf2Store } from '@core/models/store.model';
import { merge, of } from 'rxjs';
import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { ApplicationSettingsModel, UserModel } from '@api/models';
import { DataService } from './data.service';
import { ApplicationSettingsService, UserService } from '@api/services';
import { ModalService } from '@shared/services/modal.service';
import { UserStateService } from './user-state.service';
import { APP_KEYS } from '@core/models/app-keys';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Navigation state service manages navigation tasks for the application, including
 * saving state keys to cache on navigation, previousUrls and recalling state keys from
 * Cf2Store
 *
 * @export
 * @class NavigationStateService
 * @extends {Cf2Store}
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationStateService extends Cf2Store {
  private _isInit = false;
  isLoggedIn = false;
  applicationSettings: ApplicationSettingsModel;
  user: UserModel;
  translocoService : TranslocoService; 
  constructor(
    @Inject(CF2_CONFIG) private _config: Cf2ConfigType,
    private router: Router,
    private globals: Globals,
    private dataSvc: DataService,
    private settingsService: ApplicationSettingsService,
    private modalSvc: ModalService,
    private userSvc: UserService,
    private userStateService: UserStateService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  /**
   * Initialize navigation services. Should only be called once.
   *
   * @memberof NavigationStateService
   */
  isRedirect = false;

  init(translocoService) {
    this._isInit = true;
    this.translocoService = translocoService;
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationStart),
        map((navStart) => {
          const { url = '' } = navStart;
          this.globals.returnUrl = this.globals.currentUrl;
          this.globals.currentUrl = url;

          const currentNavigation = this.router.getCurrentNavigation();
          const { state = {} } = currentNavigation.extras;
          const { queryParams = {} } = currentNavigation?.extractedUrl;

          const keyValues = { ...state, ...queryParams };
          const storeKeyValues = Object.keys(keyValues)
            .filter((key) => APP_KEYS.includes(key as any))
            .reduce((prev, curr) => {
              const convertedToNumber = Number.isNaN(parseInt(keyValues[curr]))
                ? keyValues[curr]
                : parseInt(keyValues[curr]);
              // store in globals
              this.globals[curr] = convertedToNumber;

              return { ...prev, ...{ [curr]: convertedToNumber } };
            }, {});

          return storeKeyValues;
        }),
        switchMap((storeKeyValues) => {
          return this.batchKeys(storeKeyValues);
        })
      )
      .subscribe();

    this.ready$.subscribe((obs) => {
      if (!obs) return;
      const stateKeys = this._config.caseKeys;
      const subs = stateKeys.map((key) => this.getItem(key).pipe(map((value) => {
       return  ({ key, value: value });
      })));

      merge(...subs)
        .pipe(
          // merge with URL query params
          mergeMap((keyValuePair) => {
            return this.activatedRoute.queryParams.pipe(
              map((queryParams) => {
                return {
                  ...keyValuePair,
                  value: !queryParams[keyValuePair.key] ? keyValuePair.value : queryParams[keyValuePair.key],
                };
              })
            );
          })
        )
        .subscribe();
    });
  }

  getApplicationSettings() {
    return this.settingsService
      .apiApplicationSettingsGet$Json()
      .pipe(
        retry(3),
        debounceTime(5000),
        catchError((err) => {
          console.log('Error Get ApplicationSettings:', err);
          this.modalSvc.openDialog({
            data: {
              message: `The request failed to process due to a network error. Please retry`,
              title: `Error: ${err} - ${this.settingsService.rootUrl}${ApplicationSettingsService.ApiApplicationSettingsGetPath}`,
              buttons: { cancel: { text: 'Okay' } },
            },
          });
          this.router.navigate(['logout'], {});
          return of(null);
        }),
        tap((appSettings: ApplicationSettingsModel) => {
          this.globals.applicationTitle = appSettings.applicationTitle;
          this.globals.serviceTitle = appSettings.serviceTitle;
          this.globals.applicationVersion = appSettings.applicationVersion;
          this.globals.environment = appSettings.environment;
          this.globals.deploymentDate = appSettings.deploymentDate;
          this.globals.defaultGridRows = appSettings.defaultGridRows;
          this.globals.maximumFileSizeMb = appSettings.maximumFileSizeMb;
          this.globals.hstIndicator = appSettings.hstIndicator;
          this.globals.hstPercent = appSettings.hstPercent;
          this.globals.gstPercent = appSettings.gstPercent;
          this.globals.pstPercent = appSettings.pstPercent;
          this.globals.q16CutOffDate = appSettings.q16CutOffDate;
          this.dataSvc.parentRegion = appSettings.parentRegion;

          if (appSettings.fileTypes !== undefined && appSettings.fileTypes.length > 0) {
            this.globals.fileTypes = appSettings.fileTypes.split(',');
          }

          this.globals.applicationDataLoaded = true;

          this.applicationSettings = appSettings;
        })
      )
      .toPromise();
  }

  getIsMaintenance() {
    return this.settingsService
      .apiApplicationSettingsIsMaintenanceGet$Json()
      .pipe(
        debounceTime(5000),
        tap((ret) => {
          this.globals.isMaintenance = ret;
        })
      )
      .toPromise();
  }

  getLoggedInUserDetails() {
    return this.userSvc
      .apiUserGet$Json()
      .pipe(
        retry(3),
        debounceTime(5000),
        catchError((err) => {
          console.log('get user error', err);
          this.modalSvc.openDialog({
            data: {
              message: `The request failed to process due to a network error. Please retry`,
              title: `Error: ${err} - ${this.userSvc.rootUrl}${UserService.ApiUserGetPath}`,
              buttons: { cancel: { text: 'Okay' } },
            },
          });
          this.router.navigate(['logout'], {});
          return of(null);
        }),

        tap((data) => {
          if (!data) return;
          const user = data as UserModel;

          this.globals.email = user.email;
          this.globals.displayName = user.displayName;
          this.globals.username = user.userId;
          this.globals.functionalAccess = user.functionalAccess;
          // this.globals.userId = user.userId;

          // extended user data
          this.globals.languageCode = user.languageCode;

          if (this.globals.languageCode === undefined || this.globals.languageCode === null) {
            this.globals.languageCode = 'ENG';
          }
          this.globals.siteKey = user.parentSiteKey;
          this.globals.siteName = user.siteName;
          /**
           * @Note
           * reportsToOrganizationalUnitKey should be set only for SMs and ECs,
           * everybody else should get parentOrganizationKey
           * */
          if (user.roleCode === 'EC' || user.roleCode === 'SM') {
            this.globals.organizationKey = user.reportsToOrganizationalUnitKey;
          } else {
            this.globals.organizationKey = user.parentOrganizationalUnitKey;
          }

          this.globals.organizationName = user.organizationName;
          this.globals.roleCode = user.roleCode;
          this.globals.roleDescription = user.roleDescription;

          this.globals.employeeKey = user.parentEmployeeKey;

          if (this.globals.languageCode === 'FR') this.globals.roleDescription = user.roleDescriptionFr;

          // this.authStateSvc.init();
          this.userStateService.init(user, this.translocoService);

          this.user = user;
        })
      )
      .toPromise();
  }
}
