import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { ClientNavigationComponent } from '@shared/components/client-navigation/client-navigation.component';
import { ExpenditureWorkflowsComponent } from '@shared/components/expenditure-workflows/expenditure-workflows.component';
import { UserComponent } from './views/user/user.component';
import { ViewPositionsComponent } from './views/positions/view-positions.component';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './views/home/home.component';
import { MonitoringChecksCardComponent } from './components/monitoring-checks-card/monitoring-checks-card.component';
import { MeetingOccuranceCardComponent } from './components/meeting-occurence-card/meeting-occurence-card.component';

import { InactiveCasesCardComponent } from './components/inactive-cases-card/inactive-cases-card.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    ClientNavigationComponent,
    HomeComponent,
    UserComponent,
    ExpenditureWorkflowsComponent,
    ViewPositionsComponent,
    MonitoringChecksCardComponent,
    InactiveCasesCardComponent,
    MeetingOccuranceCardComponent,
  ],
  imports: [CommonModule, SharedModule, HomeRoutingModule, TranslocoModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ClientNavigationComponent, UserComponent, ExpenditureWorkflowsComponent, ViewPositionsComponent],
})
export class HomeModule { }
