import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cf2-maintenance',
  template: `
    <div style="height: 100%; display: flex;">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="cf2-avatar"></div>
            <mat-card-title>Maintenance</mat-card-title>
            <mat-card-subtitle>Upgrade is in progress...</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            The website is currently undergoing maintenance. Please try again later. 
          </mat-card-content>
          <!-- <mat-card-content>
            <p>The page you're looking for doesn't exist in CaseFLO.</p>
          </mat-card-content> -->
          <mat-card-actions>
            <a [routerLink]="home">Home</a>
          </mat-card-actions>
        </mat-card>
    </div>
  `,
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  home = '/home';
  constructor() { }

  ngOnInit(): void { }
}
