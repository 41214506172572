import * as R from 'remeda';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ClientAddressModel } from '@api/models';
import { AddressViewType } from '@shared/components/address/models/address-form.model';
import { AddressDataService } from '@shared/components/address/services/address-data.service';
import { AddressListData, ListConfig } from './address-datasource';
import { faLock, faLockOpen, faTimesCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CLIENT_MODULE } from '@core/services/navigation.service';
import { Globals } from '../../../../globals';
import { TranslocoService } from '@ngneat/transloco';

export interface AddressListConfig {
  showDeleteIcon?: boolean;
}

@Component({
  selector: 'cf2-address-list',
  template: `
    <mat-table [dataSource]="filteredData" matSort aria-label="Address List">
      <!-- column definitions -->
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
        <mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          [disabled]="!columnConfig[i].sort"
          [class.is-select-column]="columnConfig[i].key === 'select'"
          [class.is-icon-column]="
            columnConfig[i].key === 'icon' || columnConfig[i].key === 'isLocked' || columnConfig[i].key === 'deleteIcon'
          "
          [class.is-small-column]="
            columnConfig[i].key === 'key' ||
            columnConfig[i].key === 'addressTypeDescription' ||
            columnConfig[i].key === 'unit' ||
            columnConfig[i].key === 'primary'
          "
        >
          <ng-container *ngIf="columnConfig[i].key !== 'select'">
            {{ columnConfig[i].label }}
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'select'">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </ng-container>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let cell; index as j"
          (click)="selectedItemFn(cell.key)"
          [class.is-linkable]="columnConfig[i].linkable"
          [class.is-select-column]="columnConfig[i].key === 'select'"
          [class.is-icon-column]="columnConfig[i].key === 'deleteIcon'"
          [class.is-small-column]="
            columnConfig[i].key === 'key' ||
            columnConfig[i].key === 'addressTypeDescription' ||
            columnConfig[i].key === 'unit' ||
            columnConfig[i].key === 'primary'
          "
        >
          <ng-container *ngIf="columnConfig[i].key === 'select'">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectionChanged($event, cell) : null"
              [checked]="selection.isSelected(cell)"
            >
            </mat-checkbox>
          </ng-container>
          <ng-container
            *ngIf="
              columnConfig[i].key !== 'select' &&
              columnConfig[i].key !== 'icon' &&
              columnConfig[i].key !== 'isLocked' &&
              columnConfig[i].key !== 'isLocked' &&
              columnConfig[i].key !== 'key' &&
              columnConfig[i].key !== 'addressTypeDescription'
            "
          >
            {{ cell[column] }}
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'deleteIcon'">
            <a href="javascript: void(0);" aria-label="Delete Address" (click)="deleteItem(cell.key)">
              <fa-icon [icon]="deleteIcon" class="red"></fa-icon>
            </a>
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'key'">
            {{ cell[column] >= 1 ? cell[column] : '' }}
          </ng-container>
          <ng-container *ngIf="columnConfig[i].key === 'addressTypeDescription'">
            <a href="javascript: void(0);" (click)="selectedItemFn(cell.key)">
              {{ cell[column] }}
            </a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Header Row-->
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

      <!-- Select Record -->
      <mat-row *matRowDef="let cell; columns: columns"></mat-row>
    </mat-table>

    <!-- No data found -->
    <ng-container *ngIf="isReady && filteredData.length < 1">
      <div class="noresult">
        <div class="center">No results found</div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() data: ClientAddressModel[];
  @Input() isReady = false;
  @Input() filteredData: ClientAddressModel[];
  @Input() caseKey: number;
  @Input() searchFc?: UntypedFormControl;
  @Input() isReadOnly = false;
  @Output() isReadOnlyChange = new EventEmitter<boolean>();

  @Input() view?: AddressViewType = 'view';
  // set default values for casenotes list configuration
  _config: AddressListConfig = {
    // showLockIcon: true, // default show lock icon
    showDeleteIcon: false, // default hides delete icon
    // showSelection: false // default dont show row select checkboxes
  };

  get config() {
    return this._config;
  }

  @Input()
  set config(configObj: AddressListConfig) {
    this._config = {
      ...this._config,
      ...configObj,
    };
  }

  // @return number
  @Output() selectedItem = new EventEmitter<number>();
  // @return CaseNotes[]
  @Output() selectedItems = new EventEmitter<ClientAddressModel[]>();

  @Output() delete = new EventEmitter<number>();

  columnConfig: ListConfig[];
  lockIcon = faLock;
  lockOpenIcon = faLockOpen;

  deleteIcon = faTimesCircle;
  linkIcon = faLink;

  showDeleteIcon = false;

  columns: any;
  sortColumn: string;
  sortDirection: boolean;
  currentPath: string;

  selection = new SelectionModel<ClientAddressModel>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.filteredData.forEach((row) => this.selection.select(row));

    this.selectedItems.emit(this.selection.selected);
  }

  selectionChanged(event, cell) {
    this.selection.toggle(cell);
    this.selectedItems.emit(this.selection.selected);
  }

  selectedItemFn(key: number) {
    this.selectedItem.emit(key);
  }

  deleteItem(key: number) {
    this.delete.emit(key);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataSvc: AddressDataService,
    private globals: Globals,
    private translocoService: TranslocoService
  ) {
    if (this.route?.snapshot?.routeConfig?.path) {
      this.currentPath = this.route.snapshot.routeConfig.path;
    }

    const tableSetup = new AddressListData(this.translocoService);

    this.columnConfig = tableSetup.config;

    this.columns = this.columnConfig.map((column) => column.key);
  }

  configureDataTable() {
    // show delete icon
    if (!this.config.showDeleteIcon) {
      this.columnConfig = this.columnConfig.filter((col) => col.key !== 'deleteIcon');
    }

    this.columns = this.columnConfig.map((column) => column.key);
    // console.log(this.columns);
  }

  ngOnInit(): void {
    this.configureDataTable();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((obs) => {
      this.sortData(obs.active, obs.direction === 'asc');
    });

    if (this.searchFc) {
      this.searchFc.valueChanges.subscribe((obs) => {
        this.updateSearch(obs);
      });
    }
  }

  /**
   * set the casenote key
   * @param key - set the value of casenote key
   */
  // setCaseNoteKey(key: number) {
  //   this.dataSvc.setCaseNoteKey(key);
  // }

  sortData(column: string, asc: boolean): void {
    const data = this.data;
    const uniqVals = R.uniq(data.map((vals) => vals[column]));

    // avoid re-sorting a column that has one value.
    // TODO: move this out to a sorted value
    const nullChecked = data.map((itm) => ({ ...itm, [column]: itm[column] == null ? '' : itm[column] }));

    if (column === 'clientContactDateFormatted') {
      const sorted = this.sortResults('clientContactDate', nullChecked, asc);
      this.sortDirection = asc;
      this.sortColumn = column;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    } else {
      const sorted = this.sortResults(column, nullChecked, asc);
      this.sortColumn = column;
      this.sortDirection = asc;
      this.filteredData = uniqVals.length <= 1 ? data : sorted;
    }
  }

  sortResults(column: string, data: any[], isAsc = true): any[] {
    return R.sort(data, (a, b) => this.compare(a[column], b[column], isAsc));
  }

  compare(a, b, isAsc): any {
    if (!a) return -1;
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
    return (a.length < 1 ? -1 : a <= b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  async updateSearch(filterStr: string): Promise<void> {
    const filter = filterStr.toLowerCase();
    const data = this.data;
    const columns = this.columns;
    const filtered = data.filter((row) => {
      const picked = R.pick(row, columns);
      const mapped = R.toPairs(picked).map((val) => val[1]);
      const some = mapped.some((val: string) => {
        if (typeof val === 'string') {
          const test = val.toLowerCase().includes(filter);
          return test;
        } else return val === filter;
      });
      return some;
    });
    this.filteredData = filtered;
  }
}
