import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorFns } from '@core/models/cf2-validators.model';
import { FormsFns } from '@core/models/forms-fns.model';
import { OutcomesDataService } from '@modules/outcomes/services/outcomes-data.service';
import { ApprovalTaskData } from '@shared/components/approval-task/approval-task.component';

@Component({
  selector: 'cf2-finalize-outcome-dialog',
  template: `
    <h2 mat-dialog-title>Finalize</h2>
    <mat-dialog-content>
      <div class="flex-col">
        <cf2-select-field-fixed
          [ctrl]="dataSvc.fg.controls.claimStatusCode"
          [required]="true"
          [label]="dataSvc.labels.claimStatusCode"
          [options]="dataSvc.claimStatusCodesOpts$ | async"
        ></cf2-select-field-fixed>

        <!-- onholdReason -->
        <cf2-input-field-fixed
          [ctrl]="dataSvc.fg.controls.onholdReason"
          [label]="dataSvc.labels.onholdReason"
          [required]="dataSvc.fg.controls.claimStatusCode.value === 'ONHOLD'"
          *ngIf="dataSvc.fg.controls.claimStatusCode.value === 'ONHOLD'"
        ></cf2-input-field-fixed>
        <!-- onholdToDate -->

        <cf2-date-field-fixed
          [ctrl]="dataSvc.fg.controls.onholdToDate"
          [label]="dataSvc.labels.onholdToDate"
          [required]="dataSvc.fg.controls.claimStatusCode.value === 'ONHOLD'"
          dateType="pastDate"
          *ngIf="dataSvc.fg.controls.claimStatusCode.value === 'ONHOLD'"
        ></cf2-date-field-fixed>

        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          <label
            >Comment
            <!-- <span class="required-asterisk" *ngIf="isRequired">*</span> -->
          </label>
          <textarea rows="3" matInput [(ngModel)]="comment" class="comment-field"></textarea>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="clicked(true)" [disabled]="dataSvc.fg.invalid">Submit</button>
      <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./finalize-outcome-dialog.component.scss'],
})
export class FinalizeOutcomeDialogComponent implements OnInit {
  comment = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UntypedFormGroup,

    public dialogRef: MatDialogRef<FinalizeOutcomeDialogComponent>,
    public dataSvc: OutcomesDataService
  ) {}

  ngOnInit(): void {
    this.dataSvc.fg.controls.claimStatusCode.setValidators(Validators.required);

    this.dataSvc.fg.controls.claimStatusCode.updateValueAndValidity(FormsFns.formUpdateOpts);
  }
  clicked(evt: any) {
    this.dialogRef.close({ comment: this.comment, close: true });
  }
}
