import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CaseNotes } from '@shared/components/case-notes/models/case-notes.model';
import { CaseNotesListConfig } from '@shared/components/case-notes/case-notes-list/case-notes-list.component';
import { CaseNotesDataService } from '@shared/components/case-notes/services/case-notes-data.service';
import * as R from 'remeda';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cf2-link-case-notes-dialog',
  template: `
    <h2 mat-dialog-title>Link Case Notes</h2>

    <mat-dialog-content>
      <ng-container *ngIf="dataSub | async as data">
        <cf2-case-notes-list
          [config]="caseNotesListConfig"
          [isReady]="isReady"
          [caseKey]="caseKey"
          [data]="data"
          [filteredData]="data"
          (selectedCaseNotes)="onSelectedCaseNotes($event)"
        >
        </cf2-case-notes-list>
      </ng-container>

      <div class="loading" *ngIf="!isReady">CaseNotes Loading...</div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <!-- return empty array on cancel -->
      <button mat-button [mat-dialog-close]="[]">Cancel</button>
      <button mat-button [mat-dialog-close]="selectedCaseNotes" [disabled]="selectedCaseNotes.length === 0">
        Link
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host ::ng-deep mat-table {
        border: 1px solid $border-color;
      }

      :host .loading {
        text-align: center;
        font-size: 24px;
        padding: 16px;
      }
    `,
  ],
})
export class LinkCaseNotesDialogComponent implements OnInit {
  isReady = false;
  caseNotesListConfig: CaseNotesListConfig = {
    showSelection: true,
    showLockIcon: false,
  };
  caseKey: number;
  selectedCaseNotes: CaseNotes[] = [];
  dataSub: BehaviorSubject<CaseNotes[]> = new BehaviorSubject<CaseNotes[]>([]);

  constructor(
    public dialogRef: MatDialogRef<LinkCaseNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { linkedCaseNotes: CaseNotes[] },
    public dataSvc: CaseNotesDataService
  ) {
    console.log(this.constructor.name);
    console.log(dialogData);
  }

  clicked(evt: any) {
    this.dialogRef.close(true);
  }

  onSelectedCaseNotes(casenotes) {
    console.log('onSelectedCaseNotes', casenotes);
    this.selectedCaseNotes = casenotes;
  }

  /**
   * return documents which are not linked to show in the popup modal
   */
  getUnlinkedCaseNotes(casenotes: CaseNotes[]) {
    if (this.dialogData?.linkedCaseNotes && this.dialogData.linkedCaseNotes.length > 0) {
      const linkedCaseNoteKeys = this.dialogData.linkedCaseNotes.map((doc) => doc.parentCaseNoteKey);
      // return casenotes which are not alreayd linked
      return casenotes.filter((doc) => linkedCaseNoteKeys.indexOf(doc.parentCaseNoteKey) === -1);
    } else {
      return casenotes;
    }
  }

  processResposne(response) {
    if (response.status !== 200) {
      setTimeout(() => {
        this.isReady = true;
      }, 100);
      return console.log('Data failed to load with ', response.status);
    }

    const responseCaseNotes: CaseNotes[] = response.body as CaseNotes[];
    // hide already linked casenotes
    const unlinkedNotes = this.getUnlinkedCaseNotes(responseCaseNotes);
    const mapped = this.dataSvc.mapCaseNotes(unlinkedNotes);
    const sorted = R.sortBy(mapped, (rec: CaseNotes) => rec.clientContactDate).reverse();
    this.dataSub.next(sorted);
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnInit() {
    // get the form fields and send it to file-upload-details
    this.caseKey = this.dataSvc.getCaseKey();
    if (!this.caseKey) throw new Error(`no case key set in ${this.constructor.name} `);

    // console.log(this.dataSvc.documentsViewType);
    const viewType = this.dataSvc.caseNotesViewType;
    if (!viewType)
      throw new Error(`no view type set in ${this.constructor.name}; Use CaseNotesDataService.initCaseNotesCard`);

    // console.log(this.dataSvc.parentCategoryCode);
    const categoryCode: string = this.dataSvc.parentCategoryCode;
    if (!categoryCode) throw new Error(`no category code set in ${this.constructor.name}`);

    if (viewType === 'create') {
      // get filtered casenotes to show in link casenotes table
      // this.dataSvc.getAll().subscribe((response) => {
      //   this.processResposne(response);
      // });
      // view = edit
    } else {
      if (categoryCode === 'EXPENDITURE') {
        const expenditureKey = this.dataSvc.expenditureKey;
        if (!expenditureKey) throw new Error(`no expenditure key set in ${this.constructor.name}`);

        // this.dataSvc
        //   .getParentExpenditureAvailableCaseNotes(expenditureKey)
        //   .pipe(take(1))
        //   .subscribe((response) => {
        //     this.processResposne(response);
        //   });
      } else if (categoryCode === 'CLAIMS_OUTC') {
        const parentOutcomeClaimKey = this.dataSvc.outcomeClaimKey;
        if (!parentOutcomeClaimKey) throw new Error(`no outcome claim key set in ${this.constructor.name}`);

        this.dataSvc
          .getParentOutcomeClaimAvailableCaseNotes(parentOutcomeClaimKey, this.caseKey)
          .pipe(take(1))
          .subscribe((response) => {
            this.processResposne(response);
          });
      }
    }
  }
}
