import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { BaseComponent } from '@shared/components/base/base.component';
import { CaseMenuService } from '@core/services/client-menu.service';
import { MenuItem } from '@shared/components/menu/menu.component';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import * as R from 'remeda';
import { FileTypeFns } from '@shared/models/file-types.model';
import { ExpenditureModel, TaskModel } from '@api/models';
import { Globals } from 'src/app/globals';
import { WorkflowService } from '@api/services';
import { MonitoringPhaseDataService } from '@modules/outcomes/services/monitoring-phase-data.service';
import { caseKeysFilter } from '@core/models/case-keys.function';

/*

13.	The Expenditure Request History block provides a chronological history (oldest record displayed first) of the workflow/status changes for the Expenditure Request.  This is view only data block that by default is collapsed but can be expanded by selecting the expand action icon on the block.  The data displayed in this block includes:
a.	Status
b.	Status Date
c.	Created By (User who created the status)
d.	Comments (any comments captured against the workflow status, for example comments regarding a Returned status).

*/

@Component({
  selector: 'cf2-expenditure-workflows',
  template: `
    <ng-container *ngIf="dataSub | async as data">
      <cf2-expenditure-workflows-list
        [data]="data"
        [filteredData]="data"
        [isReady]="isReady"
        (selectedTask)="viewExpenditure($event)"
      ></cf2-expenditure-workflows-list>
    </ng-container>
  `,
  styleUrls: ['./expenditure-workflows.component.scss'],
})
export class ExpenditureWorkflowsComponent extends BaseComponent implements OnInit, OnDestroy {
  /* Menu Setup */
  expenditureMenuItems: MenuItem[] = this.caseMenuSvc.caseMenuItems;

  expenditureKey: number;

  /* set as observable */
  dataSub = new BehaviorSubject<TaskModel[]>([]);

  date: Date = new Date();

  constructor(
    private caseMenuSvc: CaseMenuService,

    private workflSvc: WorkflowService,
    private ngZone: NgZone,

    private globals: Globals,
    private mpDataSvc: MonitoringPhaseDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();

    /* if the key is here make some call */

    /* map data */
  }

  /**
   *
   * @param module the module code from task.moduleCode
   * @returns the object containing the globals key to set and the link to navigate to
   */
  getModuleAccessors(module: string) {
    const moduleDict = {
      EXPMGMT: { key: 'expenditureKey', link: '/clients/expenditure/view' },
      CLAIMMGMT: { key: 'outcomeClaimKey', link: '/clients/outcomes' },
    };
    return moduleDict[module];
  }

  async viewExpenditure(task: TaskModel) {
    /* TODO: Update the expenditure workflow to get the right one.  */
    const acc = this.getModuleAccessors(task.moduleCode);

    const isOutcome = task.moduleCode === 'CLAIMMGMT';

    this.workflSvc
      .apiWorkflowRecordLinkCaseHeaderGet$Json({
        recordLink: isOutcome ? `CLAIMMGMT|${task.fkrecordKey}` : task.recordLink,
      })
      .pipe(take(1))

      .subscribe((response) => {
        this.clearStore();
        const client = response;
        const caseKey = client.parentCaseKey;
        const clientKey = client.parentClientKey;
        this.globals[acc.key] = task.fkrecordKey;

        this.setGlobalKeys({
          caseKey,
          clientKey,
        });

        isOutcome
          ? this.addSubscription(
              this.viewOutcome(task.fkrecordKey)
                .pipe(
                  switchMap(() =>
                    of(
                      this.navigate('clients/outcomes/view', {
                        ...caseKeysFilter(response),
                        outcomeKey: task.fkrecordKey,
                      })
                    )
                  )
                )
                .subscribe()
            )
          : this.ngZone.run(() => this.navigate(acc.link, { caseKey, clientKey, expenditureKey: task.fkrecordKey }));
      });
  }

  viewOutcome(ocKey: number) {
    this.globals.outcomeKey = ocKey;
    return this.mpDataSvc.monitoringPhase$.pipe(switchMap(() => this.mpDataSvc.monitoringPhaseOutcomes$));
  }

  loadData() {
    this.workflSvc
      .apiWorkflowGet$Json$Response({})
      .pipe(take(1))
      .subscribe((response) => {
        if (response.status !== 200) {
          this.isReady = true;
          return console.log('data failed to load with ', response.status);
        }
        const sorted = R.sortBy(response.body, (rec) => rec.dueDate);

        this.dataSub.next(sorted);
        this.isReady = true;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
