import { ExpenditureParametersType, OutcomeParametersType } from '@core/types/cf2-parameters.type';

const expenditureStatusCode = ['COMPL_APPR', 'INPR', 'RECOMMEND'];
export const EXPENDITURE_FILTER_CONFIG: ExpenditureParametersType = {
  expenditureStatusCode,
  site: null,
  fromDate: null,
  toDate: null,
};

export const OUTCOME_FILTER_CONFIG: OutcomeParametersType = {};
