import { MonitoringPhaseModel } from '@api/models';
import { FormsComponentField } from '@core/models/base-component-field';
import { Cf2Forms } from '@core/models/cf2-forms.model';
import { FormsFns } from '@core/models/forms-fns.model';

import {parseDate} from "@progress/kendo-angular-intl";

import { TranslocoService } from '@ngneat/transloco';
export type MonitoringPhaseKeysType = keyof MonitoringPhaseModel | 'monitoringPhaseCode';

export type MonitoringPhaseLabelsType = Record<MonitoringPhaseKeysType, boolean | string>;

interface MonitoringFormConfig extends FormsComponentField {
  key: MonitoringPhaseKeysType;
}

export interface MonitoringPhaseFormOpts {
  value?: Partial<MonitoringPhaseModel> & Partial<MonitoringPhaseModel>;
}

const MONITORING_PHASE_FIELD_CONFIG: readonly MonitoringFormConfig[] = [
  /*  monitoring phase details */
  {
    key: 'monitoringStartDate',
    type: 'pastDate',
    label: 'Monitoring Start Date',
    required: true,

  },
  {
    key: 'startReasonCode',
    label: 'Monitoring Phase Start Reason',
    type: 'select',
  },
  {
    key: 'monitoringPhaseCode',
    label: 'Monitoring Phase',
    type: 'select',
    required: false,
    disabled: true,
  },
];

export class MonitoringPhaseForm extends Cf2Forms {
  _fg: any;
  _labels: any;
  _disabled: any;
  _required: any;

  get mapTypes() {
    return this.mapTypes([...MONITORING_PHASE_FIELD_CONFIG]);
  }
  constructor(fb, ts : TranslocoService, opts: MonitoringPhaseFormOpts = { value: null }) {
    super(fb,ts);
    const { value = null } = opts;

    const config = [...MONITORING_PHASE_FIELD_CONFIG];

    const mappedConfig = value
      ? FormsFns.mapValue(config)({
          ...value,
          monitoringStartDate: value?.monitoringStartDate ? parseDate(value.monitoringStartDate) : null,
        })
      : config;

    const { labels, disabled, fg, required } = this.makeFields(mappedConfig);

    this._labels = labels;
    this._disabled = disabled;
    this._fg = fg;
    this._required = required;
  }

  get fg() {
    return this._fg;
  }

  get labels() {
    return this._labels;
  }

  get disabled() {
    return this._disabled;
  }

  get required() {
    return this._required;
  }
}
