/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssessmentDataModel } from '../models/assessment-data-model';
import { CaseDesignationModel } from '../models/case-designation-model';
import { CaseEmployeeModel } from '../models/case-employee-model';
import { CaseHeaderModel } from '../models/case-header-model';
import { CaseModel } from '../models/case-model';
import { CaseNoteModel } from '../models/case-note-model';
import { CaseServiceInformationModel } from '../models/case-service-information-model';
import { CaseSiteModel } from '../models/case-site-model';
import { CaseStatusHistory } from '../models/case-status-history';
import { CaseloadModel } from '../models/caseload-model';
import { DocumentDto } from '../models/document-dto';
import { EapModel } from '../models/eap-model';
import { EmployerModel } from '../models/employer-model';
import { EmployerPositionReferralModel } from '../models/employer-position-referral-model';
import { ExpenditureModel } from '../models/expenditure-model';
import { ExternalContactModel } from '../models/external-contact-model';
import { MonitoringPhaseModel } from '../models/monitoring-phase-model';
import { ProcessResponse } from '../models/process-response';
import { ProgramModel } from '../models/program-model';
import { ProgramStreamModel } from '../models/program-stream-model';
import { TaskSearchModel } from '../models/task-search-model';
import { EapInclusionGroupModel } from '@api/models/eap-inclusion-group-model';
import { MsalService } from '@azure/msal-angular';
import { ICaseToEmployeeMap } from '@shared/components/case-employees/services/case-employees-data.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private authService: MsalService
  ) {
    super(config, http);
  }  

  /**
   * Path part for operation apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost
   */
  static readonly ApiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPostPath = '/api/Case/{parentCaseKey}/monitoringPhase/{parentMonitoringPhaseKey}/link';

  /**
   * Link a position to a monitoring phase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json$Response(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    parentMonitoringPhaseKey: number;

    /**
     * Employer position
     */
    parentEmployerPositionKey?: number;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});
      rb.path('parentMonitoringPhaseKey', params.parentMonitoringPhaseKey, {});
      rb.query('parentEmployerPositionKey', params.parentEmployerPositionKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Link a position to a monitoring phase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    parentMonitoringPhaseKey: number;

    /**
     * Employer position
     */
    parentEmployerPositionKey?: number;

  }): Observable<string> {

    return this.apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete
   */
  static readonly ApiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDeletePath = '/api/Case/{parentCaseKey}/monitoringPhase/{parentMonitoringPhaseKey}/link';

  /**
   * Unlink a position from a monitoring phase.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json$Response(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    parentMonitoringPhaseKey: number;

    /**
     * Employer position
     */
    parentEmployerPositionKey?: number;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDeletePath, 'delete');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});
      rb.path('parentMonitoringPhaseKey', params.parentMonitoringPhaseKey, {});
      rb.query('parentEmployerPositionKey', params.parentEmployerPositionKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Unlink a position from a monitoring phase.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    parentMonitoringPhaseKey: number;

    /**
     * Employer position
     */
    parentEmployerPositionKey?: number;

  }): Observable<string> {

    return this.apiCaseParentCaseKeyMonitoringPhaseParentMonitoringPhaseKeyLinkDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiCaseExternalContactsParentExternalContactKeyGet
   */
  static readonly ApiCaseExternalContactsParentExternalContactKeyGetPath = '/api/Case/externalContacts/{parentExternalContactKey}';

  /**
   * Gets an External Contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseExternalContactsParentExternalContactKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseExternalContactsParentExternalContactKeyGet$Json$Response(params: {
    parentExternalContactKey: number;

  }): Observable<StrictHttpResponse<ExternalContactModel>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseExternalContactsParentExternalContactKeyGetPath, 'get');
    if (params) {

      rb.path('parentExternalContactKey', params.parentExternalContactKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExternalContactModel>;
      })
    );
  }

  /**
   * Gets an External Contact.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseExternalContactsParentExternalContactKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseExternalContactsParentExternalContactKeyGet$Json(params: {
    parentExternalContactKey: number;

  }): Observable<ExternalContactModel> {

    return this.apiCaseExternalContactsParentExternalContactKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExternalContactModel>) => r.body as ExternalContactModel)
    );
  }

  /**
   * Path part for operation apiCaseExternalContactsParentExternalContactKeyDelete
   */
  static readonly ApiCaseExternalContactsParentExternalContactKeyDeletePath = '/api/Case/externalContacts/{parentExternalContactKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseExternalContactsParentExternalContactKeyDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseExternalContactsParentExternalContactKeyDelete$Response(params: {
    parentExternalContactKey: number;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseExternalContactsParentExternalContactKeyDeletePath, 'delete');
    if (params) {

      rb.path('parentExternalContactKey', params.parentExternalContactKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseExternalContactsParentExternalContactKeyDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseExternalContactsParentExternalContactKeyDelete(params: {
    parentExternalContactKey: number;

  }): Observable<void> {

    return this.apiCaseExternalContactsParentExternalContactKeyDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCaseFilteredGet
   */
  static readonly ApiCaseFilteredGetPath = '/api/Case/list';

  apiCaseFilteredGet(params?: {
    parentOrganizationKey?: null | number;
    parentSiteKey?: null | Array<string>;
    stream?: null | Array<string>;
    caseStatus?: null | Array<string>;
    employee?: null | Array<string>;
    program?: null | Array<string>,
    contractType?: null | Array<string>,
    pageNumber?: 0 | number;
    pageSize: 15 | number;
    sortColumn: '' | string;
    sortDirection: 'asc' | string;
  }): Observable<Array<CaseloadModel>> {
    let url = `${this.rootUrl}${CaseService.ApiCaseFilteredGetPath}?${this.objectToQueryString(params)}`;
    return this.http.get<CaseloadModel[]>(url);
  }

  objectToQueryString(params: any): string {
    if (params) {
      const tempParams = [];
      for (const p in params)
        if (params.hasOwnProperty(p)) {
          let value = params[p];
          if (value === null || (Array.isArray(value) && value.length < 1)) continue;
          tempParams.push(encodeURIComponent(p) + '=' + value);
        }
      const queryString = tempParams.join('&');
      return queryString;
    }
    return '';
  }

  /**
   * Path part for operation apiCaseParentCaseKeyGet
   */
  static readonly ApiCaseParentCaseKeyGetPath = '/api/Case/{parentCaseKey}';

  /**
   * Returns a single case record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<CaseModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseModel>>;
      })
    );
  }

  /**
   * Returns a single case record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<CaseModel>> {

    return this.apiCaseParentCaseKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseModel>>) => r.body as Array<CaseModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyHeaderGet
   */
  static readonly ApiCaseParentCaseKeyHeaderGetPath = '/api/Case/{parentCaseKey}/header';

  /**
   * Returns the case header record (streamlined case / client data).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyHeaderGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<CaseHeaderModel>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyHeaderGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CaseHeaderModel>;
      })
    );
  }

  /**
  * Returns the case header record (streamlined case / client data).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyHeaderGet$Json(params: {
    parentCaseKey: number;

  }): Observable<CaseHeaderModel> {

    return this.apiCaseParentCaseKeyHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CaseHeaderModel>) => r.body as CaseHeaderModel)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyEapsGet
   */
  static readonly ApiCaseParentCaseKeyEapsGetPath = '/api/Case/{parentCaseKey}/eaps';

  /**
   * Get a list of all EAP records for a case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyEapsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
 apiCaseParentCaseKeyEapsGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<EapModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyEapsGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EapModel>>;
      })
    );
  }

  /**
   * Get a list of all EAP records for a case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyEapsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyEapsGet$Json(params: {
    parentCaseKey: number;
  }): Observable<Array<EapModel>> {

    return this.apiCaseParentCaseKeyEapsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EapModel>>) => r.body as Array<EapModel>)
    );
  }

  /**
   * Path part for operation apiCaseProgramsGet
   */
  static readonly ApiCaseProgramsGetPath = '/api/Case/programs';

  /**
   * Get a list of all active programs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseProgramsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseProgramsGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<ProgramModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseProgramsGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
   })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramModel>>;
      })
    );
  }

  /**
   * Get a list of all active programs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseProgramsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseProgramsGet$Json(params?: {

  }): Observable<Array<ProgramModel>> {

    return this.apiCaseProgramsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProgramModel>>) => r.body as Array<ProgramModel>)
    );
  }

  /**
   * Path part for operation apiCaseProgramsParentProgramKeyStreamsGet
   */
  static readonly ApiCaseProgramsParentProgramKeyStreamsGetPath = '/api/Case/programs/{parentProgramKey}/streams';

  /**
   * Get a list of all active streams associated with a program.
  *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseProgramsParentProgramKeyStreamsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseProgramsParentProgramKeyStreamsGet$Json$Response(params: {
    parentProgramKey: number;

  }): Observable<StrictHttpResponse<Array<ProgramStreamModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseProgramsParentProgramKeyStreamsGetPath, 'get');
    if (params) {

      rb.path('parentProgramKey', params.parentProgramKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramStreamModel>>;
      })
    );
  }

  /**
   * Get a list of all active streams associated with a program.
   *
   *
  *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseProgramsParentProgramKeyStreamsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseProgramsParentProgramKeyStreamsGet$Json(params: {
    parentProgramKey: number;

  }): Observable<Array<ProgramStreamModel>> {

    return this.apiCaseProgramsParentProgramKeyStreamsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProgramStreamModel>>) => r.body as Array<ProgramStreamModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyCaseNotesGet
   */
  static readonly ApiCaseParentCaseKeyCaseNotesGetPath = '/api/Case/{parentCaseKey}/caseNotes';

  /**
   * Get all case notes associated with a case record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyCaseNotesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyCaseNotesGet$Json$Response(params: {
    parentCaseKey: number;

 }): Observable<StrictHttpResponse<Array<CaseNoteModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyCaseNotesGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseNoteModel>>;
      })
    );
  }

  /**
   * Get all case notes associated with a case record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyCaseNotesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyCaseNotesGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<CaseNoteModel>> {

   return this.apiCaseParentCaseKeyCaseNotesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseNoteModel>>) => r.body as Array<CaseNoteModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyCaseNotesPost
   */
  static readonly ApiCaseParentCaseKeyCaseNotesPostPath = '/api/Case/{parentCaseKey}/caseNotes';

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyCaseNotesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyCaseNotesPost$Json$Response(params: {
    parentCaseKey: number;
    body?: CaseNoteModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyCaseNotesPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
     accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update case note.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyCaseNotesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyCaseNotesPost$Json(params: {
    parentCaseKey: number;
    body?: CaseNoteModel
  }): Observable<ProcessResponse> {

    return this.apiCaseParentCaseKeyCaseNotesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiCaseGet
   */
  static readonly ApiCaseGetPath = '/api/Case';

 /**
   * Get tasks assigned to a user/employee.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<TaskSearchModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TaskSearchModel>>;
      })
    );
  }

  /**
   * Get tasks assigned to a user/employee.
   *
   *
  *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseGet$Json(params?: {

  }): Observable<Array<TaskSearchModel>> {

    return this.apiCaseGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TaskSearchModel>>) => r.body as Array<TaskSearchModel>)
    );
  }

  /**
   * Path part for operation apiCasePost
   */
  static readonly ApiCasePostPath = '/api/Case';

  /**
   * Update a case record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCasePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCasePost$Json$Response(params?: {

    /**
     * Case
    */
    body?: CaseModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCasePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update a case record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCasePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCasePost$Json(params?: {

    /**
    * Case
     */
    body?: CaseModel
  }): Observable<string> {

    return this.apiCasePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiCaseCaseKeyDocumentsGet
   */
  static readonly ApiCaseCaseKeyDocumentsGetPath = '/api/Case/{caseKey}/documents';

  /**
   * Get a filtered list of documents for a case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseCaseKeyDocumentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseCaseKeyDocumentsGet$Json$Response(params: {
    caseKey: number;
    fromDate?: null | string;
    toDate?: null | string;
    documentSectionCode?: null | string;
    documentTypeCode?: null | string;
    includeArchived?: boolean;
    //documentCategoryCode?: null | string;
    //documentSubCategoryCode?: null | string;
  }): Observable<StrictHttpResponse<Array<DocumentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseCaseKeyDocumentsGetPath, 'get');
    if (params) {

      rb.path('caseKey', params.caseKey, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentTypeCode', params.documentTypeCode, {});
      rb.query('includeArchived', params.includeArchived, {});
      //rb.query('documentCategoryCode', params.documentCategoryCode, {});
      //rb.query('documentSubCategoryCode', params.documentSubCategoryCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentDto>>;
      })
    );
  }

  /**
   * Get a filtered list of documents for a case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseCaseKeyDocumentsGet$Json$Response()` instead.
   *
  * This method doesn't expect any request body.
   */
  apiCaseCaseKeyDocumentsGet$Json(params: {
    caseKey: number;
    fromDate?: null | string;
    toDate?: null | string;
    documentSectionCode?: null | string;
    documentTypeCode?: null | string;
    includeArchived?: boolean;

  }): Observable<Array<DocumentDto>> {

    return this.apiCaseCaseKeyDocumentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>) => r.body as Array<DocumentDto>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyAssessmentsGet
   */
  static readonly ApiCaseParentCaseKeyAssessmentsGetPath = '/api/Case/{parentCaseKey}/assessments';

  /**
   * Get all assessments associated with a case record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyAssessmentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyAssessmentsGet$Json$Response(params: {
    parentCaseKey: number;
  }): Observable<StrictHttpResponse<Array<AssessmentDataModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyAssessmentsGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AssessmentDataModel>>;
      })
    );
  }

  /**
   * Get all assessments associated with a case record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyAssessmentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyAssessmentsGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<AssessmentDataModel>> {

   return this.apiCaseParentCaseKeyAssessmentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AssessmentDataModel>>) => r.body as Array<AssessmentDataModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyAssessmentsPost
   */
  static readonly ApiCaseParentCaseKeyAssessmentsPostPath = '/api/Case/{parentCaseKey}/assessments';

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyAssessmentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyAssessmentsPost$Json$Response(params: {
    parentCaseKey: number;
    body?: AssessmentDataModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyAssessmentsPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
     accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create or update an assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyAssessmentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyAssessmentsPost$Json(params: {
    parentCaseKey: number;
    body?: AssessmentDataModel
  }): Observable<ProcessResponse> {

    return this.apiCaseParentCaseKeyAssessmentsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyReferralsGet
   */
  static readonly ApiCaseParentCaseKeyReferralsGetPath = '/api/Case/{parentCaseKey}/referrals';

  /**
   * Retrieves all the Employment Position Referrals for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyReferralsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyReferralsGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<EmployerPositionReferralModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyReferralsGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerPositionReferralModel>>;
      })
    );
  }

  /**
   * Retrieves all the Employment Position Referrals for a Case.
  *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyReferralsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyReferralsGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<EmployerPositionReferralModel>> {

    return this.apiCaseParentCaseKeyReferralsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerPositionReferralModel>>) => r.body as Array<EmployerPositionReferralModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyEmployersGet
   */
  static readonly ApiCaseParentCaseKeyEmployersGetPath = '/api/Case/{parentCaseKey}/employers';

  /**
   * Retrieves all the Employers for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyEmployersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyEmployersGet$Json$Response(params: {
   parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<EmployerModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyEmployersGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployerModel>>;
      })
    );
  }

  /**
   * Retrieves all the Employers for a Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyEmployersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyEmployersGet$Json(params: {
    parentCaseKey: number;

 }): Observable<Array<EmployerModel>> {

    return this.apiCaseParentCaseKeyEmployersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployerModel>>) => r.body as Array<EmployerModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyExpendituresGet
   */
  static readonly ApiCaseParentCaseKeyExpendituresGetPath = '/api/Case/{parentCaseKey}/expenditures';

  /**
   * Retrieves all the Expenditures for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExpendituresGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<ExpenditureModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExpendituresGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExpenditureModel>>;
      })
    );
  }

  /**
   * Retrieves all the Expenditures for a Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExpendituresGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<ExpenditureModel>> {

    return this.apiCaseParentCaseKeyExpendituresGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExpenditureModel>>) => r.body as Array<ExpenditureModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyExpendituresPost
   */
  static readonly ApiCaseParentCaseKeyExpendituresPostPath = '/api/Case/{parentCaseKey}/expenditures';
  /**
   * Create or Update an expenditure record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExpendituresPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyExpendituresPost$Json$Response(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Expenditure
     */
    body?: ExpenditureModel
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExpendituresPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
     filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        //return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<any>;
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) }) as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Create or Update an expenditure record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExpendituresPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyExpendituresPost$Json(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Expenditure
     */
    body?: ExpenditureModel
  }): Observable<any> {

    return this.apiCaseParentCaseKeyExpendituresPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }
  /**
   * Path part for operation apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet
   */
  static readonly ApiCaseParentCaseKeyExpendituresParentExpenditureKeyGetPath = '/api/Case/{parentCaseKey}/expenditures/{parentExpenditureKey}';

  /**
   * Retrieves a Expenditure for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json$Response(params: {
    parentCaseKey: number;
    parentExpenditureKey: number;

  }): Observable<StrictHttpResponse<ExpenditureModel>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExpendituresParentExpenditureKeyGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<ExpenditureModel>;
      })
    );
  }

  /**
   * Retrieves a Expenditure for a Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json(params: {
    parentCaseKey: number;
    parentExpenditureKey: number;

  }): Observable<ExpenditureModel> {

    return this.apiCaseParentCaseKeyExpendituresParentExpenditureKeyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExpenditureModel>) => r.body as ExpenditureModel)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyMonitoringPhaseGet
   */
  static readonly ApiCaseParentCaseKeyMonitoringPhaseGetPath = '/api/Case/{parentCaseKey}/monitoringPhase';

  static readonly ApiCaseParentCaseKey12MonthClaimStatusGetPath = '/api/Case/{parentCaseKey}/claimStatus';

  /**
  * Retrieves the 12 Month Claim status for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKey12MonthClaimStatusGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKey12MonthClaimStatusGet$Plain$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKey12MonthClaimStatusGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }


  /**
  * Retrieves the 12 Month claim status for a Case.
 *
  *
  *
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `apiCaseParentCaseKey12MonthClaimStatusGet$Plain$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
  apiCaseParentCaseKey12MonthClaimStatusGet$Plain(params: {
    parentCaseKey: number;

  }): Observable<string> {

    return this.apiCaseParentCaseKey12MonthClaimStatusGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Retrieves the Monitoring Phase record for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyMonitoringPhaseGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<MonitoringPhaseModel>> {
    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyMonitoringPhaseGetPath, 'get');
   if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoringPhaseModel>;
      })
    );
  }

  /**
   * Retrieves the Monitoring Phase record for a Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyMonitoringPhaseGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyMonitoringPhaseGet$Json(params: {
    parentCaseKey: number;

  }): Observable<MonitoringPhaseModel> {

    return this.apiCaseParentCaseKeyMonitoringPhaseGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoringPhaseModel>) => r.body as MonitoringPhaseModel)
    );

 }

  /**
   * Path part for operation apiCaseParentCaseKeyMonitoringPhasePost
   */
  static readonly ApiCaseParentCaseKeyMonitoringPhasePostPath = '/api/Case/{parentCaseKey}/monitoringPhase';

  /**
   * Create or Update a monitoring phase record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyMonitoringPhasePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyMonitoringPhasePost$Json$Response(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    body?: MonitoringPhaseModel
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyMonitoringPhasePostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create or Update a monitoring phase record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyMonitoringPhasePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyMonitoringPhasePost$Json(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Monitoring Phase
     */
    body?: MonitoringPhaseModel
  }): Observable<string> {

    return this.apiCaseParentCaseKeyMonitoringPhasePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyExternalContactsGet
   */
  static readonly ApiCaseParentCaseKeyExternalContactsGetPath = '/api/Case/{parentCaseKey}/externalContacts';

  /**
   * Gets a list of External Contacts for a Case.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExternalContactsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExternalContactsGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<ExternalContactModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExternalContactsGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ExternalContactModel>>;
      })
    );
  }

  /**
   * Gets a list of External Contacts for a Case.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExternalContactsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExternalContactsGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<ExternalContactModel>> {

    return this.apiCaseParentCaseKeyExternalContactsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ExternalContactModel>>) => r.body as Array<ExternalContactModel>)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyExternalContactsPost
   */
  static readonly ApiCaseParentCaseKeyExternalContactsPostPath = '/api/Case/{parentCaseKey}/externalContacts';

  /**
   * Creates or Updates External Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExternalContactsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyExternalContactsPost$Json$Response(params: {
    parentCaseKey: number;
    body?: ExternalContactModel
  }): Observable<StrictHttpResponse<ExternalContactModel>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExternalContactsPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExternalContactModel>;
      })
    );
  }

  /**
   * Creates or Updates External Contacts.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExternalContactsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCaseParentCaseKeyExternalContactsPost$Json(params: {
    parentCaseKey: number;
    body?: ExternalContactModel
  }): Observable<ExternalContactModel> {

    return this.apiCaseParentCaseKeyExternalContactsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExternalContactModel>) => r.body as ExternalContactModel)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyStatusHistoryGet
   */
  static readonly ApiCaseParentCaseKeyStatusHistoryGetPath = '/api/Case/{parentCaseKey}/statusHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyStatusHistoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyStatusHistoryGet$Json$Response(params: {
    parentCaseKey: number;

  }): Observable<StrictHttpResponse<Array<CaseStatusHistory>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyStatusHistoryGetPath, 'get');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseStatusHistory>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyStatusHistoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyStatusHistoryGet$Json(params: {
    parentCaseKey: number;

  }): Observable<Array<CaseStatusHistory>> {

    return this.apiCaseParentCaseKeyStatusHistoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseStatusHistory>>) => r.body as Array<CaseStatusHistory>)
    );
  }

  public assignCases(data: ICaseToEmployeeMap[]) {
    const url = `/api/Case/assign-cases-to-employees`;
    const rb = new RequestBuilder(this.rootUrl, url, 'post');
    rb.body(data, 'application/*+json');

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyUploadPost
   */
  static readonly ApiCaseParentCaseKeyUploadPostPath = '/api/Case/{parentCaseKey}/upload';

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyUploadPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCaseParentCaseKeyUploadPost$Json$Response(params: {
    parentCaseKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    //documentCategoryCode?: null | string;
    //documentSubCategoryCode?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyUploadPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});
      rb.query('documentSectionCode', params.documentSectionCode, {});
      rb.query('documentType', params.documentType, {});
      rb.query('comment', params.comment, {});
      rb.query('documentDate', params.documentDate, {});
      //rb.query('documentCategoryCode', params.documentCategoryCode, {});
      //rb.query('documentSubCategoryCode', params.documentSubCategoryCode, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Upload Document.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyUploadPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiCaseParentCaseKeyUploadPost$Json(params: {
    parentCaseKey: number;
    documentSectionCode?: null | string;
    documentType?: null | string;
    comment?: null | string;
    documentDate?: null | string;
    body?: { 'file'?: Blob }
  }): Observable<ProcessResponse> {

    return this.apiCaseParentCaseKeyUploadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

  /**
   * Path part for operation apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost
   */
  static readonly ApiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPostPath = '/api/Case/{parentCaseKey}/expenditures/{parentExpenditureKey}/submit';

  /**
   * Submit an expenditure record for approval.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Expenditure
     */
    parentExpenditureKey: number;

  }): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPostPath, 'post');
    if (params) {

      rb.path('parentCaseKey', params.parentCaseKey, {});
      rb.path('parentExpenditureKey', params.parentExpenditureKey, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Submit an expenditure record for approval.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json(params: {

    /**
     * Case
     */
    parentCaseKey: number;

    /**
     * Expenditure
     */
    parentExpenditureKey: number;

  }): Observable<number> {

    return this.apiCaseParentCaseKeyExpendituresParentExpenditureKeySubmitPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }



  /**
   * Path part for operation apiCaseInaciveCasesGet
   */
  static readonly ApiCaseInactiveCasesGetPath = '/api/Case/InactiveCases';

  /**
   * Get a list of active cases assigned to the current user.
   *
   * This method doesn't expect any request body.
   */
  apiCaseInactiveCasesGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<Array<CaseModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CaseService.ApiCaseInactiveCasesGetPath, 'get');
    if (params) {

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CaseModel>>;
      })
    );
  }


  apiCaseInactiveCasesGet$Json(params: {}): Observable<Array<CaseModel>> {
    return this.apiCaseInactiveCasesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CaseModel>>) => r.body as Array<CaseModel>)
    );
  }
}
