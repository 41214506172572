export const ActiveDirectoryConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/',
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },
  clientId: {
    // York
    yorkDev: 'cd383cac-7349-4b0b-99f0-e4191e86383a',
    yorkUat: '6ac63a1b-9d2d-40ce-b460-f6335e504ba7',
    yorkProd: 'a9c24873-e1e6-4f4f-a69c-49dffae56133',
    // Peel
    peelDev: '92e087f0-8f37-4d8a-9bac-124d30b5ad07',
    peelUat: '81ec43b0-6e23-49cc-84fc-c256308288bf',
    peelProd: 'dcf54c28-aefa-46a1-a62c-fd1bcddcb27d',
    // Other
    localDev: '63e764b2-393f-4917-b15a-ebf3ea22435f',
    cams: '0d4003ee-d619-4573-a40f-4e4528b83394',
    wcgTraining: '7fdde454-ed16-49ff-97fb-fe6791adcb2d',
  },
  tenantId: {
    // York
    yorkDev: '57b68a03-0d3b-4b64-ad06-66d0834b1516',
    yorkUat: '57b68a03-0d3b-4b64-ad06-66d0834b1516',
    yorkProd: '88bcacd2-67d7-4482-a6de-2ac34814c8c1',
    // Peel
    peelDev: '57b68a03-0d3b-4b64-ad06-66d0834b1516',
    peelUat: '57b68a03-0d3b-4b64-ad06-66d0834b1516',
    peelProd: '88bcacd2-67d7-4482-a6de-2ac34814c8c1',
    // Other
    localDev: '57b68a03-0d3b-4b64-ad06-66d0834b1516',
    cams: '88bcacd2-67d7-4482-a6de-2ac34814c8c1',
    wcgTraining: '88bcacd2-67d7-4482-a6de-2ac34814c8c1',
  },
} as const;