import { Component, OnInit, OnDestroy } from '@angular/core';
import { Globals } from './globals';
import { Observable, of, Subject } from 'rxjs';
// import { TranslateService } from '@ngx-translate/core';
import { ApplicationSettingsService } from './api/services/application-settings.service';
import { environment } from '@environments/environment';
import { CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { } from '@core/core.module';
import { NavigationStateService } from '@core/services/navigation-state.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ConsentScopes } from '@core/factories/cf2-msal.factory';
import { TranslocoService } from '@ngneat/transloco';

const PRODUCTION = environment.production;

@Component({
  selector: 'wcg-app',
  template: `
    <!-- <app-navigation> -->
    <router-outlet></router-outlet>
    <!-- </app-navigation> -->

    
    <ng-container *ngIf="accessDenied">
      <h6>Access Denied</h6>
    </ng-container>
  `,
  providers: [ApplicationSettingsService, { provide: CF2_CONFIG, useExisting: CF2_CONFIG }],
})
export class AppComponent implements OnInit, OnDestroy {
  public accessDenied = false;

  private production = PRODUCTION;
  private unsubscribe = new Subject<void>();
  adUser: AccountInfo;


  constructor(private globals: Globals,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private navStateSvc: NavigationStateService,
    private transloco : TranslocoService) {
  }

  async ngOnInit() {
    document.addEventListener('DOMNodeInserted', function() {
      const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });

    this.navStateSvc.init(this.transloco);

    this.msalService.instance.handleRedirectPromise().then(res => {
      if (res?.account) {
        this.msalService.instance.setActiveAccount(res.account);
      }
    })
    this.init();

    let language = "";
    const ci = localStorage.getItem("LangPref");
    if(ci !== undefined && ci !== null ){
      language = ci;
    } else {
      language = navigator.language.substring(0,2);
    }
    this.transloco.setActiveLang(language);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  private init() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.unsubscribe)
      ).subscribe((status) => {
        this.getDetails().subscribe(() => {
          // do nothing
        }, error => {
          this.msalService.instance.loginRedirect();
        })
      })
  }


  private getDetails(): Observable<AccountInfo> {
    const account = this.msalService.instance.getActiveAccount();
    if (account) return of(account);
    const accessTokenRequest = {
      scopes: ConsentScopes,
      account: account
    } as SilentRequest;

    return this.msalService.acquireTokenSilent(accessTokenRequest)
      .pipe(map(data => data.account));
  }

  login() {
    // not implemented
  }


}
