import { Directive, ElementRef, Host, HostBinding, Input, TemplateRef } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { TaskModel } from '@api/models';

@Directive({
  selector: '[cf2Spinner]',
  exportAs: 'spinner',
})
export class SpinnerDirective<T> {
  // @Input('class') public class: string;
  // @HostBinding('class.spinner-wrapper') loading = true;

  @Input('cf2Spinner') set isLoading(loading: boolean) {
    // this.loading = loading;
    // console.log(this);
    this.table.dataSource;
    const ref: MatSortable = { id: 'taskName', start: 'desc', disableClear: false };
    // setTimeout(() => this.sort.sort(ref), 1000);

    // this.elementRef.nativeElement.removeFooterRowDef;
  }

  setSpinner() {
    // this.elementRef.nativeElement.class = 'spinner-wrapper';
  }
  constructor(private elementRef: ElementRef<MatTable<any>>, private table: MatTable<T>, private sort: MatSort) {
    // console.log(this);
    this.setSpinner();
  }
}
/*
  Position the spinner overtop of incoming content
*/
// .spinner-wrapper {
//     left: 50%;
//     top: 50%;
//     margin-top: -50%;
//     display: block;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-image: url("/assets/images/spinner.gif");
//     width: 100px;
//     height: 100px;
//     margin: 0 auto;
//     position: fixed;
// }
