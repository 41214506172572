import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDisabilityModel } from '@api/models';
import { MultipleEntriesComponent } from '@shared/components/multiple-entries/multiple-entries-card.component';
import { ModalService } from '@shared/services/modal.service';
import { DisabilitiesListConfig } from '../disabilities-list/disabilities-list.component';
import { DisabilitiesDataService } from '../services/disabilities-data.service';
import { ClientDisabilityLabelsType } from '../models/disabilities-form.model';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';
import { Globals } from 'src/app/globals';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';

import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-disabilities-card',
  template: `
  <ng-container *transloco="let t"> 
    
    <div class="cf2-multiple-entries-card flex-col">
      <div class="action-buttons flex-row flex-between">
        <h3>{{ t('Disabilities') }}</h3>
        <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addForm()">{{ t('Add') }}</button>
      </div>

      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub$ | async as data">
          <cf2-disabiities-list
          [isready]="isReady"
            [config]="listConfig"
            [data]="data"
            [filteredData]="data"
            (selectedItem)="showEditEntityForm($event)"
            ></cf2-disabiities-list>
          </ng-container>
      </div>

      <div class="edit-block flex-col" *ngIf="showForm">
        <cf2-disabilities-form [fields]="fields"> </cf2-disabilities-form>
        <div class="action-buttons flex-row flex-end">
          <button mat-button class="button--secondary" (click)="cancel()">{{ t('Cancel') }}</button>
          <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addNew()">{{ t('Save') }}</button>
          <button mat-button *ngIf="!isReadOnly && editMode" class="button--secondary" (click)="updateEntity()">
            Save
          </button>
        </div>
      </div>
    </div>
    </ng-container>
    `,
})
export class DisabilitiesCardComponent
  extends MultipleEntriesComponent<ClientDisabilityModel, ClientDisabilityLabelsType>
  implements OnInit {
    listConfig: DisabilitiesListConfig = {
      showDeleteIcon: false,
    };
    
    get isReadOnly() {
      return this.globals.viewType === 'VIEW';
    }
  
    constructor(
      private dataSvc: DisabilitiesDataService,
      private globals: Globals,
      router: Router,
      modalSvc: ModalService,
      clientCaseDataSvc: ClientCaseDataService,
      private translocoService: TranslocoService
    ) {
    super(router, modalSvc, clientCaseDataSvc);
  }

  ngOnInit() {
    this.dataSub$ = this.dataSvc.entityListDataSub$;
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  initForm(formValue = {}) {
    const form = this.dataSvc.initForm(formValue);
    this.fields = form.fields;
    this.fg = this.fields.fg;
    
    setTimeout(() => {
      this.showForm = true;
    }, 100);
  }

  showEditEntityForm(keyId: number) {
    const entityData = this.dataSvc.getEntityDataByKey(keyId);
    const {
      key,
      code,
      primary,
      disclosable,
      comment,
      effectiveDate,
      clientDisabilityExpiryDateFormatted,
    } = entityData.entity;

    const formValue: Partial<ClientDisabilityModel> = {
      key,
      code,
      primary,
      disclosable,
      comment,
      effectiveDate: effectiveDate
        ? effectiveDate
        : null,
      clientDisabilityExpiryDate: clientDisabilityExpiryDateFormatted
        ? clientDisabilityExpiryDateFormatted
        : null,
    };

    this.showEditForm(key, formValue);
  }

  showEditForm(key: number, formValue: Partial<ClientDisabilityModel>) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedKey === key) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    
    this.editMode = true;
    this.dataSvc.selectedKey = key;
    // TODO: remove timeout
    setTimeout(() => this.initForm(formValue), 10);
  }
  
  addForm() {
    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    // reset selected casenote
    this.dataSvc.selectedKey = null;
    this.editMode = false;

    // set default values for new entity
    const formValue: Partial<ClientDisabilityModel> = {
      disclosable: false,
      primary: false,
    };
    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const entity = this.fg.value as ClientDisabilityModel;

    if (entity.primary) {
      this.dataSvc.resetPrimary();
    }

    const newEntry = this.dataSvc.addEntityData({
      tempKey: this.generateKey(),
      entity: {
        ...this.fg.value,
        createdDateFormatted: parseDateFormattedFromDate(new Date()),
        createdUserName: this.globals.displayName,
      },
    });
    
    this.dataSvc.sanitizeForm();
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    this.save();
  }
  
  updateEntity() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const entity = this.fg.value as ClientDisabilityModel;

    if (entity.primary) {
      this.dataSvc.resetPrimary();
    }
    
    this.dataSvc.updateEntityData({
      tempKey: this.dataSvc.selectedKey,
      entity: {
        ...entity,
        updatedDateFormatted: parseDateFormattedFromDate(new Date()),
        updatedUserName: this.globals.displayName,
      },
    });
    
    this.dataSvc.sanitizeForm();
    this.editMode = false;
    this.showForm = false;
    
    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());
    
    // save all client case form data to backend
    this.save();
  }

  cancel() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeForm();
  }
}
