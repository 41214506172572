import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { ApiModule } from '@api/api.module';
import { MsalModule, MsalInterceptor, MsalService, MsalBroadcastService, MSAL_INSTANCE, MsalGuard, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { Cf2MaterialModule } from '@modules/cf2-material/cf2-material.module';
import { CUSTOM_DATE_FORMATS, CustomDatePickerAdapter } from '@shared/models/date-formats.model';

import {  MsalInstanceFactory, MsalInterceptorConfigFactory } from './factories/cf2-msal.factory';
import { Cf2Store } from './models/store.model';
import { CF2_CONFIG, ENVIRONMENT_CONFIG } from './tokens/cf2-config.token';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TransitionScreenComponent } from './components/transition-screen/transition-screen.component';
import {Injector} from '@angular/core';


export function getBaseUrl(): string {
  return document.getElementsByTagName('base')[0].href;
}

export let InjectorInstance: Injector;

export function getBaseUrlClean(): string {
  let baseUrl = document.getElementsByTagName('base')[0].href;

  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substr(0, baseUrl.length - 1);
  }

  return baseUrl;
}

@NgModule({
  declarations: [TransitionScreenComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    Cf2MaterialModule,
    RouterModule,

    ApiModule.forRoot({ rootUrl: getBaseUrlClean() }),
    MsalModule,
    TranslocoModule
  ],
  exports: [MsalModule, ApiModule, HttpClientModule],
})
export class CoreModule {
  msalSvc: MsalService;
  constructor(@Optional() @SkipSelf() coreModule: CoreModule, private injector: Injector) {
    if (coreModule) {
      throw new TypeError(`core module imported twice`);
    }
    InjectorInstance = this.injector;
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        MsalService,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MsalInstanceFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MsalInterceptorConfigFactory,
        },
        { provide: CF2_CONFIG, useValue: ENVIRONMENT_CONFIG },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: DateAdapter, useClass: CustomDatePickerAdapter },
        {
          provide: Cf2Store,
          useValue: new Cf2Store(),
        },
      ],
    };
  }
}
