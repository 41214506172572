import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { InputFieldTopComponent } from '../input-field-top/input-field-top.component';

@Component({
  selector: 'cf2-typeahead-search-field',
  templateUrl: './typeahead-search-field.component.html',
  styles: [],
})
export class TypeaheadSearchFieldComponent extends InputFieldTopComponent implements OnInit, AfterViewChecked {
  @Input() options: { description: string; value: string }[];

  @Input() ctrl: FormControl;
  @Input() placeholder: string;
  @Input() defaultOpiton: boolean = false;

  @Input() appearance: string = 'outline';

  @Output() lookupSelected = new EventEmitter<string>();

  public filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  filterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  private emptyOption: { description: string; value: string }[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    //if this field is not mandatory, provide empty option
    if (!this.required && this.defaultOpiton == true) {
      this.emptyOption = [{ description: 'none', value: null }];
    } else {
      this.emptyOption = [];
    }

    this.selectOptions.subscribe((list) => {
      // load the initial option list
      this.filteredOptions.next([...this.emptyOption, ...list.slice()]);

      //clear value if not in list
      if (!list.find((i) => i.value === this.ctrl.value)) {
        this.ctrl?.setValue(null);
      }
    });

    // listen for search field value changes
    this.filterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOptions();
    });

    this.ctrl.valueChanges.subscribe((val) => {
      this.lookupSelected.emit(val);
    });
  }

  public ngAfterViewChecked(): void {
    if (this.value !== undefined && this.value.length > 0) this.updateValue();
    if (this.disabled) this.ctrl.disable();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get isValid() {
    const selection = this.ctrl.value;
    const filtered = this.options.filter((opt) => opt.value === selection);
    if (this.required) {
      return filtered.length > 0;
    }
    return true;
  }

  clearValue() {
    this.ctrl.patchValue('');
  }

  get selectOptions(): Observable<any[]> {
    const list = this.options;
    if (Array.isArray(list)) {
      return of(<any[]>list);
    } else {
      return list as Observable<any[]>;
    }
  }

  protected filterOptions() {
    if (!this.selectOptions) {
      return;
    }

    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.selectOptions.subscribe((list) => {
        this.filteredOptions.next([...this.emptyOption, ...list.slice()]);
      });
      return;
    } else {
      search = search.toLowerCase();
    }
    //filter the options
    this.selectOptions.subscribe((list) =>
      this.filteredOptions.next([
        ...this.emptyOption,
        ...list.filter((option) => option.description.toLowerCase().indexOf(search) > -1),
      ])
    );
  }
}
