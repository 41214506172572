import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserAccess, StateModel, CaseHeaderModel } from '@api/models';
import { Cf2Store } from '@core/models/store.model';
import { UserStateService } from '@core/services/user-state.service';
import { LoggingService } from '@core/services/logging.service';
import { Cf2ConfigType, CF2_CONFIG } from '@core/tokens/cf2-config.token';
import { CaseHeaderService } from '@core/services/case-header.service';
import { ViewType } from '@core/models/view-type.model';
import { APP_KEYS } from '@core/models/app-keys';

@Injectable({
  providedIn: 'root',
})
export class Globals extends Cf2Store {
  currentUrl: string;
  constructor(
    @Inject(CF2_CONFIG) private config: Cf2ConfigType,
    private userSvc: UserStateService,
    private logSvc: LoggingService
  ) {
    super();
    this.initialize()
    console.log('globals initiated');
  }

  clearCaseKeys() {
    this.config.caseKeys.forEach((key) => (this[key] = 0));
  }

  clearStore() {
    this.logSvc.logError({ lvl: 'DEBUG', mssg: `store cleared` });
    // this.clearCaseKeys();
    // this.userSvc.clearStore();
  }

  public updateNavigation$ = new BehaviorSubject<boolean>(null);

  public clientId = '';
  public applicationDataLoaded = false;
  public applicationTitle = '';
  public serviceTitle = '';
  public applicationVersion = '';
  public environment = '';
  public deploymentDate = '';
  public defaultGridRows = 100;
  public maximumFileSizeMb = 10;
  public fileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/tiff',
    'image/x-tiff',
    'image/bmp',
    'image/x-windows-bmp',
    'image/gif',

    'text/plain',

    'application/pdf',

    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ];

  public isMaintenance = false;

  public hstIndicator = false;
  public hstPercent = 13;
  public gstPercent = 0;
  public pstPercent = 0;
  public q16CutOffDate = '';
  public displayName = '';
  public firstName = '';
  public lastName = '';
  public email = '';
  public username = '';
  public functionalAccess: UserAccess[];
  public loggedIn = false;

  // un-authentication routes
  public ignoreAuthentication: string[] = ['/client-inquiry', '/assessment'];

  // extended other user data
  public languageCode = '';
  public siteName = '';
  public siteKey = 0;
  public organizationName = '';
  public organizationKey = 0;
  public roleCode = '';
  public roleDescription = '';
  public employeeKey = 0;

  // navigation management
  private _activeModule: string;
  private _activeSubModule: string;
  private _previousModule: string;
  private _previousSubModule: string;

  // state management
  private _caseKey = 0;
  private _clientKey = 0;
  private _documentKey = 0;
  private _employerPositionKey = 0;
  private _employerPositionReferralKey = 0;
  private _clientDisplayName = '';
  private _expenditureKey = 0;
  private _eapKey = 0;
  private _meetingKey = 0;
  private _meetingOccurrenceKey = 0;
  
  // integration dashboard state management
  private _dashClientKey = 0;
  private _dashCapturedId = 0;
  public  dashCount = 0;

  get isProduction() {
    return this.environment === 'Production';
  }

  private _incomeSourceCode = null;

  get incomeSourceCode() {
    return this._incomeSourceCode;
  }

  set incomeSourceCode(code: string) {
    if (code == null) return;
    this._incomeSourceCode = code;
  }

  private _caseOrgKey = 0;
  get caseOrgKey() {
    return this._caseOrgKey || 0;
  }

  set caseOrgKey(key: number) {
    this._caseOrgKey = key || 0;
  }

  private _viewType: ViewType = 'VIEW';

  setViewType(caseHeader: CaseHeaderModel) {
    const canEdit = this.userSvc.canEditCase(caseHeader);

    // case header is null indicates that there is no case. Then user might be creating a new Client/Case
    if (caseHeader === null) this._viewType = 'CREATE';
    else this._viewType = canEdit ? 'EDIT' : ('VIEW' as ViewType);
  }

  set viewType(view: ViewType) {
    this._viewType = view;
  }

  get viewType() {
    return this._viewType as ViewType;
  }

  returnUrl = 'clients';

  public clearUserSession(): void {
    this.displayName = '';
    this.firstName = '';
    this.email = '';
    this.username = '';
    this.loggedIn = false;
  }

  public clearSession(): void {
    this.displayName = '';
    this.firstName = '';
    this.email = '';
    this.username = '';
    this.loggedIn = false;
    this._clientKey = 0;
    this._documentKey = 0;
    this._employerPositionKey = 0;
    this._employerPositionReferralKey = 0;
    this._eapKey = 0;
    this._expenditureKey = 0;
    this._clientDisplayName = '';
    this._activeModule = '';
    this._activeSubModule = '';
    this._meetingKey = 0;
    this._meetingOccurrenceKey = 0;
  }

  public resetKeys(): void {
    this.activeModule = '';
    this.activeSubModule = '';
    this.clientKey = 0;
    this.caseKey = 0;
    this.documentKey = 0;
    this.employerPositionKey = 0;
    this.employerPositionReferralKey = 0;
    this.eapKey = 0;
    this.expenditureKey = 0;
    this.clientDisplayName = '';
  }

  get omOrganizationalUnitKey() {
    return this.userSvc.getProp('parentOrganizationalUnitKey');
  }

  // set cached value
  public setStateValue(key: string | number, value: string | number): void {
    value;
  }

  public auditor(): boolean {
    return this.roleCode == 'AUD';
  }

  // active module property
  public set activeModule(value: string) {
    if (value === this._activeModule) return;
    if (value === '') this.setStateValue('activeModule', '%20');
    else this.setStateValue('activeModule', value);

    if (value !== this._activeModule) {
      this._previousModule = this._activeModule;
      this._previousSubModule = this._activeSubModule;
    }

    this._activeModule = value;
  }

  public get activeModule(): string {
    return this._activeModule;
  }

  public updateActiveModule(value: string, subModule: string): void { }

  // active sub-module property
  public set activeSubModule(value: string) {
    if (value === this._activeSubModule) return;
    if (value === '') this.setStateValue('activeSubModule', '%20');
    else this.setStateValue('activeSubModule', value);

    if (value !== this._activeSubModule) {
      this._previousSubModule = this._activeSubModule;
    }

    this._activeSubModule = value;
  }

  public get activeSubModule(): string {
    return this._activeSubModule;
  }

  // current route
  public get currentRoute(): string {
    let route = '/' + this._activeModule;

    if (
      this._activeSubModule !== undefined &&
      this._activeSubModule !== null &&
      this._activeSubModule !== '%20' &&
      this._activeSubModule.length > 0
    ) {
      route += '/' + this._activeSubModule;
    }

    return route;
  }

  // previous module(s)
  public get previousRoute(): string {
    let route = '/' + this._previousModule;

    if (
      this._previousSubModule !== undefined &&
      this._previousSubModule !== null &&
      this._previousSubModule !== '%20' &&
      this._previousSubModule.length > 0
    ) {
      route += '/' + this._previousSubModule;
    }

    return route;
  }

  public get previousModule(): string {
    return this._previousModule;
  }

  public get previousSubModule(): string {
    return this._previousSubModule;
  }

  // case key property
  public set caseKey(value: number) {
    if (value === this._caseKey || !value) return;
    this.setStateValue('caseKey', value.toString());

    this._caseKey = value;
  }

  public get caseKey(): number {
    return this._caseKey;
  }

  // eap key property
  public set eapKey(value: number) {
    if (value === this._eapKey) return;
    this.setStateValue('eapKey', value.toString());
    this._eapKey = value;
  }

  public get eapKey(): number {
    return this._eapKey;
  }

  // display name property
  public set clientDisplayName(value: string) {
    if (value === this._clientDisplayName) return;
    // if (value === undefined || value === null || value === '') value = '%20';
    this.setStateValue('clientDisplayName', value);
    this._clientDisplayName = value;
  }

  public get clientDisplayName(): string {
    return this._clientDisplayName;
  }

  // client key property
  public set clientKey(value: number) {
    if (value === this._clientKey) return;
    // this.setStateValue('clientKey', value.toString());
    this._clientKey = value;
  }

  public get clientKey() {
    return this._clientKey;
  }

  // document key property
  public set documentKey(value: number) {
    if (value === this._documentKey) return;
    // this.setStateValue('documentKey', value.toString());
    this._documentKey = value;
  }

  public get documentKey() {
    return this._documentKey;
  }

  // employment position key
  public set employerPositionKey(value: number) {
    if (value === this._employerPositionKey) return;
    this.setStateValue('employmentPositionKey', value.toString());
    this._employerPositionKey = value;
  }

  public get employerPositionKey() {
    return this._employerPositionKey;
  }

  // employment position referral key
  public set employerPositionReferralKey(value: number) {
    if (value === this._employerPositionReferralKey) return;
    this.setStateValue('employmentPositionReferralKey', value.toString());
    this._employerPositionReferralKey = value;
  }

  public get employerPositionReferralKey() {
    return this._employerPositionReferralKey;
  }

  // expenditure key
  public get expenditureKey() {
    return this._expenditureKey;
  }

  public set expenditureKey(value: number) {
    if (!value) return;
    if (isNaN(value)) {
      throw new Error('value set in expenditure key is NaN');
    }
    this._expenditureKey = value;
  }

  // Meeting Key
  public set meetingKey(value: number) {
    if (value === this._meetingKey) return;
    this._meetingKey = value;
  }

  public get meetingKey() {
    return this._meetingKey;
  }

  // Meeting Occurence Key
  public set meetingOccurrenceKey(value: number) {
    if (value === this._meetingOccurrenceKey) return;
    this._meetingOccurrenceKey = value;
  }

  public get meetingOccurrenceKey() {
    return this._meetingOccurrenceKey;
  }

  private _monitoringPhaseKey = 0;

  public get monitoringPhaseKey() {
    return this._monitoringPhaseKey;
  }

  public set monitoringPhaseKey(key: number) {
    this.setStateValue('monitoringPhaseKey', key ? key : null);

    this._monitoringPhaseKey = key;
  }

  private _outcomeKey = 0;

  public get outcomeKey() {
    return this._outcomeKey;
  }

  public set outcomeKey(key: number) {
    this.setStateValue('outcomeKey', key ? key : null);

    this._outcomeKey = key;
  }

  async load() {
    return (await Promise.all(APP_KEYS.map(key => {
      return this.getItem(key).toPromise().then(value => ({ key, value }))
    }))).filter(kvp => !!kvp.value);
  }

  async initialize(): Promise<void> {
    const data = await this.load();

    for (let keyValuePair of data) {
      this[keyValuePair.key as any] = keyValuePair.value;
    }
  }
  
  public set dashClientKey(key: number) {
    this._dashClientKey = key;
  }
  
  public get dashClientKey() {
    return this._dashClientKey;
  }

  public set dashCapturedId(id: number) {
    this._dashCapturedId = id;
  }

  public get dashCapturedId() {
    return this._dashCapturedId;
  }
}
