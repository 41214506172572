import { Injectable } from '@angular/core';
import { ClientDisabilityModel } from '@api/models';
import { SelectOptions } from '@core/models/select-options.model';
import { DataService } from '@core/services/data.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  DisabilitiesForm,
  ClientDisabilityLabelsType,
} from '@shared/components/disabilities/models/disabilities-form.model';
import * as R from 'remeda';
import { map, tap } from 'rxjs/operators';
import { MultipleEntriesData } from '@shared/components/multiple-entries/multiple-entries.model';
import { MultipleEntriesService } from '@shared/components/multiple-entries/multiple-entries-data.service';
import { LoggingService } from '@core/services/logging.service';
import { ClientService } from '@api/services';
import { Globals } from 'src/app/globals';
import { TranslocoService } from '@ngneat/transloco';
import { parseDateFormattedFromDate } from '@shared/models/date-formats.model';

interface EntityData extends MultipleEntriesData<ClientDisabilityModel> {}

@Injectable({ providedIn: 'root' })
export class DisabilitiesDataService extends MultipleEntriesService<
  ClientDisabilityModel,
  ClientDisabilityLabelsType,
  EntityData
> {
  clientDisabilitiesTypeList: SelectOptions[];
  constructor(
    private fb: RxFormBuilder,
    private dataSvc: DataService,
    private logSvc: LoggingService,
    private clientSvc: ClientService,
    private globals: Globals,
    private translocoService: TranslocoService
  ) {
    super();
  }

  get clientKey() {
    return this.globals.clientKey;
  }
  get clientDisabilityTypes$() {
    return this.dataSvc.lookupRecords('DisabilityType').pipe(
      map((result) => DataService.lookupToOptions(result, this.translocoService.getActiveLang())),
      tap((result) => {
        this.clientDisabilitiesTypeList = result;
      })
    );
  }

  initForm(value?: Partial<ClientDisabilityModel>) {
    this.formFields = value ? new DisabilitiesForm(this.fb, this.translocoService, { value: value }) : new DisabilitiesForm(this.fb, this.translocoService);
    this.formFields.fields.fg.patchValue(value);
    this.formGroup = this.formFields.fields.fg;

    return this.formFields;
  }

  getEntityDataByKey(key: number) {
    return this.entityData.filter((entityData) => entityData.entity.key === key)[0];
  }

  resetPrimary() {
    this.entityData = this.entityData.map((data) => {
      if (data.entity.primary) {
        data.action = 'UPDATE';
        data.entity.primary = false;
      }
      return data;
    });
  }

  addEntityData(entityData: EntityData) {
    // create new casenote
    entityData.action = 'CREATE';
    const newEntry = this.constructEntityData(entityData);
    this.entityData.push(newEntry);

    return newEntry;
  }

  constructEntityData(data: EntityData) {
    const entityData = data;
    const { effectiveDate, clientDisabilityExpiryDate } = entityData.entity;

    entityData.entity.key = entityData.tempKey;
    entityData.entity.effectiveDate = effectiveDate
      ? effectiveDate
      : null;
    entityData.entity.clientDisabilityExpiryDateFormatted = clientDisabilityExpiryDate
      ? parseDateFormattedFromDate(new Date(clientDisabilityExpiryDate))
      : null;

    // check if list values already exists
    if (this.clientDisabilitiesTypeList.length > 0) {
      this.clientDisabilitiesTypeList.forEach((listItem) => {
        if (listItem.value === entityData.entity.code) {
          entityData.entity.disabilityTypeDescription = listItem.description;
        }
      });
    }

    return entityData;
  }

  getEntityData() {
    const filtered = R.reject(this.entityData, (entity) => {
      return !!entity.action && entity.action === 'DELETE';
    });
    return filtered.map((obj) => obj.entity);
  }

  updateEntityData(entityData: EntityData) {
    this.entityData = this.entityData.map((data : any) => {
      if (data.tempKey === entityData.tempKey) {
        // construct updated caseNoteData
        data.entity = {
          ...data.entity,
          ...this.constructEntityData(entityData).entity,
        };

        // Update exising case notes
        if (data.tempKey >= 1) {
          data.action = 'UPDATE';
        }
      }

      return data;
    });
  }

  mapEntityData(entities: ClientDisabilityModel[]): EntityData[] {
    const entityData = entities.map((data) => {
      return {
        tempKey: data.key,
        entity: {
          ...data,
          effectiveDate: data.effectiveDate
            ? new Date(data.effectiveDate)
            : null,
          expiryDate : data.clientDisabilityExpiryDate
          ? new Date(data.clientDisabilityExpiryDate)
          : null,
          clientDisabilityExpiryDate: data.clientDisabilityExpiryDateFormatted
            ? data.clientDisabilityExpiryDateFormatted
            : null,
        },
      };
    });

    return entityData;
  }

  getParentClientKeyClientDisabilities(parentClientKey: number) {
    return this.clientSvc.apiClientParentClientKeyClientDisabilitiesGet$Json({
      parentClientKey,
    });
  }

  async saveParentClientDisabilities(parentClientKey: number) {
    if (this.entityData.length === 0)
      return Promise.resolve({
        status: 200,
      });

    if (!parentClientKey) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name} saveParentClientDisabilities: parentClientKey is not set`,
      });
      return Promise.reject({
        status: 400,
      });
    }

    try {
      // Remove  without action
      const entitiesWithAction = R.reject(this.entityData, (data) => !data.action);
      console.log('Entities With Action', entitiesWithAction);
      const entityPromises = R.flatMap(entitiesWithAction, (data) => {
        const {
          code,
          primary,
          comment,
          disclosable,
          effectiveDate,
          clientDisabilityExpiryDate,
        } = data.entity;

        // create new
        if (data.action === 'CREATE') {
          return this.clientSvc
            .apiClientParentClientKeyClientDisabilitiesPost$Json$Response({
              parentClientKey,
              body: {
                code,
                primary,
                comment,
                disclosable,
                parentClientKey,
                effectiveDate,
                clientDisabilityExpiryDate,
              },
            })
            .toPromise();

          // update metadata
        } else if (data.action === 'UPDATE') {
          return this.clientSvc
            .apiClientParentClientKeyClientDisabilitiesPost$Json$Response({
              parentClientKey,
              body: data.entity,
            })
            .toPromise();
        }
      });

      // console.log(entityPromises);
      if (entityPromises.length === 0) {
        return Promise.resolve({
          status: 200,
        });
      } else {
        const response = await Promise.all(entityPromises);
        let status: number = 200;
        response.forEach((res) => (res.status !== 200 ? (status = 500) : ''));

        return Promise.resolve({ status });
      }
    } catch (e) {
      this.logSvc.logError({
        lvl: 'ERROR',
        mssg: `${this.constructor.name}.saveParentClientKeyDisabilities: Error saving disabilities`,
      });
      // console.log(e);
      return e;
    } finally {
      // sanitize  actions to prevent performing same updates to the db again
      this.entityData = R.reject(this.entityData, (data) => data.action === 'DELETE').map((data) => {
        return {
          ...data,
          action: null,
        };
      });
    }
  }
}
