import { Injectable } from '@angular/core';
import { MenuItem } from '@shared/components/menu/menu.component';
import { Router } from '@angular/router';
import { CaseNotesDataService } from '@shared/components/case-notes/services/case-notes-data.service';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root',
})
export class CaseMenuService {
  private _caseMenuItems: MenuItem[] = [
    {
      label: 'Add Case Note',
      key: 'addNote',
      icon: 'edit',
      disabled: false,
      fontAwesomeIcon: true,
    },
    { label: 'Book Appointment', key: 'book', icon: 'event', disabled: true },
    {
      label: 'Add EAP Activity',
      key: 'addEap',
      icon: 'list_alt',
      disabled: false,
    },
    {
      label: 'Add Expenditure Request',
      key: 'addExpenditure',
      icon: 'attach_money',
      disabled: true,
    },
    {
      label: 'Add Employment',
      key: 'addEmployment',
      icon: 'business_center',
      disabled: false,
    },
    {
      label: 'Add Case Document',
      key: 'addDocument',
      icon: 'edit',
      disabled: false,
    },
    {
      label: 'Add Outcome',
      key: 'addOutcome',
      icon: 'check_box',
      disabled: true,
    },
  ];
  get caseMenuItems() {
    return this._caseMenuItems;
  }
  constructor(
    private globals: Globals,
    private router: Router,
    private caseNotesDataService: CaseNotesDataService
  ) { }

  menuSelect(selected: { index: number; value: string }) {
    const { value } = selected;
    if (value === this.caseMenuItems[0].key) return this.onAddCaseNote();
    if (value === this.caseMenuItems[4].key) return this.onAddEmployment();
    if (value === this.caseMenuItems[5].key) return this.onAddDocument();
    if (value === this.caseMenuItems[2].key) return this.onAddEap();
  }

  private onAddCaseNote() {
    this.caseNotesDataService.newModuleCaseNote('case', this.globals.caseKey, [
      this.router.url,
    ]);
  }

  /* add a new Employment */
  onAddEmployment() {
    this.router.navigate([`clients/employment-history/new`]);
  }

  private onAddDocument() {
    this.router.navigate(['clients/documents/new']);
  }

  private onAddEap() {
    this.router.navigate(['clients/eap/new']);
  }
}
