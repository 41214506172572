/* tslint:disable */
export enum ProcessResponse {
  Success = 'Success',
  Failed = 'Failed',
  FailedDuplicate = 'FailedDuplicate',
  FailedRecordNotFound = 'FailedRecordNotFound',
  FailedMissingForeignKeyData = 'FailedMissingForeignKeyData',
  ErrorDatabaseUnavailable = 'ErrorDatabaseUnavailable',
  ErrorCamsUnavailable = 'ErrorCamsUnavailable',
  FailedInvalidFileType = 'FailedInvalidFileType',
  FailedDocumentNotFound = 'FailedDocumentNotFound',
  FailedMeetingRecordNotFound = 'FailedMeetingRecordNotFound',
  FailedMeetingOccurrenceRecordNotFound = 'FailedMeetingOccurrenceRecordNotFound',
  FailedMeetingNoLongerAvailable = 'FailedMeetingNoLongerAvailable',
  FailedOrganizationRecordNotFound = 'FailedOrganizationRecordNotFound',
  FailedPositionRecordNotFound = 'FailedPositionRecordNotFound',
  FailedPositionRecordFilled = 'FailedPositionRecordFilled',
  FailedDuplicateSiteName = 'FailedDuplicateSiteName',
  FailedSiteRecordNotFound = 'FailedSiteRecordNotFound',
  FailedSiteAttributeRecordNotFound = 'FailedSiteAttributeRecordNotFound',
  FailedSiteRoomRecordNotFound = 'FailedSiteRoomRecordNotFound',
  FailedAttendeeRecordNotFound = 'FailedAttendeeRecordNotFound',
  FailedInvalidPropertyRecord = 'FailedInvalidPropertyRecord',
  FailedPropertyRequestAlreadyExists = 'FailedPropertyRequestAlreadyExists',
  SuccessInquiryComplete = 'SuccessInquiryComplete',
  FailedInquiryRecordNotFound = 'FailedInquiryRecordNotFound',
  FailedAssessmentRecordNotFound = 'FailedAssessmentRecordNotFound',
  FailedClientRecordNotFound = 'FailedClientRecordNotFound',
  FailedClientCaseRecordNotFound = 'FailedClientCaseRecordNotFound',
  FailedClientCaseRecordNotActive = 'FailedClientCaseRecordNotActive',
  FailedInvalidCaseRecord = 'FailedInvalidCaseRecord',
  FailedClientSelfIdentificationRecordNotFound = 'FailedClientSelfIdentificationRecordNotFound',
  FailedCaseDesignationRecordNotFound = 'FailedCaseDesignationRecordNotFound',
  FailedExternalContactNotFound = 'FailedExternalContactNotFound',
  FailedClientDisabilityNotFound = 'FailedClientDisabilityNotFound',
  FailedCaseNoteRecordNotFound = 'FailedCaseNoteRecordNotFound',
  FailedEmployerRecordNotFound = 'FailedEmployerRecordNotFound',
  FailedEmployerPositionRecordNotFound = 'FailedEmployerPositionRecordNotFound',
  FailedEmployerPositionReferralRecordNotFound = 'FailedEmployerPositionReferralRecordNotFound',
  FailedUserRecordNotFound = 'FailedUserRecordNotFound',
  FailedEmployeeRecordNotFound = 'FailedEmployeeRecordNotFound',
  FailedUserIdExists = 'FailedUserIdExists',
  FailedGroupNameExists = 'FailedGroupNameExists',
  FailedActiveEapRecordExists = 'FailedActiveEapRecordExists',
  FailedEapRecordNotFound = 'FailedEapRecordNotFound',
  FailedExpenditureRecordNotFound = 'FailedExpenditureRecordNotFound',
  FailedExpenditureCannotBeUpdated = 'FailedExpenditureCannotBeUpdated',
  FailedInvalidExpenditureRecord = 'FailedInvalidExpenditureRecord',
  FailedExpenditureCannotBeFinalized = 'FailedExpenditureCannotBeFinalized',
  FailedInvalidAssistanceType = 'FailedInvalidAssistanceType',
  FailedMonitoringPhaseRecordNotFound = 'FailedMonitoringPhaseRecordNotFound',
  FailedMonitoringPhaseCannotBeUpdated = 'FailedMonitoringPhaseCannotBeUpdated',
  FailedInvalidMonitoringPhaseRecord = 'FailedInvalidMonitoringPhaseRecord',
  FailedMonitoringPhaseCannotBeFinalized = 'FailedMonitoringPhaseCannotBeFinalized',
  FailedOutcomeClaimRecordNotFound = 'FailedOutcomeClaimRecordNotFound',
  FailedOutcomeClaimCannotBeUpdated = 'FailedOutcomeClaimCannotBeUpdated',
  FailedInvalidTaskMetadata = 'FailedInvalidTaskMetadata'
}
