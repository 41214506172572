import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { dateBuilder } from '@core/models/date.model';
import { IdTypesDataService } from '../services/id-types-data.services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientAddressModel } from '@api/models';
import { ClientIdentifierLabelsType } from '../models/id-types-form.model';

export interface IdTypesFormConfig {
  showEffectiveDate?: boolean;
  showExpiryDate?: boolean;
  showPrimaryField?: boolean;
  showClientIdentifierType?: boolean;
}

@Component({
  selector: 'cf2-id-types-form',
  template: `
    <div class="flex-row flex-wrap">
      <ng-container *ngIf="clientIdentifierTypes$ | async as options">
        <cf2-select-field-top
          [ctrl]="getFc('code')"
          [label]="labels.code"
          [required]="required.code"
          [disabled]="isReadOnly || disabled.code"
          [options]="options"
        ></cf2-select-field-top>
      </ng-container>

      <cf2-input-field-top
        [ctrl]="getFc('comment')"
        [label]="labels.comment"
        [required]="required.comment"
        [disabled]="isReadOnly"
      >
      </cf2-input-field-top>
    </div>
  `,
})
export class IdTypesFormComponent extends BaseComponent implements OnInit {
  isInit = false;
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: ClientIdentifierLabelsType;
    disabled: ClientIdentifierLabelsType;
    required: ClientIdentifierLabelsType;
  };

  @Input() config: IdTypesFormConfig = {
    showEffectiveDate: true,
    showExpiryDate: true,
    showClientIdentifierType: true,
  };

  clientIdentifierTypes$ = this.dataSvc.clientIdentifierTypes$;

  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;

  get isReadOnly() {
    return this.dataSvc.getIsReadOnly();
  }
  getFc: (formKey: string) => AbstractControl;
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);

  constructor(private dataSvc: IdTypesDataService) {
    super();
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-id-types-form>, you have to initalize id types form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc(fg);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
