import { InjectionToken, Injector } from '@angular/core';
import { EXPENDITURE_FILTER_CONFIG, OUTCOME_FILTER_CONFIG } from '@core/constants/cf2-filter-defaults.constants';
import { CASE_KEYS, USER_STATE_KEYS } from '@core/constants/cf2-key-config.constants';
import { CF2_APP_ROLES } from '@core/constants/cf2-roles.constants';
import { environment } from '@environments/environment';

const { timeout: TIMEOUT } = environment;
const ODSP_INCOME_SOURCES = ['ODSP', 'OW'] as const;

export const ENVIRONMENT_CONFIG = {
  timeout: TIMEOUT,
  expenditureFilter: EXPENDITURE_FILTER_CONFIG,
  outcome: OUTCOME_FILTER_CONFIG,
  caseKeys: CASE_KEYS,
  userKeys: USER_STATE_KEYS,
  incomeSourceODSPorOw: ODSP_INCOME_SOURCES,
  roles: CF2_APP_ROLES,
} as const;

// const injector = Injector.create({ provide: isLoggedIn, multi: true, useValue: ENVIRONMENT_CONFIG });
export type Cf2ConfigType = typeof ENVIRONMENT_CONFIG;

export const CF2_CONFIG = new InjectionToken<Cf2ConfigType>('app.config');

export type Cf2ConfigValuesType<T extends keyof Cf2ConfigType> = Cf2ConfigType[T];

export type CaseKeysType = typeof CASE_KEYS[number];
