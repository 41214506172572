const ICON_SET = [
  'expand_more',
  'expand_less',
  'filter_less',
  'chevron_right',
  'filter_plus',
  'filter_list',
  'settings',
  'account_circle',
  'search',
  'add',
  'call',
  'date_range',
  'tune',
] as const;

export type AppIcon = typeof ICON_SET[number];

interface IconConfig {
  [key: string]: AppIcon;
}

export const ICON_CONFIG = {
  filter: 'tune',
  filterClose: 'chevron_right',
  profile: 'account_circle',
  search: 'search',
  create: 'add',
  phone: 'call',
  book: 'date_range',
} as IconConfig;
