/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClientSiteAssessmentInquiryModel } from '../models/client-site-assessment-inquiry-model';
import { ProcessResponse } from '../models/process-response';
import { SiteModel } from '../models/site-model';
import { TemplateModel } from '../models/template-model';

@Injectable({
  providedIn: 'root',
})
export class ClientSiteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiClientSiteSiteCodeGet
   */
  static readonly ApiClientSiteSiteCodeGetPath = '/api/ClientSite/{siteCode}';

  /**
   * Get site record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientSiteSiteCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSiteSiteCodeGet$Json$Response(params: {
    siteCode: null | string;

  }): Observable<StrictHttpResponse<SiteModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSiteService.ApiClientSiteSiteCodeGetPath, 'get');
    if (params) {

      rb.path('siteCode', params.siteCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SiteModel>;
      })
    );
  }

  /**
   * Get site record.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientSiteSiteCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSiteSiteCodeGet$Json(params: {
    siteCode: null | string;

  }): Observable<SiteModel> {

    return this.apiClientSiteSiteCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SiteModel>) => r.body as SiteModel)
    );
  }

  /**
   * Path part for operation apiClientSiteConfigurationTemplateTypeCodeGet
   */
  static readonly ApiClientSiteConfigurationTemplateTypeCodeGetPath = '/api/ClientSite/configuration/{templateTypeCode}';

  /**
   * Returns the assessment template configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientSiteConfigurationTemplateTypeCodeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSiteConfigurationTemplateTypeCodeGet$Json$Response(params: {
    templateTypeCode: null | string;

  }): Observable<StrictHttpResponse<TemplateModel>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSiteService.ApiClientSiteConfigurationTemplateTypeCodeGetPath, 'get');
    if (params) {

      rb.path('templateTypeCode', params.templateTypeCode, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateModel>;
      })
    );
  }

  /**
   * Returns the assessment template configuration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientSiteConfigurationTemplateTypeCodeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClientSiteConfigurationTemplateTypeCodeGet$Json(params: {
    templateTypeCode: null | string;

  }): Observable<TemplateModel> {

    return this.apiClientSiteConfigurationTemplateTypeCodeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateModel>) => r.body as TemplateModel)
    );
  }

  /**
   * Path part for operation apiClientSitePost
   */
  static readonly ApiClientSitePostPath = '/api/ClientSite';

  /**
   * Create a new "client" assessment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClientSitePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientSitePost$Json$Response(params?: {
      body?: ClientSiteAssessmentInquiryModel
  }): Observable<StrictHttpResponse<ProcessResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClientSiteService.ApiClientSitePostPath, 'post');
    if (params) {


      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProcessResponse>;
      })
    );
  }

  /**
   * Create a new "client" assessment.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClientSitePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClientSitePost$Json(params?: {
      body?: ClientSiteAssessmentInquiryModel
  }): Observable<ProcessResponse> {

    return this.apiClientSitePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ProcessResponse>) => r.body as ProcessResponse)
    );
  }

}
