import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { EmployerPositionReferralModel } from '@api/models/employer-position-referral-model';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LinkEmploymentDatasource } from '@modules/outcomes/models/link-employment-datasource';
import { ListConfig } from '@shared/components/documents/documents-list/documents-datasource';
import * as R from 'remeda';

@Component({
  selector: 'cf2-link-employment-list',
  template: `
    <div class="table-wrapper" *ngIf="tableData">
      <mat-table [dataSource]="tableData" aria-label="Employment History List">
        <!-- column definitions -->
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columns; index as i">
          <mat-header-cell *matHeaderCellDef>
            {{ columnConfig[i].label }}
          </mat-header-cell>

          <mat-cell *matCellDef="let cell; index as j">
            <ng-container
              *ngIf="
                columnConfig[i].key !== 'select' &&
                columnConfig[i].key !== 'clear' &&
                columnConfig[i].key !== 'hourlyPay'
              "
            >
              {{ cell[column] }}
            </ng-container>
            <ng-container *ngIf="columnConfig[i].key === 'select'">
              <div mdcFormField>
                <div mdcCheckbox>
                  <input
                    mdcCheckboxInput
                    type="checkbox"
                    [(ngModel)]="cell[column]"
                    (click)="valueChange(!cell[column], cell)"
                    #cb
                  />
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="columnConfig[i].key === 'hourlyPay'">
              {{ cell[column] | currency }}
            </ng-container>
            <ng-container *ngIf="!isReadOnly && columnConfig[i].key === 'clear'">
              <fa-icon [icon]="closeIcon" (click)="selectionChange.emit(cell)"></fa-icon>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Header Row-->
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

        <!-- Select Record -->
        <mat-row *matRowDef="let cell; columns: columns"></mat-row>
      </mat-table>
    </div>
  `,
  styleUrls: ['./link-employment-list.component.scss'],
})
export class LinkEmploymentListComponent implements OnInit {
  @Input() tableData: EmployerPositionReferralModel[] = [];
  @Input() selectedFields: EmployerPositionReferralModel[] = [];
  @Input() type: 'card' | 'modal' | 'edit' = 'modal';
  @Output() selectionChange = new EventEmitter<EmployerPositionReferralModel[]>();
  @Input() isReadOnly = true;

  get noRecords() {
    return this.type === 'card' && this?.tableData?.length > 0;
  }
  columns: string[];
  columnConfig: ListConfig[];
  closeIcon = faTimesCircle;

  ngOnInit(): void {
    const fieldConfig = new LinkEmploymentDatasource(this.type ? this.type : 'modal');

    this.columns = fieldConfig?.config?.map((column) => column.key);
    this.columnConfig = R.concat(fieldConfig.config, []);
    this.selectedFields = [];
  }

  valueChange(isSelect = true, field: EmployerPositionReferralModel = null) {
    console.log(isSelect);
    const position = field;
    const oldFields = [...this.selectedFields];
    const selectField = (oldFields: EmployerPositionReferralModel[]) => R.concat(oldFields, [position]);

    const removeField = (oldFields: EmployerPositionReferralModel[]) =>
      R.filter(oldFields, (pos) => pos.parentEmployerPositionKey !== field.parentEmployerPositionKey);

    const updatedFields = isSelect ? selectField(oldFields) : removeField(oldFields);

    this.selectedFields = updatedFields;
    this.selectionChange.emit(updatedFields);
  }
}
