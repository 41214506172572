import { ActiveDirectoryConfig } from '@core/constants/cf2-msal-config.constants';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { azureClientId, azureTenantId, setClientId, setTenantId } from "../../../main";

const config = ActiveDirectoryConfig;
function getBaseUrl(): string {
  return document.getElementsByTagName('base')[0].href;
}

function getClientId(): string {
  if (!azureClientId) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", getBaseUrl() + "api/ApplicationSettings/clientId", false ); // false for synchronous request
    xmlHttp.send( null );
    setClientId(xmlHttp.responseText);
  }
  return azureClientId;
}

function getTenantId(): string {
  if (!azureTenantId) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", getBaseUrl() + "api/ApplicationSettings/tenantId", false ); // false for synchronous request
    xmlHttp.send( null );
    setTenantId(xmlHttp.responseText);
  }
  return azureTenantId;
}

export const ConsentScopes = ['user.read', 'openid', 'profile', 'email'];

export function MsalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: getClientId(),
      authority: `${config.auth.authority}${getTenantId()}/`,
      // validateAuthority,
      redirectUri: getBaseUrl(),
      postLogoutRedirectUri: getBaseUrl(),
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  })
}

function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log("MSAL Angular: ", message);
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: getProtectedResourceMap()
  }
}

function getProtectedResourceMap() {
  const cleanUrl = getBaseUrl() + 'api'
  return new Map([
    //  null or anonymous routes has to be decalred at the top

    ['https://graph.microsoft.com/v1.0/me', ['openid', 'user.read', 'profile', 'email']],
    [cleanUrl + '/ApplicationSettings', [cleanUrl]],
    [cleanUrl + '/ApplicationStatus', [cleanUrl]],
    [cleanUrl + '/Assessment', [cleanUrl]],
    [cleanUrl + '/CaseNotes', [cleanUrl]],
    [cleanUrl + '/Case', [cleanUrl]],
    [cleanUrl + '/Client', [cleanUrl]],
    [cleanUrl + '/ClientSite', [cleanUrl]],
    [cleanUrl + '/CommonAssessment', [cleanUrl]],
    [cleanUrl + '/Document', [cleanUrl]],
    [cleanUrl + '/Eap', [cleanUrl]],
    [cleanUrl + '/Employee', [cleanUrl]],
    [cleanUrl + '/Employment', [cleanUrl]],
    [cleanUrl + '/Expenditure', [cleanUrl]],
    [cleanUrl + '/Inquiry', [cleanUrl]],
    [cleanUrl + '/Lookup', [cleanUrl]],
    [cleanUrl + '/Organization', [cleanUrl]],
    [cleanUrl + '/Outcome', [cleanUrl]],
    [cleanUrl + '/Property', [cleanUrl]],
    [cleanUrl + '/Reports', [cleanUrl]],
    [cleanUrl + '/Scheduling', [cleanUrl]],
    [cleanUrl + '/Security', [cleanUrl]],
    [cleanUrl + '/State', [cleanUrl]],
    [cleanUrl + '/User', [cleanUrl]],
    [cleanUrl + '/Workflow', [cleanUrl]],
    [cleanUrl + '/IntegrationDashboard', [cleanUrl]],
  ]);
}
