import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
// import { AuthenticationStateService } from '@core/services/authentication-state.service';

@Component({
  selector: 'cf2-logout',
  template: `
    <div style="height: 100%; display: flex;">
        <mat-card class="example-card">
          <mat-card-header>
            <div mat-card-avatar class="cf2-avatar"></div>
            <mat-card-title>Oops...</mat-card-title>
            <mat-card-subtitle>Unable to login</mat-card-subtitle>
          </mat-card-header>
          <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu" /> -->
          <mat-card-content>
            <p>
              Something went wrong logging into CaseFlo. Use the logout button below and try again. If you're still
              locked out please contact support and we'll get you back in right away.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <cf2-button (callback)="msalService.logoutRedirect().subscribe()" isPrimary="true" aling="end">Logout</cf2-button>
          </mat-card-actions>
        </mat-card>
    </div>
  `,
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  constructor(public msalService: MsalService) {
  }
}
