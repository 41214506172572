import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientAddressModel } from '@api/models';
import { MultipleEntriesComponent } from '@shared/components/multiple-entries/multiple-entries-card.component';
import { ModalService } from '@shared/services/modal.service';
import { AddressListConfig } from '../address-list/address-list.component';
import { AddressDataService } from '../services/address-data.service';
import { AddressLabelsType } from '../models/address-form.model';
import { ClientCaseDataService } from '@modules/client/services/client-case-data.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'cf2-address-card',
  template: `
  <ng-container *transloco="let t">
    <div class="cf2-multiple-entries-card flex-col">
      <div class="action-buttons flex-row flex-between">
        <h3>{{ t('Addresses') }}</h3>
        <button mat-button *ngIf="!isReadOnly && !editMode" class="button--secondary" (click)="addForm()">{{ t('Add') }}</button>
      </div>

      <div class="list-block flex-row">
        <ng-container *ngIf="dataSub$ | async as data">
          <cf2-address-list
            [isready]="isReady"
            [data]="data"
            [filteredData]="data"
            (selectedItem)="showEditAddressForm($event)"
            [(isReadOnly)]="isReadOnly"
          ></cf2-address-list>
        </ng-container>
      </div>

      <div class="edit-block flex-col" *ngIf="showForm">
        <cf2-address-form [fields]="fields"></cf2-address-form>
        <div class="action-buttons flex-row flex-end">
          <button mat-button class="button--secondary" (click)="cancel()">{{ t('Cancel') }}</button>
          <button mat-button *ngIf="!editMode" class="button--secondary" (click)="addNew()">{{ t('Save') }}</button>
          <button mat-button *ngIf="!isReadOnly && editMode" class="button--secondary" (click)="updateEntity()">
            Save
          </button>
        </div>
      </div>
    </div>
    </ng-container>
  `,
})
export class AddressCardComponent
  extends MultipleEntriesComponent<ClientAddressModel, AddressLabelsType>
  implements OnInit {
  listConfig: AddressListConfig = {
    showDeleteIcon: true,
  };

  primaryAddressChanged: EventEmitter<Partial<ClientAddressModel>> = new EventEmitter();
  constructor(
    private dataSvc: AddressDataService,
    router: Router,
    modalSvc: ModalService,
    clientCaseDataSvc: ClientCaseDataService,
    private translocoService: TranslocoService
  ) {
    super(router, modalSvc, clientCaseDataSvc);
  }

  initForm(formValue = {}) {
    const form = this.dataSvc.initForm(formValue);
    this.fields = form.fields;
    this.fg = this.fields.fg;

    setTimeout(() => {
      this.showForm = true;
    }, 100);
  }

  showEditAddressForm(key: number) {
    const entityData = this.dataSvc.getEntityDataByKey(key);

    const formValue: Partial<ClientAddressModel> = {
      code: entityData.entity.code,
      primary: entityData.entity.primary,
      unit: entityData.entity.unit,
      addressLine1: entityData.entity.addressLine1,
      addressLine2: entityData.entity.addressLine2,
      city: entityData.entity.city,
      provinceCode: entityData.entity.provinceCode,
      postalCode: entityData.entity.postalCode,
      addressEffectiveDate: entityData.entity.addressEffectiveDate,
      addressExpiryDate: entityData.entity.addressExpiryDate,
    };

    this.showEditForm(key, formValue);
  }

  showEditForm(key: number, formValue: Partial<ClientAddressModel>) {
    // if same casenote is selected the don't update the form
    if (this.dataSvc.selectedKey === key) return;
    // hide existing casenote form, sanitize and then create form fields with values
    this.showForm = false;
    this.dataSvc.sanitizeForm();

    this.editMode = true;
    this.dataSvc.selectedKey = key;
    // TODO: remove timeout
    setTimeout(() => this.initForm(formValue), 10);
  }

  addForm() {
    // hide existing form
    this.showForm = false;
    this.dataSvc.sanitizeForm();
    // reset selected casenote
    this.dataSvc.selectedKey = null;
    this.editMode = false;

    const formValue: Partial<ClientAddressModel> = {
      primary: false,
    };

    this.initForm(formValue);
  }

  addNew() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    const entity = this.fg.getRawValue() as ClientAddressModel;

    // update the primary address in the Details tab
    if (entity.primary) {
      this.dataSvc.resetPrimary();
      // this.dataSvc.setExternalFormGroup(entity);
      this.primaryAddressChanged.emit(entity);
    }

    this.dataSvc.addEntityData({
      tempKey: this.generateKey(),
      entity: entity,
    });

    this.dataSvc.sanitizeForm();
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    // timout is requied to save the primary address, wait for form status  to change from Pending to Valid
    setTimeout(() => this.save());
  }

  updateEntity() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched();
      console.log('Invalid form data', this.fg);
      return;
    }

    // get disabled form control values as well usig FormGroup.getRawValues()
    const entity = this.fg.getRawValue() as ClientAddressModel;

    // update the primary address in the Details tab
    if (entity.primary) {
      this.dataSvc.resetPrimary();
      // this.dataSvc.setExternalFormGroup(entity);
      this.primaryAddressChanged.emit(entity);
    }

    this.dataSvc.updateEntityData({
      tempKey: this.dataSvc.selectedKey,
      entity: entity,
    });

    // update the primary address in the Details tab
    if (entity.primary) {
      this.primaryAddressChanged.emit(entity);
    }

    this.dataSvc.sanitizeForm();
    this.editMode = false;
    this.showForm = false;

    // update casenote list component
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    // save all client case form data to backend
    // timout is requied to save the primary address
    setTimeout(() => this.save());
  }

  cancel() {
    this.editMode = false;
    this.showForm = false;
    this.dataSvc.sanitizeForm();
  }

  processResponse(res) {
    //const entity: ClientAddressModel[] = this.dataSvc.mapCaseNotes(res);
    const entityData = this.dataSvc.mapEntityData(res);

    this.dataSvc.setEntityData(entityData);
    this.dataSvc.setEntityDataSource(this.dataSvc.getEntityData());

    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnInit() {
    this.dataSub$ = this.dataSvc.entityListDataSub$;
    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribe();
    // clear document form data and state
    this.dataSvc.sanitizeData();
  }
}
