import { Validators, UntypedFormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { dateBuilder } from './date.model';
import { FormsComponentValuesType } from './base-component-field';
import { of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ClientService } from '@api/services';
import * as R from 'remeda';
import { TranslocoService } from '@ngneat/transloco';
import { InjectorInstance } from '@core/core.module';

const alphaPatternPart = 'a-zA-Z';

const accentPattern = `Çéâêîôûàèùëïü'-\\s`;
const makeRegExpBase = (...patterns): (() => string) => (): string => [].concat(...patterns).join('');
const makeRegExpPattern = (base: string): (() => string) => (): string => `^[${base}]+$`;

export const LONG_STRING_LENGTH = {
  maxLength: 2000,
  minLength: 3,
};

export const MIDLONG_STRING_LENGTH = {
  maxLength: 1000,
  minLength: 3,
};

export const MID_STRING_LENGTH = {
  maxLength: 500,
  minLength: 3,
};

export const makeNamePattern = makeRegExpPattern(makeRegExpBase(alphaPatternPart, accentPattern)());

/**
 * Function to return a higher order function that will include min / max days
 * for date inputs
 *
 * @param min  -  the minimum required date for the input
 * @param max - the maximum allowable date for the input
 * @returns a function with validatoar params that will combine other validators to make
 * the required validation
 */
export function makeDateValidators(min: Date, max: Date): (...validators: any[]) => any[] {
  return (...validators): any[] => [
    ...validators,
    RxwebValidators.minDate({ value: new Date(min) }),
    RxwebValidators.maxDate({ value: new Date(max) }),
  ];
}

export const errorMap = {
  required: (label: string): string => InjectorInstance.get<TranslocoService>(TranslocoService).translateObject("Message.Error.ControlRequired", {ControlName: label}),
  minlength: (label: string, length: string): string => `must be at least ${length}`,
  email: (label: string): string => `${label} must be in the format "example@mail.com"`,
  maxlength: (label: string, length: string): string => `${label} must be at less than ${length}`,
  pattern: (text: string): string => text
} as const;

export function errorMapFn(fc: UntypedFormControl, label: string): string {
  if (!fc.errors) return;
  if (fc.errors.required) return InjectorInstance.get<TranslocoService>(TranslocoService).translateObject("Message.Error.ControlRequired", {ControlName: label});
  if (fc.errors.minlength) return `${label} must be at least ${fc.errors.minlength.requiredLength} characters.`;
  if (fc.errors.minLength) return `${label} ${fc.errors.minLength?.message}`;
  if (fc.errors.maxlength) return `${label} must be at less than ${fc.errors.maxlength.requiredLength}`;
  if (fc.errors.maxLength) return `${label} ${fc.errors.maxLength?.message}`;
  if (fc.errors.minDate) return `${label} ${fc.errors.minDate?.message}`;
  if (fc.errors.email) return `${label} must be in the format "example@mail.com".`;
  if (fc.errors.pattern) return `${label} must be in the format "example@mail.com".`;
  if (fc.errors.matDatepickerMin) return `${label} must be from April 1st ${dateBuilder()[0]}.`;
  if (fc.errors.cannotBeBeforeEapStartDate) return `The Monitoring Start Date cannot be before the EAP’s start date.`;
  return `${label} is invalid.`
}
export const hasError = (fc: UntypedFormControl): boolean => {
  if (!fc) return;
  return fc.invalid && fc.touched;
};

export interface NameValidatorOptions {
  minLength?: number;
  maxLength?: number;
}
export interface DateValidatorOptions {
  minDate?: Date;
  maxDate?: Date;
}

export class ValidatorFns {
  /**
   * Create a new length validator
   * @param isMinLength - identify if it's a min
   * or max length validator. Defaults to min Length Validator
   * @returns a function that takes a value of either min or max
   * to create a min/max validator
   */
  static lengthValidator = (isMinLength = true) => {
    return isMinLength
      ? (value: number): ValidatorFn => RxwebValidators.minLength({ value })
      : (value: number): ValidatorFn => RxwebValidators.maxLength({ value });
  };
  static nameAllowedCharsValidator = (): ValidatorFn =>
    RxwebValidators.pattern({
      expression: { name: new RegExp(makeNamePattern()) },
    });

  /**
   * Create a min or max date validator
   * @param isMin - if true returns a min date validator. If false returns max date
   * Returns a min date validator by default
   * @returns a function containing a min or max date validator
   */
  static minMaxDateValidator = (isMin = true) => {
    return isMin
      ? (date) => RxwebValidators.minDate({ value: new Date(date) })
      : (date: Date) => RxwebValidators.maxDate({ value: new Date(date) });
  };

  /*
   * A function to streamline building formControls
   *
   * @param value - the initial value of the formControl
   * @param options -
   *
   * @returns a new formControl
   */
  static makeFc(
    value: FormsComponentValuesType | null = null,
    opts?: { validators?: ValidatorFn[]; required?: boolean; tuple?: boolean }
  ): UntypedFormControl | [FormsComponentValuesType | null, ValidatorFn[]] {
    const { validators = [], required = true, tuple = false } = opts;
    const allValidators = required ? [...validators, RxwebValidators.required()] : validators;
    return tuple ? [value, allValidators] : new UntypedFormControl(value, ValidatorFns.compose([...allValidators]));
  }

  static required() {
    return (condition?: (x: any) => boolean) =>
      condition ? RxwebValidators.required({ conditionalExpression: condition }) : RxwebValidators.required();
  }
  static sinValidator() {
    /**
     * @param sin - the input SIN value in string format
     * @returns boolean value if the string is valid / invalid
     */
    function validateSin(sin: string) {
      if (sin && sin.trim().length > 0) {
        // Init weights and other stuff
        const weights: number[] = [1, 2, 1, 2, 1, 2, 1, 2, 1];
        let sum = 0;

        // Clean up string
        const value = sin.trim();
        sin = value
          .replace(/-/g, '') // remove underlines
          .replace(/\s/g, ''); // spaces

        // Test for length
        if (sin.length !== 9) {
          return false;
        }

        // Test for string of zeros
        if (sin === '000000000') {
          return false;
        }

        // Walk through each character
        for (let i = 0; i < sin.length; i++) {
          // pull out char
          const char = sin.charAt(i);

          // parse the number
          const num = Number(char);
          if (Number.isNaN(num)) {
            return false;
          }

          // multiply the value against the weight
          let result = num * weights[i];

          // If two digit result, subtract 9
          if (result > 9) {
            result = result - 9;
          }

          // add it to our sum
          sum += result;
        }

        // The sum must be divisible by 10
        if (sum % 10 !== 0) {
          return false;
        } else {
          return true;
        }
      }
    }

    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value ? !validateSin(control.value) : null;
      return forbidden ? { invalidSin: { value: `${control.value} is not valid` } } : null;
    };
  }

  static compose(validators: ValidatorFn[]) {
    return RxwebValidators.compose({
      validators,
    });
  }
}

export class Cf2Validators {
  minDate: Date;
  maxDate: Date;
  dateTuples: number[];
  translocoService : TranslocoService;

  constructor() {
    this.translocoService = InjectorInstance.get<TranslocoService>(TranslocoService);
    this.dateTuples = dateBuilder();
  }

  /**
   * @param options - placeholder ofr email options.
   *
   * @returns - the rx web validator (email) and min/max length validators
   */
  emailValidators(options = {}) {
    return [ValidatorFns.lengthValidator(true)(3), ValidatorFns.lengthValidator(false)(50), RxwebValidators.email()];
  }

  /**
   * @param options - placeholder options object
   * @returns array of validators for strings.
   */
  stringValidators(options: NameValidatorOptions | DateValidatorOptions) {
    let stringOptions = { minLength: 3, maxLength: 100 };
    if (options) stringOptions = R.merge(stringOptions, options);
    const { minLength, maxLength } = stringOptions as NameValidatorOptions;
    return () => [ValidatorFns.lengthValidator(true)(minLength), ValidatorFns.lengthValidator(false)(maxLength)];
  }

  longStringValidators(options: NameValidatorOptions | DateValidatorOptions) {
    let stringOptions = { minLength: LONG_STRING_LENGTH.minLength, maxLength: LONG_STRING_LENGTH.maxLength };
    if (options) stringOptions = R.merge(stringOptions, options);
    const { minLength, maxLength } = stringOptions as NameValidatorOptions;
    return () => [ValidatorFns.lengthValidator(true)(minLength), ValidatorFns.lengthValidator(false)(maxLength)];
  }

  midLongStringValidators(options: NameValidatorOptions | DateValidatorOptions) {
    
    let stringOptions = { minLength: MIDLONG_STRING_LENGTH.minLength, maxLength: MIDLONG_STRING_LENGTH.maxLength };
    if (options) stringOptions = R.merge(stringOptions, options);
    const { minLength, maxLength } = stringOptions as NameValidatorOptions;
    return () => [ValidatorFns.lengthValidator(true)(minLength), ValidatorFns.lengthValidator(false)(maxLength)];
    
   //return this.customStringValidators(options, MIDLONG_STRING_LENGTH.minLength, MIDLONG_STRING_LENGTH.maxLength);
  }  


  midStringValidators(options: NameValidatorOptions | DateValidatorOptions) {
    
    let stringOptions = { minLength: MID_STRING_LENGTH.minLength, maxLength: MID_STRING_LENGTH.maxLength };
    if (options) stringOptions = R.merge(stringOptions, options);
    const { minLength, maxLength } = stringOptions as NameValidatorOptions;
    return () => [ValidatorFns.lengthValidator(true)(minLength), ValidatorFns.lengthValidator(false)(maxLength)];
    
    //return this.customStringValidators(options, MID_STRING_LENGTH.minLength, MID_STRING_LENGTH.maxLength);
  }    

  customStringValidators(options: NameValidatorOptions | DateValidatorOptions, min: number, max: number) {
    let stringOptions = { minLength: min, maxLength: max };
    if (options) stringOptions = R.merge(stringOptions, options);
    const { minLength, maxLength } = stringOptions as NameValidatorOptions;
    return () => [ValidatorFns.lengthValidator(true)(minLength), ValidatorFns.lengthValidator(false)(maxLength)];
  }   

  /**
   * @param options - future options object
   * returns array of min/max validators
   */
  birthdateValidators(options = {}) {
    this.dateTuples = dateBuilder();
    const minDate = new Date(this.dateTuples[0] - 110, this.dateTuples[1], this.dateTuples[2] - 1);
    const maxDate = new Date(dateBuilder()[0] - 14, this.dateTuples[1], this.dateTuples[2] - 1);

    return [ValidatorFns.minMaxDateValidator(true)(minDate), ValidatorFns.minMaxDateValidator(false)(maxDate)];
  }

  /**
   * @param params - configuration options for the data validator
   * returns min and max validators for the form
   */
  dateValidators(params: DateValidatorOptions = {}) {
    const {
      minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0),
      maxDate = new Date(dateBuilder()[0] + 1, dateBuilder()[1], dateBuilder()[2]),
    } = params;

    const min = ValidatorFns.minMaxDateValidator()(minDate);
    const max = ValidatorFns.minMaxDateValidator(false)(maxDate);

    return [min, max];
  }

  wideDateValidators(params: DateValidatorOptions = {}) {
    const {
      minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0),
      maxDate = new Date(dateBuilder()[0] + 2, dateBuilder()[1], dateBuilder()[2]),
    } = params;

    const min = ValidatorFns.minMaxDateValidator()(minDate);
    const max = ValidatorFns.minMaxDateValidator(false)(maxDate);

    return [min, max];
  }

  /**
   * Past date validator enforces any date newer than today to be invalid
   */

  pastDateValidators(params: DateValidatorOptions = {}) {
    const minDate = new Date(dateBuilder()[0] - 4, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);
    const maxDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2]);
    maxDate.setHours(23,59,59);
    const min = ValidatorFns.minMaxDateValidator()(minDate);
    const max = ValidatorFns.minMaxDateValidator(false)(maxDate);

    return [min, max];
  }

  futureDateValidators(params: DateValidatorOptions = {}) {
    const minDate = new Date(dateBuilder()[0], dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);
    const maxDate = new Date(dateBuilder()[0] + 4, dateBuilder()[1], dateBuilder()[2]);
    const min = ValidatorFns.minMaxDateValidator()(minDate);
    const max = ValidatorFns.minMaxDateValidator(false)(maxDate);

    return [min, max];
  }

  /**
   * Create a new name validator for a component.
   * @param value - valid value
   * @param opts - minLength & maxLength default to the predefined value
   *
   * @returns an array of the validators required for name fields
   */
  nameValidators(opts: NameValidatorOptions = {}) {
    const { minLength = 2, maxLength = 50 } = opts;

    const min = ValidatorFns.lengthValidator(true)(minLength);
    const max = ValidatorFns.lengthValidator(false)(maxLength);
    const name = ValidatorFns.nameAllowedCharsValidator();
    return [min, max, name];
  }

  confirmationValidator() {
    return [RxwebValidators.requiredTrue()];
  }

  sinValidator() {
    return [ValidatorFns.sinValidator(), RxwebValidators.pattern({ expression: { sin: /^\d{3}-?\d{3}-?\d{3}$/ } })];
  }

  // phone fields are masked so this works fine
  phoneValidator() {
    return [RxwebValidators.pattern({ expression: { phone: new RegExp(/(\(\d{3}\)|\d{3})([- ])?\d{3}([- ])?\d{4}/) } })
    ];
  }

  postalValidators() {
    return [ValidatorFns.lengthValidator(true)(6), ValidatorFns.lengthValidator(false)(6)];
  }

  idValidated() {
    return [RxwebValidators.required({ conditionalExpression: (x) => x.idValidated })];
  }

  smsRequired() {
    return [
      RxwebValidators.required({
        conditionalExpression: (x) => x.primaryContactType === 'SMS',
      }),
      ValidatorFns.lengthValidator(false)(14),
      ValidatorFns.lengthValidator(true)(14),
    ];
  }

  otherLanguage() {
    return [
      RxwebValidators.required({
        conditionalExpression: (x) => x.languageCode !== null && x.languageCode.toLowerCase().includes('oth'),
      }),
      ValidatorFns.lengthValidator()(2),
    ];
  }

  expectedCost() {
    return [
      RxwebValidators.required({
        conditionalExpression: (x) => x.planItemCode.toLowerCase().includes('oth'),
      }),
      ValidatorFns.lengthValidator()(2),
    ];
  }

  currencyValidators() {
    return [ValidatorFns.lengthValidator(true)(1)];
  }

  camsValidators() {
    return [ValidatorFns.lengthValidator(false)(7), ValidatorFns.lengthValidator(true)(7)];
  }

  craValidators() {
    return [ValidatorFns.lengthValidator(false)(9), ValidatorFns.lengthValidator(true)(9)];
  }

  //TODO: Sean please create a real time validator for the Time Selector :)
  timeValidators() {
    // return [RxwebValidators.time()]
    return [];
  }
}

export function sinExistsWrapper(cliSvc: ClientService, clKey: number) {
  return async (ctrl: AbstractControl): Promise<ValidationErrors | null> => {
    //check that sin is valid
    if (!ctrl.value) return null;

    const invFormat = ValidatorFns.sinValidator()(ctrl);
    if (invFormat !== null) {
      //a bit dirty but it won't read invalidSin message so we have to use dupSin
      return of({ duplicateSin: 'invalid SIN' }).toPromise();
    }

    const prom = cliSvc
      .apiClientBusinessKeyGet$Json({ bk: ctrl.value })
      .pipe(
        debounceTime(200),
        map((data) => {
          if (data.length < 1 || (data.length === 1 && data[0].parentClientKey.toString() === clKey.toString())) {
            //unique if the only matching sin belongs to this client
            return null;
          }
          return { duplicateSin: 'Duplicate SIN exists' };
        })
      )
      .toPromise();
    return prom;
  };
}

export function emailExistsWrapper(cliSvc: ClientService, clientKey: number) {
  return async (ctrl: AbstractControl): Promise<ValidationErrors | null> => {
    //check that sin is valid
    if (!ctrl.value) return null;

    // const invFormat = ValidatorFns.emai()(ctrl);
    // if (invFormat !== null) {
    //   //a bit dirty but it won't read invalidSin message so we have to use dupSin
    //   return of({ duplicateSin: 'invalid SIN' }).toPromise();
    // }

    const prom = cliSvc
      .apiClientSearchByEmailGet$Json({ emailAddress: ctrl.value })
      .pipe(
        debounceTime(200),
        map((data) => {
          if (data.length < 1 || (data.length === 1 && data[0].clientKey.toString() === clientKey.toString())) {
            //unique if the only matching sin belongs to this client
            return null;
          }
          return { duplicateEmail: 'Duplicate Email exists' };
        })
      )
      .toPromise();
    return prom;
  };
}
