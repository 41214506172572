import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormsFns } from '@core/models/forms-fns.model';
import { BaseComponent } from '@shared/components/base/base.component';
import { dateBuilder, maxExpiryDate } from '@core/models/date.model';
import { DisabilitiesDataService } from '../services/disabilities-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientAddressModel } from '@api/models';
import { ClientDisabilityKeysType, ClientDisabilityLabelsType } from '../models/disabilities-form.model';
import { Globals } from 'src/app/globals';
import { TranslocoService } from '@ngneat/transloco';

export interface DisabilitiesFormConfig {
  //showPrimaryField?: boolean;
  showClientDisabilityType?: boolean;
}

@Component({
  selector: 'cf2-disabilities-form',
  template: `
  <ng-container *transloco="let t"> 
    <div class="flex-row flex-wrap">
      <ng-container *ngIf="clientDisabilityTypes$ | async as options">
        <cf2-select-field-top
          [ctrl]="getFc('code')"
          [label]="labels.code"
          [required]="required.code"
          [disabled]="isReadOnly || disabled.code"
          [options]="options"
        ></cf2-select-field-top>
      </ng-container>

      <div>
        <label [for]="labels.primary"> {{ labels.primary }} </label>
        <mat-radio-group
          [aria-labelledby]="labels.primary"
          [formControl]="getFc('primary')"
          [disabled]="isReadOnly"
        >
          <mat-radio-button [value]="true" style="margin-right: 15px;">{{ t('Yes') }}</mat-radio-button>
          <mat-radio-button [value]="false">{{ t('No') }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="flex-row disclosable-row">
      <label [for]="labels.disclosable" class="mbm-label">
        {{ labels.disclosable }}
      </label>
      <!-- TODO: disable and link to indicator -->
      <mat-radio-group
        [aria-labelledby]="labels.disclosable"
        [formControl]="getFc('disclosable')"
        [disabled]="isReadOnly"
      >
        <mat-radio-button [value]="true" style="margin-right: 15px;">{{ t('Yes') }}</mat-radio-button>
        <mat-radio-button [value]="false">{{ t('No') }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- <div class="comments-row"> -->
    <cf2-textarea-field-top
      [ctrl]="getFc('comment')"
      [fullWidth]="true"
      [label]="labels.comment"
      [required]="required.comment"
      [disabled]="isReadOnly"
      [rows]="2"
    >
    </cf2-textarea-field-top>
    <!-- </div> -->

    <div class="flex-row flex-wrap">
      <cf2-date-field-top
        [ctrl]="getFc('effectiveDate')"
        [label]="labels.effectiveDate"
        [required]="required.effectiveDate"
        [disabled]="isReadOnly || disabled.effectiveDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
      ></cf2-date-field-top>

      <cf2-date-field-top
        [ctrl]="getFc('clientDisabilityExpiryDate')"
        [label]="labels.clientDisabilityExpiryDate"
        [required]="required.clientDisabilityExpiryDate"
        [disabled]="isReadOnly || disabled.clientDisabilityExpiryDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
      ></cf2-date-field-top>
    </div>
  </ng-container>
  `,
  styles: [
    `
      .disclosable-row {
        padding: 25px;
      }
    `,
  ],
})
export class DisabilitiesFormComponent extends BaseComponent implements OnInit {
  isInit = false;
  @Input() fields: {
    fg: UntypedFormGroup;
    labels: ClientDisabilityLabelsType;
    disabled: ClientDisabilityLabelsType;
    required: ClientDisabilityLabelsType;
  };

  @Input() config: DisabilitiesFormConfig = {
    showClientDisabilityType: true,
  };

  clientDisabilityTypes$ = this.dataSvc.clientDisabilityTypes$;
  labels: any;
  required: any;
  disabled: any;
  fg: UntypedFormGroup;
  getFc: (formKey: string) => AbstractControl;
  public minDate = new Date(dateBuilder()[0] - 2, dateBuilder()[1], dateBuilder()[2] - 1, 0, 0, 0, 0);

  /* the maximum allowed date for the document  */
  public maxDate = maxExpiryDate();

  get isReadOnly() {
    return this.globals.viewType === 'VIEW';
  }

  constructor(private dataSvc: DisabilitiesDataService, private globals: Globals) {
    super();
  }

  ngOnInit() {
    if (!this.fields) {
      throw new Error('no fields set in <cf2-disabilities-form>, you have to initalize disabilities form');
    }

    const { fg, labels, required, disabled } = this.fields;

    this.getFc = FormsFns.getFc<ClientDisabilityKeysType>(fg);
    this.fg = fg;
    this.labels = labels;
    this.required = required;
    this.disabled = disabled;
    setTimeout(() => {
      this.isInit = true;
    }, 100);
  }
}
