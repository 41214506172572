/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplicationSettingsModel } from '../models/application-settings-model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiApplicationSettingsGet
   */
  static readonly ApiApplicationSettingsGetPath = '/api/ApplicationSettings';


  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationSettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsGet$Json$Response(params?: {

  }): Observable<StrictHttpResponse<ApplicationSettingsModel>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationSettingsService.ApiApplicationSettingsGetPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationSettingsModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationSettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsGet$Json(params?: {

  }): Observable<ApplicationSettingsModel> {

    return this.apiApplicationSettingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationSettingsModel>) => r.body as ApplicationSettingsModel)
    );
  }

  /**
   * Path part for operation apiApplicationSettingsIsMaintenance
   */
  static readonly ApiApplicationSettingsIsMaintenanceGetPath = '/api/ApplicationSettings/IsMaintenance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationSettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsIsMaintenanceGet$Json$Response(): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationSettingsService.ApiApplicationSettingsIsMaintenanceGetPath, 'get');
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationSettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsIsMaintenanceGet$Json(): Observable<boolean> {

    return this.apiApplicationSettingsIsMaintenanceGet$Json$Response().pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiApplicationSettingsTestEmailToAddressGet
   */
  static readonly ApiApplicationSettingsTestEmailToAddressGetPath = '/api/ApplicationSettings/testEmail/{toAddress}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationSettingsTestEmailToAddressGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsTestEmailToAddressGet$Response(params: {
    toAddress: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationSettingsService.ApiApplicationSettingsTestEmailToAddressGetPath, 'get');
    if (params) {

      rb.path('toAddress', params.toAddress, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationSettingsTestEmailToAddressGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsTestEmailToAddressGet(params: {
    toAddress: null | string;

  }): Observable<void> {

    return this.apiApplicationSettingsTestEmailToAddressGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiApplicationSettingsTestSmsToPhoneNumberGet
   */
  static readonly ApiApplicationSettingsTestSmsToPhoneNumberGetPath = '/api/ApplicationSettings/testSms/{toPhoneNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationSettingsTestSmsToPhoneNumberGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsTestSmsToPhoneNumberGet$Response(params: {
    toPhoneNumber: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationSettingsService.ApiApplicationSettingsTestSmsToPhoneNumberGetPath, 'get');
    if (params) {

      rb.path('toPhoneNumber', params.toPhoneNumber, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationSettingsTestSmsToPhoneNumberGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationSettingsTestSmsToPhoneNumberGet(params: {
    toPhoneNumber: null | string;

  }): Observable<void> {

    return this.apiApplicationSettingsTestSmsToPhoneNumberGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
