import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'cf2-button',
  template: `
    <span [matTooltip]="tooltip" [matTooltipDisabled]="!disabled">
      <button
        mdcButton
        raised
        [class.button-primary]="isPrimary"
        [class.button-alternate]="!isPrimary"
        [class.button-disabled]="disabled"
        [disabled]="disabled"
        (click)="buttonClick()"
        type="button"
        #button
      >
        {{ buttonText }}

        <ng-content></ng-content>
      </button>
    </span>
  `,
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @ViewChild('button') buttonControl: ElementRef;

  @Output() callback = new EventEmitter();

  @Input() disabled = false;
  @Input() isPrimary = false;
  @Input() buttonText: string;
  @Input() tooltip: string;

  public focus(): void {
    if (this.buttonControl !== undefined) {
      this.buttonControl.nativeElement.focus();
    }
  }

  public buttonClick() {
    this.callback.emit();
  }

  public disableButton() {
    this.disabled = true;
  }

  public enableButton() {
    this.disabled = false;
  }
}
